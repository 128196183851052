export const pushType = {
    USER_RIDE_DIRECT_RISK_ALERT_TO_ADMIN:"USER_RIDE_DIRECT_RISK_ALERT_TO_ADMIN"
}

export const general = {
    DRIVING_SCORE_HIGH:"You drive safely. You have excellent driving practice and you have a nature of very cautious.",
    DRIVING_SCORE_MEDIUM:"You are surprised from time to time by the singularities of the road poorly anticipated. Yet you have good driving practice and a normal self-confidence. Learn how to focus your attention on key places that correspond to alerts and you will lower your risk.",
    DRIVING_SCORE_LOW:  "Your driving is risky. You have good driving practice and a normal self-confidence. You have probably taken some bad habits to urgently fix to lower your risk.",

}

export const riskLevel = {
    animalCrossingLow:0,
    animalCrossingMedium:0,
    animalCrossingHigh:0,
    cautionLow:0,
    cautionMedium:0,
    cautionHigh:0,
    curveLow:0,
    curveMedium:0,
    curveHigh:0,
    roundAboutLow:0,
    roundAboutMedium:0,
    roundAboutHigh:0,
    hillLow:0,
    hillMedium:0,
    hillHigh:0,
    hillDownwardsLow:0,
    hillDownwardsMedium:0,
    hillDownwardsHigh:0,
    hillUpwardsLow:0,
    hillUpwardsMedium:0,
    hillUpwardsHigh:0,
    icyConditionsLow:0,
    icyConditionsMedium:0,
    icyConditionsHigh:0,
    intersectionLow:0,
    intersectionMedium:0,
    intersectionHigh:0,
    laneMergeLow:0,
    laneMergeMedium:0,
    laneMergeHigh:0,
    lowGearLow:0,
    lowGearMedium:0,
    lowGearHigh:0,
    mobileUseLow:0,
    mobileUseMedium:0,
    mobileUseHigh:0,
    narrowLow:0,
    narrowMedium:0,
    narrowHigh:0,
    noOverTakingLow:0,
    noOverTakingMedium:0,
    noOverTakingHigh:0,
    overSpeedLow:0,
    overSpeedMedium:0,
    overSpeedHigh:0,
    pedestrianLow:0,
    pedestrianMedium:0,
    pedestrianHigh:0,
    priorityLow:0,
    priorityMedium:0,
    priorityHigh:0,
    railwayCrossingLow:0,
    railwayCrossingMedium:0,
    railwayCrossingHigh:0,
    riskOfGroundLow:0,
    riskOfGroundMedium:0,
    riskOfGroundHigh:0,
    schoolLow:0,
    schoolMedium:0,
    schoolHigh:0,
    slipperyRoadsLow:0,
    slipperyRoadsMedium:0,
    slipperyRoadsHigh:0,
    stopLow:0,
    stopMedium:0,
    stopHigh:0,
    noOvertakingTrucksLow:0,
    noOvertakingTrucksMedium:0,
    noOvertakingTrucksHigh:0,
    trafficLightLow:0,
    trafficLightMedium:0,
    trafficLightHigh:0,
    windLow:0,
    windMedium:0,
    windHigh:0,
    windRoadLow:0,
    windRoadMedium:0,
    windRoadHigh:0,
    yieldLow:0,
    yieldMedium:0,
    yieldHigh:0,

}
