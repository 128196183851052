import { apiConfig } from "../../config/Config.js";
import { apiEndPoint, apiType, apiStatus } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import {
  SC_COMPANY_ID,
  SC_DIVISION_ID,
  SC_GROUP_ID,
  SC_LOGIN_USER_ID,
  SC_MODULE_ID,
} from "../../constant/StorageConstant.js";
import userSessionController from "../UserSession/UserSessionController.js";

let Report = {
  async report(report) {
    var companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = userSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = userSessionController.getLocalSessionValue(SC_LOGIN_USER_ID);
    var moduleId = userSessionController.getLocalSessionValue(SC_MODULE_ID);

    if (
      companyId.length > 0 &&
      divisionId.length > 0 &&
      userId.length > 0 &&
      moduleId.length > 0
    ) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        moduleId: moduleId,
        description: "Test Report",
        status: apiStatus.REGISTERED,
        type: apiType.REPORT_PROBLEM,
        userFeedbackField: {
          description: report,
        },
      };

      return await ApiService.callPOST(
        apiConfig.API_VERSION_1,
        apiEndPoint.FEEDBACK,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getUserLastActivityReport(
    indexStart,
    filter,
    workFilter,
    categoryValue,
    startDateTime,
    endDateTime,
    pageSize,
    selectedOption
  ) {
    try {
      const companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID) || '';
      const divisionId = userSessionController.getLocalSessionValue(SC_DIVISION_ID) || '';

      if (!companyId || !divisionId) {
        return { status: "FAILED", error: "Company or Division ID is missing", data: [] };
      }

      const reportFields = {
        filterType: filter,
        userStatus: workFilter
      };

      const reqPayload = {
        companyId,
        divisionId,
        type: "USER_ACTIVITY",
        offset: indexStart,
        limit: pageSize,
        reportFields: JSON.stringify(reportFields),
        startDateTime,
        endDateTime,
        reportType: "SAD_MY_USER_LAST_ACTIVITY",
        userId: selectedOption,
        sortOrder: categoryValue,
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqPayload
      );
    } catch (error) {
      console.error("Error in getUserReport:", error);
      return { status: "FAILED", error: "Request Process Failed", data: [] };
    }
  },
  async getUserloginActivityReport(
    indexStart,
    filter,
    exceptionFilter,
    deviceFilter,
    startDateTime,
    endDateTime,
    pageSize,
    userFilter,
  ) {
    var companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = userSessionController.getLocalSessionValue(SC_DIVISION_ID);

    var reportFields = {
      filterType: filter,
      active: exceptionFilter,
      deviceType: deviceFilter,
    };

    if (companyId.length > 0 && divisionId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        type: "USER_ACTIVITY",
        reportType: "SAD_MY_USER_SESSION_ACTIVITY",
        offset: indexStart,
        limit: pageSize,
        reportFields: JSON.stringify(reportFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        userId: userFilter,
      };

      // console.log("myuserspayload: " + JSON.stringify(reqPayload))
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getUserListReport() {
    var companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = userSessionController.getLocalSessionValue(SC_DIVISION_ID);

    if (companyId.length > 0 && divisionId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        type: "USER",
        offset: 0,
        limit: 100,
        category: "USER",
        status: "ACTIVE",
      };

      // console.log("myuserspayload: " + JSON.stringify(reqPayload))
      return await ApiService.callGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getUserAppExpReport(
    indexStart,
    filter,
    exceptionFilter,
    deviceFilter,
    startDateTime,
    endDateTime,
    pageSize,
    userFilter,
  ) {
    var companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = userSessionController.getLocalSessionValue(SC_DIVISION_ID);

    var reportFields = {
      filterType: filter,
      subFilterType: exceptionFilter,
      deviceType: deviceFilter,
    };

    if (companyId.length > 0 && divisionId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        type: "USER_APP_EXCEPTION",
        reportType: "SAD_MY_USER_EXCEPTION_ACTIVITY",
        offset: indexStart,
        limit: pageSize,
        reportFields: JSON.stringify(reportFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        userId: userFilter,
        // viewType: "text",
      };

      // console.log("myuserspayload: " + JSON.stringify(reqPayload))
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getDivisionList() {
    var companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId = userSessionController.getLocalSessionValue(SC_GROUP_ID);
    if (companyId.length > 0 && groupId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        groupId: groupId,
        type: "CLIENT",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: 0,
        limit: 100
      };
      return await ApiService.callGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DIVISION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getUserOnboardData(divisionId, filterMethod, username, userId, usernamemethod) {
    const companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    // const groupId = userSessionController.getLocalSessionValue(SC_GROUP_ID);
    const reportFields = { filterType: filterMethod, username: username };

    if (companyId.length > 0 && divisionId.length > 0) {
      let reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        type: "USER_ACTIVITY",
        reportType: "SAD_MY_USER_ONBOARD_STATUS_REPORT",
        offset: 0,
        limit: 100,
        sortOrder: "ASC"
      };

      if (usernamemethod === "SELECT_USERNAME") {
        reqPayload.userId = userId;
      } else if (usernamemethod === "SEARCH_USERNAME") {
        reqPayload.reportFields = JSON.stringify(reportFields);
      }

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getCrashReport(
    indexStart,
    filter,
    deviceFilter,
    startDateTime,
    endDateTime,
    accidentTypeFilter,
    userId,
    pageSize
  ) {
    var companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = userSessionController.getLocalSessionValue(SC_DIVISION_ID);

    var reportFields = {
      filterType: filter,
      accidentType: accidentTypeFilter,
      origin: deviceFilter,
    };

    if (companyId.length > 0 && divisionId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: indexStart,
        limit: pageSize,
        type: "SPEEDO_METER_DEVICE",
        reportType: "AD_MULTI_USER_CRASH_RIDE_LIST",
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        reportFields: JSON.stringify(reportFields),
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getUserDeviceHealth(indexStart, pageSize, startDateTime, endDateTime, userFilter, filter, deviceFilter, errorFilter) {
    var companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = userSessionController.getLocalSessionValue(SC_DIVISION_ID);

    var reportFields = {
      filterType: filter,
      deviceType: deviceFilter,
      deviceActive: errorFilter,
    };

    if (companyId.length > 0 && divisionId.length > 0) {

      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        type: "USER_DEVICE_HEALTH",
        reportType: "SAD_MY_USER_DEVICE_HEALTH_REPORT",
        offset: indexStart,
        limit: pageSize,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        userId: userFilter,
        reportFields: JSON.stringify(reportFields),
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
};

export default Report;