  
export const alertDataType = {  
    START_DATA           : "START_DATA", 
    END_DATA             : "END_DATA", 
    ANIMAL_CROSSING      : "ANIMAL_CROSSING", 
    CAUTION              : "CAUTION",
    CURVE                : "CURVE",
    HILL                 : "HILL",
    HILL_DOWNWARDS       : "HILL_DOWNWARDS",
    HILL_UPWARDS         : "HILL_UPWARDS",
    ICY_CONDITIONS       : "ICY_CONDITIONS",
    INTERSECTION         : "INTERSECTION",
    LANE_MERGE           : "LANE_MERGE",
    LOW_GEAR_AREA        : "LOW_GEAR_AREA",
    MOBILE_USE           : "MOBILE_USE",
    NARROW_ROAD          : "NARROW_ROAD",
    NO_OVERTAKING        : "NO_OVERTAKING", 
    NO_OVERTAKING_TRUCKS : "NO_OVERTAKING_TRUCKS",
    OVER_SPEED           : "OVER_SPEED",
    P_CROSSING           : "PEDESTRIAN_CROSSING",
    R_CROSSING           : "RAILWAY_CROSSING",
    RISK_OF_GROUNDING    : "RISK_OF_GROUNDING",
    ROUND_ABOUT          : "ROUND_ABOUT",
    SEVERE_CORNERING     : "SEVERE_CORNERING",
    SCHOOL_ZONE          : "SCHOOL_ZONE",
    SLIPPERY_ROADS        :"SLIPPERY_ROADS",
    STOP_SIGN            : "STOP_SIGN",
    TRAFFIC_LIGHT        : "TRAFFIC_LIGHT",
    WINDING_ROAD         : "WINDING_ROAD",  
    YIELD                : "YIELD",
    SEVERE_ACCELERATION   : "SEVERE_ACCELERATION", 
    SEVERE_BRAKING       : "SEVERE_BRAKING",  
    PRIORITY             :  "PRIORITY",
    WIND                 : "WIND",
    SUDDEN_BRAKING       : "SUDDEN_BRAKING",
    FORCE_ACCELERATION   : "FORCE_ACCELERATION"
};  