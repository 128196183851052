import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import UtilDateTime from "../../util/UtilDateTime.js";
import {
  SC_COMPANY_ID,
  SC_DIVISION_ID,
  SC_USER_ID,
  SC_GROUP_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let UBIController = {
  async scoreKilometerHour(startDateTime, endDateTime, filters) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var dashboardFields = { filterType: filters };

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        dashboardType: "UD_SINGLE_USER_UBI_DASHBOARD",
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dashboardFields: JSON.stringify(dashboardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getSafetyPoint(startDateTime, endDateTime, filters) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var dashboardFields = { filterType: filters };

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: 0,
        limit: 5,
        dashboardType: "SAD_GROUP_SINGLE_CLIENT_DASHBOARD_SUMMARY",
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dashboardFields: JSON.stringify(dashboardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getDStyleBehaviorSSCMUH(startDateTime, endDateTime, filters) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var dashboardFields = { filterType: filters };

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        dashboardType: "UD_SINGLE_USER_UBI_DASHBOARD",
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dashboardFields: JSON.stringify(dashboardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getACategorySDistribution(startDateTime, endDateTime, filters) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var dashboardFields = { filterType: filters };

    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        groupId: groupId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        dashboardType: "UD_SINGLE_USER_UBI_DASHBOARD",
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dashboardFields: JSON.stringify(dashboardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
};

export default UBIController;
