import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import MyRideIcon from "../../assets/images/MyRideIcon.svg";
import ActiveMyRideIcon from "../../assets/images/ActiveMyRideIcon.svg";

import { SC_FCM_NOTIFICATION_COUNT , SC_FCM_VEHICLECRASHES_COUNT } from "../../constant/StorageConstant";

import Bell from "../../assets/images/bell.svg";
import ActiveBell from "../../assets/images/bellBlue.svg";

import CarCrashBlue from "../../assets/images/carCrashBlue.svg";
import CarCrash from "../../assets/images/carCrash.svg";

import ActiveDashboardIcon from "../../assets/images/ActiveDashboardIcon.svg";
import DashboardIcon from "../../assets/images/DashboardIcon.svg";

import UBIActiveDashboardIcon from "../../assets/images/UBIActiveDashboardIcon.svg";
import UBIDashboardIcon from "../../assets/images/UBIDashboardIcon.svg";

import NewUBIActiveDashboardIcon from "../../assets/images/UBIActiveDashboardIcon.svg";
import NewUBIDashboardIcon from "../../assets/images/UBIDashboardIcon.svg";


import ActiveSettingIcon from "../../assets/images/ActiveSettingIcon.svg";
import SettingIcon from "../../assets/images/SettingIcon.svg";
import classNames from "classnames";

import Badge from '@mui/material/Badge';



const Navbar = (props) => {
  const [app, setApp] = useState(false);
  // const [ubiApp, setUbiApp] = useState(false);
  const [previouspath, setPreviouspath] = useState(null);
  const { pathname } = useLocation();
  const dropdownRef = useRef(null); 
  // const dropdownUbiRef = useRef(null);

  const notificationValue = localStorage.getItem(SC_FCM_NOTIFICATION_COUNT) != "" ? parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) : 0;
  const vehiclecrashValue = localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT) != "" ? parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) : 0;

  const toggleAppDropdown = (e) => {
    e.preventDefault();
    setApp((prevApp) => !prevApp);
  };

  // const toggleUbiAppDropdown = (e) => {
  //   e.preventDefault();
  //   setUbiApp((prevUbiApp) => !prevUbiApp);
  // };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setApp(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if(pathname != previouspath) {
      if(previouspath == '/notifications') {
        if(parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) > 0) {
          localStorage.setItem(SC_FCM_NOTIFICATION_COUNT,"0");
        }
      } else if (previouspath == '/vehicleCrashList') {
        if(parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) > 0) {
          localStorage.setItem(SC_FCM_VEHICLECRASHES_COUNT,"0");
        }
      }
    }
    setPreviouspath(pathname);
  },[pathname]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownUbiRef.current && !dropdownUbiRef.current.contains(event.target)) {
  //       setApp(false);
  //     }
  //   };

  //   // Add event listener
  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     // Remove event listener on cleanup
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownUbiRef]);


  return (
    <React.Fragment>
      <div className="topnav cardHeader">

        <div className="container-fluid ps-0">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content" >
              <ul className="navbar-nav">

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none  p-0 m-3 ms-0" to="/dashboard" style={{ display: "flex", alignItems: "center" }} >
                    {(pathname === "/dashboard" || pathname === "/dashboard/RiskAlertSummary" || pathname === "/dashboard/CategorySummary") ? <img src={ActiveDashboardIcon} alt="ActiveDashboardIcon" height={30} width={30} /> : <img src={DashboardIcon} alt="DashboardIcon" height={30} width={30} />}
                    <span style={{ color: (pathname === "/dashboard" || pathname === "/dashboard/RiskAlertSummary" || pathname === "/dashboard/CategorySummary") ? "#5156be" : "#313533", paddingLeft: "4px", }}>{props.t("Dashboard")}</span>
                  </Link>
                </li>


                <li className="nav-item dropdown" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                  <Link className="nav-link dropdown-toggle arrow-none  p-0" to="/vehicleCrashListUser" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                    {pathname == "/vehicleCrashListUser" ? (
                        <>
                        { vehiclecrashValue > 0 ?(
                          <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                            <img src={CarCrashBlue} alt="crashride" height={30} width={30} />
                          </Badge>
                        ) : (
                          <img src={CarCrashBlue} alt="crashride" height={30} width={30} />
                        )
                        }
                      </>
                      ) : (
                        <>
                          { vehiclecrashValue > 0 ?(
                            <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                              <img src={CarCrash} alt="crashride" height={30} width={30} />
                            </Badge>
                          ) : (
                            <img src={CarCrash} alt="crashride" height={30} width={30} />
                          )
                          }
                        </>
                      )}
                     
                      <span style={{ paddingLeft: "4px", color: pathname === "/vehicleCrashListUser" ? "#5156be" : "#313533" }} > {props.t("Vehicle Crashes")} </span>
                     
                     
                    </div>
                    <p style={{color: "#313533",fontSize:"6px",padding:"0px",margin:"0px" ,display: "flex", alignItems: "center", justifyContent: "center" ,marginTop:"-5px",marginBottom:"0px",marginLeft:"19px"}} > {props.t(`(V2 - Multi Crash Ride Detail)`)} </p>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none  p-0 m-3 ms-0" to="/vehicleCrashList" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                    {pathname == "/vehicleCrashList" ? (
                        <>
                        { vehiclecrashValue > 0 ?(
                          <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                            <img src={CarCrashBlue} alt="crashride" height={30} width={30} />
                          </Badge>
                        ) : (
                          <img src={CarCrashBlue} alt="crashride" height={30} width={30} />
                        )
                        }
                      </>
                      ) : (
                        <>
                          { vehiclecrashValue > 0 ?(
                            <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                              <img src={CarCrash} alt="crashride" height={30} width={30} />
                            </Badge>
                          ) : (
                            <img src={CarCrash} alt="crashride" height={30} width={30} />
                          )
                          }
                        </>
                      )}
                      
                      <span style={{ paddingLeft: "4px", color: pathname === "/vehicleCrashList" ? "#5156be" : "#313533" }} > {props.t("Vehicle Crashes")} </span>
                     
                      
                    </div>
                    <p style={{color: "#313533",fontSize:"6px",padding:"0px",margin:"0px" ,display: "flex", alignItems: "center", justifyContent: "center" ,marginTop:"-5px",marginBottom:"0px",marginLeft:"19px"}} >{props.t(`(V1 - Single Crash Ride Detail)`)} </p>
                  </Link>
                </li>  

              </ul>
            </Collapse>
          </nav>
        </div>

      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
