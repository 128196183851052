import React, { useEffect, useState } from "react";
import RuduLogo from "../../../assets/images/logo/motivAiLogoPng.png";
import UtilDateTime from "../../../util/UtilDateTime";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Rect,
  Svg,
  Line,
  Circle,
  Tspan,
} from "@react-pdf/renderer";
import LoaderPdf from "../../Common/loaderPdf";
import {
  SC_COMPANY_NAME,
  SC_DIVISION_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_NAME,
  SC_REGISTRATION_DATE,
  SC_USERNAME,
  SC_USER_FIRST_NAME,
  SC_USER_LOGIN_TYPE,
} from "../../../constant/StorageConstant";
import ValueFormat from "../../../util/ValueFormat";

const fontBold = {
  fontFamily: "Helvetica-Bold",
};
const fontItalic = {
  fontFamily: "Times-BoldItalic",
};

const footer = [
  { f: "https://motivai.tech" },
  { f: "https://app.motivai.tech" },
  { f: '"Drive Safe, Arrive Safe"' },
  { f: "A MotivAI Product" },
];

const styles = StyleSheet.create({
  section: {
    margin: 5,
    paddingHorizontal: 25,
    flexGrow: 1,
  },
  page: {
    paddingLeft: 30,
    paddingRight: 30,
    width: "100%",
  },
  table: {
    display: "table",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    height: "30px",
  },
  cell: {
    margin: 5,
    fontSize: "9px",
  },
  PDFViewer: {
    height: "96%",
    width: "100%",
    marginTop: "20px",
    marginBottom: "5px",
  },
  Document: {
    marginTop: "15px",
    marginBottom: "5px",
  },
  tablestyle: {
    border: "1px solid black",
    borderTop: "none",
    borderRight: "none",
    padding: "5px",
    fontSize: "10px",
    textAlign: "center",
  },
  imgAbbr: {
    width: 12,
    height: 12,
    marginRight: 1,
    marginLeft: 1,
    marginBottom: "3px",
  },
  page2content: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    paddingHorizontal: 30,
  },
});

export const UdOverAllSummaryPdf = ({
  UDMainDashboardSafetyTableData,
  yearlyDatas,
  UDMainDashboardTopRowItemsTemp,
  USRideBoxCount,
  overallDataProps,
  getSelectedYearData,
  lastOverAllSyData,
  totalSafeRidePer,
  aggregateData,
  filtersData,
  totalDays,
}) => {
  const [showLoader, setShowLoader] = useState(1);
  const [companyName, setCompanyName] = useState(
    localStorage.getItem(SC_COMPANY_NAME)
  );
  const [unitName, setUnitName] = useState(
    localStorage.getItem(SC_DIVISION_NAME)
  );
  const firstName =
    location.pathname === "/dashboard"
      ? localStorage.getItem(SC_USER_FIRST_NAME)
      : localStorage.getItem(SC_LOGIN_USER_FIRST_NAME);
  const [UDMainDashboardData, setUDMainDashboardData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const fontBold = {
    fontFamily: "Helvetica-Bold",
  };
  // console.log(yearlyDatas, "ss");

  const createdDate = localStorage.getItem(SC_REGISTRATION_DATE);
  const reversedDateFormat = UtilDateTime.getCurrentDay();

  const header = [
    { title: "App Name", name: "Motiv AI" },
    { title: "Customer Name", name: companyName },
    { title: "Date", name: reversedDateFormat },
    { title: "First Name", name: firstName },
  ];

  let period = "";
  let startDate = "";
  let endDate = "";
  if(filtersData.durationFilter === "CURRENT_YEAR"){
    period = ValueFormat.smallLettersFormat(filtersData.durationFilter)
    startDate = UtilDateTime.getCurrentYear(filtersData.startDateTime)
    endDate = ""
  }else if(filtersData.durationFilter === "CURRENT_MONTH"){
    period = ValueFormat.smallLettersFormat(filtersData.durationFilter)
    startDate = UtilDateTime.getCurrentMonth(filtersData.startDateTime)
    endDate = ""
  }else if(filtersData.durationFilter === "CURRENT_WEEK"){
    period = ValueFormat.smallLettersFormat(filtersData.durationFilter)
    startDate = UtilDateTime.getCurrentWeek1(filtersData.startDateTime)
    endDate = ""
  }else if(filtersData.durationFilter === "CURRENT_DAY"){
    period = ValueFormat.smallLettersFormat(filtersData.durationFilter)
    startDate = UtilDateTime.getCurrentDay(filtersData.startDateTime)
    endDate = ""
  }else{
    period = ValueFormat.smallLettersFormat(filtersData.durationFilter)
    startDate = filtersData.startDateTime.split(' ')[0]
    endDate = filtersData.endDateTime.split(' ')[0]
  }

  const tableHeaders = [
    "Duration",
    "Ride Count",
    "Distance (Kms)",
    "Duration (Hrs)",
    "Risk Alerts",
    "Other Alerts",
  ];

  const overallData = yearlyDatas[0] || {};
  const cyData = yearlyDatas[1] || {};
  const cmData = yearlyDatas[2] || {};
  const cwData = yearlyDatas[3] || {};
  const cdData = yearlyDatas[4] || {};

  const generateCategoryData = (title, data) => [
    title,
    data.totalRides || 0,
    data.totalKiloMeters || 0,
    data.totalHours || 0,
    data.totalRiskAlerts || 0,
    data.totalManualAlerts || 0,
  ];

  const tableData = {
    headers: tableHeaders.map((title) => ({ title })),
    overall: generateCategoryData("Overall", overallData),
    currentYear: generateCategoryData("Current Year", cyData),
    currentMonth: generateCategoryData("Current Month", cmData),
    currentWeek: generateCategoryData("Current Week", cwData),
    currentDay: generateCategoryData("Current Day", cdData),
  };

  const maxDataListKeysLengthActivitySummary = 5;
  const maxDataListKeysLength = 6;

  const widget1 = () => {
    const dataByType = [];
    // console.log(
    //   "UDMainDashboardTopRowItemsTemp",
    //   UDMainDashboardTopRowItemsTemp
    // );
    UDMainDashboardTopRowItemsTemp.forEach((item) => {
      const {
        type,
        currentYearValue,
        currentMonthValue,
        currentWeekValue,
        currentDayValue,
        overallData,
      } = item;

      dataByType.push([
        overallData,
        currentYearValue,
        currentMonthValue,
        currentWeekValue,
        currentDayValue,
      ]);
    });

    setUDMainDashboardData(dataByType);
  };

  // Zone Summary
  const maxValW2 = USRideBoxCount.totalRides;

  // console.log("usrideboxcount", USRideBoxCount);

  const categoryData = () => {
    return USRideBoxCount.count.map((item, index) => {
      return { total: item.totalRides, percentage: item.percentage };
    });
  };
  const categoryCircle = categoryData();

  const modifiedWidget2 = () => {
    return categoryCircle.map((item) => {
      // Create a new object to avoid modifying the original data
      const sItem = { ...item };

      // Apply your conditions to each item
      if (sItem.total > 0) {
        if (sItem.total < maxValW2) {
          sItem.total = ((sItem.total / maxValW2) * 230).toFixed(1);
        } else {
          sItem.total = 230;
        }
      } else {
        sItem.total = 0;
      }

      return sItem;
    });
  };

  const w2 = modifiedWidget2();

  // overall driving score
  const safetyCategoryData = [];
  const safetyCategoryDataCY = [];
  const safetyCategoryDataSY = [];

  const safetyData = overallDataProps.map((item) => item.score);
  const safetyDataSY = lastOverAllSyData.map((item) => item.lastScore);

  const maxSafetyCategoryHeight = 0;
  const minSafetyCategoryHeight = -139.5;
  const safetyCategoryScalingFactor =
    Math.abs(minSafetyCategoryHeight) / (maxSafetyCategoryHeight - 110);

  const elementWidth = 10;
  const gap = 35;
  const initialX = 37;
  const initialXX = 27;
  const textYOffset = 10;

  safetyData.forEach((value, index) => {
    const x = initialX + index * (elementWidth + gap);
    const textX = x + 7;
    const textY = 139.5 - value * safetyCategoryScalingFactor + textYOffset;

    safetyCategoryData.push({
      x,
      y: 139.5,
      color: value > 92 ? "rgb(25, 84, 62)" : value > 80 ? "#DEA602" : "red",
      width: elementWidth,
      height: value * safetyCategoryScalingFactor,
      textX,
      textY,
      text: value,
    });
  });

  safetyData.slice(2, -1).map((value, index) => {
    const x = initialX + index * (elementWidth + gap);
    const textX = x + 7;
    const textY = 139.5 - value * safetyCategoryScalingFactor + textYOffset;
    safetyCategoryDataCY.push({
      x,
      y: 139.5,
      color:
        totalSafeRidePer > 92
          ? "rgb(25, 84, 62)"
          : totalSafeRidePer > 80
          ? "#DEA602"
          : "red",
      width: elementWidth,
      height: -totalSafeRidePer === 0 ? 0 : -totalSafeRidePer - 16,
      textX,
      textY,
      text: totalSafeRidePer,
    });
  });

  safetyDataSY.map((value, index) => {
    const x = initialX + index * (elementWidth + gap);
    const textX = x + 7;
    const textY = 139.5 - value * safetyCategoryScalingFactor + textYOffset;
    safetyCategoryDataSY.push({
      x,
      y: 139.5,
      color: value > 92 ? "rgb(25, 84, 62)" : value > 80 ? "#DEA602" : "red",
      width: elementWidth,
      height: value * safetyCategoryScalingFactor,
      textX,
      textY,
      text: value,
    });
  });
  var selectedYear =
    new Date(getSelectedYearData).getFullYear() ||
    UtilDateTime.getCurrentYear();
  const titleHead = ["Units", "C1", "C2", "C3", "C4", "C5", "C6"];

  const titleHead1 = [
    "Total Days",
    "Total Rides",
    "Total Distance",
    "Total Duration",
    "High Risk",
    "Medium Risk",
  ];

  const T1 = {
    title: titleHead.map((title) => ({ title })),
    totalKm: [
      "Kilometers",
      ...(USRideBoxCount.count || []).map((item) =>
        item && !isNaN(item.totalKm) ? Math.round(item.totalKm) : 0
      ),
    ],
    totalTt: [
      "Minutes",
      ...(USRideBoxCount.count || []).map((item) =>
        item && !isNaN(item.totalTravelTime)
          ? Math.round(item.totalTravelTime)
          : 0
      ),
    ],
  };

  const T2 = {
    title: titleHead1.map((title) => ({ title })),
    data: [
      totalDays || 0,
      aggregateData.totalRides || 0,
      aggregateData.totalKilometer
        ? aggregateData.totalKilometer.toFixed(2) + " kms"
        : 0 + " kms",
      aggregateData.totalTravelTime
        ? aggregateData.totalTravelTime.toFixed(2) + " hrs"
        : 0 + " hrs",
      aggregateData.totalHighRiskCount || 0,
      aggregateData.totalMediumRiskCount || 0,
    ],
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(0);
    }, 4000);
    widget1();
  }, []);

  var totalPages = 0;

  const watermarkText = "Motiv AI";
  const watermarkStyles = StyleSheet.create({
    watermark: {
      position: "absolute",
      fontSize: 95,
      opacity: 0.3,
      color: "gray",
      transform: "rotate(-52deg)",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      top: "42%",
      left: "22%",
    },
  });

  return (
    <React.Fragment>
      {showLoader > 0 ? <LoaderPdf /> : ""}
      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
        fileName="myPdf.pdf"
      >
        <Document
          fileName="myPdf.pdf"
          style={{ marginTop: "15px", marginBottom: "5px" }}
        >
          <Page pageNumber={pageNumber} style={styles.page}>
            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>
            <View style={{ height: "5.3%" }}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                {/* <View style={{ width: "77%", marginBottom: "5px"}}> */}

                <View
                  style={{
                    marginTop: "5px",
                    width: "100%",
                    flexDirection: "row",
                    // justifyContent: "space-between",
                  }}
                >
                  <Image
                    src={RuduLogo}
                    style={{
                      height: "21px",
                      width: "100px",
                      marginTop: "2px",
                      marginBottom: "5px",
                      marginLeft: "31px",
                    }}
                  />
                  <View
                    style={{
                      flexDirection: "Row",
                      marginBottom: "5px",
                      marginLeft: "30px",
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Text style={[fontBold, { fontSize: 11 }]}>
                        {header[1].title}:
                      </Text>
                      <Text style={{ fontSize: 11, marginLeft: "3px" }}>
                        {header[1].name}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "2px",
                          width: "60%",
                        }}
                      >
                        <Text
                          style={[fontBold, { fontSize: 11, width: "85px" }]}
                        >
                          {header[3].title}:
                        </Text>
                        <Text style={{ fontSize: 11, width: "100%" }}>
                          {header[3].name}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "2px",
                          marginLeft: "42px",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[fontBold, { fontSize: 11 }]}>
                            {header[2].title}:{" "}
                          </Text>
                          <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                        </View>
                      </View>
                    </View>

                    {/* <View style={{flexDirection:"row"}}>
                       <Text
                   style={[fontBold, { fontSize: 11 }]}
                  >
                    {header[0].title}: 
                  </Text>
                  <Text
                   style={{fontSize: 11,marginLeft:"3px" }}
                  >
                    {header[0].name}
                  </Text>
                        
                       </View> */}

                    {/* <View style={{flexDirection:"row",marginTop:"2px"}}>
                       <Text style={[fontBold, { fontSize: 11 }]}>
                        {header[2].title}: </Text>
                        <Text style={{ fontSize: 11,marginLeft:"1px"}}>
                        {header[2].name}</Text>

                       </View> */}
                  </View>

                  {/* <View
                      style={{
                        flexDirection:"column",
                        marginBottom: "5px",
                        marginRight: 30,
                        
                      }}
                    >
                     
                      <View style={{flexDirection:"row",marginTop:"2px"}}>
                      <Text style={[fontBold, { fontSize: 11 }]}>
                        {header[3].title}: 
                        </Text>
                        <Text style={{ fontSize: 11,marginLeft:"3px" }}>
                        {header[3].name}
                        </Text>

                      </View>
                 
                  
                        </View> */}
                </View>

                {/* </View> */}
              </View>
            </View>

            <View style={{ marginTop: "5px", width: "100%", flexDirection:"column"}}>
              <Text
                style={[
                  fontBold,
                  {
                    fontSize: "12px",
                    margin: "3px",
                    textAlign: "center",
                    color: "#003cb3",
                    textDecoration: "underline",
                  },
                ]}
              >
                User Dashboard 
              </Text>
              <Text style={{color:"black", fontSize: "11px", textDecoration:"none", fontWeight: 600, textAlign: "center", marginTop: 5}}>This report is generated for the period {period} {endDate === "" ? `(${startDate})` : `(Between ${startDate} and ${endDate})`}</Text>
              {/* <Text style={
                  {
                    fontSize: "12px",
                    margin: "3px",
                    textAlign: "center",
                    color: "#000",
                  }}>Period: {ValueFormat.capitalizeFirstLetterAndFormat(filtersData.durationFilter)}</Text> */}
            </View>

            {/* <Text style={{ ...watermarkStyles.watermark }}>
            {watermarkText}
          </Text> */}

            {/* Header */}
            <View
              style={{
                flexDirection: "row",
                marginTop: 5,
                marginBottom: 5,
                fontSize: "11px",
              }}
            >
              <Text style={[fontBold, { fontSize: 11 }]}>
                1. My Activity Summary
              </Text>
            </View>
            {/* Table View */}
            <View
              style={{
                marginTop: 15,
                marginBottom: 15,
                flexDirection: "column", // Set flexDirection to row
                justifyContent: "center",
              }}
            >
              <View style={{ flexDirection: "row" }}>
                {tableData.headers.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      alignItems: "center",
                      fontSize: "9px",
                      border: "1px solid black",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                    }}
                    key={index}
                  >
                    <Text style={[fontBold, { textAlign: "center" }]} break>
                      {item.title}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {tableData.overall.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      fontSize: 9, // removed quotes around 9px
                      border: "1px solid black",
                      borderTop: "none",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      textAlign: index === 0 ? "left" : "right",
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        padding:
                          index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                      }}
                      break
                    >
                      {item} {/* Assuming T1.totalKm is an array of numbers */}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {tableData.currentYear.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      fontSize: 9, // removed quotes around 9px
                      border: "1px solid black",
                      borderTop: "none",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      textAlign: index === 0 ? "left" : "right",
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        padding:
                          index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                      }}
                      break
                    >
                      {item} {/* Assuming T1.totalKm is an array of numbers */}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {tableData.currentMonth.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      fontSize: 9, // removed quotes around 9px
                      border: "1px solid black",
                      borderTop: "none",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      textAlign: index === 0 ? "left" : "right",
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        padding:
                          index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                      }}
                      break
                    >
                      {item} {/* Assuming T1.totalKm is an array of numbers */}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {tableData.currentWeek.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      fontSize: 9, // removed quotes around 9px
                      border: "1px solid black",
                      borderTop: "none",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      textAlign: index === 0 ? "left" : "right",
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        padding:
                          index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                      }}
                      break
                    >
                      {item} {/* Assuming T1.totalKm is an array of numbers */}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {tableData.currentDay.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      fontSize: 9, // removed quotes around 9px
                      border: "1px solid black",
                      borderTop: "none",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      textAlign: index === 0 ? "left" : "right",
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        padding:
                          index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                      }}
                      break
                    >
                      {item} {/* Assuming T1.totalKm is an array of numbers */}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            {/* Header */}
            <View
              style={{
                flexDirection: "row",
                marginTop: 5,
                marginBottom: 5,
                fontSize: "11px",
              }}
            >
              <Text style={[fontBold, { fontSize: 11 }]}>
                2. Driver Performance Overview
              </Text>
            </View>
            {/* Table View */}
            <View
              style={{
                flexDirection: "row",
                height: "130px",
                marginTop: "5px",
                marginBottom: "30px",
                border: "1px solid black",
                borderBottom: "none",
              }}
            >
              {[
                "Driver Category",
                "Ride Count",
                "Distance (Kms)",
                "Duration (Hrs)",
                "Risk Alerts",
                "Other Alerts",
              ].map((header, columnIndex) => (
                <View
                  key={columnIndex}
                  style={{
                    flexDirection: "column",
                    flex: 1 / UDMainDashboardSafetyTableData.length,
                  }}
                >
                  <Text
                    style={[
                      fontBold,
                      {
                        border: "1px solid black",
                        padding: "5px",
                        fontSize: "9px",
                        textAlign: "center",
                        borderTop: "none",
                        borderRight:
                          columnIndex !==
                          UDMainDashboardSafetyTableData.length + 1
                            ? "none"
                            : "1px solid black",
                        borderLeft:
                          columnIndex === 0 ? "none" : "1px solid black",
                      },
                    ]}
                  >
                    {header}
                  </Text>
                  {Array.from({ length: maxDataListKeysLength }).map(
                    (_, rowIndex) => (
                      <Text
                        key={rowIndex}
                        style={[
                          styles.tablestyle,
                          {
                            borderBottom:
                              rowIndex === maxDataListKeysLength - 1 &&
                              maxDataListKeysLength > 1
                                ? "1px solid black"
                                : "none",
                            fontSize: "9px",
                            borderRight:
                              columnIndex !==
                              UDMainDashboardSafetyTableData.length + 1
                                ? "none"
                                : "1px solid black",
                            borderLeft:
                              columnIndex === 0 ? "none" : "1px solid black",
                            textAlign: columnIndex === 0 ? "left" : "right",
                            paddingRight: columnIndex === 0 ? "none" : "15px",
                          },
                        ]}
                      >
                        {columnIndex === 0
                          ? [
                              "Safe",
                              "Sportive",
                              "Quite Safe",
                              "Reckless",
                              "Quite Dangerous",
                              "Dangerous",
                            ][rowIndex]
                          : columnIndex === 2 || columnIndex === 3 // Check if column is "Distance" or "Duration"
                          ? parseFloat(
                              UDMainDashboardSafetyTableData[columnIndex - 1]
                                ?.dataListValues[rowIndex] || "0"
                            ).toFixed(2) // Format to 2 decimal places
                          : UDMainDashboardSafetyTableData[columnIndex - 1]
                              ?.dataListValues[rowIndex] || "0"}
                      </Text>
                    )
                  )}
                </View>
              ))}
            </View>

            <View
              style={{
                flexDirection: "row",
                marginTop: -10,
                marginBottom: 0,
                fontSize: "11px",
              }}
            >
              <Text style={[fontBold, { fontSize: 11 }]}>3. Zone Summary</Text>
            </View>
            <View
              style={{
                marginTop: 5,
                marginBottom: 0,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  width: "100%",
                  marginTop: 15,
                  marginRight: 30,
                  marginBottom: 5,
                  height: "90px",
                }}
              >
                <Text style={[fontBold, { fontSize: 10.5 }]}>
                  3.1. Green Zone
                </Text>
                <Svg width="100%" height="80" style={{ marginTop: 5 }}>
                  <Text x="0" y="27" style={{ fontSize: "10px" }}>
                    {"(C1) Safe Category" +
                      " (" +
                      (categoryCircle.length > 0 &&
                        `${categoryCircle[0].total} / ${USRideBoxCount.totalRides}`) +
                      ")"}
                  </Text>

                  <Rect
                    x="0"
                    y="40"
                    rx="5"
                    ry="5"
                    width="230"
                    height="25"
                    fill="#e6e6e6"
                  />
                  <Rect
                    x="0"
                    y="40"
                    rx="5"
                    ry="5"
                    width={w2[0].total}
                    height="25"
                    fill="#2ab57d"
                  />
                  <Text
                    x={115}
                    y={57}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{ fontSize: "10px" }}
                  >
                    {categoryCircle[0].percentage + "%"}
                  </Text>
                  <Text x="305" y="27" style={{ fontSize: "10px" }}>
                    {"(C2) Sportive Category" +
                      " (" +
                      (categoryCircle.length > 0 &&
                        `${categoryCircle[1].total} / ${USRideBoxCount.totalRides}`) +
                      ")"}
                  </Text>
                  <Rect
                    x="305"
                    y="40"
                    rx="5"
                    ry="5"
                    width="230"
                    height="25"
                    fill="#e6e6e6"
                  />
                  <Rect
                    x="305"
                    y="40"
                    rx="5"
                    ry="5"
                    width={w2[1].total}
                    height="25"
                    fill="#2ab57d"
                  />
                  <Text
                    x={422}
                    y={57}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{ fontSize: "10px" }}
                  >
                    {categoryCircle[1].percentage + "%"}
                  </Text>
                </Svg>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  width: "100%",
                  marginTop: 5,
                  marginRight: 30,
                  marginBottom: 5,
                  height: "90px",
                }}
              >
                <Text style={[fontBold, { fontSize: 10.5 }]}>
                  3.2. Amber Zone
                </Text>
                <Svg width="100%" height="80" style={{ marginTop: 5 }}>
                  <Text x="0" y="27" style={{ fontSize: "10px" }}>
                    {"(C3) QuiteSafe Category" +
                      " (" +
                      (categoryCircle.length > 0 &&
                        `${categoryCircle[2].total} / ${USRideBoxCount.totalRides}`) +
                      ")"}
                  </Text>

                  <Rect
                    x="0"
                    y="40"
                    rx="5"
                    ry="5"
                    width="230"
                    height="25"
                    fill="#e6e6e6"
                  />
                  <Rect
                    x="0"
                    y="40"
                    rx="5"
                    ry="5"
                    width={w2[2].total}
                    height="25"
                    fill="#ffbf53"
                  />
                  <Text
                    x={115}
                    y={57}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{ fontSize: "10px" }}
                  >
                    {categoryCircle[2].percentage + "%"}
                  </Text>
                  <Text x="305" y="27" style={{ fontSize: "10px" }}>
                    {"(C4) Reckless Category" +
                      " (" +
                      (categoryCircle.length > 0 &&
                        `${categoryCircle[3].total} / ${USRideBoxCount.totalRides}`) +
                      ")"}
                  </Text>
                  <Rect
                    x="305"
                    y="40"
                    rx="5"
                    ry="5"
                    width="230"
                    height="25"
                    fill="#e6e6e6"
                  />
                  <Rect
                    x="305"
                    y="40"
                    rx="5"
                    ry="5"
                    width={w2[3].total}
                    height="25"
                    fill="#ffbf53"
                  />
                  <Text
                    x={422}
                    y={57}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{ fontSize: "10px" }}
                  >
                    {categoryCircle[3].percentage + "%"}
                  </Text>
                </Svg>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  width: "100%",
                  marginTop: 5,
                  marginRight: 30,
                  marginBottom: 5,
                  height: "90px",
                }}
              >
                <Text style={[fontBold, { fontSize: 10.5 }]}>
                  3.3. Red Zone
                </Text>
                <Svg width="100%" height="160" style={{ marginTop: 5 }}>
                  <Text x="0" y="27" style={{ fontSize: "10px" }}>
                    {"(C5) QuiteDangerous Category" +
                      " (" +
                      (categoryCircle.length > 0 &&
                        `${categoryCircle[4].total} / ${USRideBoxCount.totalRides}`) +
                      ")"}
                  </Text>

                  <Rect
                    x="0"
                    y="40"
                    rx="5"
                    ry="5"
                    width="230"
                    height="25"
                    fill="#e6e6e6"
                  />
                  <Rect
                    x="0"
                    y="40"
                    rx="5"
                    ry="5"
                    width={w2[4].total}
                    height="25"
                    fill="#fd625e"
                  />
                  <Text
                    x={115}
                    y={57}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{ fontSize: "10px" }}
                  >
                    {categoryCircle[4].percentage + "%"}
                  </Text>
                  <Text x="305" y="27" style={{ fontSize: "10px" }}>
                    {"(C6) Dangerous Category" +
                      " (" +
                      (categoryCircle.length > 0 &&
                        `${categoryCircle[5].total} / ${USRideBoxCount.totalRides}`) +
                      ")"}
                  </Text>
                  <Rect
                    x="305"
                    y="40"
                    rx="5"
                    ry="5"
                    width="230"
                    height="25"
                    fill="#e6e6e6"
                  />
                  <Rect
                    x="305"
                    y="40"
                    rx="5"
                    ry="5"
                    width={w2[5].total}
                    height="25"
                    fill="#fd625e"
                  />
                  <Text
                    x={422}
                    y={57}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{ fontSize: "10px" }}
                  >
                    {categoryCircle[5].percentage + "%"}
                  </Text>
                </Svg>
              </View>
            </View>
            <View
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "44.5px",
                marginLeft: 30,
                marginRight: 30,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",

                  bottom: "0px",
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>
                    Page {pageNumber + 0} of 2
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
          <Page pageNumber={pageNumber} style={styles.page}>
            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>
            <View style={{ height: "5.3%" }}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                {/* <View style={{ width: "77%", marginBottom: "5px"}}> */}

                <View
                  style={{
                    marginTop: "5px",
                    width: "100%",
                    flexDirection: "row",
                    // justifyContent: "space-between",
                  }}
                >
                  <Image
                    src={RuduLogo}
                    style={{
                      height: "21px",
                      width: "100px",
                      marginTop: "2px",
                      marginBottom: "5px",
                      marginLeft: "31px",
                    }}
                  />
                  <View
                    style={{
                      flexDirection: "Row",
                      marginBottom: "5px",
                      marginLeft: "30px",
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Text style={[fontBold, { fontSize: 11 }]}>
                        {header[1].title}:
                      </Text>
                      <Text style={{ fontSize: 11, marginLeft: "3px" }}>
                        {header[1].name}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "2px",
                          width: "60%",
                        }}
                      >
                        <Text
                          style={[fontBold, { fontSize: 11, width: "85px" }]}
                        >
                          {header[3].title}:
                        </Text>
                        <Text style={{ fontSize: 11, width: "100%" }}>
                          {header[3].name}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "2px",
                          marginLeft: "42px",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[fontBold, { fontSize: 11 }]}>
                            {header[2].title}:{" "}
                          </Text>
                          <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                        </View>
                      </View>
                    </View>

                    {/* <View style={{flexDirection:"row"}}>
                       <Text
                   style={[fontBold, { fontSize: 11 }]}
                  >
                    {header[0].title}: 
                  </Text>
                  <Text
                   style={{fontSize: 11,marginLeft:"3px" }}
                  >
                    {header[0].name}
                  </Text>
                        
                       </View> */}

                    {/* <View style={{flexDirection:"row",marginTop:"2px"}}>
                       <Text style={[fontBold, { fontSize: 11 }]}>
                        {header[2].title}: </Text>
                        <Text style={{ fontSize: 11,marginLeft:"1px"}}>
                        {header[2].name}</Text>

                       </View> */}
                  </View>

                  {/* <View
                      style={{
                        flexDirection:"column",
                        marginBottom: "5px",
                        marginRight: 30,
                        
                      }}
                    >
                     
                      <View style={{flexDirection:"row",marginTop:"2px"}}>
                      <Text style={[fontBold, { fontSize: 11 }]}>
                        {header[3].title}: 
                        </Text>
                        <Text style={{ fontSize: 11,marginLeft:"3px" }}>
                        {header[3].name}
                        </Text>

                      </View>
                 
                  
                        </View> */}
                </View>

                {/* </View> */}
              </View>
            </View>

            <View
              style={{
                marginTop: 15,
                marginBottom: 15,
                flexDirection: "column", // Set flexDirection to row
                justifyContent: "center",
              }}
            >
              <View style={{ flexDirection: "row" }}>
                {T1.title.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      alignItems: "center",
                      fontSize: "9px",
                      border: "1px solid black",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                    }}
                    key={index}
                  >
                    <Text style={[fontBold, { textAlign: "center" }]} break>
                      {item.title}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {T1.totalKm.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      fontSize: 9, // removed quotes around 9px
                      border: "1px solid black",
                      borderTop: "none",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      textAlign: index === 0 ? "left" : "right",
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        padding:
                          index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                      }}
                      break
                    >
                      {item} {/* Assuming T1.totalKm is an array of numbers */}
                    </Text>
                  </View>
                ))}
              </View>

              {/* TotalTt row */}
              <View style={{ flexDirection: "row" }}>
                {T1.totalTt.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      fontSize: 9, // removed quotes around 9px
                      border: "1px solid black",
                      borderTop: "none",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      textAlign: index === 0 ? "left" : "right",
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        padding:
                          index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                      }}
                      break
                    >
                      {item} {/* Assuming T1.totalKm is an array of numbers */}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            <View
              style={{
                marginTop: 15,
                marginBottom: 15,
                flexDirection: "column", // Set flexDirection to row
                justifyContent: "center",
              }}
            >
              <View style={{ flexDirection: "row" }}>
                {T2.title.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      alignItems: "center",
                      fontSize: "9px",
                      border: "1px solid black",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                    }}
                    key={index}
                  >
                    <Text style={[fontBold, { textAlign: "center" }]} break>
                      {item.title}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {T2.data.map((item, index) => (
                  <View
                    style={{
                      flex: 2,
                      justifyContent: "center",
                      padding: 5,
                      fontSize: 9, // removed quotes around 9px
                      border: "1px solid black",
                      borderTop: "none",
                      borderLeft: index === 0 ? "1px solid black" : "none",
                      textAlign: index === 0 ? "left" : "right",
                    }}
                    key={index}
                  >
                    <Text
                      style={{
                        padding:
                          index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                      }}
                      break
                    >
                      {item} {/* Assuming T1.totalKm is an array of numbers */}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            {/* <View style={{ marginTop:"20px"}}/> */}
            <View
              style={[
                fontBold,
                {
                  marginTop: "15px",
                  fontSize: "11px",
                  width: "100%",
                  // paddingHorizontal: 30,
                  marginBottom: "10px",
                },
              ]}
            >
              <Text style={{ marginBottom: "10px" }}>
                4. Overall Driving Score
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                // paddingHorizontal: 30,
                marginBottom: "10px",
                flexDirection: "row",
                // justifyContent: "space-between",
                gap: 80,
              }}
            >
              <View
                style={[
                  fontBold,
                  {
                    fontSize: "11px",
                  },
                ]}
              >
                <Text style={{ marginBottom: "10px" }}>4.1. {"All Year"}</Text>
              </View>
              <View
                style={[
                  fontBold,
                  {
                    fontSize: "11px",
                  },
                ]}
              >
                <Text style={{ marginBottom: "10px" }}>
                  4.2. {"Selected Year"}
                </Text>
              </View>
              <View
                style={[
                  fontBold,
                  {
                    fontSize: "11px",
                  },
                ]}
              >
                <Text style={{ marginBottom: "10px" }}>
                  4.3. {"Current Year"}
                </Text>
                <Text style={{ marginBottom: "10px" }}></Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Svg style={{ width: "190px" }} height={"20px"}>
                <Rect x="0" rx="2" ry="2" width="15" height="15" fill="red" />
                <Text x="20" y="10" style={{ height: "110", fontSize: "11px" }}>
                  {" "}
                  Poor
                </Text>
                <Rect
                  x="60"
                  rx="2"
                  ry="2"
                  width="15"
                  height="15"
                  fill="#DEA602"
                />
                <Text
                  x="80"
                  y="10"
                  style={{
                    height: "15",
                    fontSize: "11px",
                    marginLeft: "5px",
                  }}
                >
                  {" "}
                  Average
                </Text>
                <Rect
                  x="140"
                  rx="2"
                  ry="2"
                  width="15"
                  height="15"
                  fill="rgb(25, 84, 62)"
                />
                <Text x="160" y="10" style={{ height: "15", fontSize: "11px" }}>
                  {" "}
                  Good
                </Text>
              </Svg>
            </View>
            <View
              style={{
                width: "100%",
                height: "230.666px",
                paddingHorizontal: 30,
                marginBottom: "10px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "75%" }}>
                <View
                  style={{
                    fontSize: "10px",
                    marginBottom: "4px",
                  }}
                >
                  <Text> </Text>
                </View>
                <View style={{ height: "100%" }}>
                  <Svg>
                    <Line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="140"
                      stroke="black"
                      strokeWidth="1"
                    />
                    <Line
                      x1="0"
                      y1="140"
                      x2="67"
                      y2="140"
                      stroke="black"
                      strokeWidth="1"
                    />

                    {safetyCategoryDataCY.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Rect
                            x={item.x}
                            y={item.y}
                            fill={item.color}
                            width={item.width}
                            height={item.height}
                          />
                          <Text
                            x={item.x + 5}
                            y={item.y + item.height - 4}
                            style={{ fontSize: "10px" }}
                            textAnchor="middle"
                          >
                            {item.text + "%"}
                          </Text>
                        </React.Fragment>
                      );
                    })}
                  </Svg>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-80px",
                  }}
                >
                  <Text style={{ fontSize: "10px", marginLeft: "28px" }}>
                    {/* {UtilDateTime.getCurrentYear()} */}
                    {"All Year"}
                  </Text>
                </View>
                {/* <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop:"5px"
                }}
              >
                <Text style={{ fontSize: "7px", marginLeft: "26px" }}>
                  (All Year)
                </Text>
              </View> */}
              </View>
              <View style={{ width: "75%" }}>
                <View
                  style={{
                    fontSize: "10px",
                    marginBottom: "4px",
                  }}
                >
                  <Text> </Text>
                </View>
                <View style={{ height: "100%" }}>
                  <Svg>
                    <Line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="140"
                      stroke="black"
                      strokeWidth="1"
                    />
                    <Line
                      x1="0"
                      y1="140"
                      x2="67"
                      y2="140"
                      stroke="black"
                      strokeWidth="1"
                    />

                    {safetyCategoryDataSY.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Rect
                            x={item.x}
                            y={item.y}
                            fill={item.color}
                            width={item.width}
                            height={item.height}
                          />
                          <Text
                            x={item.x + 5}
                            y={item.y + item.height - 4}
                            style={{ fontSize: "10px" }}
                            textAnchor="middle"
                          >
                            {item.text + "%"}
                          </Text>
                        </React.Fragment>
                      );
                    })}
                  </Svg>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-80px",
                  }}
                >
                  <Text style={{ fontSize: "10px", marginLeft: "30px" }}>
                    {selectedYear}
                  </Text>
                </View>
                {/* <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop:"5px"
                }}
              >
                <Text style={{ fontSize: "7px", marginLeft: "23px" }}>
                  (Selected Year)
                </Text>
              </View> */}
              </View>
              <View style={{ width: "100%" }}>
                <View
                  style={{
                    fontSize: "10px",
                    marginBottom: "4px",
                  }}
                >
                  <Text> </Text>
                </View>
                <View style={{ height: "100%" }}>
                  <Svg>
                    <Line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="140"
                      stroke="black"
                      strokeWidth="1"
                    />
                    <Line
                      x1="0"
                      y1="140"
                      x2="227"
                      y2="140"
                      stroke="black"
                      strokeWidth="1"
                    />

                    {safetyCategoryData.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Rect
                            x={item.x}
                            y={item.y}
                            fill={item.color}
                            width={item.width}
                            height={item.height}
                          />
                          <Text
                            x={item.x + 5}
                            y={item.y + item.height - 4}
                            style={{ fontSize: "10px" }}
                            textAnchor="middle"
                          >
                            {item.text + "%"}
                          </Text>
                        </React.Fragment>
                      );
                    })}
                  </Svg>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-80px",
                  }}
                >
                  <Text style={{ fontSize: "10px", marginLeft: "0px" }}>
                    {UtilDateTime.getCurrentDay()}
                  </Text>
                  <Text style={{ fontSize: "10px", marginLeft: "10px" }}>
                    {UtilDateTime.getCurrentWeek()}
                  </Text>
                  <Text style={{ fontSize: "10px", marginLeft: "0px" }}>
                    {UtilDateTime.getCurrentMonth()}
                  </Text>
                  <Text style={{ fontSize: "10px", marginLeft: "15px" }}>
                    {UtilDateTime.getCurrentYear()}
                  </Text>
                </View>
                {/* <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop:"5px"
                }}
              >
                <Text style={{ fontSize: "7px", marginLeft: "0px" }}>
                  (Current Day)
                </Text>
                <Text style={{ fontSize: "7px", marginLeft: "10px" }}>
                  (Current Week)
                </Text>
                <Text style={{ fontSize: "7px", marginLeft: "8px" }}>
                  (Current Month)
                </Text>
                <Text style={{ fontSize: "7px", marginLeft: "10px" }}>
                  (Current Year)
                </Text>
              </View> */}
              </View>
            </View>
            {/* <View style={{ marginTop: "0px", textAlign: "center" }}>
            <Text style={{ fontSize: "9px" }}>
              Note: X-axis for Time, Y-axis for Score
            </Text>
          </View> */}
            <View
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "44.5px",
                marginLeft: 30,
                marginRight: 30,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",
                  bottom: "0px",
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>
                    Page {pageNumber + 1} of 2
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
};
