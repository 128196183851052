import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CardBody,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { Card, CardHeader } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { excelParser } from "../../../components/Parser/ExportJson.js";
import logo from "../../../assets/images/logo-sm.svg";
// import CarouselPage from './CarouselPage';
import "bootstrap/dist/css/bootstrap.min.css";
import motivLogo from "../../../assets/images/ruduicon.png";
import { CSVLink } from "react-csv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import userController from "../../../controller/User/UserController.js";
import ValueFormat from "../../../util/ValueFormat";
import {
  SC_USER_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_DASHBOARD_TYPE,
} from "../../../constant/StorageConstant";
import "../Myusers/myusersStyle.css";
import { CgCloseO } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { myUserSearch } from "../../../store/actions";
import MyRidesController from "../../../controller/Rides/MyRides.js";
import { validatePassword } from "../../../util/UtilFieldValidation.js";
import Loader from "../../../components/Common/loader.js";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const [filterValue, setFilterValue] = useState("");
  const [localFilter, setLocalFilter] = React.useState("");
  const dispatch = useDispatch();

  const searchUser = () => {
    dispatch(myUserSearch({ value: localFilter, search: true }));
  };

  return (
    <Row style={{ marginBottom: "-25px" }}>
      <Col xl={6} lg={6} md={6} sm={6} style={{ paddingTop: "10px" }}>
        <p style={{ paddigTop: "10px" }}>Local Search: </p>
      </Col>
      <Col xl={6} lg={6} md={6} sm={6} style={{ paddingTop: "10px" }}>
        <p style={{ paddigTop: "10px" }}>Global Search: </p>
      </Col>

      <Col xl={6} lg={6} md={6} sm={6} sx={6}>
        <form className=" d-none d-lg-block">
          <input
            className="form-control"
            style={{ borderColor: "black" }}
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`Local search by 'All' the local fields`}
          />
        </form>
      </Col>
      <Col xl={6} lg={6} md={6} sm={6} sx={6}>
        <form className="d-none d-lg-block">
          <div style={{ display: "flex", justifyContent: "right" }}>
            <input
              type="text"
              style={{ borderColor: "black" }}
              className="form-control"
              value={localFilter || ""}
              onChange={(e) => {
                setLocalFilter(e.target.value);
              }}
              placeholder="Global Search by 'First Name' or 'Mobile Number'"
            />
            <button
              style={{ marginLeft: "5px" }}
              className="btn btn-primary me-1"
              type="button"
              onClick={searchUser}
            >
              <i className="bx bx-search-alt align-middle"></i>
            </button>
          </div>
        </form>
      </Col>
    </Row>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function Table({ columns, data }) {

  const exportExcel = async (userId) => {
    var dataExcel = [];
    var result = await MyRidesController.myRidesListExport(userId);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;

    // console.log(
    //   "resultJSONData myRidesListExport : " + JSON.stringify(resultJSONData)
    // );

    for (let i = 0; i < resultJSONData.length; i++) {
      var drivingScore = "0",
        rideName = "N/A",
        /*status = 'N/A', origin ='N/A',*/ alerts = 0,
        createdTime = "N/A",
        /*subType = 'N/A',*/ dayPercentage = "0",
        nightPercentage = "0" /*accessLevel = 'N/A',*/,
        endTime = "N/A",
        city = "N/A",
        state = "N/A",
        country = "N/A",
        /*locationName = 'N/A',*/ zipCode = "N/A",
        anticipation = "0",
        drivingSkill = "0",
        selfConfidence = "0",
        travelTime = "-",
        totalKiloMeter = "0 Kms",
        urbanPercent = "0%",
        ruralPercent = "0%",
        highwayPercent = "0%";
      try {
        // var totalPercentage = 100;
        var rideObject = resultJSONData[i];

        rideName = rideObject.name.length > 0 ? rideObject.name : "N/A";
        // status         = rideObject.status.length > 0 ?  rideObject.status : 'N/A';
        // origin         = rideObject.origin.length > 0 ? rideObject.origin : 'N/A';
        alerts = rideObject.alertDataCount > 0 ? rideObject.alertDataCount : 0;
        createdTime =
          rideObject.startDateTimeToTimeZone.length > 0
            ? rideObject.startDateTimeToTimeZone
            : "N/A";
        endTime =
          rideObject.endDateTimeToTimeZone != null
            ? rideObject.endDateTimeToTimeZone
            : "N/A";

        // subType        = rideObject.subType.length > 0 ?  rideObject.subType : 'N/A';
        dayPercentage =
          rideObject.dayPercentage > 0
            ? parseFloat(rideObject.dayPercentage).toFixed(0)
            : 0;
        nightPercentage =
          rideObject.dayPercentage > 0 ? 100 - dayPercentage + "%" : 0 + "%";
        // locationName    =  rideObject.locationName.length > 0 ? rideObject.locationName : 'N/A';
        // accessLevel     = rideObject.accessLevel.length > 0 ? rideObject.accessLevel : 'N/A';
      } catch (errMess) {
        // console.log("Exception Error Message :" + errMess);
      }

      try {
        var deviceDataAddressField = rideObject.lastDeviceField;
        var deviceDataField = rideObject;
        city =
          deviceDataAddressField.city != null &&
            deviceDataAddressField.city.length > 0
            ? deviceDataAddressField.city
            : "N/A";
        state =
          deviceDataAddressField.state != null &&
            deviceDataAddressField.state.length > 0
            ? deviceDataAddressField.state
            : "N/A";
        country =
          deviceDataAddressField.country != null &&
            deviceDataAddressField.country.length > 0
            ? deviceDataAddressField.country
            : "N/A";
        zipCode =
          deviceDataAddressField.zipCode != null &&
            deviceDataAddressField.zipCode > 0
            ? deviceDataAddressField.zipCode
            : "N/A";
        anticipation =
          deviceDataField.anticipation != null &&
            deviceDataField.anticipation > 0
            ? parseFloat(deviceDataField.anticipation).toFixed(0)
            : 0;
        drivingSkill =
          deviceDataField.drivingSkill != null &&
            deviceDataField.drivingSkill > 0
            ? parseFloat(deviceDataField.drivingSkill).toFixed(0)
            : 0;
        drivingScore =
          deviceDataField.drivingScore != null &&
            deviceDataField.drivingScore > 0
            ? parseFloat(deviceDataField.drivingScore).toFixed(0)
            : 0;
        selfConfidence =
          deviceDataField.selfConfidence != null &&
            deviceDataField.selfConfidence > 0
            ? parseFloat(deviceDataField.selfConfidence).toFixed(2)
            : 0;
        travelTime =
          deviceDataField.travelTime != null && deviceDataField.travelTime > 0
            ? parseFloat(deviceDataField.travelTime).toFixed(2) + " Mins"
            : 0 + "Mins";
        totalKiloMeter =
          deviceDataField.kiloMeter != null && deviceDataField.kiloMeter > 0
            ? parseFloat(deviceDataField.kiloMeter).toFixed(2) + "Kms"
            : 0 + "Kms";
        urbanPercent =
          deviceDataField.urbanPercent != null &&
            deviceDataField.urbanPercent > 0
            ? parseFloat(deviceDataField.urbanPercent).toFixed(2) + "%"
            : 0 + "%";
        ruralPercent =
          deviceDataField.ruralPercent != null &&
            deviceDataField.ruralPercent > 0
            ? parseFloat(deviceDataField.ruralPercent).toFixed(2) + "%"
            : 0 + "%";
        highwayPercent =
          deviceDataField.highwayPercent != null &&
            deviceDataField.highwayPercent > 0
            ? parseFloat(deviceDataField.highwayPercent).toFixed(2) + "%"
            : 0 + "%";
      } catch (errMess) {
        // console.log("Exception Error Message :" + errMess);
      }

      var userDetails = {
        "Ride Name": rideName,
        // 'Ride Status': status,
        "Driving Score": drivingScore,
        "Driving Skill": drivingSkill,
        Anticipation: anticipation,
        "Self Confidence": selfConfidence,
        "Start Time": createdTime,
        "End Time": endTime,
        "No Of Alerts": alerts,
        "Day Percentage": dayPercentage + "%",
        "Night Percentage": nightPercentage,
        // 'From - Location Name' : locationName,
        "To-City": city,
        "To-State": state,
        "To-Country": country,
        "To-Zip Code": zipCode,
        "Urban Percentage": urbanPercent,
        "Rural Percentage": ruralPercent,
        "Highway Percentage": highwayPercent,
        // 'Mobile' : origin,
        // 'Ride Type' : subType,
        Distance: totalKiloMeter,
        Duration: travelTime,
      };
      dataExcel.push(userDetails);
    }
    excelParser().exportDataFromJSON(dataExcel, "DataTables Motiv AI", "csv");
  };
  // alert(JSON.stringify(data))
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter
  );


  return (
    <React.Fragment>
      <div className="d-flex gap-2">
        {allColumns.map((column) => (
          <div key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              {column.id}
            </label>
          </div>
        ))}
        <br />
      </div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <form className="app-search d-none d-lg-block">
        <div className="position-relative"></div>
      </form>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) =>
                column.hideHeader === false ? null : (
                  <th key={column.id}>
                    <span style={{ display: index == 12 ? "none" : "block" }}>
                      {" "}
                      {column.render("Header")}{" "}
                    </span>
                    {/* Render the columns filter UI */}
                    {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                  </th>
                )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      style={{ fontSize: "12px" }}
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      <Link
                        to={index == 0 && "/user-dashboard"}
                        state={{ userId: row.values.userId }}
                        onClick={() => [
                          localStorage.setItem(SC_USER_ID, row.values.userId),
                          localStorage.setItem(
                            SC_LOGIN_USER_FIRST_NAME,
                            row.values.firstName
                          ),
                        ]}
                      >
                        <span
                          onClick={index === 11 ? () => exportExcel(row.values.userId) : ""}
                          className={
                            index === 11
                              ? "btn btn-secondary btn-sm text-white"
                              : ""
                          }
                          style={{
                            color: index == 0 ? "#05849B" : "black",
                            fontWeight: index == 0 ? 600 : 300,
                            display: index == 12 ? "none" : "block",
                          }}
                        >
                          {cell.render("Cell")}{" "}
                        </span>
                      </Link>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}

function MyUsersDataList({ props }) {
  // console.log("props", props);
  const [copyData, setCopyData] = useState([]);
  // const [copied, setCopied] = useState(false)
  const { searchField } = useSelector((state) => ({
    searchField: state.myUserSearch.searchField,
  }));
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (searchField.search) {
      setSearchText(searchField.value);
    }
  }, [searchField]);

  const exportPDF = () => {
    // setTableData(data)
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "DataTables | Motiv AI";
    const headers = [
      [
        "First Name",
        "Username",
        "Password",
        "Email Id",
        "Status",
        "Created Time",
        "Last Ride Time",
        "Total Rides",
        "CY Rides",
        "CM Rides",
        "CD Rides",
        "Export Rides",
        "userId",
      ],
    ];

    const Data = tableData.map((elt) => [
      elt.firstName,
      elt.username,
      elt.password,
      elt.emailId,
      elt.status,
      elt.createdTime,
      elt.lastRideTime,
      elt.totalRides,
      elt.cYRides,
      elt.cMRides,
      elt.cDRides,
      elt.exportRides,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: Dataa,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("DataTables Motiv AI");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Info",
        hideHeader: false,
        columns: [
          {
            Header: "First Name",
            accessor: "firstName",
          },
          {
            Header: "Username",
            accessor: "userName",
          },
          {
            Header: "Password",
            accessor: "password",
          },
          {
            Header: "Email Id",
            accessor: "emailId",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Created Time",
            accessor: "createdTime",
          },
          {
            Header: "Last Ride Time",
            accessor: "lastRideTime",
          },
          {
            Header: "Total Rides",
            accessor: "totalRides",
          },
          {
            Header: "CY Rides",
            accessor: "CYRides",
          },
          {
            Header: "CM Rides",
            accessor: "CMRides",
          },
          {
            Header: "CD Rides",
            accessor: "CDRides",
          },
          {
            Header: "Export Rides",
            accessor: "ExportRides",
          },
          {
            Header: "userId",
            accessor: "userId",
          },
        ],
      },
    ],
    []
  );

  const dataTable = [
    {
      firstName: "-",
      userName: "-",
      password: "-",
      emailId: "-",
      status: "-",
      createdTime: "-",
      lastRideTime: "-",
      totalRides: "-",
      CYRides: "-",
      CMRides: "-",
      CDRides: "-",
      ExportRides: "-",
      userId: "-",
    },
  ];
  const [pageNumber, setPageNumber] = useState(1);

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const pageSize = 10;
  const [data, setData] = useState(dataTable);
  const [indexStart, setIndexStart] = useState(0);
  const [filterDays, setFilterDays] = useState("");

  const [filterDataList, setFilterDataList] = useState("");
  const [exportData, setExportData] = useState([]);
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [mobileValid, setMobileValid] = React.useState(null);
  const [passwordValid, setPasswordValid] = useState(null);
  const [prevShow, setPrevShow] = useState();
  const [nextShow, setNextShow] = useState();
  // useEffect(()=>{
  //   if(exportData.length > 0  ){
  //     setExportData(...exportExcel())
  //   }

  // },[exportData])

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const getUserListData = async () => {
    var result = await userController.getUser(
      indexStart,
      filterDays,
      filterDataList,
      searchText,
      pageSize
    );
    var userList = [];

    try {
      var resultJSON = JSON.parse(result);
      var resultJSONData = resultJSON.data;
      // console.log("getUserList :" + JSON.stringify(resultJSONData));
      for (let i = 0; i < resultJSONData.length; i++) {
        var userStatus = resultJSONData[i];
        var userId = ValueFormat.parseAsString(userStatus.userId);
        var firstName = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetter(userStatus.firstName)
        );
        var lastName = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetter(userStatus.lastName)
        );
        var email = ValueFormat.parseAsString(
          ValueFormat.sliceStringAndDot(userStatus.email, 10)
        );
        var emailToolTip = userStatus.email.length > 0 ? userStatus.email : "-";
        var status = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetter(userStatus.status)
        );
        var username = ValueFormat.parseAsString(
          ValueFormat.sliceString(userStatus.username, 10)
        );
        var password = ValueFormat.parseAsString(userStatus.password);
        var createdAt = ValueFormat.parseAsString(
          userStatus.createdAtToTimeZone
        );
        var lastRideDateTimeToTimeZone = ValueFormat.parseAsString(
          userStatus.lastRideDateTimeToTimeZone
        );
        var currentMonthRideCount = ValueFormat.parseAsInt(
          userStatus.currentMonthRideCount
        );
        var rides = ValueFormat.parseAsInt(userStatus.rideCount);
        var currentYearRideCount = ValueFormat.parseAsInt(
          userStatus.currentYearRideCount
        );
        var currentDayRideCount = ValueFormat.parseAsInt(
          userStatus.currentDayRideCount
        );

        var userDetails = {
          firstName: firstName,
          lastName: lastName,
          userName: username,
          password: password,
          emailId: email,
          status: status,
          createdTime: createdAt,
          lastRideTime: lastRideDateTimeToTimeZone,
          totalRides: rides,
          CYRides: currentYearRideCount,
          CMRides: currentMonthRideCount,
          CDRides: currentDayRideCount,
          ExportRides: "CY",
          userId: userId,
        };
        userList.push(userDetails);
      }
    } catch (errMess) {
      // console.log("Exception: ", errMess);
    }
    setData(userList);

    if (indexStart == 0) {
      setPrevShow(false);
    } else if (indexStart > 0) {
      setPrevShow(true);
    }

    if (resultJSONData.length == 10) {
      setNextShow(true);
    } else {
      setNextShow(false);
    }
  };

  const createUser = async () => {
    var result = await userController.createUserRegistration(
      mobileNumber,
      password
    );
    try {
      var resultJSON = JSON.parse(result);
      var resultJSONData = resultJSON.data;
      if (resultJSONData.length > 0) {
        getUserListData();
      }
    } catch (errMess) {
      // console.log("Exception: ", errMess);
    }
  };
  const validPhone = () => {
    const phrex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (
      phrex.test(mobileNumber) &&
      mobileNumber.length < 11 &&
      mobileNumber.length > 9
    ) {
      setMobileValid(true);
    } else {
      setMobileValid(false);
    }
  };
  const validPassword = () => {
    const rep = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{5,}/;
    if (rep.test(password)) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  useEffect(() => {
    validPhone();
    validPassword();
  }, [mobileNumber, password]);

  const submit = () => {
    if (mobileValid === true && passwordValid === true) {
      createUser();
    }
  };

  const handleFilterValues = (filterDataList) => {
    setFilterDataList(filterDataList);
  };

  useEffect(() => { }, []);

  useEffect(() => {
    getUserListData();
    setNextShow(true);
    setPrevShow(true);
  }, [indexStart, filterDataList, searchText]);

  //meta title
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title=" My Users" breadcrumbItem=" My Users" />
        {/* <Row>
                <Col className="col-12">
                    <Card>
                        <CardHeader>
                            <h4 className="card-title">Default Datatable</h4>
                            <p className="card-title-desc">DataTables has most features enabled by
                                default, so all you need to do to use it with your own tables is to call
                                the construction function: <code>$().DataTable();</code>.
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Table columns={columns} data={data} />
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}
        <Row className="mb-3">
          <Col xs={12} className="d-flex justify-content-start text-black">
            <h5>My Users</h5>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader style={{ padding: "0px", paddingBottom: 0 }}>
                <Row>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-3 mt-3"
                        style={{ textAlign: "center" }}
                      >
                        Action
                      </h4>
                    </div>

                    <div className="mt-3 mb-2" style={{ textAlign: "center" }}>
                      <button
                        className="button"
                        onClick={openModal}
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid #555555",
                        }}
                      >
                        <i
                          className="fa fa-plus"
                          style={{ fontSize: "10px" }}
                        ></i>
                        &nbsp;Add User
                      </button>
                    </div>
                  </Col>
                  <Col
                    xl={4}
                    lg={3}
                    md={3}
                    style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-3 mt-3"
                        style={{ textAlign: "center" }}
                      >
                        Status
                      </h4>
                    </div>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          filterDataList == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm "
                        }
                        onClick={() => handleFilterValues("")}
                      >
                        All
                      </button>
                      <button
                        type="button"
                        className={
                          filterDataList == "ACTIVE"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => handleFilterValues("ACTIVE")}
                      >
                        Active
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filterDataList == "REGISTERED"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => handleFilterValues("REGISTERED")}
                      >
                        Registered
                      </button>{" "}
                      <ReactTooltip place="top" content="" style={{ backgroundColor: "black" }}>
                        <button
                          type="button"
                          className={
                            filterDataList == "INITIATED"
                              ? "btn btn-primary btn-sm me-1"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => handleFilterValues("INITIATED")}
                        >
                          License Issued
                        </button>
                      </ReactTooltip>
                      {/* <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => handleFilterValues("CURRENT_YEAR")}>
                                        Export As Excel
                                    </button>{" "} */}
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={4} style={{ paddingLeft: "0px" }}>
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-3 mt-3"
                        style={{ textAlign: "center" }}
                      >
                        Export
                      </h4>
                    </div>
                    <div
                      className="mt-3 mb-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <span className="mt-1">Excel :</span>
                      <div style={{ marginLeft: "20px" }}>
                        <CSVLink
                          data={data}
                          type="button"
                          // onClick={onCloseClick}
                          className="btn btn-secondary btn-sm"
                          filename="DataTables Motiv AI"
                        // id="delete-record"
                        >
                          <span style={{ fontSize: 10 }}>CY</span>
                        </CSVLink>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* */}

                {/* <p className="card-title-desc">The Buttons extension for DataTables
                                provides a common set of options, API methods and styling to display
                                buttons on a page that will interact with a DataTable. The core library
                                provides the based framework upon which plug-ins can built.
                            </p> */}

                {/* <div className="dt-buttons btn-group flex-wrap mb-2"> */}
                {/* <CopyToClipboard text={JSON.stringify(copyData)}>
                        <button
                          onClick={() => setCopyData(data)}
                          type="button"
                          className="btn btn-secondary buttons-copy buttons-html5"
                        >
                          <span>Copy</span>
                        </button>
                      </CopyToClipboard> */}

                {/* <div className="dt-buttons btn-group flex-wrap">
                      <CSVLink
                        data={exportData}
                        type="button"
                        // onClick={onCloseClick}
                        className="btn btn-secondary "
                        filename="DataTables Rudu"
                        // id="delete-record"
                      >
                        <span style={{ fontSize: 8 }}> Export as</span>{" "}
                        <span>Excel</span>
                        <br />
                        <span style={{ fontSize: 10 }}>(Current Page)</span>
                      </CSVLink>
                      </div> */}
                {/* <button
                          onClick={() => exportPDF()}
                          type="button"
                          className="btn btn-secondary buttons-copy buttons-html5"
                        >
                          <span>Pdf</span>
                        </button> */}
                {/* <select>
                                                <option>1</option>
                                                <option>2</option>
                                            </select> */}
                {/* <button type="button" className="btn btn-secondary buttons-copy buttons-html5">
                                                <span>Column visibility</span>
                                            </button> */}
                {/* </div>
                  </div>
                </div> */}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sl={12} md={6}>
                    <div className="dt-buttons btn-group flex-wrap mb-2">
                      {/* <CopyToClipboard text={JSON.stringify(copyData)}>
                        <button
                          onClick={() => setCopyData(data)}
                          type="button"
                          className="btn btn-secondary buttons-copy buttons-html5"
                        >
                          <span>Copy</span>
                        </button>
                      </CopyToClipboard> */}

                      <div className="dt-buttons btn-group flex-wrap">
                        {/* <CSVLink
                          data={data}
                          type="button"
                          // onClick={onCloseClick}
                          className="btn btn-secondary "
                          filename="DataTables Minia - Minimal Admin Dashboard Template.pdf.csv"
                          // id="delete-record"
                        >
                          <span>Excel</span>
                        </CSVLink> */}
                        {/* <button
                          onClick={() => exportPDF()}
                          type="button"
                          className="btn btn-secondary buttons-copy buttons-html5"
                        >
                          <span>Pdf</span>
                        </button> */}
                        {/* <select>
                                                <option>1</option>
                                                <option>2</option>
                                            </select> */}
                        {/* <button type="button" className="btn btn-secondary buttons-copy buttons-html5">
                                                <span>Column visibility</span>
                                            </button> */}
                      </div>
                    </div>
                    {/* <div className="section">
                                        {copyData.length ? <span style={{ color: 'red' }}>Copied.</span> : null}
                                    </div> */}
                  </Col>
                </Row>
                <Table columns={columns} data={data} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              <div className="pagination">
                <a href="#">{pageNumber}</a>
              </div>
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "right",
                }}
              >
                Next
              </button>
            </div>
          </Col>
        </Row>

        <div style={{ backgroundColor: "red" }}>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            overlayClassName="custom-modal-overlay"
            contentLabel="Example Modal"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2
                style={{
                  color: "black",
                  fontSize: "25px",
                  fontFamily: "sans-serif",
                  fontWeight: 600,
                }}
              >
                Add User
              </h2>
              <CgCloseO
                size="25px"
                style={{ marginTop: "6px" }}
                onClick={closeModal}
              />
            </div>

            <form>
              <div className="mb-3 mt-4">
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "sans-serif",
                  }}
                >
                  Username
                </label>
                <input
                  style={{
                    width: "100%",
                    padding: "12px 20px",
                    margin: "8px 0",
                    display: "inline-block",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                  }}
                  type="tel"
                  onChange={(e) => setMobileNumber(e.target.value)}
                  value={mobileNumber}
                  name="mobilenumber"
                  id="mobilenumber"
                  placeholder="Enter Mobile Number"
                />

                {mobileValid === false && (
                  <p style={{ color: "red" }}>A valid phone is required !</p>
                )}
              </div>
              <div className="mb-3">
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "sans-serif",
                  }}
                >
                  Password
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "12px 20px",
                    margin: "8px 0",
                    display: "inline-block",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                  }}
                  type="password"
                  name="password"
                  id="Password"
                  placeholder="Enter your Password"
                />
                {passwordValid === false && (
                  <h6 style={{ color: "red" }}>
                    A valid password is required !
                  </h6>
                )}
              </div>
            </form>
            <div
              // className="d-flex justify-content-space-between align-items-center"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                type="submit"
                className="btn btn-primary btn-clr text-white"
                onClick={submit}
                data-bs-dismiss="modal"
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => [setMobileNumber(""), setPassword("")]}
              >
                Clear
              </button>
            </div>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

MyUsersDataList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default MyUsersDataList;
