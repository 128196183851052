import React, { lazy, Suspense } from 'react';
import GoogleMapRisk from './GoogleMapRisk'

// const GoogleMapRisk = lazy(() => import('./GoogleMapRisk'));
export default function GoogleMapRiskView() {
  return (
    <div>
         <Suspense fallback={<div>Loading...</div>}>
      <GoogleMapRisk/>
      </Suspense>
    </div>
  )
}
