import { USER_RIDE_COUNT } from "./actiontype";

const INIT_STATE = {
  totalRideCount: {value:0,search:false}
};

const userRideCount = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_RIDE_COUNT:
      return {
        ...state,
        totalRideCount: action.payload,
      };
    default:
      return state;
  }
};

export default userRideCount;
