import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  Table,
  Col,
  Card,
  CardHeader,
} from "reactstrap";
import classnames from "classnames";
import { FiArrowUp, FiArrowDown } from "react-icons/fi";
import { CgArrowsH } from "react-icons/cg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../constant/MessageConstant";
import { Link } from "react-router-dom";

//SimpleBar
import SimpleBar from "simplebar-react";
import { SC_LOGIN_USER_FIRST_NAME } from "../../constant/StorageConstant";
import Converter from "../../components/Common/EncodeDecode";

const Transactions = ({ safetyTable }) => {
  const [activeTab, setActiveTab] = useState("1");
// console.log(safetyTable, "safetytable");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [dataDisplay, setDataDisplay] = useState(false);

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card style={{ height: 568 }}>
          <CardHeader className="cardHeader">
            <h4 className="sub_heading my-0" style={{marginLeft:"-8.5px"}}>Driver Category</h4>

          </CardHeader>
          {/* <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Transactions</h4>
                        <div className="flex-shrink-0">
                            <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
                                <NavItem>
                                    <NavLink to="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}
                                    >
                                        All
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}
                                    >
                                        Buy
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="#"
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggle('3'); }}
                                    >
                                        Sell
                                    </NavLink>
                                </NavItem>
                            </Nav>

                        </div>
                    </div> */}

          <CardBody className="px-0" style={{ maxHeight: "410px" }}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Table responsive className="table mb-0" style={{ height: "405px" }}>
                  {/* {dataDisplay == "failed" ? (
          <p className="data_not_available">{displayText.ER_1206}</p>
        ): dataDisplay == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
        ) : "" } */}
                  <thead style={{ width: "100%" }}>
                    <tr >
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Driver (Category)</th>
                      <th data-tooltip-id="Ant" style={{ fontSize: "14px",fontWeight: 600, }}>Anticipation
                        <ReactTooltip
                          id="Ant"
                          place="top"
                          content="Anticipation"
                          style={{ backgroundColor: "black" }}
                        />
                      </th>

                      <th data-tooltip-id="Self" style={{ fontSize: "14px",fontWeight: 600, }}>Self-Confidence
                        <ReactTooltip
                          id="Self"
                          place="top"
                          content="Self-Confidence"
                          style={{ backgroundColor: "black" }}
                        />
                      </th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Rides</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>%</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(safetyTable || []).map((safetyData, index) => (
                      // console.log("ridenamelist", safetyData),
                      <tr key={safetyData.name} className={`table-row${index === safetyTable.length - 1 ? ' last-tr' : ''}`}>
                        <th
                          scope="row"
                          style={{ fontSize: "13px", fontWeight: 400, verticalAlign: "middle" }}
                        >
                          <div style={{ width: 130 }}>{safetyData.name} ({safetyData.prefix})</div>
                        </th>
                        <td style={{ fontSize: "13px", verticalAlign: "middle" }}>
                          {safetyData.antiIcon == "up" ? (
                            <FiArrowUp />
                          ) : safetyData.antiIcon == "down" ? (
                            <FiArrowDown />
                          ) : (
                            <CgArrowsH />
                          )}{" "}
                        </td>
                        <td style={{ fontSize: "13px", verticalAlign: "middle" }}>
                          {safetyData.selfIcon == "up" ? (
                            <FiArrowUp />
                          ) : safetyData.selfIcon == "down" ? (
                            <FiArrowDown />
                          ) : (
                            <CgArrowsH />
                          )}
                        </td>
                        <td style={{ fontSize: "13px", verticalAlign: "middle" }}>
                          <div className=" w-80 h-2">{safetyData.totalRides}</div>
                        </td>
                        <td style={{ fontSize: "13px", verticalAlign: "middle",whiteSpace:"nowrap"}}>
                          {safetyData.percentage == "NaN" ? "-" : safetyData.percentage + " %"}
                        </td>
                        <td style={{ verticalAlign: "middle" }} className={index === safetyTable.length - 1 ? "last-cell" : ""}>

                          {safetyData.rideNameList.length ?
                            <div style={{ overflowY: "auto", height: "48px", width: "95px", }}>
                              {safetyData.rideNameList.map((ride) => (
                                <Link
                                to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(ride.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(ride.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(ride.rideId))}`}
                                onClick={() =>
                                  localStorage.setItem(
                                    SC_LOGIN_USER_FIRST_NAME,
                                    ride.firstName
                                  )
                                }
                                key={ride.rideId}
                                state={{
                                  userId: ride.userId,
                                  divisionId: ride.divisionId,
                                  rideId: ride.rideId,
                                }}
                              >
                                <p className="m-2" style={{ fontSize: "12px", height: "12px" }} key={ride}>{ride.rideName.charAt(0).toUpperCase() + ride.rideName.slice(1).toLowerCase()}</p>
                                </Link>
                              ))}
                            </div> : <p className="p-0 m-0" style={{ verticalAlign: "middle", fontWeight: 300, height: "48px", fontSize: "20px" }}>-</p>}
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </Table>
                {/* </div> */}
              </TabPane>


            </TabContent>
          </CardBody>

        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Transactions;
