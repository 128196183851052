import {
  AGGRESSIVE,
  ANGRY,
  BETWEEN_EQUAL,
  CHAOTIC,
  ECO,
  GREATER_THAN,
  HIGH,
  LESS_THAN,
  LOW,
  MEDIUM,
  MOBILE_SCREEN,
  MOBILE_USE,
  NORMAL,
  SEVERE_ACCELERATION,
  SEVERE_BRAKING,
  SEVERE_CORNERING,
  SPORTIVE,
  TIRED,
} from "./UBIConstants";

export let UBIPoints = {
  calculateMobileCallScreenPoints(value, type, jsonFile) {
    const ubiDashboard = jsonFile.mobileUsagePoint || [];

    const collection = ubiDashboard.find((item) => item.type === type);

    if (!collection) {
      return null;
    }

    const rangeList = collection.rangeList || [];

    for (const range of rangeList) {
      const minRange = parseFloat(range.minRange);
      const maxRange = parseFloat(range.maxRange);
      const considerValue = parseFloat(range.considerValue);

      if (
        (range.operator === LESS_THAN && value < maxRange) ||
        (range.operator === BETWEEN_EQUAL &&
          value >= minRange &&
          value < maxRange) ||
        (range.operator === GREATER_THAN && value >= minRange)
      ) {
        return considerValue;
      }
    }

    return null;
  },

  calculateMobileUsagePoint(mobileScreen, mobileCall, jsonFile) {
    const mobileScreenPoints = this.calculateMobileCallScreenPoints(
      mobileScreen,
      MOBILE_SCREEN,
      jsonFile
    );
    const mobileCallPoints = this.calculateMobileCallScreenPoints(
      mobileCall,
      MOBILE_USE,
      jsonFile
    );
    const result = ((0.6 * mobileScreenPoints) + (0.4 * mobileCallPoints)).toFixed(
      0
    );
    return result;
  },
  calculateLowMediumHighPoints(value, subCategory, subCategoryLevel, jsonFile) {
    const ubiDashboard = jsonFile.StressStrainPoint || [];
    const category = ubiDashboard.find(
      (item) => item.subCategory === subCategory
    );
    if (!category) {
      return null;
    }
    const categoryList = category.subCategoryList || [];
    if (!categoryList) {
      return null;
    }
    const CategoryLevel = categoryList.find(
      (item) => item.subCategoryLevel === subCategoryLevel
    );
    if (!CategoryLevel) {
      return null;
    }
    const rangeList = CategoryLevel.rangeList || [];

    for (const range of rangeList) {
      const minRange = parseFloat(range.minRange);
      const maxRange = parseFloat(range.maxRange);
      const considerValue = parseFloat(range.considerValue);

      if (
        (range.operator === LESS_THAN && value <= maxRange) ||
        (range.operator === BETWEEN_EQUAL &&
          value < minRange &&
          value <= maxRange) ||
        (range.operator === GREATER_THAN && value > minRange)
      ) {
        return considerValue;
      }
    }

    return null;
  },
  calculatestressStrainPoint(value, jsonFile) {
    const corneringLow = this.calculateLowMediumHighPoints(
      value[0].data[1],
      SEVERE_CORNERING,
      LOW,
      jsonFile
    );
    const corneringMedium = this.calculateLowMediumHighPoints(
      value[1].data[1],
      SEVERE_CORNERING,
      MEDIUM,
      jsonFile
    );
    const corneringhigh = this.calculateLowMediumHighPoints(
      value[2].data[1],
      SEVERE_CORNERING,
      HIGH,
      jsonFile
    );
    const cornering =
      0.1 * corneringLow + 0.3 * corneringMedium + 0.6 * corneringhigh;

    const brakingLow = this.calculateLowMediumHighPoints(
      value[0].data[2],
      SEVERE_BRAKING,
      LOW,
      jsonFile
    );
    const brakingMedium = this.calculateLowMediumHighPoints(
      value[1].data[2],
      SEVERE_BRAKING,
      MEDIUM,
      jsonFile
    );
    const brakinghigh = this.calculateLowMediumHighPoints(
      value[2].data[2],
      SEVERE_BRAKING,
      HIGH,
      jsonFile
    );
    const braking = 0.1 * brakingLow + 0.3 * brakingMedium + 0.6 * brakinghigh;

    const accelarationLow = this.calculateLowMediumHighPoints(
      value[0].data[0],
      SEVERE_ACCELERATION,
      LOW,
      jsonFile
    );
    const accelarationMedium = this.calculateLowMediumHighPoints(
      value[1].data[0],
      SEVERE_ACCELERATION,
      MEDIUM,
      jsonFile
    );
    const accelarationhigh = this.calculateLowMediumHighPoints(
      value[2].data[0],
      SEVERE_ACCELERATION,
      HIGH,
      jsonFile
    );
    const accelaration =
      0.1 * accelarationLow + 0.3 * accelarationMedium + 0.6 * accelarationhigh;

    const stressStrainPoint =
      (0.3 * cornering + 0.4 * braking + 0.3 * accelaration).toFixed(0);
    return stressStrainPoint;
  },
  calculateDrivingStatePoint(normalValue, tiredValue, angryValue, jsonFile){
    const normalPoint = parseInt(jsonFile.DrivingStatePoint.find((item) => item.type === NORMAL).point);
    const tiredPoint = parseInt(jsonFile.DrivingStatePoint.find((item) => item.type === TIRED).point);
    const angryPoint = parseInt(jsonFile.DrivingStatePoint.find((item) => item.type === ANGRY).point);
    const sumPoints = (normalValue * normalPoint) + (tiredValue * tiredPoint) + (angryValue * angryPoint);
    const sumCounts = parseInt(normalValue) + parseInt(tiredValue) + parseInt(angryValue);
    const DSP = sumPoints / sumCounts;
    if(normalValue==null || normalValue == undefined && tiredValue==null || tiredValue == undefined && angryValue==null || angryValue == undefined){
      return 0;
    }else if (isNaN(DSP)){
      return 0;
    }
    else{
    return DSP;
    }
  },
  calculateDrivingStylePoint(normalStyleValue, sportiveValue, ecoValue, chaoticValue, aggressiveValue, jsonFile){
    const normalPoint = parseInt(jsonFile.DrivingStylePoint.find((item) => item.type === NORMAL).point);
    const sportivePoint = parseInt(jsonFile.DrivingStylePoint.find((item) => item.type === SPORTIVE).point);
    const ecoPoint = parseInt(jsonFile.DrivingStylePoint.find((item) => item.type === ECO).point);
    const chaoticPoint = parseInt(jsonFile.DrivingStylePoint.find((item) => item.type === CHAOTIC).point);
    const aggressivePoint = parseInt(jsonFile.DrivingStylePoint.find((item) => item.type === AGGRESSIVE).point);

    const sumPoints = (normalStyleValue * normalPoint) + (sportiveValue * sportivePoint) + (ecoValue * ecoPoint) + (chaoticValue * chaoticPoint) + (aggressiveValue * aggressivePoint);
    const sumCounts = parseInt(normalStyleValue) + parseInt(sportiveValue) + parseInt(ecoValue) + parseInt(chaoticValue) + parseInt(aggressiveValue);
    const DSTP = sumPoints / sumCounts;
    if(normalStyleValue==null || normalStyleValue == undefined && sportiveValue== null || sportiveValue == undefined && ecoValue== null || ecoValue == undefined && chaoticValue== null || chaoticValue == undefined && aggressiveValue== null || aggressiveValue == undefined){
      return 0;
    }
    else if (isNaN(DSTP)){
      return 0;
    }else{
    return DSTP;
    }
  },
  calculateDrivingBehaviorPoint(normalValue, tiredValue, angryValue, normalStyleValue, sportiveValue, ecoValue, chaoticValue, aggressiveValue, jsonFile){
    const DSP  = this.calculateDrivingStatePoint(normalValue, tiredValue, angryValue, jsonFile);
    const DSTP = this.calculateDrivingStylePoint(normalStyleValue, sportiveValue, ecoValue, chaoticValue, aggressiveValue, jsonFile);
    const DBP = (DSTP+DSP)/2;

    if(DSP!== 0  &&  DSTP !== 0){
    return DBP
   } else {
    return 0
   }
},
calculateDrivingKMSPoint(totalkms, jsonFile) {
  const ubiDashboard = jsonFile.drivingkmspoint || [];
  const rangeList = ubiDashboard[0].rangeList || [];

  for (const range of rangeList) {
    const minRange = parseFloat(range.minRange);
    const maxRange = parseFloat(range.maxRange);

    if (
      (range.operator === "LESS_THAN" && totalkms < maxRange) ||
      (range.operator === "BETWEEN_EQUAL" &&
        totalkms >= minRange &&
        totalkms < maxRange) ||
      (range.operator === "GREATER_THAN" && totalkms >= minRange)
    ) { if (totalkms==null || totalkms == undefined ){
      return 0;
    }else{
      return parseFloat(range.considerValue);
    }
  }
  }
},
//new function for KMSDriving point
calculateDaysBetween(startTime, endTime) {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  // Normalize the dates to the start of the day
  const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  // Calculate the difference in time
  const diffTime = end - start;

  // Calculate the difference in days
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
},
 getDaysCount(type, startTime = null, endTime = null) {
  if (startTime && endTime) {
    return this.calculateDaysBetween(startTime, endTime);
  }

  const now = new Date();
  switch (type) {
    case "CURRENT_DAY":
      return 1;
    case "CURRENT_WEEK":
      return 7;
    case "CURRENT_MONTH":
      return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    case "CURRENT_YEAR":
      return (new Date(now.getFullYear(), 11, 31) - new Date(now.getFullYear(), 0, 0)) / (24 * 60 * 60 * 1000);
    default:
      return 0;
  }
},
 getKMSPoints(type, kmsDriven, startTime = null, endTime = null) {

  if (kmsDriven == undefined || kmsDriven == null || kmsDriven == 0) {
    return 0;
  }

  const daysCount = this.getDaysCount(type, startTime, endTime);

  const pointsTable = [
    { range: 50, points: 10 },
    { range: 75, points: 9 },
    { range: 100, points: 8 },
    { range: 125, points: 7 },
    { range: 150, points: 6 },
    { range: 175, points: 5 },
    { range: 200, points: 4 },
    { range: 225, points: 3 },
    { range: 250, points: 2 },
    { range: Infinity, points: 1 }
  ];

  const rangePerDay = 50;
  const range = rangePerDay * daysCount;

  for (const entry of pointsTable) {
    if (kmsDriven <= range * (entry.range / 50)) {
      return entry.points;
    }
  }

  return 0; // If kmsDriven exceeds all ranges, return the minimum points
},
getMobileScreenPoints(type, mobileScreenValue, startTime = null, endTime = null) {

  if (mobileScreenValue == undefined || mobileScreenValue == null) {
    return 0;
  }

  const daysCount = this.getDaysCount(type, startTime, endTime);

  const pointsTable = [
    { range: 10, points: 10 },
    { range: 15, points: 9 },
    { range: 20, points: 8 },
    { range: 25, points: 7 },
    { range: 30, points: 6 },
    { range: 35, points: 5 },
    { range: 40, points: 4 },
    { range: 45, points: 3 },
    { range: 50, points: 2 },
    { range: Infinity, points: 1 }
  ];

  const rangePerDay = 10;
  const range = rangePerDay * daysCount;

  for (const entry of pointsTable) {
    if (mobileScreenValue <= range * (entry.range / 10)) {
      return entry.points;
    }
  }

  return 0; // If mobileScreenValue exceeds all ranges, return the minimum points
},
getMobileCallPoints(type, mobileCallValue, startTime = null, endTime = null) {

  if (mobileCallValue == undefined || mobileCallValue == null) {
    return 0;
  }

  const daysCount = this.getDaysCount(type, startTime, endTime);

  const pointsTable = [
    { range: 10, points: 10 },
    { range: 15, points: 9 },
    { range: 20, points: 8 },
    { range: 25, points: 7 },
    { range: 30, points: 6 },
    { range: 35, points: 5 },
    { range: 40, points: 4 },
    { range: 45, points: 3 },
    { range: 50, points: 2 },
    { range: Infinity, points: 1 }
  ];

  const rangePerDay = 10;
  const range = rangePerDay * daysCount;

  for (const entry of pointsTable) {
    if (mobileCallValue <= range * (entry.range / 10)) {
      return entry.points;
    }
  }

  return 0; // If mobileCallValue exceeds all ranges, return the minimum points
},
getStressStrainLowPoints(type, lowACBValue, startTime = null, endTime = null) {

  if (lowACBValue == undefined || lowACBValue == null) {
    return 0;
  }

  const daysCount = this.getDaysCount(type, startTime, endTime);

  const pointsTable = [
    { range: 0.546, points: 10 },
    { range: 0.683, points: 9 },
    { range: 0.82, points: 8 },
    { range: 0.956, points: 7 },
    { range: 1.093, points: 6 },
    { range: 1.23, points: 5 },
    { range: 1.366, points: 4 },
    { range: 1.503, points: 3 },
    { range: 1.639, points: 2 },
    { range: Infinity, points: 1 }
  ];

  const rangePerDay = 0.546;
  const range = rangePerDay * daysCount;

  for (const entry of pointsTable) {
    if (lowACBValue <= range * (entry.range / 0.546)) {
      return entry.points;
    }
  }

  return 0; // If lowACBValue exceeds all ranges, return the minimum points
},
getStressStrainMediumPoints(type, mediumACBValue, startTime = null, endTime = null) {

  if (mediumACBValue == undefined || mediumACBValue == null) {
    return 0;
  }

  const daysCount = this.getDaysCount(type, startTime, endTime);

  const pointsTable = [
    { range: 0.137, points: 10 },
    { range: 0.205, points: 9 },
    { range: 0.273, points: 8 },
    { range: 0.342, points: 7 },
    { range: 0.41, points: 6 },
    { range: 0.478, points: 5 },
    { range: 0.546, points: 4 },
    { range: 0.615, points: 3 },
    { range: 0.683, points: 2 },
    { range: Infinity, points: 1 }
  ];

  const rangePerDay = 0.137;
  const range = rangePerDay * daysCount;

  for (const entry of pointsTable) {
    if (mediumACBValue <= range * (entry.range / 0.137)) {
      return entry.points;
    }
  }

  return 0; // If mediumACBValue exceeds all ranges, return the minimum points
},
getStressStrainHighPoints(type, highACBValue, startTime = null, endTime = null) {

  if (highACBValue == undefined || highACBValue == null) {
    return 0;
  }

  const daysCount = this.getDaysCount(type, startTime, endTime);

  const pointsTable = [
    { range: 0.027, points: 10 },
    { range: 0.033, points: 9 },
    { range: 0.038, points: 8 },
    { range: 0.044, points: 7 },
    { range: 0.049, points: 6 },
    { range: 0.055, points: 5 },
    { range: 0.06, points: 4 },
    { range: 0.066, points: 3 },
    { range: 0.071, points: 2 },
    { range: Infinity, points: 1 }
  ];

  const rangePerDay = 0.027;
  const range = rangePerDay * daysCount;

  for (const entry of pointsTable) {
    if (highACBValue <= range * (entry.range / 0.027)) {
      return entry.points;
    }
  }

  return 0; // If highACBValue exceeds all ranges, return the minimum points
},

}
