// import { Document, PDFViewer, Page, Text, View, } from "@react-pdf/renderer";
// import React, { useEffect, useState } from "react";
// import LoaderPdf from "../../Common/loaderPdf";
// import ValueFormat from "../../../util/ValueFormat";
// import UtilDateTime from "../../../util/UtilDateTime";
// import PdfHeader from "../Components/pdfHeader";
// import PdfFooter from "../Components/pdfFooter";
// import PdfHeading from "../Components/pdfHeading";
// import { useLocation } from "react-router-dom";

// export default function ColouredCategorySummaryPdf({ safetyTableData, sportyTableData, quiteTableData, recklessTableData, quiteDangerousTableData,
//     dangerousTableData, filtersADCS }) {
//     const [showLoader, setShowLoader] = useState(false);
//     const fontBold = { fontFamily: "Helvetica-Bold", };
//     const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
//     const pathname = useLocation();

//     let period = "";
//     let startDate = "";
//     let endDate = "";

//     if (filtersADCS.durationFilter === "CURRENT_YEAR") {
//         period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
//         startDate = UtilDateTime.getCurrentYear(filtersADCS.startDateTime);
//         endDate = "";
//     } else if (filtersADCS.durationFilter === "CURRENT_MONTH") {
//         period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
//         startDate = UtilDateTime.getCurrentMonth(filtersADCS.startDateTime);
//         endDate = "";
//     } else if (filtersADCS.durationFilter === "CURRENT_WEEK") {
//         period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
//         startDate = UtilDateTime.getCurrentWeek1(filtersADCS.startDateTime);
//         endDate = "";
//     } else if (filtersADCS.durationFilter === "CURRENT_DAY") {
//         period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
//         startDate = UtilDateTime.getCurrentDay(filtersADCS.startDateTime);
//         endDate = "";
//     } else {
//         period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
//         startDate = filtersADCS.startDateTime.split(" ")[0];
//         endDate = filtersADCS.endDateTime.split(" ")[0];
//     }

//     const periodFormat = [period, startDate, endDate];
//     const heading = "Admin Dashboard (Category Summary)";
//     var currentDate = new Date();
//     const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

//     const renderCategoryHeader = (reportType) => {
//         let categoryHeader = "";
//         switch (reportType.toLowerCase()) {
//             case "safe": categoryHeader = "1. Safe Category (C1)";
//                 break;
//             case "sportive": categoryHeader = "2. Sportive Category (C2)";
//                 break;
//             case "quite safe": categoryHeader = "3. Quite Safe Category (C3)";
//                 break;
//             case "reckless": categoryHeader = "4. Reckless Category (C4)";
//                 break;
//             case "quite dangerous": categoryHeader = "5. Quite Dangerous Category (C5)";
//                 break;
//             case "dangerous": categoryHeader = "6. Dangerous Category (C6)";
//                 break;
//             default:
//                 break;
//         }

//         return (
//             <>
//                 <View style={{ paddingLeft: 30, paddingRight: 30, marginTop: 10 }}>
//                     <View style={{ marginBottom: 10 }}>
//                         <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
//                     </View>
//                     <Text style={[fontBold, { fontSize: 11 }]}>{categoryHeader}</Text>
//                 </View>
//             </>
//         );
//     };

//     var totalPages = 0;
//     const generateRows = (data, reportType, period, startDate, endDate, globalPageCounterRef) => {
//         if (!data || data.length === 0) {
//             // Return a default page or message for empty data
//             return [
//                 <Page key="empty">
//                     <PdfHeader totalPages={totalPages++} />
//                     <Text style={{ textAlign: 'center', marginTop: 20 }}>No data available for {reportType} category</Text>
//                     <PdfFooter pageNumber={totalPages} pathname={pathname} />
//                 </Page>
//             ];
//         }

//         let maxRowCountPerPage = 32;
//         const pages = [];
//         let currentPageContent = [];
//         let currentRowCount = 0;

//         data.forEach((item, index) => {
//             let itemRowCount = 0;

//             if (item.rideName && item.rideName.length >= 24) {
//                 itemRowCount = Math.ceil(24 / 8);
//             } else if (item.rideName) {
//                 itemRowCount = Math.ceil(item.rideName.length / 8);
//             }

//             currentRowCount += itemRowCount + 2;

//             if (currentRowCount > maxRowCountPerPage) {
//                 pages.push(currentPageContent);
//                 currentPageContent = [];
//                 currentRowCount = 0;
//             }

//             currentPageContent.push(
//                 <View key={`additional_${index}`}>
//                     <View
//                         style={{
//                             backgroundColor: "#615AE41A",
//                             flexDirection: "column",
//                             marginTop: 5,
//                             padding: 10,
//                             borderRadius: 10,
//                             marginHorizontal: 30,
//                             // borderBottom: "1px solid black",
//                         }}
//                         key={index}
//                     >
//                         <View
//                             style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 5 }} >
//                             <Text style={{ fontSize: 10 }}>Name: {item.name || "N/A"}</Text>
//                             <Text style={{ fontSize: 10 }}>
//                                 Username: {item.username || "N/A"}
//                             </Text>
//                             <Text style={{ fontSize: 10 }}>
//                                 Rides: Showing (
//                                 {item.totalRides && item.totalRides > 0 ? "1" : "0"} -{" "}
//                                 {item.totalRides && item.totalRides >= 24 ? "24" : item.totalRides < 24 ? " " + item.totalRides : "0"} ) of {item.totalRides || "0"}
//                             </Text>
//                         </View>
//                         {item.rideName && item.rideName.length > 0 && (
//                             <View>
//                                 {[...Array(Math.min(Math.ceil(item.rideName.length / 8), 3))].map((_, rowIndex) => (
//                                     <View key={rowIndex} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5, }} >
//                                         {item.rideName.slice(rowIndex * 8, Math.min((rowIndex + 1) * 8, 24)).map((ride, rideIndex) => (
//                                             <Text
//                                                 style={{
//                                                     fontSize: 9,
//                                                     border: "1px solid grey",
//                                                     width: rideIndex === 7 ? "12%" : "11.5%",
//                                                     marginRight: rideIndex === 7 ? "0px" : "1%",
//                                                     textAlign: "center",
//                                                     padding: 1,
//                                                     paddingTop: 2,
//                                                     paddingBottom: 0,
//                                                     borderRadius: 10
//                                                 }}
//                                                 key={rideIndex}
//                                             >
//                                                 {ride.rideName || "N/A"}
//                                             </Text>
//                                         ))}
//                                     </View>
//                                 ))}
//                             </View>
//                         )}
//                     </View>
//                 </View>
//             );
//         });

//         // Add the remaining content to the last page
//         if (currentPageContent.length > 0) {
//             pages.push(currentPageContent);
//         }

//         // Wrap each page's content in a Page component
//         const formattedPages = pages.map((pageContent, index) => {
//             const pageCount = globalPageCounterRef.current + 1;
//             globalPageCounterRef.current += 1;
//             return (
//                 <Page key={index}>
//                     <PdfHeader totalPages={totalPages++} />
//                     {index === 0 && renderCategoryHeader(reportType, period, startDate, endDate)}
//                     <View style={{ marginTop: index === 0 ? 10 : 10 }} > {pageContent} </View>
//                     <PdfFooter pageNumber={totalPages} pathname={pathname} />
//                 </Page>
//             );
//         });

//         return formattedPages;
//     };

//     const globalPageCounterRef = React.useRef(0);

//     const renderSummaryPdf = (safetyTableData, sportyTableData, quiteTableData, recklessTableData, quiteDangerousTableData, dangerousTableData) => {
//         const safetyRows = generateRows(safetyTableData, "Safe", period, startDate, endDate, globalPageCounterRef);
//         const sportyRows = generateRows(sportyTableData, "Sportive", period, startDate, endDate, globalPageCounterRef);
//         const quiteRows = generateRows(quiteTableData, "Quite Safe", period, startDate, endDate, globalPageCounterRef);
//         const recklessRows = generateRows(recklessTableData, "Reckless", period, startDate, endDate, globalPageCounterRef);
//         const quiteDangerousRows = generateRows(quiteDangerousTableData, "Quite Dangerous", period, startDate, endDate, globalPageCounterRef);
//         const dangerousRows = generateRows(dangerousTableData, "Dangerous", period, startDate, endDate, globalPageCounterRef);

//         return [...(safetyRows || []), ...(sportyRows || []), ...(quiteRows || []), ...(recklessRows || []), ...(quiteDangerousRows || []), ...(dangerousRows || [])];
//     };

//     useEffect(() => {
//         renderSummaryPdf(safetyTableData, sportyTableData, quiteTableData, recklessTableData, quiteDangerousTableData, dangerousTableData);
//         setShowLoader(true);
//         const loader = setTimeout(() => {
//             setShowLoader(false);
//         }, 2000);
//         return () => clearTimeout(loader);
//     }, [filtersADCS.durationFilter, filtersADCS.startDateTime, filtersADCS.endDateTime]);

//     if (showLoader) {
//         return <LoaderPdf />;
//     }

//     const summaryPdf = renderSummaryPdf(safetyTableData, sportyTableData, quiteTableData, recklessTableData, quiteDangerousTableData, dangerousTableData);

//     return (
//         <React.Fragment>
//             {showLoader > 0 ? <LoaderPdf /> : ""}

//             <PDFViewer style={{ height: "96%", width: "100%", marginTop: "20px", marginBottom: "5px" }} >
//                 <Document style={{ marginTop: "15px", marginBottom: "5px" }} >
//                     {summaryPdf}
//                 </Document>
//             </PDFViewer>
//         </React.Fragment>
//     );
// }

import { Document, PDFViewer, Page, Text, View, } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import LoaderPdf from "../../Common/loaderPdf";
import ValueFormat from "../../../util/ValueFormat";
import UtilDateTime from "../../../util/UtilDateTime";
import PdfHeader from "../Components/pdfHeaderV1";
import PdfFooter from "../Components/pdfFooterV1";
import PdfHeading from "../Components/pdfHeading";
import { useLocation } from "react-router-dom";

export default function ColouredCategorySummaryPdf({ safetyTableData, sportyTableData, quiteTableData, recklessTableData, quiteDangerousTableData,
    dangerousTableData, filtersADCS }) {
    const [showLoader, setShowLoader] = useState(false);
    const fontBold = { fontFamily: "Helvetica-Bold", };
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const pathname = useLocation();

    let period = "";
    let startDate = "";
    let endDate = "";

    if (filtersADCS.durationFilter === "CURRENT_YEAR") {
        period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
        startDate = UtilDateTime.getCurrentYear(filtersADCS.startDateTime);
        endDate = "";
    } else if (filtersADCS.durationFilter === "CURRENT_MONTH") {
        period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
        startDate = UtilDateTime.getCurrentMonth(filtersADCS.startDateTime);
        endDate = "";
    } else if (filtersADCS.durationFilter === "CURRENT_WEEK") {
        period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
        startDate = UtilDateTime.getCurrentWeek1(filtersADCS.startDateTime);
        endDate = "";
    } else if (filtersADCS.durationFilter === "CURRENT_DAY") {
        period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
        startDate = UtilDateTime.getCurrentDay(filtersADCS.startDateTime);
        endDate = "";
    } else {
        period = ValueFormat.smallLettersFormat(filtersADCS.durationFilter);
        startDate = filtersADCS.startDateTime.split(" ")[0];
        endDate = filtersADCS.endDateTime.split(" ")[0];
    }

    const periodFormat = [period, startDate, endDate];
    const heading = "Admin Dashboard (Category Summary)";
    var currentDate = new Date();
    const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

    const renderCategoryHeader = (reportType) => {
        let categoryHeader = "";
        switch (reportType.toLowerCase()) {
            case "safe": categoryHeader = "1. Safe Category (C1)";
                break;
            case "sportive": categoryHeader = "2. Sportive Category (C2)";
                break;
            case "quite safe": categoryHeader = "3. Quite Safe Category (C3)";
                break;
            case "reckless": categoryHeader = "4. Reckless Category (C4)";
                break;
            case "quite dangerous": categoryHeader = "5. Quite Dangerous Category (C5)";
                break;
            case "dangerous": categoryHeader = "6. Dangerous Category (C6)";
                break;
            default:
                break;
        }

        return (
            <>
                <View style={{ paddingLeft: 30, paddingRight: 30, marginTop: 10 }}>
                    <View style={{ marginBottom: 10 }}>
                        <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
                    </View>
                    <Text style={[fontBold, { fontSize: 11 }]}>{categoryHeader}</Text>
                </View>
            </>
        );
    };

    var totalPages = 0;
    const generateRows = (data, reportType, period, startDate, endDate, globalPageCounterRef) => {
        if (!data || data.length === 0) {
            // Return a default page or message for empty data
            return [
                <Page size={"A2"} key="empty">
                    <PdfHeader totalPages={totalPages++} />
                    <Text style={{ textAlign: 'center', marginTop: 20 }}>No data available for {reportType} category</Text>
                    <PdfFooter pageNumber={totalPages} pathname={pathname} />
                </Page>
            ];
        }

        let maxRowCountPerPage = 95;
        const pages = [];
        let currentPageContent = [];
        let currentRowCount = 0;

        data.forEach((item, index) => {
            let itemRowCount = 0;

            if (item.rideName && item.rideName.length >= 48) {
                itemRowCount = Math.ceil(48 / 16);
            } else if (item.rideName) {
                itemRowCount = Math.ceil(item.rideName.length / 16);
            }

            currentRowCount += itemRowCount + 3;

            if (currentRowCount > maxRowCountPerPage) {
                pages.push(currentPageContent);
                currentPageContent = [];
                currentRowCount = 0;
            }

            currentPageContent.push(
                <View key={`additional_${index}`}>
                    <View
                        style={{
                            // backgroundColor: "#615AE41A",
                            border: '1px solid lightgrey',
                            flexDirection: "column",
                            marginTop: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginHorizontal: 30,
                            // borderBottom: "1px solid black",
                        }}
                        key={index}
                    >
                        <View
                            style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 5 }} >
                            <Text style={{ fontSize: 10 }}>Name: {item.name || "N/A"}</Text>
                            <Text style={{ fontSize: 10 }}>
                                Username: {item.username || "N/A"}
                            </Text>
                            <Text style={{ fontSize: 10 }}>
                                Rides: Showing (
                                {item.totalRides && item.totalRides > 0 ? "1" : "0"} -{" "}
                                {item.totalRides && item.totalRides >= 48 ? "48" : item.totalRides < 48 ? " " + item.totalRides : "0"} ) of {item.totalRides || "0"}
                            </Text>
                        </View>
                        {item.rideName && item.rideName.length > 0 && (
                            <View>
                                {[...Array(Math.min(Math.ceil(item.rideName.length / 16), 3))].map((_, rowIndex) => (
                                    <View key={rowIndex} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5, }} >
                                        {item.rideName.slice(rowIndex * 16, Math.min((rowIndex + 1) * 16, 48)).map((ride, rideIndex) => (
                                            <Text
                                                style={{
                                                    fontSize: 9,
                                                    border: "1px solid grey",
                                                    width: rideIndex === 15 ? "12%" : "11.5%",
                                                    marginRight: rideIndex === 15 ? "0px" : "1%",
                                                    textAlign: "center",
                                                    padding: 1,
                                                    paddingTop: 2,
                                                    paddingBottom: 0,
                                                    borderRadius: 10
                                                }}
                                                key={rideIndex}
                                            >
                                                {ride.rideName || "N/A"}
                                            </Text>
                                        ))}
                                    </View>
                                ))}
                            </View>
                        )}
                    </View>
                </View>
            );
        });

        // Add the remaining content to the last page
        if (currentPageContent.length > 0) {
            pages.push(currentPageContent);
        }

        // Wrap each page's content in a Page component
        const formattedPages = pages.map((pageContent, index) => {
            const pageCount = globalPageCounterRef.current + 1;
            globalPageCounterRef.current += 1;
            return (
                <Page size={"A2"} key={index}>
                    <PdfHeader totalPages={totalPages++} />
                    {index === 0 && renderCategoryHeader(reportType, period, startDate, endDate)}
                    <View style={{ marginTop: index === 0 ? 10 : 10 }} > {pageContent} </View>
                    <PdfFooter pageNumber={totalPages} pathname={pathname} />
                </Page>
            );
        });

        return formattedPages;
    };

    const globalPageCounterRef = React.useRef(0);

    const renderSummaryPdf = (safetyTableData, sportyTableData, quiteTableData, recklessTableData, quiteDangerousTableData, dangerousTableData) => {
        const safetyRows = generateRows(safetyTableData, "Safe", period, startDate, endDate, globalPageCounterRef);
        const sportyRows = generateRows(sportyTableData, "Sportive", period, startDate, endDate, globalPageCounterRef);
        const quiteRows = generateRows(quiteTableData, "Quite Safe", period, startDate, endDate, globalPageCounterRef);
        const recklessRows = generateRows(recklessTableData, "Reckless", period, startDate, endDate, globalPageCounterRef);
        const quiteDangerousRows = generateRows(quiteDangerousTableData, "Quite Dangerous", period, startDate, endDate, globalPageCounterRef);
        const dangerousRows = generateRows(dangerousTableData, "Dangerous", period, startDate, endDate, globalPageCounterRef);

        return [...(safetyRows || []), ...(sportyRows || []), ...(quiteRows || []), ...(recklessRows || []), ...(quiteDangerousRows || []), ...(dangerousRows || [])];
    };

    useEffect(() => {
        renderSummaryPdf(safetyTableData, sportyTableData, quiteTableData, recklessTableData, quiteDangerousTableData, dangerousTableData);
        setShowLoader(true);
        const loader = setTimeout(() => {
            setShowLoader(false);
        }, 2000);
        return () => clearTimeout(loader);
    }, [filtersADCS.durationFilter, filtersADCS.startDateTime, filtersADCS.endDateTime]);

    if (showLoader) {
        return <LoaderPdf />;
    }

    const summaryPdf = renderSummaryPdf(safetyTableData, sportyTableData, quiteTableData, recklessTableData, quiteDangerousTableData, dangerousTableData);

    return (
        <React.Fragment>
            {showLoader > 0 ? <LoaderPdf /> : ""}

            <PDFViewer style={{ height: "96%", width: "100%", marginTop: "20px", marginBottom: "5px" }} >
                <Document style={{ marginTop: "15px", marginBottom: "5px" }} >
                    {summaryPdf}
                </Document>
            </PDFViewer>
        </React.Fragment>
    );
}