import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Col,
  Row,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

import C2Chart from "./c2Chart";
import C3Chart from "./c3Chart";
import C4Chart from "./c4Chart";
import C5Chart from "./c5Chart";
import C6Chart from "./c6Chart";
import C1Chart from "./c1Chart";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup";

const CustomDateFilterMain = ({ loaderCount, handleSafetyTableData }) => {
    const [filter, setFilter] = useState();
    const [filters, setFilters] = useState({
        durationFilter: "CURRENT_YEAR",
        startDateTime: "",
        endDateTime: ""
    })

    const [startDateTime, setStartDateTime] = useState("");
    const [endDateTime, setEndDateTime] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);  

    useEffect(() => {
        setFilters( {...filters,
            startDateTime: startDateTime,
            endDateTime: endDateTime } )
    }, [startDateTime, endDateTime]);

  return (
    <React.Fragment>
        <Row>
            <Col xl={12}>
                <Card>
                    <CardHeader>
                    <div className="ms-auto">
                    <div style={{float:"right"}}>
                      <button
                        type="button"
                        data-tooltip-id="cd"
                        className={
                          filters.durationFilter == "CURRENT_DAY"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilters({...filters, durationFilter: "CURRENT_DAY"})}
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cw"
                        className={
                            filters.durationFilter == "CURRENT_WEEK"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilters({...filters, durationFilter: "CURRENT_WEEK"})}
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cm"
                        className={
                            filters.durationFilter == "CURRENT_MONTH"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilters({...filters, durationFilter:"CURRENT_MONTH"})}
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cy"
                        className={
                            filters.durationFilter == "CURRENT_YEAR"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilters({...filters, durationFilter:"CURRENT_YEAR"})}
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>{" "}
                      <button
                          type="button"
                          className={
                            filters.durationFilter == "CUSTOM_DURATION"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{setIsOpen(true);}}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                    </div>
                  </div>
                    </CardHeader>
                </Card>
            </Col>
        </Row>
        <C1Chart filter={filters} loaderCount={loaderCount} handleSafetyTableData={handleSafetyTableData}/>
        <C2Chart filter={filters} loaderCount={loaderCount} handleSafetyTableData={handleSafetyTableData}/>
        <C3Chart filter={filters} loaderCount={loaderCount} handleSafetyTableData={handleSafetyTableData}/>
        <C4Chart filter={filters} loaderCount={loaderCount} handleSafetyTableData={handleSafetyTableData}/>
        <C5Chart filter={filters} loaderCount={loaderCount} handleSafetyTableData={handleSafetyTableData}/>
        <C6Chart filter={filters} loaderCount={loaderCount} handleSafetyTableData={handleSafetyTableData}/>
      
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen}  setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter}/>
    </React.Fragment>
  );
};

export default CustomDateFilterMain;
