import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import {
    SC_COMPANY_ID,
    SC_DIVISION_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let FCMNotificationController = {
    async FCMNotificationRiskAlertUser( indexStart, filter, deviceFilter, startDateTime, endDateTime, subFilterType, userId, pageSize ) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);

        var queryFields={filterType : filter, subFilterType: subFilterType, origin: deviceFilter,};

        if (companyId.length > 0) {
            var reqPayload = {
                companyId: companyId,
                divisionId:divisionId,
                userId:userId,
                type: "USER_NOTIFICATION",
                sortBy: "createdAt",
                offset: indexStart,
                limit: pageSize,
                reportType:"AD_MULTI_USER_RISK_ALERT_LIST",
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                reportFields: JSON.stringify(queryFields)
            };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.REPORT,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

  

  
};

export default FCMNotificationController;