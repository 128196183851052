import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
// import UtilDateTime from "../../util/UtilDateTime.js";
// import { trimValue } from "../../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_DIVISION_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";
// var month1startDateTime = "2023-09-10 00:00:00";
// var month1endDateTime = "2023-09-16 23:59:59";
let AdminWeeklySummeryController = {
  async adminWeeklyDrivingBehaviour() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = { filterType: "CURRENT_WEEK" };

    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(dashBoardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminWeeklyLowRiskScoreController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var queryFields = {
      filterType: "CURRENT_WEEK",
      subFilterType: "LOW_RISK_RIDE",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminWeeklyMediumRiskScoreController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var queryFields = {
      filterType: "CURRENT_WEEK",
      subFilterType: "MEDIUM_RISK_RIDE",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminWeeklyHighRiskScoreController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var queryFields = {
      filterType: "CURRENT_WEEK",
      subFilterType: "HIGH_RISK_RIDE",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminWeeklyUserListController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = {
      filterType: "CURRENT_WEEK",
      subFilterType: "USER_FIRST_NAME",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RIDE_LIST_AND_RIDE_SUMMARY",
        dashboardFields: JSON.stringify(dashBoardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async adminWeeklyTotalRiskAlertsController(userID) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = userID;
    var queryFields = {
      filterType: "CURRENT_WEEK",
    };
    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RIDE_LIST_AND_RIDE_SUMMARY",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminWeeklySummaryController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var queryFields = {
      filterType: "CURRENT_WEEK",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RIDE_SUMMARY_AND_RISK_ALERT_COUNT",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminWeeklyRiskAlertUserListController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = {
      filterType: "CURRENT_WEEK",
      subFilterType: "USER_FIRST_NAME",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
        dashboardFields: JSON.stringify(dashBoardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminWeeklyRiskAlertsController(userID) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = userID;
    var queryFields = {
      filterType: "CURRENT_WEEK",fields: "riskValueList"
    };
    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        category:"ALERT_DATA,STRESS_STRAIN_DATA",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,

        dashboardType: "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminWeeklyDriverAnalysisController(startDateTime, endDateTime, norFilter) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var queryFields = {
      filterType: norFilter,
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        startDateTime: startDateTime,
        endDateTime: endDateTime,

        dashboardType: "AD_MULTI_USER_DRIVER_LIST_AND_ANALYSIS",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
};

export default AdminWeeklySummeryController;
