import React from 'react';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { VectorMap } from "react-jvectormap";
import "../Maps/jquery-jvectormap.scss";
import OverSpeedLocationMaps from '../Maps/LeafletMap/OverSpeedLocationMap';
const OverSpeedLocations = () => {
    const map = React.createRef(null);
    return (
        <React.Fragment>
            <Col xl={12} lg={12} xs={12} sm={12} md={12}>
                <Card  style={{ height: "550px",width:"100%" }}>
                    <CardHeader className='cardHeader'>
                    <div className="d-flex flex-wrap align-items-center mb-0">
                            <h5 className="sub_heading me-2">Overspeed Location</h5>
                        </div>
                    </CardHeader>
                    <CardBody>
                        

                        <div id="sales-by-locations" data-colors='["#5156be"]'>
                          
                            <div id="leaflet-map-custom-icons" className="leaflet-map ">
                    <OverSpeedLocationMaps />
                  </div>
                                
                            </div>
                        

                    
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default OverSpeedLocations;