import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Common/loader";
import "../UserAppBootSettings/appBootStyles.css";
import ValueFormat from "../../util/ValueFormat";
import MyRidesController from "../../controller/Rides/MyRides";
import AdminDashboardController from "../../controller/Dashboard/AdminDasahboardController";
import AppBootActiveUserdataList from "./appbootactiveuserdata";

const AdminAppBootSettings = () => {
  const [count, setCount] = useState(0);
  const [submitVisible, setSubmitVisible] = useState(true); 
  const [userNameList, setUserNameList] = useState("");
  const [userRides, setUserRides] = useState("");  
  const [apiData, setApiData] = useState();
  const [data, setData] = useState(AppBootActiveUserdataList);
  const [showLoader, setShowLoader] = useState(false);
  const [nextShow, setNextShow] = useState();
  const pageSize = 10;

  const deviceSettings = {  

    WORK: [
      {
        label: "Work Auto",
        value: "WORK_AUTO",
      },
      {
        label: "Personal Auto",
        value: "PERSONAL_AUTO",
      },
      {
        label: "Work Manual",
        value: "WORK_MANUAL",
      },
      {
        label: "Personal Manual",
        value: "PERSONAL_MANUAL",
      },
    ],
  };

  const [workFilter, setWorkFilter] = useState("");

  const [settings, setSettings] = useState(deviceSettings);

  const getRidesData = async () => {
    setShowLoader(true);
    var result = await MyRidesController.appBootMyRidesList(userNameList);
    setShowLoader(false);
    var updatedTableData = [];
    try {
      var resultJSON = JSON.parse(result);
      var resultJSONData = resultJSON.data;
      // console.log("getRidesData: " + JSON.stringify(resultJSONData));
      for (let i = 0; i < resultJSONData.length; i++) {
        var userRideDetail = resultJSONData[i];       
        var rideId = ValueFormat.parseAsString(userRideDetail.deviceId);
        var rideName = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.name)
        );
        var deviceMode =
          userRideDetail.deviceMode;
        var userId = ValueFormat.parseAsString(userRideDetail.userId);
        var divisionId = ValueFormat.parseAsString(userRideDetail.divisionId);

        var userDetails = {
          rideId: rideId,
          userId: userId,
          divisionId: divisionId,
          rideNo: rideName,
          mode: deviceMode,
        };
        if(i==0){
            setWorkFilter(deviceMode)
          }
    
        updatedTableData.push(userDetails);
      }

      setApiData(updatedTableData);
    } catch (errMess) {
      // console.log("Exception: ", errMess);
    }


    if (resultJSONData.length == 10) {
      setNextShow(true);
    } else {
      setNextShow(false);
    }
  };


  useEffect(()=>{
    getRidesData();
  },[userNameList])

  const appBootActiveUserList = async () => {
    // setShowLoader(true);
    var result = await AdminDashboardController.activeUserListAppBoot();
    var topUserListData = [];
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;      
    // console.log("topUserList: " + JSON.stringify(resultJSONData));
    resultJSONData.forEach((value,i) => {
      const {
        username,
        firstName = value.firstName.length > 0
          ? ValueFormat.capitalizeFirstLetter(value.firstName)
          : "N/A",        
        userId,
      } = value;
      
      if(i==0){
        setUserNameList(userId)
      }

      const resultTopList = {
        userId: userId,
        username: username,
        firstName:
          firstName.length > 0
            ? ValueFormat.capitalizeFirstLetter(firstName)
            : "N/A",       
      };
      topUserListData.push(resultTopList);
    });
    setData(topUserListData);
    // setShowLoader(false);
  };

  useEffect(() => {   
    appBootActiveUserList();
  }, []);

  const submit = () => {
    setSubmitVisible(true);
  };

  return (
    <div className="page-content">
      {count > 0 && <Loader />}
      <ToastContainer style={{ width: "auto" }} />

      <Container fluid>
        <Card>
          <CardHeader>
            <h2 className="card-title">App Boot Settings</h2>
          </CardHeader>
          <CardBody className="pb-0">
            <Row>
              <Col md={4}>
                <Row md={12}>
                  <Col md={5}>
                    <h4 className="headingStyles">User</h4>
                  </Col>
                  <Col md={7} p={0} m={0}>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={userNameList}
                        onChange={(e) => [
                          setUserNameList(e.target.value),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(data || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.userId}
                          >
                            {device.firstName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>  
                <Row md={12}>
                  <Col md={5}>
                    <h4 className="headingStyles"></h4>
                  </Col>
                  <Col md={7} p={0} m={0}>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={userRides}
                        onChange={(e) => [
                          setWorkFilter(e.target.value),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(apiData || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.mode}
                          >
                            {device.rideNo}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row md={12}>
                  <Col md={5}>
                    <h4 className="headingStyles">Ride Duration Type</h4>
                  </Col>
                  <Col md={7} p={0} m={0}>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={workFilter}
                        onChange={(e) => [
                          setWorkFilter(e.target.value),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(settings.WORK || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.value}
                          >
                            {device.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <br />
            <Row
              style={{
                borderTop: "1px solid #DFDEDE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col sm={4} style={{ paddingLeft: "0px", paddingBottom: "10px" }}>
                <div className="d-flex justify-content-center mt-2">
                  <button
                    className="btn btn-primary"
                    disabled={submitVisible}
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AdminAppBootSettings;
