import CryptoJS from "crypto-js";
import { Buffer } from 'buffer';
import md5 from 'md5';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

const SECRET_KEY = 'MotivAI@2022'
const Converter = {

  // CryptoJS ENCODE_DECODE

  encode(value, name) {
    try {
      const encode = CryptoJS.AES.encrypt(JSON.stringify(value), name).toString()
      return encode;
    } catch (e) {
      // console.log("encode err :", e)
    }
  },
  decode(value, name) {
    try {
      const decode = CryptoJS.AES.decrypt(value, name).toString(CryptoJS.enc.Utf8)
      return JSON.parse(decode);
    } catch (e) {
      // console.log("decrypt err :" ,e)
    }
  },

  // BASE64 ENCODE_DECODE

  encodeBase64(value, name) {
    const encodeBase64 = base64_encode(JSON.stringify(value), name);
    return encodeBase64;
  },
  decodeBase64(value, name) {
    const decodeBase64 = base64_decode(value, name);
    return JSON.parse(decodeBase64);
  },

  // MD5 ENCODE_DECODE

  convertMd5(value) {
    try {
      const convertMd5 = md5(value).toString();
      return convertMd5;

    } catch (e) {
      // console.log("md5 error :",e)
    }
  },

  // BUFFER

  convertBuffer(value, name) {
    const convertBuffer = Buffer.from(value, name).toString('base64');
    return convertBuffer
  },

  // SYMMETRIC_ASYMMETRIC

  symmetricEncrypt(value, name, IV) {
    // const SALT = 'MY SECRET KEY SALT'
    try {
      const Iv = 16
      const symmetric = CryptoJS.AES.encrypt(JSON.stringify(value), name,).toString();
      return symmetric
    } catch (e) {
      // console.log("symmetricEncryption err :", e)
    }

  },

  symmetricDecrypt(value, name, IV) {
    // const SALT = 'MY SECRET KEY SALT'
    try {
      const Iv = 16
      const symmetric = CryptoJS.AES.decrypt(value, name,).toString(CryptoJS.enc.Utf8)
      return symmetric
    } catch (e) {
      // console.log("symmetricEncryption err :", e)
    }

  },
  //ENCRYPT_DECRYPT USING CRYPTO GCM METHOD
  encryptDataGCM(data, secretKey) {
    try {
      const iv = CryptoJS.lib.WordArray.random(16); // 16 bytes IV

      const encrypted = CryptoJS.AES.encrypt(data, secretKey, {
        iv: iv,
        mode: CryptoJS.mode.GCM,
      });

      return {
        cipherText: encrypted.toString(),
        iv: iv.toString(),
      };
    } catch (e) {
      console.error("encryptDataGCM error: ", e);
    }
  },
  decryptDataGCM(cipherText, iv, secretKey) {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const parsedIv = CryptoJS.enc.Hex.parse(iv);

    const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
      iv: parsedIv,
      mode: CryptoJS.mode.GCM,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  },
  encryptDataCBC(data, secretKey) {
    var key = CryptoJS.enc.Utf8.parse(secretKey);
    var iv = CryptoJS.enc.Utf8.parse(secretKey.substring(0, 16));

    /*-- Encryption --*/
    var cipherText = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    return cipherText
  },
  decryptDataCBC(cipherText, secretKey) {
    var key = CryptoJS.enc.Utf8.parse(secretKey);
    var iv = CryptoJS.enc.Utf8.parse(secretKey.substring(0, 16));
    var decrypted = CryptoJS.AES.decrypt(cipherText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  },
  encryptDataCFB(data, secretKey) {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(16); // 16 bytes IV

    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.Pkcs7,
    });

    return {
      cipherText: encrypted.toString(),
      iv: iv.toString(),
    };
  },
  decryptDataCFB(cipherText, iv, secretKey) {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const parsedIv = CryptoJS.enc.Hex.parse(iv);

    const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
      iv: parsedIv,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  },

  encryptCBC(value, key) {
    var data = value;
    var key = CryptoJS.enc.Latin1.parse(key);
    var iv = CryptoJS.enc.Latin1.parse(key);
    var encryptedData = CryptoJS.AES.encrypt(
      data,
      key,
      {
        iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding
      });

    return encryptedData.toString()

    // console.log('encrypted: ' + encryptedData.toString()) ;
    // var decrypted = CryptoJS.AES.decrypt(encrypted,key,{iv:iv,padding:CryptoJS.pad.ZeroPadding});
    // console.log('decrypted: '+decrypted.toString(CryptoJS.enc.Utf8));
  },

  decryptCBC(value, key) {
    var key = CryptoJS.enc.Latin1.parse(key);
    var iv = CryptoJS.enc.Latin1.parse(key);
    var decrypted = CryptoJS.AES.decrypt(value, key, { iv: iv, padding: CryptoJS.pad.ZeroPadding });
    return decrypted.toString(CryptoJS.enc.Utf8)
  },

  encrypt(text) {
    return CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
  },

  decrypt(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

}
export default Converter;