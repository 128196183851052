export const pageNames = {
    USER_DASHBOARD: "USER_DASHBOARD",
    ADMIN_DASHBOARD: "ADMIN_DASHBOARD",
    SUPER_ADMIN_DASHBOARD:"SUPER_ADMIN_DASHBOARD",
    SUPER_ADMIN_AMAZON_DASHBOARD:"SUPER_ADMIN_AMAZON_DASHBOARD",

    USERS: "USERS",
    MY_PROFILE: "MY_PROFILE",
    MY_RIDES: "MY_RIDES",
    ALL_RIDES:"All_RIDES",
    COMPANY_PROFILE: "COMPANY_PROFILE",
    COMPANY_USERS:"COMPANY_USERS",
    FCM_NOTIFICATION_USERS:"FCM_NOTIFICATION_USERS",
    ABOUT: "ABOUT",
    LOGOUTALERT: "LOGOUTALERT",
    LOGOUT: "LOGOUT",
    FAQ: "FAQ",
    TERMSANDCONDITIONS: "TERMSANDCONDITIONS",
    REPORT: "REPORT",
    FEEDBACK: "FEEDBACK",
    LOGIN: "LOGIN",
    SETTINGS: "SETTINGS",
    DRIVINGBEHAVIOUR: "DRIVINGBEHAVIOUR",
    ADAS: "ADAS",
    OBD: "OBD",
    DMS: "DMS",
    MY_VEHICLE: "MY_VEHICLE",
    MY_DEVICE: "MY_DEVICE",
    MY_OBD: "MY_OBD",
    SITE: "SITE",
};