import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import { useLocation } from "react-router-dom";

export default function PdfFooterV1({pageNumber, totalPages, pathname}) {
  const footer = [
    { f: "https://motivai.tech" },
    { f: "https://app.motivai.tech" },
    { f: '"Drive Safe, Arrive Safe"' },
    { f: "A MotivAI Product" },
  ];
  const fontBold = {
    fontFamily: "Helvetica-Bold",
  };

  const pageTotal = totalPages;


  return (
    <View
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        borderBottom: "5px solid #615AE4",
        // backgroundColor: "#f4f4f4",
        borderTop: '1px solid lightgrey',
        display: "flex",
        flexDirection: "column",
        padding: 5,
        alignItems: "center",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            textAlign: "left",
            color: "#615AE4",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
          {footer[0].f}
        </Text>
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            textAlign: "right",
            color: "#615AE4",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
          {footer[1].f}
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          paddingRight: 30,
          paddingLeft: 30,
        }}
      >
        <View style={{ display: "flex", justifyContent: "flex-start", width:"30%" }}>
          <Text
            style={{
                fontSize: 10,
                fontWeight: 600,
                textAlign: "left",
                color: "#000",
                padding: 2,
                fontFamily: "Helvetica-Oblique"
              }}
          >
            {footer[2].f}
          </Text>
        </View>
        <View style={{ display: "flex", justifyContent: "center", width:"40%" }}>
        <Text
          style={{
            fontSize: 12,
            fontWeight: 400,
            textAlign: "center",
            color: "#000",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
        {`Page ${pageNumber}${(pathname?.pathname !== "/adminDashboard/CategorySummary" && pathname?.pathname !== "/drivingEfficeiency") ? ` of ${totalPages}` : ''}`}        </Text>
        </View>
        <View style={{ display: "flex", justifyContent: "flex-end", width:"30%" }}>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "right",
            color: "#000",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
          {footer[3].f}
        </Text>
        </View>
      </View>
    </View>
  );
}
