import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { VectorMap } from "react-jvectormap";
import "../Maps/jquery-jvectormap.scss";
import MapMarkerCustomIcons from "../Maps/LeafletMap/MapMarkerCustomIcons"

const Locations = ({getLocationDataPdf}) => {
    const [dataMapMarkerPdf, setDataMapMarkerPdf] = useState([]);
    const map = React.createRef(null);
    const getDataPdf = (data) => {
        
        setDataMapMarkerPdf(data)
    }
    useEffect(()=>{
        getLocationDataPdf(dataMapMarkerPdf)
    },[dataMapMarkerPdf])
    
    return (
        <React.Fragment>
            <Col xl={4}>
                <Card style={{height:550}}>
                    <CardHeader className="cardHeader" style={{padding:"5px"}}>
                        {/* <div className="d-flex flex-wrap align-items-center mb-0"> */}
                            <h5 className="card-title">Vehicle Last Location</h5>
                            {/* <div className="ms-auto">
                            </div>
                        </div> */}
                    </CardHeader>
                    <CardBody>
                        <div id="sales-by-locations" data-colors='["#5156be"]' style={{ height: "460px" }}>
                            <div style={{ width: "100%", height: 600 }}>
                                <div id="leaflet-map-custom-icons" className="leaflet-map">
                                    <MapMarkerCustomIcons getDataPdf={getDataPdf}/>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Locations;