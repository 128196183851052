import { Document, Line, PDFViewer, Page, Rect, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import LoaderPdf from "../../Common/loaderPdf";
import UtilDateTime from "../../../util/UtilDateTime";
import ValueFormat from "../../../util/ValueFormat";
import PdfHeader from "../Components/pdfHeader";
import PdfHeaderV1 from "../Components/pdfHeaderV1";
import PdfHeading from "../Components/pdfHeading";
import PdfFooter from "../Components/pdfFooterV1";
import { useLocation } from "react-router-dom";

export default function ColouredRiskSummaryPdf({ riskRideData, filtersADRS }) {
    // console.log("riskRideData", riskRideData);
    const [showLoader, setShowLoader] = useState(1);
    const pathname = useLocation();
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    // Check if riskRideData is available and its properties are not empty
    const isDataAvailable = riskRideData && riskRideData.length > 0 &&
        riskRideData[0].xAxisData.length > 0 &&
        riskRideData[0].rideCount.length > 0 &&
        riskRideData[0].redAlertCount.length > 0 &&
        riskRideData[0].amberAlertCount.length > 0 &&
        riskRideData[0].unameData.length > 0;

    if (!isDataAvailable) {
        return (
            <PDFViewer style={{ height: "96%", width: "100%", marginTop: "20px", marginBottom: "5px" }}>
                <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
                    <Page>
                        <PdfHeaderV1 />
                        <View style={{ paddingHorizontal: 30, marginTop: 10 }}>
                            <Text style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>No data available to display</Text>
                        </View>
                        <PdfFooter pageNumber={1} totalPages={1} pathname={pathname} />
                    </Page>
                </Document>
            </PDFViewer>
        );
    }

    let period = "";
    let startDate = "";
    let endDate = "";
    if (filtersADRS.durationFilter === "CURRENT_YEAR") {
        period = ValueFormat.smallLettersFormat(filtersADRS.durationFilter);
        startDate = UtilDateTime.getCurrentYear(filtersADRS.startDateTime);
        endDate = "";
    } else if (filtersADRS.durationFilter === "CURRENT_MONTH") {
        period = ValueFormat.smallLettersFormat(filtersADRS.durationFilter);
        startDate = UtilDateTime.getCurrentMonth(filtersADRS.startDateTime);
        endDate = "";
    } else if (filtersADRS.durationFilter === "CURRENT_WEEK") {
        period = ValueFormat.smallLettersFormat(filtersADRS.durationFilter);
        startDate = UtilDateTime.getCurrentWeek1(filtersADRS.startDateTime);
        endDate = "";
    } else if (filtersADRS.durationFilter === "CURRENT_DAY") {
        period = ValueFormat.smallLettersFormat(filtersADRS.durationFilter);
        startDate = UtilDateTime.getCurrentDay(filtersADRS.startDateTime);
        endDate = "";
    } else {
        period = ValueFormat.smallLettersFormat(filtersADRS.durationFilter);
        startDate = filtersADRS.startDateTime.split(" ")[0];
        endDate = filtersADRS.endDateTime.split(" ")[0];
    }

    const periodFormat = [period, startDate, endDate];
    const heading = "Admin Dashboard (Risk Summary)";
    var currentDate = new Date();
    const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();
    var totalPagess = 0;

    const fontBold = { fontFamily: "Helvetica-Bold" };

    const xAxisData = riskRideData[0].xAxisData;
    const rideCount = riskRideData[0].rideCount;
    const redAlertCount = riskRideData[0].redAlertCount;
    const amberAlertCount = riskRideData[0].amberAlertCount;
    const username = riskRideData[0].unameData;

    const xAxisDataLength = riskRideData[0].xAxisData.length;

    const transformedRiskRideData = xAxisData.map((name, index) => ({
        value: rideCount[index],
        value1: amberAlertCount[index],
        value2: redAlertCount[index],
        title: name,
        uName: username[index],
    }));

    const generatePages = () => {
        const pages = [];
        const itemsPerPage = 25; // Number of items to display per page
        const totalItems = xAxisDataLength;
        const totalPages = Math.ceil(totalItems / itemsPerPage);

        const allValues = transformedRiskRideData
            ? transformedRiskRideData.flatMap((item) => [
                item.value,
                item.value1,
                item.value2,
            ])
            : [];

        const maxValue = Math.max(...allValues);

        for (let page = 1; page <= totalPages; page++) {
            const startIdx = (page - 1) * itemsPerPage;
            const endIdx = Math.min(startIdx + itemsPerPage, totalItems);

            // Slice your data based on the start and end indices
            const slicedData = transformedRiskRideData.slice(startIdx, endIdx);

            // Apply the modifications for the sliced data

            const modifiedRiskRideData = slicedData.map((item) => {
                const s6Item = { ...item };

                if (s6Item.value > 0) {
                    s6Item.value = (s6Item.value / maxValue) * 880;
                } else {
                    s6Item.value = 0;
                }

                if (s6Item.value1 > 0) {
                    s6Item.value1 = (s6Item.value1 / maxValue) * 880;
                } else {
                    s6Item.value1 = 0;
                }

                if (s6Item.value2 > 0) {
                    s6Item.value2 = (s6Item.value2 / maxValue) * 880;
                } else {
                    s6Item.value2 = 0;
                }

                return s6Item;
            });

            //   Render a new page
            const pageContent = (
                <Page size={'A2'} key={page}>
                    {/* Add your existing page content here */}
                    <PdfHeaderV1 totalPagess={totalPagess++} />
                    {/* {page === 1 ? ( */}
                    <View style={{ paddingHorizontal: 30, marginTop: 10 }}>
                        <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
                    </View>
                    {/* ) : (
                        ""
                    )} */}

                    <View style={{ fontSize: "11px", marginTop: "15px", display: "flex", justifyContent: "space-between", flexDirection: "row", paddingHorizontal: 30 }} >
                        <Text style={[fontBold, { fontSize: 11 }]}> 1. User Ride & Risk Count </Text>
                    </View>
                    <Text style={{ fontSize: "10px", marginBottom: "5px", marginTop: "1px", paddingHorizontal: 30 }} >
                        {`Showing ${startIdx + 1} - ${endIdx} users out of ${totalItems} users `}
                    </Text>

                    <View style={{ flexDirection: "column", marginTop: "0px", height: "87%", paddingVertical: 10, marginHorizontal: 30, paddingHorizontal: 30,
                          //  backgroundColor: "#f4f4f4",
                          border: '1px solid lightgrey',
                          borderRadius: 10, }} >
                        <View style={{ height: '30px'}}>
                        <Svg viewBox="0 0 550 20" style={{ flexDirection: "row", marginTop: 0, marginBottom: 0 }} >
                            <Rect x="50" y="1" rx="2" ry="2" width="20" height="10" fill="#006877" />
                            <Text x="75" y="50%" style={{ height: "10", fontSize: "11px" }}> Ride Count (RC) </Text>
                            <Rect x="190" y="1" rx="2" ry="2" width="20" height="10" fill="#E21212" />
                            <Text x="215" y="50%" style={{ height: "10", fontSize: "11px" }}> High Risk Count (HRC) </Text>
                            <Rect x="350" rx="2" ry="2" width="20" height="10" fill="#FFBF00" />
                            <Text x="375" y="50%" style={{ height: "10", fontSize: "11px" }}> Medium Risk Count (MRC) </Text>
                        </Svg>
                        </View>
                        <Svg width="100%" height="1400">
                            {/* Y-Axis */}
                            <Line x1="120" y1="0" x2="120" y2="1350" stroke="black" strokeWidth="1" />

                            {modifiedRiskRideData.map((item, index) => (
                                <Rect key={index} x="120.5" y={index * 53} width={920} height="40" fill="#d9d9d9" />
                            ))}

                            {/* Bars with Static Heights */}
                            {modifiedRiskRideData.map((item, index) => (
                                <Rect key={index} x="120.5" y={index * 53} width={item.value || 0} height="10" fill="#006877" />
                            ))}

                            {modifiedRiskRideData.map((item, index) => (
                                <Rect key={index} x="120.5" y={index * 53 + 15} width={item.value2 || 0} height="10" fill="#E21212" />
                            ))}

                            {modifiedRiskRideData.map((item, index) => (
                                <Rect key={index} x="120.5" y={index * 53 + 30} width={item.value1 || 0} height="10" fill="#FFBF00" />    
                            ))}

                            {/* Bar labels */}
                            {slicedData.map((item, index) => (
                                <Text key={index} x={modifiedRiskRideData[index].value + 124} y={index * 53 + 9} fontSize="10" fill="black" > {item.value || "0"} </Text>
                            ))}
                            {slicedData.map((item, index) => (
                                <Text key={index} x={modifiedRiskRideData[index].value2 + 124} y={index * 53 + 24} fontSize="10" fill="black" > {item.value2 || "0"} </Text>
                            ))}
                            {slicedData.map((item, index) => (
                                <Text key={index} x={modifiedRiskRideData[index].value1 + 124} y={index * 53 + 39} fontSize="10" fill="black" > {item.value1 || "0"} </Text>
                            ))}

                            {slicedData.map((item, index) => (
                                <Text key={index} x={1045} y={index * 53 + 9} fontSize="10" fill="black" > {"RC"} </Text>
                            ))}
                            {slicedData.map((item, index) => (
                                <Text key={index} x={1045} y={index * 53 + 24} fontSize="10" fill="black" > {"HRC"} </Text>
                            ))}
                            {slicedData.map((item, index) => (
                                <Text key={index} x={1045} y={index * 53 + 39} fontSize="10" fill="black" > {"MRC"} </Text>
                            ))}

                            {slicedData.map((item, index) => {
                                const maxLength = 17; // Maximum length before breaking
                                const maxBreakLength = 30; // Maximum length before breaking at second space

                                if (item.title && item.title.length <= maxLength) {
                                    return (
                                        <React.Fragment key={index}>
                                            <Text x={0} y={index * 53 + 17} fontSize="11" fill="black" textAlign="right" > {item.title || ""} </Text>
                                            <Text x={0} y={index * 53 + 27} fontSize="11" fill="black" textAlign="right" > {"(" + item.uName + ")" || ""} </Text>
                                        </React.Fragment>
                                    );
                                } else {
                                    // Find the index of the last space before maxLength
                                    const spaceIndex = item.title.lastIndexOf(" ", maxLength);
                                    if (spaceIndex !== -1) {
                                        // Split the string at the last space
                                        return (
                                            <React.Fragment key={index}>
                                                <Text x={0} y={index * 53 + 17} fontSize="11" fill="black" textAlign="right" > {item.title.substring(0, spaceIndex)} </Text>
                                                <Text x={0} y={index * 53 + 27} fontSize="11" fill="black" textAlign="right" > {item.title.substring(spaceIndex + 1)} </Text>
                                                <Text x={0} y={index * 53 + 37} fontSize="11" fill="black" textAlign="right" > {"(" + item.uName + ")" || ""} </Text>
                                            </React.Fragment>
                                        );
                                    } else {
                                        // If there's no space before maxLength, break the string at maxLength
                                        return (
                                            <React.Fragment key={index}>
                                                <Text x={0} y={index * 53 + 17} fontSize="11" fill="black" textAlign="right" > {item.title.substring(0, maxLength)} </Text>
                                                <Text x={0} y={index * 53 + 27} fontSize="11" fill="black" textAlign="right" > {item.title.substring(maxLength)} </Text>
                                                <Text x={0} y={index * 53 + 37} fontSize="11" fill="black" textAlign="right" > {"(" + item.uName + ")" || ""} </Text>
                                            </React.Fragment>
                                        );
                                    }
                                }
                            })}
                        </Svg>
                        <View style={{ textAlign: 'center' }} >
                            <Text style={{ fontSize: "15px" }}> Note: X-axis for Count, Y-axis for User </Text>
                        </View>
                    </View>
                    <PdfFooter pageNumber={totalPagess} totalPages={totalPages} pathname={pathname} />
                </Page>
            );

            pages.push(pageContent);
        }

        return pages;
    };

    const RenderedPages = generatePages();

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(0);
        }, 4000);
    }, []);

    return (
        <React.Fragment>
            {showLoader > 0 ? <LoaderPdf /> : ""}
            <PDFViewer style={{ height: "96%", width: "100%", marginTop: "20px", marginBottom: "5px" }} >
                <Document style={{ marginTop: "15px", marginBottom: "5px" }} > {RenderedPages} </Document>
            </PDFViewer>
        </React.Fragment>
    );
}