const ActiveUserdataList = [{
    firstName:"-",
    username:"-",
    totalRide:"-",    
    safeRides:"-",
    unSafeRides:"-",
    safePer:"-",
    unSafePer:"-",
    safeOverAll:"-",
    unSafeOverAll:"-",
},



];

export default ActiveUserdataList;