export const trimValue = value => {
  var result = '';
  if (value != null && value != '' && value.length > 0) { 
    return value; 
  }
  return result;
};

export const validateName = name => {
    if (!name.length) {
      return { valid: false, error: "Name is required" };
    }
    return { valid: true, error: null };
};

export const validateFirstName = name => {
    if (!name.length) { 
      return { valid: false, error: "First name is required" };
    }
    return { valid: true, error: null };
};

export const validateLastName = name => { 
    if (!name.length) {
      return { valid: false, error: "Last name is required" };
    }
    return { valid: true, error: null };
};
   
 export const validateUsername = username => {
    if (!username.length) { 
      return { valid: false, error: 'Username is required' };
    } 
    if (!username.match(/^[0-9]{8,12}$/gm)) {
      return { valid: false, error: 'Please enter a valid username' };
    } 
    return { valid: true, error: null };
}; 

export const validatePassword = password => {
    if (!password.length) {
      return { valid: false, error: "Password is required" };
    }
    if (password.length <= 5) {
      return { valid: false, error: "Password is too short" };
    } 
    if (password.length >= 15) {
        return { valid: false, error: "Password is too long" };
      } 
    return { valid: true, error: null };  
}; 

export const validatePhone = phone => {
    if (!phone.length) {
      return { valid: false, error: 'Phone is required' };
    }
  
    if (!phone.match(/^[+][(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/gm)) {
      return { valid: false, error: 'Please enter a valid phone number' };
    }
  
    return { valid: true, error: null };
}
  
export const validateEmail = email => {
    if (!email.length) {
      return { valid: false, error: "Email is required" };
    }
    if (!email.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
      return { valid: false, error: "Please enter a valid email." };
    }
    return { valid: true, error: null };
};
  