import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IconButton, InputAdornment } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const YearPopupUsageHistory = ({ setStartDateTime, setEndDateTime , setFilter}) => {
  const currentDate = new Date();
  const nowYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(nowYear);
  const handleAccept = (date) => {
    // This function is called when the user clicks "OK"
    setFilter("CUSTOM_DURATION");
    const selectedYear = date.getFullYear();
    const startDateTime = `${selectedYear}-01-01 00:00:00`;
    const endDateTime = `${selectedYear}-12-31 23:59:59`;
    // console.log(startDateTime,endDateTime,"endTime")
    setSelectedYear(selectedYear);
    setStartDateTime(startDateTime);
    setEndDateTime(endDateTime);
  };

  return (
    <div style={{ width: "125px", padding: "0px" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DemoContainer sx={{ px: 1 }} components={["MobileDatePicker"]}>
          <DemoItem>
            <MobileDatePicker
              className="MuiTypography-overline"
              value={new Date(selectedYear, 0, 1)}
              maxDate={currentDate}
              //   onChange={handleDateChangeTo}
              onAccept={handleAccept}
              minDate={new Date(2021, 0, 1)}
              views={["year"]}
              format="yyyy"
              ampm={false}
              minutesStep={1}
              sx={{"& .MuiInputBase-input": {
                // width:"50px",
                fontSize: "12px"
              }}}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    "& .MuiInputBase-input": {
                     fontSize:'12px'// Adjust padding as needed
                    },
                  },
                  InputProps: {
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => console.log("Edit clicked")}
                          size="small"
                        >
                          <EditIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default YearPopupUsageHistory;
