import { initializeApp, getApps } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

const firebaseConfig = {
        apiKey: "AIzaSyC7l3tM-Qsc2TMhEnTbonap_rrYpIqMS94",
        authDomain: "motiv-ai.firebaseapp.com",
        projectId: "motiv-ai",
        storageBucket: "motiv-ai.appspot.com",
        messagingSenderId: "715827859685",
        appId: "1:715827859685:web:e429f91f5f6ca2192b440c",
        measurementId: "G-S970Z0DCC3"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export default messaging;