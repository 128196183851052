import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import {
    SC_COMPANY_ID,
    SC_DIVISION_ID,
    SC_MODULE_ID,
    SC_USER_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let AppBootSettingsController = {
    async getSettings() {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        if (
            companyId.length > 0 &&
            divisionId.length > 0 &&
            userId.length > 0
        ) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                type: "USER_APP_BOOT_SETTING",
                sortBy: "createdAt",
                sortOrder: "ASC",
                offset: "0",
                limit: "100",
                
            };
            return await ApiService.callGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.USER_PREFERENCE,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async postSettings(postSettings) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        var moduleId = UserSessionController.getLocalSessionValue(SC_MODULE_ID);

        if (
            companyId.length > 0 &&
            divisionId.length > 0 &&
            userId.length > 0
        ) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                moduleId:moduleId,
                userId: userId,
                name:"User App Boot Setting",
                userPrefAppBootSetting:postSettings,
                status:"REGISTERED",
                type: "USER_APP_BOOT_SETTING",
              
            };
            return await ApiService.callPOST(
                apiConfig.API_VERSION_1,
                apiEndPoint.USER_PREFERENCE,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "code": "412", "error" : "Request Process Failed", "data": []}';
        }
    },

    async putSettings(id,putSettings) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        var moduleId = UserSessionController.getLocalSessionValue(SC_MODULE_ID);

        if (
            companyId.length > 0 &&
            divisionId.length > 0 &&
            userId.length > 0
        ) {
            var reqParams = {
                id: id,
                companyId: companyId,
                divisionId: divisionId,
                moduleId:moduleId,
                userId: userId,
                name:"User App Boot Setting",
                userPrefAppBootSetting:putSettings,
                status:"REGISTERED",
                type: "USER_APP_BOOT_SETTING",
              
            };
            return await ApiService.callPUT(
                apiConfig.API_VERSION_1,
                apiEndPoint.USER_PREFERENCE,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

};

export default AppBootSettingsController;