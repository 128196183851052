import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CardBody,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { Card, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Tooltip as ReactTooltip } from "react-tooltip";

import logo from "../../assets/images/logo-sm.svg";
// import CarouselPage from './CarouselPage';
import "bootstrap/dist/css/bootstrap.min.css";
import motivLogo from "../../assets/images/ruduicon.png";
import { CSVLink } from "react-csv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import MyRidesController from "../../controller/Rides/MyRides.js";
import ValueFormat from "../../util/ValueFormat.js";
import { SC_USER_FIRST_NAME, SC_USER_ID } from "../../constant/StorageConstant";
import { useDispatch, useSelector } from "react-redux";
import MyRidedataList from "../MyRides/myridetabledata"; 



function MyRidesNew() {
    const [workFilter, setWorkFilter] = useState("");
    const [filterDateList, setFilterDateList] = useState("allRidesDeatils");
    const [filterDays, setFilterDays] = useState("");
    const [excelData, setExcelData] = useState([]);
    const [ pageNumber , setPageNumber] = useState(1);
    const[nextShow, setNextShow] = useState();
    const[prevShow, setPrevShow] = useState();
    const[apiData, setApiData] = useState(MyRidedataList)
    const [indexStart, setIndexStart] = useState(0);
    const [searchText , setSearchText] = useState("");
    const[userId, setUserId]= useState(localStorage.getItem(SC_USER_ID))
    const pageSize = 10;
    const inputRef = useRef()


    const handleFilterValues = (value, filterDateList) => {
    setFilterDateList(filterDateList);
    setFilterDays(value);
  };

  
  
  const previous = () =>{
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1)
  }
 const next = () =>{
  setIndexStart(indexStart + pageSize);
  setPageNumber(pageNumber + 1)
  }   

  const getRidesData = async () => {
    var result = await MyRidesController.myRidesList(
      indexStart,
      filterDays,
      workFilter,
      searchText,
      pageSize
    );
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    // console.log("vv", resultJSONData);
    var updatedTableData = [];

    // console.log("getRidesData: " + JSON.stringify(resultJSONData));
    try {
      var resultJSON = JSON.parse(result);
      var resultJSONData = resultJSON.data;
      var endTime = "N/A",
        driveScore = "0",
        distance = "0",
        duration = "0",
        deviceMode = "N/A";


      for (let i = 0; i < resultJSONData.length; i++) {
        var userRideDetail = resultJSONData[i];
        var categoryTemp = ValueFormat.parseAsInt(
          userRideDetail.rideBox.rideBox
        );
        var category =
          categoryTemp == 1
            ? "Safe Driver"
            : categoryTemp == 2
            ? "Sportive Driver"
            : categoryTemp == 3
            ? "Quiet Safe Driver"
            : categoryTemp == 4
            ? "Reckless Driver"
            : categoryTemp == 5
            ? "Quiet Dangerous"
            : "Dangerous Driver";
        var rideId = ValueFormat.parseAsString(userRideDetail.deviceId);

        var rideName = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.name)
        );

        var status = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.status)
        );

        var statusTemp = "Completed";
        if (i === 0 && indexStart === 0 && status === "In progress") {
          statusTemp = "In progress";
        } else if (i === 0 && indexStart === 0 && status === "Registered") {
          statusTemp = "Registered";
        } else if (status === "Completed") {
          statusTemp = "Completed";
        } else {
          statusTemp = "Completed (*)";
        }

        var startTime = ValueFormat.parseAsString(
          userRideDetail.startDateTimeToTimeZone
        );

        var mobile = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.origin)
        );

        var event = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.subType)
        );

        var distanceDataCount =
          userRideDetail.distanceDataCount.length > 0
            ? userRideDetail.distanceDataCount
            : "-";
        var alertDataCount = ValueFormat.parseAsInt(
          userRideDetail.alertDataCount
        );

        // var startDataCount =
        //   userRideDetail.startDataCount.length > 0
        //     ? userRideDetail.startDataCount
        //     : "-";
        // var endDataCount =
        //   userRideDetail.endDataCount.length > 0
        //     ? userRideDetail.endDataCount
        //     : "-";
        endTime = ValueFormat.parseAsString(
          userRideDetail.endDateTimeToTimeZone
        );
        driveScore = Math.round(
          ValueFormat.parseAsInt(userRideDetail.drivingScore)
        );

        distance = ValueFormat.parseAsFloat(userRideDetail.kiloMeter, 2);

        duration = ValueFormat.parseAsFloat(userRideDetail.travelTime, 2);

        deviceMode =
          userRideDetail.deviceMode == "WORK_AUTO" ||
          userRideDetail.deviceMode == "PERSONAL_AUTO"
            ? "Auto"
            : userRideDetail.deviceMode == "WORK_MANUAL" ||
              userRideDetail.deviceMode == "PERSONAL_MANUAL"
            ? "Manual"
            : "N/A";
        var userId = ValueFormat.parseAsString(userRideDetail.userId);
        var divisionId = ValueFormat.parseAsString(userRideDetail.divisionId);

        var stressStrain = ValueFormat.parseAsInt(
          userRideDetail.stressStrainDataCount
        );

        var userDetails = {
          RideId: rideId,
          UserId: userId,
          DivisionId: divisionId,
          rideNo: rideName,
          status: status,
          StatusTemp: statusTemp,
          startTime: startTime,
          endTime: endTime,
          mobile: mobile,
          event: event,
          score: driveScore,
          duration: duration,
          distance: distance,
          mode: deviceMode,
          alerts: alertDataCount,
          category: category,
          distanceDataCount: distanceDataCount,
          stressStrain: stressStrain,
        };

        updatedTableData.push(userDetails);
      }

      setApiData(updatedTableData);

    } catch (errMess) {
      // console.log("Exception: ", errMess);
    }
    if (indexStart == 0) {
      setPrevShow(false);
    } else if (indexStart > 0) {
      setPrevShow(true);
    }

    if (resultJSONData.length == 10) {
      setNextShow(true);
    } else {
      setNextShow(false);
    }
  };


  const exportExcelData = async () => {
    var dataExcel = [];
    var result = await MyRidesController.myRidesListExport(userId);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    // console.log(
    //   "resultJSONData myRidesListExport : " + JSON.stringify(resultJSONData)
    // );

    for (let i = 0; i < resultJSONData.length; i++) {
      var drivingScore = "0",
        rideName = "N/A",
        /*status = 'N/A', origin ='N/A',subType = 'N/A',*/ alerts = 0,
        createdTime = "N/A",
        dayPercentage = "0",
        nightPercentage = "100%" /*accessLevel = 'N/A',*/,
        endTime = "N/A",
        city = "N/A",
        state = "N/A",
        country = "N/A",
        /*locationName = 'N/A',*/ zipCode = "N/A",
        anticipation = "0",
        drivingSkill = "0",
        selfConfidence = "0",
        travelTime = "0Mins",
        totalKiloMeter = "0Kms",
        urbanPercent = "0%",
        ruralPercent = "0%",
        highwayPercent = "0%";
      var rideObject = resultJSONData[i];
      var deviceField = rideObject.deviceField;

      try {
        // var totalPercentage = 100;

        rideName = rideObject.name.length > 0 ? rideObject.name : "N/A";
        // status         = rideObject.status.length > 0 ?  rideObject.status : 'N/A';
        // origin         = rideObject.origin.length > 0 ? rideObject.origin : 'N/A';
        alerts = rideObject.alertDataCount > 0 ? rideObject.alertDataCount : 0;
        createdTime =
          rideObject.startDateTimeToTimeZone.length > 0
            ? rideObject.startDateTimeToTimeZone
            : "N/A";
        // subType        = rideObject.subType.length > 0 ?  rideObject.subType : 'N/A';
        dayPercentage =
          rideObject.dayPercentage > 0
            ? parseFloat(rideObject.dayPercentage).toFixed(0)
            : 0;
        nightPercentage =
          rideObject.dayPercentage > 0 ? 100 - dayPercentage + "%" : 100 + "%";
        // locationName    =  rideObject.locationName.length > 0 ? rideObject.locationName : 'N/A';
        // accessLevel     = rideObject.accessLevel.length > 0 ? rideObject.accessLevel : 'N/A';
        city =
          deviceField.city != null && deviceField.city.length > 0
            ? deviceField.city
            : "N/A";
        state =
          deviceField.state != null && deviceField.state.length > 0
            ? deviceField.state
            : "N/A";
        country =
          deviceField.country != null && deviceField.country.length > 0
            ? deviceField.country
            : "N/A";
        zipCode =
          deviceField.zipCode != null && deviceField.zipCode > 0
            ? deviceField.zipCode
            : "N/A";

        endTime =
          rideObject.endDateTimeToTimeZone != null
            ? rideObject.endDateTimeToTimeZone
            : "N/A";
        anticipation =
          rideObject.anticipation != null && rideObject.anticipation > 0
            ? parseFloat(rideObject.anticipation).toFixed(0)
            : 0;
        drivingSkill =
          rideObject.drivingSkill != null && rideObject.drivingSkill > 0
            ? parseFloat(rideObject.drivingSkill).toFixed(0)
            : 0;
        drivingScore =
          rideObject.drivingScore != null && rideObject.drivingScore > 0
            ? parseFloat(rideObject.drivingScore).toFixed(0)
            : 0;
        selfConfidence =
          rideObject.selfConfidence != null && rideObject.selfConfidence > 0
            ? parseFloat(rideObject.selfConfidence).toFixed(2)
            : 0;
        travelTime =
          rideObject.travelTime != null && rideObject.travelTime > 0
            ? parseFloat(rideObject.travelTime).toFixed(2) + " Mins"
            : 0 + " Mins";
        totalKiloMeter =
          rideObject.kiloMeter != null && rideObject.kiloMeter > 0
            ? parseFloat(rideObject.kiloMeter).toFixed(2) + " Kms"
            : 0 + " Kms";
        urbanPercent =
          rideObject.urbanPercent != null && rideObject.urbanPercent > 0
            ? parseFloat(rideObject.urbanPercent).toFixed(0) + "%"
            : 0 + "%";
        ruralPercent =
          rideObject.ruralPercent != null && rideObject.ruralPercent > 0
            ? parseFloat(rideObject.ruralPercent).toFixed(0) + "%"
            : 0 + "%";
        highwayPercent =
          rideObject.highwayPercent != null && rideObject.highwayPercent > 0
            ? parseFloat(rideObject.highwayPercent).toFixed(0) + "%"
            : 0 + "%";
      } catch (errMess) {
        // console.log("MyRide Export Excel Error Message :" + errMess);
      }

      var userDetails = {
        "Ride No": rideName,
        // 'Status': status,
        "Driving Score": drivingScore,
        "Driving Skill": drivingSkill,
        Anticipation: anticipation,
        "Self Confidence": selfConfidence,
        "Start Time": createdTime,
        "End Time": endTime,
        "No Of Alerts": alerts,
        "Day Percentage": dayPercentage + "%",
        "Night Percentage": nightPercentage,
        // 'From - Location Name' : locationName,
        "To-City": city,
        "To-State": state,
        "To-Country": country,
        "To-Zip Code": zipCode,
        "Urban Percentage": urbanPercent,
        "Rural Percentage": ruralPercent,
        "Highway Percentage": highwayPercent,
        // 'Mobile' : origin,
        // 'Event' : subType,
        Distance: totalKiloMeter,
        Duration: travelTime,
      };
      dataExcel.push(userDetails);
    }
    setExcelData(dataExcel);
  };

  useEffect(()=>{
    getRidesData();
    
  },[indexStart, filterDays,workFilter ])

  useEffect(()=>{
    exportExcelData()
  },[]);

  const handleSearch = () => {
    getRidesData()
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Rides" breadcrumbItem="Rides" />
        {/* <Row>
                <Col className="col-12">
                    <Card>
                        <CardHeader>
                            <h4 className="card-title">Default Datatable</h4>
                            <p className="card-title-desc">DataTables has most features enabled by
                                default, so all you need to do to use it with your own tables is to call
                                the construction function: <code>$().DataTable();</code>.
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Table columns={columns} data={data} />
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}

        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader style={{padding:"0px"}}>
                <Row>                 
                  <Col xl={4} lg={4} md={4} style={{borderRight:"1px solid #DFDEDE",paddingRight:0}} >
                  
                    <div style={{borderBottom:"1px dashed #DFDEDE",}}>
                    <h4 className="card-title mb-3 mt-3" style={{textAlign:"center"}} >Ride</h4>

                    </div>
                    <div className="mt-3 mb-3" style={{textAlign:"center"}}>
                      <button
                        type="button"
                        className={
                          workFilter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }onClick={()=> setWorkFilter("")}>
                        All
                      </button>
                      <button
                        type="button"
                        className={
                          workFilter == "WORK"
                            ? "btn btn-secondary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={()=> setWorkFilter("WORK")}
                      >
                        Work
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          workFilter == "PERSONAL"
                            ? "btn btn-secondary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={()=> setWorkFilter("PERSONAL")}
                      >
                        Personal
                      </button>{" "}
                    </div>
                   
                    
                   
                  </Col>

                  <Col xl={4 } lg={4} md={4} style={{borderRight:"1px solid #DFDEDE",padding:0}}>
                  <div className="ms-auto ">
                  <div style={{borderBottom:"1px dashed #DFDEDE"}}>
                    <h4 className="card-title mb-3 mt-3" style={{textAlign:"center"}} >Duration</h4>

                    </div>
                    <div className="mt-3 mb-3" style={{textAlign:"center"}}>
                      <button
                        type="button"
                        className={
                          filterDateList == "allRidesDeatils"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }      
                      >
                        All
                      </button>
                      <button
                        type="button"
                        className={
                          filterDateList == "CdRidesDeatils"
                            ? "btn btn-secondary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() =>
                          handleFilterValues("CURRENT_DAY", "CdRidesDeatils")
                        }
                      >
                        CD
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filterDateList == "CwRidesDeatils"
                            ? "btn btn-secondary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() =>
                          handleFilterValues("CURRENT_WEEK", "CwRidesDeatils")
                        }
                      >
                        CW
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filterDateList == "CmRidesDeatils"
                            ? "btn btn-secondary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() =>
                          handleFilterValues("CURRENT_MONTH", "CmRidesDeatils")
                        }
                      >
                        CM
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filterDateList == "CYRidesDeatils"
                            ? "btn btn-secondary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() =>
                          handleFilterValues("CURRENT_YEAR", "CYRidesDeatils")
                        }
                      >
                        CY
                      </button>{" "}
                    </div>
                  </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} style={{paddingLeft:"0px"}}>
                  <div >
                  <div style={{borderBottom:"1px dashed #DFDEDE"}}>
                    <h4 className="card-title mb-3 mt-3" style={{textAlign:"center"}}>Export</h4>

                    </div>
                    {/* <CopyToClipboard text={JSON.stringify(copyData)}>
                        <button
                          onClick={() => setCopyData(data)}
                          type="button"
                          className="btn btn-secondary buttons-copy buttons-html5"
                        >
                          <span>Copy</span>
                        </button>
                      </CopyToClipboard> */}

                    <div className="mt-3 mb-3"  style={{display:"flex",justifyContent:"center"}}>
                       
                        <span className="mt-1">Excel :</span>
                       
                        <div style={{marginLeft:"20px"}}>
                        <CSVLink
                        data={excelData}
                        type="button"
                        // onClick={onCloseClick}
                        className="btn btn-secondary btn-sm"
                       
                        filename={"Motiv AI" + ".pdf.csv"}
                        // id="delete-record"
                      >
                        {/* <span style={{ fontSize: 8 }}> Export as</span>{" "} */}
                        {/* <span> */}
                          {/* Excel <br />{" "} */}
                          <span style={{ fontSize: 10 }}>CY</span>
                        {/* </span> */}
                      </CSVLink>
                      </div>
                      </div>

                      {/* <div className="mt-3"  style={{display:"flex",justifyContent:"center"}}>
                      <span>Pdf :</span>

                      <div style={{marginLeft:"20px"}}>
                   
                      <button
                          onClick={() => exportPDF()}
                          type="button"
                          className="btn btn-secondary buttons-copy btn-sm"
                        >
                          <span  style={{ fontSize: 10 }}>CY</span>
                        </button>
                        </div>
                        </div> */}
                      {/* <select>
                                                <option>1</option>
                                                <option>2</option>
                                            </select> */}
                      {/* <button type="button" className="btn btn-secondary buttons-copy buttons-html5">
                                                <span>Column visibility</span>
                                            </button> */}
                    
                  </div>
                  </Col>  
                  
                             
                </Row>
               
              </CardHeader>
              <CardBody style={{padding:"0px"}}>
              <Row>
<Col xl={12} lg={12} md={12} sm={12} sx={12} >
<form className="d-none d-lg-block">
<div className="mb-3 mt-3"  style={{display:"flex", justifyContent:"right"}}>
 
  <input type="text"  style={{borderColor:"black"}} className="form-control"
    value={searchText}
    onChange={(e) => {
      setSearchText(e.target.value);
    }}
    placeholder="Global Search by 'First Name' or 'Mobile Number'" />
  <button style={{marginLeft:"5px"}} className="btn btn-primary" type="button" onClick={handleSearch} ><i
    className="bx bx-search-alt align-middle"></i></button>
</div>
</form>

</Col>
</Row>
              <Row>
                  <Col xl={12} md={12} sm={12}>
                  <div className="table-responsive mt-3">
                    <table className="table table-bordered border-primary mb-0">
                      <thead>
                        <tr>
                          <th style={{textAlign:"center",padding:"8px"}}>Ride No</th>
                          <th style={{textAlign:"center",padding:"8px"}}>Status</th>
                          <th style={{textAlign:"center",padding:"8px"}}>Mobile</th>
                          <th style={{textAlign:"center",padding:"8px"}}>Mode</th>
                          <th style={{textAlign:"center",padding:"8px"}}>Event</th>
                          <th style={{textAlign:"center",padding:"8px"}}>Start Time</th>
                          <th style={{textAlign:"center",padding:"8px"}}>End Time</th>                         
                          <th  style={{textAlign:"center",padding:"8px"}}>Score</th>
                          <th  style={{textAlign:"center",padding:"8px"}}>Duration</th>
                          <th style={{textAlign:"center",padding:"8px"}}>Distance</th>
                          <th style={{textAlign:"center",padding:"8px"}}>Alerts</th>
                          <th style={{textAlign:"center",padding:"8px"}}>Category</th>
                        </tr>
                      </thead>
                     <tbody>
                     {apiData.map((apiDataTemp,i) =>(  <tr key={i}>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                            
                                <span style={{fontSize:"12px"}}>{apiDataTemp.rideNo}</span>

                           
                          </td>
                          <td  style={{fontSize:"12px",verticalAlign:"middle",textAlign:"center",padding:"0px"}}>{apiDataTemp.status}</td>
                          
                         
                          <td style={{fontSize:"12px",verticalAlign:"middle",textAlign:"center",padding:"10px"}}>{apiDataTemp.mobile}</td>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                               <span  style={{fontSize:"12px"}}>{apiDataTemp.mode}</span>
                               
                               
                               
                           
                          </td>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                               <span  style={{fontSize:"12px"}}>{apiDataTemp.event}</span>
                               
                           
                          </td>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                           
                               <span style={{fontSize:"12px"}}>{apiDataTemp.startTime}</span>
                               
                          </td>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                          <span style={{fontSize:"12px"}}>{apiDataTemp.endTime}</span>
                          </td>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                          <span style={{fontSize:"12px"}}>{apiDataTemp.score}</span>
                          </td>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                          <span style={{fontSize:"12px"}}>{apiDataTemp.duration}</span>
                          </td>
                          
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                          <span style={{fontSize:"12px"}}>{apiDataTemp.distance}</span>
                          </td>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                          <span style={{fontSize:"12px"}}>{apiDataTemp.alerts}</span>
                          </td>
                          <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                          <span style={{fontSize:"12px"}}>{apiDataTemp.category}</span>
                          </td>
                          {/* <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                           
                              <div style={{textAlign:"center",marginTop:"5px",}}>
                              <span  style={{fontSize:"12px",marginTop:"10px"}}>Excel : <button
                        type="button"
                        className="btn btn-soft-secondary btn-sm "
                       
                        // onClick={() => handleFilterValues("")}
                      >
                        CY
                      </button></span>
                      </div>
                            </td> */}
                            {/* <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                          <span style={{fontSize:"12px"}}>{datas.alerts}</span>
                          </td> */}
                          {/* <td style={{verticalAlign:"middle",textAlign:"center",padding:"0px"}}>
                          <span style={{fontSize:"12px"}}>{datas.category}</span>
                          </td> */}
                        </tr>
                        ))}
                                              </tbody>
                                               
                    </table>
                  </div>
                    
                  </Col>
                </Row>
                
                
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
        
<div className="d-flex justify-content-between" >
          <button type="button" className="btn btn-outline-danger btn-sm" 
          onClick={previous} 
           style={{visibility: prevShow ? "visible" : "hidden", width:"45px",float:"left" }}
           >
           Prev</button>
           <div className="pagination ">
  <a href="#">
    {pageNumber}
  </a>
</div>
    <button type="button" className="btn btn-outline-success btn-sm" 
    onClick={next} 
      style={{visibility: nextShow ? "visible" : "hidden" , float:"hidden" }}
      >
      Next</button>
           </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// MyRidesNew.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// };

export default MyRidesNew;
