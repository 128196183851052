import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

const RiskCountChart = ({ rideRiskData }) => {

  const [modifiedName, setModifiedName] = useState([]);

  useEffect(() => {
    if (
      rideRiskData.rideRiskXaxis.length > 0 &&
      rideRiskData.usernameData.length > 0
    ) {
      const modifiedNameTemp = rideRiskData.rideRiskXaxis.map((item, index) => (
        `${item} (${rideRiskData.usernameData[index]})`
      ));      
      setModifiedName(modifiedNameTemp);
    } else {
      setModifiedName([]);
    }
  }, [rideRiskData]); 
  
  

  const option = {

    tooltip: {},
    legend: {
      data: ['High Risk', 'Medium Risk'],
    },
    grid: { bottom: 40, left: 120, right: 40 },
    xAxis: {
      name: "Risk Count",
      type: 'category',
      nameTextStyle: { 
        fontFamily: "sans-serif",
        fontWeight:200,
      },
      nameLocation: "middle",
      nameGap: 30,
      type: "value",
      splitLine: {
        show: false
      },
      axisLabel: {
        fontSize: 10,
      }
    },
    yAxis: {
      name: "User",
      type: 'category',
      nameTextStyle: { 
        fontFamily: "sans-serif",
        fontWeight:200,
      },
      nameLocation: "middle",
      nameGap: 110,
      inverse: true,
      data: modifiedName,
      axisLabel: {
        margin: 5,
        fontSize: 10,
        fontWeight: "normal",
        fontColor: "#f1f1f1",
        formatter: function(value) {
          // Custom formatter function to wrap text
          const maxChars = 20;
          if (value && value.includes(" (")) {
            const openParenIndex = value.indexOf(" (");
            const valueAfterParen = value.substring(openParenIndex + 1); // Get the part of the string after "("
            return value.substring(0, openParenIndex + 1) + '\n' + valueAfterParen;
          }
          else if (value.length > maxChars) {
            return value.substring(0, maxChars) + '\n' + value.substring(maxChars);
          }
          return value;
        }
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: 'High Risk',
        type: 'bar',
        itemStyle: {
          color: '#E21212'
        },
        label: {
          show: true,
          position: 'right',
          textStyle: {
            fontSize: 9,
            fontWeight: 'bold',
          },
        },
        data: rideRiskData.riskRedSeries,
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ],
        // },
        // markLine: {
        //   data: [{ type: 'average', name: 'Avg' }]
        // },
        barWidth: 10
      },
      {
        name: 'Medium Risk',
        type: 'bar',
        itemStyle: {
          color: '#FFBF00'
        },
        label: {
          show: true,
          position: 'right',
          textStyle: {
            fontSize: 9,
            fontWeight: 'bold',
          },
        },
        data: rideRiskData.riskAmberSeries,
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
        // markLine: {
        //   data: [{ type: 'average', name: 'Avg' }]
        // },
        barWidth: 10
      }
    ]
  };



  return (
    <ReactEcharts option={option} type="bar" style={{ height:  rideRiskData.rideRiskXaxis && rideRiskData.rideRiskXaxis.length <= 5 ? 300 : Math.max(400, 50 * rideRiskData.rideRiskXaxis.length) + "px" }}/>
  );
};

export default RiskCountChart;
