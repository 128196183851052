import React, { useEffect, useState } from "react";
import GoogleMapRiskView from "./GoogleMapRiskView";
// import MyRideDetailController from "../../controller/MyRideDetail/MyRideDetail";
// import ValueFormat from "../../util/ValueFormat";
export default function MapcomponentsView() {
//   const [locationParams, setLocationParams] = useState({
//     rideId: "",
//     userId: "",
//     divisionId: "",
//   });
//   console.log("locationParams : " + JSON.stringify(locationParams));

//   useEffect(() => {
    // const getRiskAlertsDataList = async () => {
    //   var result = await MyRideDetailController.getTripProfileRiskAlertDataList(
    //     locationParams.rideId,
    //     locationParams.divisionId,
    //     locationParams.userId
    //   );
    //   var resultJSON = JSON.parse(result);
    //   var status = resultJSON.status;
    //   if (status == "SUCCESS") {
    //     console.log("status : Success");

    //     var resultJSONData = resultJSON.data;
    //     console.log(
    //       "getRiskAlertsDataList : " + JSON.stringify(resultJSONData)
    //     );
    //     var centerLatLong = { lat: 11.004556, lng: 76.961632 };
    //     console.log("centerLatLong : " + JSON.stringify(centerLatLong));
    //     if (resultJSONData.length > 0) {
    //       for (let rdCount = 0; rdCount < resultJSONData.length; rdCount++) {
    //         tripIcon = "";
    //         try {
    //           var deviceDataField = resultJSONData[rdCount].deviceDataField;
    //           var dDataField = ValueFormat.parseAsObject(deviceDataField);
    //           var speedTemp = ValueFormat.parseAsFloat(dDataField.speed, 0);
    //           console.log("speed : " + JSON.stringify(speedTemp));
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       }
    //     } else {
    //       console.log("Data Didn't Fetch");
    //     }
    //   } else {
    //     console.log("status : Failed");
    //   }
    //   getRiskAlertsDataList();
    // };
//   }, [locationParams]);

  return (
    <div>
      <GoogleMapRiskView />
    </div>
  );
}
