import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
  CardFooter,
  NavItem,
  Nav,
} from "reactstrap";
/** import Mini Widget data */
import ValueFormat from "../../../util/ValueFormat.js";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import { useSelector } from "react-redux";
import Loader from "../../../components/Common/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink } from "react-router-dom";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { displayText } from "../../../constant/MessageConstant";
import {
  SC_LOCK_STATUS,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_ID,
  SC_USER_USERNAME,
} from "../../../constant/StorageConstant";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import AdminDashboardSubMenu from "./AdminDashboardSubMenu.js";

import i1 from "../../../assets/images/adminDashboard/ad-dr-name.svg"
import i2 from "../../../assets/images/adminDashboard/ad-dr-uname.svg"
import i3 from "../../../assets/images/adminDashboard/ad-dr-rank.svg"
import i4 from "../../../assets/images/adminDashboard/totalRides.svg"
import i5 from "../../../assets/images/adminDashboard/ad-dr-riskAlerts.svg"
import i6 from "../../../assets/images/adminDashboard/ad-dr-otherAlerts.svg"
import i7 from "../../../assets/images/adminDashboard/ad-dr-overspeed.svg"
import i8 from "../../../assets/images/adminDashboard/ad-dr-mobileCall.svg"
import i9 from "../../../assets/images/adminDashboard/ad-dr-mobileScreen.svg"
import i10 from "../../../assets/images/adminDashboard/ad-dr-acceleration.svg"
import i11 from "../../../assets/images/adminDashboard/ad-dr-Braking.svg"
import i12 from "../../../assets/images/adminDashboard/ad-dr-cornering.svg"

import crash from "../../../assets/images/adminDashboard/crash_accident.svg"
import UBIDrivingScore from "../../../assets/images/Ubi Images/UBIDrivingScore.svg"
import UBIAnticipation from "../../../assets/images/Ubi Images/UBIAnticipation.svg"
import UBISelfConfidence from "../../../assets/images/adminDashboard/myRidesPageIcons/SelfConfidenceIcon.svg"
import UBIDrivingSkill from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingSkillIcon.svg"
import UBIDrivingStyle from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingStyleIcon.svg"
import UBIDrivingState from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingStateIcon.svg"
import UBIMobileScreenUse from "../../../assets/images/Ubi Images/UBIMobileScreenUse.svg"
import UBIMobileCallUse from "../../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg"
import UBIKmsDriven from "../../../assets/images/Ubi Images/UBIKmsDriven.svg"
import UBIStressStrain from "../../../assets/images/adminDashboard/myRidesPageIcons/AccBrakingCorIcon.svg"
import UBIScoreImage from "../../../assets/images/Ubi Images/newDrivingEfficiencyImage.svg"
import tdistance from "../../../assets/images/adminDashboard/ad-2r-distance.svg"
import tduration from "../../../assets/images/adminDashboard/ad-2r-time.svg"

import locationIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import minsIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import OverSpeedIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIconNew.svg";
import AccelerationIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/AccelerationIcon.svg";
import BreakingIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/BrakingIcon.svg";
import CorneringIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/CorneringIcon.svg";
import ScreenCountIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/ScreenCountIcon.svg";
import CallCountIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/CallCountIcon.svg";
import CountImage from "../../../assets/images/adminDashboard/myRidesPageIcons/CountImage.svg";
import CallCountIconNewOne from "../../../assets/images/adminDashboard/myRidesPageIcons/CallCountIconNew.svg";
import steeringWheel from "../../../assets/images/adminDashboard/steering-wheel.svg";
import UserNameIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import userFirstNameIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/userFirstNameIcon.svg"
import UBIAnticipationNew from "../../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";



const UnsafeDrivers = () => {
  const {
    layoutType,
    layoutWidth,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
    layoutMode,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    layoutWidth: state.Layout.layoutWidth,
    layoutPosition: state.Layout.layoutPosition,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    layoutMode: state.Layout.layoutMode,
  }));
  const [filter, setFilter] = useState("CURRENT_YEAR");
  
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: ""
});

  const [dataLength, setDataLength] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);  
  const [showLoader, setShowLoader] = useState(0);
  const [indexStart, setIndexStart] = useState(0);  
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  
  
  // Unsafe Driver
  const [dataDisplay, setDataDisplay] = useState(false);
  const [summaryDataList, setSummaryDataList] = useState([]);
  const containerIds = ["container1"];
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [summaryData, setSummaryData] = useState([]);

  const handleSummaryData = (data) => {
    setSummaryData(data);
  };
  



 

 
  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      border: "0.2px",
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "#fff",
      height: "20px",
      fontWeight: 600,
      fontSize: "14px",
    },
  };

  // Unsafe Drivers

  const getOverAllSummary = async () => {
    setDataDisplay(null);
    // console.log(filters.startDateTime,filters.endDateTime,"filter9jfilterfilterfilter")
    var result = await AdminDashboardController.unsafeDrivers(
      indexStart,
      filters.durationFilter,
      "",
      filters.startDateTime,
      filters.endDateTime
    );
    // console.log("getOverAllSummaryNew: ", result);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      if (resultJSONData.length > 0) {
        setDataDisplay(null);
        // console.log("getOverAllSummary:", resultJSONData);
        setDataLength(resultJSONData.length);
        var summaryData = [];
        resultJSONData.map((value, i) => {
          var rank = (pageNumber - 1) * 1000 + (1 + i);
          var firstName = ValueFormat.parseAsString((value.firstName).toUpperCase());
          var username = ValueFormat.parseAsString(value.username) || "-";
          var totalRides = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.totalRides)) || "-";
          var userId = ValueFormat.parseAsString(value.userId);
          var overallDrivingScore = ValueFormat.parseAsFloat(value.drivingScorePoint, 0) || "-";
          var overallAnticipation = ValueFormat.parseAsFloat(value.anticipationPoint, 0) || "-";
          var overallSelfConfidence = ValueFormat.parseAsFloat(value.selfConfidencePoint, 0) || "-";
          var overallDrivingSkill = ValueFormat.parseAsFloat(value.drivingSkillPoint, 0) || "-";
          var dStyleValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 0) || "-";
          var dStateValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 0) || "-";
          var overSpeedPoint = ValueFormat.parseAsFloat(value.overSpeedPoint, 2) || "-";
          var kmsDriven = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 0) || "-";
          var mobileCallValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 2) || "-";
          var mobileScreenValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 2) || "-";
          var abcPoint = ValueFormat.parseAsFloat(value.accBrakCorPoint, 0) || "-";
          var calculatedDrivingScore = ValueFormat.parseAsFloat(value.calculatedDrivingScore, 0) || "-";
          var calculatedDrivingScoreTool = ValueFormat.parseAsFloat(value.calculatedDrivingScore, 2) || "-";
          var drivingBehaviourScore = ValueFormat.parseAsFloat(value.drivingBehaviourScore) || "-";

          var totalEfficiencyRaw =
            parseFloat(ValueFormat.parseAsFloat(value.drivingScorePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.anticipationPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.selfConfidencePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingSkillPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingStylePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.drivingStatePoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.mobileCallPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.mobileScreenPoint, 1)) +
            parseFloat(ValueFormat.parseAsFloat(value.accBrakCorPoint, 1));

          var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 0);
          var totalEfficiencyTV = ValueFormat.parseAsFloat(totalEfficiencyRaw, 2);

          var overallDrivingScoreTooltipValue = ValueFormat.parseAsFloat(value.drivingScorePoint, 1) || "-";
          var overallAnticipationTooltipValue = ValueFormat.parseAsFloat(value.anticipationPoint, 1) || "-";
          var overallSelfConfidenceTooltipValue = ValueFormat.parseAsFloat(value.selfConfidencePoint, 1) || "-";
          var overallDrivingSkillTooltipValue = ValueFormat.parseAsFloat(value.drivingSkillPoint, 1) || "-";
          var dStyleValueTooltipValue = ValueFormat.parseAsFloat(value.drivingStylePoint, 1) || "-";
          var dStateValueTooltipValue = ValueFormat.parseAsFloat(value.drivingStatePoint, 1) || "-";
          var kmsDrivenTooltipValue = ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1) || "-";
          var mobileCallValueTooltipValue = ValueFormat.parseAsFloat(value.mobileCallPoint, 1) || "-";
          var mobileScreenValueTooltipValue = ValueFormat.parseAsFloat(value.mobileScreenPoint, 1) || "-";
          var abcPointTooltipValue = ValueFormat.parseAsFloat(value.accBrakCorPoint, 1) || "-";

          var efficiency = ValueFormat.parseAsFloat(value.drivingEfficiency, 0) || "-";
          var efficiencyTooltipValue = ValueFormat.parseAsFloat(value.drivingEfficiency, 2) || "-";

          var totalSevereAccelerationLowCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationLowCount, 2) || "-";
          var totalSevereAccelerationMediumCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationMediumCount, 2) || "-";
          var totalSevereAccelerationHighCount = ValueFormat.parseAsFloat(value.totalSevereAccelerationHighCount, 2) || "-";

          var totalAccelerationCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereAccelerationHighCount, 0));

          var totalSevereBrakingLowCount = ValueFormat.parseAsFloat(value.totalSevereBrakingLowCount, 2) || "-";
          var totalSevereBrakingMediumCount = ValueFormat.parseAsFloat(value.totalSevereBrakingMediumCount, 2) || "-";
          var totalSevereBrakingHighCount = ValueFormat.parseAsFloat(value.totalSevereBrakingHighCount, 2) || "-";

          var totalBrakingCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereBrakingHighCount, 0));

          var totalSevereCorneringLowCount = ValueFormat.parseAsFloat(value.totalSevereCorneringLowCount, 2) || "-";
          var totalSevereCorneringMediumCount = ValueFormat.parseAsFloat(value.totalSevereCorneringMediumCount, 2) || "-";
          var totalSevereCorneringHighCount = ValueFormat.parseAsFloat(value.totalSevereCorneringHighCount, 2) || "-";

          var totalCorneringCount = parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringLowCount, 0)) + parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringMediumCount, 0))
            + parseFloat(ValueFormat.parseAsFloat(value.totalSevereCorneringHighCount, 0));

          var totalMobileUseInAcceptedCount = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedCount, 0) || "-";
          var totalMobileUseInAcceptedDistance = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDistance, 0) || "-";
          const parsedNum = parseFloat(value.totalMobileUseInAcceptedDuration);

          var totalMobileUseInAcceptedDuration = Number.isInteger(parsedNum) ? parsedNum :  ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 2) || "-";

          var totalMobileScreenScreenOnCount = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnCount, 0) || "-";
          var totalMobileScreenScreenOnDistance = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDistance, 0) || "-";
          const parsedNumMobileScreen = parseFloat(value.totalMobileScreenScreenOnDuration);
          var totalMobileScreenScreenOnDuration = Number.isInteger(parsedNumMobileScreen) ? parsedNumMobileScreen : ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDuration, 2) || "-";

          var totalOverSpeedCount = ValueFormat.parseAsFloat(value.totalOverSpeedCount, 0) || "-";
          const parsedNumOverSpeed = parseFloat(value.totalOverSpeedDuration);
          var totalOverSpeedDuration =  Number.isInteger(parsedNumOverSpeed) ? parsedNumOverSpeed : ValueFormat.parseAsFloat(value.totalOverSpeedDuration, 2) || "-";
          var totalMediumRiskCount = ValueFormat.parseAsFloat(value.totalMediumRiskCount, 0) || "-";
          var totalHighRiskCount = ValueFormat.parseAsFloat(value.totalHighRiskCount, 0) || "-";

          var tKiloMeter = ValueFormat.parseAsFloat(value.tKiloMeter, 2) || "-";
          var tTravelTime = ValueFormat.parseAsFloat(value.tTravelTime, 2) || "-";

          var crashCount = ValueFormat.parseAsString(value.crashCount) || "-";

          var tKiloMeterTV = ValueFormat.parseAsFloat(value.tKiloMeter, 2) || "-";
          var tTravelTimeTV = ValueFormat.parseAsFloat(value.tTravelTime, 2) || "-";
          var totalOverSpeedDurationTV = ValueFormat.parseAsFloat(value.totalOverSpeedDuration, 2) || "-";
          var totalMobileScreenScreenOnDurationTV = ValueFormat.parseAsFloat(value.totalMobileScreenScreenOnDuration, 2) || "-";
          var totalMobileUseInAcceptedDurationTV = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 2) || "-";
          var totalMobileUseInAcceptedDurationTVCount = ValueFormat.parseAsFloat(value.totalMobileUseInAcceptedDuration, 0) || "-";

          var mobileUsage =
          parseFloat(value.mobileCallPoint) +
          parseFloat(value.mobileScreenPoint);
          var mobileUsageTotal = ValueFormat.parseAsFloat(mobileUsage, 0) || 0
          var resultObject = {
            rank: rank,
            firstName: firstName,
            username: username || "N/A",
            drivingScore: overallDrivingScore,
            calculatedDrivingScore:calculatedDrivingScore,
            calculatedDrivingScoreTool:calculatedDrivingScoreTool,
            drivingBehaviourScore:drivingBehaviourScore,
            totalRides: totalRides,
            userId: userId,
            ABCPoint: abcPoint,
            mobileScreenPointNew: mobileScreenValue,
            mobileUsePointNew: mobileCallValue,
            overallAnticipation: overallAnticipation,
            overallSelfConfidence: overallSelfConfidence,
            overallDrivingSkill: overallDrivingSkill,
            totalKmSPoint: kmsDriven,
            DSP: dStateValue,
            DSTP: dStyleValue,
            overSpeedPoint: overSpeedPoint,
            efficiency: efficiency,
            totalEfficiency: totalEfficiency,
            efficiencyTooltipValue: efficiencyTooltipValue,
            crashCount: ValueFormat.parseAsFloat(crashCount),

            overallDrivingScoreTooltipValue: overallDrivingScoreTooltipValue,
            overallAnticipationTooltipValue: overallAnticipationTooltipValue,
            overallSelfConfidenceTooltipValue: overallSelfConfidenceTooltipValue,
            overallDrivingSkillTooltipValue: overallDrivingSkillTooltipValue,
            dStyleValueTooltipValue: dStyleValueTooltipValue,
            dStateValueTooltipValue: dStateValueTooltipValue,
            kmsDrivenTooltipValue: kmsDrivenTooltipValue,
            mobileCallValueTooltipValue: mobileCallValueTooltipValue,
            mobileScreenValueTooltipValue: mobileScreenValueTooltipValue,
            abcPointTooltipValue: abcPointTooltipValue,
            totalEfficiencyTV: totalEfficiencyTV,

            totalAccelerationCount: totalAccelerationCount,
            totalBrakingCount: totalBrakingCount,
            totalCorneringCount: totalCorneringCount,
            totalOverSpeedDuration: totalOverSpeedDuration,
            totalMobileUseInAcceptedCount: totalMobileUseInAcceptedCount,
            totalMobileUseInAcceptedDistance: totalMobileUseInAcceptedDistance,
            totalMobileUseInAcceptedDuration: totalMobileUseInAcceptedDuration,
            totalMobileUseInAcceptedDurationTVCount:totalMobileUseInAcceptedDurationTVCount,

            totalMobileScreenScreenOnCount: totalMobileScreenScreenOnCount,
            totalMobileScreenScreenOnDistance: totalMobileScreenScreenOnDistance,
            totalMobileScreenScreenOnDuration: totalMobileScreenScreenOnDuration,

            totalOverSpeedCount: totalOverSpeedCount,
            totalMediumRiskCount: totalMediumRiskCount,
            totalHighRiskCount: totalHighRiskCount, 

            tKiloMeter: tKiloMeter,
            tTravelTime: tTravelTime,

            totalOverSpeedDurationTV: totalOverSpeedDurationTV,
            totalMobileScreenScreenOnDurationTV: totalMobileScreenScreenOnDurationTV,
            totalMobileUseInAcceptedDurationTV: totalMobileUseInAcceptedDurationTV,
            tKiloMeterTV: tKiloMeterTV,
            tTravelTimeTV: tTravelTimeTV,
            mobileUsage: mobileUsageTotal
          };
          summaryData.push(resultObject);
          // console.log("resultObject: ", resultObject);
          setDataDisplay(
            Object.values(resultObject).some((value) => value !== 0)
          );
        });
      } else {
        setDataDisplay(false);
      }
      handleSummaryData({ data: summaryData, filter: filters.durationFilter });

      setSummaryDataList(summaryData);
    } else if (status == "FAILED") {
      setDataDisplay(false);

      var code = resultJSON.error.code;
      if (code == "E1206") {
        setDataDisplay("failed");
      }
    }
  };

  useEffect(() => {
    getOverAllSummary();
  }, [filters.durationFilter, filters.startDateTime, filters.endDateTime]);
  // console.log(filter, "filterdssdf")
  useEffect(() => {
    // console.log(startDateTime, "startDateTimem9startDateTime")
    setFilters( {
        durationFilter: filter,
        startDateTime: startDateTime,
        endDateTime: endDateTime } )
}, [filter,startDateTime, endDateTime]);

// console.log(filters,"filtersfilters")


  return (
    <React.Fragment>
      <div className="page-content" >
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid id="division">
          <div id="container1">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />

            <Row className="adminmenu-height mb-2">
              <Col xs={12} className="shadow-sm" style={{ position: "fixed", zIndex: 1, backgroundColor: "#fff" }} >
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col xl={7} className="py-2">
                    <AdminDashboardSubMenu />
                  </Col>

                  <Col
                    xl={5}
                    className="py-2"
                    style={{ alignContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="ms-auto pe-2 border-end border-secondary">
                        <div style={{ float: "right" }}>
                          <button
                            type="button"
                            data-tooltip-id="cd"
                            className={
                              (filters.durationFilter == "CURRENT_DAY" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                            //   setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_DAY",
                              })
                            }}
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Current Day"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cw"
                            className={
                              (filters.durationFilter == "CURRENT_WEEK" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                            //   setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_WEEK",
                              })
                            }}
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Current Week"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cm"
                            className={
                              (filters.durationFilter == "CURRENT_MONTH" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                            //   setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_MONTH",
                              })
                            }}
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Current Month"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cy"
                            className={
                              (filters.durationFilter == "CURRENT_YEAR" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                            //   setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_YEAR",
                              })
                            }}
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Current Year"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CUSTOM_DURATION" || modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setIsOpen(true);
                            //   setCounts(1);
                            }}
                            data-tooltip-id="dfdf"
                          >
                            <FontAwesomeIcon icon={faClock} />
                            <ReactTooltip
                              id="dfdf"
                              place="bottom"
                              content="Custom Date Selection"
                              style={{ background: "black" }}
                            />
                          </button>{" "}
                        </div>
                      </div>
                      

                       <div>
                        <ExportScreenPdf
                      containerIds={containerIds}
                      fileName={userFirstName + "'s" + " Lowest Performance Drivers"}
                    />
                      </div>
                      <div>
                      <ViewPdf
                        // ADTopRowItemsTemp={ADTopRowItemsTemp}
                        // ADRideBoxCount={ADRideBoxCount}
                        summaryData={summaryData}
                        // riskSummaryData={riskSummaryData}
                        // aggregateData1={aggregateData}
                        // totalDays1={totalDays}
                        filtersADOS={filters}
                      />
                      </div>

                      {/* <Link to={"/exportRisk"}>
                            <button
                              // className="btn btn-soft-primary btn-sm ms-1"
                              className="btn btn-primary btn-sm"
                              data-tooltip-id="err"
                            >
                              Go to Rides & Risks
                              <ReactTooltip
                                id="err"
                                place="bottom"
                                content="Go to Rides & Risks"
                                style={{ backgroundColor: "black" }}
                              />
                            </button>
                          </Link> */}
                    </div>
                  </Col>
                 
                </Row>
              </Col>
            </Row>

            <Row>
        <Col xs={12}>
        <Card>
        <CardBody style={{ padding: "0px" }}>
                <Card className="border-0">
                  <CardHeader
                    className="cardHeader"
                    style={{ paddingLeft: "12px" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <h5 className="sub_heading_new m-0">Driving Score</h5>
                        <ReactTooltip
                          id={"info"}
                          place="top"
                          // content={`
                          // Driver Behaviour Score is calculated on a scale of 100 and represents a cumulative sum of the
                          // Driving Point, Anticipation Point, Self Confidence Point, Driving Skill Point, Driving Style Point, Driver State Point, Mobile Use Point, Mobile Call Point, Overspeed KMS Driven Point, and Acc/Brak/Cor Point
                          // `}
                          multiline={true}
                          style={{
                            backgroundColor: "black",
                            height: "auto",
                            width: "40vw",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            padding: "20px",
                          }}
                        />
                      </div>

                      {/* <div className="ms-auto">
                                                <div style={{ verticalAlign: "middle", display: "flex" }}>

                                                    <div>
                                                        <div className="d-flex mt-1 align-items-center" >
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#E21212' }} />
                                                                Poor (0-80)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#FFBF00' }} />
                                                                Fair (80-93)
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center" style={{ fontSize: "14px" }}>
                                                                <button className="btn me-1" style={{ backgroundColor: '#19543E' }} />
                                                                Good (93-100)
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div> */}
                    </div>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div
                      className="mb-4"
                      // style={{ height: 525, overflowY: "auto" }}
                    >
                      <div
                        style={{
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      >
                        {/* {displayText.DRIVER_BEHAVIOUR_DESCRIPTION} */}
                      </div>
                      <Table bordered style={TableStyle.table}>
                        <thead>
                          {/* <tr>
                                                        
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Rating</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Driving<br />Score</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Ride <br />Name</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="4">Driving Risk Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="7">Driving Behavior Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Travel<br />Period</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Battery %</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Event</th>
                                                    </tr> */}
                          <tr>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              User<br/>Details
                              {/* <br/><br/><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/>Score
                              {/* <br/><br/><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>
                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/><br/><br/><img src={ride_name} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/>Risk
                              {/* <br /><img src={category_image} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>

                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Driving <br />Points<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Risk
                              <br />
                              Data
                              {/* <img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/>Self <br />Confidence <br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/>Driving <br />Skill <br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving <br />
                              Behaviour
                              {/* <img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Behaviour <br />
                              Data
                              {/* <img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            {/* <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Crash <br />
                              Probability
                              <img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} />
                            </th> */}
                           
                           
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Acc/Brak/Cor
                              <br />
                              Counts
                              {/* <img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Over Speed
                              <br />
                              Data
                              {/* <img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Mobile
                              <br />
                              Usage
                              {/* <img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Red Alert <br />
                              Counts
                              {/* <img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>

                           
                          </tr>
                        </thead>
                        {dataLength > 0 && (
                          <tbody>
                            {(summaryDataList || []).map((apiData, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={UserNameIcon}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          marginLeft: "-2px",
                                        }}
                                        
                                      />
                                     
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >

<span
                             style={{
                              fontSize: "11px",
                              color: "#5156BE",
                              marginBottom: "0px",
                              fontWeight: 700,
                              cursor:"pointer"
                            }}
                            onMouseEnter={() => [
                              localStorage.setItem(
                                SC_USER_ID,
                                apiData.userId
                              ),
                              localStorage.setItem(
                                SC_LOGIN_USER_FIRST_NAME,
                                apiData.firstName
                              ),
                              localStorage.setItem(
                                SC_USER_USERNAME,
                                apiData.username
                              ),
                            ]}
                          >
                            <Link to={"/user-dashboard"}>
                              {apiData.firstName}
                            </Link>
                          </span>
                                 
                                   
                                 
                                  </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={userFirstNameIcon}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          marginLeft: "-2px",
                                        }}
                                        
                                      />
                                     
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >
                                 
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#5156BE",
                                        marginBottom: "0px",
                                        fontWeight: 700,
                                        cursor:"pointer"
                                      }}
                                    >{`${apiData.username}`}</p>
                                 
                                  </div>
                                  </div>

                                  
                                 <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={i4}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          marginLeft: "-2px",
                                        }}
                                        
                                      />
                                     
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >
                                 
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#5156BE",
                                        marginBottom: "0px",
                                        fontWeight: 700,
                                        cursor:"pointer"
                                      }}
                                    >{`${apiData.totalRides}`}{" "}Rides</p>
                                 
                                  </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tKiloMeter + " Kms"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tTravelTime + " Mins"}
                                    </div>
                                  </div>
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "10px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.calculatedDrivingScore >= 0 &&
                                      apiData.calculatedDrivingScore <= 80
                                        ? "Poor"
                                        : apiData.calculatedDrivingScore >=
                                            81 &&
                                          apiData.calculatedDrivingScore <= 93
                                        ? "Fair"
                                        : "Good"}
                                    </div>
                                  </div> */}
                                </td>
                                {/* <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.calculatedDrivingScore >= 0 && apiData.calculatedDrivingScore <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                                                                    apiData.calculatedDrivingScore >= 81 && apiData.calculatedDrivingScore <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                                                                        <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                                                                </td> */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                  data-tooltip-id={`ETVN${index}`}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.calculatedDrivingScore}
                                        text={`${apiData.calculatedDrivingScore}%`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:
                                            apiData.calculatedDrivingScore >=
                                              0 &&
                                            apiData.calculatedDrivingScore <= 80
                                              ? "#E21212"
                                              : apiData.calculatedDrivingScore >=
                                                  81 &&
                                                apiData.calculatedDrivingScore <=
                                                  93
                                              ? "#FFBF00"
                                              : "#19543e",
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    <ReactTooltip
                                      id={`ETVN${index}`}
                                      place="top"
                                      content={apiData.totalEfficiencyTVNew}
                                      style={{ backgroundColor: "black" }}
                                    />
                                    
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                     justifyContent:"center",
                                     marginTop:"20px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",                                        
                                        fontSize: "11px",
                                        marginLeft:"5px"
                                        
                                      }}
                                    >
                                       {apiData.calculatedDrivingScore >= 0 &&
                                      apiData.calculatedDrivingScore <= 50
                                        ? "Bad"
                                        : apiData.calculatedDrivingScore >
                                            50 &&
                                          apiData.calculatedDrivingScore <= 80
                                        ? "Dangerous"
                                        : apiData.calculatedDrivingScore > 80 && apiData.calculatedDrivingScore <= 93 ? "Quiet Safe" : apiData.calculatedDrivingScore > 93 && apiData.calculatedDrivingScore <= 100 ? "Good" : ""}
                                    </div>
                                  </div>
                                </td>
                                {/* <td className="lightBlueColor text-nowrap" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                    <Link
                                                                        to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(apiData.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(apiData.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}&rideCategory=${encodeURIComponent(Converter.encrypt(apiData.rideCategory))}`}>
                                                                        <span
                                                                            data-tooltip-id={`rideTooltip_${index}`}
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            {apiData.rideName}
                                                                        </span>
                                                                    </Link>
                                                                    <ReactTooltip
                                                                        id={`rideTooltip_${index}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.StatusTemp}
                                                                    />
                                                                </td> */}
                                {/* <td className="lightBlueColor text-nowrap" data-tooltip-id={`categoryTooltip_${index}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.categoryTooltip}`} */}
                                {/* <ReactTooltip
                                                                        id={`categoryTooltip_${index}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.categoryTooltip}
                                                                    /> */}
                                {/* </td> */}
                                <td
                                  className="text-nowrap"
                                  data-tooltip-id={`categoryTooltip_${index}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${ValueFormat.formatDecimalIfRounded(
                                      apiData.drivingScore
                                    )}`,
                                  }} // This will render the <br/> as an actual line break
                                />
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{"-"}</div></td> */}
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{"-"}</div></td> */}
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIAnticipationNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Anticipation
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.overallAnticipation
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBISelfConfidence}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "7px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Self Confidence
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallSelfConfidence
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingSkill}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Skill
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallDrivingSkill
                                      )}
                                    </div>
                                  </div>
                                </td>
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallSelfConfidence)}`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallDrivingSkill)} `}</div></td> */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                    fontSize: "11px",
                                  }}
                                >
                                  <div style={{fontSize: "13px"}}>{`${apiData.drivingBehaviourScore}`}</div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingStyle}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Style
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.DSTP
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingState}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving State
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.DSP
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIMobileCallUse}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "9px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Mobile Usage
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.mobileUsage}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Over Speed
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.totalKmSPoint
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIStressStrain}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          ABC
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.ABCPoint
                                      )}
                                    </div>
                                  </div>
                                </td>
                                {/* <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value="-"
                                        text="-"
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:apiData.cppLevelColor,                                          
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    
                                   
                                  </div>
                                  <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                      <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"20px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L0 is Safe</p>

                                    </div>
                                      
                                    </div>
                                    <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"-5px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L6 is Unsafe</p>

                                    </div>
                                    </div>
                                  <div>
                                    Level{" - "}
                                    {apiData.cppLevel}
                                  </div>
                                  <div style={{ marginTop: "15px" }}>
                                    Point(s){" - "}
                                    {apiData.crashProbabilityScore || 0}
                                  </div>
                                </td> */}
                                
                               
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={AccelerationIcon}
                                          alt="totalAccelerationCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalAccelerationCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={BreakingIcon}
                                          alt="totalBrakingCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Brak
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalBrakingCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={CorneringIcon}
                                          alt="totalCorneringCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalCorneringCount}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                   <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      
                                      {apiData.totalOverSpeedCount}{" "}{"Count"}
                                    </div>
                                  </div>           

                                
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalOverSpeedDuration}{" "}{"Mins"}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                   <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ScreenCountIcon}
                                        alt="icons2"
                                        style={{ height: "24px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnDuration}{" "}{"Mins"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CallCountIconNewOne}
                                        alt="icons2"
                                        style={{ height: "26px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Mins"}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                 <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalHighRiskCount}{" "}{"Count"}
                                    </div>
                                  </div>           

                                  
                                  
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {"-"}
                                    </div>
                                  </div> */}
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {"-"}
                                    </div>
                                  </div> */}
                                </td>
                                
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>

                      {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>

               
              </CardBody>
        </Card>
        </Col>
      </Row>         

          </div>

        </Container>
      </div >
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen}  setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter} />
    </React.Fragment >
  );
};

export default UnsafeDrivers;
