import { MY_USER_SEARCH } from "./actiontype";

const INIT_STATE = {
  searchField: {value:"",search:false}
};

const myUserSearch = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MY_USER_SEARCH:
      return {
        ...state,
        searchField: action.payload,
      };
    default:
      return state;
  }
};

export default myUserSearch;
