import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../util/ValueFormat";
import { Alert, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import OneRiskAlertChart from "../Admin/Charts/oneRiskeChart";
import StreesStrainChart from "../Admin/Charts/streesStrainChart";
import userSessionController from "../../controller/UserSession/UserSessionController";
import { overSpeedDataTemp } from "../../common/data/otherAlertsTableData";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { accDataDashbordTemps } from "../RideDetails/AccCorBrakingData";
import riskAlertSubCategory from "../../common/data/riskAlertCategories";
import AnalyticalController from "../../controller/Analytical/AnalyticalController";
import { SC_DIVISION_ID, SC_USER_ID } from "../../constant/StorageConstant";
import { Link, json } from "react-router-dom";
import { alertDataType } from "../../constant/ModelConstant";
import OverSpeedLocations from "./OverSpeedLocation";
import { displayText } from "../../constant/MessageConstant";
import { useDispatch } from "react-redux";
import { OverSpeedLocationData } from "../../store/actions";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import Converter from "../../components/Common/EncodeDecode";
const RiskCountChart = ({ filter, loaderCount, getOsMcuMsuProps ,getACBProps,getRCCProps, getRiskAlertPdf, getACBDataProps, getOSDataProps, getMcuDataProps,getMsuDataProps}) => {
  const dispatch = useDispatch()
  const options = {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
    },
    // dataZoom: [{
    //   show: true,
    //   bottom: 20,
    //   height: 10,
    //   right: "15%",
    //   left: "13%"
    // }],
    xAxis: {
      type: "category",
      data: [],
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 45,
      },
    },
    yAxis: {
      type: "value",
    },
    grid: { bottom: 125, left: 50, right: 140 },

    series: {
      name: "Count",
      data: [0],
      type: "bar",
      barWidth: 25,
      //  markPoint: {
      //   data: [
      //     { type: 'max', name: 'Max' },
      //     { type: 'min', name: 'Min' }
      //   ]
      // },
      label: {
        show: true,
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideTop',
        distance: -5,
      },

    },
  };

  const [barOption, setBarOption] = useState(options);
  // const [filter, setFilter] = useState("CURRENT_YEAR");
  // getRCCfilter(filter); 
  const [otherData, setOtherData] = useState([]);
  const [otherDataLabel, setOtherDataLabel] = useState([]);
  const [stressStrainData, setStressStrainData] = useState({
    low: [0],
    medium: [0],
    high: [0],
    label: [],
  });
  const [overSpeedDataList, setOverSpeedDataList] = useState(overSpeedDataTemp);
  const [mobileUseDataList, setMobileUseDataList] = useState([]);
  const [mobileScreenDataList, setMobileScreenDataList] = useState([]);
  const [userId, setUserId] = useState(localStorage.getItem(SC_USER_ID));
  const [divisionId, setDivisionId] = useState(
    localStorage.getItem(SC_DIVISION_ID)
  );
  const [indexStart, setIndexStart] = useState(0);
  const [riskAlertData, setRiskAlertData] = useState([]);
  const [stressData, setstressData] = useState([]);
  const [overSpeedLocationData, setOverSpeedLocationData] = useState([]);
  const [mobileCallCount, setMobileCallCount] = useState(0);
  const [mobileScreenUse, setMobileScreenUse] = useState(0);
  const [overSpeedCount, setOverSpeedCount] = useState(0);
  const [dataDisplay, setDataDisplay] = useState(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [acb, setAcb] = useState(false);
  const [rAlert, setRAlert] = useState(false);
  const [tableACB, setTableACB] = useState(false);
  const [oSpeed, setOSpeed] = useState(false);
  const [mobileSU, setMobileSU] = useState(false);
  const [mobileSU1, setMobileSU1] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  let getAlertSummary = async () => {
    loaderCount((current) => current + 1);
    setDataDisplay(null)
    var alertsType = "";
    var result = await UserDrivingScoreController.userRideCompleteSummary(
      indexStart
    );
    loaderCount((current) => current - 1);
    var resultJSON = JSON.parse(result);
      // console.log("getAlertSummary: ",resultJSON )
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      try {
        var resultJSONData = resultJSON.data;
        // console.log(
        //   "userDashboardSummaryData: " + JSON.stringify(resultJSONData)
        // );
        var alertSummeryXAxis = [];
        var alertSummeryData = [];
        var latLong = [];
        var tripRiskList = [];
        var riskAlertDataPdf = [];
        var tripRiskLatLongList = [];
        var overSpeedData = [];
        var osDatPdf = [];
        var mobileScreenData = [];
        var msudataPdf = [];
        var stressStrainDataList = [];
        var acbDataPdf = [];
        var mobileUseData = [];
        var mcuDataPdf =[];
        var tripRiskSigList = [];
        var alertImage = "";
        var tripIcon = "";
        var locationMapDataList = [];
        var overSpeedCount = 0;
        var curveCount = 0;
        resultJSONData.map((value, i) => {
          var deviceDataField = ValueFormat.parseAsObject(value.deviceDataField);
          var latitudeTemp = ValueFormat.parseAsString(deviceDataField.latitude , null);
          var longitudeTemp = ValueFormat.parseAsString(
            deviceDataField.longitude , null
          );
          var category =
            value.category != "" && value.category != null ? value.category : "";

          alertsType =
            value.category != "" && value.category != null
              ? ValueFormat.capitalizeFirstLetterAndFormat(value.category)
              : "";
          var subCategoryTemp =
            value.subCategory != "" && value.subCategory != null
              ? value.subCategory
              : "";
          if (subCategoryTemp == "CURVE") {
            curveCount = curveCount + 1;
          }
          var subCategory =
            value.subCategory != "" && value.subCategory != null
              ? ValueFormat.capitalizeFirstLetterAndFormat(value.subCategory)
              : "";
          var kiloMeter =
            value.kilo_meter != "" && value.kilo_meter != null
              ? value.kilo_meter
              : "";
          var subType = ValueFormat.parseAsString(value.subType);
          var startDate = ValueFormat.parseAsString(value.createdAtToTimeZone);
          var rideName = ValueFormat.parseAsString(value.name);
          var rideId = ValueFormat.parseAsString(value.deviceId);
          var deviceDataField = value.deviceDataField;
          var speed =
            value.speed != "" && value.speed != null ? value.speed : "0";
          var riskValueTemp = ValueFormat.parseAsInt(value.risk);
          var subCategoryLevel = ValueFormat.parseAsString(
            value.subCategoryLevel
          );
          var speedLimit = ValueFormat.parseAsFloat(value.speedLimit, 2);
          var latLongTempMap = latitudeTemp + "," + longitudeTemp;

          if (subCategory.length > 0) {
            tripRiskSigList.push(subCategory);
          } else {
            tripRiskSigList.push(category);
          }

          var xAxisLabel = "-";
          if (category == "START_DATA" || category == "END_DATA") {
            xAxisLabel = alertsType;
          } else if (
            category == "ALERT_DATA" ||
            category == "STRESS_STRAIN_DATA" ||
            category == "MANUAL_DATA"
          ) {
            xAxisLabel = subCategory;
          } else if (category == "DISTANCE_DATA") {
            xAxisLabel = kiloMeter + " Km";
          }

          var overSpeedDataResult = {
            rideId: rideId,
            rideName: rideName,
            date: startDate,
            distance: kiloMeter,
            speedLimit: speedLimit,
            speed: speed,
          };
          var overSpeedDataResult1 = {
            rideName: rideName,date: startDate,speedLimit: speedLimit,
            speed: speed,
          }
          var mobileScreenDataResult = {
            rideId: rideId,
            rideName: rideName,
            date: startDate,
            distance: kiloMeter,
            speed: speed,
          };
          var mobileScreenDataResult1 = {
            rideName: rideName,
            date: startDate,
            speed: speed,
          };
          var stressStrainData = {
            rideId: rideId,
            rideName: rideName,
            date: startDate,
            type: subCategory,
            subType: subType,
            distance: kiloMeter,
            speed: speed,
            subCategoryLevel: subCategoryLevel,
          };
          var stressStrainData1 = {
            rideName: rideName,
            date: startDate,
            type: subCategory,
            subCategoryLevel: subCategoryLevel,
            speed: speed,
          };
          var locationMapResult = {
            lat: latitudeTemp,
            rideName: rideName,
            lng: longitudeTemp,
            date: startDate,
            type: subCategory,
            speed: speed,
          };
          if (subCategory == "Over speed") {
            setOverSpeedCount((prev) => prev + 1);
            overSpeedData.push(overSpeedDataResult);
            osDatPdf.push(overSpeedDataResult1)
            locationMapDataList.push(locationMapResult);
          } else if (subCategory == "Mobile screen") {
            setMobileScreenUse((prev) => prev + 1);
            locationMapDataList.push(locationMapResult);
            mobileScreenData.push(mobileScreenDataResult);
            msudataPdf.push(mobileScreenDataResult1);
          } else if (subCategory == "Mobile use") {
            setMobileCallCount((pre) => pre + 1);
            locationMapDataList.push(locationMapResult);
            mobileUseData.push(mobileScreenDataResult);
            mcuDataPdf.push(mobileScreenDataResult1)
          } else if (category == "STRESS_STRAIN_DATA") {
            stressStrainDataList.push(stressStrainData);
            acbDataPdf.push(stressStrainData1)
          }
          // alert(JSON.stringify(stressStrainDataList));
          alertSummeryXAxis.push(xAxisLabel);
          alertSummeryData.push(speed);
          if (subCategoryTemp == alertDataType.ANIMAL_CROSSING) {
            alertImage = "t_animal_crossing.png";
          } else if (subCategoryTemp == alertDataType.CAUTION) {
            alertImage = "t_caution.png";
          } else if (subCategoryTemp == alertDataType.CURVE) {
            alertImage = "t_curves.png";
          } else if (subCategoryTemp == alertDataType.SEVERE_ACCELERATION) {
            alertImage = "t_force_acceleration.png";
          } else if (subCategoryTemp == alertDataType.HILL_DOWNWARDS) {
            alertImage = "t_hill_downwards.png";
          } else if (subCategoryTemp == alertDataType.HILL_UPWARDS) {
            alertImage = "t_hill_upwards.png";
          } else if (subCategoryTemp == alertDataType.INTERSECTION) {
            alertImage = "t_intersection.png";
          } else if (subCategoryTemp == alertDataType.LANE_MERGE) {
            alertImage = "t_lane_merge.png";
          } else if (subCategoryTemp == alertDataType.LOW_GEAR_AREA) {
            alertImage = "t_low_gear_area.png";
          } else if (subCategoryTemp == alertDataType.NARROW_ROAD) {
            alertImage = "t_narrow_road.png";
          } else if (subCategoryTemp == alertDataType.MOBILE_USE) {
            alertImage = "t_mob_use.png";
          } else if (subCategoryTemp == alertDataType.NO_OVERTAKING) {
            alertImage = "t_no_overtaking.png";
          } else if (subCategoryTemp == alertDataType.OVER_SPEED) {
            alertImage = "t_over_speed.png";
          } else if (subCategoryTemp == alertDataType.P_CROSSING) {
            alertImage = "t_pedestrian_crossing.png";
          } else if (subCategoryTemp == alertDataType.R_CROSSING) {
            alertImage = "t_railway_crossing.png";
          } else if (subCategoryTemp == alertDataType.SCHOOL_ZONE) {
            alertImage = "t_school_zone.png";
          } else if (subCategoryTemp == alertDataType.STOP_SIGN) {
            alertImage = "t_stop.png";
          } else if (subCategoryTemp == alertDataType.SEVERE_BRAKING) {
            alertImage = "t_sudden_break.png";
          } else if (subCategoryTemp == alertDataType.TRAFFIC_LIGHT) {
            alertImage = "t_traffic_light.png";
          } else if (subCategoryTemp == alertDataType.WINDING_ROAD) {
            alertImage = "t_winding_road.png";
          } else if (subCategoryTemp == "YIELD") {
            alertImage = "t_yield.png";
          } else if (subCategoryTemp == alertDataType.PRIORITY) {
            alertImage = "t_priority.png";
          } else if (subCategoryTemp == alertDataType.ICY_CONDITIONS) {
            alertImage = "t_icy_conditions.png";
          } else if (subCategoryTemp == alertDataType.WIND) {
            alertImage = "t_wind.png";
          } else if (subCategoryTemp == alertDataType.HILL) {
            alertImage = "t_hill.png";
          } else if (subCategoryTemp == alertDataType.SLIPPERY_ROADS) {
            alertImage = "t_slippery_road.png";
          } else if (subCategoryTemp == alertDataType.RISK_OF_GROUNDING) {
            alertImage = "t_risk_of_grouping.png";
          } else if (subCategoryTemp == alertDataType.SEVERE_CORNERING) {
            alertImage = "sCornering.svg";
          } else if (subCategoryTemp == alertDataType.ROUND_ABOUT) {
            alertImage = "Round-about.svg";
          } else if (subCategoryTemp == alertDataType.NO_OVERTAKING_TRUCKS) {
            alertImage = "No-overtaking-trucks.svg";
          } else {
            alertImage = "not-avl.png";
          }
          if (riskValueTemp < 90 && riskValueTemp >= 60) {
            tripIcon = "alert_risk_amber.png";
          } else if (riskValueTemp < 60) {
            tripIcon = "alert_start.png";
          } else if (riskValueTemp >= 90) {
            tripIcon = "alert_risk_red.png";
          }
          var deviceDataObject = {
            rideId: rideId,
            alertImage: alertImage,
            tripIcon: tripIcon,
            subCategory: subCategory,
            rideName: rideName,
            date: startDate,
            distance: kiloMeter,
            speedLimit: speedLimit,
            speed: speed,
          };

          var RAPdf = {
            tripIcon: tripIcon,
            alertImage: alertImage,
            rideName: rideName,
            date: startDate,
            speed: speed,
          };

          if (subCategory != "" && category == "ALERT_DATA") {
            if (category != "STRESS_STRAIN_DATA" || category != "MANUAL_DATA") {
              // console.log(riskValueTemp);
              tripRiskList.push(deviceDataObject);
              riskAlertDataPdf.push(RAPdf)
            }
          }
        });

        setstressData(stressStrainDataList);
        getACBDataProps(acbDataPdf);
        setRiskAlertData(tripRiskList);
        getRiskAlertPdf(riskAlertDataPdf);
        setOverSpeedDataList(overSpeedData);
        getOSDataProps(osDatPdf);
        setMobileUseDataList(mobileUseData);
        getMsuDataProps(mobileUseData);
        setMobileScreenDataList(mobileScreenData);
        getMcuDataProps(msudataPdf);
        getMsuDataProps(mcuDataPdf);
        dispatch(OverSpeedLocationData({value:locationMapDataList}))
        // setOverSpeedLocationData(locationMapDataList);



        setRAlert(
          Object.values(tripRiskList).some((value) => value.rideName !== null)
        );
        setTableACB(
          Object.values(stressStrainDataList).some(
            (value) => value.rideName !== null
          )
        );
        setOSpeed(
          Object.values(overSpeedData).some((value) => value.rideName !== null)
        );
        setMobileSU(
          Object.values(mobileScreenData).some((value) => value.rideName !== null)
        );
        setMobileSU1(
          Object.values(mobileUseData).some((value) => value.rideName !== null)
        );
      } catch (e) {
        // console.log("otherAlertsErr" + e);
      }
    } else {
      const code = resultJSON.error.code;
      if (code == "E1206") {
        setDataDisplay("failed")
      }
    }
  };
  useEffect(() => {
    getAlertSummary();
    setRAlert();
    setTableACB();
    setOSpeed();
    setMobileSU();
    setMobileSU1();
  }, []);

  const [accCorBrak, setAccCorBrak] = useState(accDataDashbordTemps);
  const getRideCount = async () => {
    loaderCount((current) => current + 1);
    var result = await UserDrivingScoreController.riskCountController(
      filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime,
    );
    loaderCount((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    // console.log("getRideCount:" + JSON.stringify(resultJSONData));

    var riskValueListTemp = [];
    var otherRiskData = [];
    var lowData = [0, 0, 0];
    var mediumData = [0, 0, 0];
    var highData = [0, 0, 0];
    var dataLabel = [];
    var stressStrainLabel = [];
    var otherDataLabel = [];
    var noOfRecords = 0;
    var stressDataIndex = 0;

    for (let ii = 0; ii < riskAlertSubCategory.length; ii++) {
      var count1 = 0;
      var mainObject = riskAlertSubCategory[ii];
      var categoryMain = mainObject.category;
      var subCategoryMain = mainObject.subCategory;
      var label = mainObject.label;
      var subCategoryLevel = "";
      try {
        for (let i = 0; i < resultJSONData.length; i++) {
          var rData = resultJSONData[i];
          var riskAlertDetailCount = ValueFormat.parseAsArray(
            rData.riskAlertDetailCount
          );
          for (let count = 0; count < riskAlertDetailCount.length; count++) {
            var rideDataList = riskAlertDetailCount[count];
            var subCategoryTemp = ValueFormat.parseAsString(
              rideDataList.subCategory
            );
            var categoryTemp = ValueFormat.parseAsString(rideDataList.category);
            subCategoryLevel = ValueFormat.parseAsString(
              rideDataList.subCategoryLevel
            );

            if (
              subCategoryMain == subCategoryTemp &&
              categoryTemp != "DISTANCE_DATA"
            ) {
              noOfRecords = ValueFormat.parseAsInt(rideDataList.noOfRecords);
              count1 = count1 + noOfRecords;
              if (subCategoryTemp == "SEVERE_ACCELERATION") {
                stressDataIndex = 0;
              } else if (subCategoryTemp == "SEVERE_CORNERING") {
                stressDataIndex = 1;
              } else if (subCategoryTemp == "SEVERE_BRAKING") {
                stressDataIndex = 2;
              }
              if (categoryTemp == "STRESS_STRAIN_DATA") {
                if (subCategoryLevel == "LOW") {
                  lowData[stressDataIndex] = noOfRecords;
                } else if (subCategoryLevel == "MEDIUM") {
                  mediumData[stressDataIndex] = noOfRecords;
                } else if (subCategoryLevel == "HIGH") {
                  highData[stressDataIndex] = noOfRecords;
                }
              }
            }
          }
        }
        setAcb(
          Object.values(lowData).some((value) => value !== 0) ||
          Object.values(mediumData).some((value) => value !== 0) ||
          Object.values(highData).some((value) => value !== 0)
        );

        if (categoryMain == "MANUAL_DATA") {
          otherRiskData.push(count1);
          otherDataLabel.push(label);
        } else if (categoryMain == "ALERT_DATA") {
          riskValueListTemp.push(count1);
          dataLabel.push(label);
        } else if (categoryMain == "STRESS_STRAIN_DATA") {
          stressStrainLabel.push(label);
        }
      } catch (errMess) {
        // console.log(errMess);
      }
    }
    setIsDataAvailable(
      Object.values(otherRiskData).some((value) => value !== 0)
    );

    getACBProps((prev) => {
      return {
        ...prev,
        low: lowData,
        medium: mediumData,
        high: highData,
        label: stressStrainLabel,
      };
    })
    setStressStrainData((prev) => {
      return {
        ...prev,
        low: lowData,
        medium: mediumData,
        high: highData,
        label: stressStrainLabel,
      };
    });

    setOtherData(otherRiskData);
    getOsMcuMsuProps(otherRiskData);
    setOtherDataLabel(otherDataLabel);
    var seriesData = {
      name: "Count",
      data: riskValueListTemp,
      type: "bar",
      barWidth: 25,
      label: {
        show: true,
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideTop',
        distance: -5,
        color: 'black',
      },
      // markPoint: {
      //   data: [
      //     { type: 'max', name: 'Max' },
      //     { type: 'min', name: 'Min' }
      //   ]
      // }, 
    };
// console.log("riskValueListTemp",riskValueListTemp)
    setDataDisplay(Object.values(seriesData.data).some((value) => value !== 0));

    setBarOption((prev) => {
      return { ...prev, series: seriesData, xAxis: { ...prev.xAxis, data: dataLabel, }, };
    });
    
    getRCCProps({data:riskValueListTemp,filter:filter.durationFilter})
  }

  const getStressStrain = async () => {
    var result = await UserDrivingScoreController.stressStrainController();
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      // console.log("getStressStrain: " + JSON.stringify(resultJSONData));
    } else {
      const code = resultJSON.error.code;
      if (code == "E1206") {
        setDataDisplay("failed")
      }
    }
  };

  useEffect(() => {
    getRideCount();
    setDataDisplay();
    setIsDataAvailable();
    setAcb();
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime]);

  useEffect(() => {
    getStressStrain();
  }, [])


  const typeMapping = {
    "Severe acceleration": "Acceleration",
    "Severe cornering": "Cornering",
    "Severe braking": "Braking",
  };
  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className="cardHeader">
              <div className="d-flex flex-wrap align-items-center mb-2">
                <h4 className="sub_heading mb-0">Risk Alert Count</h4>
                {/* <div className="ms-auto">
                  <div>
                    <button
                      data-tooltip-id="cds"
                      type="button"
                      className={
                        filter == "CURRENT_DAY"
                          ? "btn btn-secondary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_DAY")}
                    >
                      CD
                      <ReactTooltip
                        id="cds"
                        place="top"
                        content="Current Day"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>{" "}
                    <button
                      data-tooltip-id="cws"
                      type="button"
                      className={
                        filter == "CURRENT_WEEK"
                          ? "btn btn-secondary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_WEEK")}
                    >
                      CW
                      <ReactTooltip
                        id="cws"
                        place="top"
                        content="Current Week"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>{" "}
                    <button
                      data-tooltip-id="cms"
                      type="button"
                      className={
                        filter == "CURRENT_MONTH"
                          ? "btn btn-secondary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_MONTH")}
                    >
                      CM
                      <ReactTooltip
                        id="cms"
                        place="top"
                        content="Current Month"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>{" "}
                    <button
                      data-tooltip-id="cys"
                      type="button"
                      className={
                        filter == "CURRENT_YEAR"
                          ? "btn btn-primary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_YEAR")}
                    >
                      CY
                      <ReactTooltip
                        id="cys"
                        place="top"
                        content="Current Year"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>{" "}
                    <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION"
                              ? "btn btn-secondary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{
                            setIsOpen(true);
                            // setFilter("CUSTOM_DURATION");
                          }}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ backgroundColor: "black" }}
                          />
                        </button>{" "}
                  </div>
                </div> */}
              </div>
            </CardHeader>

            <CardBody>
              {dataDisplay == "failed" ? (
                <p className="data_not_available">{displayText.ER_1206}</p>
              ) : dataDisplay == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
              ) : dataDisplay == null ? (<p className="fetch_data">{displayText.FETCHING_DATA}</p>
              ) : ""}
              <ReactEcharts
                option={barOption}
                style={{ height: "350px", width: "100vw" }}
              />
            </CardBody>

          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="sub_heading">
                Overspeed / Mobile Call Use / Mobile Screen Use
              </h4>
            </CardHeader>
            {dataDisplay == "failed" ? (
                <p className="data_not_available">{displayText.ER_1206}</p>
              ) : isDataAvailable == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
              ) : isDataAvailable == null ? (<p className="fetch_data">{displayText.FETCHING_DATA}</p>
              ) : ""}
            <CardBody>
              <OneRiskAlertChart otherData={otherData} otherDataLabel={otherDataLabel}/>
            </CardBody>
          </Card>
        </Col>

        <Col xl={6}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="sub_heading">Acceleration / Cornering / Braking</h4>
            </CardHeader>

            <CardBody>
              {dataDisplay == "failed" ? (
                <p className="data_not_available">{displayText.ER_1206}</p>
              ) : acb == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
              ) : acb == null ? (<p className="fetch_data">{displayText.FETCHING_DATA}</p>
              ) : ""}
              <StreesStrainChart stressStrainData={stressStrainData} />
            </CardBody>

          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={6} lg={6}>
          <Card style={{ height: "520px" }}>
            <CardHeader className="cardHeader">
              <h4 className="sub_heading" style={{marginLeft:"-8.5px"}}>Risk Alert</h4>
            </CardHeader>
            {dataDisplay == "failed" ? (
                <CardBody style={{ height: 500, overflow: "auto", padding: "0px" }}>
                <Table className="table mb-0">
                  <thead className="sticky-header">
                    <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Risk</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Alert</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Speed<br /><span style={{ fontSize: "11px", fontWeight: 400 }}>
                        (km/h)
                      </span></th>
                    </tr>
                  </thead>
                </Table>
                <p className="data_not_available" style={{height:"370px"}}>{displayText.ER_1206}</p>
            </CardBody>
              ) : rAlert == false ? (
                <CardBody style={{ height: 500, overflow: "auto", padding: "0px" }}>
                <Table className="table mb-0">
                  <thead className="sticky-header">
                  <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Risk</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Alert</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Speed<br /><span style={{ fontSize: "11px", fontWeight: 400 }}>
                        (km/h)
                      </span></th>
                    </tr>
                  </thead>
                </Table>
                <p className="data_not_available" style={{height:"370px"}}>{displayText.DATA_NOT_AVAILABLE}</p>
            </CardBody>
              ) : rAlert == null ? (
              <CardBody style={{ height: 500, overflow: "auto", padding: "0px" }}>
              <Table className="table mb-0">
                <thead className="sticky-header">
                <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Risk</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Alert</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Speed<br /><span style={{ fontSize: "11px", fontWeight: 400 }}>
                        (km/h)
                      </span></th>
                    </tr>
                </thead>
              </Table>
              <p className="fetch_data" style={{height:"370px"}}>{displayText.FETCHING_DATA}</p>
          </CardBody>
              ) : (<CardBody style={{ height: 500, overflow: "auto", padding: "0px" }}>
              <Table className="table mb-0">
                <thead className="sticky-header">
                <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Risk</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Alert</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Speed<br /><span style={{ fontSize: "11px", fontWeight: 400 }}>
                        (km/h)
                      </span></th>
                    </tr>
                </thead>
                <tbody>
                  {(riskAlertData || []).map((apiData, key) => (
                    <tr key={key}>
                      <td style={{ verticalAlign: "middle" }}>
                        &nbsp;&nbsp;
                        <img
                          src={require("../../assets/images/TripIcons/" +
                            apiData.tripIcon)}
                          width={12}
                          alt="image"
                        />{" "}
                      </td>
                      <td style={{ fontSize: "13px" }}>
                        <img
                          src={require("../../assets/images/" +
                            apiData.alertImage)}
                          alt={apiData.subCategory}
                          style={{ width: 30, height: 30 }}
                        />
                      </td>
                      <td
                        style={{
                          fontSize: "13px",
                          verticalAlign: "middle",
                        }}
                      >
                        <Link
                          to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(userId))}&divisionId=${encodeURIComponent(Converter.encrypt(divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}`}
                          state={{
                            userId: userId,
                            divisionId: divisionId,
                            rideId: apiData.rideId,
                          }}
                        >
                          {apiData.rideName ? apiData.rideName.charAt(0).toUpperCase() +
                            apiData.rideName.slice(1).toLowerCase() : ""}
                        </Link>
                      </td>
                      <td
                        style={{
                          fontSize: "13px",
                          verticalAlign: "middle",
                        }}
                      >
                        {apiData.date}
                      </td>
                      <td
                        style={{
                          fontSize: "13px",
                          verticalAlign: "middle",
                        }}
                      >
                        {apiData.speed}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
          </CardBody>)}
          </Card>
        </Col>
        <Col xl={6} lg={6}>
          <Card style={{ height: "520px" }}>
            <CardHeader className="cardHeader">
              <h4 className="sub_heading" style={{marginLeft:"-8.5px"}}>
                Acceleration / Cornering / Braking
              </h4>
            </CardHeader>
            {dataDisplay == "failed" ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px" }}>
                <Table className="table mb-0">
                  <thead className="sticky-header">
                    <tr>
                      <th style={{ fontSize: "13px", verticalAlign: "top" }}>Ride </th>
                      <th style={{ fontSize: "13px", verticalAlign: "top" }}>Date / Time</th>
                      <th style={{ fontSize: "13px", verticalAlign: "top" }}>Type</th>
                      <th style={{ fontSize: "13px", verticalAlign: "top" }}>Subtype</th>
                      <th style={{ fontSize: "13px" }}>Speed
                        <br />
                        <span style={{ fontSize: "11px", fontWeight: 400 }}>
                          (km/h)
                        </span>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <p className="data_not_available" style={{ height: "370px" }}>{displayText.ER_1206}</p>
              </CardBody>
            ) : tableACB == false ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px" }}>
                <Table className="table mb-0">
                  <thead className="sticky-header">
                    <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Ride </th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Type</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Subtype</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed
                        <br />
                        <span style={{ fontSize: "11px", fontWeight: 400 }}>
                          (km/h)
                        </span>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <p className="data_not_available" style={{ height: "370px" }}>{displayText.DATA_NOT_AVAILABLE}</p>
              </CardBody>
            ) : tableACB == null ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px" }}>
                <Table className="table mb-0">
                  <thead className="sticky-header">
                  <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Ride </th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Type</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Subtype</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed
                        <br />
                        <span style={{ fontSize: "11px", fontWeight: 400 }}>
                          (km/h)
                        </span>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <p className="fetch_data" style={{ height: "370px" }}>{displayText.FETCHING_DATA}</p>
              </CardBody>
            ) : (<CardBody style={{ height: 300, overflow: "auto", padding: "0px" }}>
              <Table className="table mb-0">
                <thead className="sticky-header">
                <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Ride </th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Type</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, verticalAlign: "top" }}>Subtype</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed
                        <br />
                        <span style={{ fontSize: "11px", fontWeight: 400 }}>
                          (km/h)
                        </span>
                      </th>
                    </tr>
                </thead>
                <tbody>
                  {(stressData || []).map((apiData, key) => (
                    <tr key={key}>
                      <td style={{ fontSize: "13px" }}>
                        {" "}
                        <Link
                          to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(userId))}&divisionId=${encodeURIComponent(Converter.encrypt(divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}`}
                          state={{
                            userId: userId,
                            divisionId: divisionId,
                            rideId: apiData.rideId,
                          }}
                        >
                          {apiData.rideName ? apiData.rideName.charAt(0).toUpperCase() +
                            apiData.rideName.slice(1).toLowerCase() : ""}
                        </Link>
                      </td>
                      <td style={{ fontSize: "13px" }}>{apiData.date}</td>
                      <td style={{ fontSize: "13px" }}>
                        {typeMapping[apiData.type] || apiData.type}
                      </td>
                      <td style={{ fontSize: "13px" }}>
                        {apiData.subCategoryLevel ?apiData.subCategoryLevel
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ") : ""}
                      </td>
                      <td style={{ fontSize: "13px" }}>{apiData.speed}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>)}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={4}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="sub_heading" style={{marginLeft:"-8.5px"}}>Overspeed</h4>
            </CardHeader>
            {dataDisplay == "failed" ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px" }} >
                <Table className="table">
                  <thead className="sticky-header">
                    <tr>
                      {/* <th style={{fontSize:"12px"}}>Count</th> */}
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed Limit</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                </Table>
                <p className="data_not_available" style={{ height: "230px" }}>{displayText.ER_1206}</p>
              </CardBody>
            ) : oSpeed == false ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px" }} >
                <Table className="table">
                  <thead className="sticky-header">
                  <tr>
                      {/* <th style={{fontSize:"12px"}}>Count</th> */}
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed Limit</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                </Table>
                <p className="data_not_available" style={{ height: "230px" }}>{displayText.DATA_NOT_AVAILABLE}</p>
              </CardBody>
            ) : oSpeed == null ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px" }} >
                <Table className="table">
                  <thead className="sticky-header">
                  <tr>
                      {/* <th style={{fontSize:"12px"}}>Count</th> */}
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed Limit</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                </Table>
                <p className="fetch_data" style={{ height: "230px" }}>{displayText.FETCHING_DATA}</p>
              </CardBody>
            ) : (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px"}} >
                <Table className="table">
                  <thead className="sticky-header">
                  <tr>
                      {/* <th style={{fontSize:"12px"}}>Count</th> */}
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed Limit</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(overSpeedDataList || []).map((apiData, key) => (
                      <tr key={key}>
                        <td style={{ fontSize: "13px", width: "22%" }}>
                          <Link
                          to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(userId))}&divisionId=${encodeURIComponent(Converter.encrypt(divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}`}
                          state={{
                              userId: userId,
                              divisionId: divisionId,
                              rideId: apiData.rideId,
                            }}
                          >
                            {apiData.rideName ?apiData.rideName.charAt(0).toUpperCase() +
                              apiData.rideName.slice(1).toLowerCase() : ""}
                          </Link>
                        </td>

                        <td style={{ fontSize: "13px", width: "48%" }}>{apiData.date}</td>
                        <td style={{ fontSize: "13px" }}>
                          {apiData.speedLimit}
                        </td>
                        <td style={{ fontSize: "13px" }}>{apiData.speed}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>)}

          </Card>
        </Col>
        <Col sm={6} lg={4}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="sub_heading" style={{marginLeft:"-8.5px"}}>Mobile Screen Use</h4>
            </CardHeader>
            {dataDisplay == "failed" ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px" }}>
                <Table className="table">
                  <thead
                    style={{
                      zIndex: 1,
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                </Table>
                <p style={{ height: "230px" }} className="data_not_available">{displayText.ER_1206}</p>
              </CardBody>

            ) : mobileSU == false ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px" }}>
                <Table className="table">
                  <thead
                    style={{
                      zIndex: 1,
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                </Table>
                <p style={{ height: "230px" }} className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
              </CardBody>
            ) : mobileSU == null ? (
              <CardBody style={{ height: 300, overflow: "auto", padding: "0px"}}>
                <Table className="table">
                  <thead
                    style={{
                      zIndex: 1,
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                </Table>
                <p style={{ height: "230px" }} className="fetch_data">{displayText.FETCHING_DATA}</p>
              </CardBody>
            ) : (<CardBody style={{ height: 300, overflow: "auto", padding: "0px", }}>
              <div>
                <Table className="table">
                  <thead
                    style={{
                      zIndex: 1,
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <tr>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(mobileScreenDataList || []).map((apiData, key) => (
                      <tr key={key}>
                        <td style={{ fontSize: "13px" }}>
                          <Link
                          to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(userId))}&divisionId=${encodeURIComponent(Converter.encrypt(divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}`}
                          state={{
                              userId: userId,
                              divisionId: divisionId,
                              rideId: apiData.rideId,
                            }}
                          >
                            {apiData.rideName ? apiData.rideName.charAt(0).toUpperCase() +
                              apiData.rideName.slice(1).toLowerCase() : ""}
                          </Link>
                        </td>
                        <td style={{ fontSize: "13px" }}>{apiData.date}</td>
                        <td style={{ fontSize: "13px" }}>{apiData.speed}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>)}

          </Card>
        </Col>
        <Col sm={6} lg={4}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="sub_heading" style={{marginLeft:"-8.5px"}}>Mobile Call Use</h4>
            </CardHeader>
            {dataDisplay == "failed" ? (
              <CardBody
                style={{ height: 300, overflow: "auto", padding: "0px" }}
              >
                <div>
                  <Table className="table">
                    <thead
                      style={{
                        zIndex: 1,
                        position: "sticky",
                        top: "0",
                        background: "white",
                      }}
                    >
                      <tr>
                        {/* <th style={{fontSize:"12px"}}>Count</th> */}
                        <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                        <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                        <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                      </tr>
                    </thead>
                  </Table>
                  <p className="data_not_available" style={{ height: "230px", display: "flex", alignItems: "center", justifyContent: "center" }}>{displayText.ER_1206}</p>
                </div>
              </CardBody>

            ) : mobileSU1 == false ? (<CardBody
              style={{ height: 300, overflow: "auto", padding: "0px" }}
            >
              <div>
                <Table className="table">
                  <thead
                    style={{
                      zIndex: 1,
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <tr>
                      {/* <th style={{fontSize:"12px"}}>Count</th> */}
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                </Table>
                <p className="data_not_available" style={{ height: "230px", display: "flex", alignItems: "center", justifyContent: "center" }}>{displayText.DATA_NOT_AVAILABLE}</p>
              </div>
            </CardBody>
            ) : mobileSU1 == null ? (<CardBody
              style={{ height: 300, overflow: "auto", padding: "0px" }}
            >
              <div>
                <Table className="table">
                  <thead
                    style={{
                      zIndex: 1,
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <tr>
                      {/* <th style={{fontSize:"12px"}}>Count</th> */}
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                </Table>
                <p className="fetch_data" style={{ height: "230px", display: "flex", alignItems: "center", justifyContent: "center" }}>{displayText.FETCHING_DATA}</p>
              </div>
            </CardBody>
            ) : (<CardBody
              style={{ height: 300, overflow: "auto", padding: "0px"}}
            >
              <div>
                <Table className="table">
                  <thead
                    style={{
                      zIndex: 1,
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <tr>
                      {/* <th style={{fontSize:"12px"}}>Count</th> */}
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Ride</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Date / Time</th>
                      <th style={{ fontSize: "14px",fontWeight: 600, }}>Speed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(mobileUseDataList || []).map((apiData, key) => (
                      <tr key={key}>
                        {/* <td style={{fontSize:"11px"}}>{apiData.count}</td> */}
                        <td style={{ fontSize: "13px" }}>
                          <Link
                          to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(userId))}&divisionId=${encodeURIComponent(Converter.encrypt(divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}`}
                          state={{
                              userId: userId,
                              divisionId: divisionId,
                              rideId: apiData.rideId,
                            }}
                          >
                            {apiData.rideName ? apiData.rideName.charAt(0).toUpperCase() +
                              apiData.rideName.slice(1).toLowerCase(): ""}
                          </Link>
                        </td>
                        <td style={{ fontSize: "13px" }}>{apiData.date}</td>
                        <td style={{ fontSize: "13px" }}>{apiData.speed}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>)}
          </Card>
        </Col>
      </Row>
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime}  />
    </React.Fragment>
  );
};

export default RiskCountChart;
