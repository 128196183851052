import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Loader from "../../../components/Common/loader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AdminDailySummeryController from "../../../controller/AdminReport/ADDailyReportController";
import ReactApexChart from "react-apexcharts";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import ValueFormat from "../../../util/ValueFormat";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index";
import { SC_USER_FIRST_NAME } from "../../../constant/StorageConstant";
import { displayText } from "../../../constant/MessageConstant";
import ToastService from "../../../components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup";
import lessThenImage from "../../../assets/images/lessThenImg.png";

export default function RiskChart() {
  // const series = [
  //   {
  //     name: "Ride Count",
  //     data: [],
  //   },
  // ];
  // const options = {
  //   chart: {
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: true,
  //       columnWidth: "45%",
  //       barHeight: "20%",
  //       endingShape: "rounded",
  //       dataLabels: {
  //         position: "top",
  //       },
  //     },
  //   },

  //   dataLabels: {
  //     enabled: true,
  //     style: {
  //       fontSize: "10px",
  //       // fontWeight: 'bold',
  //       colors: ["#006877"],
  //     },

  //     offsetX: 30,
  //   },
  //   stroke: {
  //     show: true,
  //     width: 1,
  //     colors: ["transparent"],
  //   },

  //   colors: ["#006877"],
  //   xaxis: {
  //     title: {
  //       text: "Ride Count",
  //     },
  //     categories: [],
  //   },
  //   yaxis: {
  //     title: {
  //       text: "User",
  //     },
  //   },
  //   grid: {
  //     borderColor: "#f1f1f1",
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: function (val) {
  //         return val;
  //       },
  //     },
  //   },
  // };

  const options = {
    tooltip: {},
    legend: {
      data: ["Ride Count"],
      top: 10,
    },
    grid: { bottom: 40, left: 140, right: 40, top: 50 },
    xAxis: {
      name: "Ride Count",
      type: "value",
      nameLocation: "middle",
      nameGap: 25,
      type: "value",
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 10,
      },
    },
    yAxis: {
      // name: "User",
      type: "category",
      nameLocation: "middle",
      nameGap: 110,
      inverse: true,
      data: [],
      // data: [0],
      // data: rideRiskData.rideRiskXaxis,

      axisLabel: {
        margin: 5,
        fontSize: 12,
        fontWeight: "normal",
        fontColor: "#f1f1f1",
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "Ride Count",
        type: "bar",
        itemStyle: {
          color: "#006877",
        },
        label: {
          show: true,
          position: "right",
          textStyle: {
            fontSize: 9,
            fontWeight: "bold",
          },
        },
        data: [0],
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },

        barWidth: 10,
      },
    ],
  };

  const optionsRisk = {
    tooltip: {},
    legend: {
      data: ["High Risk", "Medium Risk"],
      top: 10,
    },
    grid: { bottom: 40, left: 140, right: 40, top: 40 },
    xAxis: {
      name: "Risk Count",
      type: "value",
      nameLocation: "middle",
      nameGap: 25,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 12,
      },
    },
    yAxis: {
      // name: "User",
      type: "category",
      nameLocation: "middle",
      nameGap: 110,
      inverse: true,
      data: [],
      // data: rideRiskData.rideRiskXaxis,
      axisLabel: {
        margin: 5,
        fontSize: 12,
        fontWeight: "normal",
        fontColor: "#f1f1f1",
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "High Risk",
        type: "bar",
        data: [0],
        itemStyle: {
          color: "#E21212",
        },
        label: {
          show: true,
          position: "right",
          textStyle: {
            fontSize: 9,
            fontWeight: "bold",
          },
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ],
        // },
        barWidth: 10,
      },
      {
        name: "Medium Risk",
        type: "bar",
        data: [0],
        itemStyle: {
          color: "#FFBF00",
        },
        label: {
          show: true,
          position: "right",
          textStyle: {
            fontSize: 9,
            fontWeight: "bold",
          },
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
        barWidth: 10,
      },
    ],
  };

  // const seriesRisk = [
  //   {
  //     name: "High Risk",
  //     data: [],
  //   },
  //   {
  //     name: "Medium Risk",
  //     data: [],
  //   },
  // ];
  // const optionsRisk = {
  //   chart: {
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: true,
  //       columnWidth: "45%",
  //       barHeight: "40%",
  //       endingShape: "rounded",
  //       dataLabels: {
  //         position: "top",
  //       },
  //     },
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     offsetX: 30,
  //     style: {
  //       colors: ["#E21212", "#FFBF00"],
  //       fontSize: "10px",
  //     },
  //   },
  //   stroke: {
  //     show: true,
  //     width: 1,
  //     colors: ["transparent"],
  //   },

  //   colors: ["#E21212", "#FFBF00"],
  //   xaxis: {
  //     categories: [],
  //   },
  //   yaxis: {
  //     title: {
  //       text: "User",
  //     },
  //   },

  //   grid: {
  //     borderColor: "#f1f1f1",
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: function (val) {
  //         return val;
  //       },
  //     },
  //   },
  // };

  const [showLoader, setShowLoader] = useState(0);
  const [chartOption, setChartOption] = useState(options);
  const [riskChartOption, setRiskChartOption] = useState(optionsRisk);
  const [rideData, setrideData] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [chartData, setChartData] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("CURRENT_YEAR");
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [dataLength, setDataLength] = useState(null);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [dataDisplay, setDataDisplay] = useState(false);
  const containerIds = ["container1"];
  const navigate = useNavigate();

  const getRiskRideCount = async () => {
    setShowLoader((current) => current + 1);
    setDataDisplay(null);
    setrideData(null);
    setChartData(null);
    var result =
      await AdminDailySummeryController.adminDailyRideRiskCountExport(
        currentFilter, startDateTime, endDateTime
      );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      try {
        var resultJSONData = resultJSON.data;
        var xAxisData = [];
        var rideCount = [];
        var redAlertCount = [];
        var amberAlertCount = [];
        // console.log("getRiskRideCount :" + JSON.stringify(resultJSONData));
        // for (let count = 0; count < 10; count ++){
        setDataLength(resultJSONData.length);
        resultJSONData.map((apiData) => {
          var firstName = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetterAndFormat(apiData.firstName)
          );

          var rideCountTemp = ValueFormat.parseAsInt(apiData.rideCount);
          var alertCountList = ValueFormat.parseAsObject(
            apiData.riskAlertCount
          );
          var redCountTemp = ValueFormat.parseAsInt(
            alertCountList.highRiskCount
          );
          var amberCountTemp = ValueFormat.parseAsInt(
            alertCountList.mediumRiskCount
          );

          xAxisData.push(firstName);
          // alert(JSON.stringify(redCountTemp))
          rideCount.push(rideCountTemp);
          redAlertCount.push(redCountTemp);
          amberAlertCount.push(amberCountTemp);
          // console.log("vishnu : ", rideCount)
          setrideData(Object.values(rideCount).some((value) => value !== 0));
          setChartData(
            Object.values(redAlertCount).some((value) => value !== 0) ||
              Object.values(amberAlertCount).some((value) => value !== 0)
          );
        });

        // }

        var result = [{ name: "Ride Count", data: rideCount }];
        var riskResult = [
          { name: "High Risk", data: redAlertCount },
          { name: "Medium Risk", data: amberAlertCount },
        ];
        // setSeriesData(result);
        // setRiskSeriesData(riskResult);
        setChartOption((prev) => {
          return {
            ...prev,
            series: [
              {
                name: "Ride Count",
                type: "bar",
                itemStyle: {
                  color: "#006877",
                },
                label: {
                  show: true,
                  position: "right",
                  textStyle: {
                    fontSize: 9,
                    fontWeight: "bold",
                  },
                },
                data: rideCount,
                // markPoint: {
                //   data: [
                //     { type: 'max', name: 'Max' },
                //     { type: 'min', name: 'Min' }
                //   ]
                // },
                barWidth: 10,
              },
            ],
            yAxis: { type: "category", data: xAxisData },
          };
        });

        setRiskChartOption((prev) => {
          return {
            ...prev,
            series: [
              {
                name: "High Risk",
                type: "bar",
                itemStyle: {
                  color: "#E21212",
                },
                label: {
                  show: true,
                  position: "right",
                  textStyle: {
                    fontSize: 9,
                    fontWeight: "bold",
                  },
                },
                data: redAlertCount,
                // markPoint: {
                //   data: [
                //     { type: 'max', name: 'Max' },
                //     { type: 'min', name: 'Min' }
                //   ],
                // },
                barWidth: 10,
              },
              {
                name: "Medium Risk",
                type: "bar",
                itemStyle: {
                  color: "#FFBF00",
                },

                label: {
                  show: true,
                  position: "right",
                  textStyle: {
                    fontSize: 9,
                    fontWeight: "bold",
                  },
                },
                data: amberAlertCount,
                // markPoint: {
                //   data: [
                //     { type: 'max', name: 'Max' },
                //     { type: 'min', name: 'Min' }
                //   ]
                // },
                barWidth: 10,
              },
            ],
            yAxis: { type: "category", data: xAxisData },
          };
        });
      } catch (e) {
        // console.log("riskExport: " + e);
      }
      // console.log("riskchart", xAxisData);
    } else {
      var code = resultJSON.error.code;
      if (code == "E1206") {
        setDataDisplay("failed");
        setrideData("failed");

        ToastService.errormsg(displayText.ER_1206);
        // setTimeout(() => {
        //   window.location.href = "/adminDashboard";
        // }, 3500);
        setShowLoader(true);
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getRiskRideCount();
    setrideData();
    setChartData();
  }, [currentFilter, startDateTime, endDateTime]);

  return (
    <div className="page-content" id="container1">
      {/* <div className="ps-5 pe-5"> */}
      <Container fluid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "left", marginTop:"-3px" }}>
            <button
              data-tooltip-id="rides&risks"
              className="back-btn"
              onClick={handleBack}
              style={{
                backgroundColor: "#fff",
                fontWeight: "700",
                fontSize: "20px",
                marginTop: "-16px",
              }}
            >
              <img src={lessThenImage} alt="lessthenImag" style={{width:"25px",height:"25px"}}/>
              <ReactTooltip
                id="rides&risks"
                place="bottom"
                content="Back"
                style={{
                  backgroundColor: "black",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              />
            </button>
            <h3 className="card-title " style={{ marginLeft: "2px" }}>
              Rides & Risks
            </h3>
          </div>
          <div className="d-flex justify-content-between">
            <div className="ms-auto me-2">
              <div>
                <button
                  type="button"
                  className={
                    currentFilter == "CURRENT_DAY"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-primary btn-sm"
                  }
                  onClick={() => setCurrentFilter("CURRENT_DAY")}
                >
                  CD
                </button>{" "}
                <button
                  type="button"
                  className={
                    currentFilter == "CURRENT_WEEK"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setCurrentFilter("CURRENT_WEEK")}
                >
                  CW
                </button>{" "}
                <button
                  type="button"
                  className={
                    currentFilter == "CURRENT_MONTH"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setCurrentFilter("CURRENT_MONTH")}
                >
                  CM
                </button>{" "}
                <button
                  type="button"
                  className={
                    currentFilter == "CURRENT_YEAR"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-primary btn-sm"
                  }
                  onClick={() => setCurrentFilter("CURRENT_YEAR")}
                >
                  CY
                </button>{" "}
                <button
                  type="button"
                  className={
                    currentFilter == "CUSTOM_DURATION"
                      ? "btn btn-primary btn-sm me-2"
                      : "btn btn-soft-secondary btn-sm me-2"
                  }
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faClock} />
                </button>
              </div>
            </div>

            <div className="vl ms-1 me-4" ></div>

            <ExportScreenPdf
              containerIds={containerIds}
              fileName={userFirstName + "'s" + " Ride & Risk"}
            />
          </div>
        </div>

        <div className="p-2">
          {showLoader > 0 && <Loader />}
          <Row>
            <Col lg={6} xs={6}>
              <Card>
                <CardHeader className="cardHeader">
                  <h4 className="card-title">Rides</h4>
                </CardHeader>
                {dataDisplay == "failed" ? (
                  <p className="data_not_available">{displayText.ER_1206}</p>
                ) : rideData == false ? (
                  <p className="data_not_available">
                    {displayText.DATA_NOT_AVAILABLE}
                  </p>
                ) : rideData == null ? (
                  <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                ) : (
                  ""
                )}
                <ReactEcharts
                  option={chartOption}
                  type="bar"
                  style={{ height: Math.max(420, 50 * dataLength) + "px" }}
                />
              </Card>
            </Col>
            <Col lg={6} xs={6}>
              <Card>
                <CardHeader className="cardHeader">
                  <h4 className="card-title">Risks</h4>
                </CardHeader>
                {dataDisplay == "failed" ? (
                  <p className="data_not_available">{displayText.ER_1206}</p>
                ) : chartData == false ? (
                  <p className="data_not_available">
                    {displayText.DATA_NOT_AVAILABLE}
                  </p>
                ) : chartData == null ? (
                  <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                ) : (
                  ""
                )}
                <ReactEcharts
                  option={riskChartOption}
                  style={{ height: Math.max(400, 50 * dataLength) + "px" }}
                  // series={riskSeriesData}
                  type="bar"
                />
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer style={{ width: "auto" }} />
      </Container>
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setCurrentFilter}/>
    </div>
  );
}
