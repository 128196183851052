import React, { Component, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

const PieChart  = ({pieColors, WallentBalanceData }) => {

 const getOption = {
    color:pieColors,
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}({d}%) ",
      },
      
      legend: {
        show:false,
        orient: "vertical",
        left: "left",
        top: 50,
        textStyle: {
          color: ["#858d98"],
        },
      },
      series: [
        {
          name: "Safety Category",
          type: "pie",
          radius: "55%",
          center: ["48%", "45%"],
          data: WallentBalanceData || [],
          label:{
            show: true,
            position:'outside',
            fontSize: 12,
            formatter: "{b}: {c}",
          },
          itemStyle: {
         
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
   
  };

  
    return (
      <React.Fragment>
       
          
              <ReactEcharts
                style={{ height: "310px", width: "100%" }}
                option={getOption}
                // series={WallentBalanceData.data || []}
              />
           
        
      </React.Fragment>
    );
  
}
export default PieChart;
