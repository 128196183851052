import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const YearPopup = ({ setStartYear, setEndYear, setFilter }) => {
  const currentDate = new Date();
  const nowYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(nowYear);
  const handleAccept = (date) => {
    // This function is called when the user clicks "OK"
    const selectedYear = date.getFullYear();
    const startDateTime = `${selectedYear}-01-01 00:00:00`;
    const endDateTime = `${selectedYear}-12-31 23:59:59`;
    setSelectedYear(selectedYear);
    setStartYear(startDateTime);
    setEndYear(endDateTime);
    setFilter("CUSTOM_DURATION")
  };

  return (
    <div style={{ width: "100px", padding: "0px" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DemoContainer components={["MobileDatePicker"]}>
          <DemoItem>
            <MobileDatePicker
              className="MuiTypography-overline"
              sx={{"& .MuiInputBase-input": {
                height: "24px",
                fontSize: "14px"
              }}}
              value={new Date(selectedYear, 0, 1)}
              maxDate={currentDate}
              //   onChange={handleDateChangeTo}
              onAccept={handleAccept}
              minDate={new Date(2021, 0, 1)}
              views={["year"]}
              format="yyyy"
              ampm={false}
              minutesStep={1}
              slotProps={{
                textField: {
                  size: "small",
                  InputProps: {
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          // onClick={() => console.log("Edit clicked")}
                          size="small"
                          style={{width:'4px',height:"4px"}}
                        >
                          <EditIcon fontSize="1px"/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                  sx: {
                    "& .MuiInputBase-input": {
                      padding: "8px 0px 7.5px 12px", fontSize:'14px'// Adjust padding as needed
                    },
                  }
                },
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default YearPopup;
