import { Image, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
// import Logo from "../../../assets/images/pdf/pdfLogo.png";
import Logo from "../../../assets/images/logo/motivAiLogoPng.png";
// import cnIcon from "../../../assets/images/pdf/pdfIconCN.png";
// import fnIcon from "../../../assets/images/pdf/pdfIconFN.png";
import cnIcon from "../../../assets/images/pdf/customerNamePDF.png";
import fnIcon from "../../../assets/images/pdf/firstNamePDF.png";
import dIcon from "../../../assets/images/pdf/pdfIconDate.png";
import {
  SC_COMPANY_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";

export default function PdfHeaderV1() {
  const currentPath = window.location.pathname;
  const [userFirstName, setUserFirstName] = useState("");

  useEffect(() => {
    if (currentPath.includes("/user-dashboard")) {
      setUserFirstName(localStorage.getItem(SC_LOGIN_USER_FIRST_NAME));
    } else {
      setUserFirstName(localStorage.getItem(SC_USER_FIRST_NAME));
    }
  }, [currentPath]);

  const companyName = localStorage.getItem(SC_COMPANY_NAME);
  // const firstName = localStorage.getItem(SC_LOGIN_USER_FIRST_NAME);
  // Font.register({ family: 'Segoe UI', src: source, fontStyle: 'normal', fontWeight: 'normal', fonts?: [] });

  const header = [
    { title: "Customer Name : " + companyName },
    { title: "First Name : " + userFirstName },
  ];

  return (
    <View
      style={{
        // borderTop: "5px solid #ffcf24", backgroundColor: "#615AE4", flexDirection: "row", paddingTop: 5, paddingBottom: 5,
        borderTop: "5px solid #ffcf24",
        //  backgroundColor: "#f4f4f4",
        borderBottom: "1px solid lightgrey",
        flexDirection: "row",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 30,
        paddingRight: 30,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ width: "30%", marginRight: "10px", zIndex: 9999 }}>
        <Image
          src={Logo}
          alt={"logo"}
          style={{
            height: "30px",
            width: "140px",
            marginTop: "2px",
            marginBottom: "5px",
            zIndex: 9999,
          }}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
          width: "30%",
        }}
      >
        <Image
          src={cnIcon}
          style={{ height: "22px", width: "21px" }}
          alt={"fg"}
        />
        <Text
          style={{
            fontSize: 11,
            fontWeight: 600,
            textAlign: "left",
            color: "black",
            fontFamily: "Helvetica",
          }}
        >
          {header[0].title}
        </Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
          width: "30%",
        }}
      >
        <Image src={fnIcon} style={{ height: "18px", width: "18px" }} />
        <Text
          style={{
            fontSize: 11,
            fontWeight: 600,
            textAlign: "left",
            color: "black",
            fontFamily: "Helvetica",
          }}
        >
          {header[1].title}
        </Text>
      </View>
    </View>
  );
}
