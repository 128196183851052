import React, { useEffect, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown } from "react-icons/fa";
import Report from "../../controller/Report/Report";

export default function AppException() {
  const [showLoader, setShowLoader] = useState(false);
  const [totalPageCount , setTotalPageCount] = useState(0);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiDataUser, setApiDataUser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [exceptionFilter, setExceptionFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectUsername, setSelectUsername] = useState("")
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [nextShow, setNextShow] = useState(false);
  const [prevShow, setPrevShow] = useState(false);

  const pageSize = 10;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(userIdTemp);
    setSelectUsername(firstName);
    // Do something with the selected option
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const getAppExceptionReport = async (indexStart) => {
    setShowLoader(true);
    var result = await Report.getUserAppExpReport(
      indexStart,
      filter,
      exceptionFilter,
      deviceFilter,
      startDateTime,
      endDateTime,
      pageSize,
      selectedOption
    );
    var tableData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData", resultJSONData);
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var firstName = "",
            deviceType = "",
            uniqueId = "",
            versionNumber = "",
            appVersion = "",
            remote = "",
            userAgent = "",
            errorType = "",
            keyvalueTemp = "",
            batteryLevel = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userApp = resultJSONData[i];           
            var userDetail = ValueFormat.parseAsObject(userApp.userDetail);
            firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userDetail.firstName));
            userIdTemp = userApp.userId || "";
            var totalCounts = ValueFormat.parseAsInt(userApp.totalCount);
            setTotalPageCount(Math.ceil(totalCounts/pageSize));
            setNextShow(indexStart + pageSize < totalCounts);

            if (userApp.deviceType === "ANDROID") {
              deviceType = "Android"
            } else if (userApp.deviceType === "ANDROID_SDK"){
              deviceType = "Android SDK"
            } else if (userApp.deviceType === "IOS") {
              deviceType = "iOS"
            } else if (userApp.deviceType === "IOS_SDK"){
              deviceType = "iOS SDK"
            } else  {
              deviceType = "N/A"
            }
            // deviceType = ValueFormat.parseAsString(
            //   ValueFormat.capitalizeFirstLetter(userApp.deviceType)
            // );
            uniqueId = ValueFormat.parseAsString(userApp.deviceUniqueId);
            versionNumber = ValueFormat.parseAsInt(userApp.deviceVersionNumber);
            appVersion = ValueFormat.parseAsFloat(userApp.appVersion);
            remote = ValueFormat.parseAsFloat(userApp.remoteAddress);
            userAgent = ValueFormat.parseAsString(userApp.userAgent);
            errorType = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(userApp.errorType)
            );
            batteryLevel = ValueFormat.parseAsFloat(userApp.batteryLevel);
            keyvalueTemp =
              userApp.keyValues.map((val) =>
                ValueFormat.capitalizeFirstLetterAndFormat(val.key)
              ) || [];

            var appExceptiondetails = {
              firstName: firstName,
              deviceType: deviceType,
              uniqueId: uniqueId,
              versionNumber: versionNumber,
              appVersion: appVersion,
              remote: remote,
              userAgent: userAgent,
              errorType: errorType,
              batteryLevel: batteryLevel,
              keyvalueTemp: keyvalueTemp,
              userIdTemp: userIdTemp,
            };
            tableData.push(appExceptiondetails);
            setApiData(tableData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.code;
        // if (resultJSONCode == "E1206" && userType == "USER") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/dashboard";
        //   }, 3500);
        // } else if (resultJSONCode == "E1206" && userType == "ADMIN") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard";
        //   }, 3500);
        // }
        console.log("Status failed");
      }
    } catch (errMes) {
      console.log("Catch error:",errMes);
    } finally{
    setShowLoader(false);
    }
    if (indexStart == 0) {
      setPrevShow(false);
    } else if (indexStart > 1) {
      setPrevShow(true);
    }

  };

  const getUserList = async () => {
    // setShowLoader(true);
    var result = await Report.getUserListReport();
    // setShowLoader(false);
    var userData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData1", resultJSONData);
        // setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var firstName = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userListData = resultJSONData[i];
            firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userListData.firstName));
            userIdTemp = userListData.id;

            var userDataListTemp = {
              firstName: firstName,
              userIdTemp: userIdTemp,
            };

            userData.push(userDataListTemp);
            setApiDataUser(userData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.code;
        // if (resultJSONCode == "E1206" && userType == "USER") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/dashboard";
        //   }, 3500);
        // } else if (resultJSONCode == "E1206" && userType == "ADMIN") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard";
        //   }, 3500);
        // }
        console.log("Status failed");
      }
    } catch (errMes) {}
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
      setPageNumber(1);
      if (indexStart == 0) setCount((prev) => prev + 1);
      else setIndexStart(0);
  }, [filter,exceptionFilter,deviceFilter,startDateTime,endDateTime,selectedOption]);

  useEffect(() => {
    if (count > 0) getAppExceptionReport(indexStart);
}, [indexStart,count]);

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        {/* {loginType == "SUPER_ADMIN" ? (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Welcome, You are viewing{" "}
            <span
              style={{
                fontSize: "13px",
                fontWeight: 800,
              }}
            >
              {userFirstName}'s
            </span>{" "}
            App Exception
          </span>
        ) : ( */}
            <Row className="my-2 py-2">
            <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
              <h5 className="mb-0">User Exception Activities</h5>
            </Col>
          </Row>
          {/* )}  */}

        <Row>
          <Col className="col-12 mt-2">
            <Card>
              <CardHeader style={{ padding: "0px" }}>
                <Row>
                  <Col
                    md={4}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        borderBottom: "1px dashed #DFDEDE",
                      }}
                    >
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Error Selection
                      </h4>
                    </div>
                    <div className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          exceptionFilter == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("")}
                        data-tooltip-id="alle"
                      >
                        All
                        <ReactTooltip
                          id="alle"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "PRE_INSTALLATION"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("PRE_INSTALLATION")}
                        data-tooltip-id="preIn"
                      >
                        Pre Install
                        <ReactTooltip
                          id="preIn"
                          place="top"
                          content="Pre Installation"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "POST_INSTALLATION"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("POST_INSTALLATION")}
                        data-tooltip-id="postin"
                      >
                        Post Install
                        <ReactTooltip
                          id="postin"
                          place="top"
                          content="Post Installation"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "PRE_LOGIN"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("PRE_LOGIN")}
                        data-tooltip-id="preLo"
                      >
                        Pre Login
                        <ReactTooltip
                          id="preLo"
                          place="top"
                          content="Pre Login"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "POST_LOGIN"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("POST_LOGIN")}
                        data-tooltip-id="postLo"
                      >
                        Post Login
                        <ReactTooltip
                          id="postLo"
                          place="top"
                          content="Post Login"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Device Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px"}}>
                      <button
                        type="button"
                        className={
                          deviceFilter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID")}
                        data-tooltip-id="androidBl"
                      >
                        Android
                        <ReactTooltip
                          id="androidBl"
                          place="top"
                          content="Android"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("IOS")}
                        data-tooltip-id="iosBl"
                      >
                        iOS
                        <ReactTooltip
                          id="iosBl"
                          place="top"
                          content="iOS"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "WEB"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("WEB")}
                        data-tooltip-id="Webt"
                      >
                        Web
                        <ReactTooltip
                          id="Webt"
                          place="top"
                          content="Web"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Duration Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px" }}>
                      <button
                        type="button"
                        className={
                          filter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_DAY" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                        data-tooltip-id="cd"
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_WEEK" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                        data-tooltip-id="cw"
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_MONTH" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                        data-tooltip-id="cm"
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_YEAR" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                        data-tooltip-id="cy"
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CUSTOM_DURATION" || modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        data-tooltip-id="dfdf"
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <ReactTooltip
                          id="dfdf"
                          place="top"
                          content="Custom Date Selection"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{
                      paddingRight: 0, paddingLeft: 0,
                    }}
                  >
                    <div style={{ marginRight: "10px", borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        User Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px"}}>
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        size="sm"
                      >
                        <DropdownToggle
                          caret
                          color="primary"
                        >
                          {selectUsername ? selectUsername : "All Users"}
                          <FaAngleDown style={{ marginLeft: "5px" }} />
                        </DropdownToggle>
                        <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
                          <DropdownItem onClick={() => handleSelect(" ")}>
                            All Users
                          </DropdownItem>
                          {apiDataUser.map(user => (
                            <DropdownItem key={user.userIdTemp} onClick={() => handleSelect(user.userIdTemp, user.firstName)}>
                              {user.firstName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered mb-0">
                        <thead className="cardHeader">
                          <tr>
                            <th
                              data-tooltip-id="Fn"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              First Name
                              <ReactTooltip
                                id="Fn"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="err"
                              style={{fontSize: "13px",fontWeight: 600}}
                            >
                              Error
                              <ReactTooltip
                                id="err"
                                place="top"
                                content="Error Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="deviceType"
                              style={{fontSize: "13px",fontWeight: 600}}
                            >
                              Device Type
                              <ReactTooltip
                                id="deviceType"
                                place="top"
                                content="Device Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="appversion"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              App Version
                              <ReactTooltip
                                id="appversion"
                                place="top"
                                content="App Version"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="versionNumber"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Device Version Number
                              <ReactTooltip
                                id="versionNumber"
                                place="top"
                                content="Device Version Number"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="uniqueId"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Device Unique Id
                              <ReactTooltip
                                id="uniqueId"
                                place="top"
                                content="Device Unique Id"
                                style={{ background: "black" }}
                              />
                            </th>
                            
                            
                            <th
                              data-tooltip-id="rAddress"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Remote
                              <ReactTooltip
                                id="rAddress"
                                place="top"
                                content="Remote Address"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="UserAgent"
                              style={{fontSize: "13px",fontWeight: 600,  }}
                            >
                              User Agent
                              <ReactTooltip
                                id="UserAgent"
                                place="top"
                                content="User Agent"
                                style={{ background: "black" }}
                              />
                            </th>
                            
                            <th
                              data-tooltip-id="batterlevel"
                              style={{fontSize: "13px",fontWeight: 600, }}
                            >
                              Battery Level
                              <ReactTooltip
                                id="batterylevel"
                                place="top"
                                content="Battery Level"
                                style={{ background: "black" }}
                              />
                            </th>
                            {/* <th
                              data-tooltip-id="keyval"
                              style={{fontSize: "13px",fontWeight: 600, padding: "13px" }}
                            >
                              Key Values
                              <ReactTooltip
                                id="keyval"
                                place="top"
                                content="Key Values"
                                style={{ background: "black" }}
                              />
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            apiData.map((apiDataTemp, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.firstName}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.errorType}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.deviceType}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.appVersion}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.versionNumber}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.uniqueId}
                                  </span>
                                </td>
                                
                                
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.remote}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.userAgent}
                                  </span>
                                </td>
                                
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.batteryLevel}
                                  </span>
                                </td>

                                {/* <td
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  <tr
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {apiDataTemp.keyvalueTemp
                                      .slice(0, 2)
                                      .map((temp, i) => (
                                        <span
                                          key={i}
                                          style={{
                                            fontSize: "13px",
                                            margin: "3px",
                                          }}
                                        >
                                          {temp}
                                          {i <
                                            apiDataTemp.keyvalueTemp.length -
                                              1 && " ,"}
                                        </span>
                                      ))}
                                  </tr>
                                  <tr
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {apiDataTemp.keyvalueTemp
                                      .slice(2, 4)
                                      .map((temp, i) => (
                                        <span
                                          key={i}
                                          style={{
                                            fontSize: "13px",
                                            margin: "3px",
                                            marginTop: "0px",
                                          }}
                                        >
                                          {temp}
                                          {i <
                                            apiDataTemp.keyvalueTemp.slice(0, 2)
                                              .length -
                                              1 && " ,"}
                                        </span>
                                      ))}
                                  </tr>
                                </td> */}
                              </tr>
                            ))
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className="pagination">
                  <a>{pageNumber}</a>
                </div>
                <p style={{marginTop:"6px",marginLeft:"5px",marginRight:"5px"}} >of</p>             
              <div className="pagination ">                  
             <a>{totalPageCount}</a>             
           </div> 
           </div>
              ) : null}
              {dataLength > 0 ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
                ) : null}
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}
