import React, { Component } from "react";
import cloneDeep from "lodash.clonedeep";
import ReactEcharts from "echarts-for-react";

const  Guage  = ({drivingStatus}) => {
 

  const getOption = () => {
    return {
      tooltip: {
        formatter: "{b} : {c}%",
      },
      series: [
        {
          name: "Driving Points",
          type: "gauge",
          detail: { formatter: "{value}%" },
          axisLine: {
            lineStyle: {
              color: [
                [0.84, "#E21212"],
                [0.93, "#DEA602"],
                [1, "#06CD91"],
              ],
              width: 20,
            },
          },
          axisLabel: {
            distance: 25,
            color: "#999",
            fontSize: 10,
          },
          title: {           
            fontSize: 12
          },
          detail: {       
           
            valueAnimation: true,
            formatter: function (value) {
              return '{value|' + value.toFixed(0) + '}';
            },
            rich: {
              value: {
                fontSize: 20,                
                color: 'black'
              },               
            }
            
          },
         
          data: [{ value: drivingStatus.drivingScore, name: "Driving Points" }],
        },
      ],
    };
  };


    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "400px" }} option={getOption()} />
      </React.Fragment>
    );
  }
export default Guage;
