  export const GlobalConst = {
      SUCCESS: "SUCCESS",
      FAILED: "FAILED"
  };

  export const vueNameConst = {
      SPLASH: "Splash",
      MOTIV_AI: "MotivAI",
      HEADER: "Header",
      DRIVE_DASHBOARD: "DriveDashboard",
      CAR_DASHBOARD: "CarDashboard",
      Forgot: "Forgot",
      LOGIN: "Login",
      SIGN_UP: "SignUp"
  };