import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
} from "reactstrap";

import AppBootSettingsController from "../../controller/AppBootSettings/AppBootSettingsController";
import ToastService from "../../components/Common/toastService";
import Loader from "../../components/Common/loader";
import "../UserAppBootSettings/appBootStyles.css";
import ValueFormat from "../../util/ValueFormat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { displayText } from "../../constant/MessageConstant";
import { SC_USER_LOGIN_TYPE } from "../../constant/StorageConstant";
import { RiArrowDropDownLine } from "react-icons/ri";

const AppBootSettings = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(true);
  const [dasmValue, setDasmValue] = useState("SENSOR");
  const [workFilter, setWorkFilter] = useState({
    DASM: "NORMAL",
    DASSM: "AUTO",
    TM: "API_CALL",
    DAST: 5,
    DUR: "RIDE_DRIVER",
    DRDT: "ALL_RIDE",
    DDCT: "ALL",
  });
  const deviceSettings = {
    DASM: [
      {
        label: "Sensor",
        value: "SENSOR",
      },
      {
        label: "Normal",
        value: "NORMAL",
      },
    ],
    DASSM: [
      {
        label: "Auto",
        value: "AUTO",
      },
      {
        label: "Manual",
        value: "MANUAL",
      },
    ],
    TM: [
      {
        label: "API Call",
        value: "API_CALL",
      },
      {
        label: "Ride CSV",
        value: "RIDE_CSV",
      },
    ],

    DAST: ["5", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
    DUR: [
      {
        label: "Ride Driver",
        value: "RIDE_DRIVER",
      },
      {
        label: "Ride Passenger",
        value: "RIDE_PASSENGER",
      },
    ],
    DRDT: [
      {
        label: "All Ride",
        value: "ALL_RIDE",
      },
      {
        label: "Valid Ride",
        value: "VALID_RIDE",
      },
    ],
    DDCT: [
      {
        label: "All",
        value: "ALL",
      },
      {
        label: "Android",
        value: "ANDROID",
      },
      {
        label: "Ios",
        value: "IOS",
      },
    ],
  };

  const [settings, setSettings] = useState(deviceSettings);
  const [userId, setUserId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [getResLength, setGetResLength] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [count, setCount] = useState(0);

  const userType = localStorage.getItem(SC_USER_LOGIN_TYPE);

  const getAppBootSettings = async () => {
    setCount((current) => current + 1);

    var result = await AppBootSettingsController.getSettings();
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    // if(status == "SUCCESS"){

    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      // var status = resultJSON.status;
      // console.log("getAppBootSettings: " + JSON.stringify(resultJSONData));
      setGetResLength(resultJSONData.length);
      setShowLoader(true);
      if (resultJSONData.length > 0) {
        resultJSONData.map((value) => {
          var id = value.id.length > 0 ? value.id : "";
          setUserId(id);

          var userPrefAppBootSetting = value.userPrefAppBootSetting;
          var deviceAutoStartMode =
            userPrefAppBootSetting.deviceAutoStartMode.length > 0
              ? userPrefAppBootSetting.deviceAutoStartMode
              : "";
          var deviceAutoStartSubMode =
            userPrefAppBootSetting.deviceAutoStartSubMode.length > 0
              ? userPrefAppBootSetting.deviceAutoStartSubMode
              : "";
          var deviceDetailTranferMode =
            userPrefAppBootSetting.deviceDetailTranferMode.length > 0
              ? userPrefAppBootSetting.deviceDetailTranferMode
              : "";
          var deviceAutoStopMinute =
            userPrefAppBootSetting.deviceAutoStopMinute.length > 0
              ? userPrefAppBootSetting.deviceAutoStopMinute
              : "";

          var deviceRawDetailTranferFile = ValueFormat.parseAsString(
            userPrefAppBootSetting.deviceRawDetailTranferFile
          );

          var deviceDetailCSVTranferFrom = ValueFormat.parseAsString(
            userPrefAppBootSetting.deviceDetailCSVTranferFrom
          );
          var deviceUserRole = ValueFormat.parseAsString(
            userPrefAppBootSetting.deviceUserRole
          );
          var result = {
            DASM: deviceAutoStartMode,
            DASSM: deviceAutoStartSubMode,
            TM: deviceDetailTranferMode,
            DAST: deviceAutoStopMinute,
            DRDT: deviceRawDetailTranferFile,
            DDCT: deviceDetailCSVTranferFrom,
            DUR: deviceUserRole,
          };

          setWorkFilter(result);
        });

        setCount((current) => current - current);
      }
      setShowLoader(false);
    } else {
      console.log("Status failed");
      // var resultJSONCode = resultJSON.error.code;
      // if (resultJSONCode == "E1206" && userType == "USER") {
      //   ToastService.errormsg(displayText.ER_1206);
      //   setTimeout(() => {
      //     window.location.href = "/dashboard";
      //   }, 3500);
      // }
      setCount((current) => current - current);
    }
    // }
  };
  useEffect(() => {
    getAppBootSettings();
  }, []);

  const postAppBootSettings = async () => {
    setCount((current) => current + 1);
    var postSettings = {
      deviceAutoStartMode: workFilter.DASM,
      deviceAutoStartSubMode: workFilter.DASSM,
      deviceDetailTranferMode: workFilter.TM,
      deviceAutoStopMinute: workFilter.DAST,
      deviceRawDetailTranferFile: workFilter.DRDT,
      deviceDetailCSVTranferFrom: workFilter.DDCT,
      deviceUserRole: workFilter.DUR,
    };

    var result = await AppBootSettingsController.postSettings(postSettings);
    var resultJSON = JSON.parse(result);

    var status = resultJSON.status;
    if (status == "SUCCESS") {
      ToastService.successmsg("Successfully Posted");
      getAppBootSettings();
    }
    setCount((current) => current - current);

    // console.log("postAppBootSettings: " + JSON.stringify(resultJSON));
  };

  const putAppBootSettings = async () => {
    setCount((current) => current + 1);
    var putSettings = {
      deviceAutoStartMode: workFilter.DASM,
      deviceAutoStartSubMode: workFilter.DASSM,
      deviceDetailTranferMode: workFilter.TM,
      deviceAutoStopMinute: workFilter.DAST,
      deviceRawDetailTranferFile: workFilter.DRDT,
      deviceDetailCSVTranferFrom: workFilter.DDCT,
      deviceUserRole: workFilter.DUR,
    };

    var result = await AppBootSettingsController.putSettings(
      userId,
      putSettings
    );
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      ToastService.successmsg("Successfully Updated");
      getAppBootSettings();
    }
    setCount((current) => current - current);

    // console.log("putAppBootSettings: " + JSON.stringify(resultJSON));
  };

  const submit = () => {
    setModalOpen(true);
  };

  const handleModalYes = () => {
    getResLength > 0 ? putAppBootSettings() : postAppBootSettings();
    setSubmitVisible(true);
    setModalOpen(false)
  };
  const handleModalNo = () => {
    setModalOpen(false);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <ToastContainer style={{ width: "auto" }} />

      <Container fluid>
      <Row className="my-3">
          <Col xs={12} className="d-flex justify-content-start text-black">
            <h5>App Boot Settings</h5>
          </Col>
        </Row>
        <Card>
          <CardHeader className="cardHeader">
            <h2 className="card-title">App Boot Settings</h2>
          </CardHeader>
          {/* <CardBody className="pb-0">
            <Row>
              <Col md={4}>
                <Row md={12}>
                  <Col md={6}>
                    <h4 className="headingStyles">Ride User Role</h4>
                  </Col>
                  <Col md={6} p={0} m={0}>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={workFilter.DUR}
                        onChange={(e) => [
                          setWorkFilter((prev) => {
                            return { ...prev, DUR: e.target.value };
                          }),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(settings.DUR || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.value}
                          >
                            {device.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={6}>
                    <h4 className="headingStyles">Ride Auto Stop Minute</h4>
                  </Col>
                  <Col md={6}>
                    <div
                      className="mt-3 mb-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={workFilter.DAST}
                        onChange={(e) => [
                          setWorkFilter((prev) => {
                            return { ...prev, DAST: e.target.value };
                          }),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(settings.DAST || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device}
                          >
                            {device}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={6}>
                    <h4 className="headingStyles">Ride Detail Transfer Mode</h4>
                  </Col>
                  <Col md={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                      className="mt-3 mb-3"
                    >
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={workFilter.TM}
                        onChange={(e) => [
                          setWorkFilter((prev) => {
                            return { ...prev, TM: e.target.value };
                          }),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(settings.TM || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.value}
                          >
                            {device.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row md={12}>
                  <Col md={6}>
                    <h4 className="headingStyles">Ride Auto Start Mode</h4>
                  </Col>
                  <Col md={6} p={0} m={0}>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={workFilter.DASM}
                        onChange={(e) => [
                          setWorkFilter((prev) => {
                            return { ...prev, DASM: e.target.value };
                          }),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(settings.DASM || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.value}
                          >
                            {device.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={6}>
                    <h4 className="headingStyles">
                      Ride Raw Detail Tranfer File
                    </h4>
                  </Col>
                  <Col md={6}>
                    <div
                      className="mt-3 mb-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={workFilter.DRDT}
                        onChange={(e) => [
                          setWorkFilter((prev) => {
                            return { ...prev, DRDT: e.target.value };
                          }),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(settings.DRDT || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.value}
                          >
                            {device.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row md={12}>
                  <Col md={6}>
                    <h4 className="headingStyles">Ride Auto Start Sub Mode</h4>
                  </Col>
                  <Col md={6} p={0} m={0}>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        onChange={(e) => [
                          setWorkFilter((prev) => {
                            return { ...prev, DASSM: e.target.value };
                          }),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(deviceSettings.DASSM || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.value}
                          >
                            {device.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={6}>
                    <h4 className="headingStyles">
                      Ride Detail CSV Tranfer From
                    </h4>
                  </Col>
                  <Col md={6}>
                    <div
                      className="mt-3 mb-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        style={{ width: "60%" }}
                        value={workFilter.DDCT}
                        onChange={(e) => [
                          setWorkFilter((prev) => {
                            return { ...prev, DDCT: e.target.value };
                          }),
                          setSubmitVisible(false),
                        ]}
                      >
                        {(settings.DDCT || []).map((device, index) => (
                          <option
                            style={{ textAlign: "left" }}
                            key={index}
                            value={device.value}
                          >
                            {device.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row
              style={{
                borderTop: "1px solid #DFDEDE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col sm={4} style={{ paddingLeft: "0px", paddingBottom: "10px" }}>
                <div className="d-flex justify-content-center mt-2">
                  <button
                    className="btn btn-primary"
                    disabled={submitVisible}
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody> */}
          <CardBody>
            <Row>
              <Col md={3} xs={12}>
                <FormGroup>
                  <Label className="headingStyles">
                    Ride Auto Stop Minutes
                  </Label>
                  <Input
                    type="select"
                    name="cars"
                    id="cars"
                    value={workFilter.DAST}
                    onChange={(e) => [
                      setWorkFilter((prev) => {
                        return { ...prev, DAST: e.target.value };
                      }),
                      setSubmitVisible(false),
                    ]}
                  >
                    {(settings.DAST || []).map((device, index) => (
                      <option
                        style={{ textAlign: "left" }}
                        key={index}
                        value={device}
                      >
                        {device}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3} xs={12}>
                <FormGroup>
                  <Label className="headingStyles">
                    Ride Detail Transfer Mode
                  </Label>
                  <Input
                    type="select"
                    name="cars"
                    id="cars"
                    value={workFilter.TM}
                    onChange={(e) => [
                      setWorkFilter((prev) => {
                        return { ...prev, TM: e.target.value };
                      }),
                      setSubmitVisible(false),
                    ]}
                  >
                    {(settings.TM || []).map((device, index) => (
                      <option
                        style={{ textAlign: "left" }}
                        key={index}
                        value={device.value}
                      >
                        {device.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3} xs={12}>
                <FormGroup>
                  <Label className="headingStyles">Ride Auto Start Mode</Label>
                  <Input
                    type="select"
                    name="cars"
                    id="cars"
                    value={workFilter.DASM}
                    onChange={(e) => [
                      setWorkFilter((prev) => {
                        return { ...prev, DASM: e.target.value };
                      }),
                      setSubmitVisible(false),
                    ]}
                  >
                    {(settings.DASM || []).map((device, index) => (
                      <option
                        style={{ textAlign: "left" }}
                        key={index}
                        value={device.value}
                      >
                        {device.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <div
              color="primary"
              id="toggler"
              style={{ marginBottom: "1rem", width:"100%", textAlign:"right", padding:"2px", background:"#eaebebcc", border:"1px solid #d1d1d1" }}
            >
              Additional Features ▼
            </div>
            <UncontrolledCollapse toggler="#toggler">
              <Row>
                <Col md={3} xs={12}>
                  <FormGroup>
                    <Label className="headingStyles">Ride User Role</Label>
                    <Input
                      type="select"
                      name="cars"
                      id="cars"
                      value={workFilter.DUR}
                      onChange={(e) => [
                        setWorkFilter((prev) => {
                          return { ...prev, DUR: e.target.value };
                        }),
                        setSubmitVisible(false),
                      ]}
                    >
                      {(settings.DUR || []).map((device, index) => (
                        <option
                          style={{ textAlign: "left" }}
                          key={index}
                          value={device.value}
                        >
                          {device.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3} xs={12}>
                  <FormGroup>
                    <Label className="headingStyles">
                      Ride Raw Detail Tranfer File
                    </Label>
                    <Input
                      type="select"
                      name="cars"
                      id="cars"
                      value={workFilter.DRDT}
                      onChange={(e) => [
                        setWorkFilter((prev) => {
                          return { ...prev, DRDT: e.target.value };
                        }),
                        setSubmitVisible(false),
                      ]}
                    >
                      {(settings.DRDT || []).map((device, index) => (
                        <option
                          style={{ textAlign: "left" }}
                          key={index}
                          value={device.value}
                        >
                          {device.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3} xs={12}>
                  <FormGroup>
                    <Label className="headingStyles">
                      Ride Auto Start Sub Mode
                    </Label>
                    <Input
                      type="select"
                      name="cars"
                      id="cars"
                      onChange={(e) => [
                        setWorkFilter((prev) => {
                          return { ...prev, DASSM: e.target.value };
                        }),
                        setSubmitVisible(false),
                      ]}
                    >
                      {(deviceSettings.DASSM || []).map((device, index) => (
                        <option
                          style={{ textAlign: "left" }}
                          key={index}
                          value={device.value}
                        >
                          {device.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3} xs={12}>
                  <FormGroup>
                    <Label className="headingStyles">
                      Ride Detail CSV Tranfer From
                    </Label>
                    <Input
                      type="select"
                      name="cars"
                      id="cars"
                      value={workFilter.DDCT}
                      onChange={(e) => [
                        setWorkFilter((prev) => {
                          return { ...prev, DDCT: e.target.value };
                        }),
                        setSubmitVisible(false),
                      ]}
                    >
                      {(settings.DDCT || []).map((device, index) => (
                        <option
                          style={{ textAlign: "left" }}
                          key={index}
                          value={device.value}
                        >
                          {device.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </UncontrolledCollapse>
          </CardBody>
          <CardFooter>
                <div className="d-flex justify-content-center mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
          </CardFooter>
        </Card>
        <ToastContainer style={{ width: "auto" }} />
      </Container>
      <Modal isOpen={modalOpen}>
        <ModalHeader>
         Changing your settings will make the following changes:
        </ModalHeader>
        <ModalBody>
          <li>
            This action is to ensure the settings of your account is to be changed.
          </li>
          <li className="mt-3">These changes will apply to the upcoming rides and wont affect the previous & current rides</li>
          <li className="mt-3 mb-3">
            Please make sure you agree to proceed before continuing.
          </li>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" onChange={handleCheckboxChange} style={{border:"1px solid black"}}/> I Agree
              to the above conditions
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={handleModalNo} className="btn btn-outline-danger">
            Cancel
          </button>
          <button
            onClick={handleModalYes}
            className={`btn btn-outline-success ${isChecked ? "" : "disabled"}`}
            disabled={!isChecked}
          >
            Proceed
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AppBootSettings;
