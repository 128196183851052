import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
import logo from "../../assets/images/logo-sm.svg";
import avatar from "../../assets/images/users/avatar-1.jpg";
import CarouselPage from './CarouselPage';
import motivLogo from "../../assets/images/ruduicon.png";
import RuduLogo from "../../assets/images/logo/motivAiLogo.svg";
import { SC_LOCK_STATUS, SC_PASSWORD, SC_USER_FIRST_NAME } from '../../constant/StorageConstant';
import MyRideIcon from "../../assets/images/MyRideIcon.svg";
import {BiSolidUserCircle} from "react-icons/bi";
import CryptoJS from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../components/Common/toastService";
import UserSessionController from "../../controller/UserSession/UserSessionController.js";
import Converter from '../../components/Common/EncodeDecode';
import { GlobalConst } from '../../constant/GlobalConstant.js';







const LockScreen = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const navigate = useNavigate();

    const history = useNavigate();

  const logout = async () =>{
    var result = await UserSessionController.deleteUserSession();
    var resultTemp = JSON.parse(result);
    var resultStatus = resultTemp.status;
    if (resultStatus == GlobalConst.SUCCESS) {
    history("/logout")
    }
    else {
    history("/logout")
    }
  }




    let checkPassword =  async (event) => {
    event.preventDefault()
    var currentPassword = localStorage.getItem(SC_PASSWORD);
    currentPassword = Converter.decodeBase64(currentPassword, "password")
        // console.log(password , currentPassword)
        if (password == currentPassword){
        navigate(-1)
        localStorage.setItem(SC_LOCK_STATUS, false)
        }
        else {
            ToastService.errormsg("Incorrect Password");
        }
       
        // if (resultStatus == GlobalConst.SUCCESS && resultJSONData != null) {
        //   var category = "";
        //   var groupCode = "";
        //   var groupName = '';
        //   try {
        //     category = resultJSONData.user.category;
        //     groupCode = resultJSONData.group.code;
        //     groupName = resultJSONData.group.name;
        //     localStorage.setItem(SC_COMPANY_NAME,groupName)
        //     localStorage.setItem(SC_PASSWORD, CryptoJS.AES.decrypt(password, "password").toString(CryptoJS.enc.Utf8))
           
        //   } catch (err) {
        //     console.log("Error : " + err);
        //   }
        //   if (category == "USER") {
        //     localStorage.setItem(SC_USER_LOGIN_TYPE, "USER");
        //     window.location.href = "/dashboard"
        //   } else if (category == "ADMIN") {
        //     localStorage.setItem(SC_USER_LOGIN_TYPE, "ADMIN");
        //     window.location.href = "/adminDashboard";
        //     // this.$router.push({ path: "/adminDashboard" });
        //   } else if (groupCode === "DEFAULT") {
        //     localStorage.setItem(SC_USER_LOGIN_TYPE, "SUPER_ADMIN")  ;
        //     window.location.href = "/superAdminDashboard";
        //     // this.$router.push({ path: "/superadmindashboardmotivai" });
        //   }
        //    else if (groupCode != "DEFAULT") {
        //     localStorage.setItem(SC_USER_LOGIN_TYPE, "SUPER_ADMIN_AMAZON");
        //     window.location.href = "/superAdminClientDashboard";
        //     // this.$router.push({ path: "/superadmindashboardAmazon" }); 
        //   }
        // }
        
        //  else {
        //   // alert("not matched");
          
        //   ToastService.errormsg(resultTemp.error.message);
        // }
      
      }







    useEffect(()=>{
        var userFirstNameTemp = localStorage.getItem(SC_USER_FIRST_NAME);
        if (userFirstNameTemp && userFirstNameTemp.length > 0) {
          try {
            var userFirstName =
              userFirstNameTemp[0].toLocaleUpperCase() +
              userFirstNameTemp.slice(1);
              setUserName(userFirstName);
          } catch (errMess) {
            // console.log("Error : " + errMess);
          }
        }
       },[])
    //meta title
    document.title = "Lock Screen | Motiv AI";
    return (
        <React.Fragment>
            <ToastContainer  style={{width:"auto"}}/>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col className="col-xxl-3 col-lg-4 col-md-5">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={RuduLogo} alt="" height="40" /> 
                                                {/* <span className="logo-txt">Rudu</span> */}
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">Lock screen</h5>
                                                <p className="text-muted mt-2">Enter your password to unlock the screen!</p>
                                            </div>
                                            <div className="user-thumb text-center mb-4 mt-4 pt-2">
                                            <BiSolidUserCircle style={{width:"45px",height:"45px"}}/>
                                                <h5 className="font-size-15 mt-3">{userName}</h5>
                                            </div>
                                            <form className="custom-form mt-4" onSubmit={checkPassword}>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="userpassword">Password</label>
                                                    <div className="input-group auth-pass-inputgroup">
                                                    <input type={passwordShow ? 'text' : 'password'} className="form-control" id="userpassword" value={password}
                                                     onChange={(e)=>setPassword(e.target.value)} placeholder="Enter password" />
                                                        <button
                                      onClick={() =>
                                        setPasswordShow(!passwordShow)
                                      }
                                      className="btn btn-light shadow-none ms-0"
                                      type="button"
                                      id="password-addon"
                                    >
                                      {!passwordShow ? (
                                        <i className="mdi mdi-eye-outline"></i>
                                      ) : (
                                        <i className="mdi mdi-eye-off"></i>
                                      )}
                                    </button></div>
                                                </div>
                                                <div className="mb-3 mt-4">
                                                    <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Unlock</button>
                                                </div>
                                            </form>

                                            <div className="mt-5 text-center">
                                                <p className="text-muted mb-0">Not you ? return</p> 
                                                 <button onClick={logout} className="dropdown-item" > Sign in </button> 
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">{new Date().getFullYear()} © Motiv AI. All rights reserved.
                                             {/* <i className="mdi mdi-heart text-danger"></i> by Themesbrand */}
                                             </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LockScreen;