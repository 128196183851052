import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import {
    SC_COMPANY_ID,
    SC_DIVISION_ID,
    SC_USER_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let CurrentLocationController = {
    async currentLocation() {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        // var queryFields = {filterType:"CURRENT_DAY"}
        if (
            companyId.length > 0 &&
            divisionId.length > 0 &&
            userId.length > 0
        ) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                type: "SPEEDO_METER_DEVICE",
                sortBy: "createdAt",
                sortOrder: "DESC",
                offset: "0",
                limit: "1",
                queryType:"SAD_MULTI_USER_VEHICLE_CURRENT_LOCATION",
                // queryFields:JSON.stringify(queryFields)
            };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.QUERY,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
};

export default CurrentLocationController;