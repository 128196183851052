import React, { useEffect, useState } from "react";
import { Card, CardText, Container, Row } from "reactstrap";
import "./MyRidesMobScreen.css";

import redBluetooth from "../../assets/images/redBluetooth.svg";
import greenBluetooth from "../../assets/images/greenBluetoothSvg.svg";
import startToDestination from "../../assets/images/starToDestination.svg";
import blueSandClockIcon from "../../assets/images/blueSandClock.svg";
import steeringWheelIcon from "../../assets/images/streeringWheel.svg";
import yellowCalendarIcon from "../../assets/images/yellowCallendar.svg";
import blueGpsRouteIcon from "../../assets/images/blueGpsRoute.svg";
import helmetIcon from "../../assets/images/helmet.svg";
import noHelmetIcon from "../../assets/images/noHelmet.svg";
import noPhotoIcon from "../../assets/images/noPhoto.svg";
import withSeatbeltIcon from "../../assets/images/withSeatBelt.svg";
import withoutSeatbeltIcon from "../../assets/images/withoutSeatBelt.svg";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import MyRidesController from "../../controller/Rides/MyRides";
import MyRidedataList from "./myridetabledata";
import ValueFormat from "../../util/ValueFormat";
import Loader from "../../components/Common/loader";
import { FormControl, InputBase, MenuItem, Select } from "@mui/material";
import { InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

const MyRidesMobScreen = () => {
  const [indexStart, setIndexStart] = useState(0);
  const [filterDays, setFilterDays] = useState("");
  const [workFilter, setWorkFilter] = useState(" ");
  const [rideFilter, setRideFilter] = useState("RIDE_DRIVER");
  const [searchText, setSearchText] = useState("");
  const pageSize = 100;
  const [apiData, setApiData] = useState(MyRidedataList);
  const [prevShow, setPrevShow] = useState();
  const [nextShow, setNextShow] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [dataLength, setDataLength] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPadding, setTotalPadding] = useState({
    paddingLeft:
      window.innerWidth >= 769 && window.innerWidth <= 2560 ? "10%" : "1%",
    paddingRight:
      window.innerWidth >= 769 && window.innerWidth <= 2560 ? "10%" : "1%",
  });

  useEffect(() => {
    function handleResize() {
      setTotalPadding({
        paddingLeft:
          window.innerWidth >= 769 && window.innerWidth <= 2560 ? "10%" : "1%",
        paddingRight:
          window.innerWidth >= 769 && window.innerWidth <= 2560 ? "10%" : "1%",
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the 'paramName' query parameter
  const paramNameValue = queryParams.get("username");

  useEffect(() => {
    const getMobData = async () => {
      setShowLoader(true);
      var response = await MyRidesController.myRidesMobileList(
        paramNameValue,
        indexStart,
        filterDays,
        workFilter,
        rideFilter,
        searchText,
        pageSize
      );
      setShowLoader(false);
      var updatedTableData = [];
      try {
        var resultJSON = JSON.parse(response);
        var status = resultJSON.status;
        if (status == "SUCCESS") {
          var resultJSONData = resultJSON.data;
          setDataLength(resultJSONData.length);
          if (resultJSONData.length > 0) {
            var endTime = "N/A",
              duration = "0",
              distance = "0",
              driveScore = "0";
            for (let i = 0; i < resultJSONData.length; i++) {
              var userRideDetail = resultJSONData[i];

              var rideNo = ValueFormat.parseAsString(
                ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.name)
              );
              var accessLevel = ValueFormat.parseAsString(
                userRideDetail.accessLevel
              );
              var rideBox = ValueFormat.parseAsInt(
                userRideDetail.rideBox.rideBox
              );
              var deviceSafetyField = ValueFormat.parseAsString(
                userRideDetail.deviceSafetyField
              );

              duration = ValueFormat.parseAsFloat(userRideDetail.travelTime, 2);

              distance = ValueFormat.parseAsFloat(userRideDetail.kiloMeter, 2);
              var locationName = ValueFormat.parseAsString(
                userRideDetail.locationName.trim()
              );

              driveScore = Math.round(
                ValueFormat.parseAsInt(userRideDetail.drivingScore)
              );

              var deviceFieldCity = ValueFormat.parseAsString(
                userRideDetail.deviceField.city.trim()
              );
              var deviceFieldState = ValueFormat.parseAsString(
                userRideDetail.deviceField.state.trim()
              );
              var deviceFieldCountry = ValueFormat.parseAsString(
                userRideDetail.deviceField.country.trim()
              );

              var combineValue =
                [
                  locationName,
                  deviceFieldCity,
                  deviceFieldState,
                  deviceFieldCountry,
                ]
                  .filter(
                    (value) =>
                      value !== undefined && value !== null && value !== ""
                  )
                  .join(", ") || "NA";

              var lastLocationName = ValueFormat.parseAsString(
                userRideDetail.lastLocationName.trim()
              );

              var lastDeviceFieldCity = ValueFormat.parseAsString(
                userRideDetail.lastDeviceField.city.trim()
              );
              var lastDeviceFieldState = ValueFormat.parseAsString(
                userRideDetail.lastDeviceField.state.trim()
              );
              var lastDeviceFieldCountry = ValueFormat.parseAsString(
                userRideDetail.lastDeviceField.country.trim()
              );

              var lastCombineValue =
                [
                  lastLocationName,
                  lastDeviceFieldCity,
                  lastDeviceFieldState,
                  lastDeviceFieldCountry,
                ]
                  .filter(
                    (value) =>
                      value !== undefined && value !== null && value !== ""
                  )
                  .join(", ") || "NA";

              var startTime = ValueFormat.parseAsString(
                userRideDetail.startDateTimeToTimeZone
              );
              endTime = ValueFormat.parseAsString(
                userRideDetail.endDateTimeToTimeZone
              );

              const inputDateTime = startTime;
              const formatDate = (dateTimeString) => {
                const date = new Date(dateTimeString);
                const day = date.getDate().toString().padStart(2, "0");
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, "0");
                const minutes = date.getMinutes().toString().padStart(2, "0");
                const amOrPm = hours >= 12 ? "PM" : "AM";
                const formattedHours = (hours % 12 || 12)
                  .toString()
                  .padStart(2, "0");
                const formattedDateTime = `${day}/${month}/${year} | ${formattedHours}:${minutes} ${amOrPm}`;
                return formattedDateTime;
              };
              var formattedStartDate = formatDate(inputDateTime);

              const inputTime = endTime;
              const formatTime = (timeString) => {
                const date = new Date(timeString);
                const hours = date.getHours().toString().padStart(2, "0");
                const minutes = date.getMinutes().toString().padStart(2, "0");
                const amOrPm = hours >= 12 ? "PM" : "AM";
                const formattedHours = (hours % 12 || 12)
                  .toString()
                  .padStart(2, "0");
                return `${formattedHours}:${minutes} ${amOrPm}`;
              };
              var formattedTime = formatTime(inputTime);

              var userDetails = {
                rideNo: rideNo,
                accessLevel: accessLevel,
                duration: duration,
                distance: distance,
                formattedStartDate: formattedStartDate,
                formattedTime: formattedTime,
                combineValue: combineValue,
                lastCombineValue: lastCombineValue,
                score: driveScore,
                rideBox: rideBox,
                deviceSafetyField,
                deviceSafetyField,
              };

              updatedTableData.push(userDetails);
            }
            setApiData(updatedTableData);
          }
        } else {
          console.log("Status failed");
          // var resultJSONCode = resultJSON.error.code;
          // if (resultJSONCode == "E1206" && userType == "USER") {
          //   ToastService.errormsg(displayText.ER_1206);
          //   setTimeout(() => {
          //     window.location.href = "/dashboard";
          //   }, 3500);
          // } else if (resultJSONCode == "E1206" && userType == "ADMIN") {
          //   ToastService.errormsg(displayText.ER_1206);
          //   setTimeout(() => {
          //     window.location.href = "/adminDashboard";
          //   }, 3500);
          // }
        }
      } catch (errMess) {
        console.log("Exception: ", errMess);
      }
      if (indexStart == 0) {
        setPrevShow(false);
      } else if (indexStart > 0) {
        setPrevShow(true);
      }

      if (resultJSONData.length == 5) {
        setNextShow(true);
      } else {
        setNextShow(false);
      }
    };
    setTimeout(() => {
      getMobData();
    }, 800);
  }, [indexStart, filterDays, workFilter, rideFilter]);

  const [maxWidth, setMaxWidth] = useState(
    window.innerWidth <= 319
      ? "150px"
      : window.innerWidth <= 375
      ? "200px"
      : window.innerWidth >= 376 && window.innerWidth <= 500
      ? "250px"
      : "350px"
  );
  useEffect(() => {
    const handleResize = () => {
      setMaxWidth(
        window.innerWidth <= 319
          ? "150px"
          : window.innerWidth <= 375
          ? "200px"
          : window.innerWidth >= 376 && window.innerWidth <= 500
          ? "250px"
          : "350px"
      );
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleWorkFilterChange = (e) => {
    setWorkFilter(e.target.value);
  };

  const handleRideFilterChange = (e) => {
    setRideFilter(e.target.value);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      borderRadius: 12,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "2px 10px 2px 10px",
      textAlign: "center",
      AlignItems: "center",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 12,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  return (
    <div className="p-2">
      {showLoader && <Loader />}
      <Container fluid>
        <Row style={totalPadding}>
          <div className="col-12 p-0 pb-2 d-flex align-items-center">
            <div className="col-4 d-flex">
              <CardText tag="h5" className=" ms-2">
                My Rides
              </CardText>
            </div>
            <div className="col-8 pe-2 d-flex justify-content-between">
              <FormControl
                size="small"
                variant="standard"
                style={{ minWidth: "49%", maxWidth: "49%", height: "30px" }}
              >
                <InputLabel htmlFor="demo-customized-select-native"></InputLabel>
                <Select
                  id="demo-customized-select-native"
                  value={workFilter}
                  onChange={handleWorkFilterChange}
                  input={<BootstrapInput />}
                >
                  <MenuItem value=" ">All</MenuItem>
                  <MenuItem value="WORK">Work</MenuItem>
                  <MenuItem value="PERSONAL">Personal</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                size="small"
                variant="standard"
                style={{ minWidth: "49%", maxWidth: "49%", height: "30px" }}
              >
                <InputLabel htmlFor="demo-customized-select-native"></InputLabel>
                <Select
                  id="demo-customized-select-native"
                  value={rideFilter}
                  onChange={handleRideFilterChange}
                  input={<BootstrapInput />}
                >
                  <MenuItem value="RIDE_DRIVER">Driver</MenuItem>
                  <MenuItem value="RIDE_PASSENGER">Passenger</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </Row>
        {dataLength > 0 &&
          apiData.map((apiDataTemp, i) => (
            <Container fluid key={i} style={totalPadding}>
              <div>
                <Card className="card_style px-2 pt-1">
                  <div className="col-12 d-flex mb-1">
                    <div className=" col-8 mb-1 d-flex align-items-center">
                      <CardText tag="h6" className="m-0 me-2 rideNo">
                        {apiDataTemp.rideNo.toUpperCase()}
                      </CardText>
                      <>
                        {Object.keys(apiDataTemp.deviceSafetyField).length ===
                          0 && (
                          <img
                            alt="noPhotoIcon"
                            src={noPhotoIcon}
                            className="safetyField"
                          />
                        )}
                        {apiDataTemp.deviceSafetyField.helmetAloneCount === 0 &&
                          apiDataTemp.deviceSafetyField.userWithHelmetCount ===
                            0 &&
                          apiDataTemp.deviceSafetyField
                            .userWithSeatbeltCount === 0 &&
                          apiDataTemp.deviceSafetyField
                            .userWithoutHelmetCount === 0 &&
                          apiDataTemp.deviceSafetyField
                            .userWithoutSeatbeltCount === 0 && (
                            <img
                              alt="noPhotoIcon"
                              src={noPhotoIcon}
                              className="safetyField"
                            />
                          )}
                        {apiDataTemp.deviceSafetyField.helmetAloneCount === 1 &&
                          apiDataTemp.deviceSafetyField.userWithHelmetCount ===
                            1 &&
                          apiDataTemp.deviceSafetyField
                            .userWithSeatbeltCount === 1 &&
                          apiDataTemp.deviceSafetyField
                            .userWithoutHelmetCount === 1 &&
                          apiDataTemp.deviceSafetyField
                            .userWithoutSeatbeltCount === 1 && (
                            <img
                              alt="noPhotoIcon"
                              src={noPhotoIcon}
                              className="safetyField"
                            />
                          )}
                        {apiDataTemp.deviceSafetyField.helmetAloneCount ===
                          1 && (
                          <img
                            alt="noHelmetIcon"
                            src={noHelmetIcon}
                            className="safetyField"
                          />
                        )}
                        {apiDataTemp.deviceSafetyField.userWithHelmetCount ===
                          1 && (
                          <img
                            alt="helmetIcon"
                            src={helmetIcon}
                            className="safetyField"
                          />
                        )}
                        {apiDataTemp.deviceSafetyField.userWithSeatbeltCount ===
                          1 && (
                          <img
                            alt="withSeatbeltIcon"
                            src={withSeatbeltIcon}
                            className="safetyField"
                          />
                        )}
                        {apiDataTemp.deviceSafetyField
                          .userWithoutHelmetCount === 1 && (
                          <img
                            alt="noHelmetIcon"
                            src={noHelmetIcon}
                            className="safetyField"
                          />
                        )}
                        {apiDataTemp.deviceSafetyField
                          .userWithoutSeatbeltCount === 1 && (
                          <img
                            alt="withoutSeatbeltIcon"
                            src={withoutSeatbeltIcon}
                            className="safetyField"
                          />
                        )}
                      </>
                      {apiDataTemp.accessLevel === "AUTHENTICATED" && (
                        <>
                          <img
                            alt="bluetoothIcon"
                            src={greenBluetooth}
                            className="bluetooth"
                          />
                        </>
                      )}
                      {apiDataTemp.accessLevel === "UNAUTHENTICATED" && (
                        <>
                          <img
                            alt="bluetoothIcon"
                            src={redBluetooth}
                            className="bluetooth"
                          />
                        </>
                      )}
                    </div>
                    <div
                      className="col-4 justify-content-end"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        alt="blueSandClockIcon"
                        src={blueSandClockIcon}
                        className="sandClockIcon"
                      />
                      <CardText tag="p">
                        {" "}
                        {`${apiDataTemp.duration} Mins`}
                      </CardText>
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-1">
                    <div className="col-1 p-0">
                      <img
                        alt="yellowCalendarIcon"
                        src={yellowCalendarIcon}
                        className="yellowCalendarIcon"
                      />
                    </div>

                    <div className="col-7 p-0" style={{ marginTop: "2px" }}>
                      <CardText>
                        {
                          apiDataTemp.formattedStartDate
                          // +
                          //   " - " +
                          //   apiDataTemp.formattedTime
                        }
                      </CardText>
                    </div>

                    <div className="col-4 p-0 d-flex justify-content-end">
                      <img
                        alt="blueGpsRouteIcon"
                        src={blueGpsRouteIcon}
                        className="gpsRouteIcon"
                      />
                      <CardText style={{ marginTop: "3px" }}>
                        {`${apiDataTemp.distance} KM`}
                      </CardText>
                    </div>
                  </div>

                  <div className="col-12 d-flex mb-1">
                    <div className="col-1 p-0">
                      <img
                        alt="yellowsteeringIcon"
                        src={steeringWheelIcon}
                        className="yellowsteeringIcon"
                      />
                    </div>

                    <div className="col-8 p-0" style={{ marginTop: "0px" }}>
                      <CardText style={{ color: "#996515" }}>
                        {apiDataTemp.rideBox === 1
                          ? "Safe Driver"
                          : apiDataTemp.rideBox === 2
                          ? "Sportive Driver"
                          : apiDataTemp.rideBox === 3
                          ? "Quite Safe Driver"
                          : apiDataTemp.rideBox === 4
                          ? "Reckless Driver"
                          : apiDataTemp.rideBox === 5
                          ? "Quite Dangerous Driver"
                          : apiDataTemp.rideBox === 6
                          ? "Dangerous Driver"
                          : ""}
                      </CardText>
                    </div>
                  </div>

                  <div className="col-12 mt-0 mb-1 d-flex p-0">
                    <div className="col-1 p-0 justify-content-start ">
                      <img
                        src={startToDestination}
                        style={{ marginTop: "4px" }}
                      />
                    </div>
                    <div className="col-9 p-0 destinationIcon">
                      <CardText
                        className="location"
                        style={{
                          marginTop: "2px",
                          maxWidth: maxWidth,
                          color: "#3155ED",
                          marginBottom: "0px",
                        }}
                        title={apiDataTemp.combineValue}
                      >
                        {apiDataTemp.combineValue}
                      </CardText>
                      <CardText
                        className="location"
                        style={{
                          marginTop: "6px",
                          maxWidth: maxWidth,
                          color: "#3155ED",
                        }}
                        title={apiDataTemp.lastCombineValue}
                      >
                        {apiDataTemp.lastCombineValue}
                      </CardText>
                    </div>
                    <div className="col-2 justify-content-end">
                      <div className="circularProgressBarDiv">
                        <CircularProgressbar
                          value={apiDataTemp.score}
                          text={`${apiDataTemp.score}`}
                          styles={buildStyles({
                            textColor: "#001A46",
                            pathColor: "#58B10A",
                            trailColor: "#0495f7",
                            textSize: "28px",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </Container>
          ))}
      </Container>
    </div>
  );
};

export default MyRidesMobScreen;
