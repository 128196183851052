import { apiConfig } from "../config/Config.js";
import { apiEndPoint } from "../constant/ApiConstant.js";
import { ApiService } from "../service/ApiService.js";
import {
  SC_COMPANY_ID,
  SC_USER_ID,
  SC_DIVISION_ID,
  SC_USER_LOGIN_TYPE,
  SC_GROUP_ID,
  SC_MODULE_ID,
} from "../constant/StorageConstant.js";
import UserSessionController from "./UserSession/UserSessionController.js";

let MyRidesCtrlr = {
  
 // ---------------------------------- Ride List api -------------------------------------------------- //

  async getV1RideList(
    indexStart,
    filterDays,
    mode,   
    subFilterText,
    pageSize,
    startDateTime,
    endDateTime
  ) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);        
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userType = UserSessionController.getLocalSessionValue(SC_USER_LOGIN_TYPE);
    var subFilterType = "";    

    if (userType != "SUPER_ADMIN") {
      subFilterType = "SUCCESS";
    }

    var dashboardFields = {
        durationType: filterDays,
      scoreValidationStatus: subFilterType,
      searchType: "WILD_CARD_SEARCH",
      searchText: subFilterText,
      deviceMode: mode,
      travelType: "RIDE_DRIVER",
    };

    if (companyId.length > 0 && userId.length > 0) {

      var reqPayload = {
        companyId: companyId,
        groupId:groupId,
        userId: userId,
        divisionId: divisionId,       
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: indexStart,
        limit: 25,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dashboardType: "V1_RIDE_LIST",
        dashboardFields: JSON.stringify(dashboardFields),
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );

    } else {

      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';

    }
  }, 

// --------------------------------------------------------------------------------------------- //

// ---------------------------------- Ride Deatils Ride api -------------------------------------------------- //

async getV1RideDeatils( 
    divisionId,  
    userId,
    rideId,
    rideCategory 
  ) {

    
    var companyId     = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId       = UserSessionController.getLocalSessionValue(SC_GROUP_ID);   
    var userType      = UserSessionController.getLocalSessionValue(SC_USER_LOGIN_TYPE);
    var subFilterType = "";    

    if (userType != "SUPER_ADMIN") {
      subFilterType = "SUCCESS";
    }

    var dashboardFields = {      
        scoreValidationStatus: subFilterType, 
        travelType: rideCategory,
    };

    if (companyId && userId) {

      var reqPayload = {
        companyId       : companyId,
        groupId         :groupId,
        userId          : userId,
        deviceId        : rideId,
        divisionId      : divisionId,       
        sortBy          : "createdAt",
        sortOrder       : "ASC",       
        limit           : 25,       
        dashboardType   : "V1_RIDE_LIST",
        dashboardFields : JSON.stringify(dashboardFields),
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );

    } else {

      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';

    }
  }, 

// --------------------------------------------------------------------------------------------- //

// ---------------------------------- Risk Histogram Ride api -------------------------------------------------- //

async getV1RideSummaryDetails(divisionId, userId, rideId) {

    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID); 
    var dashboardFields = {travelType: "RIDE_DRIVER"}

    if (
      companyId.length > 0 &&
      divisionId.length > 0 &&
      userId.length > 0 &&
      rideId.length > 0
    ) {

      var reqPayload = {

        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,
        userId          : userId,
        deviceId        : rideId,       
        sortBy          : "createdAt",
        sortOrder       : "ASC",
        offset          : "0",
        limit           : "1000",
        dashboardType   : "V1_RIDE_SUMMARY",
        dashboardFields : JSON.stringify(dashboardFields)

      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );

    } else {

      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    }
  },

// --------------------------------------------------------------------------------------------- //


async getRideDetailV1RideDataList(divisionId, userId, rideId) {
  // rideId = trimValue(rideId);
  var dashboardFields = {showLastData : "YES", travelType: "RIDE_DRIVER"};
  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  
  if (rideId.length > 0) {
    var reqParams = {
      companyId: companyId,
      groupId:groupId,
      divisionId: divisionId,
      userId: userId,
      deviceId: rideId,
      category:
        "START_DATA,ALERT_DATA,STRESS_STRAIN_DATA,MANUAL_DATA,END_DATA",      
      sortBy: "createdAt",
      sortOrder: "ASC",
      offset: "0",
      limit: "1000",
      subCategory:"",
      status:"",
      dashboardType: "V1_RIDE_DATA_LIST",
      dashboardFields: JSON.stringify(dashboardFields)
    };
    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.DASHBOARD,
      1,
      reqParams
    );
  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }
},

// ---------------------------------- CSV api -------------------------------------------------- //

async getCSVFile(rideId, divisionId, userId) {
  var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var moduleId = UserSessionController.getLocalSessionValue(SC_MODULE_ID);
  var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

  if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
    var reqParams = {
      companyId: companyId,
      divisionId: divisionId,
      userId: userId,
      deviceId: rideId,
      moduleId: moduleId,
      groupId: groupId,
      type: "RIDE_RAW_CSV",
    };
   

    return await ApiService.callGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.FILE + apiEndPoint.DEVICE + apiEndPoint.RAWDATA,
      1,
      reqParams
    );
  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }
},

// --------------------------------------------------------------------------------------------- //

};


export default MyRidesCtrlr;
