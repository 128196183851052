import { apiConfig } from "../../config/Config.js";
import { apiEndPoint, apiType, apiStatus } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
// import { GlobalConst } from "./../../constant/GlobalConstant.js";
 
import UserSessionController from "../UserSession/UserSessionController.js";
import {
    SC_COMPANY_ID,
    SC_DIVISION_ID,
    SC_MODULE_ID,
    SC_LOGIN_USER_ID,
    SC_TIME_ZONE_ID,
    SC_LANGUAGE_ID,
    SC_GROUP_ID,
    SC_COUNTRY_ID,
} from "../../constant/StorageConstant.js";
 
// import { apiStatus, apiType, apiAction } from './../../constant/api_constant.js';
 
let userController = {
   
    async getUser(indexStart, filterValue, statusValue, subFilterText, pageSize, deviceFilter) {
        var subFilterStatusTemp = "";
        var statusTemp = statusValue;
        if(statusValue == "ACTIVE"){
            subFilterStatusTemp = "ACTIVE";
        } else if(statusValue == "INACTIVE"){
            subFilterStatusTemp = "INACTIVE";
            statusTemp = "ACTIVE";
        }
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var queryFields={filterType : filterValue, subFilterType: "WILD_CARD_SEARCH",subFilterText:subFilterText,subFilterStatus:subFilterStatusTemp,deviceType: deviceFilter}
 
        if (companyId.length > 0 && divisionId.length > 0) {
            var reqPayload = {
                companyId: companyId,
                divisionId: divisionId,
                type: "USER",
                sortBy: "createdAt",
                sortOrder: "DESC",
                offset: indexStart,
                limit: pageSize,
                fromTimeZone:"UTC",
                toTimeZone:"IST",
                status:statusTemp,
                queryType:"AD_DIVISION_USER_LIST" ,
                queryFields:JSON.stringify(queryFields)
            };
 
            // console.log("myuserspayload: " + JSON.stringify(reqPayload))
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.QUERY,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    async getUserExcelData(filterValue, statusValue, subFilterText, deviceFilter) {
        var subFilterStatusTemp = "";
        var statusTemp = statusValue;
        if(statusValue == "ACTIVE"){
            subFilterStatusTemp = "ACTIVE";
        } else if(statusValue == "INACTIVE"){
            subFilterStatusTemp = "INACTIVE";
            statusTemp = "ACTIVE";
        }
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
 
        var queryFields={filterType : filterValue, subFilterType: "WILD_CARD_SEARCH",subFilterStatus:subFilterStatusTemp,deviceType: deviceFilter,subFilterText: subFilterText}
        if (companyId.length > 0 && divisionId.length > 0) {
            var reqPayload = {
                companyId: companyId,
                divisionId: divisionId,
                type: "USER",
                sortBy: "createdAt",
                sortOrder: "DESC",
                offset: 0,
                limit: 500,
                fromTimeZone:"UTC",
                toTimeZone:"IST",
                status:statusTemp,
                queryType:"AD_DIVISION_USER_LIST" ,
                queryFields:JSON.stringify(queryFields)
            };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.QUERY,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    async createUserRegistration(phone, password,usernameType,deviceType,countryId,twoFA,dpass,addFirstName,vehicleType) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_LOGIN_USER_ID);
        var moduleId = UserSessionController.getLocalSessionValue(SC_MODULE_ID);
        if (
            companyId.length > 0 &&
            divisionId.length > 0 &&
            userId.length > 0 &&
            moduleId.length > 0
        ) {
            var reqPayload = {
                companyId: companyId,
                countryId:countryId,
                divisionId: divisionId,
                userId: userId,
                moduleId: moduleId,
                status: apiStatus.REGISTERED,
                type: apiType.USER,
                category: apiType.USER,
                userCollections: [{                  
                    category: "USER",
                    deviceType: deviceType,
                    firstName: addFirstName,  
                    mfaPrimaryTypeEnable: twoFA,  
                    password: password,      
                    username: phone,  
                    usernameType:usernameType,
                    defaultPassword: dpass,
                    vehicleType: vehicleType             
                }, ],
            };
            return await ApiService.callPOST(
                apiConfig.API_VERSION_1,
                apiEndPoint.USER_REGISTER,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
 
    async createBulkUserRegistration(usernameType, twoFa, formData) {
        try {
            const companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
            const divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
            const userId = UserSessionController.getLocalSessionValue(SC_LOGIN_USER_ID);
           
            if (companyId && divisionId && userId) {
                const reqPayload = {
                    companyId: companyId,
                    divisionId: divisionId,
                    userId: userId,
                    usernameType: usernameType,
                    type: apiType.USER,
                    mfaPrimaryTypeEnable: twoFa,
                };
                // console.log("setting file in controller", formData.get('file'));
                return await ApiService.encryptCallPOST(
                    apiConfig.API_VERSION_1,
                    apiEndPoint.USER_BULK_REGISTRATION,
                    1,
                    reqPayload,
                    formData
                );
            } else {
                return { status: "FAILED", error: "Required session values are missing", data: [] };
            }
        } catch (err) {
            console.error("Error in createBulkUserRegistration:", err);
            return { status: "FAILED", error: "An error occurred", data: [] };
        }
    },
 
    async getCountryList (){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        if (companyId.length > 0){
            var reqPayload = {
                companyId:companyId,
                type:"COUNTRY",
                sortBy:"createdAt",
                sortOrder:"ASC",
                offset:0,
                limit:100
            };
            return await ApiService.callGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.COUNTRY,
                0,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    async smsToUnregieredUsers(userType,username,secretCode) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
        if (
            companyId.length > 0 &&
            divisionId.length > 0 &&
            groupId.length > 0
        ) {
            var reqPayload = {
                companyId: companyId,
                divisionId: divisionId,
                groupId: groupId,
                type:"WELCOME_SMS_TO_UNREGISTERED_USER",
                username: username,
                secretCode: secretCode
            };
            return await ApiService.callGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.MANUAL_SMSNOTIFICATION,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

};
 
export default userController;