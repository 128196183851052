import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import UtilDateTime from "../../util/UtilDateTime.js";
import { trimValue } from "../../util/UtilFieldValidation.js";
import {
    SC_COMPANY_ID,
    SC_DIVISION_ID,
    SC_USER_ID,
    SC_GROUP_ID
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let UserDrivingScoreController = {
    async getUser(indexStart, indexEnd) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        if (companyId.length > 0 && indexStart.length > 0 && indexEnd.length > 0) {
            var reqPayload = {
                companyId: companyId,
                type: "USER",
                sortBy: "createdAt",
                sortOrder: "DESC",
                offset: indexStart,
                limit: "10",
            };
            return await ApiService.callGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.USER,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    async userSafetyCloudPoint (fValue,startDateTime,endDateTime){
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        var dashboardFields={filterType : fValue }
    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              userId:userId,
              deviceId:'',
              type: "SPEEDO_METER_DEVICE",
              sortBy: "createdAt",
              sortOrder: "DESC",
              offset: 0,
              limit: "1000",
              dashboardType: "SAD_GROUP_SINGLE_CLIENT_DASHBOARD_SUMMARY",
              dashboardFields:JSON.stringify(dashboardFields),
              startDateTime:startDateTime,
              endDateTime:endDateTime
          };
    
    
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }
    
      },
    async getDashboardRiskRideDataList(selectedRiskRideId) {
        selectedRiskRideId = trimValue(selectedRiskRideId);
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        if (selectedRiskRideId.length > 0) {
      

            var reqParams = {
                companyId: companyId,
                divisionId: divisionId, 
                userId: userId,
                deviceId: selectedRiskRideId,
                category:"DISTANCE_DATA",
                type: "SPEEDO_METER_DEVICE_DATA",
                sortBy: "createdAt",
                sortOrder: "ASC",
                offset: "0",
                limit: "10000",
                queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
            };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.QUERY,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    async getTripProfileRiskAlertDataList(selectedRiskRideId) {
        selectedRiskRideId = trimValue(selectedRiskRideId);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);

        if (selectedRiskRideId.length > 0) {
         

            var reqParams = {
                companyId: UserSessionController.getLocalSessionValue(SC_COMPANY_ID),
                userId: UserSessionController.getLocalSessionValue(SC_USER_ID),
                divisionId:divisionId,
                deviceId: selectedRiskRideId,
                category:"ALERT_DATA,STRESS_STRAIN_DATA,START_DATA,END_DATA",
                type: "SPEEDO_METER_DEVICE_DATA",
                sortBy: "createdAt",
                sortOrder: "ASC",
                offset: "0",
                limit: "1000",
                queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
            };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.QUERY,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async getDrivingBehaviourAlertCount(filterDay,startDateTime,endDateTime) {
        // eValue = trimValue(eValue);
        // yValue = trimValue(yValue);
        // mValue = trimValue(mValue);
        var dashboardFields={filterType : filterDay }

        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        if (companyId.length >0) {
            // var month1StartDateTime = "",
            //     month1EndDateTime = "";

            // month1StartDateTime = yValue + "-" + mValue + "-01 00:00:00";
            // month1EndDateTime = UtilDateTime.dateManipulation(
            //     "+",
            //     new Date(month1StartDateTime),
            //     1,
            //     0,
            //     0,
            //     0,
            //     0
            // );
            // month1EndDateTime = UtilDateTime.dateManipulation(
            //     "-",
            //     new Date(month1EndDateTime),
            //     0,
            //     0,
            //     0,
            //     0,
            //     1
            // );

            var reqParams = {
                companyId: companyId,
                divisionId:divisionId,
                userId: userId,
                type: "SPEEDO_METER_DEVICE",
                dashboardType: "USER_DASHBOARD_DRIVING_SCORE",
                // startDateTime: month1StartDateTime,
                // endDateTime: month1EndDateTime,
                // startDateTime1: "2023-01-01 00:00:00",
                // endDateTime1: "2023-12-31 23:59:59",
                dashboardFields:JSON.stringify(dashboardFields),
                startDateTime:startDateTime,
                endDateTime:endDateTime

            };


            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async getUsers(indexStart, indexEnd) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);

        if (
            companyId.length > 0 &&
            divisionId.length > 0 &&
            indexStart.length > 0 &&
            indexEnd.length > 0
        ) {
            var reqPayload = {
                companyId: companyId,
                divisionId: divisionId,
                type: "USER",
                category: "USER",
                sortBy: "createdAt",
                sortOrder: "DESC",
                offset: indexStart,
                limit: "10",
            };
            return await ApiService.callGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.USER,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async userDashboardController() {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                type: "SPEEDO_METER_DEVICE",
                sortBy: "createdAt",
                sortOrder: "ASC",
                offset: "0",
                limit: "100",
                dashboardType: "USER_DASHBOARD_TOP_LINE_ITEM",
            };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async userLeaderBoard(mode) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var queryFields = {
            deviceDataCategory: "END_DATA",
            //subFilterType: subFilterType,
            scoreValidationStatus : "SUCCESS",
            deviceMode:mode
          };
        if (companyId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                userId: userId,
                divisionId:divisionId,
                type: "SPEEDO_METER_DEVICE",
                sortBy: "createdAt",
                sortOrder: "DESC",
                offset: "0",
                limit: "5",
                queryType: "AD_SINGLE_USER_RIDE_LIST",
                queryFields: JSON.stringify(queryFields),

            };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.QUERY,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async userMonthRiskAlertsController() {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        var queryFields = {
          filterType: "CURRENT_MONTH",
        };
        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
          var reqParams = {
            companyId: companyId,
            divisionId: divisionId,
            userId: userId,
            type: "SPEEDO_METER_DEVICE",
            sortBy: "createdAt",
            sortOrder: "ASC",
            offset: "0",
            limit: "100",
            dashboardType: "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
            dashboardFields: JSON.stringify(queryFields),
          };
          return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.DASHBOARD,
            1,
            reqParams
          );
        } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
      },

    async riskAlertController() {
        
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                type: "SPEEDO_METER_DEVICE",
                sortBy: "createdAt",
                sortOrder: "ASC",
                offset: 0,
                limit: "100",
                dashboardType: "USER_DASHBOARD_RISK_ALERT" 
            };


            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async totalValuesController(filter,startDateTime,endDateTime) {
        
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        var dashboardFields = { filterType: filter };

        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                type: "SPEEDO_METER_DEVICE_DATA",
                sortBy: "createdAt",
                sortOrder: "ASC",
                offset: 0,
                limit: "100",
                dashboardType: "USER_DASHBOARD_TOP_LINE_ITEM_LIST",
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                dashboardFields: JSON.stringify(dashboardFields),
            };
            // console.log("totalValuesController: ",JSON.stringify(reqParams))

            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async userSafetyDetailController(filter,startDateTime,endDateTime) {
        var dashboardFields={filterType : filter}
    
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        if (companyId.length > 0) {
          var reqParams = {
            companyId: companyId,
            divisionId:divisionId,
            groupId:groupId,
            userId:userId,
            type: "SPEEDO_METER_DEVICE",
            dashboardType:"SAD_GROUP_MULTI_CLIENT_DASHBOARD_SUMMARY",
            sortBy: "createdAt",
            sortOrder: "ASC",
            offset: "0",
            limit: "1000",
            // category:"TESTING",
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            dashboardFields:JSON.stringify(dashboardFields)
          };
    
          return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.DASHBOARD,
            1,
            reqParams
          );
          
        } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
        
      },

      async safetyCategoryController(filter,startDateTime,endDateTime) {
        var dashboardFields={filterType : filter}
    
        var companyId = localStorage.getItem(SC_COMPANY_ID);
        var divisionId = localStorage.getItem(SC_DIVISION_ID);
        var groupId = localStorage.getItem(SC_GROUP_ID);
        var userId = localStorage.getItem(SC_USER_ID);

        if (companyId.length > 0) {
          var reqParams = {
            companyId: companyId,
            divisionId:divisionId,
            groupId:groupId,
            userId:userId,
            type: "SPEEDO_METER_DEVICE",
            dashboardType:"SAD_GROUP_MULTI_CLIENT_DASHBOARD_SUMMARY",
            sortBy: "createdAt",
            sortOrder: "ASC",
            offset: "0",
            limit: "1000",
            // category:"TESTING",
            dashboardFields:JSON.stringify(dashboardFields),
            startDateTime:startDateTime,
            endDateTime:endDateTime
          };
    
          return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.DASHBOARD,
            1,
            reqParams
          );
          
        } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
        
      },

      async riskCountController(filter,startDateTime,endDateTime) {
        var dashboardFields={filterType : filter}

        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                // deviceId: rideId,
                type: "SPEEDO_METER_DEVICE",
                status: "ALL",
                sortBy: "createdAt",
                sortOrder: "ASC",
                offset: 0,
                limit: "100",
                dashboardType: "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
                dashboardFields: JSON.stringify(dashboardFields),
                startDateTime: startDateTime,
                endDateTime: endDateTime,
            };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    //Week Wise BEHAVIOUR START

    async week1BehaviourController(filter) {
        var dashboardFields={filterType : "CUSTOM_DURATION"}

        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                category:"ALERT_DATA,STRESS_STRAIN_DATA,MANUAL_DATA",
                type:"SPEEDO_METER_DEVICE_DATA",
                startDateTime:filter.startDate,
                endDateTime:filter.endDate,
                dashboardType : "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
                dashboardFields : JSON.stringify(dashboardFields),
                };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    async week2BehaviourController(filter) {
        var dashboardFields={filterType : "CUSTOM_DURATION"}

        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        const startDate = "2023-08-06 00:00:00"
        const endDate = "2023-08-12 23:59:59"
        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                category:"ALERT_DATA,STRESS_STRAIN_DATA,MANUAL_DATA",
                type:"SPEEDO_METER_DEVICE_DATA",
                startDateTime:filter.startDate,
                endDateTime:filter.endDate,
                dashboardType : "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
                dashboardFields : JSON.stringify(dashboardFields),
                };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    async week3BehaviourController(filter) {
        var dashboardFields={filterType : "CUSTOM_DURATION"}

        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                category:"ALERT_DATA,STRESS_STRAIN_DATA,MANUAL_DATA",
                type:"SPEEDO_METER_DEVICE_DATA",
                startDateTime:filter.startDate,
                endDateTime:filter.endDate,
                dashboardType : "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
                dashboardFields : JSON.stringify(dashboardFields),
                };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    async week4BehaviourController(filter) {
        var dashboardFields={filterType : "CUSTOM_DURATION"}

        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                category:"ALERT_DATA,STRESS_STRAIN_DATA,MANUAL_DATA",
                type:"SPEEDO_METER_DEVICE_DATA",
                startDateTime:filter.startDate,
                endDateTime:filter.endDate,
                dashboardType : "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
                dashboardFields : JSON.stringify(dashboardFields),
                };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async week5BehaviourController(filter) {
        var dashboardFields={filterType : "CUSTOM_DURATION"}

        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                category:"ALERT_DATA,STRESS_STRAIN_DATA,MANUAL_DATA",
                type:"SPEEDO_METER_DEVICE_DATA",
                startDateTime:filter.startDate,
                endDateTime:filter.endDate,
                dashboardType : "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
                dashboardFields : JSON.stringify(dashboardFields),
                };
            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.DASHBOARD,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
    //Week Wise BEHAVIOUR END

    async stressStrainController() {
    
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);


        if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
            var reqParams = {
                companyId: companyId,
                divisionId: divisionId,
                userId: userId,
                type: "SPEEDO_METER_DEVICE",
                category:"STRESS_STRAIN_DATA",
                status: "ALL",
                sortBy: "createdAt",
                sortOrder: "ASC",
                offset: 0,
                limit: "100",
                queryType: "UD_SINGLE_USER_RIDE_DATA_SUB_CATEGORY_LEVEL_COUNT",
            };


            return await ApiService.encryptCallGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.QUERY,
                1,
                reqParams
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },

    async userRideCompleteSummary(indexStart) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
        var queryFields = {scoreValidationStatus: 'SUCCESS'};
        if (companyId.length > 0 && userId.length > 0) {
          var reqPayload = {
            companyId: companyId,
            divisionId: divisionId,
            userId: userId,
            type: "SPEEDO_METER_DEVICE_DATA",
            category:"STRESS_STRAIN_DATA,MANUAL_DATA,ALERT_DATA",
            sortBy: "createdAt",
            sortOrder: "DESC",
            offset: indexStart,
            limit: "1000",
            queryType: "UD_SINGLE_USER_ALL_RIDE_DATA_LIST",
            queryFields:JSON.stringify(queryFields),
          };
          return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.QUERY,
            1,
            reqPayload
          );
        } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
      },

      async stressStrainSummary(indexStart, rideId, divisionId,userId, rideCategory) {

        var queryFields = {
            deviceDataCategory: "END_DATA",
            //subFilterType: subFilterType,
            scoreValidationStatus : "SUCCESS",
            travelType: rideCategory
          };        
          
          var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);

        if (companyId.length > 0 && userId.length > 0) {
          var reqPayload = {
            companyId: companyId,
            divisionId: divisionId,
            userId: userId,
            deviceId: rideId,
            type: "SPEEDO_METER_DEVICE_DATA",
            category:"STRESS_STRAIN_DATA,MANUAL_DATA,ALERT_DATA",
            sortBy: "createdAt",
            sortOrder: "DESC",
            offset: indexStart,
            limit: "1000",
            queryType: "UD_SINGLE_USER_ALL_RIDE_DATA_LIST",
            queryFields: JSON.stringify(queryFields),
          };
          return await ApiService.encryptCallGET(
            apiConfig.API_VERSION_1,
            apiEndPoint.QUERY,
            1,
            reqPayload
          );
        } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
      },
      async overallUserSummary (indexStart,startDateTime,endDateTime){
        var dashboardFields={filterType : "CUSTOM_DURATION"}
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

    
        if (companyId.length > 0) {
    
          var reqPayload = {
              companyId: companyId,
              divisionId:divisionId,
              userId:userId,
              type: "SPEEDO_METER_DEVICE",
              sortBy: "createdAt",
              sortOrder: "ASC",
              offset: indexStart,
              limit: 1000,
              dashboardType: "UD_SINGLE_CLIENT_SINGLE_USER_OVERALL_RIDE_SUMMARY",
              startDateTime:startDateTime,
              endDateTime:endDateTime,
              dashboardFields : JSON.stringify(dashboardFields),
          };
          return await ApiService.encryptCallGET(
              apiConfig.API_VERSION_1,
              apiEndPoint.DASHBOARD,
              1,
              reqPayload
          );
      } else {
          return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
      }
    
      },

       // ---------------------------------- User Dashbord Ride Alerts api -------------------------------------------------- //

   async userDashbordRideAlertController(filter) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var dashboardFields = {durationType : filter};
    // console.log("divisionId: ",divisionId + " " +"userId: ",userId);
    if (companyId.length > 0) {
      var reqParams = {
        companyId: companyId,
        groupId: groupId,
        divisionId: divisionId,
        // deviceId: rideId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        // status: "ALL",
        // sortBy: "createdAt",
        // sortOrder: "ASC",
        // offset: 0,
        // limit: "100",
        dashboardType: "V1_RIDE_DATA_SUMMARY",
        dashboardFields: JSON.stringify(dashboardFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

 
  // --------------------------------------------------------------------------------------------- //

};

export default UserDrivingScoreController;