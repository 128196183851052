

const crashViewListTopRow = [
    
    {
        type : "RIDE",
        title: "Ride Number",
        overallData: 0, 
        overallDataPostFix: "", 
        currentYearValue: "", 
        postFix:"",
        statusColor: "success",
        bottomValue:""

    },
    {
        type : "SCORE",
        title: "Driving Score",
        overallData: 0,    
        tooltip:"-",     
        overallDataPostFix: "", 
        currentYearValue: "", 
        postFix: "",
        statusColor: "success",
        bottomValue:""
    },
    {
        type : "SKILL",
        title: "Driving Skill",
        overallData: 0, 
        overallDataPostFix: "", 
        currentYearValue:"", 
        postFix: "",
        statusColor: "success",
        bottomValue:""

    },
    {
        type : "ANTICIPATION",
        title: "Anticipation",
        overallData: 0, 
        overallDataPostFix: "", 
        currentYearValue: "", 
        isDoller: false,
        postFix: "",
        statusColor: "success",
        bottomValue:""

    },
    {
        type : "SELF CONFIDENCE",
        title: "Self Confidence",
        overallData: 0, 
        overallDataPostFix: "", 
        currentYearValue: "", 
        isDoller: false,
        postFix: "",
        statusColor: "success",
        bottomValue:"",
        tooltip:""

    },
    {
        type : "CATEGORY",
        title: "Driver Category",
        overallData: "Driver Category", 
        overallDataPostFix: "", 
        currentYearValue: "", 
        isDoller: false,
        postFix: "",
        statusColor: "success",
        bottomValue:""
    },
]




export {
    crashViewListTopRow
};