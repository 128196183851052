import React, { useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { SC_USER_LOGIN_TYPE } from "../../constant/StorageConstant";
import ValueFormat from "../../util/ValueFormat";
import MySubscriptionTableData from "./MySubscriptionTableData";
import MobileLicenseOrder from "./MobileLicenseOrder";
import Loader from "../../components/Common/loader";
import MyRidesController from "../../controller/Rides/MyRides";
import AdminDashboardCtrlr from "../../controller/AdminDashboardCtrlr";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: 400,
    borderRadius: 10,
    transform: "translate(-50%, -50%)",
  },
};

const MySubscription = () => {
  const [userType, setUserType] = useState(
    localStorage.getItem(SC_USER_LOGIN_TYPE)
  );
  function afterOpenModal() {
    
    subtitle.style.color = "#f00";
    setMobileNumber("");
    setPassword("");
    setMobileValid(true);
    setPasswordValid(true);
  }
  function closeModal() {    
    openConfirmationModal();
  }

  const [indexStart, setIndexStart] = useState(0);
  const pageSize = 10;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showLoader, setLoader] = useState(true);
  const [data, setData] = useState(MySubscriptionTableData);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [subScriptionDataList, setSubcriptionDataList] = useState({
    subscriptionName: "",
    totalCount: 0,
    androidCount: 0,
    iosCount: 0,
    androidCountSDK: 0,
    iosCountSDK: 0,
    startDate: "",
    endDate: "",
    billing: "1 Year",
    orderCount: 0,
  });

  const subscriptionData = async () => {
    setLoader(true);
    var updatedTableData = [];

    var response = await AdminDashboardCtrlr.MySubscriptionAdmin();
    
    try {
      var responseJSON = JSON.parse(response);
      console.log("responseJSON", responseJSON)
      if (responseJSON.status == "SUCCESS") {
        setLoader(true);

        var responseDATA = responseJSON.data;
        if (responseDATA.length > 0) {
          setLoader(true);

          for (let i = 0; i < responseDATA.length; i++) {
            const getData = responseDATA[i];
            
            const subscriptionName = ValueFormat.parseAsString(
              getData.subscriptionName
            );
            const availableLicenseCount = ValueFormat.parseAsString(
              getData.availableLicenseCount
            );
            const startDateTimeToTimeZone = ValueFormat.parseAsString(
              getData.startDateTimeToTimeZone
            );
            const endDateTimeToTimeZone = ValueFormat.parseAsString(
              getData.endDateTimeToTimeZone
            );
            const androidCount = ValueFormat.parseAsString(
              getData.androidLicenseCount
            );
            const androidCountSDK = ValueFormat.parseAsString(
              getData.androidSdkLicenseCount
            );
            const iosCountSDK = ValueFormat.parseAsString(
              getData.iosSdkLicenseCount

            );
            const iosCount = ValueFormat.parseAsString(getData.iosLicenseCount);
            const orderCount = ValueFormat.parseAsString(getData.orderCount);
            setSubcriptionDataList((prev) => {
              return {
                ...prev,
                subscriptionName: subscriptionName,
                orderCount: orderCount,
                totalCount: availableLicenseCount,
                startDate: startDateTimeToTimeZone,
                endDate: endDateTimeToTimeZone,
                androidCount: androidCount,
                iosCount: iosCount,
                androidCountSDK: androidCountSDK,
                iosCountSDK: iosCountSDK,
              };
            });
            const orderHistoryList = ValueFormat.parseAsArray(
              getData.orderList
            );
            console.log(orderHistoryList,"orderHistoryList")
            const subscriptionId = ValueFormat.parseAsString(
              getData.subscriptionId
            );
            setSubscriptionId(subscriptionId);
            for (let r = 0; r < orderHistoryList.length; r++) {
              const getOrderData = orderHistoryList[r];
              const androidLicenseCount = ValueFormat.parseAsInt(
                getOrderData.androidLicenseCount
              );
              const iosLicenseCount = ValueFormat.parseAsInt(
                getOrderData.iosLicenseCount
              );
              const androidLicenseCountSDK = ValueFormat.parseAsInt(
                getOrderData.androidSdkLicenseCount
              );
              const iosLicenseCountSDK = ValueFormat.parseAsInt(
                getOrderData.iosSdkLicenseCount
              );
              const orderName = ValueFormat.parseAsString(getOrderData.name);
              const paymentMode = ValueFormat.capitalizeFirstLetterAndFormat(
                ValueFormat.parseAsString(getOrderData.paymentMode)
              );
              const id = ValueFormat.parseAsString(getOrderData.id);
              const subscriptionId = ValueFormat.parseAsString(
                getOrderData.subscriptionId
              );
              const totalLicenseCount = ValueFormat.parseAsInt(
                getOrderData.totalLicenseCount
              );
              const date = ValueFormat.parseAsString(
                getOrderData.createdAtToTimeZone
              );
              var userDetails = {
                id: id,
                date: date,
                orderName: orderName,
                androidLicenseCount: androidLicenseCount,
                iosLicenseCount: iosLicenseCount,
                paymentMode: paymentMode,
                subscriptionId: subscriptionId,
                totalLicenseCount: totalLicenseCount,
                iosLicenseCountSDK: iosLicenseCountSDK,
                androidLicenseCountSDK: androidLicenseCountSDK
              };
              updatedTableData.push(userDetails);
            }
            setLoader(false);
          }
          setLoader(false);

          setData(updatedTableData);
        } else {
          setLoader(false);
          
        }
      } else {
        setLoader(false);

        console.error("Failed to fetch data");
      }
    } catch (e) {
      setLoader(false);

      console.error("Catch Error : ", e);
    }
  };
  useEffect(() => {
    subscriptionData();
  }, []);


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Row className="my-2 py-2">
          <Col xs={12} className="d-flex justify-content-start align-items-center  text-black">
            <h5 className="mb-0">User Licences</h5>
          </Col>
        </Row>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={false}
          style={customStyles}
          overlayClassName="custom-modal-overlay"
          contentLabel="Example Modal"
        >
          <MobileLicenseOrder
            setIsOpen={setIsOpen}
            subscriptionId={subscriptionId}
            subscriptionData={subscriptionData}
          />
        </Modal>
        <Card>
          <CardHeader className="cardHeader">
            <h2 className="card-title">My Subscription (Currently Active)</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Form>
                <FormGroup>
                  <div>
                    <div className="mb-3">
                      <div className="col-md-12 col-sm-12">
                        <Label for="">Subscription Name:</Label>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <Input
                          id=""
                          value={subScriptionDataList.subscriptionName}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="d-md-flex justify-content-between">
                      <div className="col-md-6 col-sm-12 mb-3 pe-md-1">
                        <div>
                          <Label for="">No Of Orders:</Label>
                        </div>
                        <div>
                          <Input
                            id=""
                            value={subScriptionDataList.orderCount}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 mb-3 ps-md-1">
                        <div>
                          <Label for="">Subscription Activated On:</Label>
                        </div>
                        <div>
                          <Input
                            id=""
                            value={subScriptionDataList.startDate}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </FormGroup>
              </Form>

              <div className="col-md-12 col-sm-12">
                <h6>Current Availability:</h6>
              </div>
              <div>
                <div className="col-md-12 col-sm-12 table-responsive">
                  <Table bordered>
                    <thead className="cardHeader">
                      <tr>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Total Count</th>

                        <th style={{ fontSize: "13px", fontWeight: 600 }}>iOS Count</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Android Count</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>iOS SDK Count</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Android SDK Count</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Expiry Date</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Billing Interval</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ fontSize: "13px" }}>
                        <td>{subScriptionDataList.totalCount}</td>
                        <td>{subScriptionDataList.iosCount}</td>
                        <td>{subScriptionDataList.androidCount}</td>
                        <td>{subScriptionDataList.iosCountSDK}</td>
                        <td>{subScriptionDataList.androidCountSDK}</td>
                        <td>{subScriptionDataList.endDate}</td>
                        <td>{subScriptionDataList.billing}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

              {userType == "SUPER_ADMIN" && (
                <div className="col-md-12 col-sm-12 d-flex justify-content-end" style={{ paddingTop: '10px' }}>
                  <div onClick={() => setIsOpen(true)} role="button">
                    <button className="btn btn-primary">Add Order</button>
                  </div>
                </div>
              )}

              <div className="col-md-12 col-sm-12">
                <h6>Order List:</h6>
              </div>
              <div>
                <div className="col-md-12 col-sm-12 table-responsive">
                  <Table bordered>
                    <thead className="cardHeader">
                      <tr>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>S.No</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Order Name</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>iOS Count</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Android Count</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>iOS SDK Count</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Android SDK Count</th>
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Total Count</th>
                        {/* <th style={{ fontSize: "13px", fontWeight: 600 }}>Payment Mode</th> */}
                        <th style={{ fontSize: "13px", fontWeight: 600 }}>Order Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((data, index) => (
                        <tr key={index} style={{ fontSize: "13px" }}>
                          <td>{index + 1}</td>
                          <td>{data.orderName}</td>
                          <td>{data.iosLicenseCount}</td>
                          <td>{data.androidLicenseCount}</td>
                          <td>{data.iosLicenseCountSDK}</td>
                          <td>{data.androidLicenseCountSDK}</td>
                          <td>{data.totalLicenseCount}</td>
                          {/* <td>{data.paymentMode}</td> */}
                          <td>{data.date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default MySubscription;
