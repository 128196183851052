import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Tooltip as ReactTooltip } from "react-tooltip";
import React, { useEffect, useState } from "react";
import { CgCloseO } from "react-icons/cg";
import { Card, CardBody, CardHeader, Modal } from "reactstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ToastService from "../../components/Common/toastService";
import { TextField } from "@mui/material";
import "./DateTimePopup.css"

// Tooltip custom styles
const customStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  height: "300px",
  overflow: "auto",
  overflowX: "auto",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  "@media (min-width: 720px) ": {
    width: 800,
  },
};

const DateTimePickerPopup = ({
  modalOpen,
  modalClose,
  setStartDateTime,
  setEndDateTime,
  setFilter,
  filter
}) => {
  // get the today date and time value
  var nowYear = dayjs().year();
  var nowMonth = dayjs().month();
  var today = dayjs();
  var formattedDay = today.format("DD");
  var nowHours = today.format("HH");
  var nowMinutes = dayjs().minute();

  // Date and Time From
  const [selectedYear, setSelectedYear] = useState(nowYear);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectedHours, setSelectedHours] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [selectedSeconds, setSelectedSeconds] = useState(0);

  // Date and Time to
  const [toSelectedYear, setToSelectedYear] = useState(nowYear);
  const [toSelectedMonth, setToSelectedMonth] = useState(nowMonth);
  const [toSelectedDay, setToSelectedDay] = useState(formattedDay);
  const [toSelectedHours, setToSelectedHours] = useState(23);
  const [toSelectedMinute, setToSelectedMinute] = useState(59);
  const [toSelectedSeconds, setToSelectedSeconds] = useState(59);

  const [startError, setStartError] = useState(null);
  const [endError, setEndError] = useState(null);

  // Disable dates after the current date
  const currentDate = dayjs();


  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const [isEndDateSelected, setIsEndDateSelected] = useState(false);

  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);

  useEffect(() => {
    if (modalOpen && filter !== "CUSTOM_DURATION") {
      setStartValue(null);
      setEndValue(null);
      setStartError(null);
      setEndError(null);
    }
  }, [modalOpen, filter]);

  const handleDateChangeFrom = (date) => {
    setStartValue(date)
    // ... (your existing code)
    const year = dayjs(date).year();
    const month = dayjs(date).month();
    const day = dayjs(date).date();
    // const hour = dayjs(date).hour();
    // const minute = dayjs(date).minute();

    setSelectedYear(year);
    setSelectedMonth(month);
    setSelectedDay(day);
    setSelectedHours(0);
    setSelectedMinute(0);
    setSelectedSeconds(0);
    setSelectedHours(0);
    setSelectedMinute(0);
    setSelectedSeconds(0);

    // Set the flag to true when the start date is selected
    setIsStartDateSelected(true);
    setStartError(null);
  };
  // const handleInputClick = () => {
  //   // Reset the startValue to null when the input is clicked
  //   setStartValue(null);
  // };

  useEffect(() => {
    initialDate();
  }, []);

  const initialDate = () => {
    setSelectedYear(nowYear);
    setSelectedMonth(0);
    setSelectedDay(1);
    setSelectedHours(0);
    setSelectedMinute(0);
    setSelectedSeconds(0);

    setToSelectedYear(nowYear);
    setToSelectedMonth(nowMonth);
    setToSelectedDay(formattedDay);
    setToSelectedHours(23);
    setToSelectedMinute(59);
    setToSelectedSeconds(59);
  };

  const clearButton = () => {
    // setStartValue(null)
    // setEndValue(null)
    // setStartError(null);
    // setEndError(null);
    resetState();
  };

  // To date handle change function
  const handleDateChangeTo = (date) => {
    setEndValue(date)
    const year = dayjs(date).year();
    const month = dayjs(date).month();
    const day = dayjs(date).date();
    // const hour = dayjs(date).hour();
    // const minute = dayjs(date).minute();

    setToSelectedYear(year);
    setToSelectedMonth(month);
    setToSelectedDay(day);
    setToSelectedHours(23);
    setToSelectedMinute(59);
    setToSelectedSeconds(59);
    setIsEndDateSelected(true);
    setEndError(null);
  };

  // From date default value
  var fromSelectedDate = dayjs()
    .year(selectedYear)
    .month(selectedMonth)
    .date(selectedDay)
    .hour(selectedHours)
    .minute(selectedMinute)
    .second(selectedSeconds);

  // To date default value
  var toSelectedDate = dayjs()
    .year(toSelectedYear)
    .month(toSelectedMonth)
    .date(toSelectedDay)
    .hour(toSelectedHours)
    .minute(toSelectedMinute)
    .second(toSelectedSeconds);

  // State to store previous selected dates
  const [prevSelectedDates, setPrevSelectedDates] = useState({
    from: null,
    to: null,
  });

  const handleSubmission = () => {
    // Validation for start date
    if (startValue === null || startValue === " ") {
      setStartError("Please choose a Start date");
    }

    // Validation for end date
    else if (endValue === null || endValue === " " ) {
      setEndError("Please choose an End date");
    } else if(startValue > endValue) {
      setEndError("");
    } else {

    // Check if any validation error exists
    if (startError || endError) {
      return;
    }

    const fromDate = dayjs(fromSelectedDate).format("YYYY-MM-DD HH:mm:ss");
    const toDate = dayjs(toSelectedDate).format("YYYY-MM-DD HH:mm:ss");

    // if (
    //   prevSelectedDates.from === fromDate &&
    //   prevSelectedDates.to === toDate && filter === "CUSTOM_DURATION"
    // ) {
    //   ToastService.errormsg(
    //     "You have selected the same date. Please choose different dates."
    //   );
    //   console.log("Please select the date")
    //   return;
    // }
    setPrevSelectedDates({
      from: fromDate,
      to: toDate,
    });


    if(isStartDateSelected == true &&  isEndDateSelected == true && startValue !== null && endValue !== null){
      setFilter("CUSTOM_DURATION");
      setStartDateTime(fromDate);
      setEndDateTime(toDate);
      closeModal();
    }
   }
  };

  const handleInputClick = () => {
    // Reset the startValue to null when the input is clicked
    setStartValue(null);
  };

  const resetState = () => {
    setSelectedYear(nowYear);
    setSelectedMonth(0);
    setSelectedDay(1);
    setSelectedHours(0);
    setSelectedMinute(0);
    setSelectedSeconds(0);

    setToSelectedYear(nowYear);
    setToSelectedMonth(nowMonth);
    setToSelectedDay(formattedDay);
    setToSelectedHours(23);
    setToSelectedMinute(59);
    setToSelectedSeconds(59);

    setStartValue(null); // or setStartValue('')
    setEndValue(null); // or setEndValue('')
    setIsStartDateSelected(false);
    setPrevSelectedDates({
      from: null,
      to: null,
    });
    setStartError(null);
    setEndError(null);
  };
  // Popup model close function
  const closeModal = () => {
    // resetState();
   
    modalClose(!modalOpen);
  }

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      overlayClassName="custom-modal-overlay"
      contentLabel="Example Modal"
      style={customStyles}
    >
      <Card >
        <CardHeader style={{height:"50px"}} className="cardHeader">
          <div style={{ display: "flex", justifyContent: "space-between", marginTop:"-5px"}}>
            <h4
              style={{
                color: "black",
                fontSize: "18px",
                fontFamily: "sans-serif",
                fontWeight: 600,
              }}
            >
              Custom Date Section
            </h4>
            <div style={{cursor:"pointer"}}>
              <CgCloseO
                size="20px"
                onClick={closeModal}
                data-tooltip-id="closebu"
              />
              <ReactTooltip
                id="closebu"
                place="top"
                content="Close"
                style={{ background: "black" }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="mb-0 mt-0" style={{marginTop:"-5px",}}>
            <div style={{ display: "flex", justifyContent: "center",  }}>
              <label style={{ marginTop: "23px",fontSize:'16px' }}>Start Date<span style={{ color: 'red' }}> * </span>:</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{ px: 1 }} components={["MobileDatePicker"]}>
                  <DemoItem>
                    <MobileDatePicker
                    
                    className="custom-mobile-date-picker"
                      value={startValue}
                      onChange={handleDateChangeFrom}
                      format="YYYY-MM-DD"
                      ampm={false}
                      minutesStep={1}
                      maxDate={currentDate}
                      label="Select Start Date"
                      background={startError ? "red" : ""}
                      minDate={dayjs("2021-01-01")}
                      
                      onClick={handleInputClick}
                    />
                     {startError && (
                  <div style={{ color: "red", fontSize: "12px",marginTop:"1px"  }}>
                    {startError}
                  </div>
                )}
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <label style={{ marginTop: "24px", fontSize:'16px' }}>
                End Date<span style={{ color: 'red' }}> *&nbsp;&nbsp; </span>:
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer sx={{ px: 1 }} components={["MobileDatePicker"]}>
                  <DemoItem >
                    <MobileDatePicker
                    className="custom-mobile-date-picker"
                    style={{fontSize:"12px"}}
                      value={endValue}
                      onChange={handleDateChangeTo}
                      format="YYYY-MM-DD"
                      ampm={false}
                      minutesStep={1}
                      label="Select End Date"
                      minDate={isStartDateSelected ? fromSelectedDate.add(0, 'day') : currentDate}
                      maxDate={currentDate}
                      readOnly={!isStartDateSelected}
                    />
                     {endError && (
                  <div style={{ color: "red", fontSize: "12px",marginTop:"1px" }}>
                    {endError}
                  </div>
                )}
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div style={{ textAlign: "center", }} className="mb-0 mt-3 ">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmission}
              >
                Submit
              </button>{" "}
              <button
                type="button"
                className="btn btn-primary "
                onClick={clearButton}
                style={{marginLeft:"5px"}}
              >
                Clear
              </button>{" "}
            </div>
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default DateTimePickerPopup;
