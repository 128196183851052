import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Tooltip as ReactTooltip } from "react-tooltip";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  CardBody, Col, Container, Form, FormFeedback, Input, Row, Button, ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
} from "reactstrap";
import { Card, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "bootstrap/dist/css/bootstrap.min.css";
import { CSVLink } from "react-csv";
import { Link, useLocation, useNavigate } from "react-router-dom";
import userController from "../../controller/User/UserController.js";
import ValueFormat from "../../util/ValueFormat";
import {
  SC_USER_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_DASHBOARD_TYPE,
  SC_USER_FIRST_NAME,
} from "../../constant/StorageConstant";
import { CgCloseO } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { myUserSearch } from "../../store/actions";
import MyRidesController from "../../controller/Rides/MyRides.js";
import { excelParser } from "../../components/Parser/ExportJson.js";
import Loader from "../../components/Common/loader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../components/Common/toastService";
import { PiDownloadSimple } from "react-icons/pi";
import { displayText } from "../../constant/MessageConstant";
import ClearIcon from '@mui/icons-material/Clear';
import Converter from "../../components/Common/EncodeDecode.js";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import AddIcon from "../../../src/assets/images/userAdd.svg";
import ConformationPopup from "../../components/Common/ConfirmationPopup.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: 400,
    borderRadius: 10,
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
};

function TriggerMessageForUnregisteredUsers() {
  const history = useNavigate();
  const [searchFilter, setSearchFilter] = useState("");
  const [searchFilterSub, setSearchFilterSub] = useState("")
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;
  const [data, setData] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [indexStart, setIndexStart] = useState(0);
  const [filterDays, setFilterDays] = useState("");

  const [filterDataList, setFilterDataList] = useState("INITIATED");
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [mobileValid, setMobileValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [prevShow, setPrevShow] = useState();
  const [nextShow, setNextShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dataLength, setDataLength] = useState(null);
  const [userNameType, setuserNameType] = useState("MOBILE_NUMBER");
  const [deviceType, setDeviceType] = useState("");

  const [isSubmitting, setIsSubmitting] = useState({ mobile: false, password: false });
  const location = useLocation();
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const acceptNumbers = /^[0-9]*$/;
  const acceptAlphanumeric = /^[a-zA-Z0-9]*$/;
  const acceptNumberMessage = "Only numbers are allowed";
  const acceptAlphanumericMessage = "Only alphanumeric characters are allowed"
  const [passwordShow, setPasswordShow] = useState(false);

  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    userType: 'SINGLE_USER',
    username: '',
    secretCode: '',
  });

  const toggleModal = () => setModal(!modal);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCancel = () => {
    setFormData({
      userType: 'SINGLE_USER',
      username: '',
      secretCode: '',
    });
    toggleModal();
  };

  const smsToUnregieredUsersPost = async () => {
    setShowLoader((current) => current + 1);
    var result = await userController.smsToUnregieredUsers(formData.userType, formData.username, formData.secretCode);
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status === "SUCCESS") {
        ToastService.successmsg(resultJSON.data.message);
        handleCancel();
      } else {
        ToastService.errormsg(resultJSON.error.message);
      }
    } catch (errMess) {
      console.log("Exception: ", errMess);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  const validation = useFormik({

    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      addUsername: '',
      addUserPassword: '',
    },

    validationSchema: Yup.object({
      addUsername: Yup.string().min(10, 'Minimum 10 digits or characters required !')
        .max(userNameType === "MOBILE_NUMBER" ? 10 : 20, `Maximum ${userNameType === "MOBILE_NUMBER" ? 10 : 20} Charecters Only`)
        .matches(userNameType === "MOBILE_NUMBER" ? acceptNumbers : acceptAlphanumeric, userNameType === "MOBILE_NUMBER" ? acceptNumberMessage : acceptAlphanumericMessage)
        .required('This field is required'),
      addUserPassword: Yup.string().matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{5,}/, 'Invalid Password characters')
        .required("Please Enter Your Password"),
    }),
    onSubmit: async (data) => {
      setIsSubmitting(prev => { return { ...prev, mobile: true, password: true } })

      setShowLoader((current) => current + 1);
      var encryptPassword = Converter.convertMd5(data.addUserPassword);
      var result = await userController.createUserRegistration(data.addUsername, encryptPassword, userNameType, deviceType);
      try {
        var resultJSON = JSON.parse(result);
        var status = resultJSON.status;
        var resultJSONData = resultJSON.data;
        if (status == "SUCCESS") {
          // var resultJSONData = resultJSON.data;
          ToastService.successmsg("User Registered Successfully!");
          getUserListData();
          setTimeout(() => {
            setIsOpen(false);
          }, 3500);
        } else if (resultJSON.status == "FAILED") {
          ToastService.errormsg(resultJSON.error.message);
          setMobileNumber("");
          setPassword("");
        }
        setShowLoader((current) => current - 1);

      } catch (errMess) {
        // console.log("Exception: ", errMess);
      }

      // dispatch(loginUser(values, props.router.navigate));
    }
  });

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  function openModal() {
    setTimeout(() => {
      validation.setFieldValue('addUsername', '');
      validation.setFieldValue('addPassword', '');
      validation.handleReset()
    }, 800)


    setIsOpen(true);
    setMobileNumber("");
    setPassword("");
  }

  function NumberChange(e) {
    // console.log(e.target.value);
    const value = e.target.value.replace(/\D/g, "");
    setMobileNumber(value);
    setMobileValid(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
    setMobileNumber("");
    setPassword("");
    setMobileValid(true);
    setPasswordValid(true);
  }
  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
    openPopup("Cancel");
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };
  const handleConfirmClose = () => {
    // Your existing closeModal logic here
    setIsSubmitting(prev => ({ ...prev, mobile: false, password: false }));
    validation.handleReset();
    validation.setFieldValue('addUsername', '');
    validation.setFieldValue('addPassword', '');
    setIsOpen(false);
    setMobileValid(true);
    setPasswordValid(true);

    // Close the confirmation modal
    closeConfirmationModal();
  };
  function closeModal() {
    // Open the confirmation modal
    openConfirmationModal();
  }

  const getUserListData = async () => {
    setShowLoader(true);
    try {
      var result = await userController.getUser(
        indexStart,
        filterDays,
        filterDataList,
        searchFilter,
        pageSize
      );
      var userList = [];
      var resultJSON = JSON.parse(result);
      var resultJSONData = resultJSON.data;
      // console.log("getUserList :" + JSON.stringify(resultJSONData));
      setDataLength(resultJSONData.length);

      for (let i = 0; i < resultJSONData.length; i++) {
        var userStatus = resultJSONData[i];
        // console.log("userStatus", userStatus);
        var userId = ValueFormat.parseAsString(userStatus.userId);
        var firstName = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetter(userStatus.firstName)
        );
        var lastName = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetter(userStatus.lastName)
        );
        var email = ValueFormat.parseAsString(
          ValueFormat.sliceStringAndDot(userStatus.email, 10)
        );
        var emailToolTip = userStatus.email.length > 0 ? userStatus.email : "-";
        var status = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetter(userStatus.status)
        );
        var username = ValueFormat.parseAsString(
          ValueFormat.sliceString(userStatus.username, 10)
        );
        var password = ValueFormat.parseAsString(userStatus.password);
        var createdAt = ValueFormat.parseAsString(
          userStatus.createdAtToTimeZone
        );
        var createData = createdAt.split(" ");
        var createdDate = createData[0];

        var lastRideDateTimeToTimeZone = ValueFormat.parseAsString(
          userStatus.lastRideDateTimeToTimeZone
        );
        var lastRideDateTime = lastRideDateTimeToTimeZone.split(" ");
        var lastRideTimeZone = lastRideDateTime[0];

        var currentMonthRideCount = ValueFormat.parseAsInt(
          userStatus.currentMonthRideCount
        );
        var rides = ValueFormat.parseAsInt(userStatus.rideCount);
        status =
          status == "Active" && rides == 0
            ? "Inactive"
            : status == "Initiated"
              ? "Unregistered" : status == "Registered" ? "Pending"
                : "Active";
        var currentYearRideCount = ValueFormat.parseAsInt(
          userStatus.currentYearRideCount
        );
        var currentDayRideCount = ValueFormat.parseAsInt(
          userStatus.currentDayRideCount
        );
        var currentWeekRideCount = ValueFormat.parseAsInt(
          userStatus.currentWeekRideCount
        );
        var totalCounts = ValueFormat.parseAsInt(userStatus.totalCount);
        setTotalPageCount(Math.ceil(totalCounts / pageSize));
        // setNextShow((totalCounts - indexStartOfSet) > pageSize);
        setNextShow(indexStart + pageSize < totalCounts);
        var iosRideCountList = ValueFormat.parseAsInt(userStatus.iosRideCount);
        var androaidRideCountList = ValueFormat.parseAsInt(
          userStatus.androidRideCount
        );

        var userDetails = {
          firstName: firstName,
          lastName: lastName,
          userName: username,
          password: password,
          emailId: emailToolTip,
          status: status,
          createdTime: createdAt,
          createdDate: createdDate,
          lastRideTime: lastRideDateTimeToTimeZone,
          lastRideTimeZone: lastRideTimeZone,
          iosRideCount: iosRideCountList,
          androidRideCount: androaidRideCountList,
          totalRides: rides,
          CYRides: currentYearRideCount,
          CMRides: currentMonthRideCount,
          CDRides: currentDayRideCount,
          CWRides: currentWeekRideCount,
          ExportRides: "CY",
          userId: userId,
        };
        userList.push(userDetails);
      }
      setData(userList);

      if (indexStart <= 0) {
        setPrevShow(false);
      } else if (indexStart > 0) {
        setPrevShow(true);
      }

    } catch (errMess) {
      console.log("Exception: ", errMess);
    } finally {
      setShowLoader(false);
    }
  };

  const getUserExcelListData = async () => {
    setShowLoader(true);
    var result = await userController.getUserExcelData(
      filterDays,
      filterDataList,
      searchFilter
    );
    var userList = [];

    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("getUserListEL :" + JSON.stringify(resultJSONData));
        for (let i = 0; i < resultJSONData.length; i++) {
          var userStatus = resultJSONData[i];
          var userId = ValueFormat.parseAsString(userStatus.userId);
          var firstName = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetter(userStatus.firstName)
          );
          var lastName = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetter(userStatus.lastName)
          );
          var email = ValueFormat.parseAsString(
            ValueFormat.sliceStringAndDot(userStatus.email, 10)
          );
          var emailToolTip = userStatus.email.length > 0 ? userStatus.email : "-";
          var status = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetter(userStatus.status)
          );
          var username = ValueFormat.parseAsString(
            ValueFormat.sliceString(userStatus.username, 10)
          );
          var password = ValueFormat.parseAsString(userStatus.password);
          var createdAt = ValueFormat.parseAsString(
            userStatus.createdAtToTimeZone
          );
          var createData = createdAt.split(" ");
          var createdDate = createData[0];

          var lastRideDateTimeToTimeZone = ValueFormat.parseAsString(
            userStatus.lastRideDateTimeToTimeZone
          );
          var lastRideDateTime = lastRideDateTimeToTimeZone.split(" ");
          var lastRideTimeZone = lastRideDateTime[0];

          var currentMonthRideCount = ValueFormat.parseAsInt(
            userStatus.currentMonthRideCount
          );
          var rides = ValueFormat.parseAsInt(userStatus.rideCount);
          var currentYearRideCount = ValueFormat.parseAsInt(
            userStatus.currentYearRideCount
          );
          var currentWeekRideCount = ValueFormat.parseAsInt(
            userStatus.currentWeekRideCount
          );
          var currentDayRideCount = ValueFormat.parseAsInt(
            userStatus.currentDayRideCount
          );
          var iosRideCountList = ValueFormat.parseAsInt(userStatus.iosRideCount);
          var androaidRideCountList = ValueFormat.parseAsInt(
            userStatus.androidRideCount
          );

          var userDetails = {
            "First Name": firstName,
            Status: status,
            Activation: createdDate,
            "Last Ride Time": lastRideDateTimeToTimeZone,
            IOS: iosRideCountList,
            And: androaidRideCountList,
            "Total Rides": rides,
            "CY Rides": currentYearRideCount,
            "CM Rides": currentMonthRideCount,
            "CW Rides": currentWeekRideCount,
            "CD Rides": currentDayRideCount,
          };
          userList.push(userDetails);
        }
      } else {
        console.log("Status failed");
        // var code = resultJSON.error.code;
        // if (code == "E1206") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard"
        //   }, 3500);
        // setShowLoader(true);
        // }

      }
    } catch (errMess) {
      // console.log("Exception: ", errMess);
    } finally {
      setShowLoader(false);
    }
    setDataExcel(userList);
  };

  // _____________________________

  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });

  const openPopup = (PopupType, Data) => {
    if (PopupType == "Cancel") {
      setPopUpData({
        ...popupData,
        Purpose: "Cancel",
        message: "you want to cancel ?",
      });
    } else {
      setPopUpData({
        ...popupData,
        Purpose: "Delete",
        message: "This action cannot be undone. The station will be removed from the list.",
        DataDetails: Data ? Data : "",
      });
    }
    // setOpen(true);
  };

  const PopUpClose = () => {
    // setOpen(false);
  };

  // _______________________________________________


  const exportExcel = async (userId, firstName) => {
    var dataExcel = [];
    var result = await MyRidesController.myRidesListExport(userId);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;

    // console.log(
    //   "resultJSONData myRidesListExport : " + JSON.stringify(resultJSONData)
    // );

    for (let i = 0; i < resultJSONData.length; i++) {
      var drivingScore = "0",
        rideName = "N/A",
        /*status = 'N/A', */
        origin = "N/A",
        alerts = 0,
        createdTime = "N/A",
        /*subType = 'N/A',*/ dayPercentage = "0",
        nightPercentage = "0" /*accessLevel = 'N/A',*/,
        endTime = "N/A",
        city = "N/A",
        state = "N/A",
        country = "N/A",
        /*locationName = 'N/A',*/ zipCode = "N/A",
        anticipation = "0",
        drivingSkill = "0",
        selfConfidence = "0",
        travelTime = "-",
        totalKiloMeter = "0 Kms",
        urbanPercent = "0%",
        ruralPercent = "0%",
        highwayPercent = "0%";
      // androidCount = "0";

      try {
        // var totalPercentage = 100;
        var rideObject = resultJSONData[i];

        rideName = rideObject.name.length > 0 ? rideObject.name : "N/A";
        // status         = rideObject.status.length > 0 ?  rideObject.status : 'N/A';
        origin = rideObject.origin.length > 0 ? rideObject.origin : "N/A";
        alerts = rideObject.alertDataCount > 0 ? rideObject.alertDataCount : 0;
        createdTime =
          rideObject.startDateTimeToTimeZone.length > 0
            ? rideObject.startDateTimeToTimeZone
            : "N/A";
        endTime =
          rideObject.endDateTimeToTimeZone != null
            ? rideObject.endDateTimeToTimeZone
            : "N/A";

        // subType        = rideObject.subType.length > 0 ?  rideObject.subType : 'N/A';
        dayPercentage =
          rideObject.dayPercentage > 0
            ? parseFloat(rideObject.dayPercentage).toFixed(0)
            : 0;
        nightPercentage =
          rideObject.dayPercentage > 0 ? 100 - dayPercentage + "%" : 0 + "%";
        // locationName    =  rideObject.locationName.length > 0 ? rideObject.locationName : 'N/A';
        // accessLevel     = rideObject.accessLevel.length > 0 ? rideObject.accessLevel : 'N/A';
      } catch (errMess) {
        // console.log("Exception Error Message :" + errMess);
      }

      try {
        var deviceDataAddressField = rideObject.lastDeviceField;
        var deviceDataField = rideObject;
        city =
          deviceDataAddressField.city != null &&
            deviceDataAddressField.city.length > 0
            ? deviceDataAddressField.city
            : "N/A";
        state =
          deviceDataAddressField.state != null &&
            deviceDataAddressField.state.length > 0
            ? deviceDataAddressField.state
            : "N/A";
        country =
          deviceDataAddressField.country != null &&
            deviceDataAddressField.country.length > 0
            ? deviceDataAddressField.country
            : "N/A";
        zipCode =
          deviceDataAddressField.zipCode != null &&
            deviceDataAddressField.zipCode > 0
            ? deviceDataAddressField.zipCode
            : "N/A";
        anticipation =
          deviceDataField.anticipation != null &&
            deviceDataField.anticipation > 0
            ? parseFloat(deviceDataField.anticipation).toFixed(0)
            : 0;
        drivingSkill =
          deviceDataField.drivingSkill != null &&
            deviceDataField.drivingSkill > 0
            ? parseFloat(deviceDataField.drivingSkill).toFixed(0)
            : 0;
        drivingScore =
          deviceDataField.drivingScore != null &&
            deviceDataField.drivingScore > 0
            ? parseFloat(deviceDataField.drivingScore).toFixed(0)
            : 0;
        selfConfidence =
          deviceDataField.selfConfidence != null &&
            deviceDataField.selfConfidence > 0
            ? parseFloat(deviceDataField.selfConfidence).toFixed(2)
            : 0;
        travelTime =
          deviceDataField.travelTime != null && deviceDataField.travelTime > 0
            ? parseFloat(deviceDataField.travelTime).toFixed(2) + " Mins"
            : 0 + "Mins";
        totalKiloMeter =
          deviceDataField.kiloMeter != null && deviceDataField.kiloMeter > 0
            ? parseFloat(deviceDataField.kiloMeter).toFixed(2) + "Kms"
            : 0 + "Kms";
        urbanPercent =
          deviceDataField.urbanPercent != null &&
            deviceDataField.urbanPercent > 0
            ? parseFloat(deviceDataField.urbanPercent).toFixed(2) + "%"
            : 0 + "%";
        ruralPercent =
          deviceDataField.ruralPercent != null &&
            deviceDataField.ruralPercent > 0
            ? parseFloat(deviceDataField.ruralPercent).toFixed(2) + "%"
            : 0 + "%";
        highwayPercent =
          deviceDataField.highwayPercent != null &&
            deviceDataField.highwayPercent > 0
            ? parseFloat(deviceDataField.highwayPercent).toFixed(2) + "%"
            : 0 + "%";
      } catch (errMess) {
        // console.log("Exception Error Message :" + errMess);
      }

      var userDetails = {
        "Ride Name": rideName,
        // 'Ride Status': status,
        "Driving Score": drivingScore,
        "Driving Skill": drivingSkill,
        Anticipation: anticipation,
        "Self Confidence": selfConfidence,
        "Start Time": createdTime,
        "End Time": endTime,
        "No Of Alerts": alerts,
        "Day Percentage": dayPercentage + "%",
        "Night Percentage": nightPercentage,
        // 'From - Location Name' : locationName,
        "To-City": city,
        "To-State": state,
        "To-Country": country,
        "To-Zip Code": zipCode,
        "Urban Percentage": urbanPercent,
        "Rural Percentage": ruralPercent,
        "Highway Percentage": highwayPercent,
        Mobile: origin,
        // 'Ride Type' : subType,
        Distance: totalKiloMeter,
        Duration: travelTime,
      };
      dataExcel.push(userDetails);
    }

    excelParser().exportDataFromJSON(
      dataExcel,
      firstName + "'s" + " " + "ride" + " " + "detail",
      "csv"
    );
  };

  const createUser = async () => {
    var encryptPassword = Converter.convertMd5(password);
    var result = await userController.createUserRegistration(mobileNumber, encryptPassword, userNameType);
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        // var resultJSONData = resultJSON.data;     
        ToastService.successmsg("User Registered Successfully!");
        getUserListData();
        setTimeout(() => {
          setIsOpen(false);
        }, 3500);
      } else if (resultJSON.status == "FAILED") {
        ToastService.errormsg(resultJSON.error.message);
        setMobileNumber("");
        setPassword("");
      }
    } catch (errMess) {
      // console.log("Exception: ", errMess);
    }
  };
  const validPhone = () => {
    const phrex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (
      phrex.test(mobileNumber) &&
      mobileNumber.length < 11 &&
      mobileNumber.length > 9
    ) {
      setMobileValid(true);
      return true;
    } else {
      setMobileValid(false);
      return false;
    }
  };
  const validPassword = () => {
    const rep = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{5,}/;
    if (rep.test(password)) {
      setPasswordValid(true);
      return true;
    } else {
      setPasswordValid(false);
      return false;
    }
  };

  const submit = () => {
    if (validPhone() && validPassword()) {
      setShowLoader(true);
      createUser();
      setShowLoader(false);

    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (location.state != null) {
        const { filterLink } = location.state;
        setFilterDataList(filterLink);
      }
    }, 900);
  }, []);

  const [count, setCount] = useState(0);
  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [filterDataList, searchFilterSub]);

  useEffect(() => {
    if (count > 0) {
      getUserListData(indexStart);
      getUserExcelListData();
    }
  }, [indexStart, count]);

  const searchSubmit = () => {
    setIndexStart(0);
    setPageNumber(1);
    getUserListData();
    getUserExcelListData();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchSubmit();
    }
  };

  //meta title
  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <ToastContainer style={{ width: "auto" }} />
      <Container fluid>
        <Breadcrumbs title=" My Users" breadcrumbItem=" My Users" />
        <Row className="my-2 py-2">
          <Col xs={12} className="d-flex justify-content-start  align-items-center text-black">
            <h5 className="mb-0">Users</h5>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader style={{ padding: "0px", paddingBottom: 0 }}>
                <Row>
                  {/* <Col
                    xl={1}
                    lg={1}
                    md={1}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        // data-tooltip-id="actio"
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Action
                        {/* <ReactTooltip
                          id="actio"
                          place="top"
                          content="Action"
                          style={{ backgroundColor: "black" }}
                        /> */}
                  {/* </h4>
                    </div>

                    <div className="my-3" style={{ textAlign: "center",height: "40px",}}>
                      <button                        
                        onClick={openModal}
                        type="button"
                        data-tooltip-id="superAdminNewTool"
                        className="btn btn-soft-secondary btn-sm m-1"
                      >
                       <img src={AddIcon} alt="addIcons" style={{height:"25px",width:"25px"}}/>
                       <ReactTooltip
                          id="superAdminNewTool"
                          place="top"
                          content="Add User"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col> */}
                  <Col xl={2} lg={2} md={2} style={{ borderRight: "1px solid #DFDEDE" }} className="pe-0">
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="a"
                      >
                        Action
                        <ReactTooltip
                          id="a"
                          place="top"
                          content="Action"
                          style={{ backgroundColor: "black" }}
                        />
                      </h4>
                    </div>
                    <div className="my-3" style={{ textAlign: "center", height: "40px", }}>
                      <button
                        data-tooltip-id="sms"
                        type="button"
                        className="btn btn-primary btn-sm m-1"
                        onClick={toggleModal}
                      >
                        Send SMS
                      </button>
                      <ReactTooltip
                        id="sms"
                        place="top"
                        content="Send SMS to unregistered users"
                        style={{ background: "black" }}
                      />
                    </div>
                  </Col>
                  <Col xl={4} lg={5} md={6} style={{ borderRight: "1px solid #DFDEDE" }} className="px-0" >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="status"
                      >
                        Status
                        <ReactTooltip
                          id="statu"
                          place="top"
                          content="Status"
                          style={{ backgroundColor: "black" }}
                        />
                      </h4>
                    </div>
                    <div className="my-3" style={{ textAlign: "center", height: "40px", }}>
                      {/* <button
                        type="button"
                        data-tooltip-id="aall"
                        className={
                          filterDataList == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setFilterDataList("")}
                      >
                        All Users
                        <ReactTooltip
                          id="aall"
                          place="top"
                          content="License Issued"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="acti"
                        className={
                          filterDataList == "ACTIVE"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilterDataList("ACTIVE")}
                      >
                        Active Users
                        <ReactTooltip
                          id="acti"
                          place="top"
                          content="Minimum 1 Ride"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="inacti"
                        className={
                          filterDataList == "INACTIVE"
                            ? "btn btn-primary btn-sm m-2"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilterDataList("INACTIVE")}
                      >
                        Inactive Users
                        <ReactTooltip
                          id="inacti"
                          place="top"
                          content="0 Ride"
                          style={{ background: "black" }}
                        />
                      </button>{" "} */}
                      <button
                        data-tooltip-id="INITIATED"
                        type="button"
                        className={
                          filterDataList == "INITIATED"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilterDataList("INITIATED")}
                      >
                        Unregistered Users
                      </button>
                      <ReactTooltip
                        id="INITIATED"
                        place="top"
                        content="Profile Not Registered"
                        style={{ background: "black" }}
                      />
                      <button
                        data-tooltip-id="REGISTERED"
                        type="button"
                        className={
                          filterDataList == "REGISTERED"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setFilterDataList("REGISTERED")}
                      >
                        Pending Users
                        <ReactTooltip
                          id="REGISTERED"
                          place="top"
                          content="Verification Pending Users"
                          style={{ background: "black" }}
                        />
                      </button>

                      {/* <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => setFilterDataList("CURRENT_YEAR")}>
                                        Export As Excel
                                    </button>{" "} */}
                    </div>
                  </Col>
                  <Col xl={4} lg={3} md={3} style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      // data-tooltip-id="GlobalSearch"
                      >
                        Global Search
                        {/* <ReactTooltip
                          id="GlobalSearch"
                          place="top"
                          content="Global Search"
                          style={{ background: "black", zIndex: "100" }}
                        /> */}
                      </h4>
                    </div>



                    <div
                      className="my-3"
                      style={{ height: "40px", display: "flex", justifyContent: "center", marginLeft: "15px" }}
                    >

                      <input
                        type="search"
                        style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                        value={searchFilter}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => {
                          setSearchFilter(e.target.value);
                        }}
                        placeholder="Mobile number or First name"
                      />
                      <button
                        style={{ marginLeft: "5px" }}
                        className="btn btn-primary me-3"
                        type="button"
                        onClick={searchSubmit}
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                      </button>
                    </div>

                  </Col>
                  <Col xl={2} lg={2} md={1} style={{ paddingLeft: "0px" }}>

                    <div>
                      <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                        <h4
                          className="card-title mb-1 mt-1"
                          style={{ textAlign: "center" }}
                        // data-tooltip-id="dwn"
                        >
                          Download
                          {/* <ReactTooltip
                          id="dwn"
                          place="top"
                          content="Download"
                          style={{ background: "black", zIndex: "100" }}
                        /> */}
                        </h4>
                      </div>
                      {dataLength > 0 && (
                        <div
                          className="my-3"
                          style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px", }}
                        >
                          {/* <span className="mt-1">Excel:</span> */}

                          <CSVLink
                            data={dataExcel}
                            type="button"
                            // onClick={onCloseClick}
                            className="btn btn-secondary btn-sm m-1"
                            filename={userFirstName + "'s" + " Users list.csv"}
                          // id="delete-record"
                          >
                            <PiDownloadSimple
                              data-tooltip-id="currentyears"
                              size="18px"
                            />
                            <ReactTooltip
                              id="currentyears"
                              place="top"
                              content="Current Year Users as Excel"
                              style={{ background: "black" }}
                            />
                            {/* <span  style={{ fontSize: 10 }}>CY</span> */}
                          </CSVLink>
                        </div>
                      )}
                    </div>

                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                {/* <Row>
                  <Col xl={12} lg={12} md={12} sm={12} sx={12}>
                    <form className="d-none d-lg-block">
                      <div
                        className="mb-3 mt-4"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <label className="mt-2 " style={{ fontSize: "15px" }}>
                          &nbsp;Global Search:
                        </label>
                        <input
                          type="search"
                          style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                          value={searchFilter}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => {
                            setSearchFilter(e.target.value);
                          }}
                          placeholder="Mobile number or First name"
                        />
                        <button
                          style={{ marginLeft: "5px" }}
                          className="btn btn-primary me-3"
                          type="button"
                          onClick={searchSubmit}
                        >
                          <i className="bx bx-search-alt align-middle"></i>
                        </button>
                      </div>
                    </form>
                  </Col>
                </Row> */}
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="mt-3">
                      <table className="table table-bordered border-primary mb-0">
                        <thead className="cardHeader">
                          <tr>
                            <th
                              data-tooltip-id="fname"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "start", padding: "8px" }}
                            >
                              First Name

                              <ReactTooltip
                                id="fname"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                            {/* <th data-tooltip-id="usern" style={{ textAlign: "center", padding: "8px" }}>
                              Username
                              <ReactTooltip
                                id="usern"
                                place="top"
                                content="Username"
                              />
                            </th> */}
                            <th
                              data-tooltip-id="stat"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              Status
                              <ReactTooltip
                                id="stat"
                                place="top"
                                content="Status"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="cdate"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              Activation
                              <ReactTooltip
                                id="cdate"
                                place="top"
                                content="Created Date"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="lrd"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              Last Ride
                              <ReactTooltip
                                id="lrd"
                                place="top"
                                content="Last Ride Date"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="io"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              iOS
                              <ReactTooltip
                                id="io"
                                place="top"
                                content="iOS Ride Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="andr"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              And
                              <ReactTooltip
                                id="andr"
                                place="top"
                                content="Android Ride Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="all"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              All
                              <ReactTooltip
                                id="all"
                                place="top"
                                content="All Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="cy"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              CY
                              <ReactTooltip
                                id="cy"
                                place="top"
                                content="Current Year Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>

                            <th
                              data-tooltip-id="cm"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              CM
                              <ReactTooltip
                                id="cm"
                                place="top"
                                content="Current Month Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="cw"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              CW
                              <ReactTooltip
                                id="cw"
                                place="top"
                                content="Current Week Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="cd"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              CD
                              <ReactTooltip
                                id="cd"
                                place="top"
                                content="Current Day Rides Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="downlo"
                              style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", padding: "8px" }}
                            >
                              Download
                              <ReactTooltip
                                id="downlo"
                                place="top"
                                content="Download"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            (data || []).map((datas, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  <span
                                    style={{ fontSize: "13px" }}
                                    onMouseEnter={() => [
                                      localStorage.setItem(
                                        SC_USER_ID,
                                        datas.userId
                                      ),
                                      localStorage.setItem(
                                        SC_LOGIN_USER_FIRST_NAME,
                                        datas.firstName
                                      ),
                                    ]}
                                  >
                                    <Link to={datas.status == "Active" && "/user-dashboard"}>
                                      {datas.firstName}
                                    </Link>
                                  </span>
                                </td>

                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  {datas.status}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span
                                    data-tooltip-id={"register" + i}
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.createdDate}
                                  </span>
                                  <ReactTooltip
                                    id={"register" + i}
                                    place="top"
                                    content={datas.createdTime}
                                    style={{ backgroundColor: "black" }}
                                  />
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span
                                    data-tooltip-id={"lastride" + i}
                                    style={{ fontSize: "13px" }}
                                  >
                                    {datas.lastRideTimeZone}
                                  </span>
                                  <ReactTooltip
                                    id={"lastride" + i}
                                    place="top"
                                    content={datas.lastRideTime}
                                    style={{ backgroundColor: "black" }}
                                  />
                                </td>
                                <td
                                  style={{
                                    // verticalAlign: "middle",
                                    // textAlign: "center",
                                    // padding: "0px",
                                    textAlign: "end"
                                  }}
                                >
                                  <div className=" w-50" style={{ fontSize: "13px" }}>
                                    {datas.iosRideCount}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    // verticalAlign: "middle",
                                    // textAlign: "center",
                                    // padding: "0px",
                                    textAlign: "end"
                                  }}
                                >
                                  <div className=" w-50" style={{ fontSize: "13px" }}>
                                    {datas.androidRideCount}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    // verticalAlign: "middle",
                                    // textAlign: "center",
                                    // padding: "0px",
                                    textAlign: "end"
                                  }}
                                >
                                  <div className=" w-50" style={{ fontSize: "13px" }}>
                                    {datas.totalRides}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    // verticalAlign: "middle",
                                    // textAlign: "center",
                                    // padding: "0px",
                                    textAlign: "end"
                                  }}
                                >
                                  <div className=" w-50" style={{ fontSize: "13px" }}>
                                    {datas.CYRides}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    // verticalAlign: "middle",
                                    // textAlign: "center",
                                    // padding: "0px",
                                    textAlign: "end"
                                  }}
                                >
                                  <div className=" w-50" style={{ fontSize: "13px" }}>
                                    {datas.CMRides}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>   {datas.CWRides}</span>
                                </td>
                                <td
                                  style={{
                                    // verticalAlign: "middle",
                                    // textAlign: "center",
                                    // padding: "0px",
                                    textAlign: "end"
                                  }}
                                >
                                  <div className=" w-50" style={{ fontSize: "13px" }}>
                                    {datas.CDRides}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: "5px",
                                    }}
                                    onClick={() =>
                                      exportExcel(datas.userId, datas.firstName)
                                    }
                                  >
                                    <button
                                      data-tooltip-id="CurrentYear"
                                      type="button"
                                      className="btn btn-soft-secondary btn-sm "
                                    >
                                      <PiDownloadSimple size="18px" />
                                    </button>
                                    <ReactTooltip
                                      id="CurrentYear"
                                      place="top"
                                      content="Current Year Rides as Excel"
                                      style={{ backgroundColor: "black" }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="12">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="12">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="pagination">
                    <a>{pageNumber}</a>
                  </div>
                  <p style={{ marginTop: "6px", marginLeft: "5px", marginRight: "5px" }} >of</p>
                  <div className="pagination ">
                    <a>{totalPageCount}</a>
                  </div>
                </div>
              ) : null}
              {dataLength > 0 ? (
                <button
                  type="button"
                  className="btn btn-outline-success btn-sm"
                  onClick={next}
                  style={{ visibility: nextShow ? "visible" : "hidden", float: "right" }}
                >
                  Next
                </button>
              ) : null}
            </div>
            <br />
          </Col>
        </Row>

        <div style={{ backgroundColor: "red" }}>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            overlayClassName="custom-modal-overlay"
            contentLabel="Example Modal"
          >

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2
                style={{
                  color: "black",
                  fontSize: "25px",
                  fontFamily: "sans-serif",
                  fontWeight: 600,
                }}
              >
                Add User
              </h2>
              <CgCloseO
                size="25px"
                style={{ marginTop: "6px" }}
                onClick={openConfirmationModal}
              />
            </div>
            <Form
              className="custom-form mt-0 pt-2"
              onSubmit={(e) => {

                e.preventDefault();
                setIsSubmitting(prev => { return { ...prev, mobile: true, password: true } })
                validation.handleSubmit();
                return false;
              }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  fontFamily: "sans-serif",
                }}
              >
                Username Type
              </label>
              <select className="form-select" value={userNameType} onChange={(e) => setuserNameType(e.target.value)}>
                <option value={"MOBILE_NUMBER"}> Mobile Number</option>
                <option value={"CUSTOM"}> Custom</option>

              </select>
              <div className="mt-3">
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "sans-serif",
                  }}
                >
                  Device Type
                </label>
                <select required className="form-select" value={deviceType} onChange={(e) => setDeviceType(e.target.value)}>
                  <option value={" "} disabled>Select Mobile Type</option>
                  <option value={"ANDROID"}>Android</option>
                  <option value={"IOS"}> IOS</option>

                </select>
              </div>
              <div className=" mt-3">
                <div style={{ display: 'flex' }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "sans-serif",
                    }}
                  >
                    Username
                  </label>
                  <div>
                    <AiOutlineExclamationCircle style={{ marginTop: "-3px", marginLeft: "5px" }} data-tooltip-id="usernameTooltipContent" />
                    <ReactTooltip
                      id="usernameTooltipContent"
                      place="top"
                      content={<span>Username is case insensitive</span>}
                      style={{ backgroundColor: "black" }}
                    />
                  </div>
                </div>
                <div className="input-group auth-pass-inputgroup">
                  <Input
                    type="text"
                    onChange={(e) => {
                      validation.handleChange(e);
                      setIsSubmitting((prev) => ({ ...prev, mobile: false }));
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.addUsername}
                    name="addUsername"
                    id="addUsername"
                    placeholder="Enter Mobile Number/Custom username"
                    invalid={
                      isSubmitting.mobile && validation.errors.addUsername ? true : false
                    }
                  />
                  {validation.values.addUsername !== "" && (
                    <button
                      onClick={() => {
                        validation.setFieldValue("addUsername", "");
                        setIsSubmitting((prev) => ({ ...prev, mobile: false }));
                      }}
                      className="btn btn-light shadow-none ms-0"
                      type="button"
                      id="password-addon"
                    >
                      <i className="mdi mdi-close"></i>
                    </button>
                  )}

                  {isSubmitting.mobile && validation.errors.addUsername ? (
                    <FormFeedback type="invalid">{validation.errors.addUsername}</FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="mb-3 mt-3">
                <div style={{ display: "flex" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "sans-serif",
                    }}
                  >
                    Password
                  </label>
                  <div>
                    <AiOutlineExclamationCircle
                      style={{ marginTop: "-3px", marginLeft: "5px" }}
                      data-tooltip-id="passwordTooltipContent"
                    />
                    <ReactTooltip
                      id="passwordTooltipContent"
                      place="top"
                      content={
                        <span>
                          Password must have a minimum of 8 characters.
                          <br />
                          Include at least 1 lowercase, 1 uppercase,
                          <br />
                          1 numeric & 1 special character!
                        </span>
                      }
                      style={{ backgroundColor: "black" }}
                    />
                  </div>
                </div>
                <div className="input-group auth-pass-inputgroup d-flex">
                  <Input
                    name="addUserPassword"
                    value={validation.values.addUserPassword}
                    style={{ WebkitTextSecurity: passwordShow ? "none" : "disc" }}
                    placeholder="Enter Password"
                    onChange={(e) => {
                      validation.handleChange(e);
                      setIsSubmitting((prev) => ({ ...prev, password: false }));
                    }}
                    onBlur={validation.handleBlur}
                    invalid={isSubmitting.password && validation.errors.addUserPassword ? true : false}
                  />



                  {passwordShow ? (
                    <button
                      onClick={() => setPasswordShow((prev) => !prev)}
                      className="btn btn-light shadow-none ms-0"
                      type="button"
                      id="password-addon"
                    >
                      <i className="mdi mdi-eye"></i>
                    </button>) : (
                    <button
                      onClick={() => setPasswordShow((prev) => !prev)}
                      className="btn btn-light shadow-none ms-0"
                      type="button"
                      id="password-addon"
                    >
                      <i className="mdi mdi-eye-off"></i>
                    </button>
                  )}
                  {validation.values.addUserPassword !== "" && (
                    <button
                      onClick={() => {
                        validation.setFieldValue("addUserPassword", "");
                        setIsSubmitting((prev) => ({ ...prev, password: false }));
                      }}
                      className="btn btn-light shadow-none ms-0"
                      type="button"
                      id="password-addon"
                    >
                      <i className="mdi mdi-close"></i>
                    </button>
                  )}
                  {(isSubmitting.password && validation.errors.addUserPassword) && (
                    <FormFeedback type="invalid">{validation.errors.addUserPassword}</FormFeedback>
                  )}
                </div>
              </div>





              {/* <Modal
                isOpen={confirmationModalOpen}
                onRequestClose={closeConfirmationModal}
                contentLabel="Confirmation Modal"
                style={{
                  content: { height: "150px", width: "350px", margin: "auto" },
                  overlay: { backgroundColor: "rgba(255, 255, 255, 0)" } // Set the overlay background color to transparent
                }}
              >
                <div style={{ height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <p>Are you sure you want to close?</p>
                </div>
                <div style={{ height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <button onClick={handleConfirmClose} className="btn btn-outline-success btn-sm m-1">Yes</button>
                  <button onClick={closeConfirmationModal} className="btn btn-outline-danger btn-sm m-1">No</button>
                </div>
              </Modal> */}



              <Modal
                isOpen={confirmationModalOpen}
                // onRequestClose={closeConfirmationModal}
                toggle={closeConfirmationModal}
                contentLabel="Confirmation Modal"
                // className="custom-modall"
                style={{
                  content: { height: "190px", width: "340px", margin: "auto", borderRadius: "10px", padding: "0px" },
                  overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
                }}
              >
                <ModalHeader style={{ height: "40px", backgroundColor: "#5156BE", width: "100%", display: "felx", justifyContent: "center" }} >
                  <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>Confirm Action </span>
                </ModalHeader>
                <ConformationPopup
                  Data={popupData}
                  formSubmit={handleConfirmClose}
                  cancelSubmit={closeConfirmationModal}
                />
                {/* <ModalBody>
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:"30px"
                  }}
                >
                  <p style={{fontFamily:"Segoe UI", fontSize:"22px", fontWeight:600, textAlign:"center"}}
                  >Are you sure 
                  <br/>you want to close?</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:"30px"
                  }}
                >
                 
                  <Button className= "Button1" onClick={closeConfirmationModal} >
                    No
                  </Button>
                  <Button className= "Button2" onClick={handleConfirmClose} >
                    Yes
                  </Button>
                </div>
                </ModalBody> */}
              </Modal>
              <div
                // className="d-flex justify-content-space-between align-items-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  type="submit"
                  className="btn btn-primary btn-clr text-white"
                  onClick={submit}
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => [validation.setFieldValue('addUserPassword', ''), validation.setFieldValue('addUsername', ''), setIsSubmitting(false)]}
                >
                  Clear
                </button>

              </div>
            </Form>

          </Modal>

          <Modal isOpen={modal} toggle={handleCancel} keyboard={false}
            backdrop="static"
            style={{
              content: {
                height: formData.userType === "ALL_UNREGISTERED_USERS" ? '330px' : '405px',
                width: "500px",
                margin: "auto",
                borderRadius: "10px",
                padding: "0px",
              },
              overlay: { backgroundColor: "rgba(0,0,0,0.5)" }
            }}>
            <ModalHeader toggle={handleCancel} style={{ padding: "20px", paddingBottom: "20px", background: "#5156be1a", alignItems: "center" }}>Confirm Action</ModalHeader>
            <ModalBody className="p-3" style={{ height: formData.userType === "ALL_UNREGISTERED_USERS" ? '200px' : '280px' }}>
              <Form>
                <FormGroup>
                  <Label for="userType">Select User</Label>
                  <Input
                    type="select"
                    name="userType"
                    id="userType"
                    value={formData.userType}
                    onChange={handleInputChange}
                  >
                    <option value="ALL_UNREGISTERED_USERS">All Unregistered Users</option>
                    <option value="SINGLE_USER">Single User</option>
                  </Input>
                </FormGroup>
                {formData.userType === "SINGLE_USER" && (
                  <FormGroup>
                    <Label for="username">Username</Label>
                    <Input
                      type="text"
                      name="username"
                      id="username"
                      value={formData.username}
                      onChange={handleInputChange}
                      autoComplete="false"
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="secretCode">Secret Code</Label>
                  <Input
                    type="text"
                    name="secretCode"
                    id="secretCode"
                    value={formData.secretCode}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center align-items-center gap-2 p-3 pt-0">
              <Button color="primary" onClick={smsToUnregieredUsersPost}>Proceed</Button>
              <Button color="secondary" onClick={handleCancel}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

export default TriggerMessageForUnregisteredUsers;
