import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import WalletBalance from "../WalletBalance";
import InvestedOverview from "../InvestedOverview";
import MarketOverview from "../MarketOverview";
import Locations from "../Locations";
import Trading from "../Trading";
import NewSlider from "../NewSlider";
import {
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_LOGIN_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../components/Common/loader";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UserDashboardSubMenu.css";
import UserDashboardSubMenu from "./UserDashboardSubMenu.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import UserAdminDashboardSubMenu from "./UserAdminDashboardSubMenu.js";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";

const Section3 = () => {
  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [safetyPieData, setSafetyPieData] = useState([]);
  const [leaderBoardProps, setLeaderBoardProps] = useState([]);
  const [rideNamesPerCat, setRideNamesPerCat] = useState([]);
  const [locationDataPdf, setLocationDataPdf] = useState([]);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [categoryTable, setCategoryTable] = useState();
  const { pathname } = useLocation();
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const containerIds = ["container1"];

  //meta title

  const handleTableData = (data) => {
    setCategoryTable(data);
  };

  const getSafetyDataProps = (data) => {
    setSafetyPieData(data);
  };

  const getLeaderboardProps = (data) => {
    setLeaderBoardProps(data);
  };

  const getRideNameList = (data) => {
    setRideNamesPerCat(data);
  };

  const getLocationDataPdf = (newData) => {
    setLocationDataPdf(newData);
  };


  return (
    <React.Fragment>
      {showLoader > 0 && <Loader />}
      <div className="page-content">
        {/* <div className="ps-2 pe-2"> */}
        <Container fluid id="division">
          <div id="container1">
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                className="shadow-sm "
                style={{ position: "fixed", zIndex: 1, background: "#fff" }}
              >
                <Row>
                  <Col xl={5} className="py-2">
                    {loginType == "ADMIN" || loginType == "SUPER_ADMIN" ? (
                      <UserAdminDashboardSubMenu />
                    ) : (
                      <UserDashboardSubMenu />
                    )}
                  </Col>
                  <Col
                    xl={7}
                    className="py-2"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div className="ms-auto">
                        <div style={{ float: "right" }}>
                          <button
                            data-tooltip-id="cds"
                            type="button"
                            className={
                              (filters.durationFilter == "CURRENT_DAY" && !modalIsOpen)
                                ? "btn btn-primary btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_DAY",
                              })
                            }}
                          >
                            CD
                            <ReactTooltip
                              id="cds"
                              place="bottom"
                              content="Current Day"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            data-tooltip-id="cws"
                            type="button"
                            className={
                              (filters.durationFilter == "CURRENT_WEEK" && !modalIsOpen)
                                ? "btn btn-primary btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_WEEK",
                              })
                            }}
                          >
                            CW
                            <ReactTooltip
                              id="cws"
                              place="bottom"
                              content="Current Week"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            data-tooltip-id="cms"
                            type="button"
                            className={
                              (filters.durationFilter == "CURRENT_MONTH" && !modalIsOpen)
                                ? "btn btn-primary btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_MONTH",
                              })
                            }}
                          >
                            CM
                            <ReactTooltip
                              id="cms"
                              place="bottom"
                              content="Current Month"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            data-tooltip-id="cys"
                            type="button"
                            className={
                              (filters.durationFilter == "CURRENT_YEAR" && !modalIsOpen)
                                ? "btn btn-primary btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_YEAR",
                              })
                            }}
                          >
                            CY
                            <ReactTooltip
                              id="cys"
                              place="bottom"
                              content="Current Year"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CUSTOM_DURATION" || modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCount(1);
                            }}
                            data-tooltip-id="dfdf"
                          >
                            <FontAwesomeIcon icon={faClock} />
                            <ReactTooltip
                              id="dfdf"
                              place="bottom"
                              content="Custom Date Selection"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                        </div>
                      </div>

                      {(loginType == "ADMIN" ||
                        loginType == "SUPER_ADMIN" ||
                        loginType == "SUPER_ADMIN_AMAZON") && (
                        <div className=" border-start border-secondary">
                          <Link to={"/myRides"}>
                            &nbsp;&nbsp;&nbsp;
                            <button
                              data-tooltip-id="gtr"
                              className="btn btn-primary btn-sm"
                            >
                              Go To Rides
                              <ReactTooltip
                                id="gtr"
                                place="bottom"
                                content="Go To Rides"
                                style={{
                                  zIndex: "100",
                                  backgroundColor: "black",
                                }}
                              />
                            </button>
                          </Link>
                        </div>
                      )}

                      <div className="ps-2 border-start border-secondary">
                        <ExportScreenPdf
                          containerIds={containerIds}
                          fileName={
                            loginType == "ADMIN" || loginType == "SUPER_ADMIN"
                              ? userFirstName + "'s" + " Category Summary"
                              : userName + "'s" + " Category Summary"
                          }
                        />
                      </div>
                      <div
                        className="ps-0 "
                      >
                        <ViewPdf 
                        safetyPieData={safetyPieData} leaderBoardProps={leaderBoardProps} locationDataPdf={locationDataPdf} rideNamesPerCat={rideNamesPerCat} filtersUDCS={filters} categoryTable={categoryTable}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <WalletBalance
                filter={filters}
                getSafetyDataProps={getSafetyDataProps}
                loaderCount={setShowLoader}
              />
              <Col>
                <Row>
                  <InvestedOverview
                    getLeaderboardProps={getLeaderboardProps}
                    loaderCount={setShowLoader}
                  />
                  <NewSlider />
                </Row>
              </Col>
            </Row>
            {/* <Row>
                <OverSpeedLocations />
              </Row> */}

            <Row>
              <MarketOverview filter={filters} loaderCount={setShowLoader} />
              <Locations getLocationDataPdf={getLocationDataPdf} />
            </Row>

            <Trading
              filter={filters}
              getRideNameList={getRideNameList}
              loaderCount={setShowLoader}
              handleTableData={handleTableData}
            />

            <br />
            <Row>
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "14px !important",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Anticipation
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px !important",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability not to enter into the red risk
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={5}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "14px",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Self-Confidence
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability to maintain a range of risk for majority time
                          of the ride
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "14px !important",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Driving Skill
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px !important",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability to maintain the same level of risk
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        {/* </div> */}
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filters.durationFilter}
        />
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

export default Section3;
