import React, { lazy, Suspense } from 'react';
import GoogleMapCrash from './googleMapCrashView';
import SuperAdminHereMapTest from './SuperAdminDetailCrashView';

// const GoogleMapRisk = lazy(() => import('./GoogleMapRisk'));
export default function SuperAdminGoogleMapCrashView() {
  return (
    <div>
        <Suspense fallback={<div>Loading...</div>}>
            <SuperAdminHereMapTest/>
        </Suspense>
    </div>
  )
}