import DateFormat from "dateformat";
import moment from "moment/moment";

export let UtilDateTime = {
  dateManipulation(operator, date, months, days, hours, minutes, seconds) {
    var newDate = "";
    var durationInMs = 0;
    date = new Date(date);

    if (operator == "-") {
      durationInMs =
        (24 * days * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds) * 1000;
      newDate = new Date(date.getTime() - durationInMs);
    } else {
      durationInMs =
        (24 * days * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds) * 1000;
      date.setMonth(date.getMonth() + months);
      newDate = new Date(date.getTime() + durationInMs);
    }
    return this.formatDateTime(newDate);
  },

  currentSyncDateAndTime() {
    try {
      return DateFormat(new Date(), "dd mmm, yyyy");
    } catch (err) {
      return "";
    }
  },

  formatDate(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "dd mmm, yyyy");
    } catch (err) {
      return "";
    }
  },

  formatDateInt(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "dd-mm-yyyy");
    } catch (err) {
      return "";
    }
  },

  formatDateTime(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "yyyy-mm-dd HH:MM:ss");
    } catch (err) {
      return "";
    }
  },

  formatDateTimeNew(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "dd-mm-yyyy HH:MM:ss");
    } catch (err) {
      return "";
    }
  },

  formatDateTimeUTC(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "dd-mm-yyyy HH:MM:ss");
    } catch (err) {
      return "";
    }
  },

  formatStartDateTime(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "yyyy-mm-dd 00:00:00");
    } catch (err) {
      return "";
    }
  },

  formatEndDateTime(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "yyyy-mm-dd 23:59:59");
    } catch (err) {
      return "";
    }
  },

  formatTimeAsHHMMTT(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "hh:MM TT");
    } catch (err) {
      return "";
    }
  },

  formatMonthAsFullString(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "mm");
    } catch (err) {
      return "";
    }
  },

  formatYearAsFullString(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "yyyy");
    } catch (err) {
      return "";
    }
  },

  formatYearAndMonthAsFullString(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "mmm, yyyy");
    } catch (err) {
      return "";
    }
  },

  formatYearMonthAndDayAsFullString(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "dd, mmm, yyyy");
    } catch (err) {
      return "";
    }
  },

  formatTimeAsHHMM(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "HH:MM");
    } catch (err) {
      return "";
    }
  },

  formatTimeAsHHMMSS(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "HH:MM:ss");
    } catch (err) {
      return "";
    }
  },

  formatTimeAshhMM(dateTimeValue) {
    try {
      return DateFormat(dateTimeValue, "hh:MM");
    } catch (err) {
      return "";
    }
  },

  getCurrentDay() {
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = currentDate.getFullYear();

    const today = yyyy + "-" + mm + "-" + dd;
    return today;
  },
  getCurrentDayformat() {
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = currentDate.getFullYear();

    const today =  yyyy + mm + dd;
    return today;
  },
  getCurrentWeek() {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var currentDate = new Date();
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var MM = monthNames[currentDate.getMonth()];
    var yyyy = currentDate.getFullYear();
    const Year = yyyy;
    // Get ISO week number and year
    // Get ISO week number and year
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    // Calculate the current week within the month
    const daysSinceStartOfMonth = currentDate.getDate() - 1;
    const currentWeekInMonth = Math.ceil(
      (daysSinceStartOfMonth + startOfMonth.getDay() + 1) / 7
    );
    const text =
      currentWeekInMonth == 1
        ? "st"
        : currentWeekInMonth == 2
        ? "nd"
        : currentWeekInMonth == 3
        ? "rd"
        : currentWeekInMonth == 4
        ? "th"
        : "th";
    const Week = `${MM}, ${Year} \n (${currentWeekInMonth}${text} Week)`;

    return Week;
  },
  getCurrentWeek1() {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var currentDate = new Date();
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var MM = monthNames[currentDate.getMonth()];
    var yyyy = currentDate.getFullYear();
    const Year = yyyy;
    // Get ISO week number and year
    // Get ISO week number and year
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    // Calculate the current week within the month
    const daysSinceStartOfMonth = currentDate.getDate() - 1;
    const currentWeekInMonth = Math.ceil(
      (daysSinceStartOfMonth + startOfMonth.getDay() + 1) / 7
    );
    const text =
      currentWeekInMonth == 1
        ? "st"
        : currentWeekInMonth == 2
        ? "nd"
        : currentWeekInMonth == 3
        ? "rd"
        : currentWeekInMonth == 4
        ? "th"
        : "th";
    const Week = `${MM}, ${Year} ${currentWeekInMonth}${text} Week`;

    return Week;
  },
  getCurrentYear() {
    var currentDate = new Date();
    var yyyy = currentDate.getFullYear();

    const Year = yyyy;
    return Year;
  },
  getSelectedYear(selectedYear) {
    var selectedYearFormat = new Date(selectedYear);
    var yyyy = selectedYearFormat.getFullYear();

    const Year = yyyy;
    return Year;
  },
  getCurrentMonth() {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var currentDate = new Date();
    var MM = monthNames[currentDate.getMonth()];
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = currentDate.getFullYear();

    const month = MM + ", " + yyyy;
    return month;
  },

  // Function to convert UTC to LocalTime
  // convertToLocalTime(utcTime) {
  //   return moment
  //     .utc(utcTime, "HH:mm:ss")
  //     .tz("America/Phoenix")
  //     .format("HH:mm:ss");
  // },

  // Function to convert LocalTime to UST
  // convertToUTC(localTime) {
  //   return moment
  //     .tz(localTime, "HH:mm:ss", "America/Phoenix")
  //     .utc()
  //     .format("HH:mm:ss");
  // },

  // Function to get the user's current timezone
  getCurrentTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  },

  // Function to convert UTC to LocalTime
  convertToLocalTime(utcTime) {
    const userTimezone = this.getCurrentTimezone();
    return moment
      .utc(utcTime, "HH:mm:ss")
      .tz(userTimezone)
      .format("HH:mm:ss");
  },

  // Function to convert LocalTime to UTC
  convertToUTC(localTime) {
    const userTimezone = this.getCurrentTimezone();
    return moment
      .tz(localTime, "HH:mm:ss", userTimezone)
      .utc()
      .format("HH:mm:ss");
  },
};

export default UtilDateTime;
