import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Carousel,
    CarouselItem,
    CarouselIndicators,
    Col,
    Card,
    CardBody,
} from 'reactstrap';

const items = [
    {
      id: 1,
      category: "C1",
      title: "Safe Driver",
      description:"An effective driver stays consistently alert and careful, making safety the main focus throughout the entire ride."
    },
    {
      id: 2,
      category: "C2",
      title: "Sportive Driver",
      description: "A driver stays very attentive and confident, ensuring a high level of safeness but with a sporty attitude throughout the entire journey."
    },
    {
      id: 3,
      category: "C3",
      title: "Quite Safe Driver",
      description:"A driver who is moderately safe with moderate alertness, making sure the journey is moderately safe all the way."
    },
    {
        id: 4,
        category: "C4",
        title: "Reckless Driver",
        description:"A driver being reckless, holds a moderate level of alertness and high confidence, leading to a journey that is less moderate in safeness throughout the Ride."
      },
      {
        id: 5,
        category: "C5",
        title: "Quite Dangerous Driver",
        description:"A Risky Driver with less attention and who is prone to red risks, making journey unsafe throughout the Ride."
      },
      {
        id: 6,
        category: "C6",
        title: "Dangerous Driver",
        description:"A Daring Driver being less attentive and Over confident, making the journey highly Unsafe."
      },
  ];

const NewSlider = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <div className="text-center p-3"  >
                    {/* <i className={'mdi mdi-' + item.icon + ' widget-box-1-icon'}></i> */}
                    
                    <div className="avatar-md m-auto">
                        <span className="avatar-title rounded-circle bg-soft-light text-white font-size-24">
                            <h4 style={{color:"white"}}>{item.category}</h4>
                            {/* <i className={'mdi mdi-' + item.category}></i> */}
                        </span>
                    </div>
                    <h4 className="mt-3 lh-base fw-normal text-white"><b>{item.title}</b></h4>
                    <p className="text-white-50 font-size-13"> {item.description} </p>

                   
                   
                    {/* <Link to="/invoices-list" className="btn btn-light btn-sm" >
                        View details <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link> */}
                </div>
            </CarouselItem>
        );
    });

    return (
        <Col xl={4}>
            <Card className="bg-primary text-white shadow-primary" style={{height:"490px"}}>
                <CardBody className="p-0">
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                        ride="carousel"
                    >
                        {slides}
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} className="carousel-slider dashboard-slider" />
                    </Carousel>
                </CardBody>
            </Card>
        </Col>
    );
};

export default NewSlider;