import { OVERSPEEDLOCATION_DATA } from "./actiontype";

const INIT_STATE = {
  overSpeed: {value:[]}
};

const OverSpeedLocationData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OVERSPEEDLOCATION_DATA:
      return {
        ...state,
        overSpeed: action.payload,
      };
    default:
      return state;
  }
};

export default OverSpeedLocationData;
