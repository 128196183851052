import React, { useEffect, useState } from "react";
import LoaderPdf from "../Common/loaderPdf";
import ValueFormat from "../../util/ValueFormat";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Rect,
  Svg,
  Line,
} from "@react-pdf/renderer";
import MotivaiLogo from "../../assets/images/logo.motivAI.png";
import RuduLogo from "../../assets/images/logo/motivAiLogoPng.png";

import AnimalCrossing from "../../assets/images/t_animal_crossing.png";
import Caution from "../../assets/images/t_caution.png";
import Curves from "../../assets/images/t_curves.png";
import Force from "../../assets/images/t_force_acceleration.png";
import HillDownwards from "../../assets/images/t_hill_downwards.png";
import HillUpwards from "../../assets/images/t_hill_upwards.png";
import Intersection from "../../assets/images/t_intersection.png";
import LaneMerge from "../../assets/images/t_lane_merge.png";
import LowGearArea from "../../assets/images/t_low_gear_area.png";
import NarrowRoad from "../../assets/images/t_narrow_road.png";
import MobUse from "../../assets/images/t_mob_use.png";
import NoOvertaking from "../../assets/images/t_no_overtaking.png";
import OverSpeed from "../../assets/images/t_over_speed.png";
import PedestrianCrossing from "../../assets/images/t_pedestrian_crossing.png";
import RailwayCrossing from "../../assets/images/t_railway_crossing.png";
import SchoolZone from "../../assets/images/t_school_zone.png";
import StopSign from "../../assets/images/t_stop.png";
import suddenBrake from "../../assets/images/t_sudden_break.png";
import TrafficLight from "../../assets/images/t_traffic_light.png";
import WindingRoad from "../../assets/images/t_winding_road.png";
import Yield from "../../assets/images/t_yield.png";
import Priority from "../../assets/images/t_priority.png";
import IcyCondition from "../../assets/images/t_icy_conditions.png";
import Wind from "../../assets/images/t_wind.png";
import Hill from "../../assets/images/t_hill.png";
import SlipperyRoad from "../../assets/images/t_slippery_road.png";
import RiskGrounding from "../../assets/images/t_risk_of_grouping.png";
import RoundAbout from "../../assets/images/Round-About.png";
import { useLocation } from "react-router";


import {
  SC_COMPANY_NAME,
  SC_DIVISION_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_NAME,
  SC_REGISTRATION_DATE,
  SC_USERNAME,
  SC_USER_FIRST_NAME,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import UtilDateTime from "../../util/UtilDateTime";

const styles = StyleSheet.create({
  section: {
    margin: 5,
    paddingHorizontal: 25,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    height: "30px",
  },
  cell: {
    margin: 5,
    fontSize: "9px",
  },
  PDFViewer: {
    height: "96%",
    width: "100%",
    marginTop: "20px",
    marginBottom: "5px",
  },
  Document: {
    marginTop: "15px",
    marginBottom: "5px",
  },
  tablestyle: {
    border: "1.5px solid black",
    borderTop: "none",
    borderRight: "none",
    padding: "5px",
    fontSize: "10px",
    textAlign: "center",
  },
  imgAbbr: {
    width: 12,
    height: 12,
    marginRight: 1,
    marginLeft: 1,
    marginBottom: "3px",
  },
  page2content: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    paddingHorizontal: 30,
  },
});

const fontItalic = {
  fontFamily: "Times-BoldItalic",
};

const fontBold = {
  fontFamily: "Helvetica-Bold",
};


const reversedDateFormat = UtilDateTime.getCurrentDay();

const footer = [
  { f: "https://rudu.motivai.tech" },
  { f: "https://motivai.tech" },
  { f: '"Drive Safe, Arrive Safe"' },
  { f: "A MotivAI Product" },
];

const totalPages = 0;

const watermarkText = "Motiv AI";
const watermarkStyles = StyleSheet.create({
  watermark: {
    position: "absolute",
    fontSize: 95,
    opacity: 0.3,
    color: "gray",
    transform: "rotate(-52deg)",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    top: "42%",
    left: "22%",
  },
});

const UserReactToPdf = ({
  safetyPieData,
  osMcuMsuProps,
  aCBProps,
  leaderBoardProps,
  overallDataProps,
  UDMainDashboardTopRowItemsTemp,
  totalSafeRidePer,
  UDMainDashboardSafetyTableData,
  rccProps,
  rideNamesPerCat,
  riskAlertPdf,
  aCBDataProps,
  osDataProps,
  msuPdfDataProps,
  mcuPdfDataProps,
  locationDataPdf,
  // rccFilter
}) => {
  // console.log("riskAlertPdf", riskAlertPdf);
  const [showLoader, setShowLoader] = useState(1);
  const [customerName, setCustomerName] = useState(
    localStorage.getItem(SC_COMPANY_NAME)
  );
  const firstName = localStorage.getItem(SC_LOGIN_USER_FIRST_NAME);
  const userFirstName = localStorage.getItem(SC_USER_FIRST_NAME)
  const [unitName, setUnitName] = useState(
    localStorage.getItem(SC_DIVISION_NAME)
  );
  const [userName, setUserName] = useState(localStorage.getItem(SC_USERNAME));
  const createdDate = localStorage.getItem(SC_REGISTRATION_DATE);
  function displayUserName() {
    if (userName && userName.length >= 4) {
      const firstTwo = userName.slice(0, 2);
      const lastTwo = userName.slice(-2);
      const middleXs = "x".repeat(userName.length - 4);

      const displayedUserName = firstTwo + middleXs + lastTwo;
      return displayedUserName;
    } else {
      return userName;
    }
  }
  const [userType, setuserType] = useState(
    localStorage.getItem(SC_USER_LOGIN_TYPE)
  );

  const header = [
    { title: "Application Name", name: "Motiv AI" },
    { title: "Customer Name", name: customerName },
    { title: "Company Name", name: "Motiv AI" },
    { title: "Customer Unit Name", name: unitName },
  ];



  useEffect(() => {
    setTimeout(() => {
      setShowLoader(0);
    }, 4000);
  }, []);

  if (!osMcuMsuProps || osMcuMsuProps.length < 3) {
    // console.error("osMcuMsuProps is undefined or has less than 3 elements");
    return null;
  }

  const overSpeed = osMcuMsuProps[0].toString();
  const mobileCallUse = osMcuMsuProps[1].toString();
  const mobileScreenUse = osMcuMsuProps[2].toString();

  const originalMax = Math.max(
    parseFloat(overSpeed),
    parseFloat(mobileCallUse),
    parseFloat(mobileScreenUse)
  );

  const convertToHeight = (values, newMax) => {
    return values.map((value) => {
      const numericValue = parseFloat(value);
      // console.log("Numeric value:", numericValue);
      if (!isNaN(numericValue)) {
        const originalMin = 0;
        const newMin = 0;
        let mappedValue =
          ((numericValue - originalMin) / (originalMax - originalMin)) *
            (newMax - newMin) +
          newMin;
        mappedValue = Math.min(Math.max(mappedValue, newMin), newMax);
        mappedValue *= -1;
        return mappedValue;
      } else {
        // console.log("Error: Value is not a number");
        return 0;
      }
    });
  };

  const labels = ["Overspeed", "Mobile Call Use", "Mobile Screen Use"];
  const values = [overSpeed, mobileCallUse, mobileScreenUse];
  const heights = convertToHeight(values, 135);

  const data = values.map((value, index) => ({
    // x: 26 + index * (100 / values.length) + 56,   //this is for if in future they change it to four values or 1 values
    x: 26 + index * 90,
    y: 179.5,
    width: "10",
    height: heights[index],
    fill: "rgb(84, 112, 198)",
    text: value,
    label: labels[index],
  }));

  const fifthTabledata = {
    columns: [
      { title: "Ride", width: "25%" },
      { title: "Risk", width: "25%" },
      { title: "Driving Score", width: "50%" },
    ],
    rowsData: leaderBoardProps.data.map((item) => ({
      rideName: item.rideName,
      risk: item.riskCount,
      drivingScore: item.score,
    })),
  };

  const staticData3 = [
    { title: AnimalCrossing, value: 405 },
    { title: Caution, value: 505 },
    { title: Curves, value: 650 },
    { title: Hill, value: 705 },
    { title: HillDownwards, value: 1000 },
    { title: HillUpwards, value: 250 },
    { title: IcyCondition, value: 150 },
    { title: Intersection, value: 3000 },
    { title: LaneMerge, value: 850 },
    { title: LowGearArea, value: 905 },
    { title: NarrowRoad, value: 350 },
    { title: NoOvertaking, value: 150 },
    { title: PedestrianCrossing, value: 450 },
    { title: Priority, value: 505 },
    { title: RailwayCrossing, value: 650 },
    { title: RiskGrounding, value: 750 },
    { title: RoundAbout, value: 950 },
    { title: SchoolZone, value: 205 },
    { title: SlipperyRoad, value: 150 },
    { title: StopSign, value: 305 },
    { title: TrafficLight, value: 850 },
    { title: Wind, value: 905 },
    { title: WindingRoad, value: 350 },
    { title: Yield, value: 150 },
  ];

  const abbrevationData = [
    [
      { image: AnimalCrossing, text: "Animal Crossing" },
      { image: Caution, text: "Caution" },
      { image: Curves, text: "Curve" },
      { image: Hill, text: "Hill" },
      { image: HillDownwards, text: "Hill Downwards" },
    ],
    [
      { image: HillUpwards, text: "Hill Upwards" },
      { image: IcyCondition, text: "Icy Condition" },
      { image: Intersection, text: "Intersection" },
      { image: LaneMerge, text: "Lane Merge" },
      { image: LowGearArea, text: "Low Gear Area" },
    ],
    [
      { image: NarrowRoad, text: "Narrow Road" },
      { image: NoOvertaking, text: "No Overtaking" },
      { image: PedestrianCrossing, text: "Pedestrian Crossing" },
      { image: Priority, text: "Priority" },
      { image: RailwayCrossing, text: "Railway Crossing" },
    ],
    [
      { image: RiskGrounding, text: "Risk Grounding" },
      { image: RoundAbout, text: "Round About" },
      { image: SchoolZone, text: "School Zone" },
      { image: SlipperyRoad, text: "Slippery Road" },
      { image: StopSign, text: "Stop Sign" },
    ],
    [
      { image: TrafficLight, text: "Traffic Light" },
      { image: Wind, text: "Wind" },
      { image: WindingRoad, text: "Winding Road" },
      { image: Yield, text: "Yield" },
    ],
  ];
  // const { series } = rccProps.data;

  const applyConditionsToStaticData3 = () => {
    return rccProps.data.map((value, index) => {
      let transformedValue;

      if (value > 0) {
        if (value < 2000) {
          transformedValue = ((value / 2000) * 230).toFixed(1);
        } else {
          transformedValue = 230;
        }
      } else {
        transformedValue = 0;
      }

      return {
        key: `1_${index}`,
        x: index * 22 + 5,
        y: 199.5 - transformedValue,
        width: 10,
        height: transformedValue,
        fill: "rgb(84, 112, 198)",
        textY: 199.5 - transformedValue - 5,
        textContent: value || "",
      };
    });
  };

  const transformedData = applyConditionsToStaticData3();

  const lmh = {
    low: ValueFormat.parseAsString(aCBProps.low),
    medium: ValueFormat.parseAsString(aCBProps.medium),
    high: ValueFormat.parseAsString(aCBProps.high),
  };

  const maxLMHValue = Math.max(
    ...Object.values(lmh)
      .flat()
      .map((value) => parseInt(value, 10))
  );

  const generateLMHData = () => {
    const lmhData = [];
    const severityOrder = ["low", "medium", "high"];

    for (let setIndex = 0; setIndex < 3; setIndex++) {
      severityOrder.forEach((severity, severityIndex) => {
       const value = lmh[severity][setIndex];
        const x = 38 + setIndex * (17 * 3 + 30) + severityIndex * 17;
        const y = 179.5;
        const label = value;
        const height = -(value / maxLMHValue) * 135;
        let color = "rgb(54, 115, 53)";

        if (severity === "medium") {
          color = "rgb(251, 191, 0)";
        } else if (severity === "high") {
          color = "rgb(255, 0, 0)";
        }

        lmhData.push({ x, y, label, height, color });
      });
    }

    return lmhData;
  };

  const lmhData = generateLMHData();

  const maxValue = Math.max(
    ...safetyPieData.data.map((item) => item.value || 0)
  );
  const scalingFactor = maxValue > 0 ? 220 / maxValue : 1;

  const safeData = [
    {
      texts:
        "An effective driver stays consistently alert and careful, making safety the main focus throughout the entire ride.",
    },
    {
      texts:
        "A driver stays very attentive and confident, ensuring a high level of safeness but with a sporty attitude throughout the entire journey.",
    },
    {
      texts:
        "A driver who is moderately safe with moderate alertness, making sure the journey is moderately safe all the way.",
    },
    {
      texts:
        "A driver being reckless, holds a moderate level of alertness and high confidence, leading to a journey that is less moderate in safeness throughout the Ride.",
    },
    {
      texts:
        "A Risky Driver with less attention and who is prone to red risks, making journey unsafe throughout the Ride.",
    },
    {
      texts:
        "A Daring Driver being less attentive and Over confident, making the journey highly Unsafe.",
   },
  ];

  const safetyData = overallDataProps.map((item) => item.score);

  const maxSafetyCategoryHeight = 0;
  const minSafetyCategoryHeight = -139.5;
  const safetyCategoryScalingFactor =
    Math.abs(minSafetyCategoryHeight) / (maxSafetyCategoryHeight - 110);

  const safetyCategoryData = [];

  const elementWidth = 10;
  const gap = 85;
  const initialX = 47;
  const textYOffset = 10;

  safetyData.forEach((value, index) => {
    const x = initialX + index * (elementWidth + gap);
    const textX = x + 7;
    const textY = 139.5 - value * safetyCategoryScalingFactor + textYOffset;

    safetyCategoryData.push({
      x,
      y: 139.5,
      color:
        value >= 0 && value <= 80
          ? "#E21212"
          : value >= 81 && value <= 93
          ? "#DEA602"
          : "#06CD91",
      width: elementWidth,
      height: value * safetyCategoryScalingFactor,
      textX,
      textY,
      text: value,
    });
  });
  const maxDataListKeysLength = 6;

  const chunkArray = (array, chunkSize, placeholder) => {
    const result = [];
    let index = 0;

    for (let i = 0; i < Math.ceil(array.length / chunkSize); i++) {
      const chunk = array.slice(index, index + chunkSize);
      while (chunk.length < chunkSize) {
        chunk.push(placeholder);
      }
      result.push(chunk);
      index += chunkSize;
    }

    return result;
  };

  const safeDriver =
    rideNamesPerCat.data?.find((value) => value.type === "SAFE_DRIVER")
      ?.rideNameList || [];
  const sportiveDriver =
    rideNamesPerCat.data?.find((value) => value.type === "SPORTIVE_DRIVER")
      ?.rideNameList || [];
  const quietSafeDriver =
    rideNamesPerCat.data?.find((value) => value.type === "QUIET_SAFE_DRIVER")
      ?.rideNameList || [];
  const recklessDriver =
    rideNamesPerCat.data?.find((value) => value.type === "RECKLESS_DRIVER")
      ?.rideNameList || [];
  const quietdangerousDriver =
    rideNamesPerCat.data?.find(
      (value) => value.type === "QUIET_DANGEROUS_DRIVER"
    )?.rideNameList || [];
  const dangerousDriver =
    rideNamesPerCat.data?.find((value) => value.type === "DANGEROUS_DRIVER")
      ?.rideNameList || [];

  const totalSafeData = [
    ...safeDriver.slice(0, Math.min(safeDriver.length, 30)),
    ...Array(Math.max(0, 30 - safeDriver.length)).fill("-"),
  ];
  const safeDriverData = chunkArray(totalSafeData, 6, "-");

  const totalSafeDataLength = safeDriver.length;
  // Calculate dynamic range for display
  const startRange = totalSafeDataLength > 0 ? 1 : 0;
  const endRange =
    totalSafeDataLength > 0
     ? Math.min(30, totalSafeDataLength)
      : totalSafeDataLength;
  // Dynamic display text
  const showingText =
    totalSafeDataLength > 0
      ? `Showing ${startRange} - ${endRange} out of ${totalSafeDataLength}`
      : "Showing 0 out of 0";

  const totalSportiveData = [
    ...sportiveDriver.slice(0, Math.min(sportiveDriver.length, 30)),
    ...Array(Math.max(0, 30 - sportiveDriver.length)).fill("-"),
  ];
  const sportiveDriverData = chunkArray(totalSportiveData, 6, "-");

  const totalSportiveDataLength = sportiveDriver.length;
  // Calculate dynamic range for display
  const startSportiveRange = totalSportiveDataLength > 0 ? 1 : 0;
  const endSportiveRange =
    totalSportiveDataLength > 0
      ? Math.min(30, totalSportiveDataLength)
      : totalSportiveDataLength;
  // Dynamic display text
  const showingSportiveText =
    totalSportiveDataLength > 0
      ? `Showing ${startSportiveRange} - ${endSportiveRange} out of ${totalSportiveDataLength}`
      : "Showing 0 out of 0";
  const totalQuietSafeData = [
    ...quietSafeDriver.slice(0, Math.min(quietSafeDriver.length, 30)),
    ...Array(Math.max(0, 30 - quietSafeDriver.length)).fill("-"),
  ];
  const quietSafeDriverData = chunkArray(totalQuietSafeData, 6, "-");

  const totalQuietsafeDataLength = quietSafeDriver.length;
  // Calculate dynamic range for display
  const startQuietsafeRange = totalQuietsafeDataLength > 0 ? 1 : 0;
  const endQuietsafeRange =
    totalQuietsafeDataLength > 0
      ? Math.min(30, totalQuietsafeDataLength)
      : totalQuietsafeDataLength;
  // Dynamic display text
  const showingQuietsafeText =
    totalQuietsafeDataLength > 0
      ? `Showing ${startQuietsafeRange} - ${endQuietsafeRange} out of ${totalQuietsafeDataLength}`
      : "Showing 0 out of 0";
  const totalRecklessData = [
    ...recklessDriver.slice(0, Math.min(recklessDriver.length, 30)),
    ...Array(Math.max(0, 30 - recklessDriver.length)).fill("-"),
  ];
  const recklessDriverData = chunkArray(totalRecklessData, 6, "-");

  const totalRecklessDataLength = recklessDriver.length;
  // Calculate dynamic range for display
  const startRecklessRange = totalRecklessDataLength > 0 ? 1 : 0;
  const endRecklessRange =
    totalRecklessDataLength > 0
      ? Math.min(30, totalRecklessDataLength)
      : totalRecklessDataLength;
  // Dynamic display text
  const showingRecklessText =
    totalRecklessDataLength > 0
      ? `Showing ${startRecklessRange} - ${endRecklessRange} out of ${totalRecklessDataLength}`
      : "Showing 0 out of 0";
  const totalQuietDangerousData = [
    ...quietdangerousDriver.slice(0, Math.min(quietdangerousDriver.length, 30)),
    ...Array(Math.max(0, 30 - quietdangerousDriver.length)).fill("-"),
  ];
  const quietdangerousDriverData = chunkArray(totalQuietDangerousData, 6, "-");

  const totalQuietDangerousDataLength = quietdangerousDriver.length;
  // Calculate dynamic range for display
  const startQuietDangerousRange = totalQuietDangerousDataLength > 0 ? 1 : 0;
  const endQuietDangerousRange =
    totalQuietDangerousDataLength > 0
      ? Math.min(30, totalQuietDangerousDataLength)
      : totalQuietDangerousDataLength;
  // Dynamic display text
  const showingQuietDangerousText =
    totalQuietDangerousDataLength > 0
      ? `Showing ${startQuietDangerousRange} - ${endQuietDangerousRange} out of ${totalQuietDangerousDataLength}`
      : "Showing 0 out of 0";

  const totalDangerousData = [
    ...dangerousDriver.slice(0, Math.min(dangerousDriver.length, 30)),
    ...Array(Math.max(0, 30 - dangerousDriver.length)).fill("-"),
  ];
  const dangerousDriverData = chunkArray(totalDangerousData, 6, "-");

  const totalDangerousDataLength = dangerousDriver.length;
  // Calculate dynamic range for display
  const startDangerousRange = totalDangerousDataLength > 0 ? 1 : 0;
  const endDangerousRange =
    totalDangerousDataLength > 0
      ? Math.min(30, totalDangerousDataLength)
      : totalDangerousDataLength;
  // Dynamic display text
  const showingDangerousText =
    totalDangerousDataLength > 0
      ? `Showing ${startDangerousRange} - ${endDangerousRange} out of ${totalDangerousDataLength}`
      : "Showing 0 out of 0";

  const totalSafeDriverRide =
    rideNamesPerCat.data?.find((value) => value.type === "SAFE_DRIVER")
      ?.totalRides || 0;
  const totalSportiveDriverRide =
    rideNamesPerCat.data?.find((value) => value.type === "SPORTIVE_DRIVER")
      ?.totalRides || 0;
  const totalQuietSafeDriverRide =
    rideNamesPerCat.data?.find((value) => value.type === "QUIET_SAFE_DRIVER")
      ?.totalRides || 0;
  const totalRecklessDriverRide =
    rideNamesPerCat.data?.find((value) => value.type === "RECKLESS_DRIVER")
      ?.totalRides || 0;
  const totalQuietDangerousDriverRide =
    rideNamesPerCat.data?.find(
      (value) => value.type === "QUIET_DANGEROUS_DRIVER"
    )?.totalRides || 0;
  const totalDangerousDriverRide =
    rideNamesPerCat.data?.find((value) => value.type === "DANGEROUS_DRIVER")
      ?.totalRides || 0;

  const totalSafeDriverRidePer =
    rideNamesPerCat.data?.find((value) => value.type === "SAFE_DRIVER")
      ?.percentage || 0;
  const totalSportiveDriverRidePer =
    rideNamesPerCat.data?.find((value) => value.type === "SPORTIVE_DRIVER")
      ?.percentage || 0;
  const totalQuietSafeDriverRidePer =
    rideNamesPerCat.data?.find((value) => value.type === "QUIET_SAFE_DRIVER")
      ?.percentage || 0;
  const totalRecklessDriverRidePer =
    rideNamesPerCat.data?.find((value) => value.type === "RECKLESS_DRIVER")
      ?.percentage || 0;
  const totalQuietDangerousDriverRidePer =
    rideNamesPerCat.data?.find(
      (value) => value.type === "QUIET_DANGEROUS_DRIVER"
    )?.percentage || 0;
  const totalDangerousDriverRidePer =
    rideNamesPerCat.data?.find((value) => value.type === "DANGEROUS_DRIVER")
      ?.percentage || 0;

  const columns = ["tripIcon", "alertImage", "rideName", "date", "speed"];
  const columnDisplayNameMap = {
    tripIcon: "Trip Icon",
    alertImage: "Alert Image",
    rideName: "Ride Name",
    date: "Date/Time",
    speed: "Speed",
  };
  const ACBColumn = ["rideName", "date", "type", "subCategoryLevel", "speed"];
  const columnDisplayNameMap1 = {
    rideName: "Ride Name",
    date: "Date/Time",
    type: "Type",
    subCategoryLevel: "Subtype",
    speed: "Speed",
  };

  const columns1 = ["rideName", "date", "speedLimit", "speed"];
  const columnDisplayNameMap2 = {
    rideName: "Ride Name",
    date: "Date/Time",
    speedLimit: "Speed Limit",
    speed: "Speed",
  };
  const columns2 = ["rideName", "date", "speed"];
  const columnDisplayNameMap3 = {
    rideName: "Ride Name",
    date: "Date/Time",
    speed: "Speed",
  };

  const totalRiskAlertDataLength = riskAlertPdf.length;
  const displayRisAlertRange =
    totalRiskAlertDataLength > 0
      ? `Showing 1 - ${Math.min(
          10,
          totalRiskAlertDataLength
        )} out of ${totalRiskAlertDataLength}`
      : "Showing 0 out of 0";

  const totalACBDataLength = aCBDataProps.length;
  const displayACBDataRange =
    totalACBDataLength > 0
      ? `Showing 1 - ${Math.min(
          10,
          totalACBDataLength
        )} out of ${totalACBDataLength}`
      : "Showing 0 out of 0";

  const totalOSDataLength = osDataProps.length;
  const displayOSDataRange =
    totalACBDataLength > 0
      ? `Showing 1 - ${Math.min(
          6,
          totalOSDataLength
        )} out of ${totalOSDataLength}`
      : "Showing 0 out of 0";

  const totalMSUDataLength = msuPdfDataProps.length;
  const   displayMSUDataRange =
    totalMSUDataLength > 0
      ? `Showing 1 - ${Math.min(
          6,
          totalMSUDataLength
        )} out of ${totalMSUDataLength}`
      : "Showing 0 out of 0";

  const totalMCUDataLength = mcuPdfDataProps.length;
  const displayMCUDataRange =
    totalMCUDataLength > 0
      ? `Showing 1 - ${Math.min(
          6,
          totalMCUDataLength
        )} out of ${totalMCUDataLength}`
      : "Showing 0 out of 0";

      const location = useLocation();
  return (
    <React.Fragment>
      {showLoader > 0 ? <LoaderPdf /> : ""}
      <PDFViewer style={styles.PDFViewer}>
        <Document style={styles.Document}>
          <Page size="A4">
            <View style={{ height: "5.3%" }}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                <View style={{ width: "25%", marginBottom: "5px"}}>
                  <Image
                    src={RuduLogo}
                    style={{
                      height: "20px",
                      width: "100px",
                      marginTop: "10px",
                      marginLeft: "30px",
                    }}
                  />
                </View>
                
                <View style={{ width: "77%", marginBottom: "5px"}}>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',

                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                      }}
                    >
                      {header[0].title}: {header[0].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                        marginRight: 30,
                      }}
                    >
                      {header[1].title}: {header[1].name}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {header[2].title}: {header[2].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginRight: 30,
                      }}
                    >
                      {header[3].title}: {header[3].name}
                    </Text>
                  </View>

                </View>

              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                border: "1.5px solid black",
                margin: 30,
                marginLeft: 30,
                marginRight: 30,
                marginBottom: 5,
              }}
            >
              <View
                style={{
                  marginTop: "5px",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{ fontSize: "11px", margin: "3px", textAlign: "left" }}
                >
                  <Text style={[fontBold, { fontSize: 11 }]}>First Name:</Text>{" "}
                  {location.pathname === "/user-dashboard" ? firstName : userFirstName}
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    margin: "3px",
                    textAlign: "left",
                    marginBottom:'10px'
                  }}
                >
                  <Text style={[fontBold, { fontSize: 11 }]}>User Status:</Text>{" "}
                  Active{" "}
                </Text>
              </View>
              <View
                style={{
                  width: "70%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Image
                  src={RuduLogo}
                  style={{ height: "25px", width: "120px" }}
                />
              </View>
              <View
                style={{
                  marginTop: "5px",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontSize: "11px",
                    margin: "3px",
                    textAlign: "right"
                  }}
                >
                  <Text style={[fontBold, { fontSize: 11 }]}>
                    Report Generated On:
                  </Text>{" "}
                  {reversedDateFormat}
                </Text>
                <Text
                  style={{
                    fontSize: "11px",
                    margin: "3px",
                    textAlign: "right", marginBottom:'10px'
                  }}
                >
                  <Text style={[fontBold, { fontSize: 11 }]}>
                    Registered Date:
                  </Text>{" "}
                  {createdDate}
                </Text>
                
              </View>
            </View>

            <View style={{ marginTop: "5px", width: "100%" }}>
              <Text
                style={[
                  fontBold,
                  {
                    fontSize: "12px",
                    margin: "3px",
                    textAlign: "center",
                    color: "#003cb3",
                  },
                ]}
              >
                User Dashboard - Status Report
              </Text>
            </View>

            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>

            <View
              style={{
                flexDirection: "row",
                margin: 30,
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <View
                style={{ color: "black", textAlign: "left", width: "140%" }}
              >
                <Text style={{ fontSize: "11px", marginTop: "5px" }}>
                  Number of rides this year
                </Text>
                <Text
                  style={{ fontSize: "11px", marginTop: "5px", color: "#000" }}
                >
                  Distance traveled this year(Kms)
                </Text>
                <Text style={{ fontSize: "11px", marginTop: "5px" }}>
                  Total hours covered this year(Hrs)
                </Text>
              </View>
              <View
                style={{ color: "black", textAlign: "right", width: "40%" }}
              >
                {UDMainDashboardTopRowItemsTemp.map((item, key) => (
                  <View key={key}>
                    <Text style={{ fontSize: "11px", marginTop: "5px" }}>
                      {item.type === "RIDE" ? item.overallData : null}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        color: "#000",
                      }}
                    >
                      {item.type === "DISTANCE" ? `${item.overallData}` : null}
                    </Text>
                    <Text style={{ fontSize: "11px" }}>
                      {item.type === "DURATION" ? `${item.overallData}` : null}
                    </Text>
                  </View>
                ))}
              </View>

              <View
                style={{
                  borderLeft: "0.5px solid black",
                  marginLeft: "1%",
                  marginRight: "1%",
                }}
              />
              <View
                style={{ color: "black", textAlign: "left", width: "120%" }}
              >
                <Text style={{ fontSize: "11px", marginTop: "5px" }}>
                  Total count of alerts this year(Risk)
                </Text>
                <Text
                  style={{ fontSize: "11px", marginTop: "5px", color: "#000" }}
                >
                  Total count of alerts this year(Other)
                </Text>
                <Text style={{ fontSize: "11px", marginTop: "5px" }}>
                  % of Safe Driver (C1) Rides
                </Text>
              </View>
              {UDMainDashboardTopRowItemsTemp.map((item, key) => (
                <View
                  style={{
                    color: "black",
                    textAlign: "right",
                    width: "15%",
                    marginRight: "2px",
                  }}
                  key={key}
                >
                  <Text style={{ fontSize: "11px", marginTop: "5px" }}>
                    {item.type === "ALERT" ? item.overallData : null}
                  </Text>
                  <Text
                    style={{
                      fontSize: "11px",
                      color: "#000",
                      marginTop: "5px",
                    }}
                  >
                    {item.type === "ALERT" ? item.secondaryData : null}
                  </Text>
                  {key === 3 && (
                    <Text style={{ fontSize: "11px", marginTop: "5px" }}>
                      {`${totalSafeRidePer}%`}
                    </Text>
                  )}
                </View>
              ))}
            </View>
            {/* <View style={{width:'100%', height:'70px',backgroundColor:'tomato',paddingHorizontal:30,flexDirection:'row',justifyContent:'space-between',marginBottom:'30px'}}>
                <View style={{width:'49%' ,flexDirection:'row',justifyContent:'space-between',marginTop: "5px"}}>
                  <View><View>
                  <Text style={{ fontSize: "11px" ,marginTop: "5px"}}>Number of rides this year</Text>
                  <Text style={{ fontSize: "11px" ,marginTop: "10px"}}>Distance traveled this year</Text>
                  <Text style={{ fontSize: "11px" ,marginTop: "10px"}}>Total hours covered this year</Text>
                  </View>
                  </View>

              
                  <View style={{textAlign:'right'}}>
                  {UDMainDashboardTopRowItemsTemp.map((item, key) => ( <Text key={key} style={{ fontSize: "11px", marginTop: "5px" ,textAlign:'right'}}> {item.type === "RIDE" ? item.overallData : null}</Text>))}
                  {UDMainDashboardTopRowItemsTemp.map((item, key) => ( <Text key={key} style={{ fontSize: "11px", marginTop: "5px" ,textAlign:'right'}}> {item.type === "DISTANCE"? `${item.overallData} Kms` : null}</Text>))}
                  {UDMainDashboardTopRowItemsTemp.map((item, key) => ( <Text key={key} style={{ fontSize: "11px", marginTop: "5px" ,textAlign:'right'}}> {item.type === "DURATION" ? `${item.overallData} Hrs` : null}</Text>))}
                  </View>
                   
                </View>
                <View style={{borderLeft: "0.5px solid black",marginLeft: ".1%",marginRight: ".1%",}}/>
                <View style={{width:'49%'}}>
                <View style={{flexDirection:'row',justifyContent:'space-between'}}><Text style={{ fontSize: "11px", marginTop: "5px" }}>Total count of alerts this year(Risk)</Text><Text style={{textAlign:'right'}}>2</Text></View>
                <View style={{flexDirection:'row',justifyContent:'space-between'}}><Text style={{ fontSize: "11px", marginTop: "5px" }}>Total count of alerts this year(Other)</Text><Text style={{textAlign:'right'}}>2</Text></View>
                <View style={{flexDirection:'row',justifyContent:'space-between'}}><Text style={{ fontSize: "11px", marginTop: "5px" }}>% of Safe Driver (C1) Rides</Text><Text style={{textAlign:'right'}}>2</Text></View>
                </View>
            </View> */}

            <View
              style={{
                flexDirection: "row",
                margin: 30,
                marginTop: 5,
                marginBottom: 5,
                fontSize: "11px",
              }}
            >
              <Text style={[fontBold, { fontSize: 11 }]}>
                Driver Performance Overview
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "90%",
                height: "130px",
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: "5px",
                marginBottom: "30px",
                border: "1.5px solid black",
                borderBottom: "none",
              }}
            >
              {[
                "Driver Category",
                "Rides",
                "Distance(Kms)",
                "Duration(Hrs)",
                "Risk Alerts",
              ].map((header, columnIndex) => (
                <View
                  key={columnIndex}
                  style={{
                    flexDirection: "column",
                    flex: 1 / UDMainDashboardSafetyTableData.length,
                  }}
                >
                  <Text
                    style={[
                      fontBold,
                      {
                        border: "1px solid black",
                        padding: "5px",
                        fontSize: "10px",
                        textAlign: "center",
                        borderTop: "none",
                        borderRight:
                          columnIndex !==
                          UDMainDashboardSafetyTableData.length + 1
                            ? "none"
                            : "1px solid black",
                        borderLeft:
                          columnIndex === 0 ? "none" : "1px solid black",
                      },
                    ]}
                  >
                    {header}
                  </Text>
                  {Array.from({ length: maxDataListKeysLength }).map(
                    (_, rowIndex) => (
                      <Text
                        key={rowIndex}
                        style={[
                          styles.tablestyle,
                          {
                            borderBottom:
                              rowIndex === maxDataListKeysLength - 1 &&
                              maxDataListKeysLength > 1
                                ? "1px solid black"
                                : "none",
                            borderRight:
                              columnIndex !==
                              UDMainDashboardSafetyTableData.length + 1
                                ? "none"
                                : "1px solid black",
                            borderLeft:
                              columnIndex === 0 ? "none" : "1px solid black",
                            textAlign: "center",
                          },
                        ]}
                      >
                        {columnIndex === 0
                          ? [
                              "Safe",
                              "Sportive",
                              "Quite Safe",
                              "Reckless",
                              "Quite Dangerous",
                              "Dangerous",
                            ][rowIndex]
                          : UDMainDashboardSafetyTableData[columnIndex - 1]
                              ?.dataListValues[rowIndex] || "0"}
                      </Text>
                    )
                  )}
                </View>
              ))}
            </View>

            <View
              style={{
                height: "346px",
                paddingHorizontal: 30,
                marginBottom: "20px",
              }}
            >
              <View
                style={{
                  height: "10%",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: "8px" }}>
                    <Text style={[fontBold, { fontSize: "11px" }]}>
                      Safety Category
                    </Text>
                  </Text>
                  <Text style={{ fontSize: "10px" }}>
                    Showing for{" "}
                    {(() => {
                      switch (
                        ValueFormat.capitalizeFirstLetterAndFormat(
                          safetyPieData.filter
                        )
                      ) {
                        case "Current month":
                          return UtilDateTime.getCurrentMonth();
                        case "Current year":
                          return UtilDateTime.getCurrentYear();
                        case "Current week":
                          return UtilDateTime.getCurrentWeek();
                        default:
                          return UtilDateTime.getCurrentDay();
                      }
                    })()}
                  </Text>
                </View>

                <View style={{ marginTop: "10px", left: 0 }}>
                  <Svg viewBox="0 0 700 20" width={"100%"}>
                    <Rect
                      x="0"
                      rx="2"
                      ry="2"
                      width="25"
                      height="15"
                      fill="#34c38f"
                    />
                    <Text
                      x="30"
                      y="50%"
                      style={{ height: "15", fontSize: "11px" }}
                   >
                      {" "}
                      Safe(C1)
                    </Text>
                    <Rect
                      x="90"
                      rx="2"
                      ry="2"
                      width="25"
                      height="15"
                      fill="#556ee6"
                    />
                    <Text
                      x="120"
                      y="50%"
                      style={{ height: "15", fontSize: "11px" }}
                    >
                      {" "}
                      Sportive(C2)
                    </Text>
                    <Rect
                      x="200"
                      rx="2"
                      ry="2"
                      width="25"
                      height="15"
                      fill="#f46a6a"
                    />
                    <Text
                      x="230"
                      y="50%"
                      style={{ height: "15", fontSize: "11px" }}
                    >
                      {" "}
                      Quite Safe(C3)
                    </Text>
                    <Rect
                      x="320"
                      rx="2"
                      ry="2"
                      width="25"
                      height="15"
                      fill="#f1b44c"
                    />
                    <Text
                      x="350"
                      y="50%"
                      style={{ height: "15", fontSize: "11px" }}
                    >
                      {" "}
                      Reckless(C4)
                    </Text>
                    <Rect
                      x="430"
                      rx="2"
                      ry="2"
                      width="25"
                      height="15"
                      fill="#34c38f"
                    />
                    <Text
                      x="460"
                      y="50%"
                      style={{ height: "15", fontSize: "11px" }}
                    >
                      {" "}
                      Quite Dangerous(C5)
                    </Text>
                    <Rect
                      x="580"
                      rx="2"
                      ry="2"
                      width="25"
                      height="15"
                      fill="#556ee6"
                    />
                    <Text
                      x="610"
                      y="50%"
                      style={{ height: "15", fontSize: "11px" }}
                    >
                      {" "}
                      Dangerous(C6)
                    </Text>
                  </Svg>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  height: "90%",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Svg width="100%" height="310">
                    <Line
                      x1="0"
                      y1="20"
                      x2="0"
                      y2="250"
                      stroke="black"
                      strokeWidth="1"
                    />
                    <Line
                      x1="0"
                      y1="250"
                      x2="230"
                      y2="250"
                      stroke="black"
                      strokeWidth="1"
                    />

                    {safetyPieData.data?.map((item, index) => (
                      <Rect
                        key={index}
                        x={index * 35 + 20}
                        y={249.5 - (item.value || 0) * scalingFactor}
                        width="10"
                        height={(item.value || 0) * scalingFactor}
                        fill={
                          item.name == "C1"
                            ? "#34c38f"
                            : item.name == "C2"
                            ? "#556ee6"
                            : item.name == "C3"
                            ? "#f46a6a"
                            : item.name == "C4"
                            ? "#f1b44c"
                            : item.name == "C5"
                            ? "#34c38f"
                            : "#556ee6"
                        }
                      />
                    ))}
                    {safetyPieData.data?.map((item, index) => (
                      <Text
                        key={index}
                        x={index * 35 + 25}
                        y={249.5 - (item.value || 0) * scalingFactor - 5}
                        fontSize="10"
                        fill="black"
                        textAnchor="middle"
                      >
                        {item.value || ""}
                      </Text>
                    ))}
                    {safetyPieData.data?.map((item, index) => (
                      <Text
                        key={index}
                        x={index * 35 + 26}
                        y={270 - 5}
                        fontSize="10"
                        fill="black"
                        textAnchor="middle"
                      >
                        {item.name || ""}
                      </Text>
                    ))}
                  </Svg>
                  <View
                    style={{
                      marginTop: "7.5px",
                      marginLeft: "34px",
                      marginTop: "-30px",
                    }}
                  >
                    <Text style={{ fontSize: "9px" }}>
                      Note: X-axis for Category, Y-axis for Count
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <View style={{ width: "100%" }}>
                    <Text
                      x="85"
                      y="50%"
                      style={{
                        fontSize: "10px",
                        textAlign: "justify",
                        marginTop: 19.3,
                      }}
                    >
                      <Text style={[fontBold, { fontSize: 10 }]}>
                        Safe Driver(C1):{" "}
                      </Text>
                      {safeData[0].texts}
                    </Text>
                    <Text
                      x="85"
                      y="50%"
                      style={{
                        fontSize: "10px",
                        textAlign: "justify",
                        marginTop: 5,
                      }}
                    >
                      <Text style={[fontBold, { fontSize: 10 }]}>
                        Sportive Driver(C2):{" "}
                      </Text>
                      {safeData[1].texts}
                    </Text>
                    <Text
                      x="85"
                      y="50%"
                      style={{
                        fontSize: "10px",
                        textAlign: "justify",
                        marginTop: 5,
                      }}
                    >
                      <Text style={[fontBold, { fontSize: 10 }]}>
                        Quite Safe Driver(C3):{" "}
                      </Text>
                      {safeData[2].texts}
                    </Text>
                    <Text
                      x="85"
                      y="50%"
                      style={{
                        fontSize: "10px",
                        textAlign: "justify",
                        marginTop: 5,
                      }}
                    >
                      <Text style={[fontBold, { fontSize: 10 }]}>
                        Reckless Driver(C4):{" "}
                      </Text>{" "}
                      {safeData[3].texts}
                    </Text>
                    <Text
                      x="85"
                      y="50%"
                      style={{
                        fontSize: "10px",
                        textAlign: "justify",
                        marginTop: 5,
                      }}
                    >
                      <Text style={[fontBold, { fontSize: 10 }]}>
                        Quite Dangerous Driver(C5):{" "}
                      </Text>
                      {safeData[4].texts}
                    </Text>
                    <Text
                      x="85"
                      y="50%"
                      style={{
                        fontSize: "10px",
                        textAlign: "justify",
                        marginTop: 5,
                      }}
                    >
                      <Text style={[fontBold, { fontSize: 10 }]}>
                        Dangerous Driver(C6):{" "}
                      </Text>
                      {safeData[5].texts}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                height: "44.5px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>{totalPages + 1}</Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>

          <Page size="A4">
          <View style={{ height: "5.3%" }}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                <View style={{ width: "25%", marginBottom: "5px"}}>
                  <Image
                    src={RuduLogo}
                    style={{
                      height: "20px",
                      width: "100px",
                      marginTop: "10px",
                      marginLeft: "30px",
                    }}
                  />
                </View>
                
                <View style={{ width: "77%", marginBottom: "5px"}}>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',

                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                      }}
                    >
                      {header[0].title}: {header[0].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                        marginRight: 30,
                      }}
                    >
                      {header[1].title}: {header[1].name}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {header[2].title}: {header[2].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginRight: 30,
                      }}
                    >
                      {header[3].title}: {header[3].name}
                    </Text>
                  </View>

                </View>

              </View>
            </View>

            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>
            <View style={{ marginTop: "13px", marginBottom: "5px" }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 30,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[fontBold, { fontSize: 11, marginLeft: "30px" }]}
                  >
                    Risk Alert Count
                  </Text>
                  <Text style={{ fontSize: "8px" }}>
                    {"  "}(
                    <Text style={[fontBold, { fontSize: "8px" }]}>Note:</Text>{" "}
                    Max bar length set to 3000. For values above 3000, consider
                    labels.)
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: "10px" }}>
                    Showing for{" "}
                    {(() => {
                      switch (
                        ValueFormat.capitalizeFirstLetterAndFormat(
                          rccProps.filter
                        )
                      ) {
                        case "Current month":
                          return UtilDateTime.getCurrentMonth();
                        case "Current year":
                          return UtilDateTime.getCurrentYear();
                        case "Current week":
                          return UtilDateTime.getCurrentWeek();
                        default:
                         return UtilDateTime.getCurrentDay();
                      }
                    })()}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "column",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <Svg width="100%" height="220px">
                  <Line
                    x1="0"
                    y1="20"
                    x2="0"
                    y2="200"
                    stroke="black"
                    strokeWidth="1"
                  />
                  <Line
                    x1="0"
                    y1="200"
                    x2="550"
                    y2="200"
                    stroke="black"
                    strokeWidth="1"
                  />
                  {transformedData.map((item) => (
                    <>
                      <Rect {...item} />
                      <Text
                        key={item.key}
                        x={item.x + 5}
                        y={item.textY}
                        style={{ fontSize: "8px" }}
                        fill="black"
                        textAnchor="middle"
                      >
                        {item.textContent}
                      </Text>
                    </>
                  ))}

                  {staticData3.map((item, index) => (
                    <Image
                      key={index}
                      src={item.title}
                      x={index * 22 + 5}
                      y={205}
                      style={{ width: 10, height: 10 }}
                    />
                  ))}
                </Svg>
              </View>
              <View style={{ marginTop: "7.5px", textAlign: "center" }}>
                <Text style={{ fontSize: "9px" }}>
                  Note: X-axis for Category, Y-axis for Count
                </Text>
              </View>
            </View>

            <View style={styles.section}>
              <View style={styles.table}>
                {abbrevationData.map((row, rowIndex) => (
                  <View key={rowIndex} style={styles.tableRow}>
                    {row.map((col, colIndex) => (
                      <View key={colIndex} style={styles.tableCol}>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            src={col.image}
                            style={[
                              styles.imgAbbr,
                              { width: "20px", height: "18px" },
                            ]}
                          />
                          <Text style={styles.cell}>{col.text}</Text>
                        </View>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
              <View
                style={{
                  fontSize: "10px",
                  textAlign: "justify",
                  // marginTop: "5px",
                }}
              >
                {/* <View
                  style={[
                    fontBold,
                    {
                      fontSize: "11px",
                      textAlign: "left",
                      fontWeight: 900,
                    },
                  ]}
                >
                  <Text>Observation</Text>
                </View> */}
                <View style={{ flexDirection: "row", marginBottom: "5px" }}>
                  <Text>
                    <Text style={fontBold}>CAUSE - </Text>
                    "Caution signs and road condition alerts" occur when drivers
                    fail to adhere to traffic conditions, particularly by not
                    controlling the speed of the vehicle appropriately within
                    designated caution zones.
                  </Text>
                </View>
                <View style={{ flexDirection: "row", marginBottom: "5px" }}>
                  <Text>
                    <Text style={fontBold}>COUNTERMEASURE - </Text>
                    To address this, driving at slower speeds, ensuring better
                    control and responsiveness to ever-changing road conditions.
                    Stay informed about weather conditions, especially during
                    adverse weather events. Maintain a greater following
                    distance behind other vehicles to allow for better reaction
                    time in response to unexpected road conditions or sudden
                    stops.
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                height: "230.666px",
                paddingHorizontal: 30,
                marginBottom: "20px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "70%", marginTop: "-40px" }}>
                <View
                  style={[
                    fontBold,
                    {
                      fontSize: "11px",
                    },
                  ]}
                >
                  <Text style={{ marginBottom: "10px" }}>
                    Overall Driving Score
                  </Text>
                  <View
                    style={{
                      marginLeft: "86px",
                      width: "100%",
                      marginBottom: "-10px",
                    }}
                  >
                    <Svg viewBox="0 0 700 20" width={"150%"}>
                      <Rect
                        x="0"
                        rx="2"
                        ry="2"
                        width="25"
                        height="15"
                        fill="#06CD91"
                      />
                      <Text
                        x="30"
                        y="50%"
                        style={{ height: "15", fontSize: "11px" }}
                      >
                        {" "}
                        Low
                      </Text>
                      <Rect
                        x="90"
                        rx="2"
                        ry="2"
                        width="25"
                        height="15"
                        fill="#DEA602"
                      />
                      <Text
                        x="120"
                        y="50%"
                        style={{
                          height: "15",
                          fontSize: "11px",
                          marginLeft: "5px",
                        }}
                      >
                        {" "}
                        Medium
                      </Text>
                      <Rect
                        x="190"
                        rx="2"
                        ry="2"
                        width="25"
                        height="15"
                        fill="#E21212"
                      />
                      <Text
                        x="220"
                        y="50%"
                        style={{ height: "15", fontSize: "11px" }}
                      >
                        {" "}
                        High
                      </Text>
                    </Svg>
                  </View>
                </View>
                <View
                  style={{
                    fontSize: "10px",
                    marginBottom: "13px",
                  }}
                >
                  <Text> </Text>
                </View>
                <View style={{ height: "100%" }}>
                  <Svg>
                    <Line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="140"
                      stroke="black"
                      strokeWidth="1"
                    />
                    <Line
                      x1="0"
                      y1="140"
                      x2="367"
                      y2="140"
                      stroke="black"
                      strokeWidth="1"
                    />

                    {safetyCategoryData.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Rect
                            x={item.x}
                            y={item.y}
                            fill={item.color}
                            width={item.width}
                            height={item.height}
                          />
                          <Text
                            x={item.x + 5}
                            y={item.y + item.height - 4}
                            style={{ fontSize: "10px" }}
                            textAnchor="middle"
                          >
                            {item.text}
                          </Text>
                        </React.Fragment>
                      );
                    })}
                  </Svg>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-127px",
                  }}
                >
                  <Text style={{ fontSize: "10px", marginLeft: "25px" }}>
                    {UtilDateTime.getCurrentDay()}
                  </Text>
                  <Text style={{ fontSize: "10px", marginLeft: "25px" }}>
                    {UtilDateTime.getCurrentWeek()}
                  </Text>
                  <Text style={{ fontSize: "10px", marginLeft: "25px" }}>
                    {UtilDateTime.getCurrentMonth()}
                  </Text>
                  <Text style={{ fontSize: "10px", marginLeft: "57px" }}>
                    {UtilDateTime.getCurrentYear()}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={{ fontSize: "7px", marginLeft: "30px" }}>
                    (Current Day)
                  </Text>
                  <Text style={{ fontSize: "7px", marginLeft: "55px" }}>
                    (Current Week)
                  </Text>
                  <Text style={{ fontSize: "7px", marginLeft: "46px" }}>
                    (Current Month)
                  </Text>
                  <Text style={{ fontSize: "7px", marginLeft: "44px" }}>
                    (Current Year)
                  </Text>
                </View>
                <View style={{ marginTop: "7.5px", textAlign: "center" }}>
                  <Text style={{ fontSize: "9px" }}>
                    Note: X-axis for Time, Y-axis for Score
                  </Text>
                </View>
              </View>
              <View style={{ width: "28%" }}>
                <View
                  style={{
                    marginTop: "34.5px",
                    fontSize: "10px",
                    marginBottom: "5px",
                    textAlign: "justify",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      marginBottom: "5px",
                      marginTop: "-23.5px",
                    }}
                  >
                    <Text>
                      <Text style={fontBold}>AGGREGATE PERFORMANCE - </Text>
                      Each bar represents the cumulative summary of the overall
                      driving score in their respective category, offering a
                      comprehensive view of the driver's performance across
                      different rides.
                    </Text>
                  </View>
                </View>
                {/* </View> */}
              </View>
            </View>

            <View
              style={{
                height: "44.5px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>{totalPages + 2}</Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>

          <Page size="A4">
          <View style={{ height: "5.3%" , marginBottom:20}}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                <View style={{ width: "25%", marginBottom: "5px"}}>
                  <Image
                    src={RuduLogo}
                    style={{
                      height: "20px",
                      width: "100px",
                      marginTop: "10px",
                      marginLeft: "30px",
                    }}
                  />
                </View>
                
                <View style={{ width: "77%", marginBottom: "5px"}}>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',

                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                      }}
                    >
                      {header[0].title}: {header[0].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                        marginRight: 30,
                      }}
                    >
                      {header[1].title}: {header[1].name}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {header[2].title}: {header[2].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginRight: 30,
                      }}
                    >
                      {header[3].title}: {header[3].name}
                    </Text>
                  </View>

                </View>

              </View>
            </View>

            <View
              style={{
                width: "100%",
                height: "230.666px",
                paddingHorizontal: 30,
                marginBottom: "20px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "50%", position: "relative" }}>
                <View
                  style={{
                    fontSize: "11px",
                    marginBottom: "5px",
                    textAlign: "left",
                    fontWeight: 900,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={fontBold}>
                    Overspeed / Mobile Call Use / Mobile Screen Use
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: "10px",
                    marginTop: "0px",
                    marginLeft: "-3px",
                  }}
                >
                  <Text> </Text>
                </View>
                <Svg
                  width="100%"
                  height="100%"
                  style={{ position: "absolute" }}
                >
                  <Line
                    x1="0.5"
                    y1="40"
                    x2="0.5"
                    y2="180"
                    stroke="black"
                    strokeWidth="1"
                  />
                  <Line
                    x1="0"
                    y1="180"
                    x2="262"
                    y2="180"
                    stroke="black"
                    strokeWidth="1"
                  />

                  {data.map((item, index) => (
                    <React.Fragment key={index}>
                      <Rect
                        x={item.x}
                        y={item.y}
                        width={item.width}
                        height={item.height}
                        fill={item.fill}
                      />
                      <Text
                        x={item.x + 5}
                        textAnchor="middle"
                        y={item.y + item.height - 4}
                        fill="black"
                        style={{ fontSize: "10px" }}
                      >
                        {item.text}
                      </Text>
                    </React.Fragment>
                  ))}
                </Svg>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "155px",
                    justifyContent: "space-around",
                  }}
                >
                  {data.map((item, index) => (
                    <Text key={index} style={{ fontSize: "9px" }}>
                      {item.label}
                    </Text>
                  ))}
                </View>
                <View style={{ marginTop: "7.5px", textAlign: "center" }}>
                  <Text style={{ fontSize: "9px" }}>
                    Note: X-axis for Category, Y-axis for Count
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "48%",
                }}
              >
                <Text style={{ fontSize: "10px", textAlign: "right" }}>
                  Showing for{" "}
                  {(() => {
                    switch (
                      ValueFormat.capitalizeFirstLetterAndFormat(
                        rccProps.filter
                      )
                    ) {
                      case "Current month":
                        return UtilDateTime.getCurrentMonth();
                      case "Current year":
                        return UtilDateTime.getCurrentYear();
                      case "Current week":
                        return UtilDateTime.getCurrentWeek();
                      default:
                        return UtilDateTime.getCurrentDay();
                    }
                  })()}
                </Text>
                <View
                  style={{
                    fontSize: "10px",
                    marginTop: "28.5px",
                    marginBottom: "5px",
                    textAlign: "justify",
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: "5px" }}>
                    <Text>
                      <Text style={fontBold}>CAUSE - </Text>
                      These "Distraction Alerts" occur only when driver have
                      thrill-seeking tendencies and have irresponsible actions,
                      particularly in situations where focus is critical, such
                      as while driving.
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <Text>
                      <Text style={fontBold}>COUNTERMEASURE - </Text>
                      To address this, consider activating your phone's "Do Not
                      Disturb" mode or utilizing safety apps that limit
                      notifications while driving.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                height: "230.666px",
                paddingHorizontal: 30,
                marginBottom: "30px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "50%" }}>
                <View
                  style={[
                    fontBold,
                    {
                      fontSize: "11px",
                      marginBottom: "10px",
                      textAlign: "left",
                      marginLeft: "5px",
                      fontWeight: 900,
                    },
                  ]}
                >
                  <Text style={{ marginBottom: "10px" }}>
                    Acceleration / Cornering / Braking
                  </Text>
                  <View
                    style={{
                      marginLeft: "36px",
                      width: "100%",
                      marginBottom: "-10px",
                    }}
                  >
                    <Svg viewBox="0 0 700 20" width={"200%"}>
                      <Rect
                        x="0"
                        rx="2"
                        ry="2"
                        width="25"
                        height="15"
                        fill="rgb(54, 115, 53)"
                      />
                      <Text
                        x="30"
                        y="50%"
                        style={{ height: "15", fontSize: "11px" }}
                      >
                        {" "}
                        Low
                      </Text>
                      <Rect
                        x="90"
                        rx="2"
                        ry="2"
                        width="25"
                        height="15"
                        fill="rgb(251, 191, 0)"
                      />
                      <Text
                        x="120"
                        y="50%"
                        style={{
                          height: "15",
                          fontSize: "11px",
                          marginLeft: "5px",
                        }}
                      >
                        {" "}
                        Medium
                      </Text>
                      <Rect
                        x="190"
                        rx="2"
                        ry="2"
                        width="25"
                        height="15"
                        fill="rgb(255, 0, 0)"
                      />
                      <Text
                        x="220"
                        y="50%"
                        style={{ height: "15", fontSize: "11px" }}
                      >
                        {" "}
                        High
                      </Text>
                    </Svg>
                  </View>
                </View>

                <View
                  style={{
                    fontSize: "10px",
                    marginTop: "0px",
                    marginLeft: "5px",
                    marginBottom: "-33px",
                  }}
                >
                  <Text>{"  "}</Text>
                </View>

                <Svg>
                  <Line
                    x1="8.5"
                    y1="40"
                    x2="8.5"
                    y2="180"
                    stroke="black"
                    strokeWidth="1"
                  />
                  <Line
                    x1="8"
                    y1="180"
                    x2="267"
                    y2="180"
                    stroke="black"
                    strokeWidth="1"
                  />
                  {lmhData.map((item, index) => (
                    <React.Fragment key={index}>
                      <Rect
                        x={item.x}
                        y={item.y}
                        fill={item.color}
                        width="10"
                        height={item.height}
                      />
                      <Text
                        x={item.x + 5}
                        y={item.y + item.height - 4}
                        fill="black"
                        style={{ fontSize: "10px" }}
                        textAnchor="middle"
                      >
                        {item.label}
                      </Text>
                    </React.Fragment>
                  ))}
                </Svg>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-84.5px",
                  }}
                >
                  <Text style={{ fontSize: "9px", marginLeft: "36px" }}>
                    Accelaration
                  </Text>
                  <Text style={{ fontSize: "9px", marginLeft: "36px" }}>
                    Cornering
                  </Text>
                  <Text style={{ fontSize: "9px", marginLeft: "45px" }}>
                    Braking
                  </Text>
                </View>
                <View style={{ marginTop: "7.5px", textAlign: "center" }}>
                  <Text style={{ fontSize: "9px" }}>
                    Note: X-axis for Category, Y-axis for Count
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "48%",
                }}
              >
                <Text style={{ fontSize: "10px", textAlign: "right" }}>
                  Showing for{" "}
                  {(() => {
                    switch (
                      ValueFormat.capitalizeFirstLetterAndFormat(
                        rccProps.filter
                      )
                    ) {
                      case "Current month":
                        return UtilDateTime.getCurrentMonth();
                      case "Current year":
                        return UtilDateTime.getCurrentYear();
                      case "Current week":
                        return UtilDateTime.getCurrentWeek();
                      default:
                        return UtilDateTime.getCurrentDay();
                    }
                  })()}
                </Text>
                <View
                  style={{
                    fontSize: "10px",
                    marginTop: "28.5px",
                    marginBottom: "5px",
                    textAlign: "justify",
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: "5px" }}>
                    <Text>
                      <Text style={fontBold}>CAUSE - </Text>
                      "Rapid Velocity Variation and Intense Maneuvering alerts"
                      occur when drivers do sudden acceleration and deceleration
                      of vehicles at high speed.
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <Text>
                      <Text style={fontBold}>COUNTERMEASURE - </Text>
                      Driving at controlled speed along with gradual increment
                      and decrement of momentum serves as counter measure,
                      instead of doing it abruptly.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                height: "230.666px",
                paddingHorizontal: 30,
                marginBottom: "20px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "50%" }}>
                <View
                  style={{
                    fontSize: "11px",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "11px",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={fontBold}>Leaderboard </Text>
                    <Text style={{ fontSize: "8px" }}>(Last 5 Rides)</Text>
                  </View>
                </View>
                <View
                  style={{
                    borderTop: "1px solid black",
                    height: "100%",
                  }}
                >
                  <View style={{ flexDirection: "row", height: "30px" }}>
                    {fifthTabledata.columns.map((column, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          width: column.width,
                          borderRight: "1px solid black",
                          borderLeft:
                            colIndex === 0 ? "1px solid black" : "none",
                          borderBottom: "1px solid black",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "10px",
                        }}
                      >
                        <Text style={fontBold}>{column.title}</Text>
                      </View>
                    ))}
                  </View>

                  {Array.from({ length: 5 }).map((_, rowIndex) => {
                    const rowdata = fifthTabledata.rowsData[rowIndex] || {};
                    return (
                      <View
                        key={rowIndex}
                        style={{
                          flexDirection: "row",
                          height: 22,
                          borderBottom:
                            rowIndex === 4
                              ? "1px solid black"
                              : "1px dashed black",
                        }}
                      >
                        {fifthTabledata.columns.map((column, colIndex) => (
                          <View
                            key={colIndex}
                            style={{
                              width: column.width,
                              borderLeft:
                                colIndex === 0 ? "1px solid black" : "none",
                              borderRight:
                                colIndex === 2
                                  ? "1px solid black"
                                  : "1px dashed black",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 10,
                            }}
                          >
                            {colIndex === 2 ? (
                              <View
                                style={{
                                  width: "100%",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <View
                                  style={{ width: "20%", textAlign: "center" }}
                                >
                                  <Text style={{ fontSize: 9 }}>
                                    {rowdata.rideName !== undefined ||
                                    (rowdata.rideName !== null &&
                                      rowdata.drivingScore)
                                      ? rowdata.drivingScore
                                      : null}
                                  </Text>
                                </View>
                                <View style={{ width: "75%" }}>
                                  {rowdata.drivingScore !== undefined ? (
                                    <View style={{ width: "75%" }}>
                                      <Svg viewBox="0 0 700 20">
                                        <Rect
                                          x="0"
                                          rx="2"
                                          ry="2"
                                          width="100%"
                                          height="25"
                                          fill="rgb(0, 149, 255)"
                                        />
                                        <Rect
                                         x="0"
                                          rx="2"
                                          ry="2"
                                          width={`${
                                            rowdata.drivingScore || 0
                                          }%`}
                                          height="25"
                                          fill="#E4A11B"
                                        />
                                      </Svg>
                                    </View>
                                  ) : (
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        marginRight: "20%",
                                      }}
                                    >
                                      -
                                    </Text>
                                  )}
                                </View>
                              </View>
                            ) : (
                              <Text>
                                {colIndex === 1
                                  ? rowdata.risk !== undefined
                                    ? rowdata.risk
                                    : "-"
                                  : rowdata.rideName !== undefined
                                  ? rowdata.rideName
                                  : "-"}
                              </Text>
                            )}
                          </View>
                        ))}
                      </View>
                    );
                  })}
                </View>
              </View>

              <View
                style={{
                  width: "48%",
                }}
              >
                <View>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    Showing for{" "}
                    {(() => {
                      switch (
                        // ValueFormat.capitalizeFirstLetterAndFormat(
                        leaderBoardProps.filter
                      ) {
                        // )
                        case "allLeaderBoard":
                          return "All";
                        case "workLeaderBoard":
                          return "Work";
                        case "personalLeaderBoard":
                          return "Personal";
                      }
                    })()}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: "10px",
                    marginTop: "10.4px",
                    textAlign: "justify",
                  }}
                >
                  <View>
                    <Text>
                      <Text style={fontBold}>
                        SNAPSHOT OF RECENT PERFORMANCE -{" "}
                      </Text>
                      The leaderboard serves as a snapshot, encapsulating the
                      last 5 rides and underlining the trend where
                      best-performing rides consistently align with the superior
                      driving scores and less risks.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>

            <View
              style={{
                height: "44.5px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>{totalPages + 3}</Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>

          <Page size="A4">
          <View style={{ height: "5.3%",marginBottom:20 }}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                <View style={{ width: "25%", marginBottom: "5px"}}>
                  <Image
                    src={RuduLogo}
                    style={{
                      height: "20px",
                      width: "100px",
                      marginTop: "10px",
                      marginLeft: "30px",
                    }}
                  />
                </View>
                
                <View style={{ width: "77%", marginBottom: "5px"}}>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',

                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                      }}
                    >
                      {header[0].title}: {header[0].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                        marginRight: 30,
                      }}
                    >
                      {header[1].title}: {header[1].name}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {header[2].title}: {header[2].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginRight: 30,
                      }}
                    >
                      {header[3].title}: {header[3].name}
                    </Text>
                  </View>

                </View>

              </View>
            </View>

            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>

            <View
              style={{
                paddingHorizontal: 30,
                marginBottom: "20px",
                marginBottom: "20px",
              }}
            >
              <View style={{ width: "100%", height: "36px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={fontBold}>Safe Driver </Text>
                    <Text style={{ fontSize: "8px" }}>
                      {`(C1) (${showingText})`}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>
                      Showing for{" "}
                      {(() => {
                        switch (
                          ValueFormat.capitalizeFirstLetterAndFormat(
                            rideNamesPerCat.filter
                          )
                        ) {
                          case "Current month":
                            return UtilDateTime.getCurrentMonth();
                          case "Current year":
                            return UtilDateTime.getCurrentYear();
                          case "Current week":
                            return UtilDateTime.getCurrentWeek();
                          default:
                            return UtilDateTime.getCurrentDay();
                        }
                      })()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>No of rides</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {totalSafeDriverRide}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "2%",
                      fontSize: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Text>|</Text>
                  </View>

                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>Percentage</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {isNaN(totalSafeDriverRidePer)
                          ? "0%"
                          : `${totalSafeDriverRidePer}%`}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  marginBottom: "20px",
                  height: "auto",
                  flexDirection: "column",
                }}
              >
                {safeDriverData.map((row, rowIndex) => (
                  <View
                    key={rowIndex}
                    style={{ flexDirection: "row", height: "25px" }}
                  >
                    {row.map((ride, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "8px",
                          borderBottom:
                            rowIndex === safeDriverData.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                          borderRight:
                            colIndex === row.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                        }}
                      >
                        {ride !== "-" ? (
                          <>
                            <Text break>{`${ride.rideName}`}</Text>
                            {/* <Text break>
                              {ride.selfConfidenceValue} |{" "}
                              {ride.anticipateDriveValue}
                            </Text> */}
                          </>
                        ) : (
                          <Text>-</Text>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>

              <View
                style={{
                  fontSize: "10px",
                  height: "40px",
                  textAlign: "justify",
                }}
              >
                <Text>
                  The table provides a concise summary of the most recent 30
                  rides from SAFE DRIVER CATEGORY, presenting driving scores,
                  anticipation scores and self-confidence scores. This snapshot
                  offers an overview of the driver's recent performance across
                  these key metrics for all recent 30 rides
                </Text>
              </View>
            </View>

            <View style={{ paddingHorizontal: 30, marginBottom: "20px" }}>
              <View style={{ width: "100%", height: "36px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={fontBold}>Sportive Driver </Text>
                    <Text style={{ fontSize: "8px" }}>
                      {`(C2) (${showingSportiveText})`}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>
                      Showing for{" "}
                      {(() => {
                        switch (
                          ValueFormat.capitalizeFirstLetterAndFormat(
                            rideNamesPerCat.filter
                          )
                        ) {
                         case "Current month":
                            return UtilDateTime.getCurrentMonth();
                          case "Current year":
                            return UtilDateTime.getCurrentYear();
                          case "Current week":
                            return UtilDateTime.getCurrentWeek();
                          default:
                            return UtilDateTime.getCurrentDay();
                        }
                      })()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>No of rides</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {totalSportiveDriverRide}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "2%",
                      fontSize: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Text>|</Text>
                  </View>

                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>Percentage</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {isNaN(totalSportiveDriverRidePer)
                          ? "0%"
                          : `${totalSportiveDriverRidePer}%`}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  marginBottom: "20px",
                  height: "auto",
                  flexDirection: "column",
                }}
              >
                {sportiveDriverData.map((row, rowIndex) => (
                  <View
                    key={rowIndex}
                    style={{ flexDirection: "row", height: "25px" }}
                  >
                    {row.map((ride, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "8px",
                          borderBottom:
                            rowIndex === sportiveDriverData.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                          borderRight:
                            colIndex === row.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                        }}
                      >
                        {ride !== "-" ? (
                          <>
                            <Text break>{`${ride.rideName}`}</Text>
                            {/* <Text break>
                              {ride.selfConfidenceValue} |{" "}
                              {ride.anticipateDriveValue}
                            </Text> */}
                          </>
                        ) : (
                          <Text>-</Text>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>

              <View
                style={{
                  fontSize: "10px",
                  height: "40px",
                  textAlign: "justify",
                }}
              >
                <Text>
                  The table provides a concise summary of the most recent 30
                  rides from SPORTIVE DRIVER CATEGORY, presenting driving
                  scores, anticipation scores and self-confidence scores. This
                  snapshot offers an overview of the driver's recent performance
                  across these key metrics for all recent 30 rides.
                </Text>
              </View>
            </View>

            <View style={{ paddingHorizontal: 30, marginBottom: "20px" }}>
              <View style={{ width: "100%", height: "36px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={fontBold}>Quite Safe Driver </Text>
                    <Text style={{ fontSize: "8px" }}>
                      {`(C3) (${showingQuietsafeText})`}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>
                      Showing for{" "}
                      {(() => {
                        switch (
                          ValueFormat.capitalizeFirstLetterAndFormat(
                            rideNamesPerCat.filter
                          )
                        ) {
                          case "Current month":
                            return UtilDateTime.getCurrentMonth();
                          case "Current year":
                            return UtilDateTime.getCurrentYear();
                          case "Current week":
                            return UtilDateTime.getCurrentWeek();
                          default:
                            return UtilDateTime.getCurrentDay();
                        }
                      })()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>No of rides</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {totalQuietSafeDriverRide}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "2%",
                      fontSize: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Text>|</Text>
                  </View>

                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>Percentage</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {isNaN(totalQuietSafeDriverRidePer)
                          ? "0%"
                          : `${totalQuietSafeDriverRidePer}%`}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  marginBottom: "20px",
                  height: "auto",
                  flexDirection: "column",
                }}
              >
                {quietSafeDriverData.map((row, rowIndex) => (
                  <View
                    key={rowIndex}
                    style={{ flexDirection: "row", height: "25px" }}
                  >
                    {row.map((ride, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "8px",
                          borderBottom:
                            rowIndex === quietSafeDriverData.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                          borderRight:
                            colIndex === row.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                        }}
                      >
                        {ride !== "-" ? (
                          <>
                            <Text break>{`${ride.rideName}`}</Text>
                            {/* <Text break>
                              {ride.selfConfidenceValue} |{" "}
                              {ride.anticipateDriveValue}
                            </Text> */}
                          </>
                        ) : (
                          <Text>-</Text>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>

              <View
                style={{
                  fontSize: "10px",
                  height: "40px",
                  textAlign: "justify",
                }}
              >
                <Text>
                  The table provides a concise summary of the most recent 30
                  rides from QUIET SAFE DRIVER CATEGORY, presenting driving
                  scores, anticipation scores and self-confidence scores. This
                  snapshot offers an overview of the driver's recent performance
                  across these key metrics for all recent 30 rides.
                </Text>
              </View>
            </View>

            <View
              style={{
                height: "44.5px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>{totalPages + 4}</Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>

          <Page size="A4">
          <View style={{ height: "5.3%" , marginBottom:20}}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                <View style={{ width: "25%", marginBottom: "5px"}}>
                  <Image
                    src={RuduLogo}
                    style={{
                     height: "20px",
                      width: "100px",
                      marginTop: "10px",
                      marginLeft: "30px",
                    }}
                  />
                </View>
                
                <View style={{ width: "77%", marginBottom: "5px"}}>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',

                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                      }}
                    >
                      {header[0].title}: {header[0].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                        marginRight: 30,
                      }}
                    >
                      {header[1].title}: {header[1].name}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {header[2].title}: {header[2].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginRight: 30,
                      }}
                    >
                      {header[3].title}: {header[3].name}
                    </Text>
                  </View>

                </View>

              </View>
            </View>

            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>

            <View
              style={{
                paddingHorizontal: 30,
                marginBottom: "20px",
                marginBottom: "20px",
              }}
            >
              <View style={{ width: "100%", height: "36px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={fontBold}>Reckless Driver </Text>
                    <Text style={{ fontSize: "8px" }}>
                      {`(C4) (${showingRecklessText})`}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>
                      Showing for{" "}
                      {(() => {
                        switch (
                          ValueFormat.capitalizeFirstLetterAndFormat(
                            rideNamesPerCat.filter
                         )
                        ) {
                          case "Current month":
                            return UtilDateTime.getCurrentMonth();
                          case "Current year":
                            return UtilDateTime.getCurrentYear();
                          case "Current week":
                            return UtilDateTime.getCurrentWeek();
                          default:
                            return UtilDateTime.getCurrentDay();
                        }
                      })()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>No of rides</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {totalRecklessDriverRide}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "2%",
                      fontSize: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Text>|</Text>
                  </View>

                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>Percentage</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {isNaN(totalRecklessDriverRidePer)
                          ? "0%"
                          : `${totalRecklessDriverRidePer}%`}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  marginBottom: "20px",
                  height: "auto",
                  flexDirection: "column",
                }}
              >
                {recklessDriverData.map((row, rowIndex) => (
                  <View
                    key={rowIndex}
                    style={{ flexDirection: "row", height: "25px" }}
                  >
                    {row.map((ride, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "8px",
                          borderBottom:
                            rowIndex === recklessDriverData.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                          borderRight:
                            colIndex === row.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                        }}
                      >
                        {ride !== "-" ? (
                          <>
                            <Text break>{`${ride.rideName}`}</Text>
                            {/* <Text break>
                              {ride.selfConfidenceValue} |{" "}
                              {ride.anticipateDriveValue}
                           </Text> */}
                          </>
                        ) : (
                          <Text>-</Text>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>

              <View style={{ fontSize: "10px", height: "40px" }}>
                <Text>
                  The table provides a concise summary of the most recent 30
                  rides from RECKLESS DRIVER CATEGORY, presenting driving
                  scores, anticipation scores and self-confidence scores. This
                  snapshot offers an overview of the driver's recent performance
                  across these key metrics for all recent 30 rides.
                </Text>
              </View>
            </View>

            <View style={{ paddingHorizontal: 30, marginBottom: "20px" }}>
              <View style={{ width: "100%", height: "36px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                 <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={fontBold}>Quite Dangerous </Text>
                    <Text style={{ fontSize: "8px" }}>
                      {`(C5) (${showingQuietDangerousText})`}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>
                      Showing for{" "}
                      {(() => {
                        switch (
                          ValueFormat.capitalizeFirstLetterAndFormat(
                            rideNamesPerCat.filter
                          )
                        ) {
                          case "Current month":
                            return UtilDateTime.getCurrentMonth();
                          case "Current year":
                            return UtilDateTime.getCurrentYear();
                          case "Current week":
                            return UtilDateTime.getCurrentWeek();
                          default:
                            return UtilDateTime.getCurrentDay();
                        }
                      })()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>No of rides</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {totalQuietDangerousDriverRide}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "2%",
                      fontSize: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Text>|</Text>
                  </View>

                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>Percentage</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {" "}
                        {isNaN(totalQuietDangerousDriverRidePer)
                          ? "0%"
                          : `${totalQuietDangerousDriverRidePer}%`}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  marginBottom: "20px",
                  height: "auto",
                  flexDirection: "column",
                }}
              >
                {quietdangerousDriverData.map((row, rowIndex) => (
                  <View
                    key={rowIndex}
                    style={{ flexDirection: "row", height: "25px" }}
                  >
                    {row.map((ride, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "8px",
                          borderBottom:
                            rowIndex === quietdangerousDriverData.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                          borderRight:
                            colIndex === row.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                        }}
                      >
                        {ride !== "-" ? (
                          <>
                            <Text break>{`${ride.rideName}`}</Text>
                            {/* <Text break>
                              {ride.selfConfidenceValue} |{" "}
                              {ride.anticipateDriveValue}
                            </Text> */}
                          </>
                        ) : (
                          <Text>-</Text>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>

              <View style={{ fontSize: "10px", height: "40px" }}>
                <Text>
                  The table provides a concise summary of the most recent 30
                  rides from QUITE DANGEROUS DRIVER CATEGORY, presenting driving
                  scores, anticipation scores and self-confidence scores. This
                  snapshot offers an overview of the driver's recent performance
                  across these key metrics for all recent 30 rides.
                </Text>
              </View>
            </View>

            <View style={{ paddingHorizontal: 30, marginBottom: "20px" }}>
              <View style={{ width: "100%", height: "36px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={fontBold}>Dangerous Driver </Text>
                    <Text style={{ fontSize: "8px" }}>
                      {`(C6) (${showingDangerousText})`}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontSize: "11px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>
                      Showing for{" "}
                      {(() => {
                        switch (
                          ValueFormat.capitalizeFirstLetterAndFormat(
                            rideNamesPerCat.filter
                          )
                        ) {
                          case "Current month":
                            return UtilDateTime.getCurrentMonth();
                          case "Current year":
                            return UtilDateTime.getCurrentYear();
                          case "Current week":
                            return UtilDateTime.getCurrentWeek();
                          default:
                            return UtilDateTime.getCurrentDay();
                        }
                      })()}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>No of rides</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {totalDangerousDriverRide}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "2%",
                      fontSize: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Text>|</Text>
                  </View>

                  <View
                    style={{
                      width: "49%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "10px" }}>Percentage</Text>
                    </View>
                    <View>
                      <Text style={{ fontSize: "10px" }}>
                        {" "}
                        {isNaN(totalDangerousDriverRidePer)
                          ? "0%"
                          : `${totalDangerousDriverRidePer}%`}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  marginBottom: "20px",
                  height: "auto",
                  flexDirection: "column",
                }}
              >
                {dangerousDriverData.map((row, rowIndex) => (
                  <View
                    key={rowIndex}
                    style={{ flexDirection: "row", height: "25px" }}
                  >
                    {row.map((ride, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "8px",
                          borderBottom:
                            rowIndex === dangerousDriverData.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                          borderRight:
                            colIndex === row.length - 1
                              ? "1px solid black"
                              : "1px dashed black",
                        }}
                      >
                        {ride !== "-" ? (
                          <>
                            <Text break>{`${ride.rideName}`}</Text>
                            {/* <Text break>
                              {ride.selfConfidenceValue} |{" "}
                              {ride.anticipateDriveValue}
                            </Text> */}
                          </>
                        ) : (
                          <Text>-</Text>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>

              <View style={{ fontSize: "10px", height: "40px" }}>
                <Text>
                  The table provides a concise summary of the most recent 30
                  rides from DANGEROUS DRIVER CATEGORY, presenting driving
                  scores, anticipation scores and self-confidence scores. This
                  snapshot offers an overview of the driver's recent performance
                  across these key metrics for all recent 30 rides.
                </Text>
              </View>
            </View>
            <View
              style={{
                height: "44.5px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>{totalPages + 5}</Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>

          <Page size="A4">
          <View style={{ height: "5.3%" , marginBottom:20}}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                <View style={{ width: "25%", marginBottom: "5px"}}>
                  <Image
                    src={RuduLogo}
                    style={{
                      height: "20px",
                      width: "100px",
                      marginTop: "10px",
                      marginLeft: "30px",
                    }}
                  />
                </View>
                
                <View style={{ width: "77%", marginBottom: "5px"}}>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',

                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                      }}
                    >
                      {header[0].title}: {header[0].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                        marginRight: 30,
                      }}
                    >
                      {header[1].title}: {header[1].name}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {header[2].title}: {header[2].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginRight: 30,
                      }}
                    >
                      {header[3].title}: {header[3].name}
                    </Text>
                  </View>

                </View>

              </View>
            </View>

            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>

            <View
              style={{
                paddingHorizontal: 30,
                height: "732px",
                marginBottom: "15px",
              }}
            >
              <View style={{ width: "100%", marginBottom: "20px" }}>
                <View
                  style={{
                    width: "100%",
                    height: "30px",
                    marginBottom: "10px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[fontBold, { fontSize: "10px" }]}>
                      Risk Alert
                    </Text>
                    <Text style={{ fontSize: "10px" }}>
                      {" "}
                      {`(${displayRisAlertRange})`}
                    </Text>
                  </View>
                  <View>
                    <Text style={{ fontSize: "10px" }}>
                      Showing for {UtilDateTime.getCurrentYear()}
                    </Text>
                  </View>
                </View>

                <View>
                  <View style={{ flexDirection: "row", height: "40px" }}>
                    {columns.map((column, index) => (
                      <View
                        key={index}
                        style={{
                          flex: column === "date" ? 1.8 : 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "9px",
                          border: "1px solid black",
                          // borderBottom: "1px solid black",
                          borderLeft: index === 0 ? "1px solid black" : "none",
                          // borderTop: "1px solid black",
                          // borderLeft: index === 0 ? "1px solid black" : "none",
                          // borderTop: "1px solid black",
                          // borderLeft: "1px solid black",
                        }}
                      >
                        <Text style={fontBold} break>
                          {columnDisplayNameMap[column]}
                        </Text>
                      </View>
                    ))}
                  </View>

                  {/* {Array.from({ length: 10 }).map((_, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{ flexDirection: "row", height: "40px" }}
                    >
                      {columns.map((column, colIndex) => (
                        <View
                          key={colIndex}
                          style={{
                            flex: column === "date" ? 1.8 : 1,
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "8px",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderLeft:
                              colIndex === 0 ? "1px solid black" : "none",
                          }}
                        >
                          {column === "alertImage" ? (
                            <Image
                              src={require(`../../assets/images/${riskAlertPdf[rowIndex][column]}`)}
                              style={{ width: "15px", height: "15px" }}
                            />
                          ) : column === "tripIcon" ? (
                            <Image
                              src={require(`../../assets/images/TripIcons/${riskAlertPdf[rowIndex][column]}`)}
                              style={{ width: "5px", height: "5px" }}
                            />
                          ) : (
                            // Render text for other columns
                            <Text style={{ fontSize: "8px" }} break>
                              {riskAlertPdf[rowIndex] &&
                              riskAlertPdf[rowIndex][column] !== undefined
                                ? riskAlertPdf[rowIndex][column]
                                : "-"}
                            </Text>
                          )}
                        </View>
                      ))}
                    </View>
                  ))} */}
                  {Array.from({ length: 10 }).map((_, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{
                        flexDirection: "row",
                        height: "40px",
                        borderBottom:
                          rowIndex === 9
                            ? "1px solid black"
                            : "1px dashed black",
                      }}
                    >
                      {columns.map((column, colIndex) => {
                        const isFirstColumn = colIndex === 0;
                        const isLastColumn = colIndex === columns.length - 1;

                        const cellData =
                          riskAlertPdf[rowIndex] &&
                          riskAlertPdf[rowIndex][column] !== undefined
                            ? riskAlertPdf[rowIndex][column]
                            : null;

                        const borderStyles = {
                          flex: column === "date" ? 1.8 : 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "8px",
                          borderLeft: isFirstColumn
                            ? "1px solid black"
                            : "1px dashed black",
                          borderRight: isLastColumn
                            ? "1px solid black"
                            : "none",
                        };

                        return (
                          <View key={colIndex} style={borderStyles}>
                            {column === "alertImage" && cellData ? (
                              <Image
                                src={require(`../../assets/images/${cellData}`)}
                                style={{ width: "15px", height: "15px" }}
                              />
                            ) : column === "tripIcon" && cellData ? (
                              <Image
                                src={require(`../../assets/images/TripIcons/${cellData}`)}
                                style={{ width: "5px", height: "5px" }}
                              />
                            ) : (
                              <Text style={{ fontSize: "8px" }} break>
                                {cellData !== null && cellData !== undefined
                                  ? cellData
                                  : "-"}
                              </Text>
                            )}
                          </View>
                        );
                      })}
                    </View>
                  ))}
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  height: "50px",
                  marginBottom: "20px",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "10px",
                  }}
                >
                  <Text>
                    The table provides a concise summary of the most recent 10
                    rides and consists of 'Caution signs and road condition
                    alerts,' summarizing the exact times of each alert
                    occurrence along with their corresponding speeds and
                    timestamps
                  </Text>
                </View>
              </View>

              <View style={{ width: "100%", marginBottom: "20px" }}>
                <View
                  style={{
                    width: "100%",
                    height: "30px",
                    marginBottom: "10px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[fontBold, { fontSize: "10px" }]}>
                      Acceleration / Cornering / Braking
                    </Text>
                    <Text style={{ fontSize: "10px" }}>
                      {" "}
                      {`(${displayACBDataRange})`}
                    </Text>
                  </View>
                  <View>
                    <Text style={{ fontSize: "10px" }}>
                      Showing for {UtilDateTime.getCurrentYear()}
                    </Text>
                  </View>
                </View>
                <View>
                  <View style={{ flexDirection: "row", height: "40px" }}>
                    {ACBColumn.map((column, index) => (
                      <View
                        key={index}
                        style={{
                          flex: column === "date" ? 1.8 : 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "9px",
                          // borderBottom: "1px solid black",
                          // borderRight: "1px solid black",
                          // borderTop: "1px solid black",
                          // borderLeft: index === 0 ? "1px solid black" : "none",
                          border: "1px solid black",
                          // borderBottom: "1px solid black",
                          borderLeft: index === 0 ? "1px solid black" : "none",
                        }}
                      >
                       <Text style={fontBold} break>
                          {columnDisplayNameMap1[column]}
                        </Text>
                      </View>
                    ))}
                  </View>

                  {Array.from({ length: 10 }).map((_, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{
                        flexDirection: "row",
                        height: "40px",
                        borderBottom:
                         rowIndex === 9
                            ? "1px solid black"
                            : "1px dashed black",
                      }}
                    >
                      {ACBColumn.map((column, colIndex) => (
                        <View
                          key={colIndex}
                          style={{
                            flex: column === "date" ? 1.8 : 1,
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "8px",
                            // borderBottom: "1px solid black",
                            // borderRight: "1px solid black",
                            // borderLeft:
                            //   colIndex === 0 ? "1px solid black" : "none",
                            borderLeft:
                              colIndex === 0
                                ? "1px solid black"
                                : "1px dashed black",
                            borderRight:
                              colIndex === column.length - 1
                                ? "1px solid black"
                                : "none",
                          }}
                        >
                          <Text style={{ fontSize: "8px" }} break>
                            {aCBDataProps[rowIndex] &&
                            aCBDataProps[rowIndex][column] !== undefined
                              ? aCBDataProps[rowIndex][column].includes(
                                  "Severe"
                                )
                                ? aCBDataProps[rowIndex][column].replace(
                                    "Severe",
                                    ""
                                  )
                                : aCBDataProps[rowIndex][column]
                                    .charAt(0)
                                    .toUpperCase() +
                                  aCBDataProps[rowIndex][column]
                                    .slice(1)
                                    .toLowerCase()
                              : "-"}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ))}
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  height: "50px",
                  marginBottom: "20px",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "10px",
                  }}
                >
                  {" "}
                  <Text>
                    The table provides a concise summary of the most recent 10
                    rides and consists of alerts for 'Rapid Velocity Variation'
                    and 'Intense Maneuvering,' summarizing the exact times of
                    each alert occurrence along with the corresponding speeds.
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                height: "44.5px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>{totalPages + 6}</Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>

          <Page size="A4">
          <View style={{ height: "5.3%", marginBottom:20 }}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                <View style={{ width: "25%", marginBottom: "5px"}}>
                  <Image
                    src={RuduLogo}
                    style={{
                      height: "20px",
                      width: "100px",
                      marginTop: "10px",
                      marginLeft: "30px",
                    }}
                  />
                </View>
                
                <View style={{ width: "77%", marginBottom: "5px"}}>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',

                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                      }}
                    >
                      {header[0].title}: {header[0].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginTop: "5px",
                        marginRight: 30,
                      }}
                    >
                      {header[1].title}: {header[1].name}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: "5px",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent:'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {header[2].title}: {header[2].name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "11px",
                        marginRight: 30,
                      }}
                    >
                      {header[3].title}: {header[3].name}
                    </Text>
                  </View>

                </View>

              </View>
            </View>

            <Text style={{ ...watermarkStyles.watermark }}>
              {watermarkText}
            </Text>

            <View
              style={{
                paddingHorizontal: 30,
                height: "200px",
                marginBottom: "15px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "49.5%" }}>
                <View
                  style={{
                    width: "100%",
                    height: "20px",
                    marginBottom: "5px",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text style={[fontBold, { fontSize: "10px" }]}>
                    Overspeed
                  </Text>
                  <Text style={{ fontSize: "10px" }}>
                    {" "}
                    {`(${displayOSDataRange})`}
                  </Text>
                </View>
                <View>
                  <View style={{ flexDirection: "row", height: "25px" }}>
                    {columns1.map((column, index) => (
                      <View
                        key={index}
                        style={{
                          flex: column === "date" ? 1.5 : 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "9px",
                          // borderBottom: "1px solid black",
                          // borderRight: "1px solid black",
                          // borderTop: "1px solid black",
                          // borderLeft: index === 0 ? "1px solid black" : "none",
                          border: "1px solid black",
                          // borderBottom: "1px solid black",
                          borderLeft: index === 0 ? "1px solid black" : "none",
                        }}
                      >
                        <Text style={fontBold} break>
                          {columnDisplayNameMap2[column]}
                        </Text>
                      </View>
                    ))}
                  </View>

                  {Array.from({ length: 6 }).map((_, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{
                        flexDirection: "row",
                        height: "25px",
                        borderBottom:
                          rowIndex === 5
                            ? "1px solid black"
                            : "1px dashed black",
                      }}
                    >
                      {columns1.map((column, colIndex) => (
                        <View
                          key={colIndex}
                          style={{
                            flex: column === "date" ? 1.5 : 1,
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "8px",
                            // borderBottom: "1px solid black",
                            // borderRight: "1px solid black",
                            // borderLeft:
                            //   colIndex === 0 ? "1px solid black" : "none",
                            borderLeft:
                              colIndex === 0
                                ? "1px solid black"
                                : "1px dashed black",
                            borderRight:
                              colIndex === 3 ? "1px solid black" : "none",
                          }}
                        >
                          <Text style={{ fontSize: "8px" }} break>
                            {osDataProps[rowIndex] &&
                            osDataProps[rowIndex][column] !== undefined
                              ? osDataProps[rowIndex][column]
                              : "-"}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ))}
                </View>
              </View>

              <View style={{ width: "49.5%" }}>
                <View
                  style={{
                    width: "100%",
                    height: "20px",
                    marginBottom: "5px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text style={{ fontSize: "10px" }}>
                    Showing for {UtilDateTime.getCurrentYear()}
                  </Text>
                  <Text style={{ fontSize: "9px" }}> </Text>
                </View>
                <View>
                  <View
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      textAlign: "justify",
                    }}
                  >
                    <Text>
                      The table provides a concise summary of the most recent 6
                      rides and serves as a comprehensive record, offering a
                      detailed analysis of driving behavior over time. It
                      highlights instances of exceeding speed limits and
                      provides information about the speed at those specific
                      times.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                paddingHorizontal: 30,
                height: "200px",
                marginBottom: "15px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "49.5%" }}>
                <View
                  style={{
                    width: "100%",
                    height: "20px",
                    marginBottom: "5px",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text style={[fontBold, { fontSize: "10px" }]}>
                    Mobile Screen Use
                  </Text>
                  <Text style={{ fontSize: "10px" }}>
                    {" "}
                    {`(${displayMSUDataRange})`}
                  </Text>
                </View>
                <View>
                  <View style={{ flexDirection: "row", height: "25px" }}>
                    {columns2.map((column, index) => (
                      <View
                        key={index}
                        style={{
                          flex: column === "date" ? 1.8 : 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "9px",
                          borderBottom: "1px solid black",
                          // borderRight: "1px solid black",
                          // borderTop: "1px solid black",
                          // borderLeft: index === 0 ? "1px solid black" : "none",
                          border: "1px solid black",
                          // borderBottom: "1px solid black",
                          borderLeft: index === 0 ? "1px solid black" : "none",
                        }}
                      >
                        <Text style={fontBold} break>
                          {columnDisplayNameMap3[column]}
                        </Text>
                      </View>
                    ))}
                  </View>

                  {Array.from({ length: 6 }).map((_, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{
                        flexDirection: "row",
                        height: "25px",
                        borderBottom:
                          rowIndex === 5
                            ? "1px solid black"
                            : "1px dashed black",
                      }}
                    >
                      {columns2.map((column, colIndex) => (
                        <View
                          key={colIndex}
                          style={{
                            flex: column === "date" ? 1.8 : 1,
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "right",
                            fontSize: "8px",
                            // borderBottom: "1px solid black",
                            // borderRight: "1px solid black",
                            // borderLeft:
                            //   colIndex === 0 ? "1px solid black" : "none",
                            borderLeft:
                              colIndex === 0
                                ? "1px solid black"
                                : "1px dashed black",
                            borderRight:
                              colIndex === 2 ? "1px solid black" : "none",
                          }}
                        >
                          <Text style={{ fontSize: "8px" }} break>
                            {msuPdfDataProps[rowIndex] &&
                            msuPdfDataProps[rowIndex][column] !== undefined
                              ? msuPdfDataProps[rowIndex][column]
                              : "-"}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ))}
                </View>
              </View>

              <View style={{ width: "49.5%" }}>
                <View
                  style={{
                    width: "100%",
                    height: "20px",
                    marginBottom: "5px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text style={{ fontSize: "10px" }}>
                    Showing for {UtilDateTime.getCurrentYear()}
                  </Text>
                  <Text style={{ fontSize: "10px" }}> </Text>
                </View>
                <View>
                  <View
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      textAlign: "justify",
                    }}
                  >
                    <Text>
                      The table provides a concise summary of the most recent 6
                      rides and insight into the exact times of mobile screen
                      use and the corresponding speeds during the ride.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                paddingHorizontal: 30,
                height: "200px",
                marginBottom: "15px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "49.5%" }}>
                <View
                  style={{
                    width: "100%",
                    height: "20px",
                    fontSize: "5px",
                    marginBottom: "10px",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text style={[fontBold, { fontSize: "10px" }]}>
                    Mobile Call Use
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    {" "}
                    {`(${displayMCUDataRange})`}
                  </Text>
                </View>
                <View>
                  <View style={{ flexDirection: "row", height: "25px" }}>
                    {columns2.map((column, index) => (
                      <View
                        key={index}
                        style={{
                          flex: column === "date" ? 1.8 : 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "9px",
                          // borderBottom: "1px solid black",
                          // borderRight: "1px solid black",
                          // borderTop: "1px solid black",
                          // borderLeft: index === 0 ? "1px solid black" : "none",
                          border: "1px solid black",
                          // borderBottom: "1px solid black",
                          borderLeft: index === 0 ? "1px solid black" : "none",
                        }}
                      >
                        <Text style={fontBold} break>
                          {columnDisplayNameMap3[column]}
                        </Text>
                      </View>
                    ))}
                  </View>
                  {Array.from({ length: 6 }).map((_, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{
                        flexDirection: "row",
                        height: 25,
                        borderBottom:
                          rowIndex === 5
                            ? "1px solid black"
                            : "1px dashed black",
                      }}
                    >
                      {columns2.map((column, colIndex) => (
                        <View
                          key={colIndex}
                          style={{
                            flex: column === "date" ? 1.8 : 1,
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 8,
                            // borderBottom: "1px solid black",
                            // borderRight: "1px solid black",
                            // borderLeft:
                            //   colIndex === 0 ? "1px solid black" : "none",
                            borderLeft:
                              colIndex === 0
                                ? "1px solid black"
                                : "1px dashed black",
                            borderRight:
                              colIndex === 2 ? "1px solid black" : "none",
                          }}
                        >
                          <Text style={{ fontSize: 8 }} break>
                            {mcuPdfDataProps[rowIndex] &&
                            mcuPdfDataProps[rowIndex][column] !== undefined
                              ? mcuPdfDataProps[rowIndex][column]
                              : "-"}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ))}
                  {/* {Array.from({ length: 6 }).map((_, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{ flexDirection: "row", height: 25 }}
                    >
                      {columns2.map((column, colIndex) => {
                        const cellData =
                         mcuPdfDataProps[rowIndex] &&
                          mcuPdfDataProps[rowIndex][column] !== undefined
                            ? mcuPdfDataProps[rowIndex][column]
                            : null;

                        const borderStyles = {
                          flex: column === "date" ? 1.8 : 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 8,
                          borderBottom:
                            cellData || rowIndex === 5
                              ? "1px solid black"
                              : "none",
                          borderRight:
                            cellData || colIndex === 2
                              ? "1px solid black"
                              : "none",
                          borderLeft:
                            colIndex === 0 ? "1px solid black" : "none",
                        };

                        return (
                          <View key={colIndex} style={borderStyles}>
                            <Text style={{ fontSize: 8 }} break>
                              {cellData || " "}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  ))} */}
                </View>
              </View>
              <View style={{ width: "49.5%" }}>
                <View
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text style={{ fontSize: "10px" }}>
                    Showing for {UtilDateTime.getCurrentYear()}
                  </Text>
                </View>
                <View
                  style={{
                    textAlign: "justify",
                    fontSize: "10px",
                  }}
                >
                  <Text>
                    The table provides a concise summary of the most recent 10
                    rides and insight into the precise times of mobile call
                    usage, the speed traveled during the ride, and the
                    corresponding time of each event.
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                height: "70px",
                paddingHorizontal: 30,
                marginBottom: "15px",
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: "20px",
                  marginBottom: "5px",
                  fontSize: "10px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={[fontBold, { fontSize: "10px" }]}>
                  Vehicle Last Location
                </Text>
              </View>
              <View
                style={{
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  height: "60px",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    height: "30px",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "15%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={[fontBold, { fontSize: "9px" }]}>
                      Last Ride
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "25%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={[fontBold, { fontSize: "9px" }]}>
                      Driving Score
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "20%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={[fontBold, { fontSize: "9px" }]}>
                      Geo-Location
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "40%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={[fontBold, { fontSize: "9px" }]}>
                      Location
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    height: "40px",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "15%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ fontSize: "9px" }}>
                      {locationDataPdf.deviceName && locationDataPdf.deviceName}
                      {!locationDataPdf.deviceName && "-"}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "25%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {locationDataPdf.deviceName !== undefined ||
                      (locationDataPdf.deviceName !== null &&
                        locationDataPdf.drivingScore) ? (
                        <>
                          <View style={{ width: "70%" }}>
                            <Svg viewBox="0 0 700 20">
                              <Rect
                                x="0"
                                rx="2"
                                ry="2"
                                width="100%"
                                height="25"
                                fill="#D3D3D3"
                              />
                              <Rect
                                x="0"
                                rx="2"
                                ry="2"
                                width={`${locationDataPdf.drivingScore}%`}
                                height="25"
                                fill="blue"
                              />
                            </Svg>
                          </View>
                          <View style={{ width: "20%", textAlign: "center" }}>
                            <Text style={{ fontSize: 9 }}>
                              {`${locationDataPdf.drivingScore}%`}
                            </Text>
                          </View>
                        </>
                      ) : (
                        <Text style={{ fontSize: "9px" }}>-</Text>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "20%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {locationDataPdf.latitude !== undefined &&
                    locationDataPdf.longitude !== undefined ? (
                      <View>
                        <Text style={{ fontSize: "9px" }}>
                          Lat: {locationDataPdf.latitude}
                        </Text>
                        <Text style={{ fontSize: "9px" }}>
                          Lon: {locationDataPdf.longitude}
                        </Text>
                      </View>
                    ) : (
                      <Text style={{ fontSize: "9px" }}>-</Text>
                    )}
                  </View>

                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "40%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ fontSize: "9px",padding:"2px"}}>
                      {locationDataPdf.location !== undefined &&
                      locationDataPdf.location !== null
                        ? locationDataPdf.location
                        : "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                height: "44.5px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <View style={{ borderBottom: "1px solid black" }} />
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 30,
                  marginRight: 30,
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {footer[0].f}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {footer[2].f}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>{totalPages + 7}</Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {footer[1].f}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {footer[3].f}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
};

export default UserReactToPdf;