import { apiConfig } from "../../config/Config.js";
import { apiEndPoint, apiType, apiStatus } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
// import { GlobalConst } from "./../../constant/GlobalConstant.js";
 
import UserSessionController from "../UserSession/UserSessionController.js";
import {
    SC_COMPANY_ID,
    SC_DIVISION_ID,
    SC_MODULE_ID,
    SC_LOGIN_USER_ID,
    SC_USER_ID,
} from "../../constant/StorageConstant.js";
 
// import { apiStatus, apiType, apiAction } from './../../constant/api_constant.js';
 
let SecretKeyController = {
   
    async getSecrect() {
     
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_USER_ID)
        if (companyId.length > 0 && divisionId.length > 0) {
            var reqPayload = {
                companyId: companyId,
                divisionId: divisionId,
                userId:userId,
                type: "USER_SECRET",
                sortBy: "createdAt",
            };
 
            // console.log("secretKeyPayload: " + JSON.stringify(reqPayload))
            return await ApiService.callGET(
                apiConfig.API_VERSION_1,
                apiEndPoint.USER_SECRECT,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
 
};
 
export default SecretKeyController;