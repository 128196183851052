// import { Document, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
// import React from 'react';
// import PdfHeading from '../../../components/ViewPdf/Components/pdfHeading';
// import PdfHeader from '../../../components/ViewPdf/Components/pdfHeader';
// import UtilDateTime from '../../../util/UtilDateTime';
// import ValueFormat from '../../../util/ValueFormat';
// import PdfFooter from '../../../components/ViewPdf/Components/pdfFooter';
// import { useLocation } from 'react-router-dom';

// const styles = StyleSheet.create({
//     pdfViewer: {
//         height: "96%",
//         width: "100%",
//         marginTop: "20px",
//         marginBottom: "5px",
//     },
//     document: {
//         marginTop: "15px",
//         marginBottom: "5px",
//     },
//     page: {
//         width: "100%",
//     },
//     horizontalGap: {
//         paddingHorizontal: 30
//     },
//     rideContainer: {
//         backgroundColor: '#615AE41A',
//         height: 205,
//         borderRadius: 10,
//         padding: 10,
//         marginBottom: 10
//     },
//     rideHeader: {
//         paddingBottom: 10,
//         fontSize: 11,
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//         alignItems: 'center'
//     },
//     rideRow: {
//         paddingBottom: 5,
//         flexDirection: 'row',
//         alignItems: 'center',
//         // justifyContent: 'space-between','
//         gap: 4
//     },
//     rideBox: {
//         width: '70',
//         fontSize: 9,
//         border: '1px solid black',
//         borderRadius: 10,
//         padding: 5,
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center'
//     }
// });

// const fontBold = { fontFamily: "Helvetica-Bold" };

// const UBIDashboardPDF = ({ ubiData }) => {
//     const pathname = useLocation();
//     const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

//     const [filters, startYear, endYear, sPData, tenPoints, totalKmSPoint, dsp, dstp,
//         mobileUsePoint, mobileScreenPoint, topRowStressStrainPoint, filteredScoreData, filteredDistanceData, filteredHoursData, drivStyleData,
//         drivBehavData, aCatData, sDistData, sSCData, mUHData] = ubiData;

//     let acDataTemp;

//     if (aCatData && aCatData.length > 0) {
//         acDataTemp = aCatData;
//     } else {
//         acDataTemp = [{ name: 'Rural', value: '0' }, { name: 'Urban', value: '0' }, { name: 'Highway', value: '0' }];
//     }

//     let spDistTemp;

//     if (sDistData && sDistData.length > 0) {
//         spDistTemp = sDistData;
//     } else {
//         spDistTemp = ["0.0", "0.0", "0.0", "0.0", "0.0",];
//     }

//     const getData = (low, index) => {
//         return low.data[index];
//     };
//     const low = sSCData.find(item => item.name === 'Low');
//     const medium = sSCData.find(item => item.name === 'Medium');
//     const high = sSCData.find(item => item.name === 'High');

//     const lowAccValue = getData(low, 0);
//     const lowCorValue = getData(low, 1);
//     const lowBrakValue = getData(low, 2);

//     const mediumAccValue = getData(medium, 0);
//     const mediumCorValue = getData(medium, 1);
//     const mediumBrakValue = getData(medium, 2);

//     const highAccValue = getData(high, 0);
//     const highCorValue = getData(high, 1);
//     const highBrakValue = getData(high, 2);


//     const ubiScorePoint = parseInt(tenPoints[0].totalEfficiency);
//     var totalPages = 2;

//     let period = "";
//     let startDate = "";
//     let endDate = "";

//     if (filters.durationFilter === "CURRENT_YEAR") {
//         period = ValueFormat.smallLettersFormat(filters.durationFilter);
//         startDate = UtilDateTime.getCurrentYear();
//         endDate = "";
//     } else if (filters.durationFilter === "CURRENT_MONTH") {
//         period = ValueFormat.smallLettersFormat(filters.durationFilter);
//         startDate = UtilDateTime.getCurrentMonth();
//         endDate = "";
//     } else if (filters.durationFilter === "CURRENT_WEEK") {
//         period = ValueFormat.smallLettersFormat(filters.durationFilter);
//         startDate = UtilDateTime.getCurrentWeek1();
//         endDate = "";
//     } else if (filters.durationFilter === "CURRENT_DAY") {
//         period = ValueFormat.smallLettersFormat(filters.durationFilter);
//         startDate = UtilDateTime.getCurrentDay();
//         endDate = "";
//     } else if (filters.durationFilter === "CUSTOM_DURATION") {
//         period = ValueFormat.smallLettersFormat(filters.durationFilter);
//         startDate = startYear.split(' ')[0];
//         endDate = endYear.split(' ')[0];
//     } else {
//         period = ""
//         startDate = "All the time";
//         endDate = "";
//     }

//     const periodFormat = [period, startDate, endDate];
//     const heading = " Driving Behaviour Summary";
//     var currentDate = new Date();
//     const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

//     // Function to get rides by category

//     const getRidesByCategory = (category) => {
//         return sPData.filter(ride => ride.rideBox === category).slice(0, 28);
//     };

//     // Function to render a row of rides

//     const renderRideRow = (rides, startIndex) => {
//         return (
//             <View style={styles.rideRow}>
//                 {rides.slice(startIndex, startIndex + 7).map((ride, index) => (
//                     <View key={index} style={styles.rideBox}>
//                         <Text>{ride.name}</Text>
//                         <Text>{ride.anticipateDriveValue} | {ride.selfConfidenceValue}</Text>
//                     </View>
//                 ))}
//             </View>
//         );
//     };

//     // Function to render rides for a category

//     const renderRidesForCategory = (category) => {
//         const rides = getRidesByCategory(category);
//         const rows = [];
//         const totalRides = sPData?.length;
//         const percentage = totalRides > 0 ? ((sPData.filter(ride => ride.rideBox === category).length / totalRides) * 100).toFixed(2) : 0;
//         for (let i = 0; i < Math.min(4, Math.ceil(rides.length / 7)); i++) {
//             rows.push(renderRideRow(rides, i * 7));
//         }
//         return (
//             <View style={styles.rideContainer}>
//                 <View style={styles.rideHeader}>
//                     <Text>Category: C{category}</Text>
//                     <Text>Percentage: {percentage}%</Text>
//                     <Text>Showing {rides.length} out of {sPData.filter(ride => ride.rideBox === category).length}</Text>
//                 </View>
//                 {rows}
//             </View>
//         );
//     };

//     // Function to render a page with categories

//     const renderPage = (categories) => {
//         return (
//             <Page totalPages={totalPages++} style={styles.page}>
//                 <PdfHeader />
//                 <View style={styles.horizontalGap}>
//                     <View style={{ paddingVertical: 10 }}>
//                         <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
//                     </View>
//                     {totalPages == 3 && (
//                         <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
//                             <View style={{ paddingVertical: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>11. Safety Points </Text>
//                             </View>

//                             {/* <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 10 }}>
//                             <View style={{width:'200',fontSize: 10, border: '1px solid black', borderRadius: 10, padding: 5, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
//                             <Text>Ride Name</Text>
//                             <Text>Anticipation | Self Confidence</Text>
//                             </View>
//                             </View> */}
//                         </View>
//                     )}

//                     {categories.map((category, index) => renderRidesForCategory(category))}
//                 </View>
//                 <PdfFooter pageNumber={totalPages} pathname={pathname} />
//             </Page>
//         );
//     };

//     // Categories to be displayed

//     const categories = [...new Set(sPData?.map(ride => ride.rideBox))];
//     const pages = [];
//     for (let i = 0; i < categories.length; i += 3) {
//         pages.push(renderPage(categories.slice(i, i + 3)));
//     }

//     return (
//         <React.Fragment>
//             <PDFViewer style={styles.pdfViewer}>
//                 <Document style={styles.document}>
//                     <Page totalPages={totalPages++} style={styles.page}>
//                         <PdfHeader />
//                         <View style={styles.horizontalGap}>
//                             <View style={{ paddingTop: 20, paddingBottom: 20 }}><PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} /></View>
//                             <View style={{ paddingBottom: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>1. Driving Behaviour</Text>
//                             </View>
//                             <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20, flexDirection: 'row', gap: 2 }}>
//                                 <View style={{ border: "1px dashed lightgrey", width: '80px', height: '150px' }}>
//                                     <View style={[fontBold, { height: '70px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: '11px', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text style={{ padding: 3 }}>Driving Behaviour Score</Text></View>
//                                     <View style={[fontBold, { height: '80px', fontSize: '15px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }]}><Text style={{ padding: 3 }}>{ubiScorePoint || 0}</Text><View style={{ borderBottom: '1px solid lightgrey', width: '80%' }} /><Text style={{ padding: 3 }}>100</Text></View>
//                                 </View>
//                                 <View style={{ flexDirection: 'column', gap: 10 }}>
//                                     <View style={{ flexDirection: 'row', gap: 2 }}>
//                                         <View style={{ border: "1px dashed lightgrey", width: '120px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Driving Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.drivingScore ?? 0} / 20</Text></View>
//                                         </View>
//                                         <View style={{ border: "1px dashed lightgrey", width: '90px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Anticipation Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.anticipation ?? 0} / 10</Text></View>
//                                         </View>
//                                         <View style={{ border: "1px dashed lightgrey", width: '90px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Self Confidence Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.selfConfidence ?? 0} / 10</Text></View>
//                                         </View>
//                                         <View style={{ border: "1px dashed lightgrey", width: '90px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Driving Skill Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.drivingSkill ?? 0} / 10</Text></View>
//                                         </View>
//                                         <View style={{ border: "1px dashed lightgrey", width: '90px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Driving Style Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.DSTP ?? 0} / 10</Text></View>
//                                         </View>
//                                     </View>
//                                     <View style={{ flexDirection: 'row', gap: 2 }}>
//                                         <View style={{ border: "1px dashed lightgrey", width: '80px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Driver State Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.DSP ?? 0} / 10</Text></View>
//                                         </View>
//                                         <View style={{ border: "1px dashed lightgrey", width: '90px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Mobile Screen Usage Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.mobileScreenPointNew ?? 0} / 5</Text></View>
//                                         </View>
//                                         <View style={{ border: "1px dashed lightgrey", width: '90px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Mobile Call Usage Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.mobileUsePointNew ?? 0} / 5</Text></View>
//                                         </View>
//                                         <View style={{ border: "1px dashed lightgrey", width: '90px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Overspeed KMS Driven Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.totalKmSPoint ?? 0} / 10</Text></View>
//                                         </View>
//                                         <View style={{ border: "1px dashed lightgrey", width: '90px', height: '70px' }}>
//                                             <View style={[fontBold, { height: '60px', backgroundColor: "#9e98fb", borderBottom: '1px dashed lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Acc/Brak/Cor Points</Text></View>
//                                             <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.ABCPoint ?? 0} / 10</Text></View>
//                                         </View>
//                                     </View>
//                                 </View>
//                             </View>

//                             <View style={{ paddingBottom: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>2. Score Distribution </Text>
//                             </View>
//                             <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                 <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                     <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jan</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Feb</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mar</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Apr</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>May</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jun</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jul</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Aug</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sep</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Oct</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Nov</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Dec</Text></View>
//                                     </View>

//                                     <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                         {filteredScoreData.map((data, index) => (
//                                             <View key={index} style={[{ fontSize: 9, width: '44.5px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data.value : '0'}</Text></View>
//                                         ))}
//                                     </View>
//                                 </View>
//                             </View>

//                             <View style={{ paddingBottom: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>3. Kilometer Distribution </Text>
//                             </View>
//                             <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                 <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                     <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jan</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Feb</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mar</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Apr</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>May</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jun</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jul</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Aug</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sep</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Oct</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Nov</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Dec</Text></View>
//                                     </View>

//                                     <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                         {filteredDistanceData?.map((data, index) => (
//                                             <View key={index} style={[{ fontSize: 9, width: '44.5px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data.value : '0'}</Text></View>
//                                         ))}
//                                     </View>
//                                 </View>
//                             </View>

//                             <View style={{ paddingBottom: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>4. Hour Distribution </Text>
//                             </View>
//                             <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                 <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                     <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jan</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Feb</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mar</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Apr</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>May</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jun</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jul</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Aug</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sep</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Oct</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Nov</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '44.5px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Dec</Text></View>
//                                     </View>

//                                     <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                         <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                             {filteredHoursData?.map((data, index) => (
//                                                 <View key={index} style={[{ fontSize: 9, width: '44.5px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data.value : '0'}</Text></View>
//                                             ))}
//                                         </View>
//                                     </View>
//                                 </View>
//                             </View>

//                             <View style={{ paddingBottom: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>5. Driving Style </Text>
//                             </View>
//                             <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                 <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                     <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Normal</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Eco</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sportive</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Chaotic</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Aggressive</Text></View>
//                                     </View>

//                                     <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                         {drivStyleData?.map((data, index) => (
//                                             <View key={index} style={[{ fontSize: 9, width: '106.8px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(data && data.length > 0) ? data + "%" : "0%"}</Text></View>
//                                         ))}
//                                     </View>
//                                 </View>
//                             </View>

//                         </View>
//                         <PdfFooter pageNumber={1} pathname={pathname} />
//                     </Page>

//                     <Page totalPages={totalPages++} style={styles.page}>
//                         <PdfHeader />
//                         <View style={styles.horizontalGap}>
//                             <View style={{ paddingVertical: 20 }}><PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} /></View>



//                             <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
//                                 <View style={{ width: '49%' }}>
//                                     <View style={{ paddingBottom: 10 }}>
//                                         <Text style={[fontBold, { fontSize: 12 }]}>6. Driver State </Text>
//                                     </View>

//                                     <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                         <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                             <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                                 <View style={[fontBold, { fontSize: 9, width: '89', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Normal</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '89', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Angry</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '89', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Tired</Text></View>
//                                             </View>

//                                             <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                                 {drivBehavData?.map((data, index) => (
//                                                     <View key={index} style={[{ fontSize: 9, width: '89', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(data && data.length > 0) ? data + "%" : "0%"}</Text></View>
//                                                 ))}
//                                             </View>
//                                         </View>
//                                     </View>
//                                 </View>

//                                 <View style={{ width: '49%' }}>
//                                     <View style={{ paddingBottom: 10 }}>
//                                         <Text style={[fontBold, { fontSize: 12 }]}>7. Area Category</Text>
//                                     </View>

//                                     <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                         <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                             <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                                 <View style={[fontBold, { fontSize: 9, width: '89', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Rural</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '89', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Urban</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '89', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Highway</Text></View>
//                                             </View>

//                                             <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                                 {acDataTemp?.map((data, index) => (
//                                                     <View key={index} style={[{ fontSize: 9, width: '89', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data.value + "%" : "0%"}</Text></View>
//                                                 ))}
//                                             </View>
//                                         </View>
//                                     </View>
//                                 </View>
//                             </View>

//                             <View style={{ paddingBottom: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>8. Speed Distribution </Text>
//                             </View>
//                             <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                 <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                     <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>0 - 40 (km/hr)</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>40 - 60 (km/hr)</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>60 - 80  (km/hr)</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>80 - 120  (km/hr)</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '106.8', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>120+  (km/hr)</Text></View>
//                                     </View>

//                                     <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                         {spDistTemp?.map((data, index) => (
//                                             <View key={index} style={[{ fontSize: 9, width: '106.8', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data + "%" : "0%"}</Text></View>
//                                         ))}
//                                     </View>
//                                 </View>
//                             </View>


//                             <View style={{ paddingBottom: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>9. Acceleration Cornering Braking</Text>
//                             </View>
//                             <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
//                                 <View style={{ width: '32.33%' }}>
//                                     <View style={{ paddingBottom: 10 }}>
//                                         <Text style={[fontBold, { fontSize: 11.5 }]}>9.1 Acceleration</Text>
//                                     </View>

//                                     <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                         <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                             <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Low</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Medium</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>High</Text></View>
//                                             </View>

//                                             <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{lowAccValue ?? 0}</Text></View>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{mediumAccValue ?? 0}</Text></View>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{highAccValue ?? 0}</Text></View>
//                                             </View>
//                                         </View>
//                                     </View>
//                                 </View>

//                                 <View style={{ width: '32.33%' }}>
//                                     <View style={{ paddingBottom: 10 }}>
//                                         <Text style={[fontBold, { fontSize: 11.5 }]}>9.2 Cornering</Text>
//                                     </View>

//                                     <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                         <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                             <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Low</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Medium</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>High</Text></View>
//                                             </View>

//                                             <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{lowCorValue ?? 0}</Text></View>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{mediumCorValue ?? 0}</Text></View>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{highCorValue ?? 0}</Text></View>
//                                             </View>
//                                         </View>
//                                     </View>
//                                 </View>

//                                 <View style={{ width: '32.33%' }}>
//                                     <View style={{ paddingBottom: 10 }}>
//                                         <Text style={[fontBold, { fontSize: 11.5 }]}>9.3 Braking</Text>
//                                     </View>

//                                     <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                         <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                             <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Low</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Medium</Text></View>
//                                                 <View style={[fontBold, { fontSize: 9, width: '59.33px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>High</Text></View>
//                                             </View>

//                                             <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{lowBrakValue ?? 0}</Text></View>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{mediumBrakValue ?? 0}</Text></View>
//                                                 <View style={[{ fontSize: 9, width: '59.33px', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{highBrakValue ?? 0}</Text></View>
//                                             </View>
//                                         </View>
//                                     </View>
//                                 </View>
//                             </View>

//                             <View style={{ paddingBottom: 10 }}>
//                                 <Text style={[fontBold, { fontSize: 12 }]}>10. Mobile Usage in Hour </Text>
//                             </View>
//                             <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                 <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column' }}>
//                                     <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                         <View style={[fontBold, { fontSize: 9, width: '267px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mobile Screen Use</Text></View>
//                                         <View style={[fontBold, { fontSize: 9, width: '267px', borderBottom: '1px dashed lightgrey', borderTop: '1px dashed lightgrey', height: '25px', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mobile Call Use</Text></View>
//                                     </View>

//                                     <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                         <View style={[{ fontSize: 9, width: '267px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(mUHData && mUHData.length > 0) ? mUHData[0] : '0.0'}</Text></View>
//                                         <View style={[{ fontSize: 9, width: '267px', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(mUHData && mUHData.length > 0) ? mUHData[1] : '0.0'}</Text></View>
//                                     </View>
//                                 </View>
//                             </View>

//                             {!sPData?.length > 0 && (
//                                 <View>
//                                     <View style={{ paddingBottom: 10 }}>
//                                         <Text style={[fontBold, { fontSize: 12 }]}>11. Safety Points </Text>
//                                     </View>
//                                     <View style={{ backgroundColor: '#615AE41A', borderRadius: 10, padding: 10, marginBottom: 20 }}>
//                                         <View style={{ fontSize: 10, textAlign: 'center' }}><Text>Data not available</Text></View>
//                                     </View>
//                                 </View>
//                             )}

//                         </View>
//                         <PdfFooter pageNumber={2} pathname={pathname} />
//                     </Page>

//                     {pages}
//                 </Document>
//             </PDFViewer>
//         </React.Fragment>
//     );
// };

// export default UBIDashboardPDF;

import { Document, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import PdfHeading from '../../../components/ViewPdf/Components/pdfHeading';
import PdfHeader from '../../../components/ViewPdf/Components/pdfHeaderV1';
import UtilDateTime from '../../../util/UtilDateTime';
import ValueFormat from '../../../util/ValueFormat';
import PdfFooter from '../../../components/ViewPdf/Components/pdfFooterV1';
import { useLocation } from 'react-router-dom';
import { globalPDF } from '../../../assets/css/commonCssTextForPdf';

const styles = StyleSheet.create({
    pdfViewer: {
        height: "96%",
        width: "100%",
        marginTop: "20px",
        marginBottom: "5px",
    },
    document: {
        marginTop: "15px",
        marginBottom: "5px",
    },
    page: {
        width: "100%",
    },
    horizontalGap: {
        paddingHorizontal: 30
    },
    rideContainer: {
        border: '1px solid lightgrey',
        height: 205,
        borderRadius: 10,
        padding: 10,
        marginBottom: 10
    },
    rideHeader: {
        paddingBottom: 10,
        fontSize: 11,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rideRow: {
        paddingBottom: 5,
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'space-between','
        gap: 4
    },
    rideBox: {
        width: '70',
        fontSize: 9,
        border: '1px solid lightgrey',
        borderRadius: 10,
        padding: 5,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

const fontBold = { fontFamily: "Helvetica-Bold" };

const UBIDashboardPDF = ({ ubiData }) => {
    const pathname = useLocation();
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    const [filters, startYear, endYear, sPData, tenPoints, filteredScoreData, filteredDistanceData, filteredHoursData, drivStyleData,
        drivBehavData, aCatData, sDistData, sSCData, mUHData] = ubiData;

    let acDataTemp;

    if (aCatData && aCatData.length > 0) {
        acDataTemp = aCatData;
    } else {
        acDataTemp = [{ name: 'Rural', value: '0' }, { name: 'Urban', value: '0' }, { name: 'Highway', value: '0' }];
    }

    let spDistTemp;

    if (sDistData && sDistData.length > 0) {
        spDistTemp = sDistData;
    } else {
        spDistTemp = ["0.0", "0.0", "0.0", "0.0", "0.0",];
    }

    const getData = (low, index) => {
        return low.data[index];
    };
    const low = sSCData.find(item => item.name === 'Low');
    const medium = sSCData.find(item => item.name === 'Medium');
    const high = sSCData.find(item => item.name === 'High');

    const lowAccValue = getData(low, 0);
    const lowCorValue = getData(low, 1);
    const lowBrakValue = getData(low, 2);

    const mediumAccValue = getData(medium, 0);
    const mediumCorValue = getData(medium, 1);
    const mediumBrakValue = getData(medium, 2);

    const highAccValue = getData(high, 0);
    const highCorValue = getData(high, 1);
    const highBrakValue = getData(high, 2);


    const ubiScorePoint = parseInt(tenPoints[0].totalEfficiency);
    var totalPages = 2;

    let period = "";
    let startDate = "";
    let endDate = "";

    if (filters.durationFilter === "CURRENT_YEAR") {
        period = ValueFormat.smallLettersFormat(filters.durationFilter);
        startDate = UtilDateTime.getCurrentYear();
        endDate = "";
    } else if (filters.durationFilter === "CURRENT_MONTH") {
        period = ValueFormat.smallLettersFormat(filters.durationFilter);
        startDate = UtilDateTime.getCurrentMonth();
        endDate = "";
    } else if (filters.durationFilter === "CURRENT_WEEK") {
        period = ValueFormat.smallLettersFormat(filters.durationFilter);
        startDate = UtilDateTime.getCurrentWeek1();
        endDate = "";
    } else if (filters.durationFilter === "CURRENT_DAY") {
        period = ValueFormat.smallLettersFormat(filters.durationFilter);
        startDate = UtilDateTime.getCurrentDay();
        endDate = "";
    } else if (filters.durationFilter === "CUSTOM_DURATION") {
        period = ValueFormat.smallLettersFormat(filters.durationFilter);
        startDate = startYear.split(' ')[0];
        endDate = endYear.split(' ')[0];
    } else {
        period = ""
        startDate = "All the time";
        endDate = "";
    }

    const periodFormat = [period, startDate, endDate];
    const heading = " Driving Behaviour Summary";
    var currentDate = new Date();
    const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

    // Function to get rides by category

    const getRidesByCategory = (category) => {
        return sPData.filter(ride => ride.rideBox === category).slice(0, 60);
    };

    // Function to render a row of rides

    const renderRideRow = (rides, startIndex) => {
        return (
            <View style={styles.rideRow}>
                {rides.slice(startIndex, startIndex + 15).map((ride, index) => (
                    <View key={index} style={styles.rideBox}>
                        <Text>{ride.name}</Text>
                        <Text>{ride.anticipateDriveValue} | {ride.selfConfidenceValue}</Text>
                    </View>
                ))}
            </View>
        );
    };

    // Function to render rides for a category

    const renderRidesForCategory = (category) => {
        const rides = getRidesByCategory(category);
        const rows = [];
        const totalRides = sPData?.length;
        const percentage = totalRides > 0 ? ((sPData.filter(ride => ride.rideBox === category).length / totalRides) * 100).toFixed(2) : 0;
        for (let i = 0; i < Math.min(4, Math.ceil(rides.length / 15)); i++) {
            rows.push(renderRideRow(rides, i * 15));
        }
        return (
            <View style={styles.rideContainer}>
                <View style={styles.rideHeader}>
                    <Text>Category: C{category}</Text>
                    <Text>Percentage: {percentage}%</Text>
                    <Text>Showing {rides.length} out of {sPData.filter(ride => ride.rideBox === category).length}</Text>
                </View>
                {rows}
            </View>
        );
    };

    // Function to render a page with categories

    const renderPage = (categories) => {
        return (
            <Page size={"A2"} totalPages={totalPages++} style={styles.page}>
                <PdfHeader />
                <View style={styles.horizontalGap}>
                    <View style={{ paddingVertical: 10 }}>
                        <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
                    </View>
                    {totalPages == 2 && (
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <View style={{ paddingVertical: 10 }}>
                                <Text style={[fontBold, { fontSize: 12 }]}>11. Safety Points </Text>
                            </View>
                        </View>
                    )}

                    {categories.map((category, index) => renderRidesForCategory(category))}
                </View>
                <PdfFooter pageNumber={2} pathname={pathname} />
            </Page>
        );
    };

    // Categories to be displayed

    const categories = [...new Set(sPData?.map(ride => ride.rideBox))];
    const pages = [];
    for (let i = 0; i < categories.length; i += 6) {
        pages.push(renderPage(categories.slice(i, i + 6)));
    }

    return (
        <React.Fragment>
            <PDFViewer style={styles.pdfViewer}>
                <Document style={styles.document}>
                    <Page size={"A2"} totalPages={totalPages++} style={styles.page}>
                        <PdfHeader />
                        <View style={styles.horizontalGap}>
                            <View style={{ paddingTop: 20, paddingBottom: 20 }}><PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} /></View>
                            <View style={{ paddingBottom: 10 }}>
                                <Text style={[fontBold, { fontSize: 12 }]}>1. Driving Behaviour</Text>
                            </View>
                            <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20, flexDirection: 'row', gap: 2 }}>
                                <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '150px' }}>
                                    <View style={[fontBold, { height: '70px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: '11px', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text style={{ padding: 3 }}>Driving Behaviour Score</Text></View>
                                    <View style={[fontBold, { height: '80px', fontSize: '15px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }]}><Text style={{ padding: 3 }}>{ubiScorePoint || 0}%</Text><View style={{ borderBottom: '1px solid lightgrey', width: '80%' }} /><Text style={{ padding: 3 }}>100</Text></View>
                                </View>
                                <View style={{ flexDirection: 'column', gap: 10 }}>
                                    <View style={{ flexDirection: 'row', gap: 2 }}>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Driving Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.drivingScore ?? 0} / 20</Text></View>
                                        </View>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Anticipation Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.anticipation ?? 0} / 10</Text></View>
                                        </View>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Self Confidence Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.selfConfidence ?? 0} / 10</Text></View>
                                        </View>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Driving Skill Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.drivingSkill ?? 0} / 10</Text></View>
                                        </View>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Driving Style Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.DSTP ?? 0} / 10</Text></View>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', gap: 2 }}>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Driver State Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.DSP ?? 0} / 10</Text></View>
                                        </View>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Mobile Screen Usage Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.mobileScreenPointNew ?? 0} / 5</Text></View>
                                        </View>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Mobile Call Usage Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.mobileUsePointNew ?? 0} / 5</Text></View>
                                        </View>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Overspeed KMS Driven Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.totalKmSPoint ?? 0} / 10</Text></View>
                                        </View>
                                        <View style={{ border: "1px solid lightgrey", width: '20.66%', height: '70px' }}>
                                            <View style={[fontBold, { height: '60px', backgroundColor: globalPDF.GREY_COLOR, borderBottom: '1px solid lightgrey', fontSize: 9, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }]}><Text >Acc/Brak/Cor Points</Text></View>
                                            <View style={{ height: '30px', fontSize: 9, justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><Text >{tenPoints?.[0]?.ABCPoint ?? 0} / 10</Text></View>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                                <View style={{ width: '49.5%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 12 }]}>2. Score Distribution </Text>
                                    </View>
                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jan</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Feb</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mar</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Apr</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>May</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jun</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jul</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Aug</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sep</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Oct</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Nov</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Dec</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                {filteredScoreData.map((data, index) => (
                                                    <View key={index} style={[{ fontSize: 9, width: '8.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data.value : '0'}</Text></View>
                                                ))}
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ width: '49.5%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 12 }]}>3. Kilometer Distribution </Text>
                                    </View>
                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jan</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Feb</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mar</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Apr</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>May</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jun</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jul</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Aug</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sep</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Oct</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Nov</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Dec</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                {filteredDistanceData?.map((data, index) => (
                                                    <View key={index} style={[{ fontSize: 9, width: '8.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data.value : '0'}</Text></View>
                                                ))}
                                            </View>
                                        </View>
                                    </View>
                                </View>

                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                                <View style={{ width: '49.5%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 12 }]}>4. Hour Distribution </Text>
                                    </View>
                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jan</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Feb</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mar</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Apr</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>May</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jun</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Jul</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Aug</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sep</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Oct</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Nov</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '8.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Dec</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                    {filteredHoursData?.map((data, index) => (
                                                        <View key={index} style={[{ fontSize: 9, width: '8.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data.value : '0'}</Text></View>
                                                    ))}
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ width: '49.5%' }}>

                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 12 }]}>5. Driving Style </Text>
                                    </View>
                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Normal</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Eco</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sportive</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Chaotic</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Aggressive</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                {drivStyleData?.map((data, index) => (
                                                    <View key={index} style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(data && data.length > 0) ? data + "%" : "0%"}</Text></View>
                                                ))}
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>


                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={{ width: '49.5%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 12 }]}>6. Driver State </Text>
                                    </View>

                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Normal</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Angry</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Tired</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                {drivBehavData?.map((data, index) => (
                                                    <View key={index} style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(data && data.length > 0) ? data + "%" : "0%"}</Text></View>
                                                ))}
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ width: '49.5%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 12 }]}>7. Area Category</Text>
                                    </View>

                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Rural</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Urban</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Highway</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                {acDataTemp?.map((data, index) => (
                                                    <View key={index} style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data.value + "%" : "0%"}</Text></View>
                                                ))}
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                                <View style={{ width: '49.5%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 12 }]}>8. Speed Distribution </Text>
                                    </View>
                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>0 - 40 (km/hr)</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>40 - 60 (km/hr)</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>60 - 80  (km/hr)</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>80 - 120  (km/hr)</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '20%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>120+  (km/hr)</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                {spDistTemp?.map((data, index) => (
                                                    <View key={index} style={[{ fontSize: 9, width: '20%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{data ? data + "%" : "0%"}</Text></View>
                                                ))}
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ width: '49.5%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 12 }]}>9. Mobile Usage in Hour </Text>
                                    </View>
                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '50%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mobile Screen Use</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '50%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Mobile Call Use</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[{ fontSize: 9, width: '50%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(mUHData && mUHData.length > 0) ? mUHData[0] : '0.0'}</Text></View>
                                                <View style={[{ fontSize: 9, width: '50%', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(mUHData && mUHData.length > 0) ? mUHData[1] : '0.0'}</Text></View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ paddingBottom: 10 }}>
                                <Text style={[fontBold, { fontSize: 12 }]}>10. Acceleration Cornering Braking</Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={{ width: '32.33%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 11.5 }]}>10.1 Acceleration</Text>
                                    </View>

                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Low</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Medium</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>High</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{lowAccValue ?? 0}</Text></View>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{mediumAccValue ?? 0}</Text></View>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{highAccValue ?? 0}</Text></View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ width: '32.33%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 11.5 }]}>10.2 Cornering</Text>
                                    </View>

                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Low</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Medium</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>High</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{lowCorValue ?? 0}</Text></View>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{mediumCorValue ?? 0}</Text></View>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{highCorValue ?? 0}</Text></View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={{ width: '32.33%' }}>
                                    <View style={{ paddingBottom: 10 }}>
                                        <Text style={[fontBold, { fontSize: 11.5 }]}>10.3 Braking</Text>
                                    </View>

                                    <View style={{ border: '1px solid lightgrey', borderRadius: 10, padding: 10, marginBottom: 20 }}>
                                        <View style={{ height: '50px', border: '1px solid lightgrey', width: '100%', flexDirection: 'column' }}>
                                            <View style={{ height: '25px', width: '100%', borderBottom: '1px solid lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Low</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', borderRight: '1px solid lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Medium</Text></View>
                                                <View style={[fontBold, { fontSize: 9, width: '33.4%', borderBottom: '1px solid lightgrey', borderTop: '1px solid lightgrey', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>High</Text></View>
                                            </View>

                                            <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{lowBrakValue ?? 0}</Text></View>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', borderRight: '1px solid lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{mediumBrakValue ?? 0}</Text></View>
                                                <View style={[{ fontSize: 9, width: '33.4%', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{highBrakValue ?? 0}</Text></View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>

                        </View>
                        <PdfFooter pageNumber={1} pathname={pathname} />
                    </Page>


                    {pages}
                </Document>
            </PDFViewer>
        </React.Fragment>
    );
};

export default UBIDashboardPDF;

