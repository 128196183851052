


const widgetUsersData = [
    {
    type : "RIDE",
    title: "Number of Rides",
    postFix: "Rides",
    statusColor: "success",
    dataListKeys: ["Safe Driver", "Sportive Driver","Quite Safe Driver","Reckless Driver","Quite Dangerous Driver","Dangerous Driver"],
    dataListValues: [0,0,0,0,0,0],        
    
},

{
    type : "DISTANCE",
    title: "Number of Kilometer",
    postFix: "kms",
    statusColor: "success",
    dataListKeys: ["Safe Driver", "Sportive Driver","Quite Safe Driver","Reckless Driver","Quite Dangerous Driver","Dangerous Driver"],
    dataListValues: [0,0,0,0,0,0],
},
{
    type : "DURATION",
    title: "Number of Hours",
    postFix: "hrs",
    statusColor: "success",
    dataListKeys: ["Safe Driver", "Sportive Driver","Quite Safe Driver","Reckless Driver","Quite Dangerous Driver","Dangerous Driver"],
    dataListValues: [0,0,0,0,0,0],
},
{
    type : "ALERT",
    title: "Number of Risks",
    postFix: "Alerts",
    statusColor: "success",
    dataListKeys: ["Safe Driver", "Sportive Driver","Quite Safe Driver","Reckless Driver","Quite Dangerous Driver","Dangerous Driver"],
    dataListValues: [0,0,0,0,0,0],
},
{
    type : "OTHER ALERT",
    title: "Number of Other Alerts",
    postFix: "Other Alerts",
    statusColor: "success",
    dataListKeys: ["Safe Driver", "Sportive Driver","Quite Safe Driver","Reckless Driver","Quite Dangerous Driver","Dangerous Driver"],
    dataListValues: [0,0,0,0,0,0],
}];




export { widgetUsersData };
