import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
// import UserDrivingScoreController from '../../controller/Dashboard/UserDashboardController';
const OneRiskAlertChart = ({ otherData, otherDataLabel }) => {
  const options = {
    tooltip: {
      show: true,
    },
    xAxis: {
      name: "Category",
      type: "category",
      data: otherDataLabel,
      axisLabel: {
        show: true,
        rotate: 0,
      },
      nameLocation: "middle",

      nameGap: 0,

      nameTextStyle: {
        align: "center",
        color:"#8D8C8E",
        verticalAlign: "top",
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,        
        padding: [30, 0, 0, 0],
      },
    },
    yAxis: {
      name: "Count", 
      nameLocation: "middle",
      nameGap:40,    
      type: "value",
      nameTextStyle: {
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,
        color:"#8D8C8E",
        
      },
      
    },
    grid: { bottom: 55, left: 65, right: 0 },
    series: {
      name:"Count",
      data: otherData,
      type: "bar",
      barWidth: 25,
      label: {
        show: true,
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideTop',
        distance: -5,
        color: 'black',
      },
      // markPoint: {
      //   data: [
      //     { type: 'max', name: 'Max' },
      //     { type: 'min', name: 'Min' }
      //   ]
      // },
    
    },
  };

  const [barOption, setBarOption] = useState(options);

  useEffect(() => {
    setBarOption((prev) => {
      return {
        ...prev,
        series: { data: otherData },
        xAxis: { data: otherDataLabel },
      };
    });
  }, [otherData, otherDataLabel]);

  return (
    <React.Fragment>
      <ReactEcharts option={barOption} style={{ height: "378px", width: window.innerWidth > 1199 ? "44vw" : "90vw" }} />
    </React.Fragment>
  );
};

export default OneRiskAlertChart;