import React, { useEffect, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import Report from "../../controller/Report/Report";



export default function UserLastActivity() {
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [totalPageCount , setTotalPageCount] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiDataUser, setApiDataUser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [workFilter, setWorkFilter] = useState("ACTIVE");
  const [categoryValue, setCategory] = useState("ASC");
  const [indexStart, setIndexStart] = useState(0);
  const [nextShow, setNextShow] = useState(false);
  const [prevShow, setPrevShow] = useState(false);
  const userFirstName = localStorage.getItem(SC_LOGIN_USER_FIRST_NAME);
  const [searchQuery, setSearchQuery] = useState("");

  const pageSize = 10;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectUsername, setSelectUsername] = useState("");

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
    setSearchQuery("");
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(userIdTemp);
    setSelectUsername(firstName);
  };

  const filterUsers = (user) => {
    return user.firstName.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const getUserList = async () => {
    setShowLoader(true);
    var result = await Report.getUserListReport();
    var userData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData1", resultJSONData);
        if (resultJSONData.length > 0) {
          var firstName = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userListData = resultJSONData[i];
            firstName = ValueFormat.parseAsString(
              ValueFormat.capitalizeAllLetter(userListData.firstName)
            );
            userIdTemp = userListData.id;

            var userDataListTemp = {
              firstName: firstName,
              userIdTemp: userIdTemp,
            };

            userData.push(userDataListTemp);
            setApiDataUser(userData);
            // console.log("apiUsers",apiDataUser)
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.message;
        // console.log("Getting user list else error",resultJSONCode);
        console.log("Status failed");
      }
    } catch (errMes) {
      console.log("Catch error message: ", errMes);
    } finally {
      setShowLoader(false);
    }
  };

  const getUserLastActivity = async (indexStart) => {
    setShowLoader(true);
    var result = await Report.getUserLastActivityReport(
      indexStart,
      filter,
      workFilter,
      categoryValue,
      startDateTime,
      endDateTime,
      pageSize,
      selectedOption
    );
    var tableData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      // console.log("batteryLevelReport: " + JSON.stringify(resultJSON));
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        console.log("resultJSONData", resultJSONData)
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var webActivityTime = "",
            webLoginTime = "",
            firstName = "",
            userStatus = "",
            andActivityTime = "",
            andLoginTime = "",
            iosActivityTime = "",
            iosLoginTime = "",
            appFileAnd = "",
            appFileIos = "",
            mapFileAnd = "",
            mapFileIos = "",
            lastNoRideAnd = "",
            lastNoRideIos = "";

          for (let i = 0; i < resultJSONData.length; i++) {
            var UserlastActivityData = resultJSONData[i];
            firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(UserlastActivityData.firstName));
            userStatus = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetter(UserlastActivityData.userStatus));
            webLoginTime = ValueFormat.parseAsString(UserlastActivityData.sessionWebAtToTimeZone);
            webActivityTime = ValueFormat.parseAsString(
              UserlastActivityData.activityWebAtToTimeZone
            );
            andActivityTime = ValueFormat.parseAsString(
              UserlastActivityData.activityAndroidAtToTimeZone
            );
            andLoginTime = ValueFormat.parseAsString(UserlastActivityData.sessionAndroidAtToTimeZone);
            lastNoRideAnd = ValueFormat.parseAsString(UserlastActivityData.noActivityPushAndroidAtToTimeZone);
            mapFileAnd = ValueFormat.parseAsString(UserlastActivityData.mapUpdatePushAndroidAtToTimeZone)
            appFileAnd = ValueFormat.parseAsString(UserlastActivityData.appUpdatePushAndroidAtToTimeZone)
            var totalCounts = ValueFormat.parseAsInt(UserlastActivityData.totalCount);
            setTotalPageCount(Math.ceil(totalCounts/pageSize));
            setNextShow(indexStart + pageSize < totalCounts);
            iosLoginTime = ValueFormat.parseAsString(UserlastActivityData.sessionIosAtToTimeZone);
            iosActivityTime = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetter(UserlastActivityData.activityIosAtToTimeZone)
            );
            lastNoRideIos = ValueFormat.parseAsString(UserlastActivityData.noActivityPushIosAtToTimeZone);
            mapFileIos = ValueFormat.parseAsString(UserlastActivityData.mapUpdatePushIosAtToTimeZone);
            appFileIos = ValueFormat.parseAsString(UserlastActivityData.appUpdatePushIosAtToTimeZone)


            var batteryLevelDetails = {
              userStatus: userStatus,
              webLoginTime: webLoginTime,
              webActivityTime: webActivityTime,
              andActivityTime: andActivityTime,
              andLoginTime: andLoginTime,
              lastNoRideAnd: lastNoRideAnd,
              mapFileAnd: mapFileAnd,
              appFileAnd: appFileAnd,
              iosActivityTime: iosActivityTime,
              iosLoginTime: iosLoginTime,
              lastNoRideIos: lastNoRideIos,
              mapFileIos: mapFileIos,
              appFileIos: appFileIos,
              firstName: firstName,

            };
            tableData.push(batteryLevelDetails);
          }
          setApiData(tableData);
        }
      } else {
        console.log("Status failed");
      }
    } catch (errMes) {
      console.log("Battery catch error: ", errMes)
    } finally {
      setShowLoader(false);
    }

    if (indexStart == 0) {
      setPrevShow(false);
    } else if (indexStart > 1) {
      setPrevShow(true);
    }

  };

  useEffect(() => {
    getUserList();
  }, []);

  const [count, setCount] = useState(0);
  useEffect(() => {
      setPageNumber(1);
      if (indexStart == 0) setCount((prev) => prev + 1);
      else setIndexStart(0);
  }, [filter,workFilter,categoryValue,startDateTime,endDateTime,selectedOption]);

  useEffect(() => {
    if (count > 0) getUserLastActivity(indexStart);
  }, [indexStart, count]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        <Row className="my-2 py-2">
            <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
              <h5 className="mb-0">User Last Activities</h5>
            </Col>
          </Row>
        <Row>
          <Col className="col-12 mt-2">
            <Card>
              <CardHeader style={{ padding: "0px" }}>
                <Row>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        borderBottom: "1px dashed #DFDEDE",
                      }}
                    >
                      <h4
                        className="card-title mb-3 mt-3"
                        style={{ textAlign: "center" }}
                      >
                        User Status Selection
                      </h4>
                    </div>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          workFilter == " "
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter(" ")}
                        data-tooltip-id="allsession"
                      >
                        All
                        <ReactTooltip
                          id="allsession"
                          place="top"
                          content="All Records"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          workFilter == "ACTIVE"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("ACTIVE")}
                        data-tooltip-id="currentBl"
                      >
                        Active
                        <ReactTooltip
                          id="currentBl"
                          place="top"
                          content="Active Records"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          workFilter == "BLOCKED"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("BLOCKED")}
                        data-tooltip-id="blockedUsers"
                      >
                        Locked
                        <ReactTooltip
                          id="blockedUsers"
                          place="top"
                          content="Locked Users"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-3 mt-3"
                        style={{ textAlign: "center" }}
                      >
                        Order By Selection<span style={{fontSize:"12px"}}> (Last Activity)</span>
                      </h4>
                    </div>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          categoryValue == "ASC"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setCategory("ASC")}
                        data-tooltip-id="Ascending"
                      >
                        Ascending
                        <ReactTooltip
                          id="Ascending"
                          place="top"
                          content="Ascending"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          categoryValue == "DESC"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setCategory("DESC")}
                        data-tooltip-id="desc"
                      >
                        Descending
                        <ReactTooltip
                          id="desc"
                          place="top"
                          content="Descending"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={4}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-3 mt-3"
                        style={{ textAlign: "center" }}
                      >
                        Duration Selection<span style={{fontSize:"12px"}}> (User Registration)</span>
                      </h4>
                    </div>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          filter == "" && !modalIsOpen
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_DAY" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                        data-tooltip-id="cd"
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_WEEK" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                        data-tooltip-id="cw"
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_MONTH" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                        data-tooltip-id="cm"
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_YEAR" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                        data-tooltip-id="cy"
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CUSTOM_DURATION" || modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        data-tooltip-id="dfdf"
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <ReactTooltip
                          id="dfdf"
                          place="top"
                          content="Custom Date Selection"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        borderBottom: "1px dashed #DFDEDE",
                      }}
                    >
                      <h4
                        className="card-title mb-3 mt-3"
                        style={{ textAlign: "center" }}
                      >
                        User Selection
                      </h4>
                    </div>
                    <div className="mt-3 mb-3" style={{ textAlign: "center" }}>
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        size="sm"
                      >
                        <DropdownToggle caret color="primary">
                          {selectUsername ? selectUsername : "All Users"}
                          <FaAngleDown style={{ marginLeft: "5px" }} />
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          <div className="d-flex align-items-center px-3">
                            <FaSearch className="me-2" />
                            <input
                              type="text"
                              placeholder="Search users"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              className="form-control form-control-sm"
                            />
                          </div>
                          <DropdownItem onClick={() => handleSelect("")}>
                            All Users
                          </DropdownItem>
                          {apiDataUser.filter(filterUsers).map((user) => (
                            <DropdownItem
                              key={user.userIdTemp}
                              onClick={() =>
                                handleSelect(user.userIdTemp, user.firstName)
                              }
                            >
                              {user.firstName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                {/* <Row>
                  <Col xl={12} lg={12} md={12} sm={12} sx={12}>
                    <form className="d-none d-lg-block">
                      <div
                        className="mb-3 mt-4"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="mt-2 " style={{ fontSize: "15px" }}>
                          &nbsp;Global Search:
                        </label>
                        <input
                          type="text"
                          style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                          // value={searchText}
                          // placeholder="Ride Name or Ride Number"
                        />
                        <button
                          // ref={inputRef}
                          style={{ marginLeft: "5px" }}
                          className="btn btn-primary me-3"
                          // type="button"
                          // onClick={handleSearch}
                        >
                          <i className="bx bx-search-alt align-middle"></i>
                        </button>
                      </div>
                    </form>
                  </Col>
                </Row> */}
                <Col xl={12} md={12} sm={12}>
                  <div className="mt-3" style={{ overflowX: "auto", }}>
                    <Table className="table table-bordered mb-0" style={{ width: "1800px", borderCollapse: "collapse" }}>
                      <thead className="cardHeader">
                        <tr>
                          <th  
                            className="cardHeader"
                            data-tooltip-id="fn"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              position: "sticky",
                              left: -2,
                              zIndex: 2,
                              background:"white"
                            }}
                          >
                            First Name
                            <ReactTooltip
                              id="fn"
                              place="top"
                              content="First Name"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            className="cardHeader"
                            data-tooltip-id="UserStatus"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center",
                              position: "sticky",
                              left: 86,
                              zIndex: 2,
                              background:"white",
                            }}
                          >
                            User Status
                            <ReactTooltip
                              id="UserStatus"
                              place="top"
                              content="User Status"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="LastWebLoginTime"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last Web Login Time
                            <ReactTooltip
                              id="LastWebLoginTime"
                              place="top"
                              content="Last Web Login Time"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="LastWebActivityTime"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last Web Activity Time
                            <ReactTooltip
                              id="LastWebActivityTime"
                              place="top"
                              content="Last Web Activity Time"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="LastAndroidLoginTime"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last Android Login Time
                            <ReactTooltip
                              id="LastAndroidLoginTime"
                              place="top"
                              content="Last Android Login Time"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="LastAndActivityTime"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last Android Activity Time
                            <ReactTooltip
                              id="LastAndActivityTime"
                              place="top"
                              content="Last Android Activity Time"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="LastNoRideAndroid"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last No Ride Push Sent Android
                            <ReactTooltip
                              id="LastNoRideAndroid"
                              place="top"
                              content="Last No Ride Push Sent Android"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="LastmapFilepushSentAnd"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last Map File Push Sent Android
                            <ReactTooltip
                              id="LastmapFilepushSentAnd"
                              place="top"
                              content="Last Map File Push Sent Android"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="LastAppFilepushSentAnd"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last App File Push Sent Android
                            <ReactTooltip
                              id="LastAppFilepushSentAnd"
                              place="top"
                              content="Last App File Push Sent Android"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="LastIosLoginTime"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last iOS Login Time
                            <ReactTooltip
                              id="LastIosLoginTime"
                              place="top"
                              content="Last iOS Login Time"
                              style={{ background: "black" }}
                            />
                          </th>


                          <th
                            data-tooltip-id="LastIosActivityTime"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last iOS Activity Time
                            <ReactTooltip
                              id="LastIosActivityTime"
                              place="top"
                              content="Last iOS Activity Time"
                              style={{ background: "black" }}
                            />
                          </th>

                          <th
                            data-tooltip-id="LastNoRideIos"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last No Ride Push Sent iOS
                            <ReactTooltip
                              id="LastNoRideIos"
                              place="top"
                              content="Last No Ride Push Sent iOS"
                              style={{ background: "black" }}
                            />
                          </th>

                          <th
                            data-tooltip-id="LastMapFilepushSentIos"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last Map File Push Sent iOS
                            <ReactTooltip
                              id="LastMapFilepushSentIos"
                              place="top"
                              content="Last Map File Push Sent iOS"
                              style={{ background: "black" }}
                            />
                          </th>

                          <th
                            data-tooltip-id="LastAppFilePushSortIos"
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center"
                            }}
                          >
                            Last App File Push Sent iOS
                            <ReactTooltip
                              id="LastAppFilePushSortIos"
                              place="top"
                              content="Last App File Push Sent iOS"
                              style={{ background: "black" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLength > 0 ? (
                          apiData.map((apiDataTemp, i) => (
                            <tr key={i}>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  position: "sticky",
                                  left: -2,
                                  zIndex: 2,
                                  background: "white",
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.firstName}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                  position:"sticky",
                                left: 86,
                                zIndex:2,
                                background:"white"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.userStatus}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.webLoginTime}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.webActivityTime}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.andLoginTime}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.andActivityTime}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.lastNoRideAnd}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.mapFileAnd}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.appFileAnd}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.iosLoginTime}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.iosActivityTime}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.lastNoRideIos}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.mapFileIos}
                                </span>
                              </td>
                              <td
                                style={{
                                  fontSize: "13px",
                                  verticalAlign: "middle",
                                  textAlign: "center"
                                }}
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {apiDataTemp.appFileIos}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="14">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            {" "}
                            <td colSpan="14">
                              <div
                                style={{
                                  color: "black",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.NO_RECORDS_FOUND}
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
               <div style={{display:"flex",justifyContent:"space-between"}}>
               <div className="pagination">
                 <a>{pageNumber}</a>
               </div>
               <p style={{marginTop:"6px",marginLeft:"5px",marginRight:"5px"}} >of</p>             
             <div className="pagination ">                  
            <a>{totalPageCount}</a>             
          </div> 
          </div>
              ) : null}
              {dataLength > 0 ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
              ) : null}
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}
