import React, { useEffect, useState } from 'react';

import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";

import CountUp from "react-countup";

/** import Mini Widget data */
import { UDMainDashboardTopRowItems } from "../../common/data/dashboard";
import { widgetUsersData } from "../../common/data/dashboarduserdata";

import WalletBalance from '../Dashboard/WalletBalance';
import InvestedOverview from '../Dashboard/InvestedOverview';
import MarketOverview from '../Dashboard/MarketOverview';
import Locations from '../Dashboard/Locations';
import Trading from '../Dashboard/Trading';
import Transactions from '../Dashboard/Transactions';
import RecentActivity from '../Dashboard/RecentActivity';

import NewSlider from '../Dashboard/NewSlider';
import UserDrivingScoreController from '../../controller/Dashboard/UserDashboardController';
import ValueFormat from "../../util/ValueFormat.js";

const options = {
  chart: {
    height: 50,
    type: "line",
    toolbar: { show: false },
  },
  colors: ["#5156be"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const classs = [
  {
    name:"table-light"
  },  
  {
    name:"table-success"
  },  
  {
    name:"table-info"
  },  
  {
    name:"table-warning"
  },  
  {
    name:"table-danger"
  },  
  {
    name:"table-secondary"
  }
]

const SuperAdminDashboard = () => {

    const [UDMainDashboardTopRowItemsTemp, setUDMainDashboardTopRowItemsTemp] = useState(UDMainDashboardTopRowItems);
    const [UDMainDashboardSafetyTableData, setUDMainDashboardSafetyTableData] = useState(widgetUsersData);
    const getTotalValues = async () => {
        var result = await UserDrivingScoreController.totalValuesController();
        var resultJSON = JSON.parse(result);
        var resultJSONData = resultJSON.data;

        resultJSONData.map((apiResultTemp) => { 

            var overallData      = ValueFormat.parseAsObject(apiResultTemp.overallData);
            var currentYearData  = ValueFormat.parseAsObject(apiResultTemp.currentYearData);
            var currentMonthData = ValueFormat.parseAsObject(apiResultTemp.currentMonthData); 

            // console.log('overallData: ' + overallData)
 
            setUDMainDashboardTopRowItemsTemp(UDMainDashboardTopRowItemsTemp.map((staticObjectTemp) => {

              var isTypeMatched = false; var overallValue = 0; var cYearValue = 0; var cMDataList = [];  

              if (staticObjectTemp.type === "RIDE") {
                isTypeMatched = true; 
                overallValue = ValueFormat.parseAsInt(overallData.totalRides);
                cYearValue   = ValueFormat.formatAsInteger(currentYearData.totalRides);
                cMDataList   = ValueFormat.parseAsArray(currentMonthData.drivingScoreList);
              } else if(staticObjectTemp.type === "DISTANCE") { 
                isTypeMatched = true; 
                overallValue = ValueFormat.parseAsFloat(overallData.totalKiloMeters, 2);
                cYearValue   = ValueFormat.parseAsFloat(currentYearData.totalKiloMeters, 2);
                cMDataList   = ValueFormat.parseAsArray(currentMonthData.kiloMeterList);
              } else if(staticObjectTemp.type === "DURATION") { 
                isTypeMatched = true; 
                overallValue = ValueFormat.parseAsFloat(overallData.totalHours, 2); 
                cYearValue   = ValueFormat.parseAsFloat(currentYearData.totalHours, 2);
                cMDataList   = ValueFormat.parseAsArray(currentMonthData.travelTimeList);
              } else if(staticObjectTemp.type === "ALERT") { 
                isTypeMatched = true; 
                overallValue = ValueFormat.parseAsInt(overallData.totalRiskAlerts); 
                cYearValue   = ValueFormat.formatAsInteger(currentYearData.totalRiskAlerts); 
                cMDataList   = ValueFormat.parseAsArray(currentMonthData.riskAlertList); 
               } 

              if (isTypeMatched === true) { 
                let staticObjectTempReplace = {'overallData' : overallValue, 'currentYearValue' : cYearValue, 'currentMonthGraphData' : cMDataList }
                return{...staticObjectTemp,...staticObjectTempReplace} 
              } else { 
                return staticObjectTemp;
              }
              
            }))


  
        })

    }
  // temporary variables 
  const [startDateTimee, setStartDateTimee] = useState("");
  const [endDateTimee, setEndDateTimee] = useState("");
  const [filterr, setFilterr] = useState("");
    const getSafetyData = async () =>{
      var result = await UserDrivingScoreController.userSafetyDetailController(filterr,startDateTimee,endDateTimee);
      var resultJSON = JSON.parse(result);
      var resultJSONData = resultJSON.data;
      // console.log("getSafetyData: " + JSON.stringify(resultJSONData));
      resultJSONData.map((apiResultTemp)=>{
        var rideBoxList = ValueFormat.parseAsArray(apiResultTemp.rideBoxList);
        var totalRides = 0;
        var totalKms = 0;
        var totalHrs = 0;
        var totalRiskAlerts = 0;
        var totalRideDataList = [];
        var totalKmsList = [];
        var totalHrsList = [];
        var totalRiskList = [];


        rideBoxList.map((rideData)=>{
          totalRides = ValueFormat.parseAsInt(rideData.totalRides);
          totalKms = ValueFormat.parseAsFloat(rideData.totalKiloMeters,2);
          totalHrs = ValueFormat.parseAsFloat(rideData.totalHours,2);
          totalRiskAlerts = ValueFormat.parseAsInt(rideData.totalRiskAlerts);
          
          totalRideDataList.push(totalRides);
          totalKmsList.push(totalKms);
          totalHrsList.push(totalHrs);
          totalRiskList.push(totalRiskAlerts)
        })

        // setUDMainDashboardSafetyTableData(UDMainDashboardSafetyTableData.safetyData.map((staticArrayTemplate) => {


        //   if(staticArrayTemplate.label === "RIDES"){
        //     return [...staticArrayTemplate.value, totalRideDataList]
        //   } else if(staticArrayTemplate.label === "KMS") {
        //     return [...staticArrayTemplate.value, totalKmsList]
        //   }
        //   else if(staticArrayTemplate.label === "HRS") {
        //     return [...staticArrayTemplate.value, totalHrsList]

        //   } else if(staticArrayTemplate.label === "RISK") {
        //     return [...staticArrayTemplate.value, totalRiskList]

        //   } else {
        //     return UDMainDashboardSafetyTableData
        //   }
          
        // }))

      })

    }
    
      useEffect(() => {
        getTotalValues();
        getSafetyData();
      }, [])


  //meta title

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="SuperAdminDashboard" breadcrumbItem="SuperAdminDashboard" />

          <Row>
                        {(UDMainDashboardTopRowItemsTemp || []).map((widget, key) => (
                            <Col xl={3} md={6} key={key}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6}>
                                                <span className="text-muted mb-3 lh-1 d-block text-truncate">{widget.title}</span>
                                                <h4 className="mb-3">
                                                    {/* {widget.isDoller === true ? '$' : ''} */}
                                                    <span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            end={widget.overallData}
                                                             decimals={key === 1 || key === 2  ? 2 : 0}
                                                             decimal="."
                                                            duration={1}
                                                        />
                                                        {widget.postFix}
                                                    </span>
                                                </h4>
                                            </Col>
                                         
                                        </Row>
                                  
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}

                    </Row>
{/* 
          <Row>
            {(UDMainDashboardSafetyTableData.safetyData || []).map((widgetdata, key) => (
              <Col xl={3} md={6} key={key}>
                <Card className="card-h-100" style={{border:"0px"}}>
                  <CardBody >
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {(UDMainDashboardSafetyTableData.label || []).map((value, i) => (
                            <tr key={i} className={classs[i].name} >
                              <td
                                className="text-muted  text-truncate"
                                style={{ fontSize: "12px",paddingTop:"1px",paddingBottom:"1px"}}
                              >
                                {value.name}
                              </td>
                              <td  style={{ fontSize: "12px",marginLeft:"1px !important",paddingTop:"1px",paddingBottom:"1px"}}>{widgetdata.value[i]}&nbsp;<span style={{ fontSize: "10px"}}>{widgetdata.postFix}</span></td>
                              
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          {/* <Row>
            <WalletBalance />
            <Col>
              <Row>
                <InvestedOverview />
                <NewSlider />
              </Row>
            </Col>
          </Row> */}
          {/* <Row>
            <MarketOverview />
            <Locations />
          </Row> */}
       
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SuperAdminDashboard;
