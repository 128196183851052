import React from "react";
import ReactEcharts from "echarts-for-react";

const StreesStrainChart = ({ stressStrainData }) => {
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend:{
      data: ["Low", "Medium", "High"],
    },
    grid: { bottom: 55, left: 65, right: 0 },
    xAxis: {
      name: "Category",
      type: "category",
      data: stressStrainData.label,
      axisLabel: {
        show: true,
      },
      nameLocation: "middle",
      nameGap: 5,
      nameTextStyle: {
        align: "center",
        color: "#8D8C8E",
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,
        verticalAlign: "top",
        padding: [30, 0, 0, 0],
      },
    },
    yAxis: {
      name: "Count",
      nameLocation: "middle",
      nameGap: 40,
      type: "value",
      splitLine: {
        show: false
      },
      nameTextStyle: {
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,
        color: "#8D8C8E",

      },

    },

    series: [
      {
        name: "Low",
        data: stressStrainData.low,
        type: "bar",
        barWidth: 25,
        itemStyle: {
          color: '#367335'
        },
        label: {
          show: true,
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideTop',
          distance: -5,
          color: 'black',
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
      },
      {
        name: "Medium",
        data: stressStrainData.medium,
        type: "bar",
        barWidth: 25,
        itemStyle: {
          color: '#FFBF00'
        },
        label: {
          show: true,
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideTop',
          distance: -5,
          color: 'black',
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
      },
      {
        name: "High",
        data: stressStrainData.high,
        type: "bar",
        barWidth: 25,
        itemStyle: {
          color: '#FF0000'
        },
        label: {
          show: true,
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideTop',
          distance: -5,
          color: 'black',
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
      }
    ]
  };

  //   const series = [
  //     {
  //       name: "Low",
  //       data: stressStrainData.low,
  //     },
  //     {
  //       name: "Medium",
  //       data:stressStrainData.medium,
  //     },
  //     {
  //       name: "High",
  //       data: stressStrainData.high,
  //     },
  //   ]
  //   const options = {
  //     chart: {
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: "30%",
  //         endingShape: "rounded",
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ["transparent"],
  //     },

  //     colors: ["#367335", "#FFBF00", "#FF0000"],
  //     xaxis: {
  //       title: {
  //         text: "Category",
  //         offsetX: 0,
  //         offsetY: 85,
  //       },
  //       categories: stressStrainData.label,
  //     },
  //     yaxis: {
  //       title: {
  //         text: "Count",
  //       },
  //     },
  //     grid: {
  //       borderColor: "#f1f1f1",
  //     },
  //     fill: {
  //       opacity: 1,
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return  val 
  //         },
  //       },
  //     },
  //   }

  return (
    <ReactEcharts option={option} type="bar" style={{ height: "378px", width: window.innerWidth > 1199 ? "44vw" : "90vw" }} />
  );
};

export default StreesStrainChart;