import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselIndicators, Row, Col } from "reactstrap";

import img1 from "../../assets/images/users/avatar-1.jpg";
import img2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/users/avatar-3.jpg";
// import mobileImage from "../../assets/images/logo/motivAiMobile-image2.png";
// import mobileImage from "../../assets/images/logo/mobile_login_new.png";
import mobileImage from "../../assets/images/loginPageImages/Latest Home.png";

const items = [
  {
    id: 1,
    img: img1,
    name: "Richard Drews",
    designation: "Web Designer",
    description:
      "I drive well, the problem is the other driver : A study about the self-assessment of the ability to drive",
  },
  {
    id: 2,
    img: img2,
    name: "Rosanna French",
    designation: "Web Developer",
    description:
      "What does it mean to drive well, outside of any emergency situation?",
  },
  {
    id: 3,
    img: img3,
    name: "Ilse R. Eaton",
    designation: "Manager",
    description:
      "If I am bad on certain criteria and good on others, it is possible that I am average overall",
  },
  {
    id: 4,
    img: img3,
    name: "Ilse R. Eaton",
    designation: "Manager",
    description:
      "Unfortunately, what some drivers do, they commit incautious acts but do not have an accident or even a bad surprise, and they take on bad habits in terms of road safety",
  },
  {
    id: 5,
    img: img3,
    name: "Ilse R. Eaton",
    designation: "Manager",
    description:
      "Detection of entry into risk triangle, and action for a rapid exit: “Undo Unsafe Act” ",
  },
  {
    id: 6,
    img: img3,
    name: "Ilse R. Eaton",
    designation: "Manager",
    description: "Functional safety: on-board computing of risk of accident",
  },
];

const CarouselPage = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        sx={{ backgroundColor: "red" }}
      >
        <div className="carousel-item active">
          <div className="testi-contain text-white">
            <div>
              {/* <i className="bx bxs-quote-alt-left text-success display-6"></i> */}
              <h5 className="mt-4 text-white">{item.description}</h5>
            </div>

            <div className="mt-4 pt-3 pb-5">
              {/* <div className="d-flex align-items-start">
                                <div className="flex-shrink-0">
                                    <img src={item.img} className="avatar-md img-fluid rounded-circle" alt="..." />
                                </div>
                                <div className="flex-grow-1 ms-3 mb-4">
                                    <h5 className="font-size-18 text-white">{item.name}
                                    </h5>
                                    <p className="mb-0 text-white-50">{item.designation}</p>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      <div className="col-xxl-9 col-lg-8 col-xl-8 col-md-7">
        <div className="auth-bg pt-md-5 p-4 d-flex">
          <div className="bg-overlay bg-primary" style={{display:"flex",}}>
            <div style={{width:"100%",}} >
              <h1
                
                style={{
                  color: "white",
                  fontSize: "45px",
                  paddingLeft: "11.50%",
                  fontFamily: "sans-serif",
                 marginTop:"20%",
                 height:"18%"
                 
                  
                }}
              >
                Drive Safe, Arrive Safe
              </h1>
              <div
                className="d-flex row justify-content-center align-items-center"
                style={{height: "50%", paddingLeft: "11.50%"}}
              >
                <div className="col-xl-8">
                  <Row
                  // className="p-0 p-sm-4 px-xl-0"
                  >
                    <Col
                      xl={12}
                      lg={12}
                      id="reviewcarouselIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                      >
                        {slides}
                      </Carousel>
                    </Col>
                    <Col xl={12} lg={12} style={{ display: "flex" }}>
                      <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
              <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                <img  align="left" src={mobileImage} style={{position:"relative", width:"55%", left:90,}}/>
                </div>

            </div>
            {/* <div style={{width:"100%"}} className="d-none d-lg-flex justify-content-center align-items-center me-2">
                <img src={mobileImage} alt="cc"style={{width:"31vw",height:'78vh',position:"fixed"}}/>

            </div> */}

            
          </div>
          <ul className="bg-bubbles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
         
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarouselPage;
