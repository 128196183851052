const MyRidedataList = [{
    rideNo:"Nodata",
    status:"-",
    mobile:"-",    
    mode:"-",
    event:"-",
    startTime:"-",
    endTime:"-",
    score:"-",
    duration:"-",
    distance:"-",
    alerts:"-",
    category:"-",
    apiDataTemp:null
                             
},

];

export default MyRidedataList;