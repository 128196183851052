const NotificationUserdataList = [{
    firstName:"-",
    username:"-",
    rideName:"-",    
    rideStatus:"-",
    rideStartTime:"-",
    riskName:"-",
    riskValue:"-",
    riskTime:"-",
    riskAlertLevel:"-",
},



];

export default NotificationUserdataList;