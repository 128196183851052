import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import dateIcon from "../../../assets/images/pdf/pdfIconDate.png"

const fontBold = {
  fontFamily: "Helvetica-Bold",
};
export default function PdfHeading({ heading, periodFormat, reversedDateFormat }) {
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text style={[fontBold, { fontSize: 14 }]}>{heading}</Text>
        <View style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
          <Image
            src={dateIcon}
            style={{
              height: "18px",
              width: "18px",
              marginRight: 2,
            }}
            alt={"fg"}
          />
          <Text
            style={{
              fontSize: 10,
              textAlign: "right",
              color: "#000",
              fontFamily: "Helvetica",
              fontWeight: 800,
            }}
          >
            Date: {reversedDateFormat}
          </Text>
        </View>
      </View>
      {(window.location.pathname == "/crashView" || window.location.pathname.includes("/ridedetails")) ? (
        <View style={{ flexDirection: 'row', alignItems: 'center', fontSize: 11,
          fontWeight: 400,
          textAlign: "left",
          color: "#000",
          fontFamily: "Helvetica",
          marginTop: 10,  }}>
        <Text>
          This report is generated for the{" "}</Text><Text style={[fontBold,{ fontSize: 11}]}>Ride {periodFormat}</Text></View>
      ) : (
        <Text style={{
          fontSize: 11,
          fontWeight: 400,
          textAlign: "left",
          color: "#000",
          fontFamily: "Helvetica",
          marginTop: 10
        }}>
          This report is generated for the period {periodFormat[0]}{" "}
          {periodFormat[2] === ""
            ? `(${periodFormat[1]})`
            : `(Between ${periodFormat[1]} and ${periodFormat[2]})`}
        </Text>
      )}
    </View>
  );
}
