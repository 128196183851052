import React, { useEffect, useState } from "react";
import AdminDailySummeryController from "../../controller/AdminReport/ADDailyReportController";
import ValueFormat from "../../util/ValueFormat";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RideCountChart from "../Admin/Charts/rideCountChart";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import RiskCountChart from "../Admin/Charts/riskCountChart";
import { Link } from "react-router-dom";
import Loader from "../../components/Common/loader";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { displayText } from "../../constant/MessageConstant";

const RiskAndRidesAdminDash = () => {
  const [showLoader, setShowLoader] = useState(0);
  const [rccData, setRccData] = useState(null);
  const [rcChart, setRcChart] = useState(null);
  const [indexStart, setIndexStart] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setDataLength] = useState(null);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  const pageSize = 4;
  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };

  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };
  const [rideRiskData, setRideRiskData] = useState({
    rideRiskXaxis: [],
    rideSeries: [],
    riskAmberSeries: [],
    riskRedSeries: [],
    filter: "",
  });
  const [filter, setFilter] = useState("CURRENT_YEAR");

  useEffect(() => {
    const getRiskRideCount = async () => {
      setRccData(null);

      setShowLoader((current) => current + 1);
      var result = await AdminDailySummeryController.adminDailyRideRiskCount(
        filter,
        indexStart,
        pageSize,
        startDateTime,
        endDateTime
      );
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        var xAxisData = [];
        var rideCount = [];
        var redAlertCount = [];
        var amberAlertCount = [];
        setDataLength(resultJSONData.length);
        // console.log("getRiskRideCount :" + JSON.stringify(resultJSONData));
        resultJSONData.map((apiData) => {
          var firstName = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetterAndFormat(apiData.firstName)
          );
          var rideCountTemp = ValueFormat.parseAsInt(apiData.rideCount);
          var alertCountList = ValueFormat.parseAsObject(
            apiData.riskAlertCount
          );
          var redCountTemp = ValueFormat.parseAsInt(
            alertCountList.highRiskCount
          );
          var amberCountTemp = ValueFormat.parseAsInt(
            alertCountList.mediumRiskCount
          );

          xAxisData.push(firstName);
          rideCount.push(rideCountTemp);
          redAlertCount.push(redCountTemp);
          amberAlertCount.push(amberCountTemp);
        });
        setRcChart(Object.values(rideCount).some((value) => value !== 0));
        setRccData(
          Object.values(redAlertCount).some((value) => value !== 0) ||
            Object.values(amberAlertCount).some((value) => value !== 0)
        );
        setRideRiskData((prev) => {
          return {
            ...prev,
            rideRiskXaxis: xAxisData,
            rideSeries: rideCount,
            riskAmberSeries: amberAlertCount,
            riskRedSeries: redAlertCount,
            filter: filter,
          };
        });
      } else {
        setShowLoader((current) => current - current);
        setRcChart(false);
        setRccData(false);
        var code = resultJSON.error.code;
        if (code == "E1206") {
          setRcChart("failed");
          setRccData("failed");
        }
      }
    };
    getRiskRideCount();
  }, [indexStart, filter, startDateTime, endDateTime]);

  return (
    <div>
      {showLoader > 0 && <Loader />}
      <Row>
        <Col xl={6}>
          <Card style={{ height: 500 }}>
            <CardHeader className="cardHeader">
              <div className="d-flex flex-wrap align-items-center mb-0">
                <h5 className="card-title me-2">Rides</h5>
                <div className="ms-auto">
                  <div>
                    <button
                      data-tooltip-id="cds"
                      type="button"
                      className={
                        filter == "CURRENT_DAY"
                          ? "btn btn-primary btn-sm"
                          : "btn btn-soft-primary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_DAY")}
                    >
                      CD
                      <ReactTooltip
                        id="cds"
                        place="top"
                        content="Current Day"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      data-tooltip-id="cws"
                      className={
                        filter == "CURRENT_WEEK"
                          ? "btn btn-secondary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_WEEK")}
                    >
                      CW
                      <ReactTooltip
                        id="cws"
                        place="top"
                        content="Current Week"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      data-tooltip-id="cms"
                      className={
                        filter == "CURRENT_MONTH"
                          ? "btn btn-secondary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_MONTH")}
                    >
                      CM
                      <ReactTooltip
                        id="cms"
                        place="top"
                        content="Current Month"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      data-tooltip-id="cys"
                      className={
                        filter == "CURRENT_YEAR"
                          ? "btn btn-primary btn-sm"
                          : "btn btn-soft-primary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_YEAR")}
                    >
                      CY
                      <ReactTooltip
                        id="cys"
                        place="top"
                        content="Current Year"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      className={
                        filter == "CUSTOM_DURATION"
                          ? "btn btn-secondary btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      data-tooltip-id="dfdf"
                    >
                      <FontAwesomeIcon icon={faClock} />
                      <ReactTooltip
                        id="dfdf"
                        place="top"
                        content="Custom Date Selection"
                        style={{ background: "black" }}
                      />
                    </button>{" "}
                    <Link to={"/exportRisk"}>
                      <button
                        className="btn btn-soft-primary btn-sm ms-1"
                        data-tooltip-id="err"
                      >
                        Export Rides & Risks
                        <ReactTooltip
                          id="err"
                          place="top"
                          content="Export Rides & Risks"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </CardHeader>
            {rcChart === true ? (
              <CardBody>
                <RideCountChart rideRiskData={rideRiskData} />
                <div className="d-flex justify-content-between mt-4">
                  <button
                    style={{
                      visibility: indexStart === 0 ? "hidden" : "visible",
                      width: "45px",
                    }}
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                  >
                    Prev
                  </button>
                  <div className="pagination">
                    <a href="#">{pageNumber}</a>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility:
                        dataLength === 0 || dataLength < 4
                          ? "hidden"
                          : "visible",
                      width: "45px",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              </CardBody>
            ) : rcChart === false ? (
              <CardBody>
                <p className="data_not_available">
                  {displayText.DATA_NOT_AVAILABLE}
                </p>
                <RideCountChart rideRiskData={rideRiskData} />
                <br />
                <div className="d-flex justify-content-between">
                  <button
                    style={{
                      visibility: indexStart === 0 ? "hidden" : "visible",
                      width: "45px",
                    }}
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                  >
                    Prev
                  </button>
                  <div className="pagination">
                    <a href="#">{pageNumber}</a>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility:
                        dataLength === 0 || dataLength < 4
                          ? "hidden"
                          : "visible",
                      width: "45px",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              </CardBody>
            ) : rcChart === "failed" ? (
              <CardBody>
                <p className="data_not_available">{displayText.ER_1206}</p>
                <RideCountChart rideRiskData={rideRiskData} />
                <br />
                <div className="d-flex justify-content-between">
                  <button
                    style={{
                      visibility: indexStart === 0 ? "hidden" : "visible",
                      width: "45px",
                    }}
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                  >
                    Prev
                  </button>
                  <div className="pagination">
                    <a href="#">{pageNumber}</a>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility:
                        dataLength === 0 || dataLength < 4
                          ? "hidden"
                          : "visible",
                      width: "45px",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                <RideCountChart rideRiskData={rideRiskData} />
                <br />
                <div className="d-flex justify-content-between">
                  <button
                    style={{
                      visibility: indexStart === 0 ? "hidden" : "visible",
                      width: "45px",
                    }}
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                  >
                    Prev
                  </button>
                  <div className="pagination">
                    <a href="#">{pageNumber}</a>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility:
                        dataLength === 0 || dataLength < 4
                          ? "hidden"
                          : "visible",
                      width: "45px",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              </CardBody>
            )}
          </Card>
          <DateTimePickerPopup
            modalOpen={modalIsOpen}
            modalClose={setIsOpen}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setFilter={setFilter}
          />
        </Col>

        <Col xl={6}>
          <Card style={{ height: 500 }}>
            <CardHeader className="cardHeader">
              <h4 className="card-title mb-2">Risks </h4>
            </CardHeader>
            {rccData === true ? (
              <CardBody>
                <RiskCountChart rideRiskData={rideRiskData} />
              </CardBody>
            ) : rccData === false ? (
              <CardBody>
                <p className="data_not_available">
                  {displayText.DATA_NOT_AVAILABLE}
                </p>
                <RiskCountChart rideRiskData={rideRiskData} />
              </CardBody>
            ) : rccData === "failed" ? (
              <CardBody>
                <p className="data_not_available">{displayText.ER_1206}</p>
                <RiskCountChart rideRiskData={rideRiskData} />
              </CardBody>
            ) : (
              <CardBody>
                <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                <RiskCountChart rideRiskData={rideRiskData} />
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RiskAndRidesAdminDash;
