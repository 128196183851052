import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";

const Doughnut = ({ urhData }) => {
  const getOption = () => {
    let urbanValue =
      (parseFloat(urhData.urbanKilometers) /
        (parseFloat(urhData.ruralKilometers) +
          parseFloat(urhData.urbanKilometers) +
          parseFloat(urhData.highwayKilometers))) *
      100;
    // console.log(urhData,"urbanValue")
    let ruralValue =
      (parseFloat(urhData.ruralKilometers) /
        (parseFloat(urhData.ruralKilometers) +
          parseFloat(urhData.urbanKilometers) +
          parseFloat(urhData.highwayKilometers))) *
      100;
    let highValue =
      (parseFloat(urhData.highwayKilometers) /
        (parseFloat(urhData.ruralKilometers) +
          parseFloat(urhData.urbanKilometers) +
          parseFloat(urhData.highwayKilometers))) *
      100;
    return {
      toolbox: {
        show: !1,
      },
      tooltip: {
        trigger: "item",
        // formatter: "{a} <br/>{b}: {c} ({d}%)",
        formatter: function (parms) {
          const customToolTipUrban =
            '<div style="padding:10px,textAlign:left;> <div class="mb-2">' +
            parms.data.name +
            ": " +
            parms.data.value +
            "%" +
            "</div>" +
            '<div class="mb-2">Kilometer : ' +
            parms.data.kilometer +
            " kms" +
            "</div> </div>";
          // alert(JSON.stringify(parms))
          return customToolTipUrban;
        },
      },
      legend: {
        orient: "horizontal",
        x: "left",
        data: ["Rural", "Urban", "Highway"],
        textStyle: {
          // color: ["#858d98"],
          fontSize: "10px",
        },
      },
      color: ["#717BCF", "#5BAFAD", "#fd625e"],
      series: [
        {
          name: "",
          type: "pie",
          radius: ["42%", "70%"],
          avoidLabelOverlap: !1,
          label: {
            normal: {
              show: !1,
              position: "center",
            },
            emphasis: {
              show: !0,
              textStyle: {
                fontSize: "20",
                fontWeight: "bold",
              },
            },
          },
          label: {
            show: true,
            position: "inside",
            color: "white",
            fontSize: 8,
            align: "center", // Align the label horizontally
            verticalAlign: "middle",
            // offset: [2, -2, 0, 0], 
            formatter: function (parms) {
              if (parms.data.value > 0) {
                var customToolTipUrban = parms.data.value + "%";
                return customToolTipUrban;
              } else {
                var customToolTipUrban = " ";
                return customToolTipUrban;
              }
            },
          },
          labelLine: {
            normal: {
              show: !1,
            },
          },
          data: [
            {
              value: ruralValue.toFixed(2),
              name: "Rural",
              kilometer: urhData.ruralKilometers,
            },
            {
              value: urbanValue.toFixed(2),
              name: "Urban",
              kilometer: urhData.urbanKilometers,
            },
            {
              value: highValue.toFixed(2),
              name: "Highway",
              kilometer: urhData.highwayKilometers,
            },
          ],
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "235px" }} option={getOption()} />
    </React.Fragment>
  );
};

export default Doughnut;
