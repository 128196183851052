import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
// import UtilDateTime from "../../util/UtilDateTime.js";
import { SC_COMPANY_ID } from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let AnalyticalController = {
  async alertSummary(rideId, divisionId, userId) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE_DATA",
        deviceId: rideId,
        category: "ALERT_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async alertSummaryWithRideCategory(rideId, divisionId, userId, rideCategory) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var queryFields = {travelType: rideCategory};
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE_DATA",
        deviceId: rideId,
        category: "ALERT_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
        queryFields: JSON.stringify(queryFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async rideCompleteSummary(indexStart, rideId, divisionId, userId, filterChart, noOfRecordsPerPage, rideCategory ) {
    const queryFields = {
      manualData: filterChart.manualData,
      stressStrainData: filterChart.stressStrainData,
      alertData: filterChart.alertData,
      // scoreValidationStatus: "SUCCESS",
      travelType: rideCategory
    };
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE_DATA",
        deviceId: rideId,
        category: "STRESS_STRAIN_DATA,MANUAL_DATA,ALERT_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: indexStart,
        limit: '1000',
        queryType: "UD_SINGLE_USER_ALL_RIDE_DATA_LIST",
        queryFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async getRiskDistributionSummary(divisionId, userId, rideId, rideCategory) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var dashboardFields = {travelType: rideCategory}
    if (
      companyId.length > 0 &&
      divisionId.length > 0 &&
      userId.length > 0 &&
      rideId.length > 0
    ) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        deviceId: rideId,
        type: "SPEEDO_METER_DEVICE_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        dashboardType: "AD_MULTI_USER_RISK_DISTRIBUTION_COUNT",
        dashboardFields: JSON.stringify(dashboardFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async overSpeedMCUMSUListAPI(rideId, divisionId, userId, rideCategory) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var queryFields = {travelType: rideCategory}
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        divisionId: divisionId,
        companyId: companyId,
        userId: userId,
        deviceId: rideId,
        category: "MANUAL_DATA",
        type: "SPEEDO_METER_DEVICE_DATA",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: '0',
        limit: '1000',
        queryType: "AD_SINGLE_USER_RIDE_DATA_LIST",
        queryFields: JSON.stringify(queryFields)
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
};

export default AnalyticalController;
