import React, { Component, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import MyRideDetailController from "../../../controller/MyRideDetail/MyRideDetail.js";
import { useLocation, useNavigate } from "react-router-dom";
import ValueFormat from "../../../util/ValueFormat.js";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { displayText } from "../../../constant/MessageConstant.js";
import Converter from "../../../components/Common/EncodeDecode.js";

const Line = () => {
  const [zoomBarShow, setZoomBarShow] = useState("enabled");
  const [showPointer, setShowPointer] = useState("enabled");

  useEffect(() => {
    const updatedParentObject = {
      ...lineOption,
      dataZoom: lineOption.dataZoom.map((obj, i) => {
        if (i === 1) {
          return { ...obj, show: zoomBarShow == "enabled" };
        }alert
        return obj;
      }),
      series: { ...lineOption.series, showSymbol: showPointer == "enabled" },
    };
    setLineOption(updatedParentObject)
    // console.log("hhhhhh" + JSON.stringify(lineOption))
  }, [zoomBarShow, showPointer])

  const getOption = {
    title: {
      left: "1%",
    },

    tooltip: {
      trigger: "item",
      formatter: function (parms) {
        const customToolTip =
          '<div style="padding:10px,textAlign:left;> <div class="mb-2">Risk : ' +
          parms.data.value +
          "</div>" +
          '<div class="mb-2">Speed : ' +
          parms.data.speed +
          " kmph" +
          '<div>Time: ' + parms.data.time + " (hh:mm:ss)"+
          "</div> </div>";
        // alert(JSON.stringify(parms))
        return customToolTip;
      },
    },

    grid: {
      top: "15%",
      bottom: "20%",
      left: 35,
      right: 35,
    },

    xAxis: {
      data: [0],
      axisTick: {
        alignWithLabel: true,
      },
      type: "category",
      name: "Kms",
      nameLocation: "bottom",
      nameGap: 0,
      nameTextStyle: {
        align: "center",
        verticalAlign: "top",
        padding: [25, 0, 0, 0],
      },
    },

    yAxis: {
      name: "                     Risk (Static Context)", //don't remove the space (-_-)
      nameGap: 50,
      type: "value",
      min: 0,
      max: 100,
      axisLabel: {
        align: "center",
        fontSize: "12px",
        padding: [0, 8, 0, 0],
      },
      nameTextStyle: {
        align: "center",
      },
    },

    dataZoom: [
      {
        type: "inside",
        xAxisIndex: [0, 1],
        start: 0,
        end: 100,
        height: 10,
        fillerColor: "rgba(46, 50, 56, 0.05)",
        borderColor: "rgba(46, 50, 56, 0)",
        textStyle: {
          color: "rgba(28, 31, 35, 0.6)",
          fontFamily: "Inter",
        },
        dataBackground: {
          areaStyle: {
            color: "rgba(46, 50, 56, 0.09)",
          },
        },
        handleIcon:
          "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
      },

      {
        show: false,
        xAxisIndex: [0, 1],
        type: "slider",
        top: "90%",
        left: "15%",
        right: "15%",
        start: 0,
        end: 100,
        height: 10,
        fillerColor: "rgba(46, 50, 56, 0.05)",
        borderColor: "rgba(46, 50, 56, 0)",
        textStyle: {
          color: "rgba(28, 31, 35, 0.6)",
          fontFamily: "Inter",
        },

        dataBackground: {
          areaStyle: {
            color: "rgba(46, 50, 56, 0.09)",
          },
        },
        handleIcon:
          "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
      },
    ],

    visualMap: {
      top: 0,
      right: 20,
      orient: "horizontal",
      pieces: [
        {
          gt: 0,
          lte: 70,
          color: "#19543E",
        },
        {
          gt: 70,
          lte: 90,
          color: "#FFBF00",
        },
        {
          gt: 90,
          lte: 100,
          color: "#cc3333",
        },
      ],
      outOfRange: {
        color: "#999",
      },
    },

    series: {
      animationDuration: 4000,
      showSymbol: false,
      smooth: false,
      lineStyle: { normal: { width: 1.4 } },
      animationEasing: "easein",
      name: "Risk",
      type: "line",
      data: [0],
      markLine: {
        silent: false,
        lineStyle: {
          color: "#d3d3d3",
        },
      },
    },
  };
  const history = useNavigate();

  // const location = useLocation();
  const [lineOption, setLineOption] = useState(getOption);
  const [locationParams, setLocationParams] = useState({
    rideId: "",
    userId: "",
    divisionId: "",
  });
  const [tripRiskChart, setTripRiskChart] = useState(false);
  const [cardHeaders, setCardHeaders] = useState(true);

  // useEffect(() => {
  //   // if (location.state != null) {
  //   //   const { rideId, userId, divisionId } = location.state;
  //   //   setLocationParams((prev) => {
  //   //     return {
  //   //       ...prev,
  //   //       rideId: rideId,
  //   //       userId: userId,
  //   //       divisionId: divisionId,
  //   //     };
  //   //   });
  //   // } else {
  //   //   // history("/myRides");
  //   // }
  //   const storedData = localStorage.getItem('rideDetails');
  //   setLocationParams((prev) => {
  //         return {
  //           ...prev,
  //           rideId: JSON.parse(storedData).rideId,
  //           userId: JSON.parse(storedData).userId,
  //           divisionId: JSON.parse(storedData).divisionId,
  //         };
  //       })
  // }, []);

  const Location = useLocation();
  const [rideCategory, setRideCategory] = useState(""); // ridecategory added

  useEffect(() => {

    const queryParams = new URLSearchParams(Location.search);

    const UID = queryParams.get('userId');
    const DID = queryParams.get('divisionId');
    const RID = queryParams.get('rideId');
    const RIDE_CATEGORY = queryParams.get("rideCategory"); // ridecategory added

    const decodedUID = Converter.decrypt(decodeURIComponent(UID));
    const decodedDID = Converter.decrypt(decodeURIComponent(DID));
    const decodedRID = Converter.decrypt(decodeURIComponent(RID));
    const decodedRIDE_CATEGORY = Converter.decrypt(decodeURIComponent(RIDE_CATEGORY));
    setRideCategory(decodedRIDE_CATEGORY); // ridecategory added

    setLocationParams((prev) => {
      return {
        ...prev,
        rideId: decodedRID,
        userId: decodedUID,
        divisionId: decodedDID,
      };
    })

  }, [Location]);

  const getTripRiskData = async () => {
    var lineChartSingleUserDataTemps = [];
    var xAxisData = [];
    var kmValue = [];

    setTripRiskChart(null);

    var result = await MyRideDetailController.getDashboardRiskRideDataList(
      locationParams.rideId,
      locationParams.divisionId,
      locationParams.userId,
      rideCategory // ridecategory added
    );
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if(status == "SUCCESS"){
    var resultJSONData = resultJSON.data;
    // console.log("UD ride details  :" + JSON.stringify(resultJSONData))
    if (resultJSONData.length > 0) {
      setTripRiskChart(null);

      var noOfRecords = resultJSONData.length;

      for (let rdCount = 0; rdCount < resultJSONData.length; rdCount++) {
        var dDataField = ValueFormat.parseAsObject(
          resultJSONData[rdCount].deviceDataField
        );
        var riskValueTemp = ValueFormat.parseAsFloat(dDataField.risk, 2);
        var kmValueTemp = ValueFormat.parseAsFloat(dDataField.kiloMeter, 2);
        var speedValueTemp = ValueFormat.parseAsFloat(dDataField.speed, 2);
        var timeDateRiskTemp = ValueFormat.parseAsString(resultJSONData[rdCount].createdAtToTimeZone);
        var timeRiskTemp = timeDateRiskTemp.split(" ")[1];
        var speedRiskData = { value: riskValueTemp, speed: speedValueTemp, time: timeRiskTemp };
        if (riskValueTemp > 0 || rdCount == 0 || rdCount == noOfRecords - 1) {
          kmValue.push(kmValueTemp);
          lineChartSingleUserDataTemps.push(speedRiskData);
          xAxisData.push(kmValueTemp);
        }

        setTripRiskChart(true);
      }
      setLineOption((updateData) => {
        const updatedParentObject = {
          ...updateData,
          xAxis: { ...updateData.xAxis, data: xAxisData },
          series: { ...updateData.series, data: lineChartSingleUserDataTemps },
        };
        return {
          ...updateData,
          ...updatedParentObject,
        };
      });
    } else {
      setTripRiskChart(false);
    }
  }else{
    var code = resultJSON.error.code;
    if(code == "E1206"){
      setTripRiskChart("failed")
      
    }

  }
  }

  useEffect(() => {
    getTripRiskData();
  }, [locationParams]);

  const [chartWidth, setChartWidth] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 460){
        setChartWidth("88vw");
      } else if (window.innerWidth >= 461 && window.innerWidth <= 991 ) {
        setChartWidth("94vw");
      } else {
        setChartWidth("48vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
        <Card>
          <CardHeader className="cardHeader" style={{padding:"5px"}}>
            {/* <div className="d-flex flex-wrap align-items-center mb-0"> */}
              <h5 className="card-title">Trip Risk Chart</h5>
              {/* <div className="ms-auto">
                <button               
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={() => {
                    setCardHeaders(!cardHeaders);
                  }}
                >
                  Filter
                 
                </button>{" "}
              </div>
            </div> */}
            {/* {cardHeaders && (
              <Row className="mt-2 mb-2">
                <div className="d-flex mb-2">
                <Col xl={3} className="me-4">
                  <div>
                    <span style={{ fontSize: "13px", fontWeight: 500, whiteSpace:'nowrap' }}>
                      X - axis scroll
                    </span>
                    <div className="mt-1">
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        value={zoomBarShow}
                        onChange={(e) => {
                          setZoomBarShow(e.target.value);
                        }}
                      >
                        <option
                          value={"disabled"}
                          style={{ textAlign: "left" }}
                        >
                          Disabled
                        </option>
                        <option value={"enabled"} style={{ textAlign: "left" }}>
                          Enabled
                        </option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col xl={3}>
                  <div>
                    <span style={{ fontSize: "13px", fontWeight: 500, whiteSpace:'nowrap' }}>
                      Show pointer
                    </span>
                    <div className="mt-1">
                      <select
                        className="btn btn-soft-secondary btn-sm"
                        name="cars"
                        id="cars"
                        value={showPointer}
                        onChange={(e) => {
                          setShowPointer(e.target.value);
                        }}
                      >
                        <option
                          value={"disabled"}
                          style={{ textAlign: "left" }}
                        >
                          Disabled
                        </option>
                        <option value={"enabled"} style={{ textAlign: "left" }}>
                          Enabled
                        </option>
                      </select>
                    </div>
                  </div>
                </Col>
                </div>
              </Row>
            )} */}
          </CardHeader>
          <CardBody style={{height:'615px'}}>
            {tripRiskChart === true ? (
              <ReactEcharts
                style={{ height: "595px", width: chartWidth }}
                option={lineOption}
              />
            ) : tripRiskChart === false ? (
              <>
                <p className="data_not_available">
                  {displayText.DATA_NOT_AVAILABLE}
                </p>
                <ReactEcharts
                  style={{ height: "556.3px", width: chartWidth }}
                  option={lineOption}
                />
              </>
            ) : (
              <>
                <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                <ReactEcharts
                  style={{ height:"556.3px", width: chartWidth }}
                  option={lineOption}
                />
              </>
            )}
          </CardBody>
        </Card>
    </React.Fragment>
  );
};

export default Line;
