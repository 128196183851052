import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";
import {
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_LOGIN_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import { Link, useLocation } from "react-router-dom";
import RiskCountChart from "../RiskCountChart";
import Loader from "../../../components/Common/loader";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import OverSpeedLocations from "../OverSpeedLocation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UserDashboardSubMenu.css";
import UserDashboardSubMenu from "./UserDashboardSubMenu.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import UserAdminDashboardSubMenu from "./UserAdminDashboardSubMenu.js";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import "./Section2.css";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";

const Section2 = () => {
  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [osMcuMsuProps, setOsMcuMsuProps] = useState([]);
  const [aCBProps, setACBProps] = useState([]);
  const [riskAlertPdf, setRiskAlertPdf] = useState([]);
  const [rccProps, setRccProps] = useState({});
  const { pathname } = useLocation();
  const [aCBDataProps, setACBDataProps] = useState([]);
  const [osDataProps, setOsDataProps] = useState([]);
  const [msuPdfDataProps, setMsuPdfDataProps] = useState([]);
  const [mcuPdfDataProps, setMcuPdfDataProps] = useState([]);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const containerIds = ["container1", "container2"];

  //meta title

  const getOsMcuMsuProps = (data) => {
    setOsMcuMsuProps(data);
  };
  const getACBProps = (data) => {
    setACBProps(data);
  };

  const getRiskAlertPdf = (data) => {
    setRiskAlertPdf(data);
  };

  const getRCCProps = (data) => {
    setRccProps(data);
  };

  const getACBDataProps = (data) => {
    setACBDataProps(data);
  };

  const getOSDataProps = (data) => {
    setOsDataProps(data);
  };

  const getMcuDataProps = (data) => {
    setMsuPdfDataProps(data);
  };

  const getMsuDataProps = (data) => {
    setMcuPdfDataProps(data);
  };

  return (
    <React.Fragment>
      {showLoader > 0 && <Loader />}
      <div className="page-content containerSticky">
        {/* <div className="ps-2 pe-2"> */}
        <Container fluid id="division">
          <div id="container1">
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                style={{ position: "fixed", zIndex: 1, background: "#fff" }}
                className="shadow-sm "
              >
                <Row>
                  <Col xl={6} className="py-2">
                    {loginType == "ADMIN" || loginType == "SUPER_ADMIN" ? (
                      <>
                        <div
                          className="sticky-header sticky-div"
                          style={{
                            position: "fixed !important",
                            zIndex: 1,
                            top: 0,
                          }}
                        >
                          <UserAdminDashboardSubMenu />
                        </div>
                      </>
                    ) : (
                        <UserDashboardSubMenu />
                    )}
                  </Col>
                  <Col
                    xl={6}
                    className="py-2"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                        alignItems:"center",
                      }}
                    >
                     
                        <div className="ms-auto pe-2 border-end border-secondary">
                          <div style={{ float: "right" }}>
                            <button
                              data-tooltip-id="cds"
                              type="button"
                              className={
                                (filters.durationFilter == "CURRENT_DAY" && !modalIsOpen)
                                  ? "btn btn-primary btn-sm me-1"
                                  : "btn btn-soft-secondary btn-sm me-1"
                              }
                              onClick={() =>{
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "CURRENT_DAY",
                                })
                              }
                              }
                            >
                              CD
                              <ReactTooltip
                                id="cds"
                                place="bottom"
                                content="Current Day"
                                style={{ backgroundColor: "black" }}
                              />
                            </button>{" "}
                            <button
                              data-tooltip-id="cws"
                              type="button"
                              className={
                                (filters.durationFilter == "CURRENT_WEEK" && !modalIsOpen)
                                  ? "btn btn-primary btn-sm me-1"
                                  : "btn btn-soft-secondary btn-sm me-1"
                              }
                              onClick={() =>{
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "CURRENT_WEEK",
                                })
                              }}
                            >
                              CW
                              <ReactTooltip
                                id="cws"
                                place="bottom"
                                content="Current Week"
                                style={{ backgroundColor: "black" }}
                              />
                            </button>{" "}
                            <button
                              data-tooltip-id="cms"
                              type="button"
                              className={
                                (filters.durationFilter == "CURRENT_MONTH" && !modalIsOpen)
                                  ? "btn btn-primary btn-sm me-1"
                                  : "btn btn-soft-secondary btn-sm me-1"
                              }
                              onClick={() =>{
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "CURRENT_MONTH",
                                })
                              }}
                            >
                              CM
                              <ReactTooltip
                                id="cms"
                                place="bottom"
                                content="Current Month"
                                style={{ backgroundColor: "black" }}
                              />
                            </button>{" "}
                            <button
                              data-tooltip-id="cys"
                              type="button"
                              className={
                                (filters.durationFilter == "CURRENT_YEAR" && !modalIsOpen)
                                  ? "btn btn-primary btn-sm me-1"
                                  : "btn btn-soft-secondary btn-sm me-1"
                              }
                              onClick={() =>{
                                setCount(0);
                                setStartDateTime("");
                                setEndDateTime("");
                                setFilters({
                                  ...filters,
                                  durationFilter: "CURRENT_YEAR",
                                })
                              }}
                            >
                              CY
                              <ReactTooltip
                                id="cys"
                                place="bottom"
                                content="Current Year"
                                style={{ backgroundColor: "black" }}
                              />
                            </button>{" "}
                            <button
                              type="button"
                              className={
                                (filters.durationFilter == "CUSTOM_DURATION" || modalIsOpen)
                                  ? "btn btn-primary btn-sm "
                                  : "btn btn-soft-secondary btn-sm "
                              }
                              onClick={() => {
                                setIsOpen(true);
                                setCount(1);
                              }}
                              data-tooltip-id="dfdf"
                            >
                              <FontAwesomeIcon icon={faClock} />
                              <ReactTooltip
                                id="dfdf"
                                place="bottom"
                                content="Custom Date Selection"
                                style={{ backgroundColor: "black" }}
                              />
                            </button>{" "}
                          </div>
                        </div>

                        {(loginType == "ADMIN" ||
                          loginType == "SUPER_ADMIN" ||
                          loginType == "SUPER_ADMIN_AMAZON") && (
                          <div className="border-end border-secondary pe-2">
                            <Link to={"/myRides"}>
                              <button
                                data-tooltip-id="gtr"
                                className="btn btn-primary btn-sm"
                              >
                                Go To Rides
                                <ReactTooltip
                                  id="gtr"
                                  place="bottom"
                                  content="Go To Rides"
                                  style={{
                                    zIndex: "100",
                                    backgroundColor: "black",
                                  }}
                                />
                              </button>
                            </Link>
                          </div>
                        )}

                        <div
                         className="border-end border-secondary pe-2"
                        >
                          <ExportScreenPdf
                            containerIds={containerIds}
                            fileName={
                              loginType == "ADMIN" || loginType == "SUPER_ADMIN"
                                ? userFirstName + "'s" + " Risk Alert Summary"
                                : userName + "'s" + " Risk Alert Summary"
                            }
                          />
                        </div>
                        <div>
                        <ViewPdf riskAlertPdf={riskAlertPdf} aCBDataProps={aCBDataProps} rccProps={rccProps} osMcuMsuProps={osMcuMsuProps} aCBProps={aCBProps}  osDataProps={osDataProps} msuPdfDataProps={msuPdfDataProps} mcuPdfDataProps={mcuPdfDataProps} section2filter={filters}/>

                        </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              className="sticky-div adminmenu-mt"
            >
              <Col xl={12}>
                <RiskCountChart
                  filter={filters}
                  getRCCProps={getRCCProps}
                  getOsMcuMsuProps={getOsMcuMsuProps}
                  getACBProps={getACBProps}
                  getRiskAlertPdf={getRiskAlertPdf}
                  getACBDataProps={getACBDataProps}
                  getOSDataProps={getOSDataProps}
                  getMcuDataProps={getMcuDataProps}
                  getMsuDataProps={getMsuDataProps}
                  loaderCount={setShowLoader}
                />
              </Col>
            </Row>
          </div>
          <div id="container2">
            <Row>
              <OverSpeedLocations />
            </Row>
          </div>
        </Container>
        {/* </div> */}
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filters.durationFilter}
        />
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

export default Section2;
