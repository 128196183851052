const riskAlertSubCategory = [
  // {"category" : "ALERT_DATA", "subCategory" : ""},
  {"category" : "ALERT_DATA", "subCategory" : "ANIMAL_CROSSING", "label" : "Animal Crossing"},
  {"category" : "ALERT_DATA", "subCategory" : "CAUTION", "label" : "Caution"},      
  {"category" : "ALERT_DATA", "subCategory" : "CURVE", "label" : "Curve"},    
  // {"category" : "ALERT_DATA", "subCategory" : "ROUND_ABOUT", "label" : "Round About"},    
  {"category" : "ALERT_DATA", "subCategory" : "HILL", "label" : "Hill"},    
  {"category" : "ALERT_DATA", "subCategory" : "HILL_DOWNWARDS", "label" : "Hill Downwards"},    
  {"category" : "ALERT_DATA", "subCategory" : "HILL_UPWARDS", "label" : "Hill Upwards"},      
  {"category" : "ALERT_DATA", "subCategory" : "ICY_CONDITIONS", "label" : "Icy Condition"},    
  {"category" : "ALERT_DATA", "subCategory" : "INTERSECTION", "label" : "Intersection"},    
  {"category" : "ALERT_DATA", "subCategory" : "LANE_MERGE", "label" : "Lane Merge"},    
  {"category" : "ALERT_DATA", "subCategory" : "LOW_GEAR_AREA", "label" : "Low Gear Area"},    
  {"category" : "ALERT_DATA", "subCategory" : "NARROW_ROAD", "label" : "Narrow Road"},    
  {"category" : "ALERT_DATA", "subCategory" : "NO_OVERTAKING", "label" : "No Overtaking"},    
  {"category" : "ALERT_DATA", "subCategory" : "PEDESTRIAN_CROSSING", "label" : "Pedestrian Crossing"},
  {"category" : "ALERT_DATA", "subCategory" : "PRIORITY", "label" : "Priority"},
  {"category" : "ALERT_DATA", "subCategory" : "RAILWAY_CROSSING", "label" : "Railway Crossing"},
  {"category" : "ALERT_DATA", "subCategory" : "RISK_OF_GROUNDING", "label" : "Risk Grounding"},
  {"category" : "ALERT_DATA", "subCategory" : "ROUND_ABOUT", "label" : "Round About"},
  {"category" : "ALERT_DATA", "subCategory" : "SCHOOL_ZONE", "label" : "School Zone"},
  {"category" : "ALERT_DATA", "subCategory" : "SLIPPERY_ROADS", "label" : "Slippery Roads"},
  {"category" : "ALERT_DATA", "subCategory" : "STOP_SIGN", "label" : "Stop Sign"},
  {"category" : "ALERT_DATA", "subCategory" : "TRAFFIC_LIGHT", "label" : "Traffic Light"},
  {"category" : "ALERT_DATA", "subCategory" : "WIND", "label" : "Wind"},
  {"category" : "ALERT_DATA", "subCategory" : "WINDING_ROAD", "label" : "Winding Road"},
  {"category" : "ALERT_DATA", "subCategory" : "YIELD", "label" : "Yield"},

  // {"category" : "MANUAL_DATA", "subCategory" : ""},  
  {"category" : "MANUAL_DATA", "subCategory" : "OVER_SPEED", 'label':"Overspeed"},  
  {"category" : "MANUAL_DATA", "subCategory" : "MOBILE_USE", 'label':"Mobile Call Use"},  
  {"category" : "MANUAL_DATA", "subCategory" : "MOBILE_SCREEN", 'label':"Mobile Screen Use"},  

  // {"category" : "STRESS_STRAIN_DATA", "subCategory" : ""},  

  {"category" : "STRESS_STRAIN_DATA", "subCategory" : "SEVERE_ACCELERATION", "label":"Acceleration"},  
  {"category" : "STRESS_STRAIN_DATA", "subCategory" : "SEVERE_CORNERING","label":"Cornering"},  
  {"category" : "STRESS_STRAIN_DATA", "subCategory" : "SEVERE_BRAKING","label":"Braking"},  

];

export default riskAlertSubCategory;