import React, { useEffect, useState } from "react";
import Loader from "../../components/Common/loader";
import { 
  CardBody,
  CardHeader,
  Col,
  Container, 
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_DIVISION_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_LOGIN_USER_ID,
  SC_USER_ID,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import Report from "../../controller/Report/Report";
import { Link } from "react-router-dom";
import UserDashboardCtrlr from "../../controller/UserDashboardCtrlr";
import AdminDashboardCrashCtrlr from "../../controller/AdminDashboardCrashCtrlr";
import UtilDateTime from "../../util/UtilDateTime";

import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import FTwheelerIcon from "../../assets/images/common/twoFourWheelerIcon.svg";
import ActiveDateIcon from "../../assets/images/common/ActiveDateIcon.svg";
import i4 from "../../assets/images/adminDashboard/totalRides.svg";
import locationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import driverTypeIcon from "../../assets/images/adminDashboard/myRidesPageIcons/driverTypeIcon.svg";
import passengerTypeIcon from "../../assets/images/adminDashboard/myRidesPageIcons/passengerTypeIcon.svg";
import minsIcon from "../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import locationImageCrash from "../../assets/images/common/crashLocationImage.svg";
import AddressImageCrash from "../../assets/images/common/addressIconCrash.svg";
import ZipcodeImageCrash from "../../assets/images/common/zipcodeCrashIcon.svg";
import LatImage from "../../assets/images/common/latCrashImage.svg";
import LongImage from "../../assets/images/common/LongImageCrash.svg";


export default function AdminCrashListNew() {
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [userId, setUserId] = useState("");
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [count, setCount] = useState(0);
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [totalPageCount, setTotalPageCount] = useState();  
  const [accidentTypeFilter, setAccidentTypeFilter] = useState(""); 
  const LSuserType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const LSuserId = localStorage.getItem(SC_LOGIN_USER_ID);
  const divId = localStorage.getItem(SC_DIVISION_ID);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  let colSize1 = 3;
  let colSize2 = 3;
  let colSize3 = 3;
  let colSize4 = 3;

 

  const pageSize = 10;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };   

  const getCrashReport = async (index) => {
        
    try {
      setShowLoader(true);
      setDataLength(null);
      var result = await AdminDashboardCrashCtrlr.getAdminV2VehicleCrashList(
        index,
        filter,
        deviceFilter,
        startDateTime,
        endDateTime,
        accidentTypeFilter,        
        pageSize
      );
      var tableData = [];

      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
     
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        console.log("risk", resultJSONData);
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          for (let i = 0; i < resultJSONData.length; i++) {
            var userApp = resultJSONData[i];  
            console.log(userApp, "userappuserapp");
            

            var userDetail = ValueFormat.parseAsObject(userApp.userDetail);

            var firstName =  ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userDetail.firstName)) || "-";

            var username = ValueFormat.parseAsString(userDetail.username) || "-";

            var userStatus = ValueFormat.parseAsString(userDetail.userStatus) || "-";

            var deviceType = ValueFormat.parseAsString(userDetail.deviceType) || "-";

            var vehicleType = ValueFormat.parseAsString(userDetail.vehicleType) || "-";

            var createdAtToTimeZone = ValueFormat.parseAsString(userDetail.createdAtToTimeZone) || "-";

            var rideName =
              ValueFormat.parseAsString(userApp.deviceName) !== null &&
                ValueFormat.parseAsString(userApp.deviceName) !== undefined
                ? ValueFormat.parseAsString(userApp.deviceName)
                : "-";
                
            var distanceInKm = ValueFormat.parseAsFloat(userApp.distanceInKm,2);

            var durationInMin = ValueFormat.parseAsFloat(userApp.durationInMin,2);

            var userid = ValueFormat.parseAsString(userDetail.userId);

            var deviceId = ValueFormat.parseAsString(userApp.deviceId);

            var errorDataList = ValueFormat.parseAsArray(userApp.errorDataList);       
            
            var userData =  ValueFormat.parseAsObject(userApp.userDetail);

            var totalCrashCount = 0;

            let lastAccidentTypeMILD = 0;

            let lastAccidentTypeMedium = 0;

            let lastAccidentTypeSevere = 0;

            let lastAccidentSeverityValue = 0;

            let lastAccidentAtToTimeZone = "-";

            let lastAccidentTypeName = "-";

            let lastLocationDeviceData = "-";

            let lastCity = "-";

            let lastState = "-";

            let lastZipCode = "-";

            let lastLatitude = "-";

            let lastLongitude = "-";

            let deviceActive = "-";

            let scoreValidationStatus = "-";

            let calculatedDrivingScore = "-";

            let riskScore = "-";

            let calculatedBehaviourScore = "-";

            let category = "-";

  for (let i = 0; i < errorDataList.length; i++) {
 
  
    
    if (errorDataList[i].accidentType === "MILD_ACCIDENT") {
      
      lastAccidentTypeMILD++;
      lastAccidentTypeName = "Mild"

    } else if(errorDataList[i].accidentType === "MEDIUM_ACCIDENT"){

      lastAccidentTypeMedium++;
      lastAccidentTypeName = "Medium"

    }else if(errorDataList[i].accidentType === "SEVERE_ACCIDENT"){

      lastAccidentTypeSevere++;
      lastAccidentTypeName = "Severe"

    }

    lastAccidentSeverityValue = errorDataList[i]?.accidentSeverityValue;
    lastAccidentAtToTimeZone  = errorDataList[i]?.accidentAtToTimeZone;
    lastLocationDeviceData    = errorDataList[i]?.deviceDataAddressField?.location;
    lastCity                  = errorDataList[i]?.deviceDataAddressField?.city;
    lastState                 = errorDataList[i]?.deviceDataAddressField?.state;
    lastZipCode               = errorDataList[i]?.zipCode;
    lastLatitude              = errorDataList[i]?.latitude;
    lastLongitude             = errorDataList[i]?.longitude;
    deviceActive              = errorDataList[i]?.deviceActive || "-";
    scoreValidationStatus     = errorDataList[i]?.scoreValidationStatus || "-";
    calculatedDrivingScore    = errorDataList[i]?.calculatedDrivingScore || "0";
    riskScore                 = errorDataList[i]?.riskScore || "0";
    calculatedBehaviourScore  = errorDataList[i]?.calculatedBehaviourScore || "0";
    category                  = errorDataList[i]?.category || "-";
  }






        let deviceTypeTemp ="";
        if (userData.deviceType === "ANDROID") {
          deviceTypeTemp = "Android"
        } else if (userData.deviceType === "ANDROID_SDK"){
          deviceTypeTemp = "Android SDK"
        } else if (userData.deviceType === "IOS") {
          deviceTypeTemp = "iOS"
        } else if (userData.deviceType === "IOS_SDK"){
          deviceTypeTemp = "iOS SDK"
        } else  {
          deviceTypeTemp = "N/A"
        }
            
            

            var locationDetails = {
              address: "-",
              zipCode: "-",
              latitude: "",
              longitude: "",
              location: "-",
            };

            userApp.accidentKeyValues.forEach((item) => {
              if (item.key === "address")
                locationDetails.address =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "zipCode")
                locationDetails.zipCode =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "latitude")
                locationDetails.latitude =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "longitude")
                locationDetails.longitude =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "location")
                locationDetails.location =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
            });

            var totalCounts = ValueFormat.parseAsInt(userApp.totalCount);
            
            setTotalPageCount(Math.ceil(totalCounts / pageSize));

            setNextShow(totalCounts - index > pageSize);

            var crashDatadetails = {
              firstName: firstName,
              username: username,
              userStatus: userStatus,
              deviceType: deviceType,
              vehicleType: vehicleType == "FOUR_WHEELER" ? "4W" : vehicleType == "TWO_WHEELER" ? "2W" : "-",
              createdAtToTimeZone : createdAtToTimeZone,
              rideName: rideName,
              distanceInKm:distanceInKm,
              durationInMin:durationInMin,
              lastAccidentSeverityValue: ValueFormat.parseAsFloat(lastAccidentSeverityValue,2),
              lastAccidentAtToTimeZone: UtilDateTime.formatDateInt(lastAccidentAtToTimeZone),
              lastAccidentAtToTime: formatTime(lastAccidentAtToTimeZone),
              lastLocationDeviceData:lastLocationDeviceData,
              lastState:lastState,
              lastCity:lastCity,
              lastZipCode:lastZipCode,
              lastLatitude:lastLatitude,
              lastLongitude:lastLongitude,
              lastAccidentTypeName: lastAccidentTypeName,            
              lastAccidentTypeMILD:lastAccidentTypeMILD,
              lastAccidentTypeMedium: lastAccidentTypeMedium,
              lastAccidentTypeSevere:lastAccidentTypeSevere,     
              deviceActive:deviceActive,
              scoreValidationStatus:scoreValidationStatus,
              calculatedDrivingScore:calculatedDrivingScore,
              riskScore:riskScore,
              calculatedBehaviourScore:calculatedBehaviourScore,
              category:category,
              totalCrashCount:(lastAccidentTypeMILD + lastAccidentTypeMedium + lastAccidentTypeSevere),           
              device: deviceTypeTemp,
              ...locationDetails,             
              userid: userid,
              deviceId: deviceId
            };
            tableData.push(crashDatadetails);
            setApiData(tableData);
          }
        }
      } else {       
        setDataLength(0);
        console.log("Status failed");
      }
    } catch (errMes) {
      console.log("Crash Report Catch Error: ", errMes);
    } finally {
      setShowLoader(false);
    }

    if (index == 0) {
      setPrevShow(false);
    } else if (index > 1) {
      setPrevShow(true);
    }

  };

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [ filter, deviceFilter, startDateTime, endDateTime, accidentTypeFilter, userId, ]);

  useEffect(() => {
    if (count > 0)  getCrashReport(indexStart);
  }, [indexStart, count]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const deviceNameHandle = (deviceName) => {

    if(deviceName == "ACTIVE"){
      return "Valid"
    }else{
      return "In Valid"
    }
  }

  const deviceActiveHandle = (deviceName) => {

    if(deviceName == "ACTIVE"){
      return "Active"
    }else if(deviceName == "INACTIVE" || deviceName == "IN_ACTIVE"){
      return "In Active"
    }else{
      return "-"
    }
  }

  function capitalizeLetter  (category){
    return category
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  }

  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      border: "0.2px",
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "white",
      position: "sticky",
      height: "20px",
      fontWeight: 600,
      fontSize: "12px",
    },
    tbody: {
      display: "block",
      height: "250px",
      overflow: "auto",
    },
  };


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        <Row className="mt-2">
          <Col className="col-12 mt-2">
           
            <CardHeader style={{ padding: "0px" }}>
                <Row>
                <Col
                    md={colSize1}
                    style={{
                     
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div  style={{ display:"flex",height:"100%"}}>
                      <div className="sub_heading_new" style={{display:"flex",justifyContent:"center",flexDirection:"column",marginLeft:"14px"}}>
                      Vehicle Crash
                    </div>
                    </div>
                  </Col>
                  <Col
                    md={colSize2}
                    style={{
                     
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    
                    <div  style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          accidentTypeFilter == ""
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setAccidentTypeFilter("")}
                        data-tooltip-id="alle"
                      >
                        All
                        <ReactTooltip
                          id="alle"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          accidentTypeFilter == "MILD_ACCIDENT"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setAccidentTypeFilter("MILD_ACCIDENT")}
                        data-tooltip-id="preIn"
                      >
                        Mild
                        <ReactTooltip
                          id="preIn"
                          place="top"
                          content="Mild Accident"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          accidentTypeFilter == "MEDIUM_ACCIDENT"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setAccidentTypeFilter("MEDIUM_ACCIDENT")}
                        data-tooltip-id="postin"
                      >
                        Medium
                        <ReactTooltip
                          id="postin"
                          place="top"
                          content="Medium Accident"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          accidentTypeFilter == "SEVERE_ACCIDENT"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setAccidentTypeFilter("SEVERE_ACCIDENT")}
                        data-tooltip-id="preLo"
                      >
                        Severe
                        <ReactTooltip
                          id="preLo"
                          place="top"
                          content="Severe Accident"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>

                  <Col
                    md={colSize3}
                    style={{
                     
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    
                    <div  style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          deviceFilter == ""
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID")}
                        data-tooltip-id="androidBl"
                      >
                        Android
                        <ReactTooltip
                          id="androidBl"
                          place="top"
                          content="Android"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS"
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("IOS")}
                        data-tooltip-id="iosBl"
                      >
                        iOS
                        <ReactTooltip
                          id="iosBl"
                          place="top"
                          content="iOS"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>

                  <Col
                    md={colSize4}
                   
                  >
                    
                    <div  style={{ textAlign: "right" }}>
                     
                      <button
                        type="button"
                        className={
                          (filter == "CURRENT_DAY" && !modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                        data-tooltip-id="cd"
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CURRENT_WEEK" && !modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                        data-tooltip-id="cw"
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CURRENT_MONTH" && !modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                        data-tooltip-id="cm"
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CURRENT_YEAR" && !modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                        data-tooltip-id="cy"
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CUSTOM_DURATION" || modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        data-tooltip-id="dfdf"
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <ReactTooltip
                          id="dfdf"
                          place="top"
                          content="Custom Date Selection"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>

                
                </Row>
              </CardHeader>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 mt-5">
            {/* <Card> */}
              
              <CardBody style={{ padding: "0px",marginTop:"-10px" }}>
                <Row>
                  <Col xl={12} md={12} sm={12}>
                  <div
          style={{
            position: "relative",
            maxHeight: 490,
            overflowY: "auto",
            borderCollapse: "collapse",
          }}
        >
          <Table bordered style={TableStyle.table}>
            <thead style={{ position: "sticky" }}>
              <tr>
                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  User Details
                </th>
                <th colSpan={2}
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Score Details
                </th>
                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Ride Details
                </th>

                <th
                colSpan={2}
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Crash Count  
                  
                  
                </th>

               

                <th
                 colSpan={7}
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                 Last Crash Details
                </th>
                
                


               

                
              </tr>
            </thead>

            <tbody>
              {dataLength > 0 ? (
                (apiData || []).map((apiData, index) => (
                  <tr 
                  key={index}
                  >
                    <td
                      style={{
                        fontSize: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={UserNameIcon}
                              alt="icons2"
                              style={{
                                height: "20px",
                                marginLeft: "-2px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "12px",
                              fontSize: "11px",
                            }}
                          >
                           
                              <span
                                style={{
                                  fontSize: "11px",
                                  color: "#5156BE",
                                  marginBottom: "0px",
                                  fontWeight: 700,
                                  cursor: "default",
                                }}
                              >
                                {apiData.firstName}
                              </span>
                         
                          </div>
                        </div>
                        <div
                          style={{
                            marginRight: "4px",
                            marginLeft: "4px",
                            marginTop: "2px",
                          }}
                        >
                          |
                        </div>
                        <div>
                          <p
                            style={{
                              fontSize: "11px",
                              color: "#000000",
                              marginBottom: "0px",
                              fontWeight: 700,
                              cursor: "pointer",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.username}
                            </p>
                        </div>
                        <div>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-sm "
                            style={{
                              backgroundColor: "#D7EC23",
                              padding: "3px",
                              fontSize: "8px",
                              fontWeight: 600,
                            }}
                          >
                            {deviceActiveHandle(apiData.userStatus)}
                          </button>
                        </div>
                      </div>                     

                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          // justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                           
                            {(apiData.deviceType == "ANDROID") ? (
                              <img
                                src={andIconNew}
                                alt="icons2"
                                style={{ height: "25px", marginLeft: "-4px" }}
                              />
                            ) : (
                              <img
                                src={iosIconNew}
                                alt="icons2"
                                style={{ height: "25px", marginLeft: "-4px" }}
                              />
                            )}
                            
                             
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "4px",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "11px",
                            }}
                          >
                            Device
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginLeft: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={FTwheelerIcon}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "4px",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "11px",
                            }}
                          >
                            {apiData.vehicleType}
                          </div>
                        </div>                      
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >                    
                        <div
                          style={{
                            display: "flex",
                            
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={ActiveDateIcon}
                              alt="icons2"
                              style={{ height: "25px",marginLeft: "-4px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "4px",
                              // color: "#0500A3",
                              fontWeight: 400,
                              fontSize: "11px",
                            }}
                          >
                            <div  style={{
                              display: "flex",
                              justifyContent: "center",}}>
                            <p style={{ marginBottom: "0px" }}>Act. Date</p>
                            <p
                              style={{
                                marginBottom: "0px",
                                whiteSpace: "nowrap",
                                marginLeft: "5px" 
                              }}
                            >
                             {UtilDateTime.formatDateInt(apiData.createdAtToTimeZone)}
                            </p>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </td>

                    <td 
                      className="text-nowrap"
                      // data-tooltip-id={`categoryTooltip_${index}`}
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        verticalAlign: "middle",
                        borderRight: "0px",
                        fontWeight: 700,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `${parseInt(apiData.calculatedDrivingScore)}`
                      }}
                    />

                    <td 
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        borderLeft: "0px",
                      }}
                      // data-tooltip-id={`ETVN${index}`}
                    >
                     
                      <div style={{ display: "flex", }} >
                        <div style={{ display: "flex", }} >
                          <div style={{ display: "flex" }}>
                            {/* <img src={UBIAnticipationNew} alt="icons2" style={{ height: "25px" }} /> */}
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", color:"#000" }} >
                            Risk 
                            </div>
                            <div style={{ marginLeft: "45px", fontSize: "11px", }} >
                            {parseInt(apiData.riskScore)}
                            </div>
                          </div> 
                        </div>
                      </div>

                      <div style={{ display: "flex", marginTop: "10px",}} >
                        <div style={{ display: "flex", }} >
                          <div style={{ display: "flex" }}>
                            {/* <img src={UBIAnticipationNew} alt="icons2" style={{ height: "25px" }} /> */}
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", color:"#000" }} >
                              Behavior 
                            </div>
                            <div style={{ marginLeft: "22px", fontSize: "11px", }} >
                            {parseInt(apiData.calculatedBehaviourScore)}
                            </div>
                          </div> 
                        </div>
                      </div>

                      {/* <div style={{display:"flex", marginTop:"5px"}}>
                        <button type="button" className="btn btn-sm" style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, width:"100px"}} >
                                    {apiData.scoreValidationStatus}
                        </button>
                      </div> */}

                    </td>

                    <td style={{ textAlign: "center", fontSize: "10px", verticalAlign: "middle", }}
                      // data-tooltip-id={`ETVN${index}`}
                    >
                     
                     <div style={{ display: "flex", justifyContent: "space-between", }} >
                        <div style={{ display: "flex", }} >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <img src={i4} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "12px", fontSize: "11px", }} >  
                          <Link to={"/crashView"}
                                    state={{
                                      userId: apiData.userid,
                                      divisionId: divId,
                                      rideId: apiData.deviceId,
                                    }}
                                  >
                            <p style={{ fontSize: "11px", marginBottom: "0px", cursor: "pointer", }} >
                              {apiData.rideName}
                            </p>    
                            </Link>
                          </div>
                          <div>&nbsp;&nbsp;
                            <button type="button" className="btn btn-sm " style={{ backgroundColor: apiData.deviceActive?.trim().toUpperCase() === "ACTIVE" ? "#6713BA" :"#D10003" , padding: "3px", fontSize: "8px", fontWeight: 600, color:"#fff" }} >
                                {deviceNameHandle(apiData.deviceActive)}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", }} >
                        <div style={{ display: "flex", }} >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <img src={locationIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "12px", fontSize: "11px", }} >                            
                              <p style={{ fontSize: "11px", marginBottom: "0px", cursor: "default", }} >
                                {apiData.distanceInKm} Kms <span style={{ fontSize: "11px", marginBottom: "0px", fontWeight:600}}  >|</span>  {apiData.durationInMin} Mins
                              </p>                           
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", }} >
                        <div style={{ display: "flex", }} >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <img src={apiData.category?.trim().toUpperCase() === "RIDE_DRIVER" ? driverTypeIcon : passengerTypeIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "12px", fontSize: "11px", }} >                            
                              <p style={{ fontSize: "11px", marginBottom: "0px", cursor: "default", }} >
                              {apiData.category?.trim().toUpperCase() === "RIDE_DRIVER" ? "Driver" : "Passenger"}
                              </p>                           
                          </div>
                        </div>
                      </div>

                        {/* <div style={{ display: "flex", justifyContent:"center", marginTop: "10px",  }} >
                          <div style={{ display: "flex", justifyContent:"center", backgroundColor:"red"}} >
                            <button type="button" className="btn btn-sm" style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, width:"100px"}} >
                                  {apiData.category}
                              </button>
                          </div>
                        </div> */}
                    </td>

                    <td
                      className="text-nowrap"
                      // data-tooltip-id={`categoryTooltip_${index}`}
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        verticalAlign: "middle",
                        borderRight: "0px",
                        fontWeight: 700,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `${apiData.totalCrashCount}`
                      }}
                    />

                    <td style={{ textAlign: "left", fontSize: "10px", verticalAlign: "middle", borderLeft: "0px", }} >
                      <div style={{ display: "flex" }}>                        
                        <div style={{ display: "flex" }}>
                            {/* <img src={UBIAnticipationNew} alt="icons2" style={{ height: "25px" }} /> */}
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", color:"#D1C600" }} >
                              Mild
                            </div>
                            <div style={{ marginLeft: "22px", fontSize: "11px", }} >
                            {apiData.lastAccidentTypeMILD}
                            </div>
                      </div>                     
                       
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                       
                          <div style={{ display: "flex" }}>
                            {/* <img
                              src={UBISelfConfidence}
                              alt="icons2"
                              style={{ height: "25px" }}
                            /> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "7px",
                                fontSize: "11px",
                                color:"#F14A21"
                                
                              }}
                            >
                             Medium
                            </div>
                            <div
                          style={{
                           
                            marginLeft: "5px",
                            fontSize: "11px",
                          }}
                        >
                         {apiData.lastAccidentTypeMedium}
                        </div>
                          </div>
                        
                       
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          // justifyContent:"space-between" 
                        }}
                      >
                        
                          
                           
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                                color:"#CB0206"
                                

                              }}
                            >
                              Severe
                            </div>
                            <div
                          style={{                            
                            fontSize: "11px",
                            marginLeft:"12px"
                          }}
                        >
                          {apiData.lastAccidentTypeSevere}
                        </div>
                         
                        
                        
                      </div>
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        borderRight: "0px",
                        fontWeight: 700,
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{ fontSize: "13px" }}
                      >
                       { parseInt(apiData.lastAccidentSeverityValue)}g
                        </div>
                    </td>
                    <td
                      className="text-nowrap"
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        borderLeft: "0px",
                      }}
                    >
                    
                       
                          
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                            {apiData.lastAccidentTypeName}
                            </div>                          
                       
                       
                     
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        
                        
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              {apiData.lastAccidentAtToTimeZone}
                            </div>                         
                      
                       
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                      
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "9px",
                                fontSize: "11px",
                              }}
                            >
                             {apiData.lastAccidentAtToTime}
                            </div>
                          

                      
                      </div>

                    
                    </td>
                    <td colSpan={4} className="text-nowrap "
                      style={{ textAlign: "left", fontSize: "10px", verticalAlign: "middle", fontSize: "11px", }} >
                      <div style={{ display: "flex", marginLeft: "5px", }} >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <img src={locationImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                            {apiData.lastCity}
                          </div>
                        </div>   

                        <div style={{ display: "flex", marginLeft: "5px", marginTop: "10px", }} >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <img src={AddressImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", color: "#000000", fontWeight: 400,  fontSize: "11px", }} >
                           {`${apiData.lastLocationDeviceData}, ${apiData.lastCity}, ${apiData.lastState}`}
                          </div>
                        </div>  

                        <div style={{ display: "flex", marginLeft: "5px", marginTop: "10px", }}  >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <img src={ZipcodeImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                          </div>
                          <div  style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                            {apiData.lastZipCode}
                          </div>
                        </div>                                            
                    </td>

                   
                    <td style={{ textAlign: "left", fontSize: "10px", verticalAlign: "middle", fontSize: "11px", }} >
                     <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                          <div style={{ display: "flex" }}>
                            <img src={LatImage} alt="icons2" style={{ height: "25px" }} />
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", }} >
                              Lat
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "14px", fontSize: "11px", }} >
                          {apiData.lastLatitude}
                        </div>
                      </div>

                      <div style={{ display: "flex",marginTop:"10px"}}>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                          <div style={{ display: "flex" }}>
                            <img src={LongImage} alt="icons2" style={{ height: "25px" }} />
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", }} >
                              Long
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", }} >
                          {apiData.lastLongitude}
                        </div>
                      </div>
                    </td>
                  </tr>
               ))
               ) : dataLength === 0 ? ( 
                 <tr>
                  {" "}
                  <td colSpan="11">
                    <div
                      style={{
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        width: "100%",
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </div>
                  </td>
                </tr>
              ) : dataLength === null ? (
                <tr>
                  {" "}
                  <td colSpan="11">
                    <div
                      style={{
                        color: "green",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        width: "100%",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </Table>
          {/* 
                      {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )} */}
        </div>
                  </Col>
                </Row>
              </CardBody>
            {/* </Card> */}
          </Col>
        </Row>
        <Row style={{marginTop:"10px"}}>
        <Col>
           {dataLength > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  width: "45px",
                  visibility: prevShow ? "visible" : "hidden",
                }}
              >
                Prev
              </button>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="pagination">
                    <a>{pageNumber}</a>
                  </div>
                  <p
                    style={{
                      marginTop: "6px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    of
                  </p>
                  <div className="pagination ">
                    <a>{totalPageCount}</a>
                  </div>
                </div>
              <button
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  width: "45px",
                  visibility: nextShow ? "visible" : "hidden",
                }}
              >
                Next
              </button>
            </div>
            ) : null}
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}
