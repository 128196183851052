import React from "react";
import Spinner from 'react-bootstrap/Spinner';
import "../../assets/css/general.css"
import { displayText } from "../../constant/MessageConstant";


function Loader() {

  return (
    <div className="snipper_wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Spinner className="spinner-border text-light" animation="border" role="status" />
      <p style={{ fontSize: "20px", color: "#fff" }}>{displayText.LOADING}</p>
    </div>

  );

}

export default Loader;