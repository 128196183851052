import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../../constant/MessageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup";

const dountchart = ({ userUsageData, setUUEndDateTime, setUUStartDateTime, setUUFilter }) => {
  const [filter, setFilter] = useState("CURRENT_DAY");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const series = userUsageData;

  useEffect(()=>{
    setUUEndDateTime(endDateTime);
    setUUStartDateTime(startDateTime);
    setUUFilter(filter)
  },[startDateTime,endDateTime,filter])

  const [uUsage, setUUsage] = useState(false);
  const getValue = () =>{
    var display = null;
    userUsageData.map((value)=>{
      display += value
    })
    setUUsage(display > 0 ? true : display == 0 ? false : null)


  }

  useEffect(() => {
    getValue()
  }, [userUsageData]);


  const options = {
    labels: ["Active User", "Inactive User", "Unregistered User", "Pending User"],
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "4px",
      fontWeight: 100,
      offsetX: 0,
      offsetY: -10,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,

      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: -10,
      style: {
        fontSize: "11px",
        fontFamily: "Helvetica, Arial, sans-serif",
        colors: undefined,
      },

      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Card style={{height: 400}}>
        <CardHeader className="cardHeader">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <h5 className="card-title me-2">User Usage</h5>
            <div className="ms-auto">
              <div>
                <button
                  data-tooltip-id="cd"
                  type="button"
                  className={
                    filter == "CURRENT_DAY"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-primary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_DAY")}
                >
                  CD
                  <ReactTooltip
                    id="cd"
                    place="top"
                    content="Current Day"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                {/* <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION"
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{setIsOpen(true);}}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "} */}
              </div>
            </div>
          </div>
        </CardHeader>
        {uUsage === true ? (
          <CardBody>
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height="310"
            />
          </CardBody>
        ) : uUsage === false ? (
          <CardBody>
            <p className="data_not_available">
              {displayText.DATA_NOT_AVAILABLE}
            </p>
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height="310"
            />
          </CardBody>
        ) : (
          <CardBody>
            <p className="fetch_data">{displayText.FETCHING_DATA}</p>
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height="310"
            />
          </CardBody>
        )}
      </Card>
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen}  setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter}/>
    </React.Fragment>
  );
};

export default dountchart;
