import React, { useState, useEffect } from "react";
import {
  DEVICE_TYPE,
  SC_TIMEZONE_ABBREVIATION,
  SC_TIMEZONE_DISPLAY_NAME,
  SC_TIMEZONE_DST_OFFSET,
  SC_TIMEZONE_GMT_OFFSET,
  SC_TIMEZONE_ZONE_NAME,
  VEHICLE_TYPE,
  SC_USER_LOGIN_TYPE,
} from "../../../constant/StorageConstant";
import world from "../../../assets/images/adminDashboard/world_time_zone.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
// import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import TW from "../../../assets/images/two_wheeler.svg";
import FW from "../../../assets/images/four_wheeler.svg";
import iOSImage from "../../../assets/images/software/iOS_.svg";
import AndroidImage from "../../../assets/images/software/Android_.svg";
import { useLocation } from "react-router-dom";
import iosIconNew from "../../../assets/images/common/iosTableIcon.svg";
import andIconNew from "../../../assets/images/common/androidTableIcon.svg";

const TopBarTimeZoneDetails = () => {
  const abbreviation = localStorage.getItem(SC_TIMEZONE_ABBREVIATION) || "";
  const gmtOffset = localStorage.getItem(SC_TIMEZONE_GMT_OFFSET) || "";
  const DisplayNAme = localStorage.getItem(SC_TIMEZONE_DISPLAY_NAME) || "";
  const ZoneName = localStorage.getItem(SC_TIMEZONE_ZONE_NAME) || "";
  const dstoffset = localStorage.getItem(SC_TIMEZONE_DST_OFFSET) || "";
  const deviceType = localStorage.getItem(DEVICE_TYPE) || "N/A";
  const vehicleType = localStorage.getItem(VEHICLE_TYPE) || "N/A";
  const userType = localStorage.getItem(SC_USER_LOGIN_TYPE) || "";

  const location = useLocation();
  const [previousPath, setPreviousPath] = useState("");

  useEffect(() => {
    // Store the current path as the previous path whenever location changes
    // console.log(deviceType,"deviceType")
    return () => {
      setPreviousPath(location.pathname);
    };
  }, [location.pathname]);

  const VehicleDeviceDisplay = ({ vehicleType, deviceType }) => {
    const normalizedDeviceType = deviceType.toLowerCase(); // Normalize the deviceType to lowercase

    

    return (
      <div
        className="d-none d-lg-flex flex-column justify-content-center align-items-center border-start px-2 py-1"
        style={{ width: "auto" }}
      >
        <div>
          {vehicleType === "TWO_WHEELER" ? (
            <img src={TW} width={25} height={25} alt="Two Wheeler" />
          ) : vehicleType === "FOUR_WHEELER" ? (
            <img src={FW} width={25} height={25} alt="Four Wheeler" />
          ) : (
            <h6 className="mb-0">-</h6>
          )}
        </div>
        <hr className="w-100 my-1" />
        <div>
          {normalizedDeviceType === "ios" ? (
            <img src={iosIconNew} width={30} height={30} alt="iOS" />
          ) : normalizedDeviceType === "android" ? (
            <img src={andIconNew} width={30} height={30} alt="Android" />
          ) : (
            <h6 className="mb-0">-</h6>
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <>
        {userType === "USER" ? (
          <VehicleDeviceDisplay
            vehicleType={vehicleType}
            deviceType={deviceType}
          />
        ) : userType === "ADMIN" &&
          (previousPath.includes("/adminDashboard") ||
            // previousPath.includes("/myusertabledata") ||
            previousPath.includes("/ridedetails") ||
            previousPath.includes("/user-dashboard") ||
            previousPath.includes("/myRides")) &&
          (location.pathname.includes("/user-dashboard") ||
            location.pathname.includes("/myRides") ||
            location.pathname.includes("/ridedetails")) ? (
          <VehicleDeviceDisplay
            vehicleType={vehicleType}
            deviceType={deviceType}
          />
        ) : null}
      </>

      <div
        className="d-none d-lg-flex justify-content-center align-items-center border-start px-3 py-1"
        style={{ width: "auto", gap: "8px" }}
      >
        <div className="d-flex align-items-center">
          <img src={world} width={35} height={35} alt="world" />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          {/* <div style={{fontSize:'12px', fontWeight:500}}>{`Time Zone (${abbreviation})`}</div>
            {/*<div>{DisplayNAme}</div> */}
          {/*<div style={{fontSize:'12px', fontWeight:500}}>{`${ZoneName}`+" "+`${gmtOffset}`}</div> */}
          <div
            style={{ fontSize: "12px", fontWeight: 500 }}
            className="text-nowrap"
            data-tooltip-id="DisplayName"
          >
            {" "}
            {`${abbreviation}`}{" "}
          </div>
          <div
            style={{ fontSize: "12px", fontWeight: 500 }}
            className="text-nowrap"
            data-tooltip-id="DisplayName"
          >
            {" "}
            {`(${ZoneName})`}{" "}
          </div>
          {/* <div
            style={{ fontSize: "10px", fontWeight: 500 }}
            className="text-nowrap"
            data-tooltip-id="Normal"
          >
            {" "}
            {`Normal (${gmtOffset})`}{" "}
          </div>
          <div
            style={{ fontSize: "10px", fontWeight: 500 }}
            className="text-nowrap"
            data-tooltip-id="dst"
          >
            {" "}
            {`DST (${dstoffset})`}{" "}
          </div> */}
          {/* <div style={{fontSize:'12px', fontWeight:500}}>{gmtOffset}</div> */}
          <ReactTooltip
            id="Normal"
            place="left-start"
            offset={5}
            content="Normal Days"
            style={{ backgroundColor: "black" }}
          />
          <ReactTooltip
            id="DisplayName"
            place="left-start"
            offset={5}
            content={DisplayNAme}
            style={{ backgroundColor: "black" }}
          />
          <ReactTooltip
            id="dst"
            place="left-start"
            offset={5}
            content="Daylight Saving Time"
            style={{ backgroundColor: "black" }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopBarTimeZoneDetails;
