import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import {
  SC_COMPANY_ID,
  SC_USER_ID,
  SC_DIVISION_ID,
  SC_USER_LOGIN_TYPE,
  SC_GROUP_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let ShiftConfigurationController = {

  async shiftGET() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID); 

    if (companyId.length > 0 && divisionId.length > 0) {

      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        groupId:groupId,
        type: "SHIFT",
        reportType:"SAD_SINGLE_DIVISION_PREFERENCE_LIST"
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
    }
  },

  async shiftPOST(shiftPOSTDetails) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    
  // alert(JSON.stringify(shiftPOSTDetails))
    if (
      companyId.length > 0 &&
      divisionId.length > 0 &&
      groupId.length > 0
    ) {
      var reqParams = {
        companyId: companyId,
        groupId: groupId,
        divisionId: divisionId,
        name: shiftPOSTDetails.name,
        divisionPreferenceField: {description: ""},
        status: "REGISTERED",
        startTime: shiftPOSTDetails.startTime,
        endTime: shiftPOSTDetails.endTime,
        category: "",
        type: "SHIFT",

      };
      return await ApiService.callPOST(
        apiConfig.API_VERSION_1,
        apiEndPoint.DIVISION_PREFERENCE,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "code": "412", "error" : "Request Process Failed", "data": []}';
    }
  },

  async shiftPUT(shiftPUT) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    // var userId =  UserSessionController.getLocalSessionValue(SC_USER_ID);
    if (
      companyId.length > 0 &&
      divisionId.length > 0 
    ) {
      var reqParams = {
        id: shiftPUT.id,
        companyId: companyId,
        groupId: groupId,
        divisionId: divisionId,
        name: shiftPUT.name,
        divisionPreferenceField: {description: ""},
        status: "REGISTERED",
        startTime: shiftPUT.startTime,
        endTime: shiftPUT.endTime,
        category: "",
        type: "SHIFT",
      };
      return await ApiService.callPUT(
        apiConfig.API_VERSION_1,
        apiEndPoint.DIVISION_PREFERENCE,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async shiftDELETE(userId) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    // var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);

    if (companyId.length > 0 && divisionId.length > 0) {

      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        id: userId
      };

      return await ApiService.callDELETE(
        apiConfig.API_VERSION_1,
        apiEndPoint.DIVISION_PREFERENCE,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
    }
  },


};


export default ShiftConfigurationController;
