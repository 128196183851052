import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
// import UtilDateTime from "../../util/UtilDateTime.js";
// import { trimValue } from "../../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_DIVISION_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

// var month1StartDateTime = "2023-03-01 00:00:00"
// var month1//endDateTime = "2023-03-31 23:59:59";

let AdminDailySummeryController = {
  async adminDailyLowRiskScoreController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var queryFields = {
      filterType: "CURRENT_DAY",
      subFilterType: "LOW_RISK_RIDE",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1StartDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyMediumRiskScoreController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var queryFields = {
      filterType: "CURRENT_DAY",
      subFilterType: "MEDIUM_RISK_RIDE",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyHighRiskScoreController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var queryFields = {
      filterType: "CURRENT_DAY",
      subFilterType: "HIGH_RISK_RIDE",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyUserListController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = {
      filterType: "CURRENT_DAY",
      subFilterType: "USER_FIRST_NAME",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RIDE_LIST_AND_RIDE_SUMMARY",
        dashboardFields: JSON.stringify(dashBoardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyRiskAlertUserListController() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = {
      filterType: "CURRENT_DAY",
      subFilterType: "USER_FIRST_NAME",
    };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
        dashboardFields: JSON.stringify(dashBoardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyRiskAlertsController(userID) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = userID;
    var queryFields = {
      filterType: "CURRENT_DAY",
    };
    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RISK_ALERT_DETAIL_COUNT",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyTotalRiskAlertsController(userID) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = userID;
    var queryFields = {
      filterType: "CURRENT_DAY",
    };
    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RIDE_LIST_AND_RIDE_SUMMARY",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyRideRiskCount(filterValue, indexStart, pageSize, startDateTime, endDateTime) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = { filterType: filterValue };
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: indexStart,
        limit: pageSize,
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        // fromTimeZone:'UTC',
        // toTimeZone:'IST',
        dashboardType: "AD_MULTI_USER_RIDE_COUNT_AND_RISK_ALERT_COUNT",
        dashboardFields: JSON.stringify(dashBoardFields),
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyRideRiskCountExport(filterValue, startDateTime, endDateTime) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = { filterType: filterValue };
   
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "1000",
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        // fromTimeZone:'UTC',
        // toTimeZone:'IST',
        dashboardType: "AD_MULTI_USER_RIDE_COUNT_AND_RISK_ALERT_COUNT",
        dashboardFields: JSON.stringify(dashBoardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyDrivingScoreStatistics() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = { filterType: "CURRENT_DAY" };

    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        offset: "0",
        limit: "100",
        // startDateTime:"2023-03-01 00:00:00",
        // endDateTime: "2023-03-31 00:00:00",
        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(dashBoardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async adminDailyDrivingBehaviour() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var dashBoardFields = { filterType: "CURRENT_DAY" };

    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RIDE_LIST",
        dashboardFields: JSON.stringify(dashBoardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async adminDailySummeryController(userID) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userId = userID;
    var queryFields = {
      filterType: "CURRENT_DAY",
    };
    if (companyId.length > 0 && divisionId.length > 0 && userId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: "0",
        limit: "100",
        //startDateTime: month1//startDateTime,
        //endDateTime: month1//endDateTime,
        dashboardType: "AD_MULTI_USER_RIDE_LIST_AND_RIDE_SUMMARY",
        dashboardFields: JSON.stringify(queryFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
};

export default AdminDailySummeryController;
