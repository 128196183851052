const MySubscriptionTableData = [{
    distanceDataCount:"-",
    alertDataCount:"-",
    drivingScore:"-",
    district:"-",  
    state:"-",
    endDateTime:"", 
    totalLicenseCount: "-",
    androidLicenseCount: "-",
    iosLicenseCount: "-",   
    androidLicenseCountSDK: "-",
    iosLicenseCountSDK: "-"           
},

];

export default MySubscriptionTableData;