import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Col as COL } from "react-grid-system";
import UBIScoreImage from "../../../assets/images/Ubi Images/newDrivingEfficiencyImage.svg";
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Tooltip as ReactTooltip } from "react-tooltip";

const UbiTopRowWidgets = ({ summaryDataList }) => {
  // console.log(summaryDataList, "summaryDataListsummaryDataList");
  
  const rideData = [
    {
      header: "Driving Behaviour Score",
      icon: "UBIDrivingScore.svg",
      value: `${summaryDataList.map((item) => item.totalEfficiency + "%" || "0%")}`,
      unit: "",
      alt: "Driving Points",
      by: "/ 10",
      toolTip: summaryDataList.map((item) => item.totalEfficiencyTV || 0),
    },
    {
      header: "Driving Points",
      icon: "UBIDrivingScore.svg",
      value: summaryDataList.map((item) => item.drivingScore || 0),
      unit: "",
      alt: "Driving Points",
      by: "/ 20",
    },
    {
      header: "Anticipation Points",
      icon: "UBIAnticipation.svg",
      value: summaryDataList.map((item) => item.overallAnticipation || 0),
      unit: "",
      alt: "Anticipation Points",
      by: "/ 10",
    },
    {
      header: "Self Confidence Points",
      icon: "UBISelfConfidence.svg",
      value: summaryDataList.map((item) => item.overallSelfConfidence || 0),
      unit: "",
      alt: "Self Confidence Points",
      by: "/ 10",
    },
    {
      header: "Driving Skill Points",
      icon: "UBIDrivingSkill.svg",
      value: summaryDataList.map((item) => item.overallDrivingSkill || 0),
      unit: "",
      alt: "Driving Skill Points",
      by: "/ 10",
    },
    {
      header: "Driving Style Points",
      icon: "UBIDrivingStyle.svg",
      value: summaryDataList.map((item) => item.DSTP || 0),
      unit: "",
      alt: "Driving Style Points",
      by: "/ 10",
    },
  ];
  const rideData1 = [
    {
      header: "Rating",
      icon:
        summaryDataList.map((item) => item.totalEfficiency || 0) >= 0 &&
          summaryDataList.map((item) => item.totalEfficiency || 0) <= 80 ? (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ marginTop: "-14px" }}
          >
            <p className="mb-0" style={{ fontSize: "14px" }}>
              Poor
            </p>
            <button
              className="btn"
              style={{
                backgroundColor: "#E21212",
                width: "35px",
                height: "20px",
              }}
            />
          </div>
        ) : summaryDataList.map((item) => item.totalEfficiency || 0) >= 81 &&
          summaryDataList.map((item) => item.totalEfficiency || 0) <= 93 ? (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ marginTop: "-14px" }}
          >
            <p className="mb-0" style={{ fontSize: "14px" }}>
              Fair
            </p>
            <button
              className="btn"
              style={{
                backgroundColor: "#FFBF00",
                width: "35px",
                height: "20px",
              }}
            />
          </div>
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ marginTop: "-14px" }}
          >
            <p className="mb-0" style={{ fontSize: "14px" }}>
              Good
            </p>
            <button
              className="btn"
              style={{
                backgroundColor: "#19543E",
                width: "35px",
                height: "20px",
              }}
            />
          </div>
        ),
      value: summaryDataList.map((item) => item.totalEfficiency || 0),
      unit: "",
      alt: "Overspeed Points",
      by: "/ 10",
    },
    {
      header: "Driver State Points",
      icon: "UBIDrivingState.svg",
      value: summaryDataList.map((item) => item.DSP || 0),
      unit: "",
      alt: "Driver State Points",
      by: "/ 10",
    },
    {
      header: "Mobile Screen Usage Points",
      icon: "UBIMobileScreenUse.svg",
      value: summaryDataList.map((item) => item.mobileScreenPointNew || 0),
      unit: "",
      alt: "Mobile Use Points",
      by: "/ 5",
    },
    {
      header: "Mobile Call Usage Points",
      icon: "UBIMobileCallUse.svg",
      value: summaryDataList.map((item) => item.mobileUsePointNew || 0),
      unit: "",
      alt: "Mobile Call Points",
      by: "/ 5",
    },
    {
      header: "Overspeed KMS Driven Points",
      icon: "UBIKmsDriven.svg",
      value: summaryDataList.map((item) => item.totalKmSPoint || 0),
      unit: "",
      alt: "Overspeed KMS Driven Points",
      by: "/ 10",
    },
    {
      header: "Acc/Brak/Cor Points",
      icon: "UBIStressStrain.svg",
      value: summaryDataList.map((item) => item.ABCPoint || 0),
      unit: "",
      alt: "Acceleration/Braking/Cornering Points",
      by: "/ 10",
    },
  ];
  return (
    <>
      <Row>
        <Col lg={12} md={12}>
          <Row style={{ paddingRight: "10px" }}>
            {rideData.map((data, index) => (
              <COL
                lg={2}
                md={6}
                key={index}
                className={`ps-3 ${index === 5 && "pe-2"}`}
              >
                <Card>
                  <CardHeader
                    style={{ fontSize: "13px" }}
                    className={`sub_heading ps-2 pe-2 pb-1 pt-2 border-0 text-nowrap overflow-hidden ${index === 0 && "cardHeader"
                      }`}
                    data-tooltip-id={index + "point"}
                  >
                    {data.header}
                  </CardHeader>
                  <CardBody
                    className={`body_text border-0 ps-2 pe-2 py-0 d-flex align-items-center ${index !== 0
                        ? "justify-content-start"
                        : "justify-content-center"
                      } ${index === 0 && "cardHeader"}`}
                    style={{ height: "50px", gap: "20px" }}
                  >
                    {index !== 0 && (
                      <img
                        src={require(`../../../assets/images/Ubi Images/${data.icon}`)}
                        alt={data.alt}
                        height={30}
                        width={30}
                      />
                    )}
                    <div
                      data-tooltip-id={index === 0 && "dbstv"}
                      style={{
                        fontWeight: index !== 0 ? 500 : 800,
                        fontSize: index !== 0 ? 14 : 30,
                        display: "flex",
                        justifyContent: index === 0 && "center",
                      }}
                    >
                      {data.value > 0 ? data.value : "0"} {index !== 0 && data.by}
                      {index === 0 && <ReactTooltip id={"dbstv"} place="top" content={data.toolTip} style={{ backgroundColor: "black" }} />}

                    </div>
                  </CardBody>
                  {/* <ReactTooltip id={index + "point"} place="top" content={data.alt} /> */}
                </Card>
              </COL>
            ))}
          </Row>
          <Row style={{ paddingRight: "10px" }}>
            {rideData1.map((data, index) => (
              <COL
                lg={2}
                md={6}
                key={index}
                className={`ps-3 ${index === 5 && "pe-2"}`}
              >
                <Card>
                  <CardHeader
                    style={{ fontSize: "13px" }}
                    className={`sub_heading ps-2 pe-2 pb-1 pt-2 border-0 text-nowrap overflow-hidden ${index === 0 && "cardHeader"}`}
                    data-tooltip-id={index + "points"}
                  >
                    {data.header}
                  </CardHeader>
                  <CardBody
                    className={`body_text border-0 ps-2 pe-2 py-0 d-flex ${index !== 0
                        ? "justify-content-start"
                        : "justify-content-center"
                      } ${index === 0 && "cardHeader"} align-items-center`}
                    style={{ height: "50px", gap: "20px" }}
                  >
                    {index !== 0 ? (
                      <img
                        src={require(`../../../assets/images/Ubi Images/${data.icon}`)}
                        alt={data.alt}
                        height={30}
                        width={30}
                      />
                    ) : (
                      data.icon
                    )}
                    {index !== 0 && (
                      <div style={{ fontWeight: 500, fontSize: 14 }}>
                        {data.value > 0 ? data.value : "0"} {data.by}
                      </div>
                    )}
                  </CardBody>
                  {/* {data.header == "Acc/Brak/Cor" && ( */}
                  {/* <ReactTooltip id={index + "points"} place="top" content={data.alt} /> */}
                  {/* )} */}
                </Card>
              </COL>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UbiTopRowWidgets;
