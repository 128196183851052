export const LOW = "LOW";
export const MEDIUM = "MEDIUM";
export const HIGH = "HIGH";

export const SEVERE_ACCELERATION = "SEVERE_ACCELERATION";
export const SEVERE_CORNERING = "SEVERE_CORNERING";
export const SEVERE_BRAKING = "SEVERE_BRAKING";
export const MOBILE_SCREEN = "MOBILE_SCREEN";
export const MOBILE_USE = "MOBILE_USE";
export const NORMAL = "NORMAL";
export const TIRED = "TIRED";
export const ANGRY = "ANGRY";
export const SPORTIVE = "SPORTIVE";
export const ECO = "ECO";
export const CHAOTIC = "CHAOTIC";
export const AGGRESSIVE = "AGGRESSIVE";


export const LESS_THAN = "LESS_THAN";
export const BETWEEN_EQUAL = "BETWEEN_EQUAL";
export const GREATER_THAN = "GREATER_THAN";

