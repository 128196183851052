import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

const Line = ({activeDriverData}) => {
  const daysOfWeeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
  function getDayNamesForPastWeek() {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date(); // Get today's date
    const dayOfWeek = today.getDay(); // Get today's day of the week
    const dayNames = [];
  
    // Loop to get day names from Sunday to today
    for (let i = 0; i <= dayOfWeek; i++) {
      dayNames.push(daysOfWeek[i]);
    }
  
    return dayNames;
  }
  
  
  const dayNamesForPastWeek = getDayNamesForPastWeek();

  const getOption = () => {

    return {
      animationDuration: 4000,

      title: {
        textStyle: {
          fontSize: 12,
        },
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        type: 'scroll',
        textStyle: {
          fontSize: 9,
        },
      },
      xAxis: {
        name: "Days",
        label: {
          axisLabel: {
            fontSize: 7,
          },
        },
        type: "category",
        boundaryGap: false,
        data: daysOfWeeks,
      },
      yAxis: {
        name: "Ride",
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        axisLabel: {
          formatter: "{value} %",
        },
      },
      series: activeDriverData
    };
  };



    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "340px",width:"100%" }} option={getOption()} />
      </React.Fragment>
    )
  
}
export default Line
