export const optionConfig = {
    CUSTOM: ['CUSTOM'],
    MOBILE_NUMBER: ['MOBILE_NUMBER'],
    EMAIL_ADDRESS: ['EMAIL_ADDRESS'],
    IMEI_NUMBER: ['IMEI_NUMBER'],

    CUSTOM_MOBILE_NUMBER: ['CUSTOM', 'MOBILE_NUMBER'],
    CUSTOM_EMAIL_ADDRESS: ['CUSTOM', 'EMAIL_ADDRESS'],
    CUSTOM_IMEI_NUMBER: ['CUSTOM', 'IMEI_NUMBER'],

    CUSTOM_MOBILE_NUMBER_EMAIL_ADDRESS: ['CUSTOM', 'MOBILE_NUMBER', 'EMAIL_ADDRESS'],
    CUSTOM_MOBILE_NUMBER_IMEI_NUMBER: ['CUSTOM', 'MOBILE_NUMBER', 'IMEI_NUMBER'],
    CUSTOM_EMAIL_ADDRESS_IMEI_NUMBER: ['CUSTOM', 'EMAIL_ADDRESS', 'IMEI_NUMBER'],

    MOBILE_NUMBER_EMAIL_ADDRESS: ['MOBILE_NUMBER', 'EMAIL_ADDRESS'],
    MOBILE_NUMBER_IMEI_NUMBER: ['MOBILE_NUMBER', 'IMEI_NUMBER'],

    EMAIL_ADDRESS_IMEI_NUMBER: ['EMAIL_ADDRESS', 'IMEI_NUMBER'],
    CUSTOM_MOBILE_NUMBER_EMAIL_ADDRESS_IMEI_NUMBER: ['CUSTOM', 'MOBILE_NUMBER', 'EMAIL_ADDRESS', 'IMEI_NUMBER']
};


export const deviceOptionConfig = {
        ANDROID: ['ANDROID'],
        IOS: ['IOS'],
        IOS_SDK: ['IOS_SDK'],
        ANDROID_SDK: ['ANDROID_SDK'],

        ANDROID_IOS: ['ANDROID', 'IOS'],
        ANDROID_IOS_SDK: ['ANDROID', 'IOS_SDK'],
        ANDROID_ANDROID_SDK: ['ANDROID', 'ANDROID_SDK'],

        IOS_IOS_SDK: ['IOS', 'IOS_SDK'],
        IOS_ANDROID_SDK: ['IOS', 'ANDROID_SDK'],

        IOS_SDK_ANDROID_SDK: ['IOS_SDK', 'ANDROID_SDK'],

        ANDROID_IOS_IOS_SDK: ['ANDROID', 'IOS', 'IOS_SDK'],
        ANDROID_IOS_ANDROID_SDK: ['ANDROID', 'IOS', 'ANDROID_SDK'],
        ANDROID_IOS_SDK_ANDROID_SDK: ['ANDROID', 'IOS_SDK', 'ANDROID_SDK'],
        IOS_IOS_SDK_ANDROID_SDK: ['IOS', 'IOS_SDK', 'ANDROID_SDK'],
        
        ANDROID_IOS_IOS_SDK_ANDROID_SDK: ['ANDROID', 'IOS', 'IOS_SDK', 'ANDROID_SDK']
}