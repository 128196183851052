import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
  CardFooter,
  NavItem,
  Nav,
} from "reactstrap";
// import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap"

import CountUp from "react-countup";

/** import Mini Widget data */
import {
  AdminDashboardRideBoxCount,
  AdminDashboardTopRowItems,
} from "../../../common/data/admin/dashboard.js";

import RideCountChart from "../Charts/rideCountChart.js";
import RiskCountChart from "../Charts/riskCountChart.js";
import ValueFormat from "../../../util/ValueFormat.js";

import AdminDailySummeryController from "../../../controller/AdminReport/ADDailyReportController.js";
import AdminWeeklySummeryController from "../../../controller/AdminReport/ADWeeklyReportController.js";
import { useSelector } from "react-redux";
import Loader from "../../../components/Common/loader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../components/Common/toastService.js";
import { Link, NavLink } from "react-router-dom";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { displayText } from "../../../constant/MessageConstant.js";
import {
  SC_LOCK_STATUS,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import OverallRiskSummary from "../OverallRiskSummary/overallRiskSummary.js";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomDateFilterMain from "../Charts/customDateFilter.js";
import AdminDrivingCharts from "../AdminDrivingChart/index.js";
import AdminDashboardSubMenu from "./AdminDashboardSubMenu.js";

const RiskSummary = () => {
  const [rideRiskData, setRideRiskData] = useState({
    rideRiskXaxis: [],
    rideSeries: [],
    usernameData: [],
    riskAmberSeries: [],
    riskRedSeries: [],
    filter: "",
  });

  const [dailyData, setDailyData] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [userUsagePer, setUserUsagePer] = useState([]);
  const [activeDriverData, setActiveDriverData] = useState([]);
  const [activeDriverPie, setActiveDriverPie] = useState([]);

  const [receiveRideRisk, setReceiveRideRisk] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [totalRidesCount, setTotalRidesCount] = useState(0);
  const [showLoader, setShowLoader] = useState(0);
  const [indexStart, setIndexStart] = useState(0);
  const [pdUsageData, setPdUsageData] = useState(false);

  const [rcChart, setRcChart] = useState(null);
  const [rccData, setRccData] = useState(null);
  const [count, setCount] = useState(0);
  const [counts, setCounts] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [filter, setFilter] = useState("");
  const [prevShow, setPrevShow] = useState(false);
  const [nextShow, setNextShow] = useState(false);
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });
  // useEffect(() => {
  //   if (count > 0)
  //     setFilters({
  //       durationFilter: filter,
  //       startDateTime: filter == "CUSTOM_DURATION" ? startDateTime : "",
  //       endDateTime: filter == "CUSTOM_DURATION" ? endDateTime : "",
  //     });
  // }, [filter, startDateTime, endDateTime]);

  useEffect(() => {
    if (counts == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);

  const [uuStartDateTime, setUUStartDateTime] = useState("");
  const [uuEndDateTime, setUUEndDateTime] = useState("");
  const [uuFilter, setUUFilter] = useState("CURRENT_DAY");
  const [norStartDateTime, setNORStartDateTime] = useState("");
  const [norEndDateTime, setNOREndDateTime] = useState("");
  const [norFilter, setNORFilter] = useState("CURRENT_WEEK");
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const pageSize = 100;
  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };

  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const [dataLength, setDataLength] = useState(null);

  const getRiskRideCount = async (indexOffset) => {
    setCounts(1);
    setRccData(null);

    setShowLoader((current) => current + 1);
    var result = await AdminDailySummeryController.adminDailyRideRiskCount(
      filters.durationFilter,
      indexOffset,
      pageSize,
      filters.startDateTime,
      filters.endDateTime
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      var xAxisData = [];
      var rideCount = [];
      var redAlertCount = [];
      var amberAlertCount = [];
      var unameData = [];
      var totalCounts = 0;
      setDataLength(resultJSONData.length);
      // console.log("getRiskRideCount :" , (resultJSONData));
      resultJSONData.map((apiData) => {
        var firstName = ValueFormat.parseAsString(
          ValueFormat.capitalizeAllLetter(apiData.firstName)
        );
        var username = ValueFormat.parseAsString(apiData.username);
        var rideCountTemp = ValueFormat.parseAsInt(apiData.rideCount);
        var alertCountList = ValueFormat.parseAsObject(apiData.riskAlertCount);
        var redCountTemp = ValueFormat.parseAsInt(alertCountList.highRiskCount);
        var amberCountTemp = ValueFormat.parseAsInt(
          alertCountList.mediumRiskCount
        );
        totalCounts = ValueFormat.parseAsInt(apiData.totalCount);
        setTotalPageCount(Math.ceil(totalCounts / pageSize));

        xAxisData.push(firstName);
        rideCount.push(rideCountTemp);
        redAlertCount.push(redCountTemp);
        amberAlertCount.push(amberCountTemp);
        unameData.push(username);
      });
      const riskRideData = [
        {
          xAxisData: xAxisData,
          rideCount: rideCount,
          redAlertCount: redAlertCount,
          amberAlertCount: amberAlertCount,
          unameData: unameData,
        },
      ];
      setReceiveRideRisk(riskRideData);
      setRcChart(Object.values(rideCount).some((value) => value !== 0));
      setRccData(
        Object.values(redAlertCount).some((value) => value !== 0) ||
          Object.values(amberAlertCount).some((value) => value !== 0)
      );
      setNextShow(totalCounts - indexOffset > pageSize);
      setRideRiskData((prev) => {
        return {
          ...prev,
          rideRiskXaxis: xAxisData,
          rideSeries: rideCount,
          riskAmberSeries: amberAlertCount,
          riskRedSeries: redAlertCount,
          usernameData: unameData,
          filter: filters.durationFilter,
        };
      });
    } else {
      setShowLoader((current) => current - current);
      setRcChart(false);
      setRccData(false);
      var code = resultJSON.error.code;
      if (code == "E1206") {
        setRcChart("failed");
        setRccData("failed");
      }
    }
    if (indexOffset == 0) {
      setPrevShow(false);
    } else if (indexOffset > 0) {
      setPrevShow(true);
    }
  };

  const containerIds = ["container1"];

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [filters.durationFilter, filters.startDateTime, filters.endDateTime,]);

  useEffect(() => {
    if (count > 0) getRiskRideCount(indexStart);
  }, [indexStart, count]);

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid id="division">
          <div id="container1">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />

            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                style={{
                  position: "fixed",
                  zIndex: 1,
                  backgroundColor: "#fff",
                }}
                className="shadow-sm"
              >
                <Row>
                  <Col xl={7} className="py-2">
                    <AdminDashboardSubMenu />
                  </Col>
                  <Col
                    xl={5}
                    className="py-2"
                    style={{ alignContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="ms-auto pe-2 border-end border-secondary">
                        <div style={{ float: "right" }}>
                          <button
                            type="button"
                            data-tooltip-id="cd"
                            className={
                              (filters.durationFilter == "CURRENT_DAY" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_DAY",
                              })
                            }}
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Current Day"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cw"
                            className={
                              (filters.durationFilter == "CURRENT_WEEK" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_WEEK",
                              })
                            }}
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Current Week"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cm"
                            className={
                              (filters.durationFilter == "CURRENT_MONTH" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_MONTH",
                              })
                            }}
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Current Month"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cy"
                            className={
                              (filters.durationFilter == "CURRENT_YEAR" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCounts(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_YEAR",
                              })
                            }}
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Current Year"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CUSTOM_DURATION" || modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCounts(1);
                            }}
                            data-tooltip-id="dfdf"
                          >
                            <FontAwesomeIcon icon={faClock} />
                            <ReactTooltip
                              id="dfdf"
                              place="bottom"
                              content="Custom Date Selection"
                              style={{ background: "black" }}
                            />
                          </button>{" "}
                        </div>
                      </div>
                      

                      <div>
                        <ExportScreenPdf
                          containerIds={containerIds}
                          fileName={userFirstName + "'s" + " Risk Summary"}
                        />
                      </div>
                      <div>
                        <ViewPdf receiveRideRisk={receiveRideRisk} filtersADRS={filters}/>
                      </div>

                      {/* <Link to={"/exportRisk"}>
                            <button
                              // className="btn btn-soft-primary btn-sm ms-1"
                              className="btn btn-primary btn-sm"
                              data-tooltip-id="err"
                            >
                              Go to Rides & Risks
                              <ReactTooltip
                                id="err"
                                place="bottom"
                                content="Go to Rides & Risks"
                                style={{ backgroundColor: "black" }}
                              />
                            </button>
                          </Link> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <Col xl={6}>
                <Card
                  style={{
                    height:
                      rideRiskData.rideRiskXaxis.length <= 5 ? 500 : "auto",
                  }}
                >
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading mb-2">Rides </h4>
                  </CardHeader>
                  {rcChart === true ? (
                    <CardBody>
                      <RideCountChart rideRiskData={rideRiskData} />
                      <div className="d-flex justify-content-between mt-4">
                        <button
                          style={{
                            visibility: prevShow ? "visible" : "hidden",
                            width: "45px",
                          }}
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={previous}
                        >
                          Prev
                        </button>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="pagination">
                            <a>{pageNumber}</a>
                          </div>
                          <p
                            style={{
                              marginTop: "6px",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            of
                          </p>
                          <div className="pagination ">
                            <a>{totalPageCount}</a>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-outline-success btn-sm"
                          onClick={next}
                          style={{
                            visibility: nextShow ? "visible" : "hidden",
                            width: "45px",
                            float: "right",
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </CardBody>
                  ) : rcChart === false ? (
                    <CardBody>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                      <RideCountChart rideRiskData={rideRiskData} />
                      <br />
                      <div className="d-flex justify-content-between">
                        <button
                          style={{
                            visibility: indexStart === 0 ? "hidden" : "visible",
                            width: "45px",
                          }}
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={previous}
                        >
                          Prev
                        </button>
                        <div className="pagination">
                          <a>{pageNumber}</a>
                        </div>
                        <button
                          type="button"
                          className="btn btn-outline-success btn-sm"
                          onClick={next}
                          style={{
                            visibility:
                              dataLength === 0 || dataLength < 100
                                ? "hidden"
                                : "visible",
                            width: "45px",
                            float: "right",
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </CardBody>
                  ) : rcChart === "failed" ? (
                    <CardBody>
                      <p className="data_not_available">{displayText.ER_1206}</p>
                      <RideCountChart rideRiskData={rideRiskData} />
                      <br />
                      <div className="d-flex justify-content-between">
                        <button
                          style={{
                            visibility: indexStart === 0 ? "hidden" : "visible",
                            width: "45px",
                          }}
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={previous}
                        >
                          Prev
                        </button>
                        <div className="pagination">
                          <a href="#">{pageNumber}</a>
                        </div>
                        <button
                          type="button"
                          className="btn btn-outline-success btn-sm"
                          onClick={next}
                          style={{
                            visibility:
                              dataLength === 0 || dataLength < 100
                                ? "hidden"
                                : "visible",
                            width: "45px",
                            float: "right",
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                      <RideCountChart rideRiskData={rideRiskData} />
                      <br />
                      <div className="d-flex justify-content-between">
                        <button
                          style={{
                            visibility: indexStart === 0 ? "hidden" : "visible",
                            width: "45px",
                          }}
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={previous}
                        >
                          Prev
                        </button>
                        <div className="pagination">
                          <a href="#">{pageNumber}</a>
                        </div>
                        <button
                          type="button"
                          className="btn btn-outline-success btn-sm"
                          onClick={next}
                          style={{
                            visibility:
                              dataLength === 0 || dataLength < 100
                                ? "hidden"
                                : "visible",
                            width: "45px",
                            float: "right",
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </CardBody>
                  )}
                </Card>
                <DateTimePickerPopup
                  modalOpen={modalIsOpen}
                  modalClose={setIsOpen}
                  setFilter={setFilter}
                  setStartDateTime={setStartDateTime}
                  setEndDateTime={setEndDateTime}
                  filter={filters.durationFilter}
                />
              </Col>

              <Col xl={6}>
                <Card
                  style={{
                    height:
                      rideRiskData.rideRiskXaxis.length <= 5 ? 500 : "auto",
                  }}
                >
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading mb-2">Risks </h4>
                  </CardHeader>
                  {rccData === true ? (
                    <CardBody>
                      <RiskCountChart rideRiskData={rideRiskData} />
                    </CardBody>
                  ) : rccData === false ? (
                    <CardBody>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                      <RiskCountChart rideRiskData={rideRiskData} />
                    </CardBody>
                  ) : rccData === "failed" ? (
                    <CardBody>
                      <p className="data_not_available">{displayText.ER_1206}</p>
                      <RiskCountChart rideRiskData={rideRiskData} />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                      <RiskCountChart rideRiskData={rideRiskData} />
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RiskSummary;
