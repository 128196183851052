import React from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import { SC_LOGIN_USER_FIRST_NAME } from "../../../constant/StorageConstant";
import Converter from "../../../components/Common/EncodeDecode";

export default function Bubble({safetyData}) {
  const history = useNavigate();

  
  // const handlePointClick = (params) => {
    
  //   if(params.data){
  //     history('/ridedetails', {
  //       state:{
  //         divisionId: params.data[4],
  //         userId: params.data[5],
  //         rideId: params.data[6]
  //       }
  //     })
  //     // localStorage.setItem(SC_LOGIN_USER_FIRST_NAME,)
  //   }
  // }
  const handlePointClick = (params) => {
    if(params.data){
      const userId = encodeURIComponent(Converter.encrypt(params.data[5]));
      const divisionId = encodeURIComponent(Converter.encrypt(params.data[4]));
      const rideId = encodeURIComponent(Converter.encrypt(params.data[6]));
      
      history(`/ridedetails?userId=${userId}&divisionId=${divisionId}&rideId=${rideId}`);
      // localStorage.setItem(SC_LOGIN_USER_FIRST_NAME, params.data[7]);
    }
  };

  return (
    <React.Fragment>
       <div className="box chart">
                    <div className="d-flex justify-content-between">
                      <p style={{ fontSize: "13px",fontWeight: 600, }} >Unsafe</p>
                      <p style={{ fontSize: "13px",fontWeight: 600, }} >Unsafe</p>
                    </div>
      <ReactEcharts style={{ height: "420px" }} option={safetyData} onEvents={{click: handlePointClick,}}/>
      <div className="d-flex justify-content-between" style={{marginTop:"-20px"}}>  
                      <p style={{ fontSize: "13px",fontWeight: 600, }} >Safe</p>
                      <p style={{ fontSize: "13px",fontWeight: 600, }} >Unsafe</p>
                    </div>
      </div>
    </React.Fragment>
  );
}
