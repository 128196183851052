import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
  CardFooter,
  NavItem,
  Nav,
} from "reactstrap";
// import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap"

import CountUp from "react-countup";

/** import Mini Widget data */
import {
  AdminDashboardRideBoxCount,
  AdminDashboardTopRowItems,
} from "../../../common/data/admin/dashboard.js";

import RideCountChart from "../Charts/rideCountChart.js";
import RiskCountChart from "../Charts/riskCountChart.js";
import ValueFormat from "../../../util/ValueFormat.js";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import DriverAnalysis from "../Charts/driverAnalysisChart.js";
import DriverAnalysisSub from "../Charts/driverAnalysisSubChart.js";
import LineApexChart from "../Charts/chartapex.js";
import OverAllSummary from "../OverallSummary/overallSummary.js";
import C1Table from "../Table/c1Table.js";
import LineBar from "../Charts/c1Chart.js";
import RadialChart from "../Charts/safetyCategorySummary.js";
import UsageHistory from "../Charts/usageHistory.js";
import DrivingBehaviour from "../Charts/multiuserDrivingBehaviour.js";
import C2Table from "../Table/c2Table.js";
import C2Chart from "../Charts/c2Chart.js";
import C3Table from "../Table/c3Table.js";
import C3Chart from "../Charts/c3Chart.js";
import C4Table from "../Table/c4Table.js";
import C4Chart from "../Charts/c4Chart.js";
import C5Table from "../Table/c5Table.js";
import C5Chart from "../Charts/c5Chart.js";
import C6Table from "../Table/c6Table.js";
import C6Chart from "../Charts/c6Chart.js";
import C1Chart from "../Charts/c1Chart.js";
import AdminDailySummeryController from "../../../controller/AdminReport/ADDailyReportController.js";
import AdminWeeklySummeryController from "../../../controller/AdminReport/ADWeeklyReportController.js";
import { useSelector } from "react-redux";
import { layoutTypes } from "../../../constants/layout.js";
import giftBox from "../../../assets/images/giftbox.png";
import Loader from "../../../components/Common/loader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../components/Common/toastService.js";
import { Link, NavLink } from "react-router-dom";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { displayText } from "../../../constant/MessageConstant.js";
import {
  SC_LOCK_STATUS,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import OverallRiskSummary from "../OverallRiskSummary/overallRiskSummary.js";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomDateFilterMain from "../Charts/customDateFilter.js";
import AdminDrivingCharts from "../AdminDrivingChart/index.js";
import AdminDashboardSubMenu from "./AdminDashboardSubMenu.js";
import "./Submenu.css";

const CategorySummary = () => {
  const [showLoader, setShowLoader] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });

  useEffect(() => {
    if (count == 1) {
      setFilters({
        durationFilter: filter,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      });
    }
  }, [filter, startDateTime, endDateTime]);
  

  // ____________________________CUSTOM FILTER END_________________________

  const containerIds = ["container1","container2"];

  const [safetyTableData, setSafetyTableData] = useState([]);
  const [sportyTableData, setSportyTableData] = useState([]);
  const [quietTableData, setQuietSafeTableData] = useState([]);
  const [dangerousTableData, setDangerousTableData] = useState([]);
  const [quietDangerousTableData, setQuietDangerousTableData] = useState([]);
  const [recklessTableData, setRecklessTableData] = useState([]);

  const handleSafetyTableData = (data) => {
    setSafetyTableData(data);
  };
  const handleSportiveTableData = (data) => {
    setSportyTableData(data);
  };
  const handleQuietSafeTableData = (data) => {
    setQuietSafeTableData(data);
  };
  const handleRecklessTableData = (data) => {
    setRecklessTableData(data);
  };
  const handleQuietDangerousTableData = (data) => {
    setQuietDangerousTableData(data);
  };
  const handleDangerousTableData = (data) => {
    setDangerousTableData(data);
  };

  const tableData = [
    {
      name: "",
      totalRides: 0,
      percentage: 0,
      rideName: [],
    },
  ];


  const [safeDriverData, setSafeDriverData] = useState(tableData);
  const [sportyDriverData, setSportyDriverData] = useState(tableData);
  const [quiteSafeDriverData, setQuiteSafeDriverData] = useState(tableData);
  const [recklessDriverData, setRecklessDriverData] = useState(tableData);
  const [quiteDangerousDriverData, setQuiteDangerousDriverData] = useState(tableData);
  const [dangerousDriverData, setDangerousDriverData] = useState(tableData);
  const [display, setDisplay] = useState(false);


  const driverCategory = "C1";




  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid id="division">
          <div>
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />

            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                style={{
                  position: "fixed",
                  zIndex: 1,
                  backgroundColor: "#fff",
                }}
                className="shadow-sm"
              >
                <Row>
                  <Col xl={7} className="py-2">
                    <AdminDashboardSubMenu />
                  </Col>
                  <Col
                    xl={5}
                    className="py-2"
                    style={{ alignContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="ms-auto pe-2 border-end border-secondary">
                        <div style={{ float: "right" }}>
                          <button
                            type="button"
                            data-tooltip-id="cd"
                            className={
                              (filters.durationFilter == "CURRENT_DAY" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_DAY",
                              })
                            }}
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Current Day"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cw"
                            className={
                              (filters.durationFilter == "CURRENT_WEEK" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_WEEK",
                              })
                            }}
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Current Week"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cm"
                            className={
                              (filters.durationFilter == "CURRENT_MONTH" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_MONTH",
                              })
                            }}
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Current Month"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cy"
                            className={
                              (filters.durationFilter == "CURRENT_YEAR" && !modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() =>{
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_YEAR",
                              })
                            }}
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Current Year"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              (filters.durationFilter == "CUSTOM_DURATION" || modalIsOpen)
                                ? "btn btn-primary btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCount(1);
                            }}
                            data-tooltip-id="dfdf"
                          >
                            <FontAwesomeIcon icon={faClock} />
                            <ReactTooltip
                              id="dfdf"
                              place="bottom"
                              content="Custom Date Selection"
                              style={{ background: "black" }}
                            />
                          </button>{" "}
                        </div>
                      </div>
                      <div>
                        <ExportScreenPdf
                          containerIds={containerIds}
                          fileName={userFirstName + "'s" + " Category Summary"}
                        />
                      </div>
                      <div>
                        <ViewPdf
                          safetyTableData1={safetyTableData}
                          sportiveTableData1={sportyTableData}
                          quiteSafeTableData1={quietTableData}
                          recklessTableData1={recklessTableData}
                          quiteDangerousTableData1={
                            quietDangerousTableData
                          }
                          dangerousTableData1={dangerousTableData}
                          filtersADCS={filters}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <div className="p-2 pt-0 d-flex" style={{ marginLeft: "10px" }}>
                  <ViewPdf
                    ADTopRowItemsTemp={ADTopRowItemsTemp}
                    totalSafeRidePer={totalSafeRidePer}
                    userUsagePer={userUsagePer}
                    summaryData={summaryData}
                    usageHistory={usageHistory}
                    safetycategory={safetycategory}
                    rideRiskData={rideRiskData}
                    activeDriverPie={activeDriverPie}
                    activeDriverData={activeDriverData}
                    safetyTableData={safetyTableData}
                    sportyTableData={sportyTableData}
                    quietTableData={quietTableData}
                    recklessTableData={recklessTableData}
                    quietDangerousTableData={quietDangerousTableData}
                    dangerousTableData={dangerousTableData}
                  />
                </div> */}
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <Col xl={12}>
              <div id="container1">
                <C1Chart
                  filter={filters}
                  loaderCount={setShowLoader}
                  handleSafetyTableData={handleSafetyTableData}
                />
                <C2Chart
                  filter={filters}
                  loaderCount={setShowLoader}
                  handleSportiveTableData={handleSportiveTableData}
                />
                <C3Chart
                  filter={filters}
                  loaderCount={setShowLoader}
                  handleQuietSafeTableData={handleQuietSafeTableData}
                />
                </div>
                <div id="container2">
                <C4Chart
                  filter={filters}
                  loaderCount={setShowLoader}
                  handleRecklessTableData={handleRecklessTableData}
                />
                <C5Chart
                  filter={filters}
                  loaderCount={setShowLoader}
                  handleQuietDangerousTableData={handleQuietDangerousTableData}
                />
                <C6Chart
                  filter={filters}
                  loaderCount={setShowLoader}
                  handleDangerousTableData={handleDangerousTableData}
                />
                </div>
                <DateTimePickerPopup
                  modalOpen={modalIsOpen}
                  modalClose={setIsOpen}
                  setFilter={setFilter}
                  setStartDateTime={setStartDateTime}
                  setEndDateTime={setEndDateTime}
                  filter={filters.durationFilter}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategorySummary;
