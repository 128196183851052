import {
    Document,
    Image,
    Line,
    PDFViewer,
    Page,
    Rect,
    StyleSheet,
    Svg,
    Text,
    View,
} from "@react-pdf/renderer";
import React from "react";
import Logo from "../../../assets/images/logo/motivAiLogoPng.png";
import {
    SC_COMPANY_NAME,
    SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import UtilDateTime from "../../../util/UtilDateTime";
import ValueFormat from "../../../util/ValueFormat";

import AnimalCrossing from "../../../assets/images/t_animal_crossing.png";
import Caution from "../../../assets/images/t_caution.png";
import Curves from "../../../assets/images/t_curves.png";
import Force from "../../../assets/images/t_force_acceleration.png";
import HillDownwards from "../../../assets/images/t_hill_downwards.png";
import HillUpwards from "../../../assets/images/t_hill_upwards.png";
import Intersection from "../../../assets/images/t_intersection.png";
import LaneMerge from "../../../assets/images/t_lane_merge.png";
import LowGearArea from "../../../assets/images/t_low_gear_area.png";
import NarrowRoad from "../../../assets/images/t_narrow_road.png";
import MobUse from "../../../assets/images/t_mob_use.png";
import NoOvertaking from "../../../assets/images/t_no_overtaking.png";
import OverSpeed from "../../../assets/images/t_over_speed.png";
import PedestrianCrossing from "../../../assets/images/t_pedestrian_crossing.png";
import RailwayCrossing from "../../../assets/images/t_railway_crossing.png";
import SchoolZone from "../../../assets/images/t_school_zone.png";
import StopSign from "../../../assets/images/t_stop.png";
import suddenBrake from "../../../assets/images/t_sudden_break.png";
import TrafficLight from "../../../assets/images/t_traffic_light.png";
import WindingRoad from "../../../assets/images/t_winding_road.png";
import Yield from "../../../assets/images/t_yield.png";
import Priority from "../../../assets/images/t_priority.png";
import IcyCondition from "../../../assets/images/t_icy_conditions.png";
import Wind from "../../../assets/images/t_wind.png";
import Hill from "../../../assets/images/t_hill.png";
import SlipperyRoad from "../../../assets/images/t_slippery_road.png";
import RiskGrounding from "../../../assets/images/t_risk_of_grouping.png";
import RoundAbout from "../../../assets/images/Round-About.png";

const styles = StyleSheet.create({
    pdfViewer: {
        height: "96%",
        width: "100%",
        marginTop: "20px",
        marginBottom: "5px",
    },
    document: {
        marginTop: "15px",
        marginBottom: "5px",
    },
    page: {
        paddingLeft: 30,
        paddingRight: 30,
        width: "100%",
    },
    watermark: {
        position: "absolute",
        fontSize: 95,
        // opacity: 0.3,
        color: "#e6e6e6",
        transform: "rotate(-52deg)",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        top: "42%",
        left: "22%",
    },
    footer: {
        position: "absolute",
        marginLeft: 30,
        marginRight: 30,
        bottom: 0,
        left: 0,
        right: 0,
        height: "44.5px",
    },
    header: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottomWidth: 1,
        padding: 5,
    },
    headerText: {
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "center",
        flex: 1,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 5,
    },
    cell: {
        fontSize: 10,
        textAlign: "center",
    },
    section: {
        marginBottom: "10px",
        marginTop: "15px",
    },
    table: {
        display: "table",
        width: "100%",
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        width: "20%",
        height: "30px",
    },
    imgAbbr: {
        width: 12,
        height: 12,
        marginRight: 1,
        marginLeft: 1,
        marginBottom: "3px",
    },
});

const fontBold = {
    fontFamily: "Helvetica-Bold",
};

const fontItalic = {
    fontFamily: "Times-BoldItalic",
};

const watermarkText = "MotivAI";

const footer = [
    { f: "https://motivai.tech" },
    { f: "https://app.motivai.tech" },
    { f: '"Drive Safe, Arrive Safe"' },
    { f: "A MotivAI Product" },
];

const RiskAlertSummaryPdf = ({
    riskAlertPdf,
    aCBDataProps,
    rccProps,
    osMcuMsuProps,
    aCBProps,
    osDataProps,
    msuPdfDataProps,
    mcuPdfDataProps,
    section2filter,
}) => {
    // console.log("riskAlertPdf", riskAlertPdf);
    const companyName = localStorage.getItem(SC_COMPANY_NAME);
    const firstName = localStorage.getItem(SC_USER_FIRST_NAME);

    const months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
    ];
    var currentDate = new Date();

    const reversedDateFormat =
        currentDate.getDate().toString().padStart(2, "0") +
        "-" +
        months[currentDate.getMonth()] +
        "-" +
        currentDate.getFullYear();

    const header = [
        { title: "App Name", name: "Motiv AI" },
        { title: "Customer Name", name: companyName },
        { title: "Date", name: reversedDateFormat },
        { title: "First Name", name: firstName },
    ];

    var totalPages = 0;

    const applyConditionsToStaticData3 = () => {
        return rccProps.data.map((value, index) => {
            let transformedValue;

            if (value > 0) {
                if (value < 2000) {
                    transformedValue = ((value / 2000) * 230).toFixed(1);
                } else {
                    transformedValue = 230;
                }
            } else {
                transformedValue = 0;
            }

            return {
                key: `1_${index}`,
                x: index * 22 + 5,
                y: 199.5 - transformedValue,
                width: 10,
                height: transformedValue,
                fill: "rgb(84, 112, 198)",
                textY: 199.5 - transformedValue - 5,
                textContent: value || "",
            };
        });
    };

    const transformedData = applyConditionsToStaticData3();

    const staticData3 = [
        { title: AnimalCrossing, value: 405 },
        { title: Caution, value: 505 },
        { title: Curves, value: 650 },
        { title: Hill, value: 705 },
        { title: HillDownwards, value: 1000 },
        { title: HillUpwards, value: 250 },
        { title: IcyCondition, value: 150 },
        { title: Intersection, value: 3000 },
        { title: LaneMerge, value: 850 },
        { title: LowGearArea, value: 905 },
        { title: NarrowRoad, value: 350 },
        { title: NoOvertaking, value: 150 },
        { title: PedestrianCrossing, value: 450 },
        { title: Priority, value: 505 },
        { title: RailwayCrossing, value: 650 },
        { title: RiskGrounding, value: 750 },
        { title: RoundAbout, value: 950 },
        { title: SchoolZone, value: 205 },
        { title: SlipperyRoad, value: 150 },
        { title: StopSign, value: 305 },
        { title: TrafficLight, value: 850 },
        { title: Wind, value: 905 },
        { title: WindingRoad, value: 350 },
        { title: Yield, value: 150 },
    ];

    const abbrevationData = [
        [
            { image: AnimalCrossing, text: "Animal Crossing" },
            { image: Caution, text: "Caution" },
            { image: Curves, text: "Curve" },
            { image: Hill, text: "Hill" },
            { image: HillDownwards, text: "Hill Downwards" },
        ],
        [
            { image: HillUpwards, text: "Hill Upwards" },
            { image: IcyCondition, text: "Icy Condition" },
            { image: Intersection, text: "Intersection" },
            { image: LaneMerge, text: "Lane Merge" },
            { image: LowGearArea, text: "Low Gear Area" },
        ],
        [
            { image: NarrowRoad, text: "Narrow Road" },
            { image: NoOvertaking, text: "No Overtaking" },
            { image: PedestrianCrossing, text: "Pedestrian Crossing" },
            { image: Priority, text: "Priority" },
            { image: RailwayCrossing, text: "Railway Crossing" },
        ],
        [
            { image: RiskGrounding, text: "Risk Grounding" },
            { image: RoundAbout, text: "Round About" },
            { image: SchoolZone, text: "School Zone" },
            { image: SlipperyRoad, text: "Slippery Road" },
            { image: StopSign, text: "Stop Sign" },
        ],
        [
            { image: TrafficLight, text: "Traffic Light" },
            { image: Wind, text: "Wind" },
            { image: WindingRoad, text: "Winding Road" },
            { image: Yield, text: "Yield" },
        ],
    ];

    if (!osMcuMsuProps || osMcuMsuProps.length < 3) {
        // console.error("osMcuMsuProps is undefined or has less than 3 elements");
        return null;
    }

    const overSpeed = osMcuMsuProps[0].toString();
    const mobileCallUse = osMcuMsuProps[1].toString();
    const mobileScreenUse = osMcuMsuProps[2].toString();

    const originalMax = Math.max(
        parseFloat(overSpeed),
        parseFloat(mobileCallUse),
        parseFloat(mobileScreenUse)
    );

    const convertToHeight = (values, newMax) => {
        return values.map((value) => {
            const numericValue = parseFloat(value);
            // console.log("Numeric value:", numericValue);
            if (!isNaN(numericValue)) {
                const originalMin = 0;
                const newMin = 0;
                let mappedValue =
                    ((numericValue - originalMin) / (originalMax - originalMin)) *
                    (newMax - newMin) +
                    newMin;
                mappedValue = Math.min(Math.max(mappedValue, newMin), newMax);
                mappedValue *= -1;
                return mappedValue;
            } else {
                // console.log("Error: Value is not a number");
                return 0;
            }
        });
    };

    const labels = ["Overspeed", "Mobile Call Use", "Mobile Screen Use"];
    const values = [overSpeed, mobileCallUse, mobileScreenUse];
    const heights = convertToHeight(values, 135);

    const data1 = values.map((value, index) => ({
        // x: 26 + index * (100 / values.length) + 56,   //this is for if in future they change it to four values or 1 values
        x: 26 + index * 90,
        y: 179.5,
        width: "10",
        height: heights[index],
        fill: "rgb(84, 112, 198)",
        text: value,
        label: labels[index],
    }));

    const lmh = {
        low: ValueFormat.parseAsString(aCBProps.low),
        medium: ValueFormat.parseAsString(aCBProps.medium),
        high: ValueFormat.parseAsString(aCBProps.high),
    };

    const maxLMHValue = Math.max(
        ...Object.values(lmh)
            .flat()
            .map((value) => parseInt(value, 10))
    );

    const generateLMHData = () => {
        const lmhData = [];
        const severityOrder = ["low", "medium", "high"];

        for (let setIndex = 0; setIndex < 3; setIndex++) {
            severityOrder.forEach((severity, severityIndex) => {
                const value = lmh[severity][setIndex];
                const x = 38 + setIndex * (17 * 3 + 30) + severityIndex * 17;
                const y = 179.5;
                const label = value;
                const height = -(value / maxLMHValue) * 135;
                let color = "rgb(54, 115, 53)";

                if (severity === "medium") {
                    color = "rgb(251, 191, 0)";
                } else if (severity === "high") {
                    color = "rgb(255, 0, 0)";
                }

                lmhData.push({ x, y, label, height, color });
            });
        }

        return lmhData;
    };

    const lmhData = generateLMHData();

    const totalRiskAlertDataLength = riskAlertPdf?.length;
    const displayRisAlertRange =
        totalRiskAlertDataLength > 0
            ? `Showing 1 - ${Math.min(
                6,
                totalRiskAlertDataLength
            )} out of ${totalRiskAlertDataLength}`
            : "Showing 0 out of 0";
    const columns = ["tripIcon", "alertImage", "rideName", "date", "speed"];
    const columnDisplayNameMap = {
        tripIcon: "Trip Icon",
        alertImage: "Alert Image",
        rideName: "Ride Name",
        date: "Date/Time",
        speed: "Speed",
    };

    const totalACBDataLength = aCBDataProps?.length;
    const displayACBDataRange =
        totalACBDataLength > 0
            ? `Showing 1 - ${Math.min(
                6,
                totalACBDataLength
            )} out of ${totalACBDataLength}`
            : "Showing 0 out of 0";

    const ACBColumn = ["rideName", "date", "type", "subCategoryLevel", "speed"];
    const columnDisplayNameMap1 = {
        rideName: "Ride Name",
        date: "Date/Time",
        type: "Type",
        subCategoryLevel: "Subtype",
        speed: "Speed",
    };

    const columns1 = ["rideName", "date", "speedLimit", "speed"];
    const columnDisplayNameMap2 = {
        rideName: "Ride Name",
        date: "Date/Time",
        speedLimit: "Speed Limit",
        speed: "Speed",
    };
    const columns2 = ["rideName", "date", "speed"];
    const columnDisplayNameMap3 = {
        rideName: "Ride Name",
        date: "Date/Time",
        speed: "Speed",
    };

    const totalOSDataLength = osDataProps?.length;
    const displayOSDataRange =
        totalOSDataLength > 0
            ? `Showing 1 - ${Math.min(
                6,
                totalOSDataLength
            )} out of ${totalOSDataLength}`
            : "Showing 0 out of 0";

    const totalMSUDataLength = msuPdfDataProps?.length;
    const displayMSUDataRange =
        totalMSUDataLength > 0
            ? `Showing 1 - ${Math.min(
                6,
                totalMSUDataLength
            )} out of ${totalMSUDataLength}`
            : "Showing 0 out of 0";

    const totalMCUDataLength = mcuPdfDataProps?.length;
    const displayMCUDataRange =
        totalMCUDataLength > 0
            ? `Showing 1 - ${Math.min(
                6,
                totalMCUDataLength
            )} out of ${totalMCUDataLength}`
            : "Showing 0 out of 0";

    let period = "";
    let startDate = "";
    let endDate = "";
    if (section2filter.durationFilter === "CURRENT_YEAR") {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = UtilDateTime.getCurrentYear(section2filter.startDateTime);
        endDate = "";
    } else if (section2filter.durationFilter === "CURRENT_MONTH") {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = UtilDateTime.getCurrentMonth(section2filter.startDateTime);
        endDate = "";
    } else if (section2filter.durationFilter === "CURRENT_WEEK") {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = UtilDateTime.getCurrentWeek1(section2filter.startDateTime);
        endDate = "";
    } else if (section2filter.durationFilter === "CURRENT_DAY") {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = UtilDateTime.getCurrentDay(section2filter.startDateTime);
        endDate = "";
    } else {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = section2filter.startDateTime.split(" ")[0];
        endDate = section2filter.endDateTime.split(" ")[0];
    }

    const maxRowsRA = 6;
    const maxRows = 6; // Maximum rows to render
    const aCBDataPropsrowCount = Math.min(aCBDataProps?.length, maxRows);
    const riskAlertPdfPropsrowCount = Math.min(riskAlertPdf?.length, maxRowsRA);
    const osDataPropsrowCount = Math.min(osDataProps?.length, maxRows);
    const msuPdfDataPropsrowCount = Math.min(msuPdfDataProps?.length, maxRows);
    const mcuPdfDataPropsrowCount = Math.min(mcuPdfDataProps?.length, maxRows);

    const { data } = rccProps;

    // Define the static data with titles and corresponding values from rccProps.data
    const staticData = [
        { title: "Animal Crossing", value: data[0] },
        { title: "Caution", value: data[1] },
        { title: "Curves", value: data[2] },
        { title: "Hill", value: data[3] },
        { title: "Hill Downwards", value: data[4] },
        { title: "Hill Upwards", value: data[5] },
        { title: "Icy Condition", value: data[6] },
        { title: "Intersection", value: data[7] },
        { title: "Lane Merge", value: data[8] },
        { title: "Low Gear Area", value: data[9] },
        { title: "Narrow Road", value: data[10] },
        { title: "No Overtaking", value: data[11] },
        { title: "Pedestrian Crossing", value: data[12] },
        { title: "Priority", value: data[13] },
        { title: "Railway Crossing", value: data[14] },
        { title: "Risk Grounding", value: data[15] },
        { title: "Round About", value: data[16] },
        { title: "School Zone", value: data[17] },
        { title: "Slippery Road", value: data[18] },
        { title: "Stop Sign", value: data[19] },
        { title: "Traffic Light", value: data[20] },
        { title: "Wind", value: data[21] },
        { title: "Winding Road", value: data[22] },
        { title: "Yield", value: data[23] },
    ];
    const table1 = staticData.slice(0, 8);
    const table2 = staticData.slice(8, 16);
    const table3 = staticData.slice(16);
    // console.log(staticData, "staticData");

    const changeableheight = "28px";
    return (
        <React.Fragment>
            <PDFViewer style={styles.pdfViewer}>
                <Document style={styles.document}>
                    <Page totalPages={totalPages++} style={styles.page}>
                        <Text style={styles.watermark}>{watermarkText}</Text>
                        <View style={{ height: "4.4%", marginBottom: "20px" }}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    borderBottom: "1px solid black",
                                    backgroundColor: "lightgrey",
                                }}
                            >
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "100%",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Image
                                        src={Logo}
                                        style={{
                                            height: "21px",
                                            width: "100px",
                                            marginTop: "2px",
                                            marginBottom: "5px",
                                            marginLeft: "31px",
                                        }}
                                    />
                                    <View
                                        style={{
                                            flexDirection: "Row",
                                            marginBottom: "5px",
                                            marginLeft: "30px",
                                        }}
                                    >
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={[fontBold, { fontSize: 11 }]}>
                                                {" "}
                                                {header[1].title}:{" "}
                                            </Text>
                                            <Text style={{ fontSize: 11, marginLeft: "3px" }}>
                                                {" "}
                                                {header[1].name}{" "}
                                            </Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    marginTop: "2px",
                                                    width: "60%",
                                                }}
                                            >
                                                <Text
                                                    style={[fontBold, { fontSize: 11, width: "85px" }]}
                                                >
                                                    {" "}
                                                    {header[3].title}:{" "}
                                                </Text>
                                                <Text style={{ fontSize: 11, width: "100%" }}>
                                                    {" "}
                                                    {header[3].name}{" "}
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    marginTop: "2px",
                                                    marginLeft: "42px",
                                                }}
                                            >
                                                <View style={{ flexDirection: "row" }}>
                                                    <Text style={[fontBold, { fontSize: 11 }]}>
                                                        {" "}
                                                        {header[2].title}:{" "}
                                                    </Text>
                                                    <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View
                            style={{
                                width: "100%",
                                flexDirection: "column",
                                marginBottom: "20px",
                            }}
                        >
                            <Text
                                style={[
                                    fontBold,
                                    {
                                        fontSize: 11,
                                        margin: "3px",
                                        marginBottom: "10px",
                                        textAlign: "center",
                                        color: "#003cb3",
                                        textDecoration: "underline",
                                    },
                                ]}
                            >
                                User Dashboard (Risk Alert Summary){" "}
                            </Text>
                            <Text
                                style={{
                                    color: "black",
                                    fontSize: "11px",
                                    textDecoration: "none",
                                    fontWeight: 600,
                                    textAlign: "center",
                                }}
                            >
                                This report is generated for the period {period}{" "}
                                {endDate === ""
                                    ? `(${startDate})`
                                    : `(Between ${startDate} and ${endDate})`}
                            </Text>
                        </View>

                        {/* <View style={{ marginBottom: "5px" }}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Text style={[fontBold, { fontSize: 11 }]}>
                                        Risk Alert Count
                                    </Text>
                                    <Text style={{ fontSize: "8px" }}>
                                        {"  "}(
                                        <Text style={[fontBold, { fontSize: "8px" }]}>Note:</Text>{" "}
                                        Max bar length set to 3000. For values above 3000, consider
                                        labels.)
                                    </Text>
                                </View>
                            </View>

                            <View
                                style={{
                                    flexDirection: "column",
                                }}
                            >
                                <Svg width="100%" height="220px">
                                    <Line
                                        x1="0"
                                        y1="20"
                                        x2="0"
                                        y2="200"
                                        stroke="black"
                                        strokeWidth="1"
                                    />
                                    <Line
                                        x1="0"
                                        y1="200"
                                        x2="550"
                                        y2="200"
                                        stroke="black"
                                        strokeWidth="1"
                                    />
                                    {transformedData.map((item) => (
                                        <>
                                            <Rect {...item} />
                                            <Text
                                                key={item.key}
                                                x={item.x + 5}
                                                y={item.textY}
                                                style={{ fontSize: "8px" }}
                                                fill="black"
                                                textAnchor="middle"
                                            >
                                                {item.textContent}
                                            </Text>
                                        </>
                                    ))}

                                    {staticData3.map((item, index) => (
                                        <Image
                                            key={index}
                                            src={item.title}
                                            x={index * 22 + 5}
                                            y={205}
                                            style={{ width: 10, height: 10 }}
                                        />
                                    ))}
                                </Svg>
                            </View>
                            <View style={{ marginTop: "7.5px", textAlign: "center" }}>
                                <Text style={{ fontSize: "9px" }}>
                                    Note: X-axis for Category, Y-axis for Count
                                </Text>
                            </View>
                        </View> */}

                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <Text style={[fontBold, { fontSize: 11 }]}>
                                1. Risk Alert Count
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginBottom: "5px" }}>
                            <View
                                style={{
                                    width: "160px",
                                    border: "1px solid black",
                                    borderBottom: "none",
                                    marginRight: "27.5px",
                                    borderLeft: "none",
                                    borderRight: "none",
                                }}
                            >
                                {table1.map((item, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            fontSize: "9px",
                                        }}
                                    >
                                        <View
                                            style={{
                                                borderLeft: "1px solid black",
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black",
                                                width: "100px",
                                                padding: "5px",
                                            }}
                                        >
                                            <Text>{item.title}</Text>
                                        </View>
                                        <View
                                            style={{
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black",
                                                width: "60px",
                                                textAlign: "right",
                                                padding: "5px",
                                                paddingRight:'25px'
                                            }}
                                        >
                                            <Text>{item.value}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>

                            <View
                                style={{
                                    width: "160px",
                                    border: "1px solid black",
                                    borderBottom: "none",
                                    marginRight: "27.5px",
                                    borderLeft: "none",
                                    borderRight: "none",
                                }}
                            >
                                {table2.map((item, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            fontSize: "9px",
                                        }}
                                    >
                                        <View
                                            style={{
                                                borderLeft: "1px solid black",
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black",
                                                width: "100px",
                                                padding: "5px",
                                            }}
                                        >
                                            <Text>{item.title}</Text>
                                        </View>
                                        <View
                                            style={{
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black",
                                                width: "60px",
                                                textAlign: "right",
                                                padding: "5px",
                                                paddingRight:'25px'
                                            }}
                                        >
                                            <Text>{item.value}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>

                            <View
                                style={{
                                    width: "160px",
                                    border: "1px solid black",
                                    borderBottom: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                }}
                            >
                                {table3.map((item, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            fontSize: "9px",
                                        }}
                                    >
                                        <View
                                            style={{
                                                borderLeft: "1px solid black",
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black",
                                                width: "100px",
                                                padding: "5px",
                                            }}
                                        >
                                            <Text>{item.title}</Text>
                                        </View>
                                        <View
                                            style={{
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black",
                                                width: "60px",
                                                textAlign: "right",
                                                padding: "5px",
                                                paddingRight:'25px'
                                            }}
                                        >
                                            <Text>{item.value}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>

                        {/* <View style={styles.section}>
                            <View style={styles.table}>
                                {abbrevationData.map((row, rowIndex) => (
                                    <View key={rowIndex} style={styles.tableRow}>
                                        {row.map((col, colIndex) => (
                                            <View key={colIndex} style={styles.tableCol}>
                                                <View
                                                    style={{
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Image
                                                        src={col.image}
                                                        style={[
                                                            styles.imgAbbr,
                                                            { width: "20px", height: "18px" },
                                                        ]}
                                                    />
                                                    <Text style={styles.cell}>{col.text}</Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>
                                ))}
                            </View>
                        </View> */}

                        <View
                            style={{
                                width: "100%",
                                height: "230.666px",
                                marginBottom: "10px",
                                marginTop: "10px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <View style={{ width: "50%" }}>
                                <View
                                    style={{
                                        fontSize: "11px",
                                        marginBottom: "3px",
                                        textAlign: "left",
                                        fontWeight: 900,
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Text style={fontBold}>
                                        2. Overspeed / Mobile Call Use / Mobile Screen Use
                                    </Text>
                                </View>
                                <Svg
                                    width="100%"
                                    height="100%"
                                // style={{ position: "absolute" }}
                                >
                                    <Line
                                        x1="0.5"
                                        y1="40"
                                        x2="0.5"
                                        y2="180"
                                        stroke="black"
                                        strokeWidth="1"
                                    />
                                    <Line
                                        x1="0"
                                        y1="180"
                                        x2="262"
                                        y2="180"
                                        stroke="black"
                                        strokeWidth="1"
                                    />

                                    {data1.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Rect
                                                x={item.x}
                                                y={item.y}
                                                width={item.width}
                                                height={item.height}
                                                fill={item.fill}
                                            />
                                            <Text
                                                x={item.x + 5}
                                                textAnchor="middle"
                                                y={item.y + item.height - 4}
                                                fill="black"
                                                style={{ fontSize: "10px" }}
                                            >
                                                {item.text}
                                            </Text>
                                        </React.Fragment>
                                    ))}
                                </Svg>

                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        marginTop: "-47.5px",
                                    }}
                                >
                                    {data1.map((item, index) => (
                                        <Text key={index} style={{ fontSize: "9px" }}>
                                            {item.label}
                                        </Text>
                                    ))}
                                </View>
                                <View style={{ marginTop: "7.5px", textAlign: "center" }}>
                                    <Text style={{ fontSize: "9px" }}>
                                        Note: X-axis for Category, Y-axis for Count
                                    </Text>
                                </View>
                            </View>
                            <View style={{ width: "50%" }}>
                                <View
                                    style={[
                                        fontBold,
                                        {
                                            fontSize: "11px",
                                            marginBottom: "10px",
                                            textAlign: "left",
                                            marginLeft: "5px",
                                            fontWeight: 900,
                                        },
                                    ]}
                                >
                                    <Text style={{ marginBottom: "10px" }}>
                                        3. Acceleration / Cornering / Braking
                                    </Text>
                                    <View
                                        style={{
                                            marginLeft: "36px",
                                            width: "100%",
                                            marginBottom: "-10px",
                                        }}
                                    >
                                        <Svg viewBox="0 0 700 20" width={"200%"}>
                                            <Rect
                                                x="0"
                                                rx="2"
                                                ry="2"
                                                width="25"
                                                height="15"
                                                fill="rgb(54, 115, 53)"
                                            />
                                            <Text
                                                x="30"
                                                y="50%"
                                                style={{ height: "15", fontSize: "11px" }}
                                            >
                                                {" "}
                                                Low
                                            </Text>
                                            <Rect
                                                x="90"
                                                rx="2"
                                                ry="2"
                                                width="25"
                                                height="15"
                                                fill="rgb(251, 191, 0)"
                                            />
                                            <Text
                                                x="120"
                                                y="50%"
                                                style={{
                                                    height: "15",
                                                    fontSize: "11px",
                                                    marginLeft: "5px",
                                                }}
                                            >
                                                {" "}
                                                Medium
                                            </Text>
                                            <Rect
                                                x="190"
                                                rx="2"
                                                ry="2"
                                                width="25"
                                                height="15"
                                                fill="rgb(255, 0, 0)"
                                            />
                                            <Text
                                                x="220"
                                                y="50%"
                                                style={{ height: "15", fontSize: "11px" }}
                                            >
                                                {" "}
                                                High
                                            </Text>
                                        </Svg>
                                    </View>
                                </View>

                                <View
                                    style={{
                                        fontSize: "10px",
                                        marginTop: "0px",
                                        marginLeft: "5px",
                                        marginBottom: "-33px",
                                    }}
                                >
                                    <Text>{"  "}</Text>
                                </View>

                                <Svg>
                                    <Line
                                        x1="8.5"
                                        y1="40"
                                        x2="8.5"
                                        y2="180"
                                        stroke="black"
                                        strokeWidth="1"
                                    />
                                    <Line
                                        x1="8"
                                        y1="180"
                                        x2="267"
                                        y2="180"
                                        stroke="black"
                                        strokeWidth="1"
                                    />
                                    {lmhData.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Rect
                                                x={item.x}
                                                y={item.y}
                                                fill={item.color}
                                                width="10"
                                                height={item.height}
                                            />
                                            <Text
                                                x={item.x + 5}
                                                y={item.y + item.height - 4}
                                                fill="black"
                                                style={{ fontSize: "10px" }}
                                                textAnchor="middle"
                                            >
                                                {item.label}
                                            </Text>
                                        </React.Fragment>
                                    ))}
                                </Svg>
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "-84.5px",
                                    }}
                                >
                                    <Text style={{ fontSize: "9px", marginLeft: "36px" }}>
                                        Accelaration
                                    </Text>
                                    <Text style={{ fontSize: "9px", marginLeft: "36px" }}>
                                        Cornering
                                    </Text>
                                    <Text style={{ fontSize: "9px", marginLeft: "45px" }}>
                                        Braking
                                    </Text>
                                </View>
                                <View style={{ marginTop: "7.5px", textAlign: "center" }}>
                                    <Text style={{ fontSize: "9px" }}>
                                        Note: X-axis for Category, Y-axis for Count
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View
                            style={{
                                height: "226.6px",
                                marginBottom: "10px",
                            }}
                        >
                            <View style={{ width: "100%", marginBottom: "15px" }}>
                                <View
                                    style={{
                                        width: "100%",
                                        height: "20px",
                                        marginBottom: "5px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <View style={{ flexDirection: "row" }}>
                                        <Text style={[fontBold, { fontSize: "10px" }]}>
                                            4. Risk Alert (Top {maxRowsRA})
                                        </Text>
                                        {/* <Text style={{ fontSize: "10px" }}>
                                            {" "}
                                            {`(${displayRisAlertRange})`}
                                        </Text> */}
                                    </View>
                                    <View>
                                        <Text style={{ fontSize: "10px" }}>
                                            Showing for {UtilDateTime.getCurrentYear()}
                                        </Text>
                                    </View>
                                </View>

                                <View>
                                    <View
                                        style={{ flexDirection: "row", height: changeableheight }}
                                    >
                                        {columns.map((column, index) => (
                                            <View
                                                key={index}
                                                style={{
                                                    flex: column === "date" ? 1.8 : 1,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "9px",
                                                    border: "1px solid black",
                                                    // borderBottom: "1px solid black",
                                                    borderLeft: index === 0 ? "1px solid black" : "none",
                                                    // borderTop: "1px solid black",
                                                    // borderLeft: index === 0 ? "1px solid black" : "none",
                                                    // borderTop: "1px solid black",
                                                    // borderLeft: "1px solid black",
                                                }}
                                            >
                                                <Text style={fontBold} break>
                                                    {columnDisplayNameMap[column]}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                    {Array.from({ length: riskAlertPdfPropsrowCount }).map(
                                        (_, rowIndex) => (
                                            <View
                                                key={rowIndex}
                                                style={{
                                                    flexDirection: "row",
                                                    height: changeableheight,
                                                    borderBottom:
                                                        rowIndex === 9
                                                            ? "1px solid black"
                                                            : // : "1px dashed black",
                                                            "1px solid black",
                                                }}
                                            >
                                                {columns.map((column, colIndex) => {
                                                    const isFirstColumn = colIndex === 0;
                                                    const isLastColumn = colIndex === columns.length - 1;

                                                    const cellData =
                                                        riskAlertPdf[rowIndex] &&
                                                            riskAlertPdf[rowIndex][column] !== undefined
                                                            ? riskAlertPdf[rowIndex][column]
                                                            : null;

                                                    const borderStyles = {
                                                        flex: column === "date" ? 1.8 : 1,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "8px",
                                                        borderLeft: isFirstColumn
                                                            ? "1px solid black"
                                                            : // : "1px dashed black",
                                                            "1px solid black",
                                                        borderRight: isLastColumn
                                                            ? "1px solid black"
                                                            : "none",
                                                    };

                                                    return (
                                                        <View key={colIndex} style={borderStyles}>
                                                            {column === "alertImage" && cellData ? (
                                                                <Image
                                                                    src={require(`../../../assets/images/${cellData}`)}
                                                                    style={{ width: "15px", height: "15px" }}
                                                                />
                                                            ) : column === "tripIcon" && cellData ? (
                                                                <Image
                                                                    src={require(`../../../assets/images/TripIcons/${cellData}`)}
                                                                    style={{ width: "5px", height: "5px" }}
                                                                />
                                                            ) : (
                                                                <Text style={{ fontSize: "8px" }} break>
                                                                    {cellData !== null && cellData !== undefined
                                                                        ? cellData
                                                                        : "-"}
                                                                </Text>
                                                            )}
                                                        </View>
                                                    );
                                                })}
                                            </View>
                                        )
                                    )}
                                </View>
                            </View>
                        </View>

                        <View style={styles.footer}>
                            <View style={{ borderBottom: "1px solid black" }} />
                            <View style={{ flexDirection: "row", bottom: "0px" }}>
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "30%",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                                        {" "}
                                        {footer[0].f}{" "}
                                    </Text>
                                    <Text style={[fontItalic, { fontSize: "10px" }]}>
                                        {" "}
                                        {footer[2].f}{" "}
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        marginTop: "10px",
                                        width: "40%",
                                        textAlign: "center",
                                    }}
                                >
                                    <Text style={{ fontSize: "11px" }}>
                                        {" "}
                                        Page {totalPages} of 3
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "30%",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            marginBottom: "3px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {" "}
                                        {footer[1].f}{" "}
                                    </Text>
                                    <Text style={{ fontSize: "10px", textAlign: "right" }}>
                                        {" "}
                                        {footer[3].f}{" "}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                    <Page totalPages={totalPages++} style={styles.page}>
                        <Text style={styles.watermark}>{watermarkText}</Text>

                        <View style={{ height: "4.4%", marginBottom: "20px" }}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    borderBottom: "1px solid black",
                                    backgroundColor: "lightgrey",
                                }}
                            >
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "100%",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Image
                                        src={Logo}
                                        style={{
                                            height: "21px",
                                            width: "100px",
                                            marginTop: "2px",
                                            marginBottom: "5px",
                                            marginLeft: "31px",
                                        }}
                                    />

                                    <View
                                        style={{
                                            flexDirection: "Row",
                                            marginBottom: "5px",
                                            marginLeft: "30px",
                                        }}
                                    >
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={[fontBold, { fontSize: 11 }]}>
                                                {" "}
                                                {header[1].title}:{" "}
                                            </Text>
                                            <Text style={{ fontSize: 11, marginLeft: "3px" }}>
                                                {" "}
                                                {header[1].name}{" "}
                                            </Text>
                                        </View>

                                        <View style={{ flexDirection: "row" }}>
                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    marginTop: "2px",
                                                    width: "60%",
                                                }}
                                            >
                                                <Text
                                                    style={[fontBold, { fontSize: 11, width: "85px" }]}
                                                >
                                                    {" "}
                                                    {header[3].title}:{" "}
                                                </Text>
                                                <Text style={{ fontSize: 11, width: "100%" }}>
                                                    {" "}
                                                    {header[3].name}{" "}
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    marginTop: "2px",
                                                    marginLeft: "42px",
                                                }}
                                            >
                                                <View style={{ flexDirection: "row" }}>
                                                    <Text style={[fontBold, { fontSize: 11 }]}>
                                                        {" "}
                                                        {header[2].title}:{" "}
                                                    </Text>
                                                    <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View
                            style={{
                                height: "246.6px",
                            }}
                        >
                            <View style={{ width: "100%", marginBottom: "20px" }}>
                                <View
                                    style={{
                                        width: "100%",
                                        height: "20px",
                                        marginBottom: "5px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <View style={{ flexDirection: "row" }}>
                                        <Text style={[fontBold, { fontSize: "10px" }]}>
                                            5. Acceleration / Cornering / Braking (Top {maxRows})
                                        </Text>
                                        {/* <Text style={{ fontSize: "10px" }}>
                                            {" "}
                                            {`(${displayACBDataRange})`}
                                        </Text> */}
                                    </View>
                                    <View>
                                        <Text style={{ fontSize: "10px" }}>
                                            Showing for {UtilDateTime.getCurrentYear()}
                                        </Text>
                                    </View>
                                </View>
                                <View>
                                    <View
                                        style={{ flexDirection: "row", height: changeableheight }}
                                    >
                                        {ACBColumn.map((column, index) => (
                                            <View
                                                key={index}
                                                style={{
                                                    flex: column === "date" ? 1.8 : 1,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "9px",
                                                    // borderBottom: "1px solid black",
                                                    // borderRight: "1px solid black",
                                                    // borderTop: "1px solid black",
                                                    // borderLeft: index === 0 ? "1px solid black" : "none",
                                                    border: "1px solid black",
                                                    // borderBottom: "1px solid black",
                                                    borderLeft: index === 0 ? "1px solid black" : "none",
                                                }}
                                            >
                                                <Text style={fontBold} break>
                                                    {columnDisplayNameMap1[column]}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>

                                    {Array.from({ length: aCBDataPropsrowCount }).map(
                                        (_, rowIndex) => (
                                            <View
                                                key={rowIndex}
                                                style={{
                                                    flexDirection: "row",
                                                    height: changeableheight,
                                                    borderBottom:
                                                        rowIndex === 9
                                                            ? "1px solid black"
                                                            : // : "1px dashed black",
                                                            "1px solid black",
                                                }}
                                            >
                                                {ACBColumn.map((column, colIndex) => (
                                                    <View
                                                        key={colIndex}
                                                        style={{
                                                            flex: column === "date" ? 1.8 : 1,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "8px",
                                                            // borderBottom: "1px solid black",
                                                            // borderRight: "1px solid black",
                                                            // borderLeft:
                                                            //   colIndex === 0 ? "1px solid black" : "none",
                                                            borderLeft:
                                                                colIndex === 0
                                                                    ? "1px solid black"
                                                                    : // : "1px dashed black",
                                                                    "1px solid black",
                                                            borderRight:
                                                                colIndex === column.length - 1
                                                                    ? "1px solid black"
                                                                    : "none",
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: "8px" }} break>
                                                            {aCBDataProps[rowIndex] &&
                                                                aCBDataProps[rowIndex][column] !== undefined
                                                                ? aCBDataProps[rowIndex][column]
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                aCBDataProps[rowIndex][column].slice(1)
                                                                : // .toLowerCase()
                                                                "-"}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                        )
                                    )}
                                </View>
                            </View>
                        </View>

                        <View
                            style={{
                                height: "246.6px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <View
                                style={{
                                    // width: "59.5%"
                                    width: "100%",
                                }}
                            >
                                <View
                                    style={{
                                        width: "100%",
                                        height: "20px",
                                        marginBottom: "5px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text style={[fontBold, { fontSize: "10px" }]}>
                                            6. Overspeed (Top {maxRows})
                                        </Text>
                                        {/* <Text style={{ fontSize: "10px" }}>
                                            {" "}
                                            {`(${displayOSDataRange})`}
                                        </Text> */}
                                    </View>
                                    <View>
                                        <Text style={{ fontSize: "10px" }}>
                                            Showing for {UtilDateTime.getCurrentYear()}
                                        </Text>
                                    </View>
                                </View>

                                <View>
                                    <View
                                        style={{ flexDirection: "row", height: changeableheight }}
                                    >
                                        {columns1.map((column, index) => (
                                            <View
                                                key={index}
                                                style={{
                                                    flex: column === "date" ? 1.5 : 1,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "9px",
                                                    // borderBottom: "1px solid black",
                                                    // borderRight: "1px solid black",
                                                    // borderTop: "1px solid black",
                                                    // borderLeft: index === 0 ? "1px solid black" : "none",
                                                    border: "1px solid black",
                                                    // borderBottom: "1px solid black",
                                                    borderLeft: index === 0 ? "1px solid black" : "none",
                                                }}
                                            >
                                                <Text style={fontBold} break>
                                                    {columnDisplayNameMap2[column]}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>

                                    {Array.from({ length: osDataPropsrowCount }).map(
                                        (_, rowIndex) => (
                                            <View
                                                key={rowIndex}
                                                style={{
                                                    flexDirection: "row",
                                                    height: changeableheight,
                                                    borderBottom:
                                                        rowIndex === 5
                                                            ? "1px solid black"
                                                            : // : "1px dashed black",
                                                            "1px solid black",
                                                }}
                                            >
                                                {columns1.map((column, colIndex) => (
                                                    <View
                                                        key={colIndex}
                                                        style={{
                                                            flex: column === "date" ? 1.5 : 1,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "8px",
                                                            // borderBottom: "1px solid black",
                                                            // borderRight: "1px solid black",
                                                            // borderLeft:
                                                            //   colIndex === 0 ? "1px solid black" : "none",
                                                            borderLeft:
                                                                colIndex === 0
                                                                    ? "1px solid black"
                                                                    : // : "1px dashed black",
                                                                    "1px solid black",

                                                            borderRight:
                                                                colIndex === 3 ? "1px solid black" : "none",
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: "8px" }} break>
                                                            {osDataProps[rowIndex] &&
                                                                osDataProps[rowIndex][column] !== undefined
                                                                ? osDataProps[rowIndex][column]
                                                                : "-"}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                        )
                                    )}
                                </View>
                            </View>
                        </View>

                        <View
                            style={{
                                height: "246.6px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <View style={{ width: "100%" }}>
                                <View
                                    style={{
                                        width: "100%",
                                        height: "20px",
                                        marginBottom: "5px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text style={[fontBold, { fontSize: "10px" }]}>
                                            7. Mobile Screen Use (Top {maxRows})
                                        </Text>
                                        {/* <Text style={{ fontSize: "10px" }}>
                                            {" "}
                                            {`(${displayMSUDataRange})`}
                                        </Text> */}
                                    </View>
                                    <View>
                                        <Text style={{ fontSize: "10px" }}>
                                            Showing for {UtilDateTime.getCurrentYear()}
                                        </Text>
                                    </View>
                                </View>
                                <View>
                                    <View
                                        style={{ flexDirection: "row", height: changeableheight }}
                                    >
                                        {columns2.map((column, index) => (
                                            <View
                                                key={index}
                                                style={{
                                                    flex: column === "date" ? 1.8 : 1,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "9px",
                                                    borderBottom: "1px solid black",
                                                    // borderRight: "1px solid black",
                                                    // borderTop: "1px solid black",
                                                    // borderLeft: index === 0 ? "1px solid black" : "none",
                                                    border: "1px solid black",
                                                    // borderBottom: "1px solid black",
                                                    borderLeft: index === 0 ? "1px solid black" : "none",
                                                }}
                                            >
                                                <Text style={fontBold} break>
                                                    {columnDisplayNameMap3[column]}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>

                                    {Array.from({ length: msuPdfDataPropsrowCount }).map(
                                        (_, rowIndex) => (
                                            <View
                                                key={rowIndex}
                                                style={{
                                                    flexDirection: "row",
                                                    height: changeableheight,
                                                    borderBottom:
                                                        rowIndex === 5
                                                            ? "1px solid black"
                                                            : // : "1px dashed black",
                                                            "1px solid black",
                                                }}
                                            >
                                                {columns2.map((column, colIndex) => (
                                                    <View
                                                        key={colIndex}
                                                        style={{
                                                            flex: column === "date" ? 1.8 : 1,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            textAlign: "right",
                                                            fontSize: "8px",
                                                            // borderBottom: "1px solid black",
                                                            // borderRight: "1px solid black",
                                                            // borderLeft:
                                                            //   colIndex === 0 ? "1px solid black" : "none",
                                                            borderLeft:
                                                                colIndex === 0
                                                                    ? "1px solid black"
                                                                    : "1px solid black",
                                                            // : "1px dashed black",
                                                            borderRight:
                                                                colIndex === 2 ? "1px solid black" : "none",
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: "8px" }} break>
                                                            {msuPdfDataProps[rowIndex] &&
                                                                msuPdfDataProps[rowIndex][column] !== undefined
                                                                ? msuPdfDataProps[rowIndex][column]
                                                                : "-"}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                        )
                                    )}
                                </View>
                            </View>
                        </View>

                        <View style={styles.footer}>
                            <View style={{ borderBottom: "1px solid black" }} />
                            <View style={{ flexDirection: "row", bottom: "0px" }}>
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "30%",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                                        {" "}
                                        {footer[0].f}{" "}
                                    </Text>
                                    <Text style={[fontItalic, { fontSize: "10px" }]}>
                                        {" "}
                                        {footer[2].f}{" "}
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        marginTop: "10px",
                                        width: "40%",
                                        textAlign: "center",
                                    }}
                                >
                                    <Text style={{ fontSize: "11px" }}>
                                        {" "}
                                        Page {totalPages} of 3{" "}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "30%",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            marginBottom: "3px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {" "}
                                        {footer[1].f}{" "}
                                    </Text>
                                    <Text style={{ fontSize: "10px", textAlign: "right" }}>
                                        {" "}
                                        {footer[3].f}{" "}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </Page>

                    <Page totalPages={totalPages++} style={styles.page}>
                        <Text style={styles.watermark}>{watermarkText}</Text>
                        <View style={{ height: "4.4%", marginBottom: "20px" }}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    borderBottom: "1px solid black",
                                    backgroundColor: "lightgrey",
                                }}
                            >
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "100%",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Image
                                        src={Logo}
                                        style={{
                                            height: "21px",
                                            width: "100px",
                                            marginTop: "2px",
                                            marginBottom: "5px",
                                            marginLeft: "31px",
                                        }}
                                    />
                                    <View
                                        style={{
                                            flexDirection: "Row",
                                            marginBottom: "5px",
                                            marginLeft: "30px",
                                        }}
                                    >
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={[fontBold, { fontSize: 11 }]}>
                                                {" "}
                                                {header[1].title}:{" "}
                                            </Text>
                                            <Text style={{ fontSize: 11, marginLeft: "3px" }}>
                                                {" "}
                                                {header[1].name}{" "}
                                            </Text>
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    marginTop: "2px",
                                                    width: "60%",
                                                }}
                                            >
                                                <Text
                                                    style={[fontBold, { fontSize: 11, width: "85px" }]}
                                                >
                                                    {" "}
                                                    {header[3].title}:{" "}
                                                </Text>
                                                <Text style={{ fontSize: 11, width: "100%" }}>
                                                    {" "}
                                                    {header[3].name}{" "}
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    marginTop: "2px",
                                                    marginLeft: "42px",
                                                }}
                                            >
                                                <View style={{ flexDirection: "row" }}>
                                                    <Text style={[fontBold, { fontSize: 11 }]}>
                                                        {" "}
                                                        {header[2].title}:{" "}
                                                    </Text>
                                                    <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* <View
                            style={{
                                height: "230px",
                                marginBottom: "10px",
                            }}
                        >
                            <View style={{ width: "100%", marginBottom: "15px" }}>
                                <View
                                    style={{
                                        width: "100%",
                                        height: "30px",
                                        marginBottom: "10px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <View style={{ flexDirection: "row" }}>
                                        <Text style={[fontBold, { fontSize: "10px" }]}>
                                            Risk Alert
                                        </Text>
                                        <Text style={{ fontSize: "10px" }}>
                                            {" "}
                                            {`(${displayRisAlertRange})`}
                                        </Text>
                                    </View>
                                    <View>
                                        <Text style={{ fontSize: "10px" }}>
                                            Showing for {UtilDateTime.getCurrentYear()}
                                        </Text>
                                    </View>
                                </View>

                                <View>
                                    <View style={{ flexDirection: "row", height: "40px" }}>
                                        {columns.map((column, index) => (
                                            <View
                                                key={index}
                                                style={{
                                                    flex: column === "date" ? 1.8 : 1,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "9px",
                                                    border: "1px solid black",
                                                    // borderBottom: "1px solid black",
                                                    borderLeft: index === 0 ? "1px solid black" : "none",
                                                    // borderTop: "1px solid black",
                                                    // borderLeft: index === 0 ? "1px solid black" : "none",
                                                    // borderTop: "1px solid black",
                                                    // borderLeft: "1px solid black",
                                                }}
                                            >
                                                <Text style={fontBold} break>
                                                    {columnDisplayNameMap[column]}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                    {Array.from({ length: 6 }).map((_, rowIndex) => (
                                        <View
                                            key={rowIndex}
                                            style={{
                                                flexDirection: "row",
                                                height: "40px",
                                                borderBottom:
                                                    rowIndex === 9
                                                        ? "1px solid black"
                                                        : // : "1px dashed black",
                                                        "1px solid black",
                                            }}
                                        >
                                            {columns.map((column, colIndex) => {
                                                const isFirstColumn = colIndex === 0;
                                                const isLastColumn = colIndex === columns.length - 1;

                                                const cellData =
                                                    riskAlertPdf[rowIndex] &&
                                                        riskAlertPdf[rowIndex][column] !== undefined
                                                        ? riskAlertPdf[rowIndex][column]
                                                        : null;

                                                const borderStyles = {
                                                    flex: column === "date" ? 1.8 : 1,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "8px",
                                                    borderLeft: isFirstColumn
                                                        ? "1px solid black"
                                                        : // : "1px dashed black",
                                                        "1px solid black",
                                                    borderRight: isLastColumn
                                                        ? "1px solid black"
                                                        : "none",
                                                };

                                                return (
                                                    <View key={colIndex} style={borderStyles}>
                                                        {column === "alertImage" && cellData ? (
                                                            <Image
                                                                src={require(`../../../assets/images/${cellData}`)}
                                                                style={{ width: "15px", height: "15px" }}
                                                            />
                                                        ) : column === "tripIcon" && cellData ? (
                                                            <Image
                                                                src={require(`../../../assets/images/TripIcons/${cellData}`)}
                                                                style={{ width: "5px", height: "5px" }}
                                                            />
                                                        ) : (
                                                            <Text style={{ fontSize: "8px" }} break>
                                                                {cellData !== null && cellData !== undefined
                                                                    ? cellData
                                                                    : "-"}
                                                            </Text>
                                                        )}
                                                    </View>
                                                );
                                            })}
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </View> */}

                        <View
                            style={{
                                height: "246.6px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <View style={{ width: "100%" }}>
                                <View
                                    style={{
                                        width: "100%",
                                        height: "20px",
                                        fontSize: "5px",
                                        marginBottom: "10px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text style={[fontBold, { fontSize: "10px" }]}>
                                            8. Mobile Call Use (Top {maxRows})
                                        </Text>
                                        {/* <Text style={{ fontSize: "9px" }}>
                                            {" "}
                                            {`(${displayMCUDataRange})`}
                                        </Text> */}
                                    </View>
                                    <View>
                                        <Text style={{ fontSize: "10px" }}>
                                            Showing for {UtilDateTime.getCurrentYear()}
                                        </Text>
                                    </View>
                                </View>
                                <View>
                                    <View
                                        style={{ flexDirection: "row", height: changeableheight }}
                                    >
                                        {columns2.map((column, index) => (
                                            <View
                                                key={index}
                                                style={{
                                                    flex: column === "date" ? 1.8 : 1,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "9px",
                                                    // borderBottom: "1px solid black",
                                                    // borderRight: "1px solid black",
                                                    // borderTop: "1px solid black",
                                                    // borderLeft: index === 0 ? "1px solid black" : "none",
                                                    border: "1px solid black",
                                                    // borderBottom: "1px solid black",
                                                    borderLeft: index === 0 ? "1px solid black" : "none",
                                                }}
                                            >
                                                <Text style={fontBold} break>
                                                    {columnDisplayNameMap3[column]}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>
                                    {Array.from({ length: mcuPdfDataPropsrowCount }).map(
                                        (_, rowIndex) => (
                                            <View
                                                key={rowIndex}
                                                style={{
                                                    flexDirection: "row",
                                                    height: changeableheight,
                                                    borderBottom:
                                                        rowIndex === 5
                                                            ? "1px solid black"
                                                            : // : "1px dashed black",
                                                            "1px solid black",
                                                }}
                                            >
                                                {columns2.map((column, colIndex) => (
                                                    <View
                                                        key={colIndex}
                                                        style={{
                                                            flex: column === "date" ? 1.8 : 1,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: 8,
                                                            // borderBottom: "1px solid black",
                                                            // borderRight: "1px solid black",
                                                            // borderLeft:
                                                            //   colIndex === 0 ? "1px solid black" : "none",
                                                            borderLeft:
                                                                colIndex === 0
                                                                    ? "1px solid black"
                                                                    : "1px solid black",
                                                            // : "1px dashed black",
                                                            borderRight:
                                                                colIndex === 2 ? "1px solid black" : "none",
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: 8 }} break>
                                                            {mcuPdfDataProps[rowIndex] &&
                                                                mcuPdfDataProps[rowIndex][column] !== undefined
                                                                ? mcuPdfDataProps[rowIndex][column]
                                                                : "-"}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                        )
                                    )}
                                    {/* {Array.from({ length: 6 }).map((_, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{ flexDirection: "row", height: 25 }}
                    >
                      {columns2.map((column, colIndex) => {
                        const cellData =
                         mcuPdfDataProps[rowIndex] &&
                          mcuPdfDataProps[rowIndex][column] !== undefined
                            ? mcuPdfDataProps[rowIndex][column]
                            : null;

                        const borderStyles = {
                          flex: column === "date" ? 1.8 : 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 8,
                          borderBottom:
                            cellData || rowIndex === 5
                              ? "1px solid black"
                              : "none",
                          borderRight:
                            cellData || colIndex === 2
                              ? "1px solid black"
                              : "none",
                          borderLeft:
                            colIndex === 0 ? "1px solid black" : "none",
                        };

                        return (
                          <View key={colIndex} style={borderStyles}>
                            <Text style={{ fontSize: 8 }} break>
                              {cellData || " "}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  ))} */}
                                </View>
                            </View>
                            {/* <View style={{ width: "49.5%" }}>
                                <View
                                    style={{
                                        width: "100%",
                                        height: "30px",
                                        fontSize: "12px",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Text style={{ fontSize: "10px" }}>
                                        Showing for {UtilDateTime.getCurrentYear()}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        textAlign: "justify",
                                        fontSize: "10px",
                                    }}
                                >
                                    <Text>
                                        The table provides a concise summary of the most recent 10
                                        rides and insight into the precise times of mobile call
                                        usage, the speed traveled during the ride, and the
                                        corresponding time of each event.
                                    </Text>
                                </View>
                            </View> */}
                        </View>

                        <View style={styles.footer}>
                            <View style={{ borderBottom: "1px solid black" }} />
                            <View style={{ flexDirection: "row", bottom: "0px" }}>
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "30%",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                                        {" "}
                                        {footer[0].f}{" "}
                                    </Text>
                                    <Text style={[fontItalic, { fontSize: "10px" }]}>
                                        {" "}
                                        {footer[2].f}{" "}
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        marginTop: "10px",
                                        width: "40%",
                                        textAlign: "center",
                                    }}
                                >
                                    <Text style={{ fontSize: "11px" }}>
                                        {" "}
                                        Page {totalPages} of 3{" "}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        marginTop: "5px",
                                        width: "30%",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: "11px",
                                            marginBottom: "3px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {" "}
                                        {footer[1].f}{" "}
                                    </Text>
                                    <Text style={{ fontSize: "10px", textAlign: "right" }}>
                                        {" "}
                                        {footer[3].f}{" "}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </React.Fragment>
    );
};

export default RiskAlertSummaryPdf;
