import React, { useState, useEffect } from "react"
import Leaflet from "leaflet"
import L from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import CurrentLocationController from "../../../controller/VehicleCurrentLocationController/CurrentLocationController";
import ValueFormat from "../../../util/ValueFormat";
import LocationIcon from "../../../assets/images/locationicon.png"
Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"
import "./overspeedlocation.css"
import { displayText } from "../../../constant/MessageConstant";
import { useSelector } from "react-redux"
delete Leaflet.Icon.Default.prototype._getIconUrl

// const icon  = Leaflet.Icon.Default.mergeOptions({
//   iconUrl: require('../../../assets/images/locationicon.png'),
//   shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
// })

const myIcon = L.icon({
  iconUrl: require('../../../assets/images/locationRedYellow.png'),
  iconSize: [35,35],
  iconAnchor: [32, 64], 
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  shadowSize: 30,
  shadowAnchor: [18, 54]
});

const  OverSpeedLocationMaps = () => {
  const [overSpeedData, setOverSpeedData] = useState([])

  const [state, setState] = useState({
    lat: 20.00000000,
    lng: 77.00000000,
    zoom: 4,
  })
  
 
  const { overSpeed} = useSelector((state) => ({
    overSpeed: state.OverSpeedLocationData.overSpeed,
  }));





  useEffect(()=>{
    setOverSpeedData(overSpeed.value)
  },[overSpeed])
 
  const [position, setPosition] = useState([state.lat, state.lng]);
  const [lastRideName, setLastRideName] = useState("")
  const [drivingScore, setDrivingScore] = useState(0);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("")
  const [dataDisplay, setDataDisplay] = useState(false);


  const getLocation = async () => {
    setDataDisplay(null)
    var result = await CurrentLocationController.currentLocation();
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
        // console.log("getLocation: " + JSON.stringify(resultJSON));

    if(status == "SUCCESS"){
    var resultJSONData= resultJSON.data;
    var latLongResult = [];
    // console.log("getLocation: " + JSON.stringify(resultJSONData));

    resultJSONData.map((value)=>{
      var rideList = value.rideList;
      rideList.map((data, i)=>{
        var deviceDataAddressField = ValueFormat.parseAsObject(data.deviceDataAddressField)
        var latitude = ValueFormat.parseAsString(data.latitude);
        var longitude = ValueFormat.parseAsString(data.longitude);
        
        var deviceName = ValueFormat.parseAsString(data.deviceName);
        var drivingScore = Math.round(ValueFormat.parseAsFloat(data.drivingScore,2));
        var location = ValueFormat.parseAsString(deviceDataAddressField.location);
        var city = ValueFormat.parseAsString(deviceDataAddressField.city);
        var state = ValueFormat.parseAsString(deviceDataAddressField.state);
        var country = ValueFormat.parseAsString(deviceDataAddressField.country);

        try {
          setLocation(location.trim() + ", " + city.trim() + ", " + state.trim() + ", " + country.trim())
        } catch (Exception) {
          setLocation();
        }

        if(i==0) {
          latLongResult.push(latitude, longitude)
     
          setState(updateData => {
            return {...updateData, lat:latitude, lng:longitude }
          })

          setPosition(latLongResult)
          setLastRideName(deviceName);
          setDrivingScore(drivingScore);
          setLatitude(latitude);
          setLongitude(longitude);
        }
      })
    })
  }else{
    const code = resultJSON.error.code;
    if(code == "E1206"){
      setDataDisplay("failed")
    }
  }
  }

  useEffect(()=>{
    getLocation()
    setDataDisplay();
  },[])

    return (
      <div>
        {dataDisplay == "failed" ? (
          <p className="data_not_available">{displayText.ER_1206}</p>
        ): dataDisplay == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
        ) : "" }
      <MapContainer
        center={position}
        zoom={ state.zoom}
        style={{ height: "440px",zIndex:0}}
      >
        <TileLayer
          attribution=''
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {overSpeedData&&overSpeedData.map&&overSpeedData.map((apiData)=>(
        <Marker 
        icon={myIcon}
        position={{lat:apiData.lat, lng:apiData.lng, zoom:4}}  key={apiData.type}>
          <Popup className="leaflet-popup-css">
          <div>
            <h6>Location Time : {apiData.date}</h6>
            <h6>Name : {apiData.type}</h6>
            <h6>Speed : {apiData.speed} Km</h6>
            <h6>Ride : {apiData.rideName}</h6>

          </div>
          </Popup>
        </Marker>
        ))}
      </MapContainer>
     
      </div>
    )
  };

  export default OverSpeedLocationMaps;

