import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserDashboardSubMenu from "../pages/Dashboard/UserDashboardNew/UserDashboardSubMenu"
import UserAdminDashboardSubMenu from "../pages/Dashboard/UserDashboardNew/UserAdminDashboardSubMenu"
import UserDashboard from "../pages/Dashboard/UserDashboardNew/Section1"
import Section2 from "../pages/Dashboard/UserDashboardNew/Section2"
import Section3 from "../pages/Dashboard/UserDashboardNew/Section3"
import Section4 from "../pages/Dashboard/UserDashboardNew/Section4"


// Calendar
import Calendar from "../pages/Calendar/index";

//Chat
import Chat from "../pages/Chat/Chat";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

//Invoice
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

//Contact
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";

//blog
import BlogGrid from "../pages/Blog/blogGrid";
import BlogList from "../pages/Blog/blogList";
import BlogDetails from "../pages/Blog/blogDetails";

//Utility
import PagesStarter from "../pages/Utility/StarterPage";
import PageMaintenance from "../pages/Utility/PageMaintenance";
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageTimeline from "../pages/Utility/PageTimeline";
import PageFaqs from "../pages/Utility/PageFaqs";
import PagePricing from "../pages/Utility/PagePricing/index";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Ui Components
import UiAlert from "../pages/UiElements/UiAlert";
import UiButton from "../pages/UiElements/UiButton";
import UiCard from "../pages/UiElements/UiCard";
import UiCarousel from "../pages/UiElements/UiCarousel";
import UiDropdowns from "../pages/UiElements/UiDropdowns";
import UiGrid from "../pages/UiElements/UiGrid";
import UiImages from "../pages/UiElements/UiImages";
import UiModal from "../pages/UiElements/UiModals";
import UiOffCanvas from "../pages/UiElements/UiOffCanvas";
import UiProgressbar from "../pages/UiElements/UiProgressbar";
import UiPlaceholders from "../pages/UiElements/UiPlaceholders";
import UiTabsAccordions from "../pages/UiElements/UiTabsAccordions";
import UiTypography from "../pages/UiElements/UiTypography";
import UiToasts from "../pages/UiElements/UiToast";
import UiVideo from "../pages/UiElements/UiVideo";
import UiGeneral from "../pages/UiElements/UiGeneral";
import UiColors from "../pages/UiElements/UiColors";
import UiUtilities from "../pages/UiElements/UiUtilities";

//Extended pages
import Lightbox from "../pages/Extended/Lightbox";
import Rangeslider from "../pages/Extended/Rangeslider";
import SessionTimeout from "../pages/Extended/SessionTimeout";
import UiRating from "../pages/Extended/UiRating";
import Notifications from "../pages/Extended/Notifications";


//Forms
import FormElements from "../pages/Forms/FormElements/index";
import FormValidation from "../pages/Forms/FormValidation/";
import AdvancedPlugins from "../pages/Forms/AdvancedPlugins";
import FormEditors from "../pages/Forms/FormEditors";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormMask from "../pages/Forms/FormMask";

//Tables
import BasicTable from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

//Charts
import Apexchart from "../pages/Charts/Apexcharts";
import EChart from "../pages/Charts/EChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import KnobCharts from "../pages/Charts/KnobCharts";
import SparklineChart from "../pages/Charts/SparklineChart";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesomes from "../pages/Icons/Fontawesomes";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageRegister from "../pages/AuthenticationInner/PageRegister";
import RecoverPassword from "../pages/AuthenticationInner/RecoverPassword";
import LockScreen from "../pages/AuthenticationInner/LockScreen";
import ConfirmMail from "../pages/AuthenticationInner/ConfirmMail";
import EmailVerification from "../pages/AuthenticationInner/EmailVerification";
import TwoStepVerfication from "../pages/AuthenticationInner/TwoStepVerfication";
import AuthLogout from "../pages/AuthenticationInner/Logout";
import UserProfile from "../pages/Authentication/user-profile";
import MyRides from "../pages/MyRides/index";
import RideDetails from "../pages/RideDetails/ridedetails";
import AdminDashboard from "../pages/Admin/AdminDashboard";
import AdminDashboardSubMenu from "../pages/Admin/TrialVersion/AdminDashboardSubMenu";
import OverallSummaryy from "../pages/Admin/TrialVersion/OverallSummary";
import CategorySummary from "../pages/Admin/TrialVersion/CategorySummary";
import RiskSummary from "../pages/Admin/TrialVersion/RiskSummary";
import MonthlyWeeklySummary from "../pages/Admin/TrialVersion/MonthlyWeeklySummary";
import SuperAdminDashboard from "../pages/SuperAdmin/SuperAdminDashboard";
import SuperAdminAmazonDashboard from "../pages/SuperAdminAmazon/SuperAdminDashboard";
import MyUsersDataList from "../pages/Admin/Myusers/myusersdata";
import MyUsersNewTable from "../pages/Admin/NewTab/tableDataMyuser";
import MyRidesNew from "../pages/MyRide/myridedatanew";
import ActiveUsersTableList from "../pages/Admin/ActiveUserNew/activeusersTable";
import RiskChart from '../pages/Admin/exportPdfFullPage/RiskChart';
import UserAppBootSettings from  '../pages/UserAppBootSettings/appBootSettings'
import ShiftConfiguration from "../pages/Admin/shiftConfiguration";
import AdminAppBootSettings from "../pages/AdminRideSettings/adminappbootsettings";
import ProfilePage from "../components/CommonForBoth/ProfilePage/profilepagemain";
import NotificationPage from "../pages/NotificationPage/notificationPages";
import MapcomponentsView from "../pages/MapVehicleTracking/MapComponentView";
import CompanyUsers from "../pages/SuperAdminAmazon/CompanyUser";
import { SC_USER_LOGIN_TYPE} from "../constant/StorageConstant";

const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE)
import MyRidesMobScreen from "../pages/MyRides/MyRidesMobScreen";
import GoogleMapRiskView from "../pages/MapVehicleTracking/GoogleMapRiskView";
import MySubscription from "../pages/SuperAdminAmazon/MySubscription";
import MobileLicenseOrder from "../pages/SuperAdminAmazon/MobileLicenseOrder";
import UBIDashboardv3 from "../pages/Admin/UBIDashboard/UBIDashboardv3";
import LoginModal from "../pages/Authentication/LoginModal";
import UBIDashboardv4 from "../pages/Admin/UBIDashboard/UBIDashboardv4";
import CrashReport from "../pages/CrashDetails/CrashReport";
import CrashList from "../pages/CrashDetails/CrashList";
import AppException from "../pages/AppException/appException";
import GoogleMapCrashView from "../pages/MapVehicleTracking/googleMapCrash";
import UserLoginActivities from "../pages/UserLoginActivities/userLoginActivities";
import UserLastActivity from "../pages/UserlastActivity/UserLastActivities"
import UserDeviceHealth from "../pages/DeviceHealth/userDeviceHealth";
import NewUBIDashboard from "../pages/Admin/UBIDashboard/NewUBIDashboard";
import TriggerMessageForUnregisteredUsers from "../pages/SuperAdminAmazon/TriggerMessageForUnregisteredUsers";
import UserOnboardStatus from "../pages/SuperAdminAmazon/UserOnboardStatus";
import NewRideList from "../pages/MyRides/newRideList";
import UnsafeDrivers from "../pages/Admin/TrialVersion/UnsafeDrivers";
import AdminCrashList from "../pages/CrashDetails/AdminCrashList";
import AdminCrashListNew from "../pages/CrashDetails/AdminCrashListNew";
import CrashListUser from "../pages/CrashDetails/UserCrashList";
import SuperAdminCrashListV2 from "../pages/CrashDetails/SuperAdminCrashListV2";
import SuperAdminCrashListV1 from "../pages/CrashDetails/SuperAdminCrashListV1";
import SuperAdminGoogleMapCrashView from "../pages/MapVehicleTracking/SuperAdminGoogleMapCrash";
import UserGoogleMapCrashView from "../pages/MapVehicleTracking/UserGoogleMapCrash";


const userRoutes = [
  //dashboard
  { path: "/dashboardNew", component: <Dashboard/>, auth:"USER" },
  { path: "/UserDashboardSubMenu", component: <UserDashboardSubMenu/>, auth:"USER" },
  { path: "/UserAdminDashboardSubMenu", component: <UserAdminDashboardSubMenu/>, auth:"ADMIN" },
  { path: "/dashboard", component: <UserDashboard/>, auth:"USER" },
  { path: "/dashboard/RiskAlertSummary", component: <Section2/>, auth:"USER" },
  { path: "/dashboard/CategorySummary", component: <Section3/>, auth:"USER" },
  { path: "/dashboardNew/section4", component: <Section4/>, auth:"USER" },
  { path: "/adminDashboardNew", component: <AdminDashboard/>, auth:"ADMIN" },
  { path: "/adminDashboardSubMenu", component: <AdminDashboardSubMenu/>, auth:"ADMIN" },
  { path: "/adminDashboard", component: <OverallSummaryy/>, auth:"ADMIN" },
  { path: "/adminDashboard/CategorySummary", component: <CategorySummary/>, auth:"ADMIN" },
  { path: "/adminDashboard/RiskSummary", component: <RiskSummary/>, auth:"ADMIN" },
  { path: "/adminDashboard/ActivityUsageSummary", component: <MonthlyWeeklySummary/>, auth:"ADMIN" },
  { path: "/adminDashboard/LowestPerformanceDrivers", component: <UnsafeDrivers/>, auth:"ADMIN" },
  { path: "/superAdminDashboard", component: <SuperAdminDashboard/>, auth:"SUPER_ADMIN" },
  { path: "/superAdminClientDashboard", component: <SuperAdminAmazonDashboard/>, auth:"SUPER_ADMIN" },
  { path: "/companyUsers", component: <CompanyUsers/>, auth:"SUPER_ADMIN" },
  { path: "/mySubscription", component: <MySubscription/>, auth:"USER_SUPER_ADMIN" },
  { path: "/mobileLicenseOrder", component: <MobileLicenseOrder/>, auth:"SUPER_ADMIN" },
  { path: "/journeyView", component: <GoogleMapRiskView/>, auth:"USER_SUPER_ADMIN" },
  { path: "/crashView", component: <GoogleMapCrashView/>, auth:"USER_SUPER_ADMIN" },
  { path: "/crashViewSA", component: <SuperAdminGoogleMapCrashView/>, auth:"SUPER_ADMIN" },
  { path: "/crashViewUser", component: <UserGoogleMapCrashView/>, auth:"USER" },
  { path: "/myuserspage", component: <MyUsersDataList/>},
  { path: "/activeuserspage", component: <ActiveUsersTableList/>, auth:"ADMIN"},
  { path: "/user-dashboard", component: <UserDashboard/>, auth:"USER_SUPER_ADMIN" },
  { path: "/user-dashboard/RiskAlertSummary", component: <Section2/>, auth:"USER_SUPER_ADMIN" },
  { path: "/user-dashboard/CategorySummary", component: <Section3/>, auth:"USER_SUPER_ADMIN" },
  { path: "/ubi-dashboard", component: <UBIDashboardv3/>, auth:"USER" },
  { path: "/drivingEfficeiency", component: <NewUBIDashboard/>, auth:"USER" },
  { path: "/crash-report", component: <CrashReport/>, auth:"USER_SUPER_ADMIN" },
  { path: "/ubi-dashboardv2", component: <UBIDashboardv4/>, auth:"USER" },
  { path: "/exportRisk", component: <RiskChart/>, auth:"ADMIN" },
  { path: "/appBootSettings", component: <UserAppBootSettings/>, auth:"USER_SUPER_ADMIN" },
  { path: "/shiftConfiguration", component: <ShiftConfiguration/>, auth:"USER_SUPER_ADMIN" },
  { path: "/adminappbootsettings", component: <AdminAppBootSettings/>,  },
  { path: "/notifications", component: <NotificationPage/>, auth:"USER_SUPER_ADMIN"},
  
  { path: "/userLoginActivities", component: <UserLoginActivities />, auth: "SUPER_ADMIN"},
  { path: "/userLastActivities" , component: <UserLastActivity/>, auth:"SUPER_ADMIN"},
  { path: "/vehicleCrashList" , component: <CrashList/>, auth:"USER_SUPER_ADMIN", },
  { path: "/vehicleCrashListUser" , component: <CrashListUser/>, auth:"USER_SUPER_ADMIN", },
  { path: "/vehicleCrashListPage" , component: <AdminCrashList/>, auth:"USER_SUPER_ADMIN", },
  { path: "/vehicleCrashListPageNew" , component: <AdminCrashListNew/>, auth:"USER_SUPER_ADMIN", },
  { path: "/appExceptionActivities" , component: <AppException/>, auth:"SUPER_ADMIN", },
  { path: "/triggerSmsUnregisteredUsers" , component: <TriggerMessageForUnregisteredUsers/>, auth:"SUPER_ADMIN", },
  { path: "/userOnboardStatus" , component: <UserOnboardStatus/>, auth:"SUPER_ADMIN", },
  // { path: "/myRides" , component: <MyRides/>, auth:"USER_SUPER_ADMIN", },
  { path: "/myRides" , component: <NewRideList/>, auth:"USER_SUPER_ADMIN", },
  { path: "/maprideview" , component: <MapcomponentsView/>, auth:"USER_SUPER_ADMIN", },
  { path: "/responsiveridelist" , component: <MyRidesMobScreen/>, auth:"USER_SUPER_ADMIN", },
  { path: "/deviceHealth", component: <UserDeviceHealth />, auth:"USER_SUPER_ADMIN"},
  { path: "/vehicleCrashListSAV2" , component: <SuperAdminCrashListV2/>, auth:"USER_SUPER_ADMIN"},
  { path: "/vehicleCrashListSAV1" , component: <SuperAdminCrashListV1/>, auth:"USER_SUPER_ADMIN"},

  { path: "/myusertabledata", component: <MyUsersNewTable/>, auth:"ADMIN"},
  { path: "/myridedatapage", component: <MyRidesNew/>, auth:"USER" },

  { path: "/ridedetails", component: <RideDetails />, auth: "USER_SUPER_ADMIN" },

  //profile
  { path: "/profile", component: <UserProfile /> },

  //Calendar
  { path: "/apps-calendar", component: <Calendar /> },

  //
  

  //Chat
  { path: "/apps-chat", component: <Chat /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },

  //Invoice
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },

  //Contact
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  // { path: "/contacts-profile", component: <ContactsProfile /> , auth:"USER_SUPER_ADMIN"},

  //blog
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-list", component: <BlogList /> },
  { path: "/blog-details", component: <BlogDetails /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PageTimeline /> },
  { path: "/pages-faqs", component: <PageFaqs /> },
  { path: "/pages-pricing", component: <PagePricing /> },

  //Components
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButton /> },
  { path: "/ui-cards", component: <UiCard /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdowns /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-toasts", component: <UiToasts /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-utilities", component: <UiUtilities /> },

  //Extended pages
  { path: "/extended-lightbox", component: <Lightbox /> },
  { path: "/extended-rangeslider", component: <Rangeslider /> },
  { path: "/extended-session-timeout", component: <SessionTimeout /> },
  { path: "/extended-rating", component: <UiRating /> },
  { path: "/extended-notifications", component: <Notifications /> },

  //Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-validation", component: <FormValidation /> },
  { path: "/form-advanced", component: <AdvancedPlugins /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-mask", component: <FormMask /> },

  //tables
  { path: "/tables-basic", component: <BasicTable /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  //Charts
  { path: "/charts-apex", component: <Apexchart /> },
  { path: "/charts-echart", component: <EChart /> },
  { path: "/charts-chartjs", component: <ChartjsChart /> },
  { path: "/charts-knob", component: <KnobCharts /> },
  { path: "/charts-sparkline", component: <SparklineChart /> },

  //Icons
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-fontawesome", component: <IconFontawesomes /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },
  { path: "/maps-leaflet", component: <MapsLeaflet /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/login" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login />, auth: "login" },
  { path: "/loginmodal", component: <LoginModal />, auth: "login" },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },


  //AuthenticationInner pages
  { path: "/page-login", component: <PageLogin /> },
  { path: "/page-register", component: <PageRegister /> },
  { path: "/page-recoverpw", component: <RecoverPassword /> },
  { path: "/page-lock-screen", component: <LockScreen /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-email-verification", component: <EmailVerification /> },
  { path: "/page-two-step-verification", component: <TwoStepVerfication /> },
  { path: "/page-logout", component: <AuthLogout /> },

  //Utility page
  { path: "/pages-maintenance", component: <PageMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
];

export { userRoutes, authRoutes };
