import {ALL_RIDES_OVER_SPEED, ALL_RIDES_MOBILE_USE_DATA, ALL_RIDES_MOBILE_SCREEN_DATA} from "./actiontype";

const INIT_STATE_MOBILE_USE = {
  mobileUseData: [{date:"-",distance:"-", speed:"-"}]
};
const INIT_STATE_MOBILE_SCREEN = {
    mobileScreenData: [{date:"-",distance:"-", speed:"-"}]
  };
const INIT_STATE_OVER_SPEED = {
    overSpeedData: [{date:"-",distance:"-",speedLimit:"-", speed:"-"}]
  };
const allRideData = (state = INIT_STATE_OVER_SPEED, action) => {
  switch (action.type) {
    case ALL_RIDES_OVER_SPEED:
        return {
          ...state,
          overSpeedData: action.payload,
        };

    default:
      return state;
  }
};

export default allRideData;
