// import { Document, Image, Line, PDFViewer, Page, Rect, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
// import React from "react";
// import Logo from "../../../assets/images/logo/motivAiLogoPng.png";
// import { SC_COMPANY_NAME, SC_USER_FIRST_NAME } from "../../../constant/StorageConstant";
// import UtilDateTime from "../../../util/UtilDateTime";
// import PdfHeader from "../Components/pdfHeader";
// import PdfFooter from "../Components/pdfFooter";
// import { useLocation } from "react-router-dom";
// import PdfHeading from "../Components/pdfHeading";
// import ValueFormat from "../../../util/ValueFormat";

// const styles = StyleSheet.create({
//     pdfViewer: {
//         height: "96%",
//         width: "100%",
//         marginTop: "20px",
//         marginBottom: "5px",
//     },
//     document: {
//         marginTop: "15px",
//         marginBottom: "5px",
//     },
//     page: {
//         // paddingLeft: 30,
//         // paddingRight: 30,
//         width: "100%",
//     },
//     watermark: {
//         position: "absolute",
//         fontSize: 95,
//         // opacity: 0.3,
//         color: "#e6e6e6",
//         transform: "rotate(-52deg)",
//         justifyContent: "center",
//         textAlign: "center",
//         alignItems: "center",
//         top: "42%",
//         left: "22%",
//     },
//     footer: {
//         position: "absolute", marginLeft: 30, marginRight: 30, bottom: 0, left: 0, right: 0, height: "44.5px"
//     },
//     header: {
//         flexDirection: "row",
//         alignItems: "center",
//         justifyContent: "space-between",
//         borderBottomWidth: 1,
//         padding: 5,
//     },
//     headerText: {
//         fontSize: 12,
//         fontWeight: "bold",
//         textAlign: "center",
//         flex: 1,
//     },
//     row: {
//         flexDirection: "row",
//         alignItems: "center",
//         justifyContent: "space-between",
//         padding: 5,
//     },
//     cell: {
//         fontSize: 10,
//         textAlign: "center",
//     },
// });

// const fontBold = {
//     fontFamily: "Helvetica-Bold",
// };

// const fontItalic = {
//     fontFamily: "Times-BoldItalic",
// };

// const watermarkText = "MotivAI";


// const footer = [
//     { f: "https://motivai.tech" },
//     { f: "https://app.motivai.tech" },
//     { f: '"Drive Safe, Arrive Safe"' },
//     { f: "A MotivAI Product" },
// ];

// const ColouredActivityUsageSummaryPdf = ({ yearlyActivities, yearlyFilter, time, numberofRidessPdfData, activeDriverData }) => {
//     // console.log("yearlyFilter", numberofRidessPdfData);
//     const companyName = localStorage.getItem(SC_COMPANY_NAME);
//     const firstName = localStorage.getItem(SC_USER_FIRST_NAME);
//     const pathname = useLocation();
//     const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

//     let period = "";
//     let startDate = "";
//     let endDate = "";

//     if (yearlyFilter === "CURRENT_YEAR") {
//         period = ValueFormat.smallLettersFormat(yearlyFilter);
//         startDate = UtilDateTime.getCurrentYear(time);
//         endDate = "";
//     } else if (yearlyFilter === "CURRENT_MONTH") {
//         period = ValueFormat.smallLettersFormat(yearlyFilter);
//         startDate = UtilDateTime.getCurrentMonth(time);
//         endDate = "";
//     } else if (yearlyFilter === "CURRENT_WEEK") {
//         period = ValueFormat.smallLettersFormat(yearlyFilter);
//         startDate = UtilDateTime.getCurrentWeek1(time);
//         endDate = "";
//     } else if (yearlyFilter === "CURRENT_DAY") {
//         period = ValueFormat.smallLettersFormat(yearlyFilter);
//         startDate = UtilDateTime.getCurrentDay(time);
//         endDate = "";
//     } else {
//         period = ValueFormat.smallLettersFormat(yearlyFilter);
//         startDate = UtilDateTime.getCurrentYear(time);
//         endDate = "";
//     }

//     const periodFormat = [period, startDate, endDate];
//     const periodFormatWeek = [ValueFormat.smallLettersFormat(yearlyFilter), UtilDateTime.getCurrentWeek1(currentDate), ""];
//     var currentDate = new Date();
//     const heading = "Admin Dashboard (Active Usage Summary)";
//     const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

//     const header = [
//         { title: "App Name", name: "Motiv AI" },
//         { title: "Customer Name", name: companyName },
//         { title: "Date", name: reversedDateFormat },
//         { title: "First Name", name: firstName },
//     ];

//     var totalPages = 0;

//     let showTime = "";
//     if (yearlyFilter === "CURRENT_YEAR") {
//         showTime = UtilDateTime.getCurrentYear();
//     } else if (yearlyFilter === "CUSTOM_DURATION") {
//         showTime = UtilDateTime.getSelectedYear(time);
//     }

//     const monthData = [
//         { label: "Jan" },
//         { label: "Feb" },
//         { label: "Mar" },
//         { label: "Apr" },
//         { label: "May" },
//         { label: "Jun" },
//         { label: "Jul" },
//         { label: "Aug" },
//         { label: "Sep" },
//         { label: "Oct" },
//         { label: "Nov" },
//         { label: "Dec" },
//     ];


//     const rideActivity = yearlyActivities.find((activity) => activity.name === "Ride");
//     const kmActivity = yearlyActivities.find((activity) => activity.name === "Km");
//     const hourActivity = yearlyActivities.find((activity) => activity.name === "Hours");
//     const calculateHeight = (value, maxValue) => {
//         if (maxValue === 0) {
//             return 0;
//         }
//         const maxHeight = -150;
//         return -((parseFloat(value) / parseFloat(maxValue)) * maxHeight);
//     };
//     const rideHeightValues = rideActivity?.data;
//     const kmHeightValues = kmActivity?.data;
//     const hourHeightValues = hourActivity?.data;

//     const maxRideValue = Math.max(...rideHeightValues);
//     const maxKmValue = Math.max(...kmHeightValues);
//     const maxHourValue = Math.max(...hourHeightValues);

//     return (
//         <React.Fragment>
//             <PDFViewer style={styles.pdfViewer}>
//                 <Document style={styles.document}>

//                     <Page totalPages={totalPages++} style={styles.page}>

//                         <PdfHeader />
//                         <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
//                             <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
//                         </View>


//                         <View style={{ height: '210px', marginBottom: '15px', marginHorizontal: 30, backgroundColor: "#615AE41A", padding: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10 }}>
//                             <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
//                                 <View ><Text style={[fontBold, { fontSize: 11 }]}>1. Ride Summary</Text></View>
//                                 <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
//                             </View>

//                             <View style={{ height: '200px' }}>
//                                 <Svg width={"100%"} height={"245px"}>
//                                     <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
//                                     <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
//                                     {rideHeightValues.map((value, index) => (
//                                         <React.Fragment key={index}>
//                                             <Rect x={(index + 1) * 40} y={164.5} fill='#34c38f' width={20} height={calculateHeight(-value, maxRideValue)} />
//                                             <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxRideValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
//                                             <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
//                                         </React.Fragment>
//                                     ))}
//                                 </Svg>
//                                 <View style={{ width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
//                                     <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Ride</Text>
//                                 </View>
//                             </View>
//                         </View>


//                         <View style={{ height: '210px', marginBottom: '15px', marginHorizontal: 30, backgroundColor: "#615AE41A", padding: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10 }}>
//                             <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
//                                 <View ><Text style={[fontBold, { fontSize: 11 }]}>2. Distance Summary</Text></View>
//                                 <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
//                             </View>

//                             <View style={{ height: '200px' }}>
//                                 <Svg width={"100%"} height={"245px"}>
//                                     <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
//                                     <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
//                                     {kmHeightValues.map((value, index) => (
//                                         <React.Fragment key={index}>
//                                             <Rect x={(index + 1) * 40} y={164.5} fill='#556ee6' width={20} height={calculateHeight(-value, maxKmValue)} />
//                                             <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxKmValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
//                                             <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
//                                         </React.Fragment>
//                                     ))}
//                                 </Svg>
//                                 <View style={{ height: "20px", width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
//                                     <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Distance(kms)</Text>
//                                 </View>
//                             </View>
//                         </View>

//                         <View style={{ height: '210px', marginBottom: '10px', marginHorizontal: 30, backgroundColor: "#615AE41A", padding: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10 }}>
//                             <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
//                                 <View ><Text style={[fontBold, { fontSize: 11 }]}>3. Duration Summary  </Text></View>
//                                 <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
//                             </View>

//                             <View style={{ height: '200px' }}>
//                                 <Svg width={"100%"} height={"245px"}>
//                                     <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
//                                     <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
//                                     {hourHeightValues.map((value, index) => (
//                                         <React.Fragment key={index}>
//                                             <Rect x={(index + 1) * 40} y={164.5} fill='#f46a6a' width={20} height={calculateHeight(-value, maxHourValue)} />
//                                             <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxHourValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
//                                             <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
//                                         </React.Fragment>
//                                     ))}
//                                 </Svg>
//                                 <View style={{ height: "20px", width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
//                                     <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Hour</Text>
//                                 </View>
//                             </View>
//                         </View>

//                         <PdfFooter pageNumber={1} totalPages={2} pathname={pathname} />
//                     </Page>

//                     <Page totalPages={totalPages++} style={styles.page}>
//                     <PdfHeader />
//                         <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
//                             <PdfHeading heading={heading} periodFormat={periodFormatWeek} reversedDateFormat={reversedDateFormat} />
//                         </View>

//                         <View style={{ height: '700px', marginHorizontal: 30 }}>
//                             <View style={{ backgroundColor:'#615AE41A', borderRadius: 10, padding: 10, marginBottom: 10 }}>
//                             <View style={{ height: '30px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
//                                 <View ><Text style={[fontBold, { fontSize: 11 }]}>4. Number of Rides <Text style={[fontBold, { fontSize: 9 }]}>(All drivers)</Text> </Text></View>
//                             </View>
//                             <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column', marginBottom: '10px' }}>
//                                 <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Days</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sunday</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Monday</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Tuesday</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Wednesday</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Thursday</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Friday</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', backgroundColor: "#9e98fb",  flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Saturday</Text></View>
//                                 </View>
//                                 <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>No of Rides</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day0) ? numberofRidessPdfData.day0  : "-"}</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day1) ? numberofRidessPdfData.day1  : "-"}</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day2) ? numberofRidessPdfData.day2  : "-"}</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day3) ? numberofRidessPdfData.day3  : "-"}</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day4) ? numberofRidessPdfData.day4  : "-"}</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day5) ? numberofRidessPdfData.day5  : "-"}</Text></View>
//                                     <View style={[{ fontSize: 10, width: '66.75px', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day6) ? numberofRidessPdfData.day6  : "-"}</Text></View>
//                                 </View>
//                             </View>
//                             </View>
//                             <View style={{ backgroundColor:'#615AE41A', borderRadius: 10, padding: 10 }}>
//                             <View style={{ height: '30px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
//                                 <View ><Text style={[fontBold, { fontSize: 11 }]}>5. Per day Usage <Text style={[fontBold, { fontSize: 9 }]}>(All drivers)</Text> </Text></View>
//                             </View>
//                             <View style={{ border: '1px dashed lightgrey', width: '100%', flexDirection: 'column', marginBottom: '10px', borderBottom: 'none', borderRight: 'none' }}>
//                                 <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center', borderBottom: '1px dashed lightgrey' }}>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Username</Text></View>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sunday</Text></View>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Monday</Text></View>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Tuesday</Text></View>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Wednesday</Text></View>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Thursday</Text></View>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}> <Text>Friday</Text></View>
//                                     <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: "#9e98fb", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Saturday</Text></View>
//                                 </View>
//                                 {activeDriverData?.map((userData, index) => (
//                                     <View key={index} style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center', borderBottom: '1px dashed lightgrey' }}>
//                                         <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', justifyContent: 'center', alignItems: 'center' }]}>
//                                             <Text>{userData.name || "-"}</Text>
//                                         </View>
//                                         {userData.data.map((value, i) => (
//                                             <View key={i} style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', justifyContent: 'center', alignItems: 'center' }]}>
//                                                 <Text>{value || "-"}</Text>
//                                             </View>
//                                         ))}
//                                     </View>
//                                 ))}
//                             </View>
//                             </View>
//                         </View>

//                         <PdfFooter pageNumber={2} totalPages={2} pathname={pathname} />
//                     </Page>


//                 </Document>
//             </PDFViewer>
//         </React.Fragment>
//     );
// };

// export default ColouredActivityUsageSummaryPdf;

import { Document, Image, Line, PDFViewer, Page, Rect, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import React from "react";
import Logo from "../../../assets/images/logo/motivAiLogoPng.png";
import { SC_COMPANY_NAME, SC_USER_FIRST_NAME } from "../../../constant/StorageConstant";
import UtilDateTime from "../../../util/UtilDateTime";
import PdfHeader from "../Components/pdfHeaderV1";
import PdfFooter from "../Components/pdfFooterV1";
import { useLocation } from "react-router-dom";
import PdfHeading from "../Components/pdfHeading";
import ValueFormat from "../../../util/ValueFormat";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";

const styles = StyleSheet.create({
    pdfViewer: {
        height: "96%",
        width: "100%",
        marginTop: "20px",
        marginBottom: "5px",
    },
    document: {
        marginTop: "15px",
        marginBottom: "5px",
    },
    page: {
        // paddingLeft: 30,
        // paddingRight: 30,
        width: "100%",
    },
    watermark: {
        position: "absolute",
        fontSize: 95,
        // opacity: 0.3,
        color: "#e6e6e6",
        transform: "rotate(-52deg)",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        top: "42%",
        left: "22%",
    },
    footer: {
        position: "absolute", marginLeft: 30, marginRight: 30, bottom: 0, left: 0, right: 0, height: "44.5px"
    },
    header: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottomWidth: 1,
        padding: 5,
    },
    headerText: {
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "center",
        flex: 1,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 5,
    },
    cell: {
        fontSize: 10,
        textAlign: "center",
    },
});

const fontBold = {
    fontFamily: "Helvetica-Bold",
};

const fontItalic = {
    fontFamily: "Times-BoldItalic",
};

const watermarkText = "MotivAI";


const footer = [
    { f: "https://motivai.tech" },
    { f: "https://app.motivai.tech" },
    { f: '"Drive Safe, Arrive Safe"' },
    { f: "A MotivAI Product" },
];

const ColouredActivityUsageSummaryPdf = ({ yearlyActivities, yearlyFilter, time, numberofRidessPdfData, activeDriverData }) => {
    // console.log("yearlyFilter", numberofRidessPdfData);
    const companyName = localStorage.getItem(SC_COMPANY_NAME);
    const firstName = localStorage.getItem(SC_USER_FIRST_NAME);
    const pathname = useLocation();
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    let period = "";
    let startDate = "";
    let endDate = "";

    if (yearlyFilter === "CURRENT_YEAR") {
        period = ValueFormat.smallLettersFormat(yearlyFilter);
        startDate = UtilDateTime.getCurrentYear(time);
        endDate = "";
    } else if (yearlyFilter === "CURRENT_MONTH") {
        period = ValueFormat.smallLettersFormat(yearlyFilter);
        startDate = UtilDateTime.getCurrentMonth(time);
        endDate = "";
    } else if (yearlyFilter === "CURRENT_WEEK") {
        period = ValueFormat.smallLettersFormat(yearlyFilter);
        startDate = UtilDateTime.getCurrentWeek1(time);
        endDate = "";
    } else if (yearlyFilter === "CURRENT_DAY") {
        period = ValueFormat.smallLettersFormat(yearlyFilter);
        startDate = UtilDateTime.getCurrentDay(time);
        endDate = "";
    } else {
        period = ValueFormat.smallLettersFormat(yearlyFilter);
        startDate = UtilDateTime.getCurrentYear(time);
        endDate = "";
    }

    const periodFormat = [period, startDate, endDate];
    const periodFormatWeek = [ValueFormat.smallLettersFormat(yearlyFilter), UtilDateTime.getCurrentWeek1(currentDate), ""];
    var currentDate = new Date();
    const heading = "Admin Dashboard (Active Usage Summary)";
    const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

    const header = [
        { title: "App Name", name: "Motiv AI" },
        { title: "Customer Name", name: companyName },
        { title: "Date", name: reversedDateFormat },
        { title: "First Name", name: firstName },
    ];

    var totalPages = 0;

    let showTime = "";
    if (yearlyFilter === "CURRENT_YEAR") {
        showTime = UtilDateTime.getCurrentYear();
    } else if (yearlyFilter === "CUSTOM_DURATION") {
        showTime = UtilDateTime.getSelectedYear(time);
    }

    const monthData = [
        { label: "Jan" },
        { label: "Feb" },
        { label: "Mar" },
        { label: "Apr" },
        { label: "May" },
        { label: "Jun" },
        { label: "Jul" },
        { label: "Aug" },
        { label: "Sep" },
        { label: "Oct" },
        { label: "Nov" },
        { label: "Dec" },
    ];


    const rideActivity = yearlyActivities.find((activity) => activity.name === "Ride");
    const kmActivity = yearlyActivities.find((activity) => activity.name === "Km");
    const hourActivity = yearlyActivities.find((activity) => activity.name === "Hours");
    const calculateHeight = (value, maxValue) => {
        if (maxValue === 0) {
            return 0;
        }
        const maxHeight = -150;
        return -((parseFloat(value) / parseFloat(maxValue)) * maxHeight);
    };
    const rideHeightValues = rideActivity?.data;
    const kmHeightValues = kmActivity?.data;
    const hourHeightValues = hourActivity?.data;

    const maxRideValue = Math.max(...rideHeightValues);
    const maxKmValue = Math.max(...kmHeightValues);
    const maxHourValue = Math.max(...hourHeightValues);

    return (
        <React.Fragment>
            <PDFViewer style={styles.pdfViewer}>
                <Document style={styles.document}>

                    <Page size={"A2"} totalPages={totalPages++} style={styles.page}>

                        <PdfHeader />
                        <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
                            <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
                        </View>

                        <View style={{ flexDirection: 'row', marginHorizontal: 30, justifyContent: 'space-between' }}>
                            <View style={{ width: '49.5%', height: '250px', marginBottom: '15px', border: '1px solid lightgrey', padding: 20, paddingVertical: 15, borderRadius: 10 }}>
                                <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                    <View ><Text style={[fontBold, { fontSize: 11 }]}>1. Ride Summary</Text></View>
                                    <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
                                </View>

                                <View style={{ height: '200px' }}>
                                    <Svg width={"100%"} height={"245px"}>
                                        <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
                                        <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
                                        {rideHeightValues.map((value, index) => (
                                            <React.Fragment key={index}>
                                                <Rect x={(index + 1) * 40} y={164.5} fill='#34c38f' width={20} height={calculateHeight(-value, maxRideValue)} />
                                                <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxRideValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
                                                <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
                                            </React.Fragment>
                                        ))}
                                    </Svg>
                                    <View style={{ width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                                        <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Ride</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{ width: '49.5%', height: '250px', marginBottom: '15px', border: '1px solid lightgrey', padding: 20, paddingVertical: 15, borderRadius: 10 }}>
                                <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                    <View ><Text style={[fontBold, { fontSize: 11 }]}>2. Distance Summary</Text></View>
                                    <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
                                </View>

                                <View style={{ height: '200px' }}>
                                    <Svg width={"100%"} height={"245px"}>
                                        <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
                                        <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
                                        {kmHeightValues.map((value, index) => (
                                            <React.Fragment key={index}>
                                                <Rect x={(index + 1) * 40} y={164.5} fill='#556ee6' width={20} height={calculateHeight(-value, maxKmValue)} />
                                                <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxKmValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
                                                <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
                                            </React.Fragment>
                                        ))}
                                    </Svg>
                                    <View style={{ height: "20px", width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                                        <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Distance(kms)</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', marginHorizontal: 30, justifyContent: 'space-between' }}>
                            <View style={{ width: '49.5%', height: '250px', marginBottom: '15px', border: '1px solid lightgrey', padding: 20, paddingVertical: 15, borderRadius: 10 }}>
                                <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                    <View ><Text style={[fontBold, { fontSize: 11 }]}>3. Duration Summary  </Text></View>
                                    <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
                                </View>

                                <View style={{ height: '200px' }}>
                                    <Svg width={"100%"} height={"245px"}>
                                        <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
                                        <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
                                        {hourHeightValues.map((value, index) => (
                                            <React.Fragment key={index}>
                                                <Rect x={(index + 1) * 40} y={164.5} fill='#f46a6a' width={20} height={calculateHeight(-value, maxHourValue)} />
                                                <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxHourValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
                                                <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
                                            </React.Fragment>
                                        ))}
                                    </Svg>
                                    <View style={{ height: "20px", width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                                        <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Hour</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{ width: '49.5%', height: '250px', marginBottom: '15px', border: '1px solid lightgrey', padding: 20, paddingVertical: 15, borderRadius: 10 }}>
                                <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                    <View ><Text style={[fontBold, { fontSize: 11 }]}>4. Number of Rides <Text style={[fontBold, { fontSize: 9 }]}>(All drivers)</Text></Text></View>
                                </View>
                                <View style={{ height: '50px', border: '1px dashed lightgrey', width: '100%', flexDirection: 'column', marginBottom: '10px' }}>
                                    <View style={{ height: '25px', width: '100%', borderBottom: '1px dashed lightgrey', flexDirection: 'row', alignItems: 'center' }}>
                                        <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Days</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sunday</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Monday</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Tuesday</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Wednesday</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Thursday</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Friday</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Saturday</Text></View>
                                    </View>
                                    <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                        <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>No of Rides</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day0) ? numberofRidessPdfData.day0 : "-"}</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day1) ? numberofRidessPdfData.day1 : "-"}</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day2) ? numberofRidessPdfData.day2 : "-"}</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day3) ? numberofRidessPdfData.day3 : "-"}</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day4) ? numberofRidessPdfData.day4 : "-"}</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px dashed lightgrey', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day5) ? numberofRidessPdfData.day5 : "-"}</Text></View>
                                        <View style={[{ fontSize: 10, width: '66.75px', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{(numberofRidessPdfData && numberofRidessPdfData.day6) ? numberofRidessPdfData.day6 : "-"}</Text></View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{ marginHorizontal: 30, }}>
                            <View style={{ border: '1px solid lightgrey', padding: 20, paddingVertical: 15, borderRadius: 10 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                    <View ><Text style={[fontBold, { fontSize: 11 }]}>5. Per day Usage <Text style={[fontBold, { fontSize: 9 }]}>(All drivers)</Text> </Text></View>
                                </View>
                                <View style={{ border: '1px dashed lightgrey', width: '100%', flexDirection: 'column', marginBottom: '10px', borderBottom: 'none', borderRight: 'none' }}>
                                    <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center', borderBottom: '1px dashed lightgrey' }}>
                                        <View style={[fontBold, { fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Username</Text></View>
                                        <View style={[fontBold, { fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sunday</Text></View>
                                        <View style={[fontBold, { fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Monday</Text></View>
                                        <View style={[fontBold, { fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Tuesday</Text></View>
                                        <View style={[fontBold, { fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Wednesday</Text></View>
                                        <View style={[fontBold, { fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Thursday</Text></View>
                                        <View style={[fontBold, { fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}> <Text>Friday</Text></View>
                                        <View style={[fontBold, { fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', backgroundColor: globalPDF.GREY_COLOR, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Saturday</Text></View>
                                    </View>
                                    {activeDriverData?.map((userData, index) => (
                                        <View key={index} style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center', borderBottom: '1px dashed lightgrey' }}>
                                            <View style={[{ fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', justifyContent: 'center', alignItems: 'center' }]}>
                                                <Text>{userData.name || "-"}</Text>
                                            </View>
                                            {userData.data.map((value, i) => (
                                                <View key={i} style={[{ fontSize: 10, width: '12.8%', height: '25px', borderRight: '1px dashed lightgrey', justifyContent: 'center', alignItems: 'center' }]}>
                                                    <Text>{value || "-"}</Text>
                                                </View>
                                            ))}
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </View>

                        <PdfFooter pageNumber={1} totalPages={1} pathname={pathname} />
                    </Page>


                </Document>
            </PDFViewer>
        </React.Fragment>
    );
};

export default ColouredActivityUsageSummaryPdf;

