import React, { useState, useEffect } from "react"
import Leaflet from "leaflet"
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import CurrentLocationController from "../../../controller/VehicleCurrentLocationController/CurrentLocationController";
import ValueFormat from "../../../util/ValueFormat";
import { displayText } from "../../../constant/MessageConstant";

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
})

const  MapMarkerCustomIcons = ({getDataPdf}) => {


  const [state, setState] = useState({
    lat: 20.00000000,
    lng: 77.00000000,
    zoom: 4,
  })
 
  const [position, setPosition] = useState([state.lat, state.lng]);
  const [lastRideName, setLastRideName] = useState("")
  const [drivingScore, setDrivingScore] = useState(0);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("")
  const [dataDisplay, setDataDisplay] = useState(false);


  const getLocation = async () => {
    setDataDisplay(null)
    var result = await CurrentLocationController.currentLocation();
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if(status == "SUCCESS"){
    var resultJSONData= resultJSON.data;
    var latLongResult = [];
    // console.log("getLocation: " + JSON.stringify(resultJSONData));

    resultJSONData.map((value)=>{
      var rideList = value.rideList;
      rideList.map((data, i)=>{
        var deviceDataAddressField = ValueFormat.parseAsObject(data.deviceDataAddressField)
        var latitude = data.latitude;
        var longitude = data.longitude;
        var deviceName = ValueFormat.parseAsString(data.deviceName);
        var drivingScore = Math.round(ValueFormat.parseAsFloat(data.drivingScore,2));
        var location = ValueFormat.parseAsString(deviceDataAddressField.location);
        var city = ValueFormat.parseAsString(deviceDataAddressField.city);
        var state = ValueFormat.parseAsString(deviceDataAddressField.state);
        var country = ValueFormat.parseAsString(deviceDataAddressField.country);

        var LastLocationPdf ={
        deviceName:deviceName,
        drivingScore:drivingScore,
        latitude:latitude,
        longitude:longitude,
        location:location.trim() + ", " + city.trim() + ", " + state.trim() + ", " + country.trim()
        }

        try {
          setLocation(location.trim() + ", " + city.trim() + ", " + state.trim() + ", " + country.trim())
        } catch (Exception) {
          setLocation();
        }

        if(i==0) {
          latLongResult.push(latitude, longitude)
     
          setState(updateData => {
            return {...updateData, lat:latitude, lng:longitude }
          })

          setPosition(latLongResult)
          setLastRideName(deviceName);
          setDrivingScore(drivingScore);
          setLatitude(latitude);
          setLongitude(longitude);
          getDataPdf(LastLocationPdf);
        }
      })
    })
  }else{
    const code = resultJSON.error.code;
    if(code == "E1206"){
      setDataDisplay("failed")
    }
  }
  }

  useEffect(()=>{
    getLocation()
  },[])

    return (
      <div style={{minHeight:300}}>
         {dataDisplay == "failed" ? (
          <p className="data_not_available">{displayText.ER_1206}</p>
        ): dataDisplay == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
        ) : "" }
      <MapContainer
        center={position}
        zoom={ state.zoom}
        style={{ height: "340px",zIndex:0 }}
      >
        <TileLayer
          attribution=''
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={state}>
          <Popup>
          <div>
            
            <h6>Ride Name: {lastRideName}</h6>
            <h6>Lat: {latitude} Long: {longitude} </h6>
            <h6>Location: {location}</h6>
            

          </div>
          </Popup>
        </Marker>
      </MapContainer>
     
          <div className="px-2 py-2 pt-0" style={{overflowY:"scroll",height:"110px"}}>
                           
          <p className="mt-3 mb-1"  style={{ fontSize: "13px" }}>Last Ride Name: {lastRideName} <span className="float-end">{drivingScore}%</span></p>
          <div className="progress mt-2" style={{ height: "6px" }}>
              <div className="progress-bar progress-bar-striped bg-primary" role="progressbar"
                  style={{ width: drivingScore + "%" }} aria-valuenow="55" aria-valuemin="0" aria-valuemax="55">
              </div>
          </div>
          <p className="mt-2 mb-1" style={{ fontSize: "13px" }}>Geo-Location: <span className="" style={{ fontSize: "13px" }}>Lat: {latitude} Long: {longitude} </span></p>

          <p className="mt-2 mb-1" style={{ fontSize: "13px" }}>Location: <span className="">{location}</span></p>
       
      </div>
      </div>
    )
  };

  export default MapMarkerCustomIcons;

