import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import {
  SC_COMPANY_ID,
  SC_USER_ID,
  SC_DIVISION_ID,
  SC_USER_LOGIN_TYPE,
  SC_GROUP_ID,
} from "../../constant/StorageConstant.js";
import UserSessionController from "../UserSession/UserSessionController.js";

let MyRidesController = {
  async myRidesList(
    indexStart,
    filterDays,
    mode,
    category,
    subFilterText,
    pageSize,
    startDateTime,
    endDateTime
  ) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userType =
      UserSessionController.getLocalSessionValue(SC_USER_LOGIN_TYPE);
    var subFilterType = "";

    if (userType != "SUPER_ADMIN") {
      subFilterType = "SUCCESS";
    }

    var queryFields = {
      deviceDataCategory: "END_DATA",
      // subFilterType: subFilterType,
      scoreValidationStatus: subFilterType,
      filterType: filterDays,
      subFilterType: "WILD_CARD_SEARCH",
      subFilterText: subFilterText,
      deviceMode: mode
    };
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        userId: userId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: indexStart,
        limit: pageSize,
        category: category,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        queryType: "AD_SINGLE_USER_RIDE_LIST",
        queryFields: JSON.stringify(queryFields),
      };
      // console.log("reqPayload: " + JSON.stringify(reqPayload));

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
    }
  },
  async newMyRidesList(
    indexStart,
    filterDays,
    mode,
    category,
    subFilterText,
    pageSize,
    startDateTime,
    endDateTime
  ) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var userId = UserSessionController.getLocalSessionValue(SC_USER_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userType = UserSessionController.getLocalSessionValue(SC_USER_LOGIN_TYPE);
    var subFilterType = "";

    if (userType != "SUPER_ADMIN") {
      subFilterType = "SUCCESS";
    }
    var dashboardFields = {
      filterType: filterDays,
      scoreValidationStatus: subFilterType,
      subFilterType: "WILD_CARD_SEARCH",
      subFilterText: subFilterText,
      deviceMode: mode,
      travelType: category,
    };
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        userId: userId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "ASC",
        offset: indexStart,
        limit: 25,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        dashboardType: "UD_SINGLE_USER_CALCULATED_RIDE_LIST",
        dashboardFields: JSON.stringify(dashboardFields),
      };
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
    }
  },

  async myRidesMobileList(
    username,
    indexStart,
    filterDays,
    mode,
    category,
    subFilterText,
    pageSize
  ) {
    var subFilterType = "SUCCESS";

    var searchFields = {
      scoreValidationStatus: subFilterType,
      subFilterType: "WILD_CARD_SEARCH",
      subFilterText: subFilterText,
      deviceMode: mode,
    };

    // if (username) {
    var reqPayload = {
      username: username,
      type: "SPEEDO_METER_DEVICE",
      sortBy: "createdAt",
      sortOrder: "DESC",
      offset: indexStart,
      limit: pageSize,
      category: category,
      searchType: "UD_OPEN_SEARCH_RIDE_LIST",
      searchFields: JSON.stringify(searchFields),
    };

    // console.log("reqPayload: " + JSON.stringify(reqPayload));

    return await ApiService.callGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.SEARCH,
      0,
      reqPayload
    );
    // } else {
    //   return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
    // }
  },

  async appBootMyRidesList(userId) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var userType =
      UserSessionController.getLocalSessionValue(SC_USER_LOGIN_TYPE);

    var queryFields = {
      deviceDataCategory: "END_DATA",
      //subFilterType: subFilterType,
      scoreValidationStatus: "SUCCESS",
    };
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        userId: userId,
        divisionId: divisionId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: 0,
        limit: 1000,

        queryType: "AD_SINGLE_USER_RIDE_LIST",
        queryFields: JSON.stringify(queryFields),
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async myRidesListExport(userId) {
    // var yValue = UtilDateTime.formatYearAsFullString();
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    // var startDateTime = yValue + "-01-01 00:00:00";
    // var endDateTime = yValue + "-12-31 23:59:59";
    var userType =
      UserSessionController.getLocalSessionValue(SC_USER_LOGIN_TYPE);
    var subFilterType = "SUCCESS";
    var queryFields = {
      deviceDataCategory: "END_DATA",
      //subFilterType: subFilterType,
      scoreValidationStatus: "SUCCESS",
      filterType: "CURRENT_YEAR",
    };

    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
        type: "SPEEDO_METER_DEVICE",
        sortBy: "createdAt",
        sortOrder: "DESC",
        offset: "0",
        limit: "1000",
        // startDateTime: startDateTime,
        // endDateTime: endDateTime,
        queryType: "AD_SINGLE_USER_RIDE_LIST",
        queryFields: JSON.stringify(queryFields),
      };
      // console.log("myreqPayload : " + JSON.stringify(reqPayload));
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },

  async MySubscription() {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);

    if (companyId.length > 0 ) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        groupId:groupId,
        type: "MOBILE_DEVICE_LICENSE_SUBSCRIPTION",
        reportType:"SAD_SINGLE_DIVISION_SUBSCRIPTION_LIST",
      };

      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.REPORT,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
    }
  },
  
  async PostSubscription(postOrderDetails,sId,name,isoCount,androidCount) {
    var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    if (companyId.length > 0 ) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        groupId:groupId,
        subscriptionId: postOrderDetails.id,
        name:postOrderDetails.orderName,
        iosLicenseCount:postOrderDetails.iosCount,
        androidLicenseCount:postOrderDetails.androidCount,
        androidSdkLicenseCount:postOrderDetails.androidSDKCount,
        iosSdkLicenseCount:postOrderDetails.iosSDKCount,
        status: "REGISTERED",  
        type: "MOBILE_DEVICE_LICENSE_ORDER"
      };
      // console.log("reqPayload :" + JSON.stringify(reqPayload))
      return await ApiService.callPOST(
        apiConfig.API_VERSION_1,
        apiEndPoint.ORDER,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED","code":"412", "error" : "Request Process Failed", "data": []}';
    }
  },
};


export default MyRidesController;
