
// const RideCountChart = ({ rideRiskData }) => {
//   const series = [
//     {
//       name: "Ride Count",
//       data: rideRiskData.rideSeries,
//     },
//   ];
//   const options = {
//     chart: {
//       toolbar: {
//         show: false,
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: true,
//         columnWidth: "45%",
//         barHeight: "20%",
//         endingShape: "rounded",
//         dataLabels: {
//           position: "top",
//         },
//       },

//     },

//     dataLabels: {
//       enabled: true,
//       style: {
//         fontSize: "10px",
//         // fontWeight: 'bold',
//         colors: ["#006877"],
//       },

//       offsetX: 4,
//       offsetY: -12,
//     },
//     stroke: {
//       show: true,
//       width: 1,
//       colors: ["transparent"],
//     },

//     colors: ["#006877"],
//     xaxis: {
//       title: {
//         text: "Ride Count",
//       },
//       categories: rideRiskData.rideRiskXaxis,
//     },
//     yaxis: {
//       title: {
//         text: "User",
//       },
//     },
//     grid: {
//       borderColor: "#f1f1f1",
//     },
//     fill: {
//       opacity: 1,
//     },
//     tooltip: {
//       y: {
//         formatter: function (val) {
//           return val;
//         },
//       },
//     },
//   };

//   return (
//     <>
//       <ReactApexChart
//         options={options}
//         series={series}
//         type="bar"
//         height={180}
//       />
//     </>
//   );
// };

// export default RideCountChart;

import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

const RideCountChart = ({ rideRiskData }) => {

  const [modifiedName, setModifiedName] = useState([]);

  useEffect(() => {
    if (
      rideRiskData.rideRiskXaxis.length > 0 &&
      rideRiskData.usernameData.length > 0
    ) {
      const modifiedNameTemp = rideRiskData.rideRiskXaxis.map((item, index) => (
        `${item} (${rideRiskData.usernameData[index]})`
      ));      
      setModifiedName(modifiedNameTemp);
    } else {
      setModifiedName([]);
    }
  }, [rideRiskData]); 

  const option = {

    tooltip: {},
    legend: {
      data: ['Ride Count'],
    },
    grid: { bottom: 40, left: 120, right: 40 },
    xAxis: {
      name: "Ride Count",
      type: 'value',
      nameTextStyle: { 
        fontFamily: "sans-serif",
        fontWeight:200,
      },
      nameLocation: "middle",
      nameGap: 30,
      splitLine: {
        show: false
      },
      axisLabel: {
        fontSize: 10,
      }
    },
    yAxis: {
      name: "User",
      type: 'category',
      label: {       
        fontFamily: "sans-serif",
        fontWeight:200,
      },
      nameLocation: "middle",
      nameGap: 110,
      inverse: true,
      data: modifiedName,
      axisLabel: {
        margin: 5,
        fontSize: 10,
        fontWeight: "normal",
        fontColor: "#f1f1f1",
        formatter: function(value) {
          const maxChars = 20;
          if (value && value.includes(" (")) {
            const openParenIndex = value.indexOf(" (");
            const valueAfterParen = value.substring(openParenIndex + 1); // Get the part of the string after "("
            return value.substring(0, openParenIndex + 1) + '\n' + valueAfterParen;
          }
          else if (value.length > maxChars) {
            return value.substring(0, maxChars) + '\n' + value.substring(maxChars);
          }
          return value;
        }
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: 'Ride Count',
        type: 'bar',
        itemStyle: {
          color: '#006877'
        },
        label: {
          show: true,
          position: 'right',
          textStyle: {
            fontSize: 9,
            fontWeight: 'bold',
          },
        },
        data: rideRiskData.rideSeries,
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Max' },
        //     { type: 'min', name: 'Min' }
        //   ]
        // },
       
        barWidth: 10,
      },
    ]
  };



  return (
    <ReactEcharts option={option} type="bar" style={{ height: rideRiskData.rideRiskXaxis && rideRiskData.rideRiskXaxis.length <= 5 ? 300 : Math.max(400, 50 * rideRiskData.rideRiskXaxis.length) + "px" }}  />
  );
};

export default RideCountChart;

