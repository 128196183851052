import React, { lazy, Suspense } from 'react';
import GoogleMapCrash from './googleMapCrashView';
import HereMapTest from './testHereMap';

// const GoogleMapRisk = lazy(() => import('./GoogleMapRisk'));
export default function GoogleMapCrashView() {
  return (
    <div>
         <Suspense fallback={<div>Loading...</div>}>
      {/* <GoogleMapCrash/> */}
      <HereMapTest/>
      </Suspense>
    </div>
  )
}
