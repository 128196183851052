import PropTypes from "prop-types";
import React, { useState,useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper,Polyline } from "google-maps-react";
import { connect } from "react-redux";
import { LightData } from "./LightData";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const LoadingContainer = () => <div>Loading...</div>;

const MapsGoogle = (props) => {

  //meta title
  document.title = "Google | Motiv AI";

  const selectedPlace = {name:"sava"};

  function onMarkerClick() {
    alert("You clicked in this marker");
  }
  const [progress, setProgress] = useState([]);
  const path = [
    // Your path data  { lat: 18.566516, lng: -68.435996 },
    { lat: 18.5644, lng: -68.423036 },
    { lat: 18.563586, lng: -68.418744 },
    { lat: 18.562339, lng: -68.410725 },
    { lat: 18.560927, lng: -68.402459 },
    { lat: 18.559605, lng: -68.394354 },
    { lat: 18.559028, lng: -68.391003 },
    { lat: 18.558841, lng: -68.390594 },
    { lat: 18.558795, lng: -68.390387 },
    { lat: 18.558767, lng: -68.390312 },
    { lat: 18.558744, lng: -68.390256 },
    { lat: 18.558726, lng: -68.390202 },
    { lat: 18.55867, lng: -68.390124 },
    { lat: 18.558663, lng: -68.390111 },
    { lat: 18.558602, lng: -68.389995 },
    { lat: 18.5585, lng: -68.389867 },
    { lat: 18.558462, lng: -68.389837 },
    { lat: 18.558396, lng: -68.389781 },
    { lat: 18.55828, lng: -68.389641 },
    { lat: 18.558234, lng: -68.389557 },
    { lat: 18.558143, lng: -68.389469 },
    { lat: 18.558089, lng: -68.389362 },
    { lat: 18.558062, lng: -68.389265 },
    { lat: 18.558011, lng: -68.389069 },
    { lat: 18.557985, lng: -68.388965 },
    { lat: 18.557988, lng: -68.38879 },
    { lat: 18.558032, lng: -68.388603 },
    { lat: 18.55806, lng: -68.388525 },
    { lat: 18.558113, lng: -68.388425 },
    { lat: 18.558192, lng: -68.388297 },
    { lat: 18.558301, lng: -68.388181 },
    { lat: 18.558497, lng: -68.388045 },
    { lat: 18.558571, lng: -68.388002 },
    { lat: 18.558701, lng: -68.387927 },
    { lat: 18.558863, lng: -68.387895 },
    { lat: 18.559046, lng: -68.387887 },
    { lat: 18.559308, lng: -68.387922 },
    { lat: 18.559677, lng: -68.388185 },
    { lat: 18.559824, lng: -68.388314 },
    { lat: 18.559929, lng: -68.388397 },
    { lat: 18.560018, lng: -68.388512 },
    { lat: 18.560203, lng: -68.388607 },
    { lat: 18.560472, lng: -68.388615 },
    { lat: 18.560655, lng: -68.388613 },
    { lat: 18.560957, lng: -68.388572 },
    { lat: 18.561206, lng: -68.388521 }
  ];
  const velocity = 100;
  const initialDate = new Date();

  const getDistance = () => {
    const differentInTime = (new Date() - initialDate) / 1000;
    return differentInTime * velocity;
  };

  const moveObject = () => {
    const distance = getDistance();
    if (!distance) {
      return;
    }

    let newProgress = path.filter((coordinates) => coordinates.distance < distance);

    const nextLine = path.find((coordinates) => coordinates.distance > distance);
    if (!nextLine) {
      setProgress(newProgress);
      return;
    }

    const lastLine = newProgress[newProgress.length - 1];

    const lastLineLatLng = new window.google.maps.LatLng(lastLine.lat, lastLine.lng);
    const nextLineLatLng = new window.google.maps.LatLng(nextLine.lat, nextLine.lng);

    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = new window.google.maps.geometry.spherical.computeHeading(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    newProgress = newProgress.concat(position);
    setProgress(newProgress);
  };

  useEffect(() => {
    const interval = window.setInterval(moveObject, 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const updatedPath = path.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 };
      }

      const { lat: lat1, lng: lng1 } = coordinates;
      const latLong1 = new window.google.maps.LatLng(lat1, lng1);

      const { lat: lat2, lng: lng2 } = array[0];
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      const distance = new window.google.maps.geometry.spherical.computeDistanceBetween(
        latLong1,
        latLong2
      );

      return { ...coordinates, distance };
    });

    setProgress(updatedPath);
  }, []);


  return (
    <React.Fragment>
      {/* <div className="page-content">
        <div className="container-fluid"> */}
          {/* <Breadcrumbs title="Maps" breadcrumbItem="Google" /> */}

         
            <Col lg={12}>
              {/* <Card>
                <CardHeader>
                  <h4 className="card-title">Markers</h4>
                  <p className="card-title-desc">Example of google maps.</p>
                </CardHeader>
                <CardBody> */}
                  <div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      style={{ width: "100vw", height: "100vh" }}
                      zoom={14}
                    >
                       {progress && (
          <>
            <Polyline
              path={progress}
              options={{ strokeColor: "#FF0000 " }}
            />
            <Marker
              position={progress[progress.length - 1]}
            />
          </>
        )}
                      <Marker
                        title={"The marker`s title will appear as a tooltip."}
                        name={"SOMA"}
                        position={{ lat: 37.778519, lng: -122.40564 }}
                      />
                      <Marker name={"Dolores park"} />
                      <InfoWindow>
                        <div>
                          <h1>{selectedPlace.name}</h1>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
                {/* </CardBody>
              </Card> */}
            </Col>

            {/* <Col lg={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Overlays</h4>
                  <p className="card-title-desc">Example of google maps.</p>
                </CardHeader>
                <CardBody>
                  <div
                    id="gmaps-overlay"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      zoom={14}
                      style={{ width: "100%", height: "100%" }}
                      initialCenter={{
                        lat: 40.854885,
                        lng: -88.081807,
                      }}
                    >
                      <Marker
                        onClick={(a, b, c) => {
                          onMarkerClick(a, b, c);
                        }}
                      />
                      <InfoWindow>
                        <div>
                          <h1>{selectedPlace.name}</h1>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          

          {/* <Row>
            <Col lg={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Basic</h4>
                  <p className="card-title-desc">Example of google maps.</p>
                </CardHeader>
                <CardBody>
                  <div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      zoom={14}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <InfoWindow>
                        <div>
                          <h1>{selectedPlace.name}</h1>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Ultra Light</h4>
                  <p className="card-title-desc">Example of google maps.</p>
                </CardHeader>
                <CardBody>
                  <div
                    id="gmaps-overlay"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      zoom={14}
                      styles={LightData.Data}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Marker
                        onClick={(a, b, c) => {
                          onMarkerClick(a, b, c);
                        }}
                      />
                      <InfoWindow>
                        <div>
                          <h1>{selectedPlace.name}</h1>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        {/* </div>
      </div> */}
    </React.Fragment>
  );
};

MapsGoogle.propTypes = {
  google: PropTypes.object,
};

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyAIwmkA2aLdQ1ohWopccQL21l7Muoz1Mw8",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(MapsGoogle)
);
