import React, { Component, useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../../constant/MessageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup";

export default function DriverAnalysis({ activeDriverPie,setDailyData,  setNOREndDateTime, setNORStartDateTime, setNORFilter }) {
  const [filter, setFilter] = useState("CURRENT_WEEK");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [display, setDisplay] = useState(false);



  const getOption = () => {
    return {
      track: {
        show: true,
        fill: "black",
        strokeWidth: "98%",
        opacity: 0.9,
        margin: 5,
        dropShadow: {
          enabled: true,
          blur: 0.09,
          opacity: 0.3,
        },
      },
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        top: 50,
        data: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        textStyle: {
          color: ["#858d98"],
        },
      },
      color: [
        "#fd625e",
        "#2ab57d",
        "#4ba6ef",
        "#ffbf53",
        "#5156be",
        "#3CAFC6",
        "#550885 ",
      ],
      series: [
        {
          name: "Active Users",
          type: "pie",
          radius: "55%",
          center: ["67%", "45%"],
          data: activeDriverPie,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label:{
            show: true,
            position:'outside',
            fontSize: 12,
            formatter: "{b}: {c} ({d}%)"
          },
        },
      ],
    };
  };

  const getValue = () => {
    setDisplay(null);
    var number = null;

    for (let i = 0; i < activeDriverPie.length; i++) {
      setDisplay(null);

      var dataValue = activeDriverPie[i].value;
      number += dataValue;
    }

    setDisplay(number > 0 ? true : number ==0 ? false : null);
  }; 

  useEffect(()=>{
    setNOREndDateTime(endDateTime);
    setNORStartDateTime(startDateTime);
    setNORFilter(filter)
  },[startDateTime,endDateTime,filter])

  useEffect(() => {
    getValue();
  }, [activeDriverPie]);

  return (
    <div>
      <React.Fragment>
        <Card style={{height: 450}}>
          <CardHeader className="cardHeader">
            <div className="d-flex flex-wrap align-items-center m-0">
              <h5 className="sub_heading me-1 mt-1">Number of Rides <span style={{fontSize: "12px",fontWeight: 400}}>(All Drivers)</span></h5>
              <div className="ms-auto">
                <div>
                  <button
                    type="button"
                    data-tooltip-id="wee"
                    className={
                      filter == "CURRENT_WEEK"
                        ? "btn btn-primary btn-sm"
                        : "btn btn-soft-primary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_WEEK")}
                  >
                    CW
                    <ReactTooltip
                      id="wee"
                      place="top"
                      content="Current Week"
                      style={{ backgroundColor: "black" }}
                    />
                  </button>{" "}
                  {/* <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION"
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{setIsOpen(true); }}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "} */}
                </div>
              </div>
            </div>
          </CardHeader>

          {setDailyData == true ? (
            <CardBody>
              <ReactEcharts
                style={{ height: "340px", width: "100%" }}
                option={getOption()}
              />
            </CardBody>
          ) : setDailyData == "failed" ? (
            <CardBody>
              <p className="data_not_available">
                {displayText.ER_1206}
              </p>
              <ReactEcharts
                style={{ height: "340px", width: "100%" }}
                option={getOption()}
              />
            </CardBody>
          ) : setDailyData == false ? (
            <CardBody>
              <p className="data_not_available">
                {displayText.DATA_NOT_AVAILABLE}
              </p>
              <ReactEcharts
                style={{ height: "340px", width: "100%" }}
                option={getOption()}
              />
            </CardBody>
          ) : (
            <CardBody>
              <p className="fetch_data">{displayText.FETCHING_DATA}</p>
              <ReactEcharts
                style={{ height: "340px", width: "100%" }}
                option={getOption()}
              />
            </CardBody>
          )}
        </Card>
        <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen}  setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter}/>
      </React.Fragment>
    </div>
  );
}
