import React from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

const CrashReport = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={3} lg={4}>
            <Card className="border-0">
              <CardBody className="px-0 pt-0 rounded-5" style={{ boxShadow: '0 0 10px 0 rgba(100, 100, 100, 0.26)' }}>
                <CardBody className="bg-primary rounded-5" style={{ height: '100px' }}>
                  <div className="d-flex justify-content-between align-items-center p-0 m-0 h-50">
                    <p className="text-white m-0">Ride No: 10000</p>
                    <p className="text-white m-0">Max Speed: 135 Km/hr</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center p-0 m-0 h-50">
                    <p className="text-white m-0">Duration: 1.5 Mins</p>
                    <p className="text-white m-0">Total Kms: 2.1 Kms</p>
                  </div>
                </CardBody>
                <br />
                <Row className="mb-2">
                  <Col className="d-flex ms-4 align-items-center">
                    <div className="me-3">
                      <img src="https://i.pinimg.com/564x/45/e9/d9/45e9d9cc55e3fd8d4d10fb757779853c.jpg" alt="" style={{ height: '25px', width: '25px' }} />
                    </div>
                    <div style={{ width: "80%" }}>
                      <h6 className="p-0 m-0">Impact and Time Location</h6>
                      <p className="p-0 m-0" style={{ whiteSpace: 'break-spaces' }}>Thu 29 February 2024, 12:12 PM</p>
                    </div>
                  </Col>
                </Row>

                <Row className="pb-2">
                  <Col className="d-flex ms-4 align-items-center">
                    <div className="me-3">
                      <img src="https://i.pinimg.com/564x/45/e9/d9/45e9d9cc55e3fd8d4d10fb757779853c.jpg" alt="" style={{ height: '25px', width: '25px' }} />
                    </div>
                    <div style={{ width: "80%" }}>
                      <h6 className="p-0 m-0" style={{ whiteSpace: 'break-spaces' }}>Vasantham Naga, SriRamakrishna Engineering College, Avinashi Road, Peelamedu, Coimbatore, Tamil Nadu, 641108</h6>
                    </div>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CrashReport;
