// import JwtTokenDecode from "constant/JwtTokenDecode";
import { SC_EXPIRATION_TIME, SC_LOCK_STATUS, SC_PROFILE_OPEN, SC_USER_ID, SC_USER_LOGIN_TYPE } from "./constant/StorageConstant";
// import Cookies from "js-cookie";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import ToastService from "./components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { displayText } from "./constant/MessageConstant";
const Authmiddleware = (props) => {

  const location = useLocation();
  const initialDuration = 24 * 3600; // 24 Hours in seconds
  const [remainingTime, setRemainingTime] = useState(initialDuration);
  const [timerActive, setTimerActive] = useState(false);

  
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the 'paramName' query parameter
  const paramNameValue = queryParams.get('username');
  
  useEffect(()=>{
// Extract and parse the query parameters
// alert(paramNameValue)
  },[])
  useEffect(() => {
    let timer;

    if (timerActive && remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 950);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timerActive, remainingTime]);

  useEffect(() => {
  }, [remainingTime])

  const startTimer = () => {
    setRemainingTime(initialDuration);
    setTimerActive(true);
  };

  const resetTimer = () => {
    setRemainingTime(initialDuration);
    setTimerActive(false);

  };

  const handleNavigation = (path) => {
    if (path == "/login") {
      resetTimer();
    } else {
      startTimer();
    }
  };

  useEffect(() => {
    handleNavigation(location.pathname);
    if (location.pathname != "/contacts-profile" && localStorage.getItem(SC_PROFILE_OPEN)) localStorage.setItem(SC_PROFILE_OPEN, false)
  }, [location.pathname]);


  const expirationTime = localStorage.getItem(SC_EXPIRATION_TIME)
  const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE)
  // const decodedToken = JwtTokenDecode();
  if(paramNameValue === null || paramNameValue === undefined){
  if (localStorage.getItem(SC_LOCK_STATUS) == "true") {
    return <Navigate to={{ pathname: "/page-lock-screen" }} />;
  }
  else if (
    (expirationTime < new Date() || props.authentication != loginType ) && props.authentication != "login" && (props.authentication != "USER_SUPER_ADMIN" || loginType == undefined)
  ) {
    // console.log("props.authentication :", props.authentication + "loginType : " + loginType)

    return <Navigate to={{ pathname: "/login" }} />;
  } else if (remainingTime === 0) {
    ToastService.errormsg(displayText.SESSION_TIMEOUT), localStorage.removeItem("_grecaptcha"), setTimeout(() => { window.location.href = "/login" }, 3000)

  }
}

  return <React.Fragment>{props.children}</React.Fragment>;


};
Authmiddleware.propTypes = {
  authentication: PropTypes.string,
  children: PropTypes.object,
  location: PropTypes.string,
};
export default Authmiddleware;