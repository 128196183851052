import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
} from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PiDownloadSimple } from "react-icons/pi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ProgressBar from "react-bootstrap/ProgressBar";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import ValueFormate, { ValueFormat } from "../../../util/ValueFormat";
import "../OverallSummary/overallSummaryStyle.css";
import Loader from "../../../components/Common/loader";
import {
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_ID,
  SC_USER_USERNAME,
} from "../../../constant/StorageConstant.js";
import { Link } from "react-router-dom";
import { displayText } from "../../../constant/MessageConstant.js";
import ToastService from "../../../components/Common/toastService.js";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import ActiveUserdataList from "../ActiveUserNew/activeusersdata.js";

import i1 from "../../../assets/images/adminDashboard/ad-dr-name.svg";
import i2 from "../../../assets/images/adminDashboard/ad-dr-uname.svg";
import i3 from "../../../assets/images/adminDashboard/ad-dr-rank.svg";
import i4 from "../../../assets/images/adminDashboard/ad-dr-rides.svg";
import i5 from "../../../assets/images/adminDashboard/ad-dr-dangerous.svg";
import i6 from "../../../assets/images/adminDashboard/ad-dr-quiteDangerous.svg";
import i7 from "../../../assets/images/adminDashboard/ad-dr-riskAlerts.svg";
import i8 from "../../../assets/images/adminDashboard/ad-dr-overspeed.svg";
import i9 from "../../../assets/images/adminDashboard/ad-dr-mobileCall.svg";
import i10 from "../../../assets/images/adminDashboard/ad-dr-mobileScreen.svg";
import i11 from "../../../assets/images/adminDashboard/ad-dr-acceleration.svg";
import i12 from "../../../assets/images/adminDashboard/ad-dr-Braking.svg";
import i13 from "../../../assets/images/adminDashboard/ad-dr-cornering.svg";

export default function OverallRiskSummary({ filter, receiveRiskSummaryData }) {
  const [data, setData] = useState(ActiveUserdataList);
  // const [filter, setFilter] = useState("CURRENT_DAY");
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setDataLength] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  const exportPDF = async () => {
    setShowLoader(true);

    var result = await AdminDashboardController.userSummaryListExport(
      filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime
    );
    setShowLoader(true);
    var UserSummaryListData = [];
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      // console.log("getOverAllSummary: ", resultJSONData);
      setDataLength(resultJSONData.length);
      let index = 0;
      resultJSONData.forEach((value) => {
        index++;
        const {
          firstName = value.firstName && value.firstName.length > 0
            ? ValueFormat.capitalizeFirstLetter(value.firstName)
            : "N/A",
          username = ValueFormat.parseAsString(value.username),
          rideBox5,
          rideBox6,
          totalRide,
          rideBox5Percent,
          rideBox6Percent,
          actionRideCount,
          actionAlertCount,
          overallDrivingScore,
          userId,
          totalSevereAccelerationLowCount,
          totalSevereAccelerationMediumCount,
          totalSevereAccelerationHighCount,
          totalSevereCorneringLowCount,
          totalSevereCorneringMediumCount,
          totalSevereCorneringHighCount,
          totalSevereBrakingLowCount,
          totalSevereBrakingMediumCount,
          totalSevereBrakingHighCount,
          totalOverSpeedCount,
          totalMobileUseInAcceptedCount,
          totalMobileUseOutAcceptedCount,
          totalMobileScreenScreenOnCount,
          totalHighRiskCount,
          totalMediumRiskCount,
        } = value;

        const rank = index;
        const quietDangerousRideCount = ValueFormat.parseAsInt(rideBox5);
        const dangerousRideCount = ValueFormat.parseAsInt(rideBox6);
        const overallDrivingScoreData =
          ValueFormat.parseAsInt(overallDrivingScore);
        const redRiskCount = ValueFormat.parseAsInt(totalHighRiskCount);
        const orangeRiskCount = ValueFormat.parseAsInt(totalMediumRiskCount);

        const severeAccLow = parseFloat(
          ValueFormat.parseAsFloat(totalSevereAccelerationLowCount)
        );
        const severeAccMed = parseFloat(
          ValueFormat.parseAsFloat(totalSevereAccelerationMediumCount)
        );
        const severeAccHigh = parseFloat(
          ValueFormat.parseAsFloat(totalSevereAccelerationHighCount)
        );
        const accelerationCount = severeAccHigh + severeAccMed + severeAccLow;

        const severeCorneringLow = parseFloat(
          ValueFormat.parseAsFloat(totalSevereCorneringLowCount)
        );
        const severeCorneringMed = parseFloat(
          ValueFormat.parseAsFloat(totalSevereCorneringMediumCount)
        );
        const severeCorneringHigh = parseFloat(
          ValueFormat.parseAsFloat(totalSevereCorneringHighCount)
        );
        const corneringCount =
          severeCorneringHigh + severeCorneringMed + severeCorneringLow;

        const severeBrakingLow = parseFloat(
          ValueFormat.parseAsFloat(totalSevereBrakingLowCount)
        );
        const severeBrakingMed = parseFloat(
          ValueFormat.parseAsFloat(totalSevereBrakingMediumCount)
        );
        const severeBrakingHigh = parseFloat(
          ValueFormat.parseAsFloat(totalSevereBrakingHighCount)
        );
        const brakingCount =
          severeBrakingHigh + severeBrakingMed + severeBrakingLow;

        const overSpeedCount = ValueFormat.parseAsFloat(totalOverSpeedCount);

        const mobileScreenCount = ValueFormat.parseAsFloat(
          totalMobileScreenScreenOnCount
        );

        const mobileUseIn = parseFloat(
          ValueFormat.parseAsFloat(totalMobileUseInAcceptedCount)
        );
        const mobileUseOut = parseFloat(
          ValueFormat.parseAsFloat(totalMobileUseOutAcceptedCount)
        );

        const mobileUseCount = mobileUseIn + mobileUseOut;

        const quietDangerousRidePercentage =
          ValueFormat.parseAsFloat(rideBox5Percent);
        const dangerousRidePercentage =
          ValueFormat.parseAsFloat(rideBox6Percent);
        const riskAlertCount = ValueFormat.parseAsInt(actionAlertCount);
        const rideCountAction = ValueFormat.parseAsInt(actionRideCount);

        const resultTopList = {
          userId: userId,
          rank: rank,
          firstName:
            firstName.length > 0
              ? ValueFormat.capitalizeFirstLetter(firstName)
              : "N/A",
          username: username,
          quietDangerousRideCount: quietDangerousRideCount,
          dangerousRideCount: dangerousRideCount,
          overallDrivingScoreData: overallDrivingScoreData,
          totalRideCount: totalRide > 0 ? totalRide : 0,
          quietDangerousRidePer: quietDangerousRidePercentage,
          dangerousRidePer: dangerousRidePercentage,
          riskAlertCount: riskAlertCount,
          rideCountAction: rideCountAction,
          accelerationCount: accelerationCount,
          corneringCount: corneringCount,
          brakingCount: brakingCount,
          overSpeedCount: overSpeedCount,
          mobileScreenCount: mobileScreenCount,
          mobileUseCount: mobileUseCount,
          redRiskCount: redRiskCount,
          orangeRiskCount: orangeRiskCount,
        };

        UserSummaryListData.push(resultTopList);
      });

      // setTableData(data)
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      const title = "Lowest Performance | Motiv AI";

      const headers = [
        [
          "Name",
          "Username",
          "Rank",
          "Total Rides",
          "Dangerous Rides",
          "Quite Dangerous Rides",
          "Risk Alerts",
          // "Red Risk",
          // "Orange Risk",
          "Overspeed",
          "Mobile Call",
          "Mobile Screen",
          "Acceleration",
          "Braking",
          "Cornering",
        ],
      ];
      let content;

      if (resultJSONData.length === 0 || dataLength === 0) {
        const text = "Data not available";
        const textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        const textHeight =
          doc.internal.getLineHeight() / doc.internal.scaleFactor;
        const startX = (doc.internal.pageSize.width - textWidth) / 2;
        const startY = (doc.internal.pageSize.height - textHeight) / 2;
        content = {
          startY: 50,
          head: headers,
          body: [],
        };
        doc.text(text, startX, startY);
      } else {
        const fontSize = 10;
        const Data = UserSummaryListData.map((elt) => [
          elt.firstName,
          elt.username,
          elt.rank,
          elt.totalRideCount,
          elt.dangerousRideCount,
          elt.quietDangerousRideCount,
          elt.riskAlertCount,
          // elt.redRiskCount,
          // elt.orangeRiskCount,
          elt.overSpeedCount,
          elt.mobileUseCount,
          elt.mobileScreenCount,
          elt.accelerationCount,
          elt.brakingCount,
          elt.corneringCount,
        ]);
        content = {
          startY: 50,
          head: headers,
          body: Data,
          didDrawPage: (hookData) => {
            doc.setFontSize(fontSize);
          },
        };
      }

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Lowest Performance");
      setShowLoader(false)();
    } else {
      setShowLoader(false);
      ToastService.errormsg(displayText.ER_1206);
    }
  };

  const userSummary = async () => {
    setShowLoader(true);
    var result = await AdminDashboardController.userSummaryList(
      filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime
    );
    var topUserListData = [];
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      setDataLength(resultJSONData.length);
      // console.log("topUserList: ", (resultJSONData));
      let index = 0;
      resultJSONData.forEach((value) => {
        index++;
        const {
          firstName = value.firstName && value.firstName.length > 0
            ? ValueFormat.capitalizeFirstLetter(value.firstName)
            : "N/A",
          username = value.username || "N/A",
          rideBox5,
          rideBox6,
          totalRide,
          rideBox5Percent,
          rideBox6Percent,
          actionRideCount,
          actionAlertCount,
          overallDrivingScore,
          userId,
          totalSevereAccelerationLowCount,
          totalSevereAccelerationMediumCount,
          totalSevereAccelerationHighCount,
          totalSevereCorneringLowCount,
          totalSevereCorneringMediumCount,
          totalSevereCorneringHighCount,
          totalSevereBrakingLowCount,
          totalSevereBrakingMediumCount,
          totalSevereBrakingHighCount,
          totalOverSpeedCount,
          totalMobileUseInAcceptedCount,
          totalMobileUseOutAcceptedCount,
          totalMobileScreenScreenOnCount,
          totalHighRiskCount,
          totalMediumRiskCount,
        } = value;

        const rank = index;
        const quietDangerousRideCount = ValueFormat.parseAsInt(rideBox5);
        const dangerousRideCount = ValueFormat.parseAsInt(rideBox6);
        const overallDrivingScoreData =
          ValueFormat.parseAsInt(overallDrivingScore);
        const redRiskCount = ValueFormat.parseAsInt(totalHighRiskCount);
        const orangeRiskCount = ValueFormat.parseAsInt(totalMediumRiskCount);

        const severeAccLow = parseFloat(
          ValueFormat.parseAsFloat(totalSevereAccelerationLowCount)
        );
        const severeAccMed = parseFloat(
          ValueFormat.parseAsFloat(totalSevereAccelerationMediumCount)
        );
        const severeAccHigh = parseFloat(
          ValueFormat.parseAsFloat(totalSevereAccelerationHighCount)
        );
        const accelerationCount = severeAccHigh + severeAccMed + severeAccLow;

        const severeCorneringLow = parseFloat(
          ValueFormat.parseAsFloat(totalSevereCorneringLowCount)
        );
        const severeCorneringMed = parseFloat(
          ValueFormat.parseAsFloat(totalSevereCorneringMediumCount)
        );
        const severeCorneringHigh = parseFloat(
          ValueFormat.parseAsFloat(totalSevereCorneringHighCount)
        );
        const corneringCount =
          severeCorneringHigh + severeCorneringMed + severeCorneringLow;

        const severeBrakingLow = parseFloat(
          ValueFormat.parseAsFloat(totalSevereBrakingLowCount)
        );
        const severeBrakingMed = parseFloat(
          ValueFormat.parseAsFloat(totalSevereBrakingMediumCount)
        );
        const severeBrakingHigh = parseFloat(
          ValueFormat.parseAsFloat(totalSevereBrakingHighCount)
        );
        const brakingCount =
          severeBrakingHigh + severeBrakingMed + severeBrakingLow;

        const overSpeedCount = ValueFormat.parseAsFloat(totalOverSpeedCount);

        const mobileScreenCount = ValueFormat.parseAsFloat(
          totalMobileScreenScreenOnCount
        );

        const mobileUseIn = parseFloat(
          ValueFormat.parseAsFloat(totalMobileUseInAcceptedCount)
        );
        const mobileUseOut = parseFloat(
          ValueFormat.parseAsFloat(totalMobileUseOutAcceptedCount)
        );

        const mobileUseCount = mobileUseIn + mobileUseOut;

        const quietDangerousRidePercentage =
          ValueFormat.parseAsFloat(rideBox5Percent);
        const dangerousRidePercentage =
          ValueFormat.parseAsFloat(rideBox6Percent);
        const riskAlertCount = ValueFormat.parseAsInt(actionAlertCount);
        const rideCountAction = ValueFormat.parseAsInt(actionRideCount);

        const resultTopList = {
          userId: userId,
          rank: rank,
          firstName:
            firstName.length > 0
              ? ValueFormat.capitalizeFirstLetter(firstName)
              : "N/A",
          username: username,
          quietDangerousRideCount: quietDangerousRideCount,
          dangerousRideCount: dangerousRideCount,
          overallDrivingScoreData: overallDrivingScoreData,
          totalRideCount: totalRide > 0 ? totalRide : 0,
          quietDangerousRidePer: quietDangerousRidePercentage,
          dangerousRidePer: dangerousRidePercentage,
          riskAlertCount: riskAlertCount,
          rideCountAction: rideCountAction,
          accelerationCount: accelerationCount,
          corneringCount: corneringCount,
          brakingCount: brakingCount,
          overSpeedCount: overSpeedCount,
          mobileScreenCount: mobileScreenCount,
          mobileUseCount: mobileUseCount,
          redRiskCount: redRiskCount,
          orangeRiskCount: orangeRiskCount,
        };

        topUserListData.push(resultTopList);
      });
      receiveRiskSummaryData({
        data: topUserListData,
        filter: filter.durationFilter,
      });
      setData(topUserListData);
      // console.log("data", data)
      setShowLoader(false);
    } else {
      setShowLoader(false);

      // const code = resultJSON.error.code;
      // if (code == "E1206") {

      //     window.location.href = '/adminDashboard'
      // }
    }
  };

  useEffect(() => {
    userSummary();
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime]);

  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      // position: "sticky",
      backgroundColor: "#fff",
      height: "20px",
      fontWeight: 600,
      fontSize: "14px",
    },
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submit();
    }
  };

  return (
    <div>
      {showLoader && <Loader />}

      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader className="cardHeader" style={{paddingLeft: '12px'}}>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="sub_heading m-0 me-1">Lowest Performance</h5><span>(Descending)</span>
                <div className="ms-auto">
                  <div style={{ verticalAlign: "middle", display: "flex" }}>
                    {/* <div>
                                            <div className="d-flex ">
                                                <div className="me-2">
                                                    <button className="btn btn-danger me-1" />
                                                    (Bad Score)
                                                </div>
                                                <div className="me-2">
                                                    <button className="btn btn-warning me-1" />
                                                    (Average Score)
                                                </div>
                                                <div className="me-5">
                                                    <button className="btn btn-success me-1" />
                                                    (Good Score)
                                                </div>

                                            </div>
                                        </div> */}
                    {/* <button
                                            data-tooltip-id="cds"
                                            type="button"
                                            className={
                                                filter == "CURRENT_DAY"
                                                    ? "btn btn-primary btn-sm me-1"
                                                    : "btn btn-soft-primary btn-sm me-1"
                                            }
                                            onClick={() => setFilter("CURRENT_DAY")}
                                        >
                                            CD
                                            <ReactTooltip
                                                id="cds"
                                                place="top"
                                                content="Current Day"
                                                style={{ backgroundColor: "black" }}
                                            />
                                        </button>{" "}
                                        <button
                                            type="button"
                                            data-tooltip-id="cws"
                                            className={
                                                filter == "CURRENT_WEEK"
                                                    ? "btn btn-secondary btn-sm me-1"
                                                    : "btn btn-soft-secondary btn-sm me-1"
                                            }
                                            onClick={() => setFilter("CURRENT_WEEK")}
                                        >
                                            CW
                                            <ReactTooltip
                                                id="cws"
                                                place="top"
                                                content="Current Week"
                                                style={{ backgroundColor: "black" }}
                                            />
                                        </button>{" "}
                                        <button
                                            type="button"
                                            data-tooltip-id="cms"
                                            className={
                                                filter == "CURRENT_MONTH"
                                                    ? "btn btn-secondary btn-sm me-1"
                                                    : "btn btn-soft-secondary btn-sm me-1"
                                            }
                                            onClick={() => setFilter("CURRENT_MONTH")}
                                        >
                                            CM
                                            <ReactTooltip
                                                id="cms"
                                                place="top"
                                                content="Current Month"
                                                style={{ backgroundColor: "black" }}
                                            />
                                        </button>{" "}
                                        <button
                                            type="button"
                                            data-tooltip-id="cys"
                                            className={
                                                filter == "CURRENT_YEAR"
                                                    ? "btn btn-primary btn-sm me-1"
                                                    : "btn btn-soft-primary btn-sm me-1"
                                            }
                                            onClick={() => setFilter("CURRENT_YEAR")}
                                        >
                                            CY
                                            <ReactTooltip
                                                id="cys"
                                                place="top"
                                                content="Current Year"
                                                style={{ backgroundColor: "black" }}
                                            />
                                        </button>{" "}
                                        <button
                                            type="button"
                                            className={
                                                filter == "CUSTOM_DURATION"
                                                    ? "btn btn-primary btn-sm"
                                                    : "btn btn-soft-secondary btn-sm"
                                            }
                                            onClick={() => { setIsOpen(true); }}
                                            data-tooltip-id="dfdf"
                                        >
                                            <FontAwesomeIcon icon={faClock} />
                                            <ReactTooltip
                                                id="dfdf"
                                                place="top"
                                                content="Custom Date Selection"
                                                style={{ background: "black" }}
                                            />
                                        </button> */}
                    <button
                      type="button"
                      data-tooltip-id="euser"
                      className={"btn btn-soft-primary btn-sm ms-3"}
                      onClick={exportPDF}
                    >
                      <PiDownloadSimple size="18px" />
                      <ReactTooltip
                        id="euser"
                        place="top"
                        content="Export All Users"
                        style={{ backgroundColor: "black" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </CardHeader>
            {dataLength > 0 ? (
              <CardBody className="p-0">
                <div style={{ height: 320, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Name
                          <br />
                          <img
                            src={i1}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Username
                          <br />
                          <img
                            src={i2}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Rank
                          <br />
                          <img
                            src={i3}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Total Rides
                          <br />
                          <img
                            src={i4}
                            alt="icons1"
                            style={{
                              marginTop: "3px",
                              marginBottom: "-8px",
                              height: "30px",
                            }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Dangerous Rides
                          <br />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Quite Dangerous Rides
                          <br />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Risk Alerts
                          <br />
                          <img
                            src={i7}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        {/* <th style={{ ...TableStyle.th, fontSize: "13px",fontWeight: 600, textAlign: "center", verticalAlign:"middle" }}>Red Risk<br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                <th style={{ ...TableStyle.th, fontSize: "13px",fontWeight: 600, textAlign: "center", verticalAlign:"middle" }}>Orange Risk<br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Overspeed
                          <br />
                          <img
                            src={i8}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Mobile Call
                          <br />
                          <img
                            src={i9}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Mobile Screen
                          <br />
                          <img
                            src={i10}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Acceleration
                          <br />
                          <img
                            src={i11}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Braking
                          <br />
                          <img
                            src={i12}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Cornering
                          <br />
                          <img
                            src={i13}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data || []).map((apiData, index) => (
                        <tr key={index}>
                          <td style={{ paddingLeft: "20px" }}>
                            <span
                              style={{ fontSize: "14px", textAlign: "start" }}
                              onMouseEnter={() => [
                                localStorage.setItem(
                                  SC_USER_ID,
                                  apiData.userId
                                ),
                                localStorage.setItem(
                                  SC_LOGIN_USER_FIRST_NAME,
                                  apiData.firstName
                                ),
                                localStorage.setItem(
                                  SC_USER_USERNAME,
                                  apiData.username
                                ),
                              ]}
                            >
                              <Link to={"/user-dashboard"} textAlign={"center"}>
                                {apiData.firstName}
                              </Link>
                            </span>
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.username}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.rank}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.totalRideCount}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.dangerousRideCount !== 0
                              ? `${apiData.dangerousRidePer}% (${apiData.dangerousRideCount})`
                              : "-"}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.quietDangerousRideCount !== 0
                              ? `${apiData.quietDangerousRidePer}% (${apiData.quietDangerousRideCount})`
                              : "-"}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.riskAlertCount !== 0
                              ? apiData.riskAlertCount
                              : "-"}
                          </td>
                          {/* <td style={{ textAlign: "center", fontSize: "14px" }}>
                                                        {apiData.redRiskCount !== 0 ? apiData.redRiskCount : "-"}
                                                    </td>
                                                    <td style={{ textAlign: "center", fontSize: "14px" }}>
                                                        {apiData.orangeRiskCount !== 0 ? apiData.orangeRiskCount : "-"}
                                                    </td> */}
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.overSpeedCount}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.mobileUseCount}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.mobileScreenCount}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.accelerationCount}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.brakingCount}
                          </td>
                          <td style={{ textAlign: "center", fontSize: "14px" }}>
                            {apiData.corneringCount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            ) : dataLength === 0 ? (
              <CardBody className="p-0">
                <div style={{ height: 340, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Name
                          <br />
                          <img
                            src={i1}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Username
                          <br />
                          <img
                            src={i2}
                            alt="icons12"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Rank
                          <br />
                          <img
                            src={i3}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Total Rides
                          <br />
                          <img
                            src={i4}
                            alt="icons1"
                            style={{
                              marginTop: "3px",
                              marginBottom: "-8px",
                              height: "30px",
                            }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Dangerous Rides
                          <br />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Quite Dangerous Rides
                          <br />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Risk Alerts
                          <br />
                          <img
                            src={i7}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        {/* <th style={{ ...TableStyle.th, fontSize: "13px",fontWeight: 600, textAlign: "center", verticalAlign:"middle" }}>Red Risk<br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                <th style={{ ...TableStyle.th, fontSize: "13px",fontWeight: 600, textAlign: "center", verticalAlign:"middle" }}>Orange Risk<br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Overspeed
                          <br />
                          <img
                            src={i8}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Mobile Call
                          <br />
                          <img
                            src={i9}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Mobile Screen
                          <br />
                          <img
                            src={i10}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Acceleration
                          <br />
                          <img
                            src={i11}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Braking
                          <br />
                          <img
                            src={i12}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Cornering
                          <br />
                          <img
                            src={i13}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                      </tr>
                    </thead>
                  </Table>
                  <p
                    className="data_not_available"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.DATA_NOT_AVAILABLE}
                  </p>
                </div>
              </CardBody>
            ) : (
              <CardBody className="p-0">
                <div style={{ height: 340, overflowY: "auto" }}>
                  <Table bordered style={TableStyle.table}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Name
                          <br />
                          <img
                            src={i1}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Username
                          <br />
                          <img
                            src={i2}
                            alt="icons12"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Rank
                          <br />
                          <img
                            src={i3}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Total Rides
                          <br />
                          <img
                            src={i4}
                            alt="icons1"
                            style={{
                              marginTop: "3px",
                              marginBottom: "-8px",
                              height: "30px",
                            }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Dangerous Rides
                          <br />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Quite Dangerous Rides
                          <br />
                          <img
                            src={i5}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Risk Alerts
                          <br />
                          <img
                            src={i7}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        {/* <th style={{ ...TableStyle.th, fontSize: "13px",fontWeight: 600, textAlign: "center", verticalAlign:"middle" }}>Red Risk<br /><img src={i8} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                <th style={{ ...TableStyle.th, fontSize: "13px",fontWeight: 600, textAlign: "center", verticalAlign:"middle" }}>Orange Risk<br /><img src={i9} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Overspeed
                          <br />
                          <img
                            src={i8}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Mobile Call
                          <br />
                          <img
                            src={i9}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Mobile Screen
                          <br />
                          <img
                            src={i10}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Acceleration
                          <br />
                          <img
                            src={i11}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Braking
                          <br />
                          <img
                            src={i12}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                        <th
                          style={{
                            ...TableStyle.th,
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Cornering
                          <br />
                          <img
                            src={i13}
                            alt="icons1"
                            style={{ marginTop: "5px", height: "20px" }}
                          />
                        </th>
                      </tr>
                    </thead>
                  </Table>
                  <p
                    className="fetch_data"
                    style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {displayText.FETCHING_DATA}
                  </p>
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {/* <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter} /> */}
    </div>
  );
}
