import React, { useState, useEffect } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Progress,
  Row,
  Table,
} from "reactstrap";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import UserReactToPdf from "../../components/ExportScreenPdf/userReactToPdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import YearPopup from "../ModelPopup/YearPopup";
import Loader from "../../components/Common/loader";
import ReactEcharts from "echarts-for-react";
import UserDashboardColorIndicators from "./UserDashboardNew/UserDashboardColorIndicators";

export default function OverAllDrivingScore({ getOverAllDataProps, getSelectedYear, totalSafeRidePer, 
  rideTotalRide, lastOverAllDataSY, USRideBoxCount }) {
     
  const overallDataTemp = [
    {
      score: 0,
      label: "CD",
      toolTip: "Current Day",
      tooltipFullScore: "-",
      toolTipId: "Current Day Full Score",
      totalKiloMeter: 0.00,
      totalRideEfficiency: 0.00,
      totalRides: 0,
    },
    {
      score: 0,
      label: "CW",
      toolTip: "Current Week",
      tooltipFullScore: "-",
      toolTipId: "Current Week Full Score",
      totalKiloMeter: 0.00,
      totalRideEfficiency: 0.00,
      totalRides: 0,
    },
    {
      score: 0,
      label: "CM",
      toolTip: "Current Month",
      tooltipFullScore: "-",
      toolTipId: "Current Mont Full Score",
      totalKiloMeter: 0.00,
      totalRideEfficiency: 0.00,
      totalRides: 0,
    },
    {
      score: 0,
      label: "CY",
      toolTip: "Current Year",
      tooltipFullScore: "-",
      toolTipId: "Current Year Full Score",
      totalKiloMeter: 0.00,
      totalRideEfficiency: 0.00,
      totalRides: 0,
    },
    // {
    //   score: 0,
    //   label: "SY",
    //   toolTip: "Selected Year",
    //   tooltipFullScore: "-",
    //   toolTipId: "Selected Year Full Score",
    //   totalKiloMeter: 0.00,
    //   totalRideEfficiency: 0.00,
    //   totalRides: 0,
    // },
  ];

  const lastOverallDataTemp = [
    {
      lastScore: 0,
      label: "SY",
      toolTip: "Selected Year",
      lastTooltipFullScore: "-",
      toolTipId: "Selected Year Full Score",
      totalKiloMeter: 0.00,
      totalRideEfficiency: 0.00,
      totalRides: 0,
      totalMinutes: 0,
    },
  ];

  const [showLoader, setShowLoader] = useState(false);
  const [display, setDisplay] = useState(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [overAllData, setOverAllData] = useState(overallDataTemp);
  const [lastOverAllData, setLastOverAllData] = useState(lastOverallDataTemp);

  const [indexStart, setIndexStart] = useState(0);

  const [startYear, setStartYear] = useState("");
  const [filter, setFilter] = useState("CUSTOM_DURATION");
  const [endYear, setEndYear] = useState("");
  const getOverAllData = async () => {
    setDisplay(null);
    setShowLoader(true);
    var result = await UserDrivingScoreController.overallUserSummary(
      indexStart,
      startYear,
      endYear
    );
    setShowLoader(false);
    var resultJSON = JSON.parse(result);
    // console.log("getOverAllData1", resultJSON);
    var status = resultJSON.status;
    var scoreTemp = 0;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      // console.log("getOverAllDataResponse: ", JSON.stringify(resultJSONData));
      resultJSONData.map((value) => {
        var currentDay = ValueFormat.parseAsObject(value.currentDay);
        var currentWeek = ValueFormat.parseAsObject(value.currentWeek);
        var currentMonth = ValueFormat.parseAsObject(value.currentMonth);
        var currentYear = ValueFormat.parseAsObject(value.currentYear);
        var selectedYear = ValueFormat.parseAsObject(value.selectedYear);

        var currentDayScore = Math.round(
          ValueFormat.parseAsInt(currentDay.overallDrivingScore)
        );
        var currentDayFullScore = ValueFormat.parseAsInt(
          currentDay.overallDrivingScore
        );
        var cdTotalKiloMeter = ValueFormat.parseAsFloat(
          currentDay.totalKiloMeter
        );
        var cdTotalRideEfficiency = ValueFormat.parseAsFloat(
          currentDay.totalRideEfficiency
        );
        var cdTotalRides = ValueFormat.parseAsInt(
          currentDay.totalRides
        );

        var currentWeekScore = Math.round(
          ValueFormat.parseAsInt(currentWeek.overallDrivingScore)
        );
        var currentWeekFullScore = ValueFormat.parseAsInt(
          currentWeek.overallDrivingScore
        );
        var cwTotalKiloMeter = ValueFormat.parseAsFloat(
          currentWeek.totalKiloMeter
        );
        var cwTotalRideEfficiency = ValueFormat.parseAsFloat(
          currentWeek.totalRideEfficiency
        );
        var cwTotalRides = ValueFormat.parseAsInt(
          currentWeek.totalRides
        );

        var currentMontScore = Math.round(
          ValueFormat.parseAsInt(currentMonth.overallDrivingScore)
        );
        var currentMontFullScore = ValueFormat.parseAsInt(
          currentMonth.overallDrivingScore
        );
        var cmTotalKiloMeter = ValueFormat.parseAsFloat(
          currentMonth.totalKiloMeter
        );
        var cmTotalRideEfficiency = ValueFormat.parseAsFloat(
          currentMonth.totalRideEfficiency
        );
        var cmTotalRides = ValueFormat.parseAsInt(
          currentMonth.totalRides
        );

        var currentYearScore = Math.round(
          ValueFormat.parseAsInt(currentYear.overallDrivingScore)
        );
        var currentYearFullScore = ValueFormat.parseAsInt(
          currentYear.overallDrivingScore
        );
        var cyTotalKiloMeter = ValueFormat.parseAsFloat(
          currentYear.totalKiloMeter
        );
        var cyTotalRideEfficiency = ValueFormat.parseAsFloat(
          currentYear.totalRideEfficiency
        );
        var cyTotalRides = ValueFormat.parseAsInt(
          currentYear.totalRides
        );

        var selectedYearScore = Math.round(
          ValueFormat.parseAsInt(selectedYear.overallDrivingScore)
        );
        var selectedYearFullScore = ValueFormat.parseAsInt(
          selectedYear.overallDrivingScore
        );
        var syTotalKiloMeter = ValueFormat.parseAsFloat(
          selectedYear.totalKiloMeter
        );
        var syTotalRideEfficiency = ValueFormat.parseAsFloat(
          selectedYear.totalRideEfficiency
        );
        var syTotalRides = ValueFormat.parseAsInt(
          selectedYear.totalRides
        );
        var syTotalTravelTime = ValueFormat.parseAsInt(
          selectedYear.totalTravelTime
        );

        scoreTemp =
          currentMontScore +
          currentYearScore +
          currentYearFullScore +
          currentDayFullScore +
          currentWeekFullScore +
          selectedYearScore;
        setDisplay(scoreTemp > 0 ? true : false);
        setOverAllData(
          overAllData.map((value) => {
            var score = 0;
            var tooltipFullScore = 0;
            var totalKiloMeter = 0.00;
            var totalRideEfficiency = 0.00;
            var totalRides = 0;
            if (value.label == "CD") {
              score = currentDayScore;
              tooltipFullScore = currentDayFullScore;
              totalKiloMeter = cdTotalKiloMeter;
              totalRideEfficiency = cdTotalRideEfficiency;
              totalRides = cdTotalRides;
            } else if (value.label == "CW") {
              score = currentWeekScore;
              tooltipFullScore = currentWeekFullScore;
              totalKiloMeter = cwTotalKiloMeter;
              totalRideEfficiency = cwTotalRideEfficiency;
              totalRides = cwTotalRides;
            } else if (value.label == "CM") {
              score = currentMontScore;
              tooltipFullScore = currentMontFullScore;
              totalKiloMeter = cmTotalKiloMeter;
              totalRideEfficiency = cmTotalRideEfficiency;
              totalRides = cmTotalRides;
            } else if (value.label == "CY") {
              score = currentYearScore;
              tooltipFullScore = currentYearFullScore;
              totalKiloMeter = cyTotalKiloMeter;
              totalRideEfficiency = cyTotalRideEfficiency;
              totalRides = cyTotalRides;
            }
            // else if (value.label == "SY") {
            //   score = selectedYearScore
            //   tooltipFullScore = selectedYearFullScore
            //   totalKiloMeter = syTotalKiloMeter;
            //   totalRideEfficiency = syTotalRideEfficiency;
            //   totalRides = syTotalRides;
            // }

            var result = {
              ...value,
              score: score,
              tooltipFullScore: tooltipFullScore,
              totalKiloMeter: totalKiloMeter,
              totalRideEfficiency: totalRideEfficiency,
              totalRides: totalRides,
            };

            return result;
          })
        );
        setLastOverAllData(
          lastOverAllData.map((value) => {
            var lastScore = 0;
            var lastTooltipFullScore = 0;
            var totalKiloMeter = 0.00;
            var totalRideEfficiency = 0.00;
            var totalRides = 0;
            var totalMinutes = 0;
            if (value.label == "SY") {
              lastScore = selectedYearScore;
              lastTooltipFullScore = selectedYearFullScore;
              totalKiloMeter = syTotalKiloMeter;
              totalRideEfficiency = syTotalRideEfficiency;
              totalRides = syTotalRides;
              totalMinutes = syTotalTravelTime;
            }
            var result = {
              ...value,
              lastScore: lastScore,
              lastTooltipFullScore: lastTooltipFullScore,
              totalKiloMeter: totalKiloMeter,
              totalRideEfficiency: totalRideEfficiency,
              totalRides: totalRides,
              totalMinutes: totalMinutes
            };

            return result;
          })
        );

        lastOverAllDataSY(
          lastOverAllData.map((value) => {
            var lastScore = 0;
            var lastTooltipFullScore = 0;
            var totalKiloMeter = 0.00;
            var totalRideEfficiency = 0.00;
            var totalRides = 0;
            if (value.label == "SY") {
              lastScore = selectedYearScore;
              lastTooltipFullScore = selectedYearFullScore;
              totalKiloMeter = syTotalKiloMeter;
              totalRideEfficiency = syTotalRideEfficiency;
              totalRides = syTotalRides;
            }
            var result = {
              ...value,
              lastScore: lastScore,
              lastTooltipFullScore: lastTooltipFullScore,
              totalKiloMeter: totalKiloMeter,
              totalRideEfficiency: totalRideEfficiency,
              totalRides: totalRides,
            };

            return result;
          })
        );

        // console.log("123", result);
        getOverAllDataProps(
          overAllData.map((value) => {
            var score = 0;
            var tooltipFullScore = 0;
            if (value.label == "CD") {
              score = currentDayScore;
              tooltipFullScore = currentDayFullScore;
            } else if (value.label == "CW") {
              score = currentWeekScore;
              tooltipFullScore = currentWeekFullScore;
            } else if (value.label == "CM") {
              score = currentMontScore;
              tooltipFullScore = currentMontFullScore;
            } else if (value.label == "CY") {
              score = currentYearScore;
              tooltipFullScore = currentYearFullScore;
            }
            else if (value.label == "SY") {
              score = selectedYearScore
              tooltipFullScore = selectedYearFullScore
            }

            var result = {
              ...value,
              score: score,
              tooltipFullScore: tooltipFullScore,
            };

            return result;
          })
        );
        getSelectedYear(startYear);

      });
    } else {
      const code = resultJSON.error.code;
      if (code == "E1206") {
        setDisplay("failed");
      }
    }
  };

  useEffect(() => {
    getOverAllData();
  }, [startYear, endYear]);

  let color = "";

  if (totalSafeRidePer >= 0 && totalSafeRidePer <= 80) {
    color = "red";
  } else if (totalSafeRidePer >= 81 && totalSafeRidePer <= 93) {
    color = "#FFBF00";
  } else {
    color = "#19543E";
  }

  const getAdditionalData = (rideBox) => {
    switch (rideBox) {
      case 1:
        return { safetyName: "Safe Category", safetyName1: 'C1', className: "success", piColor: "#08BD3F" };
      case 2:
        return { safetyName: "Sportive Category", safetyName1: 'C2', className: "primary", piColor: "#5156be" };
      case 3:
        return { safetyName: "Quite Safe Category", safetyName1: 'C3', className: "info", piColor: "#11BFD0" };
      case 4:
        return { safetyName: "Reckless Category", safetyName1: 'C4', className: "warning", piColor: "#ffbf53" };
      case 5:
        return { safetyName: "Quite Dangerous Category", safetyName1: 'C5', className: "danger", piColor: "#FF0000" };
      case 6:
        return { safetyName: "Dangerous Category", safetyName1: 'C6', className: "secondary", piColor: "#585F5F" };
      default:
        return {};
    }
  };

  const totalRides = USRideBoxCount.reduce((sum, item) => sum + item.totalRides, 0);

  const chartData = USRideBoxCount.map(item => {
    const additionalData = getAdditionalData(item.rideBox);
    return {
      value: item.totalRides,
      name: `${additionalData.safetyName} (${additionalData.safetyName1})`,
      safetyName: additionalData.safetyName,
      safetyName1: additionalData.safetyName1,
      percentage: item.percentage,
      itemStyle: {
        color: additionalData.piColor
      }
    };
  });

  const legendData = chartData.map(item => item.name);

  const option = {
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        return `${params.data.safetyName} (${params.data.safetyName1}) - (${params.data.value}/${totalRides}) ${params.data.percentage}%`;
      }
    },
    legend: {
      show: true,
      bottom: 30,
      left: "center",
      textStyle: { color: "black", align: 'center' },
      data: legendData,
      itemWidth: 20,
      itemHeight: 14,
      width: 600
    },
    series: [
      {
        name: 'Overall Driving Score',
        type: 'pie',
        radius: '50%',
        center: ["50%", "40%"],
        data: chartData || [],
        selectedMode: "single",
        label: {
          formatter: (params) => {
            return `${params.data.safetyName} (${params.data.safetyName1}) : ${params.data.value}`;
          },
          position: 'outside'
        },
        labelLine: {
          length: 15,
          length2: 10
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          }
        }
      },
    ]
  };

  const [chartWidth, setChartWidth] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 460) {
        setChartWidth("88vw");
      } else if (window.innerWidth >= 461 && window.innerWidth <= 1199) {
        setChartWidth("90vw");
      } else {
        setChartWidth("46vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      {showLoader && <Loader />}
      <Row>
        <Col xl={6} md={12}>
          <Card>
            <CardHeader
              className="cardHeader border-0 d-flex align-items-center py-1"
              style={{ height: "58.19px" }}
            >
              <h5 className="sub_heading" data-tooltip-id="All year">
              Rides Category Summary
              </h5>
              <ReactTooltip id="All year" place="top" content="Showing for all year" />
            </CardHeader>
            {/* <CardBody
              className="cardHeader border-0 pt-1"
              style={{ height: "173.98px" }}
            >
              <h6 className="body_text">
              (C1) Safe category {" "}
                {`(${rideTotalRide.ride} / ${rideTotalRide.totalRides})`}
              </h6>
              <Progress
                value={totalSafeRidePer}
                style={{ height: "25px", "--bs-progress-bar-bg": color }}
              >
                {totalSafeRidePer}%
              </Progress><br/>
              <div className="mt-2 text-center">
                <h5 className="alertcard-title font-size-16">
                  {totalSafeRidePer >= 75 ? "Congrats" : "Sorry"}
                </h5>
                <p className="font-size-12 ">
                  {totalSafeRidePer < 75 ? "Only " : ""}
                  {totalSafeRidePer}% of your rides are in Safe Driver Category
                  (C1)
                </p>
              </div>
            </CardBody> */}
            <CardBody className="cardHeader border-0 p-1" style={{ height: '428px' }}>
              <ReactEcharts style={{ height: "100%", width: chartWidth }} option={option} />
            </CardBody>
          </Card>
        </Col>

        <Col xl={6} md={12}>
          {/* <Card>
            <CardHeader
              className="cardHeader border-0 d-flex align-items-center justify-content-between py-1"
              style={{ height: "58.19px" }}
            >
              <h5 className="sub_heading" data-tooltip-id="Selected year">
                Overall Driving Score (SY)
              </h5>
              <ReactTooltip
                id="Selected year"
                place="top"
                content="Showing for selected year"
              />
              <div style={{ paddingBottom: "8px" }}>
                <YearPopup
                  setStartYear={setStartYear}
                  setEndYear={setEndYear}
                  setFilter={setFilter}
                  data-tooltip-id="sy"
                />
              </div>
            </CardHeader>
            {display === false ? (
              <p className="data_not_available" style={{ marginTop: "-19.5px" }}>
                {displayText.DATA_NOT_AVAILABLE}
              </p>
            ) : display === "failed" ? (
              <p className="data_not_available" style={{ marginTop: "-16.5px", fontSize: '11px' }}>{displayText.ER_1206}</p>
            ) : display === null ? (
              <p className="fetch_data" style={{ marginTop: "-19.5px" }}>
                {displayText.FETCHING_DATA}
              </p>
            ) : (
              ""
            )}
            <CardBody className="cardHeader border-0 pt-1" style={{ height: "173.98px" }} >
              <Row>
                <Col xl={5} lg={5} md={5} sm={5} xs={5} className="d-flex justify-content-center" >
                  {lastOverAllData.map((apiData, index) => (
                    <div
                      style={{ width: 90, height: 90 }}
                      key={index}
                    >
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "25px", fontWeight: 900 }}
                          data-tooltip-id="full score">
                          {apiData.lastScore}%
                        </span>
                        <ReactTooltip
                          id="full score"
                          place="top"
                          content={apiData.lastTooltipFullScore}
                        />
                      </div>

                      <div data-tooltip-id="sy">
                        <CircularProgressbar className="body_text"
                          value={apiData.lastScore}
                          // text={`${apiData.lastScore}`}
                          text="SY"
                          styles={buildStyles({
                            width: "20px",
                            textColor: "black",
                            pathColor:
                              apiData.lastScore >= 0 && apiData.lastScore <= 80
                                ? "red"
                                : apiData.lastScore >= 81 &&
                                  apiData.lastScore <= 93
                                  ? "#FFBF00"
                                  : "#19543E",
                            trailColor: "#0095FF",
                          })}
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "14px", fontWeight: 600 }}>
                          {apiData.totalKiloMeter} kms
                        </span>
                      </div>
                      <ReactTooltip
                        id="lt"
                        place="top"
                        content={apiData.lastTooltipFullScore}
                      />
                      <ReactTooltip
                        id="sy"
                        place="bottom"
                        content="Selected Year"
                      />
                    </div>
                  ))}
                </Col>
                <Col xl={7} lg={7} md={7} sm={7} xs={7} className="d-flex align-items-center justify-content-start" style={{ height: '170px' }}>
                  <Table borderless>
                    <thead>
                      <th className="body_text">Total Rides</th><th className="body_text">Total Minutes</th>
                    </thead>
                    <tbody>
                      {lastOverAllData.map((apiData, index) => (
                        <tr key={index}>
                          <td className="body_text ps-1">{apiData.totalRides}</td>
                          <td className="body_text ps-1">{apiData.totalMinutes}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader
              className="cardHeader border-0 d-flex flex-wrap align-items-center py-1"
              style={{ height: "58.19px" }}
            >
              <h5 className="sub_heading" data-tooltip-id="Current year">
                Overall Driving Score (CY)
              </h5>
              <ReactTooltip
                id="Current year"
                place="top"
                content="Showing for current year"
              />
            </CardHeader>
            {display === false ? (
              <p className="data_not_available" style={{ marginTop: "-19.5px" }}>
                {displayText.DATA_NOT_AVAILABLE}
              </p>
            ) : display === "failed" ? (
              <p className="data_not_available" style={{ marginTop: "-16.5px", fontSize: '11px' }}>{displayText.ER_1206}</p>
            ) : display === null ? (
              <p className="fetch_data" style={{ marginTop: "-19.5px" }}>
                {displayText.FETCHING_DATA}
              </p>
            ) : (
              ""
            )}
            <CardBody
              className="cardHeader border-0 pt-1"
              style={{ height: "173.98px" }}
            >
              <Row className="d-flex justify-content-center">
                {overAllData.map((apiData, index) => (
                  <Col
                    xl={3}
                    sm={3}
                    xs={3}
                    key={index}
                    className="d-flex justify-content-center"
                  >
                    <div style={{ width: 90, height: 90 }}>
                      <div style={{ textAlign: "center" }}>
                        <span
                          style={{ fontSize: "25px", fontWeight: 900 }}
                          data-tooltip-id={index + 11}
                        >
                          {apiData.score}%
                        </span>
                        <ReactTooltip
                          id={index + 11}
                          place="top"
                          content={apiData.tooltipFullScore}
                        />
                      </div>

                      <div data-tooltip-id={apiData.label}>
                        <CircularProgressbar className="body_text"
                          value={apiData.score}
                          text={`${apiData.label}`}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "black",
                            pathColor:
                              apiData.score >= 0 && apiData.score <= 80
                                ? "red"
                                : apiData.score >= 81 && apiData.score <= 93
                                  ? "#FFBF00"
                                  : "#19543E",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "14px", fontWeight: 600 }}>
                          {apiData.totalKiloMeter} kms
                        </span>
                      </div>
                      <ReactTooltip
                        id={apiData.label}
                        place="bottom"
                        content={apiData.toolTip}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card> */}
            {/* <UserDashboardColorIndicators /> */}
        </Col>
      </Row>
    </React.Fragment>
  );
}
