import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./UserDashboardSubMenu.css";

const UserDashboardSubMenu = () => {
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <h5 style={{ marginTop: "5px",fontSize:"16px" }}>User Dashboard</h5>&nbsp;
        <p style={{position:"relative", top:7, fontSize:"12px"}}>{"(For recent ride summary, Please select any one of the CD / CW / CM options from the filter)"}</p>Risk Histogram
        {/* <div>
          <Nav justified pills>
            <Link to="/dashboard">
              <button
                type="button"
                className={
                  pathname === "/dashboard"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Overall Summary
              </button>
            </Link>
            <Link to="/dashboard/RiskAlertSummary">
              <button
                type="button"
                className={
                  pathname === "/dashboard/RiskAlertSummary"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Risk Alert Summary
              </button>
            </Link>
            <Link to="/dashboard/CategorySummary">
              <button
                type="button"
                className={
                  pathname === "/dashboard/CategorySummary"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Category Summary
              </button>
            </Link>

             <NavItem className={pathname === "/dashboardNew/section4" ? "menu-active" : "menu-inactive"}>
               <Link  to="/dashboardNew/section4">Monthly & Weekly Summary </Link>
              </NavItem> 
          </Nav>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default UserDashboardSubMenu;
