import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import UserSessionController from "../../../controller/UserSession/UserSessionController.js";
import { GlobalConst } from "../../../constant/GlobalConstant.js";
import { BiSolidUserCircle } from "react-icons/bi";
import FeatherIcon from "feather-icons-react";
import "../TopbarDropdown/ProfileMenu.css";

import {
  SC_LOCK_STATUS,
  SC_USER_FIRST_NAME,
  SC_PROFILE_OPEN,
  SC_USER_LOGIN_TYPE,
} from "../../../constant/StorageConstant";
import Modal from "react-modal";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ConformationPopup from "../../Common/ConfirmationPopup.js";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE) || "";
  const [user, setUser] = useState("");  
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
    openPopup("Logout");
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const history = useNavigate();

// _____________________________

  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });

  const openPopup = (PopupType, Data) => {
    if (PopupType == "Logout") {
      setPopUpData({
        ...popupData,
        Purpose: "Logout",
        message: "you want to logout ?",
      });
    } else {
      setPopUpData({
        ...popupData,
        Purpose: "Delete",
        message: "This action cannot be undone. The station will be removed from the list.",
        DataDetails: Data ? Data : "",
      });
    }
    // setOpen(true);
  };

  const PopUpClose = () => {
    // setOpen(false);
  };

  // _______________________________________________

  useEffect(() => {
    if (loginType != undefined || loginType != null) {
      let userParse = loginType.split("_").map((val) => val.charAt(0) + val.slice(1).toLowerCase()).join(" ")
      setUser(userParse)
    }
  }, [loginType])

  const logout = async () => {
    var result = await UserSessionController.deleteUserSession();
    
    var resultTemp = JSON.parse(result);
    
    var resultStatus = resultTemp.status;
    if (resultStatus == GlobalConst.SUCCESS) {
      history("/logout");
    }
  };
  const lockUSer = () => {
    localStorage.setItem(SC_LOCK_STATUS, true);
  };

  const profileOpen = () => {
    localStorage.setItem(SC_PROFILE_OPEN, true);
  };
  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.displayName);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.username);
  //     }
  //   }
  // }, [props.success]);

  useEffect(() => {
    var userFirstNameTemp = localStorage.getItem(SC_USER_FIRST_NAME);
    if (userFirstNameTemp != undefined || userFirstNameTemp != null) {
      if (userFirstNameTemp.length > 0) {
        try {
          var userFirstName =
            userFirstNameTemp[0].toLocaleUpperCase() + userFirstNameTemp.slice(1);
          setusername(userFirstName);
        } catch (errMess) {
          // console.log("Error : " + errMess);
        }
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
          <div className="btn header-item   border-start" style={{ display: "flex", minWidth: "50px", maxWidth: "350px", alignItems:'center', cursor:"default"  }}>
            <div >
            
                <BiSolidUserCircle style={{ width: "50px", height: "50px", color:"#74788d" }} data-tooltip-id={username.length > 25 && "snpTooltipContent"} />
             
                <ReactTooltip
                  id={"snpTooltipContent"}
                  place="right"
                  content={username}
                  style={{ backgroundColor: "#000", opacity: 1 }}
              />
            </div>
            <DropdownToggle 
          id="page-header-user-dropdown"
          tag="button"
          style={{ border:"none", backgroundColor:"#fff" }}
        >
            <div className="d-none d-lg-inline-block text-nowrap" style={{ fontSize: '14px', fontWeight: 500, color: "#313533", textAlign: "left" }}>
                <span style={{ minWidth: "150px", maxWidth: "250px", overflow: "hidden", whiteSpace: "nowrap" }} >{username}</span>
                
                
                
                <i className="mdi mdi-chevron-down d-none d-lg-inline-block"/>

                
                <br/><span style={{fontSize: '12px',fontWeight: 500 }}>[Logged in as {user}]</span>
            </div>
            </DropdownToggle>
          </div>
          <div>
          </div>
       
        <DropdownMenu className="dropdown-menu-end" style={{ maxWidth: "300px" }}>
          {/* <DropdownItem tag="a" href="/profile"> */}
          {/* <Link
            to={"/contacts-profile"}
            className="dropdown-item"
            onClick={profileOpen}
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
            {props.t("Profile")}
          </Link> */}
          {/* <Link
            // to={"/profile"}
            className="dropdown-item"
          >
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </Link> */}
          <Link to="/page-lock-screen" className="dropdown-item">
            <button
              onClick={lockUSer}
              style={{
                border: "none",
                marginLeft: "-5px",
                paddingTop: "-10px",
              }}
            >
              {" "}
              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
              {props.t("Lock screen")}
            </button>
          </Link>
          {/*           
          {localStorage.getItem(SC_USER_LOGIN_TYPE)=="USER" &&   <Link 
          to="/appBootSettings" 
          className="dropdown-item">
         <button  style={{border:"none" ,marginLeft:"-5px", paddingTop:"-10px", marginRight:"10px"}}>  <FeatherIcon
         style={{marginRight:"5px"}}
                  icon="settings"
                  className="icon-sm"
                />
            {props.t("App Boot Settings")}</button>
          </Link>} */}
          <div className="dropdown-divider" />
          <button 
          // onClick={logout}
          onClick={openConfirmationModal}
           className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>

      <Modal
                isOpen={confirmationModalOpen}
                // onRequestClose={closeConfirmationModal}
                toggle={closeConfirmationModal}
                contentLabel="Confirmation Modal"
                // className="custom-modall"
                style={{
                  backgroundColor:"green",
                  content: { height: "190px", width: "340px", margin: "auto", borderRadius:"10px", padding:"0px" },
                  overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
                }}>


<ModalHeader style={{ height: "40px", backgroundColor: "#5156BE", width: "100%", display:"felx", justifyContent:"center"  }} >
  <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>Confirm Action </span>
</ModalHeader>
               
                 <ConformationPopup
         Data={popupData}
         formSubmit={logout}
         cancelSubmit={closeConfirmationModal}
        />
              </Modal>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default connect(mapStatetoProps, {})(withTranslation()(ProfileMenu));
