import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CardBody,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { Card, CardHeader } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import logo from "../../../assets/images/logo-sm.svg";
// import CarouselPage from './CarouselPage';
import "bootstrap/dist/css/bootstrap.min.css";
import motivLogo from "../../../assets/images/ruduicon.png";
import { CSVLink } from "react-csv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link, useNavigate } from "react-router-dom";
import ActiveUserdataList from "./activeusersdata";
import { PiDownloadSimple } from "react-icons/pi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController";
import ValueFormat from "../../../util/ValueFormat";
import {
  DEVICE_TYPE,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_ID,
  SC_USER_USERNAME,
  VEHICLE_TYPE,
} from "../../../constant/StorageConstant";
import Loader from "../../../components/Common/loader.js";
import { displayText } from "../../../constant/MessageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import { tr } from "date-fns/locale";
import "../ActiveUserNew/activeUsers.css";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TW from "../../../assets/images/two_wheeler.svg"
import FW from "../../../assets/images/four_wheeler.svg"
function ActiveUsersTableList() {
  const pageSize = 10;
  const history = useNavigate();
  const [data, setData] = useState(ActiveUserdataList);
  const [exportExcelData, setExportExcelData] = useState([]);
  const [indexStart, setIndexStart] = useState(0);
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [sortFilter, setSortFilter] = useState("ASC");
  const [workFiltersSort, setWorkFiltersSort] = useState("DP");
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setDataLength] = useState(null);
  const [subFilterText, setSubFilterText] = useState("");
  const [deviceFilter, setDeviceFilter] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [totalPageCount, setTotalPageCount] = useState();
  const [count, setCount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false); 
  const toggle = () => setDropdownOpen((prevState) => !prevState);

    const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const submit = async () => {
    setIndexStart((current) => current - current);
    setPageNumber((current) => current - current + 1);
    topUserList(0);
  };
  const topUserList = async (indexStartOfSet) => {
    setShowLoader(true);
    try {
    var result = await AdminDashboardController.activeUserList(
      indexStartOfSet,
      filter,
      sortFilter,
      subFilterText,
      startDateTime,
      endDateTime,
      deviceFilter,
      pageSize
    );
    var topUserListData = [];

    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      setDataLength(resultJSONData.length);
      // console.log("topUserList: " + JSON.stringify(resultJSONData));

      resultJSONData.forEach((value) => {
        const {
          username,
          firstName = value.firstName.length > 0
            ? ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(value.firstName))
            : "N/A",
          deviceType = ValueFormat.capitalizeFirstLetterAndFormat(value.deviceType) || "N/A",
          rideBox1,
          rideBox2,
          rideBox3,
          rideBox4,
          rideBox5,
          rideBox6,
          totalRide,
          rideBox1Percent,
          rideBox2Percent,
          rideBox3Percent,
          rideBox4Percent,
          rideBox5Percent,
          rideBox6Percent,
          userId,
          totalCount,
          vehicleType
        } = value;

        const goodRideCount = ValueFormat.parseAsInt(rideBox1);
        const sportiveRideCount = ValueFormat.parseAsInt(rideBox2);
        const quietSafeRideCount = ValueFormat.parseAsInt(rideBox3);
        const recklessRideCount = ValueFormat.parseAsInt(rideBox4);
        const quietDangerousRideCount = ValueFormat.parseAsInt(rideBox5);
        const dangerousRideCount = ValueFormat.parseAsInt(rideBox6);

        const goodRidePercentage = ValueFormat.parseAsFloat(rideBox1Percent, 2);
        const sportiveRidePercentage = ValueFormat.parseAsFloat(rideBox2Percent, 2);
        const quietSafeRidePercentage = ValueFormat.parseAsFloat(rideBox3Percent, 2);
        const recklessRidePercentage = ValueFormat.parseAsFloat(rideBox4Percent, 2);
        const quietDangerousRidePercentage = ValueFormat.parseAsFloat(rideBox5Percent, 2);
        const dangerousRidePercentage = ValueFormat.parseAsFloat(rideBox6Percent, 2);
        const vehicleTypes = ValueFormat.parseAsString(vehicleType || "-");

        var totalCounts = ValueFormat.parseAsInt(totalCount);
        setTotalPageCount(Math.ceil(totalCounts / pageSize));
        setNextShow(totalCounts - indexStartOfSet > pageSize);

        let deviceTypeTemp ="";
        if (deviceType === "ANDROID") {
          deviceTypeTemp = "Android"
        } else if (deviceType === "ANDROID_SDK"){
          deviceTypeTemp = "Android SDK"
        } else if (deviceType === "IOS") {
          deviceTypeTemp = "iOS"
        } else if (deviceType === "IOS_SDK"){
          deviceTypeTemp = "iOS SDK"
        } else  {
          deviceTypeTemp = "N/A"
        }

        const resultTopList = {
          userId: userId,
          username: username,
          firstName:
            firstName.length > 0
              ? ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(firstName))
              : "N/A",
          deviceType: deviceType.length > 0 ? deviceTypeTemp : "N/A",
          goodRideCount: goodRideCount,
          sportiveRideCount: sportiveRideCount,
          quietSafeRideCount: quietSafeRideCount,
          recklessRideCount: recklessRideCount,
          quietDangerousRideCount: quietDangerousRideCount,
          dangerousRideCount: dangerousRideCount,
          totalRideCount: totalRide > 0 ? totalRide : 0,
          goodRidePer: goodRidePercentage,
          sportiveRidePer: sportiveRidePercentage,
          quietSafeRidePer: quietSafeRidePercentage,
          recklessRidePer: recklessRidePercentage,
          quietDangerousRidePer: quietDangerousRidePercentage,
          dangerousRidePer: dangerousRidePercentage,
          vehicleType: vehicleTypes
        };

        topUserListData.push(resultTopList);
      });

      setData(topUserListData);
      setShowLoader(false);
    } else {
      setShowLoader(false);

      // const code = resultJSON.error.code;
      // if(code == "E1206"){

      //   window.location.href = '/adminDashboard'
      // }
    }
    }catch (e) {
      // console.log(e,"Catch error");
    }

    if (indexStartOfSet == 0) {
      setPrevShow(false);
    } else if (indexStartOfSet > 0) {
      setPrevShow(true);
    }

  };

  const topUserListExcelDataList = async () => {
    // setShowLoader(true);
    var result = await AdminDashboardController.activeUserExcelList(
      indexStart,
      filter,
      sortFilter,
      subFilterText,
      startDateTime,
      endDateTime,
      deviceFilter
    );
    var topUserListData = [];

    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;

    if (!resultJSONData || resultJSONData.length === 0) {
      // Handle the empty data here
      setExportExcelData("Data not available");
      // console.log("No data available");
      // setShowLoader(false);
      return;
    } else {
      // setDataLength(resultJSONData.length);
      // console.log("topUserList: " + JSON.stringify(resultJSONData));

      resultJSONData.forEach((value) => {
        const {
          username,
          firstName = value.firstName.length > 0
            ? ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(value.firstName))
            : "N/A",
          deviceType = value.deviceType.length > 0
            ? ValueFormat.capitalizeFirstLetterAndFormat(value.deviceType)
            : "N/A",
          rideBox1,
          rideBox2,
          rideBox3,
          rideBox4,
          rideBox5,
          rideBox6,
          totalRide,
        } = value;

        const goodRideCount = ValueFormat.parseAsInt(rideBox1);
        const sportiveRideCount = ValueFormat.parseAsInt(rideBox2);
        const quietSafeRideCount = ValueFormat.parseAsInt(rideBox3);
        const recklessRideCount = ValueFormat.parseAsInt(rideBox4);
        const quietDangerousRideCount = ValueFormat.parseAsInt(rideBox5);
        const dangerousRideCount = ValueFormat.parseAsInt(rideBox6);
        const resultTopList = {
          // userId:userId,
          // username:username,
          "First Name":
            firstName.length > 0
              ? ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(firstName))
              : "N/A",
          Username: username.length > 0 ? username : "N/A",
          "Device Type":
            deviceType.length > 0
              ? ValueFormat.capitalizeFirstLetterAndFormat(deviceType)
              : "N/A",
          "Total Rides": totalRide > 0 ? totalRide : 0,
          "Safe Driver": goodRideCount,
          "Sportive Driver": sportiveRideCount,
          "Quite Safe Driver": quietSafeRideCount,
          "Reckless Driver": recklessRideCount,
          "Quite Dangerous Driver": quietDangerousRideCount,
          "Dangerous Driver": dangerousRideCount,
        };

        topUserListData.push(resultTopList);
      });

      setExportExcelData(topUserListData);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    topUserListExcelDataList();
  }, [filter, sortFilter, startDateTime, endDateTime, subFilterText]);

  //   useEffect(() => {
  //   topUserList(indexStart);
  // },[indexStart])

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) {
      setCount((prev) => prev + 1);
    } else {
      setIndexStart(0);
    }
  }, [filter, sortFilter, startDateTime, endDateTime, deviceFilter]);

  useEffect(() => {
    if (count > 0) topUserList(indexStart);
  }, [indexStart, count]);

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      await e.preventDefault();
      submit();
    }
  };

  //meta title
  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <div>
          {/* <div
            style={{
              position: "absolute",
              zIndex: 1,
              marginLeft: "115px",
              marginTop: "2px",
              fontSize: "13px",
            }}
          >
            (Ride Based)
          </div> */}
          <Breadcrumbs title="Active Users" breadcrumbItem="Active Users" />
        </div>
        <Row className="my-2 py-2">
          <Col xs={12} className="d-flex justify-content-start text-black">
            <h5 className="mb-0">Active Users</h5>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader style={{ padding: "0px", paddingBottom: 0 }}>
                <Row style={{ paddingBottom: 0 }}>
                  <Col
                    xl={2}
                    lg={2}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1 me-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="short"
                      >
                        Sort By
                        <ReactTooltip
                          id="short"
                          place="top"
                          content="Sort By"
                          style={{ background: "black" }}
                        />{" "}
                        <span
                          style={{ fontSize: "13px", fontWeight: "normal" }}
                        >
                          (Ride Based)
                        </span>
                      </h4>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ marginTop: "16px", height: "40px" }}
                    >
                      <button
                        type="button"
                        data-tooltip-id="driverrr"
                        className={
                          workFiltersSort == "DP"
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setWorkFiltersSort("DP")}
                      >
                        Driver Performance
                        <ReactTooltip
                          id="driverrr"
                          place="top"
                          content="Driver Performance"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>

                  <Col
                    xl={2}
                    lg={2}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Device Selection
                      </h4>
                    </div>
                    {/* <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "40px", marginTop: "16px" }}
                    >
                      <button
                        type="button"
                        className={
                          deviceFilter == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID")}
                        data-tooltip-id="androidBl"
                      >
                        Android
                        <ReactTooltip
                          id="androidBl"
                          place="top"
                          content="Android"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("IOS")}
                        data-tooltip-id="iosBl"
                      >
                        IOS
                        <ReactTooltip
                          id="iosBl"
                          place="top"
                          content="IOS"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div> */}
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "40px", marginTop: "16px" }}>
                     <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                       <DropdownToggle caret type="button" className='btn-sm btnColorStylesNew m-1' 
                      //  color="primary"
                      style={{border:"0px",color:"black"}}
                       
                        >
                         {deviceFilter === '' ? 'All' : deviceFilter === 'ANDROID' ? 'Android' : deviceFilter === 'IOS' ? 'iOS' : deviceFilter === 'ANDROID_SDK' ? 'Android SDK' : deviceFilter === 'IOS_SDK' ? 'iOS SDK' : 'N/A'}<i className="mdi mdi-chevron-down ms-2"/>
                       </DropdownToggle>
                       <DropdownMenu>
                         <DropdownItem onClick={() => setDeviceFilter('')} > All </DropdownItem>
                         <DropdownItem onClick={() => setDeviceFilter('ANDROID')} > Android </DropdownItem>
                         <DropdownItem onClick={() => setDeviceFilter('IOS')} > iOS </DropdownItem>
                         <DropdownItem onClick={() => setDeviceFilter('ANDROID_SDK')} > Android SDK </DropdownItem>
                         <DropdownItem onClick={() => setDeviceFilter('IOS_SDK')} > iOS SDK </DropdownItem>
                       </DropdownMenu>
                     </Dropdown>
                    </div>
                  </Col>

                  <Col
                    xl={2}
                    lg={2}
                    style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="sortorsor"
                      >
                        Sort Order
                        <ReactTooltip
                          id="sortorsor"
                          place="top"
                          content="Sort Order"
                          style={{ backgroundColor: "black" }}
                        />
                      </h4>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        textAlign: "center",
                        height: "40px",
                        marginTop: "16px",
                      }}
                    >
                      <button
                        type="button"
                        data-tooltip-id="high"
                        className={
                          sortFilter == "ASC"
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setSortFilter("ASC")}
                      >
                        Safety
                        <ReactTooltip
                          id="high"
                          place="top"
                          content="Driver Performance High to Low"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="low"
                        className={
                          sortFilter == "DESC"
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setSortFilter("DESC")}
                      >
                        Dangerous
                        <ReactTooltip
                          id="low"
                          place="top"
                          content="Driver Performance Low to High"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>

                  <Col
                    xl={2}
                    lg={3}
                    style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="dura"
                      >
                        Duration
                        <ReactTooltip
                          id="dura"
                          place="top"
                          content="Duration"
                          style={{ background: "black" }}
                        />
                      </h4>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        textAlign: "center",
                        height: "40px",
                        marginTop: "16px",
                      }}
                    >
                      <button
                        type="button"
                        data-tooltip-id="cds"
                        className={
                          (filter == "CURRENT_DAY" && !modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                      >
                        CD
                        <ReactTooltip
                          id="cds"
                          place="top"
                          content="Current Day"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cws"
                        className={
                          (filter == "CURRENT_WEEK" && !modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                      >
                        CW
                        <ReactTooltip
                          id="cws"
                          place="top"
                          content="Current Week"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cms"
                        className={
                          (filter == "CURRENT_MONTH" && !modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                      >
                        CM
                        <ReactTooltip
                          id="cms"
                          place="top"
                          content="Current Month"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cys"
                        className={
                          (filter == "CURRENT_YEAR" && !modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                      >
                        CY
                        <ReactTooltip
                          id="cys"
                          place="top"
                          content="Current Year"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CUSTOM_DURATION" || modalIsOpen)
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        data-tooltip-id="dfdf"
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <ReactTooltip
                          id="dfdf"
                          place="top"
                          content="Custom Date Selection"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>

                  <Col
                    xl={3}
                    lg={2}
                    style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="GlobalSearch"
                      >
                        Global Search
                        <ReactTooltip
                          id="GlobalSearch"
                          place="top"
                          content="Global Search"
                          style={{ background: "black", zIndex: "100" }}
                        />
                      </h4>
                    </div>

                    <div
                      className="my-3"
                      style={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "15px",
                      }}
                    >
                      <input
                        type="search"
                        style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                        value={subFilterText}
                        onChange={(e) => {
                          setSubFilterText(e.target.value);
                        }}
                        onKeyPress={handleKeyPress}
                        placeholder="Mobile number or First name"
                      />
                      <button
                        onClick={submit}
                        style={{ marginLeft: "5px" }}
                        className="btn btnColorStylesNew me-3"
                        type="button"
                        //   onClick={searchSubmit}
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                      </button>
                    </div>
                  </Col>

                  <Col xl={1} lg={1} style={{ paddingLeft: "0px" }}>
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="dwls"
                      >
                        Download
                        <ReactTooltip
                          id="dwls"
                          place="top"
                          content="Download"
                          style={{ background: "black" }}
                        />
                      </h4>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        textAlign: "center",
                        height: "40px",
                        marginTop: "16px",
                      }}
                    >
                      {/* <span className="mt-1">Excel:</span> */}

                      <div>
                        <CSVLink
                          data={exportExcelData}
                          type="button"
                          // onClick={onCloseClick}
                          className="btn btn-secondary btn-sm"
                          filename={
                            userFirstName + "'s" + " Active Users List.csv"
                          }
                          // id="delete-record"
                        >
                          <PiDownloadSimple
                            data-tooltip-id="currentyears"
                            size="18px"
                          />
                          <ReactTooltip
                            id="currentyears"
                            place="top"
                            content="Current Year Active Users as Excel"
                            style={{ background: "black" }}
                          />
                          {/* <span style={{ fontSize: 10 }}>CY</span> */}
                        </CSVLink>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                {/* <Row>
                  <Col xl={12} lg={12} md={12} sm={12} sx={12}>
                    <form className="d-none d-lg-block">
                      <div
                        className="mb-3 mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="mt-2" style={{ fontSize: "15px" }}>
                          &nbsp;Global Search:
                        </label>
                        <input
                          type="search"
                          style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                          value={subFilterText}
                          onChange={(e) => {
                            setSubFilterText(e.target.value);
                          }}
                          onKeyPress={handleKeyPress}
                          placeholder="Mobile number or First name"
                        />
                        <button
                          onClick={submit}
                          style={{ marginLeft: "5px" }}
                          className="btn btn-primary me-3"
                          type="button"
                        //   onClick={searchSubmit}
                        >
                          <i className="bx bx-search-alt align-middle"></i>
                        </button>
                      </div>
                    </form>
                  </Col>
                </Row> */}
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered border-primary mb-0">
                        <thead className="cardHeader">
                          <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "8px",
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="fname"
                            >
                              First Name
                              <ReactTooltip
                                id="fname"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "8px",
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                              data-tooltip-id="uname"
                            >
                              Username
                              <ReactTooltip
                                id="uname"
                                place="top"
                                content="Username"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "8px",
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                              data-tooltip-id="vt"
                            >
                              Vehicle Type
                              <ReactTooltip
                                id="vt"
                                place="top"
                                content="Vehicle Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "8px",
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                              data-tooltip-id="dtype"
                            >
                              Device Type
                              <ReactTooltip
                                id="dtype"
                                place="top"
                                content="Device Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                textAlign: "center",
                                padding: "8px",
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="trides"
                            >
                              Total Rides
                              <ReactTooltip
                                id="trides"
                                place="top"
                                content="Total Rides"
                                style={{ background: "black" }}
                              />
                              <br />
                              <span
                                style={{ fontSize: "11px", fontWeight: 400 }}
                              ></span>
                            </th>
                            <th
                              data-tooltip-id="srides"
                              style={{
                                textAlign: "center",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "8px",
                              }}
                            >
                              Safe Driver
                              <ReactTooltip
                                id="srides"
                                place="top"
                                content="Safe Driver"
                                style={{ background: "black" }}
                              />
                              <br />
                              <span
                                style={{ fontSize: "11px", fontWeight: 400 }}
                              >
                                (C1)
                              </span>
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "8px",
                              }}
                              data-tooltip-id="spride"
                            >
                              Sportive Driver
                              <ReactTooltip
                                id="spride"
                                place="top"
                                content="Sportive Driver"
                                style={{ background: "black" }}
                              />
                              <br />
                              <span
                                style={{ fontSize: "11px", fontWeight: 400 }}
                              >
                                (C2)
                              </span>
                            </th>

                            <th
                              data-tooltip-id="safrides"
                              style={{
                                textAlign: "center",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "8px",
                              }}
                            >
                              Quite Safe Driver
                              <br />
                              <ReactTooltip
                                id="safrides"
                                place="top"
                                content="Quite Safe Driver"
                                style={{ background: "black" }}
                              />
                              <span
                                style={{ fontSize: "11px", fontWeight: 400 }}
                              >
                                (C3)
                              </span>
                            </th>
                            <th
                              data-tooltip-id="unsaferides"
                              style={{
                                textAlign: "center",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "8px",
                              }}
                            >
                              Reckless Driver
                              <br />
                              <ReactTooltip
                                id="unsaferides"
                                place="top"
                                content="Reckless Driver"
                                style={{ background: "black" }}
                              />
                              <span
                                style={{ fontSize: "11px", fontWeight: 400 }}
                              >
                                (C4)
                              </span>
                            </th>
                            <th
                              data-tooltip-id="safessrides"
                              style={{
                                textAlign: "center",
                                padding: "8px",
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                            >
                              Quite Dangerous Driver
                              <br />
                              <ReactTooltip
                                id="safessrides"
                                place="top"
                                content="Quite Dangerous Driver"
                                style={{ background: "black" }}
                              />
                              <span
                                style={{ fontSize: "11px", fontWeight: 400 }}
                              >
                                (C5)
                              </span>
                            </th>
                            <th
                              data-tooltip-id="unsafeerides"
                              style={{
                                textAlign: "center",
                                padding: "8px",
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                            >
                              Dangerous Driver
                              <br />
                              <ReactTooltip
                                id="unsafeerides"
                                place="top"
                                content="Dangerous Driver"
                                style={{ background: "black" }}
                              />
                              <span
                                style={{ fontSize: "11px", fontWeight: 400 }}
                              >
                                (C6)
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            (data || []).map((apiData, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  <Link
                                    to={"/user-dashboard"}
                                    onMouseEnter={() => [
                                      localStorage.setItem(
                                        SC_USER_ID,
                                        apiData.userId
                                      ),
                                      localStorage.setItem(
                                        SC_LOGIN_USER_FIRST_NAME,
                                        apiData.firstName
                                      ),
                                      localStorage.setItem(
                                        SC_USER_USERNAME,
                                        apiData.username
                                      ),
                                      localStorage.setItem(
                                        DEVICE_TYPE,
                                        apiData.deviceType
                                      ),
                                      localStorage.setItem(
                                        VEHICLE_TYPE,
                                        apiData.vehicleType
                                      ),
                                    ]}
                                  >
                                    <span style={{ fontSize: "13px" }}>
                                      {apiData.firstName}
                                    </span>
                                  </Link>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <div>{apiData.username}</div>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <div>
                                    {apiData.vehicleType == "TWO_WHEELER" ? 
                                    <img src={TW} width={25} height={25}/> : apiData.vehicleType == "FOUR_WHEELER" ?
                                     <img src={FW} width={25} height={25}/> : "-"  }
                                     </div>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <div>{apiData.deviceType}</div>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    textAlign: "end",
                                    padding: "10px",
                                  }}
                                >
                                  <div className=" w-50">
                                    {apiData.totalRideCount}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiData.goodRidePer} % (
                                    {apiData.goodRideCount})
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiData.sportiveRidePer} % (
                                    {apiData.sportiveRideCount})
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiData.quietSafeRidePer}% (
                                    {apiData.quietSafeRideCount})
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiData.recklessRidePer} % (
                                    {apiData.recklessRideCount})
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiData.quietDangerousRidePer}% (
                                    {apiData.quietDangerousRideCount})
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiData.dangerousRidePer}% (
                                    {apiData.dangerousRideCount})
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : dataLength === 0 ? (
                            <tr>
                              {" "}
                              <td colSpan="11">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="11">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  width: "45px",
                  visibility: prevShow ? "visible" : "hidden",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="pagination">
                    <a>{pageNumber}</a>
                  </div>
                  <p
                    style={{
                      marginTop: "6px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    of
                  </p>
                  <div className="pagination ">
                    <a>{totalPageCount}</a>
                  </div>
                </div>
              ) : null}
              <button
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  width: "45px",
                  visibility: nextShow && dataLength > 0 ? "visible" : "hidden",
                }}
              >
                Next
              </button>
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
    </div>
  );
}

// ActiveUsersTableList.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// };

export default ActiveUsersTableList;
